import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import './index.css';
import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';

import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import * as dataApi from '../../api/dataApi';
import Empty from '../Common/Empty';
import ProviderLogo from '../OfferGrid/ProviderLogo';
import ProviderListMap from '../MovingTrucks/ProviderListMap';
import AddressSearch from '../AddressSearch';
import PlaceGrid from '../PlaceGrid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import ResidentOnboardingPopup from '../ResidentOnboarding/ResidentOnboardingPopup';

import './index.css';

function ButtonsForInsuranceDashboard({
  mobile,
  checkboxChanged,
  actions,
  setCheckboxChanged,
  setServiceSetting,
  serviceSetting
}) {
  return (
    <div className={'buttons' + (mobile ? ' mobile' : '')}>
      <Button
        onClick={(event) => {
          setCheckboxChanged(false);
          setServiceSetting({
            ...serviceSetting,
            IsAlreadyProvidedChecked: false
          });
        }}
        variant="outlined">
        Cancel
      </Button>
      <Button
        variant="contained"
        color="secondary"
        disabled={!checkboxChanged}
        onClick={() => {
          actions.saveProviderService({ ...serviceSetting }).then((result) => {
            if (result) {
            }
          });
        }}>
        Submit
      </Button>
    </div>
  );
}

export function CoverageOptions({ coverageOptions }) {
  return coverageOptions && coverageOptions.InsuranceIsMinCoverageRequired ? (
    <div className="insurance-requirements">
      <h5>Coverage Requirements</h5>
      {coverageOptions.InsuranceIsMinCoverageRequired &&
      coverageOptions.InsuranceMinCoverageLiabilityRequired &&
      coverageOptions.InsuranceMinCoverageLiabilityValue ? (
        <div
          className={
            'requirement ' +
            (coverageOptions.InsuranceIsMinCoverageRequired &&
            coverageOptions.InsuranceMinCoveragePropertyRequired &&
            coverageOptions.InsuranceMinCoveragePropertyValue
              ? 'horizontal'
              : '')
          }>
          <div className="label">
            Personal Liability
            <br />
            Minimum Coverage:
          </div>
          <div className="value">
            $
            {coverageOptions.InsuranceMinCoverageLiabilityValue.toLocaleString()}
          </div>
        </div>
      ) : null}

      {coverageOptions.InsuranceIsMinCoverageRequired &&
      coverageOptions.InsuranceMinCoveragePropertyRequired &&
      coverageOptions.InsuranceMinCoveragePropertyValue ? (
        <div
          className={
            'requirement ' +
            (coverageOptions.InsuranceIsMinCoverageRequired &&
            coverageOptions.InsuranceMinCoverageLiabilityRequired &&
            coverageOptions.InsuranceMinCoverageLiabilityValue
              ? 'horizontal'
              : '')
          }>
          <div className="label">
            Personal Property
            <br />
            Minimum Coverage:
          </div>
          <div className="value">
            $
            {coverageOptions.InsuranceMinCoveragePropertyValue.toLocaleString()}
          </div>
        </div>
      ) : null}

      {coverageOptions.InsuranceIsMinCoverageRequired &&
      coverageOptions.InsuranceMinCoverageOtherRequired &&
      coverageOptions.InsuranceMinCoverageOtherLabel &&
      coverageOptions.InsuranceMinCoverageOtherValue ? (
        <div className="requirement">
          <div className="label">
            {coverageOptions.InsuranceMinCoverageOtherLabel}
            <br />
            Minimum Coverage:
          </div>
          <div className="value">
            ${coverageOptions.InsuranceMinCoverageOtherValue.toLocaleString()}
          </div>
        </div>
      ) : null}

      {coverageOptions.InsuranceIsInterestedPartyRequired &&
      coverageOptions.InsuranceInterestedPartyText ? (
        <div className="requirement">
          <div className="label">
            Policy must show the following entities as "Interested Parties":
          </div>
          <div
            className="value"
            dangerouslySetInnerHTML={{
              __html:
                coverageOptions.InsuranceInterestedPartyText.split('\n').join(
                  '<br />'
                )
            }}></div>
        </div>
      ) : null}
    </div>
  ) : null;
}

function RentersInsurance({
  preferredProvider,
  coverageOptions,
  providerService,
  residentOnboarding,
  actions
}) {
  const [residentOnboardingItem, setResidentOnboardingItem] = useState(null);
  const [checkboxChanged, setCheckboxChanged] = useState(false);
  const [showResidentOnboarding, setShowResidentOnboarding] = useState(null);
  const [isAlreadyHaveChecked, setIsAlreadyHaveChecked] = useState(null);
  const [serviceSetting, setServiceSetting] = useState({
    ProviderServiceID: providerService.ProviderServiceID,
    IsAlreadyProvidedChecked: false
  });

  useEffect(() => {
    if (residentOnboarding && residentOnboarding.Checklist) {
      loadResidentOnboardingItem();
    }
  }, [residentOnboarding]);

  function loadResidentOnboardingItem() {
    const _item = residentOnboarding.Checklist.filter(
      (item) =>
        item.ProviderServiceID === providerService.ProviderServiceID &&
        item.ResidentOnboardingStatusID === 1
    )[0];

    setResidentOnboardingItem(
      _item
        ? {
            ..._item
          }
        : null
    );
  }

  return preferredProvider || coverageOptions ? (
    <div className="renters-insurance">
      {preferredProvider ? (
        <ul className="provider-list preferred">
          <li
            className={preferredProvider.Description ? 'with-description' : ''}>
            <Button
              href={preferredProvider.ServiceStartUrl}
              target="mi_offsite">
              <div className="badge">Preferred Provider</div>
              {preferredProvider.LogoFile ? (
                <img
                  src={preferredProvider.LogoFile}
                  className={'provider-logo'}
                />
              ) : (
                preferredProvider.DisplayName
              )}
              {preferredProvider.Description ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: preferredProvider.Description
                  }}></p>
              ) : null}
            </Button>
          </li>
        </ul>
      ) : null}

      <CoverageOptions coverageOptions={coverageOptions} />

      {providerService.ProviderList.filter(
        (provider) => provider.IsServiceProofUpload
      ).length && residentOnboardingItem ? (
        <div className="renters-insurance-already-provided">
          {serviceSetting.IsAlreadyProvidedChecked ? (
            <ButtonsForInsuranceDashboard
              checkboxChanged={checkboxChanged}
              actions={actions}
              setCheckboxChanged={setCheckboxChanged}
              setServiceSetting={setServiceSetting}
              serviceSetting={serviceSetting}
            />
          ) : null}
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={serviceSetting.IsAlreadyProvidedChecked === true}
                  onChange={(event) => {
                    setServiceSetting({
                      ...serviceSetting,
                      IsAlreadyProvidedChecked: event.target.checked
                    });

                    setCheckboxChanged(true);
                  }}
                />
              }
              label={providerService.ProviderList.filter(
                (provider) => provider.IsProviderPreferred
              )
                .concat({ ProviderID: 0 })
                .map((provider, providerIndex) =>
                  providerIndex === 0
                    ? provider.ProviderID > 0
                      ? 'I already purchased coverage through ' +
                        provider.ProviderName
                      : 'I used the Preferred Provider when I signed my lease (no upload needed)'
                    : null
                )}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAlreadyHaveChecked}
                  onChange={(event) => {
                    setIsAlreadyHaveChecked(true);
                    setServiceSetting({
                      ...serviceSetting,
                      IsAlreadyProvidedChecked: false
                    });
                    setShowResidentOnboarding({ ...providerService });
                    setCheckboxChanged(false);
                  }}
                />
              }
              label="I already have coverage through a different provider (proof of coverage required)"
            />
            {serviceSetting.IsAlreadyProvidedChecked ? (
              <ButtonsForInsuranceDashboard
                mobile
                checkboxChanged={checkboxChanged}
                actions={actions}
                setCheckboxChanged={setCheckboxChanged}
                setServiceSetting={setServiceSetting}
                serviceSetting={serviceSetting}
              />
            ) : null}
          </FormGroup>
        </div>
      ) : null}

      {showResidentOnboarding ? (
        <ResidentOnboardingPopup
          providerServiceList={[showResidentOnboarding]}
          closeFunc={() => {
            setShowResidentOnboarding(null);
            setIsAlreadyHaveChecked(false);
          }}
        />
      ) : null}
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      markTaskComplete: bindActionCreators(
        serviceAddressActions.markTaskComplete,
        dispatch
      ),
      saveProviderService: bindActionCreators(
        residentOnboardingActions.saveProviderService,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RentersInsurance);
