import React, { useEffect, useRef, useState } from 'react';
import { withPrefix } from 'gatsby-link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import { loadResidentOnboarding } from '../../redux/actions/residentOnboardingActions';

import ResidentOnboardingPet from '../ResidentOnboarding/ResidentOnboardingPet';
import ResidentOnboardingHeader from '../ResidentOnboarding/ResidentOnboardingHeader';

import './index.css';

function PetProfile({
  residentOnboarding,
  pet,
  closeFunc,
  actions,
  profileKey
}) {
  useEffect(() => {
    actions.loadResidentOnboarding(pet.ResidentID);
  }, []);

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="resident-profile-popup"
      fullWidth={true}>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        <img src={withPrefix('/images/logo-icon.svg')} className="logo-icon" />
        {pet.AssistanceAnimalTypeName
          ? pet.AssistanceAnimalTypeName
          : 'Pet'}{' '}
        Profile for {pet.Name}
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        <ResidentOnboardingHeader resident={pet} />
        {residentOnboarding &&
        residentOnboarding.Header.ResidentID === pet.ResidentID ? (
          <ResidentOnboardingPet
            residentOnboarding={residentOnboarding}
            refreshResidentOnboarding={actions.loadResidentOnboarding}
            residentID={pet.ResidentID}
            residentPetID={pet.ResidentPetID}
            profileKey={profileKey}
          />
        ) : (
          <LinearProgress />
        )}
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'right' }}>
        <Button
          style={{ float: 'left' }}
          variant="contained"
          color="primary"
          href={
            process.env.GATSBY_EXPORT_URL +
            'docs/pet?property=' +
            profileKey +
            '&resident=' +
            pet.ResidentID +
            '&residentPetID=' +
            pet.ResidentPetID
          }>
          Print &nbsp;&nbsp;
          <PrintIcon />
        </Button>

        <Button
          onClick={() => {
            closeFunc();
          }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      loadResidentOnboarding: bindActionCreators(
        loadResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PetProfile);
