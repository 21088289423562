import * as api from './apiUtils';
import { getCookie } from './dataApi';

function getConfig() {
  return { headers: { user: getCookie('mi-user') } };
}

export function runAllInternetOfferImports(serviceAddress) {
  return api
    .post('API/Admin/RunAllInternetOfferImports', serviceAddress, getConfig())
    .then((response) => {
      return response.data;
    });
}
