import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import moment from 'moment';
import { useQueryParam } from 'gatsby-query-params';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Button, IconButton, LinearProgress } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';

import {
  listCalendarEvent,
  saveCalendarEvent
} from '../../api/residentOnboardingApi';

import { listUnit } from '../../api/propertyProfileApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import GeneralPopup from '../GeneralPopup';

import './index.css';

export function convertStringToDateTime(dateString, timeString) {
  const date = moment(dateString).format('yyyy-MM-DD');
  const timeArray = timeString.split(':');
  if (timeArray.length === 2) {
    const minArray = timeArray[1].split(' ');
    if (minArray.length === 2) {
      let hour = parseInt(timeArray[0]);
      const ampm = minArray[1].toLowerCase();
      if (ampm === 'pm') {
        if (hour !== 12) {
          hour += 12;
        }
      } else if (hour === 12) {
        hour = 0;
      }

      const minute = parseInt(minArray[0]);
      const dateTimeString =
        date +
        ' ' +
        (hour <= 9 ? '0' : '') +
        hour +
        ':' +
        (minute <= 9 ? '0' : '') +
        minute +
        ':00';
      const dateTime = moment(dateTimeString);
      return dateTime;
    }
  }
}

function CustomerEventCalendar({ calendar, minDate, residentID, profileKey }) {
  const [calendarDateSelected, setCalendarDateSelected] = useState(
    minDate && moment(minDate).isSameOrAfter(new Date())
      ? minDate
      : moment().format()
  );

  const [eventList, setEventList] = useState(null);
  const [schedule, setSchedule] = useState({});
  const [saving, setSaving] = useState(null);
  const [showNotAvailable, setShowNotAvailable] = useState(null);
  const [confirmRemoveEvent, setConfirmRemoveEvent] = useState(null);
  const [fullTimeslotList, setFullTimeslotList] = useState(null);
  const [showPropertyReserve, setShowPropertyReserve] = useState(null);
  const [residentList, setResidentList] = useState(null);

  useEffect(() => {
    listCalendarEvent(calendar.CalendarID).then((_eventList) =>
      updateSchedule(_eventList)
    );
  }, []);

  useEffect(() => {
    if (calendarDateSelected) {
      const startDate = moment(calendarDateSelected);

      let dateCount = 0;
      let date = moment(startDate.format());

      let _eventList = [];

      while (dateCount < 4) {
        const timeslotList = listTimeslot(date);

        if (timeslotList.length) {
          _eventList.push(timeslotList);
          dateCount++;
        }

        date.add(1, 'day');
      }

      setEventList(_eventList);
      setFullTimeslotList(listTimesBetween(getMinStartTime(), getMaxEndTime()));
    }
  }, [calendarDateSelected]);

  useEffect(() => {
    if (
      showPropertyReserve &&
      showPropertyReserve.Type === 'Resident' &&
      !residentList
    ) {
      listUnit(profileKey).then((_residentList) =>
        setResidentList(
          _residentList
            .filter(
              (resident) => resident.ResidentID && resident.ResidentStatusID < 3
            )
            .sort((a, b) => a.UnitNumber.localeCompare(b.UnitNumber))
        )
      );
    }
  }, [showPropertyReserve]);

  function getMinStartTime() {
    let time = null;
    if (calendar) {
      [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ].map((dayOfWeek) => {
        if (calendar[dayOfWeek + 'StartTime']) {
          const timeValue = convertStringToDateTime(
            '1984-11-14',
            calendar[dayOfWeek + 'StartTime']
          );
          if (timeValue && (!time || moment(timeValue).isBefore(time))) {
            time = timeValue;
          }
        }
      });
    }

    return time;
  }

  function getMaxEndTime() {
    let time = null;
    if (calendar) {
      [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ].map((dayOfWeek) => {
        if (calendar[dayOfWeek + 'EndTime']) {
          const timeValue = convertStringToDateTime(
            '1984-11-14',
            calendar[dayOfWeek + 'EndTime']
          );
          if (timeValue && (!time || moment(timeValue).isAfter(time))) {
            time = timeValue;
          }
        }
      });
    }

    return time;
  }

  function listTimeslot(date, checkExistsOnly) {
    let result = [];

    const dayOfWeek = moment(date).format('dddd');

    if (
      calendar &&
      calendar.TimeslotLengthMinutes &&
      calendar[dayOfWeek + 'StartTime'] &&
      calendar[dayOfWeek + 'EndTime']
    ) {
      const startTime = convertStringToDateTime(
        date,
        calendar[dayOfWeek + 'StartTime']
      );

      const endTime = convertStringToDateTime(
        date,
        calendar[dayOfWeek + 'EndTime']
      );

      if (startTime && endTime) {
        if (checkExistsOnly) {
          return true;
        }

        console.log('listTimeslot', calendar[dayOfWeek + 'EndTime'], endTime);

        result = listTimesBetween(startTime, endTime);
      }
    }

    return result;
  }

  function listTimesBetween(startTime, endTime) {
    let result = [];
    let time = moment(startTime.format());

    while (time.isSameOrBefore(endTime)) {
      result.push(time.format());

      time.add(calendar.TimeslotLengthMinutes, 'minutes');
    }

    return result;
  }

  function getTimeslotKey(date, timeslot) {
    return moment(date).format('YYYY-MM-DD') + '_' + timeslot;
  }

  function updateSchedule(_eventList) {
    let _schedule = {};
    _eventList.map((_event) => {
      _schedule[
        getTimeslotKey(_event.Date, _event.StartTime + '_' + _event.EndTime)
      ] = residentID ? _event.ResidentID : _event;
    });

    setSchedule(_schedule);
  }

  function nextAvailableDay(date, direction) {
    let _nextDate = moment(date).add(direction, 'day');
    if (listTimeslot(_nextDate).length) {
      return _nextDate;
    } else {
      return nextAvailableDay(_nextDate, direction);
    }
  }

  function checkTimeframeAvailable(timeframe, timeframeList) {
    return timeframeList.filter(
      (_timeframe) => _timeframe.split('T')[1] === timeframe.split('T')[1]
    ).length;
  }

  return (
    <div className="customer-event-calendar">
      <div className="date-picker">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            orientation="landscape"
            openTo="day"
            disablePast
            disableHighlightToday
            value={calendarDateSelected}
            shouldDisableDate={(date) => {
              if (minDate && moment(date).isBefore(minDate)) {
                return true;
              }

              return !listTimeslot(date, true);
            }}
            views={['day']}
            onChange={(date) => {
              setCalendarDateSelected(date);
            }}
          />
        </LocalizationProvider>
      </div>
      <div className={'event-list' + (saving ? ' saving' : '')}>
        {eventList && fullTimeslotList
          ? eventList.map((date, dateIndex) => (
              <div className="date">
                <h5>
                  <IconButton
                    className="left"
                    disabled={
                      (minDate &&
                        moment(calendarDateSelected).isSameOrBefore(minDate)) ||
                      moment(calendarDateSelected).isSameOrBefore(new Date())
                    }
                    onClick={(event) => {
                      setCalendarDateSelected(
                        nextAvailableDay(calendarDateSelected, -1)
                      );

                      event.stopPropagation();
                    }}>
                    <ChevronLeftIcon />
                  </IconButton>

                  <IconButton
                    className="right"
                    onClick={(event) => {
                      setCalendarDateSelected(
                        nextAvailableDay(calendarDateSelected, 1)
                      );

                      event.stopPropagation();
                    }}>
                    <ChevronRightIcon />
                  </IconButton>

                  {moment(date[0]).format('ddd MMM D')}
                </h5>
                {fullTimeslotList.map((timeframe, timeframeIndex) =>
                  fullTimeslotList[timeframeIndex + 1]
                    ? [
                        moment(timeframe).format('h:mm a') +
                          '_' +
                          moment(fullTimeslotList[timeframeIndex + 1]).format(
                            'h:mm a'
                          )
                      ].map((timeslot) => (
                        <div className="timeframe">
                          <Button
                            variant={
                              schedule[getTimeslotKey(date[0], timeslot)]
                                ? 'contained'
                                : 'outlined'
                            }
                            disabled={
                              !checkTimeframeAvailable(timeframe, date) ||
                              (residentID &&
                                schedule[getTimeslotKey(date[0], timeslot)] &&
                                schedule[getTimeslotKey(date[0], timeslot)] !==
                                  residentID)
                            }
                            onClick={() => {
                              const timeslotKey = getTimeslotKey(
                                date[0],
                                timeslot
                              );

                              let _schedule = { ...schedule };

                              if (residentID) {
                                if (_schedule[timeslotKey]) {
                                  _schedule[timeslotKey] = null;
                                } else {
                                  let _selectedTimeslotCount = 0;
                                  Object.keys(_schedule).map((_key) => {
                                    if (_schedule[_key] === residentID) {
                                      _selectedTimeslotCount++;
                                    }
                                  });

                                  if (
                                    _selectedTimeslotCount >=
                                    calendar.TimeslotMaxAllowed
                                  ) {
                                    _schedule[
                                      Object.keys(_schedule).filter(
                                        (_key) => _schedule[_key] === residentID
                                      )[0]
                                    ] = null;
                                  }

                                  _schedule[timeslotKey] = residentID;
                                }

                                let _eventList = [];

                                Object.keys(_schedule)
                                  .filter(
                                    (_key) => _schedule[_key] === residentID
                                  )
                                  .map((_key) => {
                                    const timeframeArr = _key.split('_');
                                    _eventList.push({
                                      Date: timeframeArr[0],
                                      StartTime: timeframeArr[1],
                                      EndTime: timeframeArr[2],
                                      IsReserved: true
                                    });
                                  });

                                setSaving(true);
                                saveCalendarEvent(
                                  calendar.CalendarID,
                                  residentID,
                                  _eventList
                                ).then((updatedEventList) => {
                                  setSaving(false);
                                  updateSchedule(updatedEventList);

                                  const missingEventList = _eventList.filter(
                                    (event) =>
                                      updatedEventList.filter(
                                        (_event) =>
                                          moment(_event.Date).format(
                                            'YYYY-MM-DD'
                                          ) === event.Date &&
                                          _event.StartTime ===
                                            event.StartTime &&
                                          _event.EndTime === event.EndTime &&
                                          _event.ResidentID === residentID
                                      ).length === 0
                                  );

                                  if (missingEventList.length) {
                                    setShowNotAvailable(missingEventList);
                                  }
                                });
                              } else {
                                if (_schedule[timeslotKey]) {
                                  setConfirmRemoveEvent(_schedule[timeslotKey]);
                                } else {
                                  const timeframeArr = timeslotKey.split('_');
                                  setShowPropertyReserve({
                                    Date: timeframeArr[0],
                                    StartTime: timeframeArr[1],
                                    EndTime: timeframeArr[2],
                                    IsReserved: true
                                  });
                                }
                              }

                              setSchedule({
                                ..._schedule
                              });
                            }}>
                            {timeslot.split('_').join(' - ')}
                            <em>
                              {schedule[getTimeslotKey(date[0], timeslot)]
                                ? saving &&
                                  schedule[
                                    getTimeslotKey(date[0], timeslot)
                                  ] === residentID
                                  ? 'Reserving...'
                                  : residentID ||
                                    !schedule[getTimeslotKey(date[0], timeslot)]
                                      .LastName
                                  ? schedule[getTimeslotKey(date[0], timeslot)]
                                      .Notes
                                    ? schedule[
                                        getTimeslotKey(date[0], timeslot)
                                      ].Notes
                                    : 'Reserved'
                                  : schedule[getTimeslotKey(date[0], timeslot)]
                                      .UnitNumber +
                                    ' - ' +
                                    schedule[getTimeslotKey(date[0], timeslot)]
                                      .LastName
                                : checkTimeframeAvailable(timeframe, date)
                                ? 'Open'
                                : 'Unavailable'}
                            </em>
                          </Button>
                        </div>
                      ))
                    : null
                )}
              </div>
            ))
          : null}
      </div>
      {showNotAvailable ? (
        <GeneralPopup
          title={calendar.Term + ' Not Saved'}
          message={
            <>
              <p style={{ paddingTop: '20px' }}>
                The time slot you selected is no longer available. The calendar
                has been updated. Please select a new time.
              </p>
            </>
          }
          closeFunc={() => setShowNotAvailable(null)}
        />
      ) : null}

      {confirmRemoveEvent ? (
        <GeneralPopup
          title={calendar.Term + ' Details'}
          message={
            <div style={{ textAlign: 'center' }}>
              <p style={{ paddingTop: '20px' }}>
                <strong>
                  {moment(confirmRemoveEvent.Date).format('dddd, MMMM D, YYYY')}{' '}
                  from {confirmRemoveEvent.StartTime} -{' '}
                  {confirmRemoveEvent.EndTime}
                </strong>
              </p>
              {confirmRemoveEvent.ResidentID &&
              confirmRemoveEvent.UnitNumber ? (
                <p>
                  Unit #{confirmRemoveEvent.UnitNumber}
                  <br />
                  {confirmRemoveEvent.FirstName} {confirmRemoveEvent.LastName}
                  {confirmRemoveEvent.Phone ? (
                    <>
                      <br />
                      <a href={'tel:' + confirmRemoveEvent.Phone}>
                        {confirmRemoveEvent.Phone}
                      </a>
                    </>
                  ) : null}
                  {confirmRemoveEvent.Email ? (
                    <>
                      <br />
                      <a href={'mailto:' + confirmRemoveEvent.Email}>
                        {confirmRemoveEvent.Email}
                      </a>
                    </>
                  ) : null}
                </p>
              ) : (
                <p>
                  Time slot reserved by property: {confirmRemoveEvent.Notes}
                </p>
              )}
            </div>
          }
          closeFunc={() => setConfirmRemoveEvent(null)}
          closeLabel={'Close'}
          submitLabel={saving ? 'Removing...' : 'Cancel Reservation'}
          submitFunc={() => {
            setSaving(true);
            saveCalendarEvent(
              calendar.CalendarID,
              confirmRemoveEvent.ResidentID,
              eventList.filter(
                (event) =>
                  event.ResidentID === confirmRemoveEvent.ResidentID &&
                  event.CalendarEventID !== confirmRemoveEvent.CalendarEventID
              )
            ).then((updatedEventList) => {
              setSaving(false);
              updateSchedule(updatedEventList);
              setConfirmRemoveEvent(null);
            });
          }}
        />
      ) : null}

      {showPropertyReserve ? (
        <GeneralPopup
          title={'Reserve Time Slot'}
          className={'calendar-timeslot-popup'}
          message={
            <div>
              <p style={{ paddingTop: '20px' }}>
                <strong>
                  Reserving{' '}
                  {moment(showPropertyReserve.Date).format('M/D/YYYY')} from{' '}
                  {showPropertyReserve.StartTime} -{' '}
                  {showPropertyReserve.EndTime}
                </strong>
              </p>
              <FormControl component="fieldset" fullWidth>
                <RadioGroup
                  aria-label="notificationMethod"
                  name="notificationMethod"
                  fullWidth
                  value={showPropertyReserve.Type}
                  onChange={(event, newValue) => {
                    setShowPropertyReserve({
                      ...showPropertyReserve,
                      Type: newValue
                    });
                  }}>
                  <FormControlLabel
                    value="Resident"
                    fullWidth
                    className={
                      showPropertyReserve.Type === 'Resident' ? 'selected' : ''
                    }
                    control={<Radio color="primary" />}
                    label={<>Resident {calendar.Term}</>}
                  />
                  {showPropertyReserve.Type === 'Resident' ? (
                    residentList ? (
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel id="resident-select-label" fullWidth>
                          Resident
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="resident-select-label"
                          id="resident-select"
                          value={showPropertyReserve.ResidentID}
                          label="Resident"
                          onChange={(event) =>
                            setShowPropertyReserve({
                              ...showPropertyReserve,
                              ResidentID: event.target.value
                            })
                          }>
                          {residentList.length ? (
                            residentList.map((resident) => (
                              <MenuItem value={resident.ResidentID}>
                                {'Unit ' +
                                  resident.UnitNumber +
                                  ' - ' +
                                  resident.FirstName +
                                  ' ' +
                                  resident.LastName}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value={0} disabled>
                              No Current Residents
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    ) : (
                      <LinearProgress />
                    )
                  ) : null}

                  <FormControlLabel
                    value="Other"
                    className={
                      showPropertyReserve.Type === 'Other' ? 'selected' : ''
                    }
                    control={<Radio color="primary" />}
                    label={<>Other (Explain Below)</>}
                  />

                  {showPropertyReserve.Type === 'Other' ? (
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      label={'Reason for Reserving'}
                      onChange={(event) =>
                        setShowPropertyReserve({
                          ...showPropertyReserve,
                          Notes: event.target.value
                        })
                      }
                      value={showPropertyReserve.Notes}
                    />
                  ) : null}
                </RadioGroup>
              </FormControl>
            </div>
          }
          closeFunc={() => setShowPropertyReserve(null)}
          closeLabel={'Cancel'}
          submitDisabled={
            !showPropertyReserve.Type ||
            (showPropertyReserve.Type === 'Resident' &&
              !showPropertyReserve.ResidentID) ||
            (showPropertyReserve.Type === 'Other' && !showPropertyReserve.Notes)
          }
          submitLabel={saving ? 'Reserving...' : 'Save ' + calendar.Term}
          submitFunc={() => {
            let _eventList =
              showPropertyReserve.Type === 'Resident'
                ? eventList.filter(
                    (event) =>
                      event.ResidentID === showPropertyReserve.ResidentID
                  )
                : [];

            _eventList.push({
              ...showPropertyReserve,
              IsReserved: true,
              Notes:
                showPropertyReserve.Type === 'Other'
                  ? showPropertyReserve.Notes
                  : ''
            });

            setSaving(true);
            saveCalendarEvent(
              calendar.CalendarID,
              showPropertyReserve.Type === 'Resident'
                ? showPropertyReserve.ResidentID
                : -1,
              _eventList
            ).then((updatedEventList) => {
              setSaving(false);
              updateSchedule(updatedEventList);
              setShowPropertyReserve(null);
            });
          }}
        />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    authUser: state.authUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerEventCalendar);
