import React, { useState, useRef, useEffect } from 'react';
import { withPrefix } from 'gatsby-link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import * as residentOnboardingActions from '../../../redux/actions/residentOnboardingActions';

import ResidentOnboarding from '../../ResidentOnboarding';

function ResidentOnboardingPopup({
  closeFunc,
  otherTasks,
  providerServiceList,
  residentID,
  includeChecklist,
  actions,
  residentOnboarding
}) {
  const [title, setTitle] = useState('My Checklist');

  useEffect(() => {
    if (otherTasks && residentOnboarding && residentOnboarding.Checklist) {
      const task = residentOnboarding.Checklist.filter(
        (item) => item.Grouping === otherTasks
      )[0];
      if (task) {
        setTitle(
          task.OnboardingColumn ? task.OnboardingColumn : task.Description
        );
      }
    }
  }, [otherTasks, residentOnboarding]);

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="resident-onboarding-checklist-popup"
      fullWidth={true}>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        <img src={withPrefix('/images/logo-icon.svg')} className="logo-icon" />
        <div>
          {residentID
            ? residentOnboarding && residentOnboarding.Header
              ? 'Unit #' +
                residentOnboarding.Header.UnitNumber +
                ' - ' +
                residentOnboarding.Header.FirstName +
                ' ' +
                residentOnboarding.Header.LastName
              : ''
            : title}
        </div>
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        <ResidentOnboarding
          otherTasks={otherTasks}
          providerServiceList={providerServiceList}
          residentID={residentID}
          includeChecklist={includeChecklist}
        />
      </DialogContent>
      {/* <DialogActions style={{ display: 'block', textAlign: 'right' }}>
        <Button
          onClick={() => {
            closeFunc();
          }}
          variant="outlined">
          Close
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      ),
      saveResidentOnboarding: bindActionCreators(
        residentOnboardingActions.saveResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResidentOnboardingPopup);
