import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import { navigate } from 'gatsby-link';

import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Popover
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as dataApi from '../../api/dataApi';

import AddressSearch from '../AddressSearch';

import './index.css';

function LandingMITop({ actions, serviceAddress, siteConfig, location }) {
  const [showMILanding, setShowMILanding] = useState(null);
  const [showAddressSearch, setShowAddressSearch] = useState(null);
  const [addressSearched, setAddressSearched] = useState(null);

  return (
    <>
      <div className="mi-landing-top">
        <Button
          variant="outlined"
          className="login-button"
          onClick={() => {
            if (serviceAddress && serviceAddress.lastUsedServiceAddress) {
              actions.serviceAddressUpdated({
                ...serviceAddress.lastUsedServiceAddress
              });
              navigate('/dashboard');
            } else {
              setShowAddressSearch(true);
            }
          }}>
          Login
        </Button>
        <div className="hero">
          <div className="text">
            <h1>
              Simplify the{' '}
              <img alt={siteConfig.brand} src={withPrefix(siteConfig.logo)} />{' '}
              process
            </h1>

            <h2>
              Make the moving in process easier for new residents and save your
              onsite staff countless hours with MovingIN Multifamily.
            </h2>
          </div>
          <div className="next">
            <Button
              onClick={() =>
                dataApi.scrollToElementInPage(
                  document.getElementById('landing-content'),
                  0,
                  false
                )
              }>
              <h3>The smartest move you'll ever make</h3>
              <img src={withPrefix('/images/landing/down-arrow.svg')} />
              <img src={withPrefix('/images/landing/down-arrow.svg')} />
            </Button>
          </div>
        </div>

        <div className="page-container" id="landing-content">
          <div className="page">
            <div className="inner">
              <h3>The concept behind MovingIN is simple:</h3>
              <h3>
                <em>
                  Help new residents with all things moving related…
                  <span className="hide-mobile">
                    <br />
                  </span>
                  so that a property’s onsite staff doesn’t have to.
                </em>
              </h3>

              <p>
                But to accomplish ‘simple’, first we had to develop some truly
                amazing tech. The Resident Dashboard below is the result of all
                that effort. It’s so simple, intuitive and easy-to-use that it
                doesn’t need explaining, its usefulness and value so immediately
                clear that it sells itself.{' '}
              </p>
              {/* <p>
                And best of all - MovingIN is <strong>completely free </strong>
                to use for both you and your residents.
              </p> */}
              <p>Improve the move with {siteConfig.brand}.</p>
              <p>
                But don’t take our word for it – try it out for yourself. Click
                anywhere in the dashboard below and you’ll open a ‘live’ version
                where you can experience it for yourself, firsthand and free of
                a salesman’s influence. Imagine the time savings and goodwill
                you’d create if you had this for your residents.{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
      {showAddressSearch ? (
        <Dialog
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          className={
            'address-search' + (addressSearched ? ' address-searched' : '')
          }>
          <DialogTitle id="form-dialog-title">
            <img
              src={withPrefix('/images/logo-icon.svg')}
              className="logo-icon"
            />
            Get Started
            <IconButton
              style={{ float: 'right' }}
              aria-label="close"
              onClick={() => {
                setShowAddressSearch(null);
                setAddressSearched(false);
              }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent width="100%">
            <AddressSearch
              modal={true}
              mode="address"
              onSelected={() => {
                setShowAddressSearch(false);
                setAddressSearched(false);
              }}
            />
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingMITop);
