import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withPrefix } from 'gatsby-link';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  LinearProgress
} from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';

import './index.css';

import * as dataApi from '../../api/dataApi';

function SchoolBlock({ serviceAddress, schoolTypeName, title, schoolList }) {
  const [showAllDistrict, setShowAllDistrict] = useState(null);

  return (
    <div className="school-block">
      {schoolTypeName === 'Traditional' ? (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={showAllDistrict}
                onChange={(event) => setShowAllDistrict(!showAllDistrict)}
              />
            }
            label="Show all schools in district"
          />
        </FormGroup>
      ) : null}
      <h2>{title}</h2>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" stickyHeader={true}>
          {schoolList ? (
            <TableBody>
              <TableRow className="sub-header">
                <TableCell style={{ minWidth: '200px' }}>School</TableCell>
                <TableCell style={{ width: '10%' }}>Low Grade</TableCell>
                <TableCell style={{ width: '10%' }}>High Grade</TableCell>
                <TableCell style={{ width: '20%', minWidth: '200px' }}>
                  Address
                </TableCell>
                <TableCell style={{ width: '15%', minWidth: '120px' }}>
                  Phone
                </TableCell>
                <TableCell style={{ width: '15%', minWidth: '200px' }}>
                  Website
                </TableCell>
              </TableRow>
              {schoolList
                .filter(
                  (school) =>
                    schoolTypeName !== 'Traditional' ||
                    school.IsHomeSchool ||
                    showAllDistrict
                )
                .map((school) => (
                  <TableRow>
                    <TableCell>
                      {school.SchoolName}{' '}
                      {school.IsHomeSchool ? <HomeIcon /> : null}
                    </TableCell>
                    <TableCell>{school.LowGrade}</TableCell>
                    <TableCell>{school.HighGrade}</TableCell>
                    <TableCell>
                      <a
                        href={
                          'https://www.google.com/maps/dir/?api=1&origin=' +
                          serviceAddress.Address.Address +
                          ', ' +
                          (serviceAddress.Address.Secondary
                            ? ' ' + serviceAddress.Address.Secondary
                            : '') +
                          ', ' +
                          serviceAddress.Address.City +
                          ', ' +
                          serviceAddress.Address.State +
                          ' ' +
                          serviceAddress.Address.Zip +
                          '&destination=' +
                          school.Address +
                          ', ' +
                          school.City +
                          ', ' +
                          school.State +
                          ' ' +
                          school.Zip
                        }
                        target="mi_offsite"
                        className="link">
                        {school.Address}
                        <br />
                        {school.City}, {school.State} {school.Zip}
                      </a>
                    </TableCell>
                    <TableCell>
                      <a className="link" href={'tel:' + school.Phone}>
                        {school.Phone}
                      </a>
                    </TableCell>
                    <TableCell>
                      <a
                        className="link"
                        href={school.WebsiteUrl}
                        target="mi_offsite">
                        {school.WebsiteUrl}
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell>
                  No {title.toLowerCase()} found in your area
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}

function SchoolPopup({ serviceAddress, siteConfig, closeFunc }) {
  const [schoolDictionary, setSchoolDictionary] = useState(null);

  useEffect(() => {
    if (serviceAddress && serviceAddress.Address) {
      dataApi.listAllSchool(serviceAddress.Address).then((schoolList) => {
        let _schoolDictionary = {};

        schoolList.map((school) => {
          if (!_schoolDictionary[school.SchoolTypeName]) {
            _schoolDictionary[school.SchoolTypeName] = [];
          }

          _schoolDictionary[school.SchoolTypeName].push(school);
        });

        setSchoolDictionary(_schoolDictionary);
      });
    }
  }, [serviceAddress]);

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="school-popup"
      fullWidth={true}>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        <img src={withPrefix('/images/logo-icon.svg')} className="logo-icon" />
        All Public Schools Near You
      </DialogTitle>
      <DialogContent width="100%">
        {schoolDictionary ? (
          <>
            <SchoolBlock
              title={'Non-Traditional/Specialized Public Schools'}
              schoolList={schoolDictionary['Non-Traditional']}
              serviceAddress={serviceAddress}
              schoolTypeName={'Non-Traditional'}
            />
            <SchoolBlock
              title={'Public Schools Assigned to This Address'}
              schoolList={schoolDictionary['Traditional']}
              serviceAddress={serviceAddress}
              schoolTypeName={'Traditional'}
            />
          </>
        ) : (
          <LinearProgress />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            closeFunc();
          }}
          variant="contained"
          color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolPopup);
