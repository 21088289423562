import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import ProviderLogo from './ProviderLogo';
import OrderButton from './OrderButton';
import OfferDetailsPopup from './OfferDetailsPopup';

import './FeaturedOffer.css';

function FeaturedOffer({ serviceAddress, featuredPlan, onOrderButtonClick }) {
  const [showOfferDetails, setShowOfferDetails] = useState(null);

  return (
    <li
      className={
        'featured-offer' +
        (featuredPlan.offerList.length > 1 ? ' multiple' : '')
      }>
      <div className="content">
        <h4 style={{ backgroundColor: featuredPlan.color }}>
          {featuredPlan.label}
        </h4>

        <h5 className="offer-name">
          {featuredPlan.offerList.length > 1 ? (
            featuredPlan.offerList.map((offer, offerIndex) => (
              <>
                {offerIndex > 0 ? (
                  <>
                    <br /> +{' '}
                  </>
                ) : (
                  ''
                )}
                {offer.DisplayName}
              </>
            ))
          ) : (
            <>
              <strong>{featuredPlan.offerList[0].ProviderName}</strong>{' '}
              {featuredPlan.offerList[0].DisplayName}
            </>
          )}
        </h5>

        <div className="price-container">
          <div className="price">
            <sup>$</sup>
            {featuredPlan.price.toFixed(2).split('.')[0]}
            <sup>.{featuredPlan.price.toFixed(2).split('.')[1]}</sup>
          </div>

          <div className="usage">
            per month - no contract
            <br />
            <div className="plan-details-mobile">
              <Button onClick={() => setShowOfferDetails(true)}>
                view plan details
              </Button>
            </div>
          </div>
        </div>

        <div className={'logo-action'}>
          {featuredPlan.offerList.map((offer, offerIndex) => (
            <ProviderLogo offer={offer} />
          ))}
          <div className="action">
            <Button
              variant="outlined"
              color="secondary"
              className="order-online"
              onClick={(event) => {
                onOrderButtonClick(event);
              }}>
              {!serviceAddress ||
              !serviceAddress.Address ||
              !serviceAddress.Address.Address
                ? 'Select this Plan'
                : 'Sign Up'}{' '}
              <ChevronRightIcon />
            </Button>
          </div>
        </div>

        <h5 className="offer-name-mobile">
          {featuredPlan.offerList.map(
            (offer, offerIndex) =>
              (offerIndex > 0 ? ' + ' : '') + offer.DisplayName
          )}
        </h5>
        <div className="plan-details">
          <Button onClick={() => setShowOfferDetails(true)}>
            View Plan Details
          </Button>
        </div>
      </div>

      {showOfferDetails ? (
        <OfferDetailsPopup
          offer={offer}
          closeFunc={() => setShowOfferDetails(null)}
          selectedUsage={usage}
          usageSetting={usageSetting}
        />
      ) : null}
    </li>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedOffer);
