import * as types from './actionTypes';
import * as userAuthApi from '../../api/userAuthApi';
import * as dataApi from '../../api/dataApi';
import { beginApiCall, apiCallError } from './apiStatusActions';
import showNotification from '../../components/Common/Notification';

export function serviceAddressUpdated(serviceAddress) {
  //For property users, the resident is stored in the session (find selectSampleResident function),
  //so override any resident information with what is stored in the browser
  if (serviceAddress && serviceAddress.Address) {
    const residentJson = dataApi.getSessionStorage('mi-selected-resident');
    if (residentJson) {
      try {
        const resident = JSON.parse(residentJson);
        if (resident) {
          serviceAddress = {
            ...serviceAddress,
            Address: {
              ...serviceAddress.Address,
              ...resident,
              Previous_Address: resident.Previous_Address
                ? resident.Previous_Address
                : serviceAddress.Address.Previous_Address,
              Previous_Secondary: resident.Previous_Secondary
                ? resident.Previous_Secondary
                : serviceAddress.Address.Previous_Secondary,
              Previous_City: resident.Previous_City
                ? resident.Previous_City
                : serviceAddress.Address.Previous_City,
              Previous_State: resident.Previous_State
                ? resident.Previous_State
                : serviceAddress.Address.Previous_State,
              Previous_Zip: resident.Previous_Zip
                ? resident.Previous_Zip
                : serviceAddress.Address.Previous_Zip
            }
          };
        }
      } catch (e) {}
    }
  }

  return { type: types.SERVICE_ADDRESS_UPDATED, serviceAddress };
}

export function serviceAddressUpdatedWebsocket(serviceAddress) {
  return { type: types.SERVICE_ADDRESS_UPDATED_WEBSOCKET, serviceAddress };
}

export function visitorUserInfoUpdated(serviceAddress) {
  return { type: types.VISITOR_USERINFO_UPDATED, serviceAddress };
}

export function serviceAddressOffersUpdated(serviceAddress) {
  return { type: types.SERVICE_ADDRESS_OFFERS_UPDATED, serviceAddress };
}

export function serviceAddressOfferExtractionComplete(apiID) {
  return { type: types.SERVICE_ADDRESS_OFFER_EXTRACTION_COMPLETE, apiID };
}

export function serviceAddressCleared() {
  return { type: types.SERVICE_ADDRESS_CLEARED };
}

export function cartUpdated(cart) {
  console.log('SERVICE_ADDRESS_CART_UPDATED');
  return { type: types.SERVICE_ADDRESS_CART_UPDATED, cart };
}

export function checklistUpdated(serviceAddress, checklist) {
  return {
    type: types.SERVICE_ADDRESS_CHECKLIST_UPDATED,
    serviceAddress,
    checklist
  };
}

export function taskUpdated(section) {
  console.log('taskUpdated', section);
  return {
    type: types.SERVICE_ADDRESS_TASKSTATUS_UPDATED,
    section
  };
}

export function toggleChecklistItemComplete(
  section,
  taskStatusID,
  subSection,
  subSectionCount
) {
  return function (dispatch, getState) {
    const serviceAddress = getState().serviceAddress;
    return dataApi
      .toggleChecklistItemComplete(
        serviceAddress.Address,
        section.ServiceSectionID,
        taskStatusID,
        subSection,
        subSectionCount
      )
      .then((result) => {
        dispatch(
          taskUpdated({
            ...section,
            IsCompleted: taskStatusID !== 4,
            TaskStatusID: result.TaskStatusID,
            TaskStatusName: result.TaskStatusName
          })
        );
      });
  };
}

export function markTaskComplete(service, completed) {
  return {
    type: types.SERVICE_ADDRESS_TASK_COMPLETE,
    service,
    completed: completed ? completed : false
  };
}

export function smartMeterPrompted() {
  return {
    type: types.SERVICE_ADDRESS_SMARTMETER_PROMPTED
  };
}

export function placeListUpdated(placeList) {
  return {
    type: types.SERVICE_ADDRESS_PLACELIST_UPDATED,
    placeList
  };
}

export function trackSmartMeterPrompted() {
  return function (dispatch, getState) {
    dispatch(smartMeterPrompted());
  };
}

export function clearServiceAddress() {
  return function (dispatch, getState) {
    deleteAllCookies();
    dataApi.setSessionStorage('mi-visit', '');
    dispatch(serviceAddressCleared());
  };
}

export function saveOrder(
  address,
  secondary,
  city,
  state,
  zip,
  uniqueAddressId,
  providerID,
  offerID,
  orderStatusID,
  offsiteOrderUrl,
  providerAccountNumber,
  additionalDetails,
  checkoutStep,
  productTypeID,
  offer,
  createOrderOnly,
  loadLatestIfAvailable,
  formMode
) {
  return function (dispatch, getState) {
    return dataApi
      .saveOrder(
        address,
        secondary,
        city,
        state,
        zip,
        uniqueAddressId,
        providerID,
        offerID,
        orderStatusID,
        offsiteOrderUrl,
        providerAccountNumber,
        additionalDetails,
        checkoutStep,
        productTypeID,
        offer,
        createOrderOnly,
        loadLatestIfAvailable,
        formMode
      )
      .then((result) => {
        if (result && result.UpdatedCart) {
          dispatch(cartUpdated(result.UpdatedCart));
        }

        return result;
      });
  };
}

export function updateVisitorUserInfo(serviceAddress, isPreviousAddress) {
  return function (dispatch, getState) {
    return dataApi.saveVisitorUserInfo(serviceAddress).then((result) => {
      dispatch(
        visitorUserInfoUpdated(
          result && result.Address && isPreviousAddress
            ? {
                Address: {
                  Previous_Address: result.Address.Previous_Address,
                  Previous_Secondary: result.Address.Previous_Secondary,
                  Previous_City: result.Address.Previous_City,
                  Previous_State: result.Address.Previous_State,
                  Previous_Zip: result.Address.Previous_Zip
                }
              }
            : { ...serviceAddress }
        )
      );

      return result;
    });
  };
}

export function updatePlaceList(serviceAddress) {
  return function (dispatch, getState) {
    return dataApi.listPlaces(serviceAddress).then((result) => {
      let placesSorted = null;

      if (result) {
        placesSorted = {};
        Object.keys(result).map((field) => {
          placesSorted[field] = dataApi.sortPlaces(
            result[field],
            'CalculatedDistance'
          );
        });
      }

      dispatch(placeListUpdated(placesSorted));

      return result;
    });
  };
}

function deleteAllCookies() {
  var cookies = document.cookie.split(';');
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf('=');
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    name = name.split(' ').join('');
    dataApi.setCookie(name, '', -100);
  }
}
