import React, { useState, useRef, useEffect, version } from 'react';
import { Button, IconButton, TextField, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import Grid from '@mui/material/Grid';

import './index.css';
import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as dataApi from '../../api/dataApi';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const $ = typeof window !== 'undefined' ? window.$ : {};

import { useQueryParam } from 'gatsby-query-params';
import ServiceContainer from '../ServiceContainer';
import ProviderLogo from '..//OfferGrid/ProviderLogo';
import IntroText from '../IntroText';
import TaskMenu from '../TaskMenu';
import BackToTopButton from '../BackToTopButton';

function SettleIn({ actions, serviceAddress, routeOverride }) {
  const route = routeOverride ? routeOverride : dataApi.getCurrentRoute();

  const [providerGrouping, setProviderGrouping] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);

  useEffect(() => {
    if (serviceAddress && serviceAddress.Checklist) {
      let _providerGrouping = {};
      serviceAddress.Checklist.filter(
        (section) => section.Route === '/settle-in'
      ).map((section) =>
        section.ServiceList.map((service) => {
          if (!_providerGrouping[service.ProviderServiceCategoryName]) {
            _providerGrouping[service.ProviderServiceCategoryName] = [];
          }

          _providerGrouping[service.ProviderServiceCategoryName].push(service);
        })
      );

      setProviderGrouping(_providerGrouping);

      const _route = route === '/electric-rates' ? '/connect-utilities' : route;
      setSelectedSection(
        serviceAddress.Checklist.filter(
          (section) => section.Route === _route
        )[0]
      );
    }
  }, [serviceAddress]);

  return providerGrouping && selectedSection ? (
    <>
      <div className={'page-container settle-in-container'}>
        <h1>
          <TaskMenu section={selectedSection} page />
          {selectedSection.Title}
        </h1>

        <IntroText
          trackAs="settle-in"
          text={selectedSection.IntroText}
          mobileText={selectedSection.IntroTextMobile}
        />

        <div className="page settle-in">
          <div className="inner">
            <Grid container spacing={2} justify="center">
              {Object.keys(providerGrouping).map(
                (providerServiceCategoryName, providerServiceCategoryIndex) => (
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="card">
                      <h2>{providerServiceCategoryName}</h2>

                      <div className="service-list-top">
                        <ul>
                          {providerGrouping[providerServiceCategoryName]
                            .filter(
                              (service, serviceIndex) => serviceIndex <= 3
                            )
                            .map((service, serviceIndex) => (
                              <li>
                                <div>
                                  {service.ProviderList.filter(
                                    (provider, providerIndex) =>
                                      providerGrouping[
                                        providerServiceCategoryName
                                      ].length === 1
                                        ? providerIndex <= 6
                                        : providerIndex === 0
                                  ).map((provider) => (
                                    <Button
                                      href={provider.ServiceStartUrl}
                                      target="mi_offsite"
                                      className="provider">
                                      <ProviderLogo offer={provider} />
                                    </Button>
                                  ))}
                                  <Button
                                    onClick={() => {
                                      const section = document.getElementById(
                                        'section_' +
                                          providerServiceCategoryIndex +
                                          '_' +
                                          serviceIndex
                                      );

                                      if (section) {
                                        dataApi.scrollToElementInPage(
                                          section,
                                          -90
                                        );
                                      }
                                    }}
                                    variant="outlined">
                                    {service.ProviderServiceName.length < 17
                                      ? 'Browse '
                                      : ''}{' '}
                                    {service.ProviderServiceName}{' '}
                                    <ChevronRightIcon />
                                  </Button>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </Grid>
                )
              )}
            </Grid>
          </div>

          <div className="service-container">
            <BackToTopButton />
            <ServiceContainer
              innerOnly
              mobileCollapse
              providerGrouping={providerGrouping}
              routeOverride={routeOverride}
            />
          </div>
        </div>
      </div>
    </>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettleIn);
