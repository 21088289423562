import React, { Fragment } from 'react';

import clsx from 'clsx';

import { Paper, List, ListItem, ListItemText } from '@mui/material';

import { connect } from 'react-redux';

import { withPrefix } from 'gatsby-link';

function Footer({ siteConfig }) {
  return (
    <div className="main-footer">
      <ul>
        <li>
          <img
            style={{
              maxWidth: siteConfig.logoMaxWidth
            }}
            className="logo"
            alt={siteConfig.brand}
            src={withPrefix(siteConfig.logo)}
          />
        </li>
        <li className="contact">
          <strong>Contact Us</strong>
          <br />
          Email: <a href={'mailto:' + siteConfig.email}>{siteConfig.email}</a>
          <br />
          Phone: <a href={'tel:' + siteConfig.phone}>{siteConfig.phone}</a>
        </li>
        <li>
          <a href={withPrefix('privacy-policy/')}>Privacy Policy</a>
          <a href={withPrefix('terms/')}>Terms</a>
          <a href={withPrefix('ccpa/')}>California Privacy Act</a>
          <a href={withPrefix('texas-disclosure/')}>Texas Broker Disclosure</a>
        </li>
      </ul>
      <div className="bottom">
        &copy; {new Date().getFullYear()} {siteConfig.copyright}. All rights
        reserved.
      </div>
    </div>
    // <Fragment>
    //   <Paper
    //     square
    //     className={clsx('app-footer text-black-50', {
    //       'app-footer--fixed': false
    //     })}>
    //     <div className="app-footer--inner">
    //       <div className="app-footer--first">
    //         <List dense className="d-flex align-items-center">
    //           <ListItem
    //             className="rounded-sm text-nowrap"
    //             button
    //             component="a"
    //             href={withPrefix('privacy-policy')}
    //             rel="noopener">
    //             <ListItemText primary="Privacy Policy" />
    //           </ListItem>
    //           <ListItem
    //             className="rounded-sm text-nowrap"
    //             button
    //             component="a"
    //             href={withPrefix('terms')}
    //             rel="noopener">
    //             <ListItemText primary="Terms" />
    //           </ListItem>
    //         </List>
    //       </div>
    //       <div className="app-footer--second">
    //         &copy; {new Date().getFullYear()} {siteConfig.copyright} All rights
    //         reserved.
    //       </div>
    //     </div>
    //   </Paper>
    // </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
