import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  LinearProgress,
  IconButton,
  Button,
  Tooltip,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import {
  getResidentOnboardingAudit,
  saveResidentOnboardingAudit
} from '../../api/residentOnboardingApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import ResidentOnboardingFileUpload from '../ResidentOnboarding/ResidentOnboardingFileUpload';

function AuditItem({
  actions,
  residentOnboarding,
  item,
  setShowResidentOnboarding,
  loadOnboardingDashboard
}) {
  const [audit, setAudit] = useState(null);

  useEffect(() => {
    getResidentOnboardingAudit(
      item.ResidentID,
      item.Grouping,
      item.ProviderServiceID,
      item.ResidentFormID
    ).then((_audit) =>
      setAudit(
        _audit
          ? { ..._audit }
          : {
              Grouping: item.Grouping,
              ProviderServiceID: item.ProviderServiceID,
              ResidentFormID: item.ResidentFormID
            }
      )
    );

    if (!residentOnboarding || residentOnboarding.Header !== item.ResidentID) {
      refreshResidentOnboarding();
    }
  }, []);

  function refreshResidentOnboarding() {
    actions.loadResidentOnboarding(item.ResidentID);
  }

  return (
    <div className="resident-onboarding-container">
      <div className="section resident-onboarding">
        <div className="resident-onboarding-item audit">
          <div class="form-header">
            <h3>
              <IconButton
                id={
                  'closeButton-' +
                  item.Grouping +
                  '-' +
                  item.ResidentID +
                  '-' +
                  item.Description
                }
                className="close-button"
                onClick={() => setShowResidentOnboarding(null)}>
                <CloseIcon />
              </IconButton>

              {item.OnboardingColumn}
            </h3>
          </div>

          {audit && residentOnboarding ? (
            <div className="form-content">
              <div className="radio-container">
                <p>
                  <strong>
                    Has this{' '}
                    {item.OnboardingColumn === 'applicant'
                      ? 'applicant'
                      : 'resident'}{' '}
                    already provided{' '}
                    {item.ProviderServiceID === 37
                      ? 'Proof of Coverage'
                      : item.ProviderServiceID
                      ? 'Proof of ' + item.Description
                      : ' this information'}
                    ?
                  </strong>
                </p>

                <FormControl component="fieldset">
                  <RadioGroup
                    value={audit.ResidentOnboardingStatusID}
                    onChange={(event, newValue) => {
                      setAudit({
                        ...audit,
                        ResidentOnboardingStatusID: parseInt(newValue)
                      });
                    }}>
                    <FormControlLabel
                      value={9}
                      className={
                        audit.ResidentOnboardingStatusID === 9 ? 'selected' : ''
                      }
                      control={<Radio color="primary" />}
                      label={'Yes - mark this task as Provided'}
                    />
                    <FormControlLabel
                      value={10}
                      className={
                        audit.ResidentOnboardingStatusID === 10
                          ? 'selected'
                          : ''
                      }
                      control={<Radio color="primary" />}
                      label={'No - mark this task as Not Provided'}
                    />
                  </RadioGroup>
                </FormControl>

                {audit.ResidentOnboardingStatusID ? (
                  <>
                    <TextField
                      fullWidth
                      value={audit.Notes ? audit.Notes : ''}
                      onChange={(event) =>
                        setAudit({ ...audit, Notes: event.target.value })
                      }
                      label={'Optional Additional Notes'}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />

                    <ResidentOnboardingFileUpload
                      residentOnboarding={residentOnboarding}
                      refreshResidentOnboarding={refreshResidentOnboarding}
                      residentFormID={item.ResidentFormID}
                      providerServiceID={item.ProviderServiceID}
                      residentFileTypeID={12}
                      grouping={item.Grouping}
                      label={'Optional Document or Attachment'}
                      residentID={item.ResidentID}
                      optional
                    />
                  </>
                ) : null}
              </div>

              <div className="save-cancel">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={audit.saving}
                  className={audit.saving ? 'disabled' : ''}
                  onClick={() => {
                    setAudit({ ...audit, saving: true });
                    saveResidentOnboardingAudit(item.ResidentID, {
                      ...audit
                    }).then((result) => {
                      refreshResidentOnboarding();
                      loadOnboardingDashboard();
                      setShowResidentOnboarding(null);
                    });
                  }}>
                  {audit.saving ? 'Saving...' : 'Save'}
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setShowResidentOnboarding(null);
                  }}>
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <LinearProgress />
          )}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditItem);
