import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import 'cropperjs/dist/cropper.css'; // Import the CSS for the new cropping library

// Import the new cropping library
import Cropper from 'react-cropper';

function ImageCrop({ title, url, onCrop, closeFunc, fileName }) {
  const cropperRef = useRef(null);
  const [croppedImageData, setCroppedImageData] = useState(null);

  useEffect(() => {
    console.log('croppedImageData', croppedImageData);
  }, [croppedImageData]);

  const onCropClick = () => {
    if (croppedImageData) {
      onCrop(croppedImageData.replace(/^data:image\/(png|jpg);base64,/, ''));
    }
  };

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="image-crop-popup">
      <DialogContent width="100%">
        <Cropper
          ref={cropperRef}
          src={url}
          style={{ height: 400, width: '100%' }}
          // Cropper.js options
          aspectRatio={1} // Set your desired aspect ratio
          guides={false}
          cropBoxMovable={true}
          crop={() => {
            const cropper = cropperRef.current?.cropper;
            var canvas = cropper.getCroppedCanvas();
            var imageData = canvas.toDataURL('image/png');
            setCroppedImageData(imageData);
          }}
        />
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'center' }}>
        <Button
          onClick={() => {
            onCropClick();
          }}
          variant="contained"
          color="secondary">
          Save Cropped Photo
        </Button>
        <Button
          onClick={() => {
            closeFunc();
          }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImageCrop;
