import React from 'react';
import moment from 'moment';

import './index.css';

function ResidentOnboardingHeader({ resident }) {
  return (
    <div className="resident-onboarding-header">
      <div className="left">
        <strong>
          {resident.FirstName} {resident.LastName}
        </strong>
        <br />
        Unit {resident.UnitNumber}
        <br />
        {resident.Address}
        <br />
        {resident.City}, {resident.State} {resident.Zip}
      </div>

      <div className="right">
        {/* <strong>
          Resident ID: <span className="value">{resident.TenantId}</span>
        </strong>
        <br /> */}
        Lease Start Date:{' '}
        <span className="value">
          {moment(resident.DateLeaseStart).format('M/D/YYYY')}
        </span>
        <br />
        Phone:{' '}
        <span className="value">
          <a href={'tel:' + resident.Phone} className="link">
            {resident.Phone}
          </a>
        </span>
        <br />
        Email:{' '}
        <span className="value">
          <a href={'mailto:' + resident.Email} className="link">
            {resident.Email}
          </a>
        </span>
      </div>
    </div>
  );
}

export default ResidentOnboardingHeader;
