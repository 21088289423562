import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import ResidentOnboardingStatusBadge from '../ResidentOnboarding/ResidentOnboardingStatusBadge';
import ResidentOnboardingPopup from '../ResidentOnboarding/ResidentOnboardingPopup';

import './index.css';

export function getResidentOnboardingStatus(
  residentOnboarding,
  section,
  setResidentresidentOnboardingProviderServiceList,
  setResidentOnboardingStatus
) {
  let _residentOnboardingStatus = null;

  if (
    residentOnboarding &&
    section &&
    section.ServiceList &&
    residentOnboarding.ProviderServiceList
  ) {
    if (section.ServiceSectionID === 22) {
      if (residentOnboarding.Checklist) {
        residentOnboarding.Checklist.filter(
          (item) =>
            item.OnboardingSection === 'pending' && !item.ProviderServiceID
        ).map((item) => {
          if (
            !_residentOnboardingStatus ||
            (item.ResidentOnboardingStatusID &&
              item.ResidentOnboardingStatusID <
                _residentOnboardingStatus.ResidentOnboardingStatusID)
          ) {
            _residentOnboardingStatus = { ...item };
          }
        });
      }
    } else {
      let _residentOnboardingProviderServiceList = [];

      section.ServiceList.map((service) => {
        residentOnboarding.Checklist.filter(
          (item) =>
            item.OnboardingSection === 'pending' &&
            item.ProviderServiceID === service.ProviderServiceID
        ).map((item) => {
          _residentOnboardingProviderServiceList.push({ ...item });

          if (
            !_residentOnboardingStatus ||
            item.ResidentOnboardingStatusID <
              _residentOnboardingStatus.ResidentOnboardingStatusID
          ) {
            _residentOnboardingStatus = { ...item };
          }
        });
      });

      if (setResidentresidentOnboardingProviderServiceList) {
        setResidentresidentOnboardingProviderServiceList(
          _residentOnboardingProviderServiceList
        );
      }
    }

    if (setResidentOnboardingStatus) {
      if (_residentOnboardingStatus) {
        setResidentOnboardingStatus(_residentOnboardingStatus);
      } else if (section) {
        switch (
          section.ServiceSectionID //If nothing came back as a required item, assume no action required
        ) {
          case 1: //Connect Your Utilities
          case 4: //Purchase Renters Insurance
            setResidentOnboardingStatus({
              ResidentOnboardingStatusID: 5,
              ResidentOnboardingStatusName: 'No Action Needed'
            });
            break;
        }
      }
    }
  }

  return _residentOnboardingStatus ? _residentOnboardingStatus : {};
}

function TaskMenu({
  actions,
  serviceAddress,
  section,
  checklistProgress,
  setChecklistProgress,
  page,
  residentOnboarding,
  subSection,
  subSectionCount,
  serviceSectionStatus,
  setServiceSectionStatus
}) {
  const [showMenu, setShowMenu] = useState(null);
  const [taskStatus, setTaskStatus] = useState(null);

  const [
    residentOnboardingProviderServiceList,
    setResidentresidentOnboardingProviderServiceList
  ] = useState(null);
  const [residentOnboardingStatus, setResidentOnboardingStatus] =
    useState(null);
  const [showResidentOnboarding, setShowResidentOnboarding] = useState(null);

  useEffect(() => {
    if (
      serviceAddress &&
      serviceAddress.Address &&
      serviceAddress.Address.ResidentID
    ) {
      if (
        !residentOnboarding ||
        !residentOnboarding.Header ||
        residentOnboarding.Header.ResidentID !==
          serviceAddress.Address.ResidentID
      ) {
        actions.loadResidentOnboarding(serviceAddress.Address.ResidentID);
      } else if (residentOnboarding) {
        getResidentOnboardingStatus(
          residentOnboarding,
          section,
          setResidentresidentOnboardingProviderServiceList,
          setResidentOnboardingStatus
        );
      }
    } else if (residentOnboarding) {
      setResidentOnboardingStatus(null);
      setResidentresidentOnboardingProviderServiceList(null);
      actions.clearResidentOnboarding();
    } else {
      setResidentOnboardingStatus(null);
      setResidentresidentOnboardingProviderServiceList(null);
    }

    setTaskStatus({
      isCompleted: section
        ? subSection && section.TaskStatusID !== 1
          ? section.SubSectionsComplete &&
            section.SubSectionsComplete.indexOf(subSection) !== -1
            ? true
            : false
          : section.IsCompleted
        : false,
      taskStatusName: section
        ? subSection && section.TaskStatusID !== 1
          ? section.SubSectionsComplete &&
            section.SubSectionsComplete.indexOf(subSection) !== -1
            ? 'Completed'
            : 'Not Completed'
          : section.TaskStatusName
          ? section.TaskStatusName
          : 'Not Completed'
        : 'Not Completed'
    });
  }, [serviceAddress]);

  useEffect(() => {
    if (setServiceSectionStatus && serviceSectionStatus && taskStatus) {
      setServiceSectionStatus({
        ...serviceSectionStatus,
        [section.ServiceSectionID]: taskStatus
      });
    }
  }, [taskStatus]);

  useEffect(() => {
    getResidentOnboardingStatus(
      residentOnboarding,
      section,
      setResidentresidentOnboardingProviderServiceList,
      setResidentOnboardingStatus
    );
  }, [residentOnboarding, section]);

  function updateTaskStatus(taskStatusID) {
    actions.toggleChecklistItemComplete(
      section,
      taskStatusID,
      subSection,
      subSectionCount
    );

    setTaskStatus({
      taskStatusName:
        taskStatusID === 1
          ? 'Completed'
          : taskStatusID === 2
          ? 'I already did this'
          : taskStatusID === 3
          ? 'Not needed'
          : 'Not Completed',
      isCompleted: taskStatusID !== 4
    });

    if (setChecklistProgress && checklistProgress) {
      setChecklistProgress({
        ...checklistProgress,
        [section.ServiceSectionID]: taskStatusID !== 4
      });
    }

    setShowMenu(null);
  }

  return serviceAddress ? (
    <div className="task-menu">
      <label>
        {residentOnboardingStatus && residentOnboarding ? (
          <Button
            className="badge-button"
            onClick={() => setShowResidentOnboarding(true)}>
            <ResidentOnboardingStatusBadge status={residentOnboardingStatus} />
          </Button>
        ) : taskStatus ? (
          <Button
            className="badge-button"
            onClick={() => {
              if (taskStatus && taskStatus.isCompleted) {
                updateTaskStatus(4);
              } else {
                updateTaskStatus(1);
              }
            }}>
            <div
              className={
                'badge resident-onboarding-badge outlined ' +
                (taskStatus && taskStatus.isCompleted
                  ? 'complete'
                  : 'incomplete')
              }>
              {taskStatus.taskStatusName}
            </div>
          </Button>
        ) : null}
        {/* <IconButton
          onClick={(event) => {
            setShowMenu(event.currentTarget);
            event.stopPropagation();
          }}>
          <MoreVertIcon />
        </IconButton> */}
      </label>

      {taskStatus ? (
        <Popover
          id={showMenu ? 'task-menu-' + section.ServiceSectionID : undefined}
          className="task-menu-items"
          open={Boolean(showMenu)}
          anchorEl={showMenu}
          onClose={() => setShowMenu(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}>
          {residentOnboardingStatus ? (
            <ul>
              <li>
                <Button
                  onClick={() => {
                    setShowResidentOnboarding(true);
                    setShowMenu(null);
                  }}>
                  {residentOnboardingStatus.ResidentOnboardingStatusID === 1
                    ? 'Complete this task'
                    : 'Edit response'}
                </Button>
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <Button onClick={() => updateTaskStatus(1)}>
                  Mark as completed
                </Button>
              </li>
              <li>
                <Button onClick={() => updateTaskStatus(2)}>
                  I already did this
                </Button>
              </li>
              <li>
                <Button onClick={() => updateTaskStatus(3)}>Not needed</Button>
              </li>

              {taskStatus.isCompleted ? (
                <li>
                  <Button onClick={() => updateTaskStatus(4)}>
                    Mark as incomplete
                  </Button>
                </li>
              ) : null}
            </ul>
          )}
        </Popover>
      ) : null}

      {showResidentOnboarding ? (
        <ResidentOnboardingPopup
          otherTasks={section.ServiceSectionID === 22}
          providerServiceList={residentOnboardingProviderServiceList}
          closeFunc={() => setShowResidentOnboarding(false)}
        />
      ) : null}
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      toggleChecklistItemComplete: bindActionCreators(
        serviceAddressActions.toggleChecklistItemComplete,
        dispatch
      ),
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      ),
      clearResidentOnboarding: bindActionCreators(
        residentOnboardingActions.clearResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskMenu);
