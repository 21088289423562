import { Button } from '@mui/material';
import React, { useState } from 'react';

import './index.css';

function ToDoCheckbox({ uniqueId, labelText, color, checked, setChecked }) {
  return (
    <>
      <svg
        viewBox="0 0 0 0"
        style={{ position: 'absolute', zIndex: -1, opacity: 0 }}>
        <defs>
          <linearGradient
            id={'boxGradient' + uniqueId}
            gradientUnits="userSpaceOnUse"
            x1="0"
            y1="0"
            x2="25"
            y2="25">
            <stop offset="0%" stop-color={color} />
            <stop offset="100%" stop-color={color} />
          </linearGradient>

          <linearGradient id={'lineGradient' + uniqueId}>
            <stop offset="0%" stop-color={color} />
            <stop offset="100%" stop-color={color} />
          </linearGradient>

          <path
            id={'todo__line' + uniqueId}
            stroke={'url(#boxGradient' + uniqueId + ')'}
            d="M21 12.3h168v0.1z"></path>
          <path
            id={'todo__box' + uniqueId}
            stroke={'url(#boxGradient' + uniqueId + ')'}
            d="M21 12.7v5c0 1.3-1 2.3-2.3 2.3H8.3C7 20 6 19 6 17.7V7.3C6 6 7 5 8.3 5h10.4C20 5 21 6 21 7.3v5.4"></path>
          <path
            id={'todo__check' + uniqueId}
            stroke={'url(#boxGradient' + uniqueId + ')'}
            d="M10 13l2 2 5-5"></path>
          <circle
            id={'todo__circle' + uniqueId}
            cx="13.5"
            cy="12.5"
            r="10"></circle>
        </defs>
      </svg>

      <div className={'todo' + (checked ? ' checked' : '')}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 200 25"
          class="todo__icon"
          style={{ stroke: color }}>
          <use
            href={'#todo__line' + uniqueId}
            class="todo__line"
            style={{ stroke: color }}></use>
          <use
            href={'#todo__box' + uniqueId}
            class="todo__box"
            style={{ stroke: color }}></use>
          <use
            href={'#todo__check' + uniqueId}
            class="todo__check"
            style={{ stroke: color }}></use>
          <use
            href={'#todo__circle' + uniqueId}
            class="todo__circle"
            style={{ stroke: color }}></use>
        </svg>

        <div class="todo__text" style={{ color: color }}>
          {labelText}
        </div>

        <Button onClick={setChecked}></Button>
      </div>
    </>
  );
}

export default ToDoCheckbox;
