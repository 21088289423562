import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import RadioOption from './RadioOption';
import { Alert } from '@mui/material';

export default function ProofOfServiceOption({
  setProviderServiceValue,
  providerService,
  serviceProfile,
  alreadyCompleted,
  authUser,
  siteConfig
}) {
  const [showProofOfServiceOptions, setShowProofOfServiceOptions] =
    useState(null);
  const [adminEdit, setAdminEdit] = useState(null);

  function proofLabel() {
    return providerService.ProviderServiceID === 37 ? 'Coverage' : 'Service';
  }
  return (
    <RadioOption
      setProviderServiceValue={setProviderServiceValue}
      serviceProfile={serviceProfile}
      providerService={providerService}
      field={'IsServiceProofRequired'}
      disabled={!providerService.IsServiceRequired}
      question={
        " Do residents need to show 'Proof of " +
        proofLabel() +
        "' for " +
        providerService.ProviderServiceName.split('(')[0] +
        ' prior to moving in?'
      }>
      <div
        className={
          'sub-option' +
          (!providerService.IsServiceProofRequired ? ' disabled' : '')
        }>
        <a
          className="link"
          onClick={() =>
            setShowProofOfServiceOptions(!showProofOfServiceOptions)
          }>
          {showProofOfServiceOptions ? 'Hide' : 'View'} Proof of {proofLabel()}{' '}
          Settings{' '}
          {showProofOfServiceOptions ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </a>
        {showProofOfServiceOptions ? (
          <>
            <Alert severity="warning" style={{ marginTop: '20px' }}>
              {authUser && authUser.User && authUser.User.IsAdmin ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  style={{ float: 'right', marginBottom: '10px' }}
                  onClick={() => setAdminEdit(!adminEdit)}>
                  {adminEdit ? 'Stop Editing' : 'Admin Edit'}
                </Button>
              ) : null}
              The selections below were set by default to be consistent across
              all properties. If you need to make a change to these settings,
              please contact your {siteConfig.brand} account manager at{' '}
              <a href={'tel:' + siteConfig.phone} className="link">
                {siteConfig.phone}
              </a>{' '}
              or email{' '}
              <a href={'mailto:' + siteConfig.email} className="link">
                {siteConfig.email}
              </a>
              .
            </Alert>

            <div
              className={!adminEdit ? 'disabled' : ''}
              style={{ marginTop: '20px' }}>
              <p>Proof of {proofLabel()} delivery method:</p>

              <RadioGroup
                value={
                  providerService.IsServiceProofDeliver
                    ? 'IsServiceProofDeliver'
                    : providerService.IsServiceProofUpload
                    ? 'IsServiceProofUpload'
                    : providerService.IsServiceProofUseUrl
                    ? 'IsServiceProofUseUrl'
                    : null
                }
                onChange={(event) =>
                  setProviderServiceValue(providerService, {
                    IsServiceProofDeliver: false,
                    IsServiceProofUpload: false,
                    IsServiceProofUseUrl: false,
                    [event.target.value]: true
                  })
                }>
                {serviceProfile &&
                serviceProfile.Header &&
                serviceProfile.Header.UseResidentDashboard ? (
                  <>
                    <FormControlLabel
                      value={'IsServiceProofUpload'}
                      control={
                        <Radio
                          color="primary"
                          disabled={!providerService.IsServiceProofRequired}
                        />
                      }
                      label={
                        'Upload Proof of ' +
                        proofLabel() +
                        ' to the MovingIN dashboard'
                      }
                    />

                    {providerService.IsServiceProofUpload ? (
                      <div className="section proof">
                        <p>
                          Select the items below that are needed to show 'Proof
                          of {proofLabel()}'
                        </p>

                        <div className="form-content">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  providerService.ServiceProofIncludeProviderName
                                }
                                onChange={(event) =>
                                  setProviderServiceValue(
                                    providerService,
                                    {
                                      ServiceProofIncludeProviderName:
                                        event.target.checked
                                    },
                                    true
                                  )
                                }
                              />
                            }
                            label={
                              <div>
                                {providerService.ProviderServiceName.split(
                                  ' (Preferred)'
                                ).join('')}{' '}
                                Provider
                              </div>
                            }
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  providerService.ServiceProofIncludeAccountNumber
                                }
                                onChange={(event) =>
                                  setProviderServiceValue(
                                    providerService,
                                    {
                                      ServiceProofIncludeAccountNumber:
                                        event.target.checked
                                    },
                                    true
                                  )
                                }
                              />
                            }
                            label={<div>Account Number</div>}
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  providerService.ServiceProofIncludeStartDate
                                }
                                onChange={(event) =>
                                  setProviderServiceValue(
                                    providerService,
                                    {
                                      ServiceProofIncludeStartDate:
                                        event.target.checked
                                    },
                                    true
                                  )
                                }
                              />
                            }
                            label={
                              providerService.ProviderServiceID === 37 ? (
                                <div>Policy Start Date</div>
                              ) : (
                                <div>Service Start Date</div>
                              )
                            }
                          />

                          {providerService.ProviderServiceID === 37 ? (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    providerService.ServiceProofIncludeEndDate
                                  }
                                  onChange={(event) =>
                                    setProviderServiceValue(
                                      providerService,
                                      {
                                        ServiceProofIncludeEndDate:
                                          event.target.checked
                                      },
                                      true
                                    )
                                  }
                                />
                              }
                              label={<div>Policy End Date</div>}
                            />
                          ) : null}

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  providerService.ServiceProofIncludeDocUpload
                                }
                                onChange={(event) =>
                                  setProviderServiceValue(
                                    providerService,
                                    {
                                      ServiceProofIncludeDocUpload:
                                        event.target.checked
                                    },
                                    true
                                  )
                                }
                              />
                            }
                            label={
                              providerService.ProviderServiceID === 37 ? (
                                <div>Proof of Coverage Document</div>
                              ) : (
                                <div>Proof of Service Document</div>
                              )
                            }
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  providerService.ServiceProofAlt1Checked ||
                                  providerService.ServiceProofAlt1
                                }
                                onChange={(event) =>
                                  setProviderServiceValue(
                                    providerService,
                                    {
                                      ServiceProofAlt1: event.target.checked
                                        ? providerService.ServiceProofAlt1
                                        : null,
                                      ServiceProofAlt1Checked:
                                        event.target.checked
                                    },
                                    true
                                  )
                                }
                              />
                            }
                            label={
                              <div>
                                <TextField
                                  value={providerService.ServiceProofAlt1}
                                  disabled={
                                    !providerService.ServiceProofAlt1Checked &&
                                    !providerService.ServiceProofAlt1
                                  }
                                  variant="outlined"
                                  fullWidth
                                  label="Other"
                                  onChange={(event) =>
                                    setProviderServiceValue(
                                      providerService,
                                      {
                                        ServiceProofAlt1: event.target.value
                                      },
                                      true
                                    )
                                  }
                                />
                              </div>
                            }
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={providerService.CheckboxInclude}
                                onChange={(event) =>
                                  setProviderServiceValue(
                                    providerService,
                                    {
                                      CheckboxInclude: event.target.checked
                                    },
                                    true
                                  )
                                }
                              />
                            }
                            label={
                              <div>
                                {'Resident Acknowledgment of ' +
                                  (providerService.ProviderServiceID === 37
                                    ? 'Insurance'
                                    : providerService.ProviderServiceName +
                                      ' Service') +
                                  ' Requirements'}
                                <br />
                                <TextField
                                  multiline
                                  variant="outlined"
                                  label="Acknowledgment Text"
                                  disabled={!providerService.CheckboxInclude}
                                  fullWidth
                                  style={{
                                    marginTop: '20px',
                                    width: '400px'
                                  }}
                                  value={providerService.CheckboxText}
                                  onChange={(event) =>
                                    setProviderServiceValue(
                                      providerService,
                                      {
                                        CheckboxText: event.target.value
                                      },
                                      true
                                    )
                                  }
                                />
                              </div>
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    <FormControlLabel
                      value={'IsServiceProofUseUrl'}
                      control={
                        <Radio
                          color="primary"
                          disabled={!providerService.IsServiceProofRequired}
                        />
                      }
                      label={
                        'Deliver Proof of ' +
                        proofLabel() +
                        ' to our Resident Portal at:'
                      }
                    />
                    <TextField
                      disabled={
                        !providerService.IsServiceProofUseUrl ||
                        !providerService.IsServiceProofRequired
                      }
                      label={'Paste your Resident Portal address (URL) here'}
                      value={providerService.ServiceProofUrl}
                      onChange={(event) =>
                        setProviderServiceValue(providerService, {
                          ServiceProofUrl: event.target.value
                        })
                      }
                      placeholder={'https://'}
                    />
                    <FormControlLabel
                      value={'IsServiceProofDeliver'}
                      control={
                        <Radio
                          color="primary"
                          disabled={!providerService.IsServiceProofRequired}
                        />
                      }
                      label={
                        'Deliver Proof of ' +
                        proofLabel() +
                        ' to our Leasing Office'
                      }
                    />
                  </>
                )}
              </RadioGroup>
            </div>
          </>
        ) : null}
      </div>
    </RadioOption>
  );
}
