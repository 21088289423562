import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import moment from 'moment';

import { LinearProgress, IconButton, Button, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import RefreshIcon from '@mui/icons-material/Refresh';
import OutputIcon from '@mui/icons-material/Output';
import {
  listResidentOnboarding,
  listResidentMessage
} from '../../api/propertyProfileApi';

import { formatPhone } from '../../api/dataApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import ResidentOnboardingStatusBadge, {
  doesItemQualify
} from '../ResidentOnboarding/ResidentOnboardingStatusBadge';
import ResidentOnboarding from '../ResidentOnboarding';
import SendResidentMessage from './SendResidentMessage';
import ResidentFormReport from '../ResidentForm/ResidentFormReport';
import MoveOutResident from './MoveOutResident';
import { ApplicantGroup } from '../ApplicantTasks/ApplicantGroupPopup';
import CustomerEventCalendar from '../CustomerEventCalendar';
import GeneralPopup from '../GeneralPopup';
import UserIcon from '../UserIcon';
import AuditItem from './AuditItem';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditResidentPopup from './EditResident';

import { convertStringToDateTime } from '../CustomerEventCalendar';

export function residentPassesSearch(resident, _searchString) {
  return (
    (resident.UnitNumber &&
      resident.UnitNumber.toLowerCase() === _searchString) ||
    (resident.FirstName &&
      resident.FirstName.toLowerCase().indexOf(_searchString) !== -1) ||
    (resident.LastName &&
      resident.LastName.toLowerCase().indexOf(_searchString) !== -1) ||
    (resident.FirstName &&
      resident.LastName &&
      (
        resident.FirstName.toLowerCase() +
        ' ' +
        resident.LastName.toLowerCase()
      ).indexOf(_searchString) !== -1) ||
    (resident.DateLeaseStart &&
      moment(resident.DateLeaseStart).format('M/D/YYYY') === _searchString)
  );
}

import './index.css';
import ResidentFormPopup from '../ResidentForm/ResidentFormPopup';

export function getExportUrl(profileKey, residentID, item, invitation, open) {
  let exportPath =
    process.env.GATSBY_EXPORT_URL +
    'docs/' +
    (item.ResidentFormID === 3
      ? 'ResidentIDCompare'
      : (item.ProviderServiceList &&
          item.ProviderServiceList.length &&
          item.ProviderServiceList.map) ||
        item.ProviderServiceID
      ? 'service'
      : item.Grouping) +
    '?property=' +
    profileKey +
    '&resident=' +
    (residentID ? residentID : '0') +
    (item.OccupantID ? '&occupantID=' + item.OccupantID : '') +
    (item.ProviderServiceList &&
    item.ProviderServiceList.length &&
    item.ProviderServiceList.map
      ? '&providerService=' +
        item.ProviderServiceList.map(
          (providerService) => providerService.ProviderServiceID
        ).join(',')
      : '') +
    (item.ProviderServiceID
      ? '&providerService=' + item.ProviderServiceID
      : '') +
    (item.ResidentFormID
      ? '&residentFormID=' + item.ResidentFormID + '&signer=All'
      : '') +
    (item.ResidentPetID && !open
      ? '&residentPetID=' + item.ResidentPetID
      : '') +
    (item.ResidentVehicleID && !open
      ? '&residentVehicleID=' + item.ResidentVehicleID
      : '') +
    (item.ExportFileName ? '&fileName=' + item.ExportFileName : '') +
    (invitation ? '&invitation=' + invitation : '') +
    (open ? '&open=true' : '');

  return exportPath;
}

export function OnboardingDashboardRow({
  resident,
  header,
  showResidentOnboarding,
  setShowResidentOnboarding,
  walkthroughOnly,
  profileKey,
  docExportOnly,
  onboardingSection,
  activeRoute,
  loadResidentOnboarding,
  residentOnboarding,
  residentStatus,
  loadOnboardingDashboard,
  setShowCalendar,
  showMessageHistory,
  setShowMessageHistory,
  setEditResident
}) {
  const showTimeout = useRef();
  const [showDocumentExport, setShowDocumentExport] = useState(false);
  const [docExportOpen, setDocExportOpen] = useState(false);
  const [messageList, setMessageList] = useState(null);
  const [sendNewMessage, setSendNewMessage] = useState(null);
  const [loadingMessages, setLoadingMessages] = useState(null);
  const [documentExportList, setDocumentExportList] = useState(null);
  const [showResidentForm, setShowResidentForm] = useState(null);
  const [showResidentFormPopup, setShowResidentFormPopup] = useState(null);
  const [exportInProgress, setExportInProgress] = useState(null);

  useEffect(() => {
    return () => {
      if (showTimeout.current) {
        clearTimeout(showTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    window.addEventListener('blur', () => {
      setExportInProgress(null);
    });

    return () => {
      window.removeEventListener('blur', () => {
        setExportInProgress(null);
      });
    };
  }, []);

  useEffect(() => {
    setShowDocumentExport(docExportOnly);
  }, [docExportOnly]);

  useEffect(() => {
    setTimeout(() => setDocExportOpen(showDocumentExport), 0);

    if (showDocumentExport) {
      setShowMessageHistory(null);
    }
  }, [showDocumentExport]);

  useEffect(() => {
    if (showMessageHistory === resident.ResidentID) {
      setShowDocumentExport(null);
      setShowResidentOnboarding(null);
      setShowResidentForm(null);
      //if (!messageList) {
      loadResidentOnboarding(resident.ResidentID);
      loadMessageList();
      //}
    }
  }, [showMessageHistory]);

  useEffect(() => {
    if (showResidentOnboarding) {
      setShowDocumentExport(null);
      setShowMessageHistory(null);
      setShowResidentForm(null);
    }
  }, [showResidentOnboarding]);

  useEffect(() => {
    setShowDocumentExport(null);
    setShowMessageHistory(null);
    setShowResidentForm(null);
  }, [activeRoute]);

  useEffect(() => {
    let _documentExportDictionary = {};

    if (resident && resident.data) {
      Object.keys(resident.data).map((column) => {
        const data = resident.data[column];
        if (!_documentExportDictionary[data.OnboardingSection]) {
          _documentExportDictionary[data.OnboardingSection] = [];
        }

        _documentExportDictionary[data.OnboardingSection].push({
          ...data,
          ItemList: data.ItemList.map((item) => {
            return {
              ...item,
              exportPath: getExportUrl(profileKey, resident.ResidentID, item)
            };
          })
        });
      });
    }

    let _documentExportList = [];

    if (_documentExportDictionary.applicant) {
      _documentExportList.push({
        onboardingSection: 'applicant',
        title: 'Applicant Documents',
        data: _documentExportDictionary.applicant
      });
    }

    if (_documentExportDictionary.pending) {
      _documentExportList.push({
        onboardingSection: 'pending',
        title: 'Move-in Documents',
        data: _documentExportDictionary.pending
      });
    }

    if (_documentExportDictionary.notice) {
      _documentExportList.push({
        onboardingSection: 'notice',
        title: 'Move-out Documents',
        data: _documentExportDictionary.notice
      });
    }

    setDocumentExportList(_documentExportList);
  }, [resident]);

  function loadMessageList() {
    setLoadingMessages(true);
    listResidentMessage(profileKey, resident.ResidentID).then(
      (_messageList) => {
        setMessageList(_messageList);
        setLoadingMessages(false);
      }
    );
  }

  return (
    <>
      <div
        className={
          'row' +
          (header ? ' header' : '') +
          (showResidentOnboarding &&
          showResidentOnboarding.resident &&
          showResidentOnboarding.resident.ResidentID === resident.ResidentID
            ? ' open '
            : '') +
          (' ' + activeRoute.split('/').join(''))
        }>
        <div className="cell agent">
          {header ? (
            'Agent'
          ) : resident.AgentName ? (
            <UserIcon
              user={{
                FirstName: resident.AgentName,
                ColorCode: resident.AgentColorCode
              }}
            />
          ) : null}
        </div>

        {activeRoute === '/property-events' ? (
          <>
            <div className="cell event-date">
              {header
                ? residentStatus === 'Move-ins'
                  ? 'Move-in Date'
                  : 'Move-out Date'
                : residentStatus === 'Move-ins'
                ? moment(resident.DateLeaseStart).format('dddd MMMM D')
                : moment(resident.DateMoveOut).format('dddd MMMM D')}
            </div>
          </>
        ) : activeRoute === '/property-notice' ? (
          <>
            <div className="cell">
              {header
                ? 'Lease End Date'
                : moment(resident.DateLeaseRenewal).format('M/D/YYYY')}
            </div>
            <div className="cell">
              {header
                ? 'Move-out Date'
                : resident.DateMoveOut
                ? moment(resident.DateMoveOut).format('M/D/YYYY')
                : ''}
            </div>
          </>
        ) : activeRoute === '/property-applicants' ? (
          <>
            <div className="cell">
              {header
                ? 'Date Applied'
                : moment(resident.DateApplied).format('M/D/YYYY')}
            </div>
            <div className="cell">
              {header
                ? 'Move-in Date'
                : moment(resident.DateLeaseStart).format('M/D/YYYY')}
            </div>
          </>
        ) : (
          <div className="cell">
            {header
              ? 'Move-in Date'
              : moment(resident.DateLeaseStart).format('M/D/YYYY')}
          </div>
        )}

        <div className="cell">
          {header ? 'Unit Number' : resident.UnitNumber}
        </div>
        <div className="cell button name">
          {
            header ? (
              'Resident Name'
            ) : // <Tooltip
            //   title={
            //     <>
            //       <strong>
            //         {resident.FirstName} {resident.LastName}
            //       </strong>
            //       <br />
            //       Email:{' '}
            //       {resident.Email ? (
            //         <a href={'mailto:' + resident.Email}>{resident.Email}</a>
            //       ) : (
            //         'Not on File'
            //       )}
            //       <br />
            //       Phone:{' '}
            //       {resident.Phone ? (
            //         <a href={'tel:' + resident.Phone}>{resident.Phone}</a>
            //       ) : (
            //         'Not on File'
            //       )}
            //     </>
            //   }>
            resident.ResidentID ? (
              <a
                className="link"
                onClick={() =>
                  setShowMessageHistory(
                    showMessageHistory === resident.ResidentID
                      ? null
                      : resident.ResidentID
                  )
                }>
                {' '}
                {resident.FirstName} {resident.LastName}
              </a>
            ) : residentStatus === 'Move-ins' ? (
              'No move-ins'
            ) : (
              'No move-outs'
            )

            // </Tooltip>
          }

          {showMessageHistory === resident.ResidentID &&
          !showResidentOnboarding ? (
            <>
              <div className="marker">
                <ArrowDropUpIcon />
              </div>
              <div className="marker overlap">
                <ArrowDropUpIcon />
              </div>
            </>
          ) : null}
        </div>
        {activeRoute === '/property-events' ? (
          <>
            {residentStatus === 'Move-ins' ? (
              <div className="cell reservation">
                {header
                  ? 'Key Pick-up Appointment'
                  : resident.otherItemList && resident.otherItemList.length
                  ? resident.otherItemList
                      .filter(
                        (_item) =>
                          _item.OnboardingSection === 'pending' &&
                          _item.CalendarType === 'keys' &&
                          _item.CalendarDate &&
                          moment(_item.CalendarDate).isSame(
                            moment(resident.DateLeaseStart)
                          ) &&
                          _item.CalendarStartTime
                      )
                      .map((_item, _itemIndex) => (
                        <>
                          {_itemIndex ? <br /> : null}
                          <a
                            className="link"
                            onClick={() => {
                              setShowCalendar({
                                CalendarID: _item.CalendarID,
                                resident: { ...resident }
                              });

                              // setShowResidentOnboarding({
                              //   resident: { ...resident },
                              //   task: { ..._item },
                              //   otherTasks: _item.Grouping,
                              //   description: _item.Description
                              // });
                            }}>
                            {_item.CalendarStartTime}
                          </a>
                        </>
                      ))
                  : null}
              </div>
            ) : (
              <div className="cell reservation">
                {/* {header
                  ? 'Move-out Appointment'
                  : resident.otherItemList && resident.otherItemList.length
                  ? resident.otherItemList
                      .filter(
                        (_item) =>
                          _item.OnboardingSection === 'pending' && //TODO: Ask Bud what appointment is for move-outs
                          _item.CalendarType === 'keys' &&
                          _item.CalendarDate &&
                          moment(_item.CalendarDate).isSame(
                            moment(resident.DateLeaseStart)
                          ) &&
                          _item.CalendarStartTime
                      )
                      .map((_item, _itemIndex) => (
                        <>
                          {_itemIndex ? <br /> : null}
                          {_item.CalendarStartTime}
                        </>
                      ))
                  : null} */}
              </div>
            )}

            <div className="cell outstanding">
              {header
                ? 'Outstanding Item(s)'
                : resident.data
                ? Object.keys(resident.data)
                    .filter(
                      (description) =>
                        resident.data[description].OnboardingSection ===
                          (residentStatus === 'Move-ins'
                            ? 'pending'
                            : 'notice') &&
                        resident.data[description].ResidentOnboardingStatusID <
                          3 &&
                        (resident.data[description].Grouping !==
                          'pet-interview' ||
                          resident.data[description]
                            .ResidentOnboardingStatusID === 2)
                    )
                    .map((description, index) => (
                      <>
                        <a
                          className={
                            'link ' +
                            (resident.data[description]
                              .ResidentOnboardingStatusID === 1
                              ? 'resident'
                              : 'property')
                          }
                          onClick={() =>
                            setShowResidentOnboarding({
                              resident: { ...resident },
                              task: { ...resident.data[description] },
                              otherTasks: resident.data[description].Grouping,
                              description
                            })
                          }>
                          {description}
                        </a>
                      </>
                    ))
                : null}
            </div>
          </>
        ) : (
          <>
            {/* <div className="cell">
              {header ? (
                walkthroughOnly ? (
                  'Submitted'
                ) : (
                  'Request Sent'
                )
              ) : !resident.IsResidentOnboarding &&
                activeRoute !== '/property-notice' ? (
                ''
              ) : walkthroughOnly ? (
                resident.data['Move-in Condition Form'] &&
                resident.data['Move-in Condition Form'].DateLastUpdated ? (
                  moment(
                    resident.data['Move-in Condition Form'].DateLastUpdated
                  ).format('M/D/YY')
                ) : (
                  ''
                )
              ) : (
                <>
                  <IconButton
                    style={{ zIndex: 4 }}
                    onClick={() => setShowMessageHistory(!showMessageHistory)}>
                    <MailOutlineIcon className="message-icon" />
                  </IconButton>
                  {showMessageHistory && !showResidentOnboarding ? (
                    <>
                      <div className="marker offset">
                        <ArrowDropUpIcon />
                      </div>
                      <div className="marker offset overlap">
                        <ArrowDropUpIcon />
                      </div>
                    </>
                  ) : null}
                </>
              )}
            </div> */}

            {Object.keys(resident.data)
              .filter(
                (column) =>
                  resident.data[column].OnboardingSection === onboardingSection
              )
              .map((column) => (
                <div
                  className={'cell button'}
                  style={{
                    width:
                      (100 /
                        Object.keys(resident.data).filter(
                          (column) =>
                            resident.data[column].OnboardingSection ===
                            onboardingSection
                        ).length) *
                        (activeRoute === '/property-notice' ||
                        activeRoute === '/property-applicants'
                          ? 0.56
                          : 0.64) +
                      '%'
                  }}>
                  {column !== 'null' ? (
                    <>
                      {header ? (
                        !walkthroughOnly ||
                        column === 'Move-in Condition Form' ? (
                          column
                        ) : (
                          ''
                        )
                      ) : (
                        <>
                          {(!walkthroughOnly ||
                            resident.data[column].Grouping === 'walkthrough') &&
                          resident.data[column].ResidentOnboardingStatusID !==
                            5 &&
                          (resident.data[column].Grouping !== 'pet-interview' ||
                            resident.data[column].ResidentOnboardingStatusID ===
                              2 ||
                            resident.data[column].ResidentOnboardingStatusID ===
                              7 ||
                            resident.data[column].ResidentOnboardingStatusID ===
                              8) ? (
                            <ResidentOnboardingStatusBadge
                              status={{ ...resident, ...resident.data[column] }}
                              button
                              isProperty
                              onClick={(event, status) => {
                                if (showTimeout.current) {
                                  clearTimeout(showTimeout.current);
                                }

                                setShowResidentOnboarding(null);

                                //Only open if it wasn't already open (even though we're setting it to null above, the state hasn't updated yet so we can still test it)
                                if (
                                  !showResidentOnboarding ||
                                  !showResidentOnboarding.resident ||
                                  showResidentOnboarding.resident.ResidentID !==
                                    resident.ResidentID ||
                                  showResidentOnboarding.column !== column
                                ) {
                                  showTimeout.current = setTimeout(
                                    () =>
                                      setShowResidentOnboarding({
                                        resident: { ...resident },
                                        task: { ...resident.data[column] },
                                        otherTasks:
                                          resident.data[column].Grouping,
                                        column
                                      }),
                                    100
                                  );
                                }
                              }}
                            />
                          ) : null}

                          {showResidentOnboarding &&
                          showResidentOnboarding.resident.ResidentID ==
                            resident.ResidentID &&
                          showResidentOnboarding.column === column ? (
                            <>
                              <div className="marker">
                                <ArrowDropUpIcon />
                              </div>
                              <div className="marker overlap">
                                <ArrowDropUpIcon />
                              </div>
                            </>
                          ) : null}
                        </>
                      )}
                    </>
                  ) : null}
                </div>
              ))}
          </>
        )}
        {activeRoute !== '/property-events' ? (
          <div className="cell">
            {
              header ? (
                'Document Export'
              ) : (
                // resident.IsResidentOnboarding ?
                <>
                  <IconButton
                    onClick={() => {
                      setShowResidentOnboarding(null);
                      setShowDocumentExport(
                        showDocumentExport ? null : resident
                      );
                    }}
                    style={{ zIndex: 4 }}>
                    <DownloadIcon />
                  </IconButton>
                  {showDocumentExport && !showResidentOnboarding ? (
                    <>
                      <div className="marker offset">
                        <ArrowDropUpIcon />
                      </div>
                      <div className="marker offset overlap">
                        <ArrowDropUpIcon />
                      </div>
                    </>
                  ) : null}
                </>
              )
              // : null
            }
          </div>
        ) : null}
      </div>
      {showResidentOnboarding &&
      showResidentOnboarding.resident &&
      showResidentOnboarding.resident.ResidentID === resident.ResidentID ? (
        <div className="resident-onboarding-container">
          {showResidentOnboarding &&
          showResidentOnboarding.task &&
          showResidentOnboarding.task.ResidentOnboardingStatusID === 1 &&
          activeRoute !== '/property-applicants' &&
          showResidentOnboarding.task.Grouping !== 'pet-interview' &&
          doesItemQualify(showResidentOnboarding.task) ? (
            <Button
              variant="outlined"
              color="primary"
              className="create-message"
              onClick={() =>
                setSendNewMessage({
                  defaultRequiredItem:
                    showResidentOnboarding.task.RequiredItemText
                })
              }>
              Send Resident Reminder <MapsUgcIcon />
            </Button>
          ) : null}

          {showResidentOnboarding &&
          !doesItemQualify(showResidentOnboarding.task) ? (
            <AuditItem
              item={showResidentOnboarding.task}
              setShowResidentOnboarding={setShowResidentOnboarding}
              loadOnboardingDashboard={loadOnboardingDashboard}
            />
          ) : showResidentOnboarding.task &&
            showResidentOnboarding.task.Grouping === 'resident-form' ? (
            <div className="resident-onboarding-container">
              <div className="section resident-onboarding">
                <div className="resident-onboarding-item form">
                  <div class="form-header">
                    <h3>
                      <IconButton
                        id={
                          'closeButton-' +
                          showResidentOnboarding.task.Grouping +
                          '-' +
                          showResidentOnboarding.task.ResidentID +
                          '-' +
                          showResidentOnboarding.task.Description
                        }
                        className="close-button"
                        onClick={() => setShowResidentOnboarding(null)}>
                        <CloseIcon />
                      </IconButton>

                      {showResidentForm
                        ? showResidentForm.OnboardingColumn
                        : showResidentOnboarding.task.OnboardingColumn}
                    </h3>
                  </div>

                  {showResidentOnboarding.task.OnboardingSection ===
                  'applicant' ? (
                    <ApplicantGroup
                      groupItem={showResidentOnboarding.task}
                      residentOnboarding={residentOnboarding}
                      loadResidentOnboarding={loadResidentOnboarding}
                      buttonAction={(_item) => {
                        if (_item.ResidentOnboardingStatusID > 1) {
                          setShowResidentForm(_item);
                        } else {
                          setShowResidentFormPopup(_item);
                        }
                      }}
                      buttonLabel={(_item) =>
                        _item.ResidentOnboardingStatusID > 1
                          ? 'View Submittal'
                          : 'Manual Confirmation'
                      }
                      buttonSelectedItem={showResidentForm}
                    />
                  ) : null}

                  {showResidentFormPopup ? (
                    <ResidentFormPopup
                      closeFunc={() => setShowResidentFormPopup(null)}
                      item={showResidentFormPopup}
                      propertyRole={showResidentFormPopup.OtherSigner}
                      residentID={resident.ResidentID}
                    />
                  ) : null}

                  {showResidentForm ||
                  showResidentOnboarding.task.OnboardingSection !==
                    'applicant' ? (
                    <div className="form-content">
                      <div>
                        <div className="multi-item">
                          {showResidentOnboarding.task.OnboardingSection ===
                          'applicant' ? (
                            <div className="form-header">
                              <h4>
                                <IconButton
                                  id={
                                    'closeButton-' +
                                    showResidentOnboarding.task.Grouping +
                                    '-' +
                                    showResidentOnboarding.task.ResidentID +
                                    '-' +
                                    showResidentOnboarding.task.Description
                                  }
                                  className="close-button"
                                  onClick={() => setShowResidentForm(null)}>
                                  <CloseIcon />
                                </IconButton>
                                {showResidentForm
                                  ? showResidentForm.Description
                                  : showResidentOnboarding.task.Description}
                              </h4>
                            </div>
                          ) : null}
                          <ResidentFormReport
                            key={
                              showResidentOnboarding.task.Grouping +
                              '-' +
                              showResidentOnboarding.task.ResidentID +
                              '-' +
                              showResidentOnboarding.task.Description
                            }
                            residentID={
                              showResidentOnboarding.resident.ResidentID
                            }
                            residentForm={
                              showResidentForm
                                ? showResidentForm
                                : showResidentOnboarding.task
                            }
                            profileKey={profileKey}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <ResidentOnboarding
              residentID={showResidentOnboarding.resident.ResidentID}
              otherTasks={
                !showResidentOnboarding.task.ProviderServiceList ||
                !showResidentOnboarding.task.ProviderServiceList.length
                  ? showResidentOnboarding.otherTasks
                  : null
              }
              providerServiceList={
                showResidentOnboarding.task.ProviderServiceList
              }
              closeOnboardingRow={() => setShowResidentOnboarding(false)}
              profileKey={profileKey}
              grouping={showResidentOnboarding.task.Grouping}
              onboardingDashboardRow={showResidentOnboarding.resident}
            />
          )}
        </div>
      ) : showDocumentExport ? (
        <div
          className={
            'resident-onboarding-container doc-export' +
            (docExportOpen || !docExportOnly ? ' open' : '')
          }>
          <IconButton
            style={{ float: 'right', marginTop: '-5px', marginRight: '-5px' }}
            onClick={() => setShowDocumentExport(null)}>
            <CloseIcon />
          </IconButton>
          <div className="resident-onboarding">
            <div className="resident-onboarding-item document-export">
              <div className="form-header">
                <h3>Document Export</h3>
                <div className="form-content">
                  {documentExportList && documentExportList.length ? (
                    <>
                      {documentExportList.map(
                        (
                          onboardingSectionDocumentList,
                          onboardingSectionIndex
                        ) => (
                          <>
                            <h4>{onboardingSectionDocumentList.title}</h4>
                            <ul className="document-export-list">
                              {onboardingSectionDocumentList.data.map(
                                (itemGroup) => (
                                  <li>
                                    {itemGroup.ItemList.filter(
                                      (item, itemIndex) =>
                                        !itemIndex ||
                                        itemGroup.ItemList[itemIndex - 1]
                                          .ExportFileName !==
                                          item.ExportFileName
                                    ).map((item) => (
                                      <div
                                        className={
                                          item.ResidentOnboardingStatusID === 3
                                            ? 'active'
                                            : ''
                                        }>
                                        <Button
                                          disabled={
                                            item.ResidentOnboardingStatusID !==
                                            3
                                          }
                                          href={item.exportPath}
                                          onClick={() =>
                                            setExportInProgress(item)
                                          }
                                          className="link">
                                          <div className="label">
                                            <span className="bull">&bull;</span>
                                            {item.Description}
                                            {item.ProviderServiceList &&
                                            item.ProviderServiceList.length &&
                                            item.ProviderServiceList[0]
                                              .ProviderServiceID !== 37
                                              ? ' Service'
                                              : ''}
                                          </div>
                                          {item.ResidentOnboardingStatusID ===
                                          3 ? (
                                            <>
                                              <div className="download-link">
                                                <DownloadIcon />
                                                {item.ExportFileName}
                                              </div>
                                            </>
                                          ) : null}
                                        </Button>
                                      </div>
                                    ))}
                                  </li>
                                )
                              )}

                              {onboardingSectionDocumentList.data.filter(
                                (itemGroup) =>
                                  itemGroup.ItemList.filter(
                                    (item) =>
                                      item.ResidentOnboardingStatusID === 3
                                  ).length
                              ).length ? (
                                <li className={'active'}>
                                  {[
                                    resident.UnitNumber +
                                      '_' +
                                      resident.LastName +
                                      '_' +
                                      onboardingSectionDocumentList.title
                                        .split(' ')
                                        .join('')
                                        .split('-')
                                        .join('') +
                                      '.pdf'
                                  ].map((fileName) => (
                                    <Button
                                      href={
                                        process.env.GATSBY_EXPORT_URL +
                                        'docs/all?property=' +
                                        profileKey +
                                        '&resident=' +
                                        resident.ResidentID +
                                        '&section=' +
                                        onboardingSectionDocumentList.data[0]
                                          .OnboardingSection +
                                        '&fileName=' +
                                        fileName
                                      }
                                      onClick={() =>
                                        setExportInProgress({
                                          Description:
                                            'All ' +
                                            onboardingSectionDocumentList.title,
                                          ExportFileName: fileName
                                        })
                                      }
                                      className="link">
                                      <div className="label">
                                        <span className="bull">&bull;</span>
                                        Download All{' '}
                                        {onboardingSectionDocumentList.title}
                                      </div>

                                      <div className="download-link">
                                        <DownloadIcon />
                                        {fileName}
                                      </div>
                                    </Button>
                                  ))}
                                </li>
                              ) : null}
                            </ul>
                          </>
                        )
                      )}

                      {Object.keys(resident.data).filter(
                        (column) =>
                          resident.data[column].ResidentOnboardingStatusID === 3
                      ).length ? (
                        <>
                          <h4>All Documents</h4>
                          <ul className="document-export-list">
                            <li className={'active'}>
                              {[
                                resident.UnitNumber +
                                  '_' +
                                  resident.LastName +
                                  '_MovingINPackage.pdf'
                              ].map((fileName) => (
                                <Button
                                  href={
                                    process.env.GATSBY_EXPORT_URL +
                                    'docs/all?property=' +
                                    profileKey +
                                    '&resident=' +
                                    resident.ResidentID +
                                    '&fileName=' +
                                    fileName
                                  }
                                  onClick={() =>
                                    setExportInProgress({
                                      Description: 'All Documents',
                                      ExportFileName: fileName
                                    })
                                  }
                                  className="link">
                                  <div className="label">
                                    <span className="bull">&bull;</span>Download
                                    All
                                  </div>

                                  <div className="download-link">
                                    <DownloadIcon />
                                    {fileName}
                                  </div>
                                </Button>
                              ))}
                            </li>
                          </ul>
                        </>
                      ) : null}
                    </>
                  ) : (
                    <div className="no-docs">
                      No documents are available for export
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : showMessageHistory === resident.ResidentID ? (
        <div
          className={
            'resident-onboarding-container message-history' +
            (showMessageHistory || !docExportOnly ? ' open' : '')
          }>
          <IconButton
            style={{ float: 'right', marginTop: '-5px', marginRight: '-5px' }}
            onClick={() => setShowMessageHistory(null)}>
            <CloseIcon />
          </IconButton>

          {residentOnboarding &&
          residentOnboarding.Header &&
          residentOnboarding.Header.ResidentID === resident.ResidentID ? (
            <div className="contact-info">
              <div
                className="contact-info-item"
                style={{
                  width:
                    100 / (residentOnboarding.OccupantList.length + 1) - 2 + '%'
                }}>
                <strong>
                  {resident.FirstName} {resident.LastName}
                </strong>{' '}
                (Primary)
                <Button
                  variant="outlined"
                  size="small"
                  style={{ marginLeft: '20px' }}
                  onClick={() => setEditResident({ ...resident })}>
                  Edit{' '}
                  {resident.ResidentStatusID === 4 ? 'Applicant' : 'Resident'}
                </Button>
                {resident.Email ? (
                  <>
                    <br />
                    <a className="link">{resident.Email}</a>
                  </>
                ) : null}
                {resident.Phone ? (
                  <>
                    <br />
                    <a className="link">{formatPhone(resident.Phone)}</a>
                  </>
                ) : null}
              </div>

              {residentOnboarding && residentOnboarding.OccupantList
                ? residentOnboarding.OccupantList.map((occupant) => (
                    <div
                      className="contact-info-item"
                      style={{
                        width:
                          100 / (residentOnboarding.OccupantList.length + 1) +
                          '%'
                      }}>
                      <strong>
                        {occupant.FirstName} {occupant.LastName}
                      </strong>{' '}
                      ({occupant.ResidentRelationshipName})
                      {occupant.Email ? (
                        <>
                          <br />
                          <a className="link">{occupant.Email}</a>
                        </>
                      ) : null}
                      {occupant.Phone ? (
                        <>
                          <br />
                          <a className="link">{formatPhone(occupant.Phone)}</a>
                        </>
                      ) : null}
                    </div>
                  ))
                : null}
            </div>
          ) : null}

          <div className="resident-onboarding">
            <div className="resident-onboarding-item message-history">
              <div className="form-header">
                <h3>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="create-message"
                    onClick={() => setSendNewMessage({})}>
                    Send New Message <MapsUgcIcon />
                  </Button>
                  Message History
                </h3>

                {messageList &&
                residentOnboarding &&
                residentOnboarding.Header.ResidentID === resident.ResidentID ? (
                  <div className="message-list">
                    <TableContainer
                      style={loadingMessages ? { opacity: '.5' } : {}}>
                      <Table aria-label="simple table" stickyHeader={true}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Message</TableCell>
                            <TableCell>Email To</TableCell>
                            <TableCell>Email Read</TableCell>
                            <TableCell>Text To</TableCell>
                            <TableCell>Text Delivered</TableCell>
                            <TableCell
                              style={{ width: '30px', textAlign: 'right' }}>
                              <IconButton onClick={() => loadMessageList()}>
                                <RefreshIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {messageList.length ? (
                            messageList.map((message) => (
                              <TableRow>
                                <TableCell>
                                  {moment(message.MessageCreated).format(
                                    'M/D/YYYY'
                                  )}
                                </TableCell>
                                <TableCell>
                                  {message.MessageTypeDescription}
                                </TableCell>
                                <TableCell>
                                  {message.Email ? message.Email : '---'}
                                </TableCell>
                                <TableCell>
                                  {message.Email_DateRead ? (
                                    moment(message.Email_DateRead).format(
                                      'M/D/YYYY'
                                    )
                                  ) : message.Email_DateDropped ? (
                                    <span className="delivery-error">
                                      Not Delivered
                                    </span>
                                  ) : (
                                    '---'
                                  )}
                                </TableCell>
                                <TableCell>
                                  {message.Phone ? message.Phone : '---'}
                                </TableCell>
                                <TableCell>
                                  {message.Text_DateDelivered ? (
                                    moment(message.Text_DateDelivered).format(
                                      'M/D/YYYY'
                                    )
                                  ) : message.Text_DateUndelivered ? (
                                    <span className="delivery-error">
                                      Not Delivered
                                    </span>
                                  ) : (
                                    '---'
                                  )}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow className="no-results">
                              <TableCell
                                component="td"
                                scope="row"
                                style={{ padding: '20px' }}>
                                No messages have been sent to this resident.
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                ) : (
                  <LinearProgress />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {sendNewMessage ? (
        <SendResidentMessage
          resident={resident}
          profileKey={profileKey}
          closeFunc={() => {
            setSendNewMessage(null);
            loadMessageList();
          }}
          defaultRequiredItem={sendNewMessage.defaultRequiredItem}
        />
      ) : null}

      {exportInProgress ? (
        <GeneralPopup
          title={'Document Download in Progress'}
          message={
            <>
              <p style={{ marginTop: '20px' }}>
                <strong>
                  Your file, '{exportInProgress.Description}', is downloading...
                </strong>
              </p>
              <p>
                If you are not automatically redirected to your file, please
                look in your Downloads folder for a file with the following
                name:
              </p>
              <ul>
                <li>{exportInProgress.ExportFileName}</li>
              </ul>
              <p>You may close this message when your download completes.</p>
            </>
          }
          closeFunc={() => {
            setExportInProgress(null);
          }}
          closeLabel={'Close'}
          className={'document-export-popup'}
        />
      ) : null}
    </>
  );
}

function OnboardingDashboard({
  profileKey,
  serviceProfile,
  residentOnboarding,
  actions,
  searchString,
  setSearchString,
  webSocket,
  activeRoute,
  setTabCounts
}) {
  const [gridExpand, setGridExpand] = useState({
    'New Applicants (past 5 days)': true,
    'Expected Move-outs over the next 10 days': true,
    'Future Move-ins': true,
    'Expected Move-ins over the next 10 days': true,
    'Move-ins': true,
    'Move-outs': true,
    CalendarList: true
  });
  const [allResidentList, setAllResidentList] = useState(null);
  const [residentList, setResidentList] = useState(null);
  const [showResidentOnboarding, setShowResidentOnboarding] = useState(null);
  const [showFullHistory, setShowFullHistory] = useState(false);
  const [fullHistoryLoaded, setFullHistoryLoaded] = useState(false);
  const [headerRow, setHeaderRow] = useState(null);
  const [addMoveOut, setAddMoveOut] = useState(null);
  const [editResident, setEditResident] = useState(null);
  const [calendarList, setCalendarList] = useState(null);
  const [showCalendar, setShowCalendar] = useState(null);
  const [showMessageHistory, setShowMessageHistory] = useState(null);

  const lastResidentOnboarding = useRef();
  const gridVisibility = {
    '/property-applicants': {
      onboardingSection: 'applicant',
      residentStatus: {
        'New Applicants (past 5 days)': true,
        'Past Applicants': true
      }
    },
    '/property-notice': {
      onboardingSection: 'notice',
      residentStatus: {
        'Expected Move-outs over the next 10 days': true,
        'Future Move-outs': true
      }
    },
    '/property-events': {
      residentStatus: { 'Move-ins': true, 'Move-outs': true }
    }
  };

  const searchTimeout = useRef();

  useEffect(() => {
    if (webSocket.connected) {
      setResidentList(null);

      loadOnboardingDashboard();
    }
  }, [showFullHistory, profileKey, webSocket]);

  // useEffect(() => {
  //   if (webSocket.connected && !allResidentList) {
  //     setResidentList(null);
  //     loadOnboardingDashboard();
  //   }
  // }, [webSocket]);

  useEffect(() => {
    if (residentOnboarding && residentOnboarding.Header) {
      if (
        lastResidentOnboarding.current === residentOnboarding.Header.ResidentID
      ) {
        loadOnboardingDashboard(); //Only loadOnboardingDashboard if the resident may have changed
      }

      lastResidentOnboarding.current = residentOnboarding.Header.ResidentID;
    } else {
      lastResidentOnboarding.current = null;
    }
  }, [residentOnboarding]);

  useEffect(() => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    if (residentList) {
      searchTimeout.current = setTimeout(() => {
        if (searchString) {
          const _searchString = searchString.toLowerCase();

          const _searchResultList = allResidentList.filter((resident) =>
            residentPassesSearch(resident, _searchString)
          );

          setResidentList({
            ...residentList,
            ['Search Results']: _searchResultList
          });

          if (_searchResultList.length) {
            setGridExpand({ ...gridExpand, ['Search Results']: true });
          }
        } else {
          setResidentList({ ...residentList, ['Search Results']: null });
        }
      }, 200);
    }
  }, [searchString]);

  useEffect(() => {
    if (residentList) {
      let pendingItemCount = 0;
      let applicationItemCount = 0;
      let noticeItemCount = 0;

      residentList['Expected Move-ins over the next 10 days']
        .concat(residentList['Future Move-ins'])
        .map((resident) => {
          pendingItemCount += checkHasPendingApproval(resident, 'pending');
        });

      residentList['New Applicants (past 5 days)'].map((resident) => {
        applicationItemCount += checkHasPendingApproval(resident, 'applicant');
      });

      residentList['Expected Move-outs over the next 10 days'].map(
        (resident) => {
          noticeItemCount += checkHasPendingApproval(resident, 'notice');
        }
      );

      setTabCounts({
        '/property-onboarding': pendingItemCount,
        '/property-applicants': applicationItemCount,
        '/property-notice': noticeItemCount
      });
    }
  }, [residentList]);

  useEffect(() => {
    setShowResidentOnboarding(null);
  }, [activeRoute]);

  useEffect(() => {
    if (showCalendar) {
      actions.loadResidentOnboarding(showCalendar.resident.ResidentID);
    }
  }, [showCalendar]);

  function checkHasPendingApproval(resident, onboardingColumn) {
    let _hasPendingApproval = 0;

    if (resident && resident.data) {
      Object.keys(resident.data).map((itemKey) => {
        const item = resident.data[itemKey];
        if (
          item.OnboardingSection === onboardingColumn &&
          item.ResidentOnboardingStatusID === 2
        ) {
          _hasPendingApproval += 1;
        }
      });
    }

    return _hasPendingApproval;
  }

  function loadOnboardingDashboard() {
    listResidentOnboarding(profileKey, showFullHistory).then(
      (_residentOnboardingItemList) => {
        setFullHistoryLoaded(showFullHistory);

        let _residentDictionary = {};
        let _calendarDictionary = {};
        _residentOnboardingItemList.map((item) => {
          if (!_residentDictionary[item.ResidentID]) {
            _residentDictionary[item.ResidentID] = {
              ...item,
              data: {}
            };
          }

          if (item.OnboardingColumn) {
            if (
              !_residentDictionary[item.ResidentID].data[item.OnboardingColumn]
            ) {
              _residentDictionary[item.ResidentID].data[item.OnboardingColumn] =
                {
                  ...item,
                  ResidentOnboardingStatusID: item.ResidentOnboardingStatusID,
                  ResidentOnboardingStatusName:
                    item.ResidentOnboardingStatusName,
                  ResidentOnboardingStatusNameOverride:
                    item.ResidentOnboardingStatusNameOverride,
                  Reason: '',
                  ProviderServiceList: [],
                  DateLastUpdated: item.DateLastUpdated,
                  ExportFileName: item.ExportFileName,
                  RequiredItemText: item.RequiredItemText,
                  OnboardingSection: item.OnboardingSection,
                  Description: item.Description,
                  ResidentFormID: item.ResidentFormID,
                  itemCount: 1,
                  ItemList: [{ ...item }]
                };
            } else if (
              (item.ResidentOnboardingStatusID !== 5 &&
                ((item.ResidentOnboardingStatusID <
                  _residentDictionary[item.ResidentID].data[
                    item.OnboardingColumn
                  ].ResidentOnboardingStatusID &&
                  _residentDictionary[item.ResidentID].data[
                    item.OnboardingColumn
                  ].ResidentOnboardingStatusID !== 2) ||
                  item.ResidentOnboardingStatusID === 2)) ||
              _residentDictionary[item.ResidentID].data[item.OnboardingColumn]
                .ResidentOnboardingStatusID === 5
            ) {
              _residentDictionary[item.ResidentID].data[
                item.OnboardingColumn
              ].ResidentOnboardingStatusID = item.ResidentOnboardingStatusID;

              _residentDictionary[item.ResidentID].data[
                item.OnboardingColumn
              ].ResidentOnboardingStatusName =
                item.ResidentOnboardingStatusName;

              _residentDictionary[item.ResidentID].data[
                item.OnboardingColumn
              ].ResidentOnboardingStatusNameOverride =
                item.ResidentOnboardingStatusNameOverride;

              _residentDictionary[item.ResidentID].data[item.OnboardingColumn]
                .itemCount++;

              _residentDictionary[item.ResidentID].data[
                item.OnboardingColumn
              ].ItemList.push({ ...item });
            } else {
              _residentDictionary[item.ResidentID].data[item.OnboardingColumn]
                .itemCount++;
              _residentDictionary[item.ResidentID].data[
                item.OnboardingColumn
              ].ItemList.push({ ...item });
            }

            if (item.ProviderServiceID) {
              _residentDictionary[item.ResidentID].data[
                item.OnboardingColumn
              ].ProviderServiceList.push({ ...item });
            } else {
              _residentDictionary[item.ResidentID].data[
                item.OnboardingColumn
              ].Grouping = item.Grouping;
            }

            _residentDictionary[item.ResidentID].data[
              item.OnboardingColumn
            ].Reason += item.Reason
              ? (item.Description
                  ? (_residentDictionary[item.ResidentID].data[
                      item.OnboardingColumn
                    ].Reason
                      ? ',,'
                      : '') +
                    item.Description +
                    ',&nbsp;&nbsp;&nbsp;&bull; '
                  : '') +
                item.Reason.split(',')
                  .filter((reason) => reason)
                  .join(',&nbsp;&nbsp;&nbsp;&bull; ')
              : '';
          } else {
            if (_residentDictionary[item.ResidentID]) {
              if (!_residentDictionary[item.ResidentID].otherItemList) {
                _residentDictionary[item.ResidentID].otherItemList = [];
              }
              _residentDictionary[item.ResidentID].otherItemList.push({
                ...item
              });
            }
          }

          if (item.CalendarID && item.CalendarStartTime) {
            const calendarDate = moment(item.CalendarDate);
            if (calendarDate.diff(moment(), 'day') <= 3) {
              if (!_calendarDictionary[item.CalendarType]) {
                _calendarDictionary[item.CalendarType] = [];
              }

              const _calendarDate = moment(item.CalendarDate).format(
                'YYYY-MM-DD'
              );

              if (!_calendarDictionary[item.CalendarType][_calendarDate]) {
                _calendarDictionary[item.CalendarType][_calendarDate] = [];
              }

              _calendarDictionary[item.CalendarType][_calendarDate].push({
                ...item
              });
            }
          }
        });

        let _residentList = {
          ['Search Results']: null,
          'New Applicants (past 5 days)': [],
          'Past Applicants': [],
          'Other Items Ready to Review': [],
          'Expected Move-ins over the next 10 days': [],
          'Future Move-ins': [],
          'Most Recent Move-ins (past 30 days)': [],
          'Quick View Unit Audit': [],
          'Expected Move-outs over the next 10 days': [],
          'Future Move-outs': [],
          'Move-ins': [],
          'Move-outs': []
        };

        let _allResidentList = [];

        const today = new Date();
        Object.keys(_residentDictionary).map((residentID) => {
          const moveInDate = new Date(
            _residentDictionary[residentID].DateLeaseStart
          );
          const daysTillMove =
            (moveInDate.getTime() - today.getTime()) / (1000 * 3600 * 24);

          if (_residentDictionary[residentID].ResidentStatusID === 4) {
            const dateApplied = new Date(
              _residentDictionary[residentID].DateApplied
            );

            const daysSinceApplied =
              (dateApplied.getTime() - today.getTime()) / (1000 * 3600 * 24);

            if (daysSinceApplied >= -5 && daysTillMove > 0) {
              _residentList['New Applicants (past 5 days)'].push(
                _residentDictionary[residentID]
              );
            } else {
              _residentList['Past Applicants'].push(
                _residentDictionary[residentID]
              );
            }
          } else if (_residentDictionary[residentID].ResidentStatusID === 6) {
            if (_residentDictionary[residentID].DateMoveOut) {
              const moveOutDate = new Date(
                _residentDictionary[residentID].DateMoveOut
              );
              const daysTillMoveOut =
                (moveOutDate.getTime() - today.getTime()) / (1000 * 3600 * 24);

              if (daysTillMoveOut > 10) {
                _residentList['Future Move-outs'].push(
                  _residentDictionary[residentID]
                );
              } else if (daysTillMoveOut > 0) {
                _residentList['Expected Move-outs over the next 10 days'].push(
                  _residentDictionary[residentID]
                );
              }

              if (daysTillMoveOut > 0 && daysTillMoveOut < 4) {
                _residentList['Move-outs'].push(
                  _residentDictionary[residentID]
                );
              }
            }
          } else {
            if (_residentDictionary[residentID].ResidentStatusID === 2) {
              _residentList['Quick View Unit Audit'].push(
                _residentDictionary[residentID]
              );
            }

            if (
              _residentDictionary[residentID].ResidentStatusID === 1 ||
              _residentDictionary[residentID].ResidentStatusID === 2
            ) {
              if (daysTillMove < -1) {
                _residentList['Most Recent Move-ins (past 30 days)'].push(
                  _residentDictionary[residentID]
                );
              } else if (
                daysTillMove > 10 &&
                _residentDictionary[residentID].ResidentStatusID === 1
              ) {
                _residentList['Future Move-ins'].push(
                  _residentDictionary[residentID]
                );
              } else if (
                _residentDictionary[residentID].ResidentStatusID === 1
              ) {
                _residentList['Expected Move-ins over the next 10 days'].push(
                  _residentDictionary[residentID]
                );

                if (daysTillMove < 4) {
                  _residentList['Move-ins'].push(
                    _residentDictionary[residentID]
                  );
                }
              }
            }
          }

          _allResidentList.push(_residentDictionary[residentID]);

          Object.keys(_residentDictionary[residentID].data).map(
            (onboardingColumn) => {
              if (
                _residentDictionary[residentID].ResidentStatusID !== 4 &&
                _residentDictionary[residentID].data[onboardingColumn]
                  .Grouping === 'walkthrough' &&
                _residentDictionary[residentID].data[onboardingColumn] //Walkthrough exists
                  .ResidentOnboardingStatusID > 0 &&
                _residentDictionary[residentID].data[onboardingColumn] //Walkthrough not approved
                  .ResidentOnboardingStatusID < 3 &&
                daysTillMove < -1 //Resident's move-in date is the in the past
              ) {
                _residentList['Other Items Ready to Review'].push(
                  _residentDictionary[residentID]
                );
              }
            }
          );
        });

        //Find the resident with the most data columns and use that for the header row
        let _headerRow = { ..._allResidentList[0] };
        _allResidentList.map((resident) => {
          if (
            resident.data &&
            (!_headerRow.data ||
              Object.keys(resident.data).length >
                Object.keys(_headerRow.data).lenth)
          ) {
            _headerRow = { ...resident };
          }
        });

        setHeaderRow(_headerRow);

        //For 5-day snapshot (property-events) make sure there is an entry for each day
        let snapshotDay = moment();
        let requiredDate = [];
        let maxDay = moment(snapshotDay.format('YYYY-MM-DD')).add('days', 5);
        while (snapshotDay.isSameOrBefore(maxDay)) {
          requiredDate.push(snapshotDay.format('YYYY-MM-DD'));
          snapshotDay.add('day', 1);
        }

        requiredDate.map((date) => {
          if (
            !_residentList['Move-ins'].filter(
              (resident) =>
                resident.DateLeaseStart &&
                moment(resident.DateLeaseStart).format('YYYY-MM-DD') === date
            ).length
          ) {
            _residentList['Move-ins'].push({
              ResidentID: 0,
              DateLeaseStart: date
            });
          }

          if (
            !_residentList['Move-outs'].filter(
              (resident) =>
                resident.DateMoveOut &&
                moment(resident.DateMoveOut).format('YYYY-MM-DD') === date
            ).length
          ) {
            _residentList['Move-outs'].push({
              ResidentID: 0,
              DateMoveOut: date
            });
          }
        });

        _residentList['New Applicants (past 5 days)'].sort(
          (residentA, residentB) =>
            residentA['DateApplied'].localeCompare(residentB['DateApplied']) ||
            residentA['DateLeaseStart'].localeCompare(
              residentB['DateLeaseStart']
            )
        );

        _residentList['Past Applicants'].sort(
          (residentA, residentB) =>
            residentB['DateApplied'].localeCompare(residentA['DateApplied']) ||
            residentB['DateLeaseStart'].localeCompare(
              residentA['DateLeaseStart']
            )
        );

        _residentList['Expected Move-outs over the next 10 days'] = sort(
          _residentList['Expected Move-outs over the next 10 days'],
          'DateMoveOut',
          false
        );

        _residentList['Future Move-outs'] = sort(
          _residentList['Future Move-outs'],
          'DateMoveOut',
          false
        );

        _residentList['Future Move-ins'] = sort(
          _residentList['Future Move-ins'],
          'DateLeaseStart',
          false
        );

        _residentList['Expected Move-ins over the next 10 days'] = sort(
          _residentList['Expected Move-ins over the next 10 days'],
          'DateLeaseStart',
          false
        );

        _residentList['Other Items Ready to Review'] = sort(
          _residentList['Other Items Ready to Review'],
          'DateLeaseStart',
          false
        );

        _residentList['Most Recent Move-ins (past 30 days)'] = sort(
          _residentList['Most Recent Move-ins (past 30 days)'],
          'DateLeaseStart',
          true
        );

        _residentList['Quick View Unit Audit'] = sort(
          _residentList['Quick View Unit Audit'],
          'UnitNumber',
          false
        );

        _residentList['Move-ins'] = sort(
          _residentList['Move-ins'],
          'DateLeaseStart',
          false
        );

        _residentList['Move-outs'] = sort(
          _residentList['Move-outs'],
          'DateMoveOut',
          false
        );

        setResidentList(_residentList);
        setAllResidentList(_allResidentList);

        let _calendarList = [];

        Object.keys(_calendarDictionary).map((calendarType) => {
          const calendarSettings = serviceProfile.CalendarList
            ? serviceProfile.CalendarList.filter(
                (calendar) => calendar.CalendarType == calendarType
              )[0]
            : null;

          if (calendarSettings) {
            let _calendar = { calendarType, dateList: [] };

            //Object.keys(_calendarDictionary[calendarType]).map(
            requiredDate.map((_calendarDate) => {
              let _day = {
                date: _calendarDate,
                timeSlotList: [],
                timeSlotTaken: {}
              };

              const dayOfWeek = moment(_calendarDate).format('dddd');
              const dayOfWeekStartTime =
                calendarSettings[dayOfWeek + 'StartTime'];
              const dayOfWeekEndTime = calendarSettings[dayOfWeek + 'EndTime'];

              if (dayOfWeekStartTime && dayOfWeekEndTime) {
                _calendar.name = calendarSettings.CalendarName;

                const startTime = convertStringToDateTime(
                  _calendarDate,
                  dayOfWeekStartTime
                );

                const endTime = convertStringToDateTime(
                  _calendarDate,
                  dayOfWeekEndTime
                );

                if (startTime && endTime) {
                  let time = moment(startTime.format());
                  while (time.isSameOrBefore(endTime)) {
                    const startTimeFormatted = moment(time).format('h:mm a');

                    time.add(calendarSettings.TimeslotLengthMinutes, 'minutes');

                    const endTimeFormatted = moment(time).format('h:mm a');

                    _day.timeSlotList.push({
                      startTime: startTimeFormatted,
                      endTime: endTimeFormatted
                    });
                  }

                  if (_calendarDictionary[calendarType][_calendarDate]) {
                    _calendarDictionary[calendarType][_calendarDate].map(
                      (_item) => {
                        let itemTime = convertStringToDateTime(
                          _calendarDate,
                          _item.CalendarStartTime
                        );

                        let itemEndTime = moment(itemTime.format());
                        itemEndTime.add(
                          calendarSettings.TimeslotLengthMinutes,
                          'minutes'
                        );

                        while (itemTime.isSameOrBefore(itemEndTime)) {
                          _day.timeSlotTaken[itemTime.format('h:mm a')] = {
                            ..._item
                          };

                          itemTime.add(
                            calendarSettings.TimeslotLengthMinutes,
                            'minutes'
                          );
                        }
                      }
                    );
                  }

                  _calendar.dateList.push(_day);
                }
              }
            });

            _calendar.dateList = sort(_calendar.dateList, 'date', false);

            _calendarList.push(_calendar);
          }
        });

        setCalendarList(
          _calendarList.filter((_calendar) => _calendar.calendarType !== 'keys')
        );
      }
    );
  }

  function sort(_residentList, column, desc) {
    _residentList.sort((a, b) => {
      let valueA = a[column];
      let valueB = b[column];

      if (column === 'DateLeaseStart') {
        if (valueA) {
          valueA = new Date(valueA.split('T')[0]);
        }

        if (valueB) {
          valueB = new Date(valueB.split('T')[0]);
        }
      }

      let result = valueA > valueB ? 1 : valueA < valueB ? -1 : 0;

      if (valueB === null) {
        result = -1;
      }

      if (valueA === null) {
        result = 1;
      }

      if (result === 0 && column !== 'UnitNumber') {
        let valueA = a.UnitNumber;
        let valueB = b.UnitNumber;

        result = valueA > valueB ? 1 : valueA < valueB ? -1 : 0;

        if (valueB === null) {
          result = -1;
        }

        if (valueA === null) {
          result = 1;
        }
      }

      if (desc) {
        result = result * -1;
      }

      return result;
    });

    return _residentList;
  }

  return (
    <div className={'onboarding-dashboard'}>
      {activeRoute === '/property-notice' && residentList ? (
        <Button
          variant="outlined"
          color="primary"
          className="on-notice-button"
          onClick={() => setAddMoveOut(true)}>
          Add Move-out <OutputIcon />
        </Button>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          className="on-notice-button"
          onClick={() =>
            setEditResident({
              ResidentID: 0,
              ResidentStatusID: activeRoute === '/property-applicants' ? 4 : 1,
              ResidentRelationshipID: 1
            })
          }>
          Add{' '}
          {activeRoute === '/property-applicants' ? 'Applicant' : 'Resident'}{' '}
          <PersonAddIcon />
        </Button>
      )}

      {residentList && headerRow ? (
        Object.keys(residentList)
          .filter((residentStatus) =>
            residentList[residentStatus]
              ? activeRoute === '/property-onboarding'
                ? !Object.keys(gridVisibility).filter(
                    (route) =>
                      gridVisibility[route].residentStatus &&
                      gridVisibility[route].residentStatus[residentStatus]
                  ).length
                : (gridVisibility[activeRoute] &&
                    gridVisibility[activeRoute].residentStatus &&
                    gridVisibility[activeRoute].residentStatus[
                      residentStatus
                    ]) ||
                  residentStatus === 'Search Results'
              : null
          )
          .map((residentStatus) => (
            <div
              className={
                'resident-list-container' +
                (gridExpand[residentStatus] ? ' open' : '')
              }>
              <h2>
                <Button
                  onClick={() => {
                    if (
                      residentStatus === 'Quick View Unit Audit' &&
                      !fullHistoryLoaded
                    ) {
                      setShowFullHistory(true);
                    }
                    setGridExpand({
                      ...gridExpand,
                      [residentStatus]: !gridExpand[residentStatus]
                    });
                  }}>
                  {residentStatus} <KeyboardArrowDownIcon />
                </Button>
              </h2>

              {gridExpand[residentStatus] ? (
                <>
                  {residentStatus !== 'Quick View Unit Audit' ||
                  fullHistoryLoaded ? (
                    residentList[residentStatus].length ? (
                      <div className="resident-list">
                        <OnboardingDashboardRow
                          resident={headerRow}
                          header
                          onboardingSection={
                            gridVisibility[activeRoute] &&
                            gridVisibility[activeRoute].onboardingSection
                              ? gridVisibility[activeRoute].onboardingSection
                              : 'pending'
                          }
                          walkthroughOnly={
                            residentStatus === 'Other Items Ready to Review'
                              ? true
                              : false
                          }
                          activeRoute={activeRoute}
                          residentOnboarding={residentOnboarding}
                          loadResidentOnboarding={
                            actions.loadResidentOnboarding
                          }
                          residentStatus={residentStatus}
                          loadOnboardingDashboard={loadOnboardingDashboard}
                          setShowCalendar={setShowCalendar}
                          showMessageHistory={showMessageHistory}
                          setShowMessageHistory={setShowMessageHistory}
                          setEditResident={setEditResident}
                        />
                        {residentList[residentStatus].map((resident) => (
                          <>
                            <OnboardingDashboardRow
                              resident={resident}
                              setShowResidentOnboarding={
                                setShowResidentOnboarding
                              }
                              showResidentOnboarding={showResidentOnboarding}
                              onboardingSection={
                                gridVisibility[activeRoute] &&
                                gridVisibility[activeRoute].onboardingSection
                                  ? gridVisibility[activeRoute]
                                      .onboardingSection
                                  : 'pending'
                              }
                              walkthroughOnly={
                                residentStatus === 'Other Items Ready to Review'
                                  ? true
                                  : false
                              }
                              profileKey={profileKey}
                              activeRoute={activeRoute}
                              residentOnboarding={residentOnboarding}
                              loadResidentOnboarding={
                                actions.loadResidentOnboarding
                              }
                              residentStatus={residentStatus}
                              loadOnboardingDashboard={loadOnboardingDashboard}
                              setShowCalendar={setShowCalendar}
                              showMessageHistory={showMessageHistory}
                              setShowMessageHistory={setShowMessageHistory}
                              setEditResident={setEditResident}
                            />
                          </>
                        ))}
                      </div>
                    ) : (
                      <div className="no-residents">No {residentStatus}.</div>
                    )
                  ) : null}
                  {residentStatus === 'Most Recent Move-ins (past 30 days)' ||
                  residentStatus === 'Quick View Unit Audit' ? (
                    <div className="full-history">
                      {!showFullHistory ? (
                        <Button
                          onClick={() => {
                            setShowFullHistory(true);
                          }}>
                          Show Full History <KeyboardArrowDownIcon />
                        </Button>
                      ) : !fullHistoryLoaded ? (
                        <LinearProgress />
                      ) : null}
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          ))
      ) : (
        <LinearProgress />
      )}

      {calendarList &&
      calendarList.length &&
      activeRoute === '/property-events' ? (
        <div
          className={
            'resident-list-container' +
            (gridExpand['CalendarList'] ? ' open' : '')
          }>
          <h2>
            <Button
              onClick={() => {
                setGridExpand({
                  ...gridExpand,
                  CalendarList: !gridExpand.CalendarList
                });
              }}>
              Appointments and Reservations <KeyboardArrowDownIcon />
            </Button>
          </h2>

          <div>
            {gridExpand.CalendarList
              ? calendarList.map((calendar) => (
                  <div className="resident-list calendar">
                    <div className="row header property-events">
                      <div className="cell day">{calendar.name}</div>
                      <div className="cell time-slots"></div>
                    </div>
                    {calendar.dateList.map((day) => (
                      <div className="row property-events">
                        <div className="cell day">
                          {moment(day.date).format('dddd MMMM D')}
                        </div>
                        <div className="cell time-slots">
                          {day.timeSlotList.map((timeslot) => (
                            <div
                              className="slot"
                              style={{
                                width: 100 / day.timeSlotList.length + '%'
                              }}>
                              <Button
                                variant="outlined"
                                className={
                                  day.timeSlotTaken[timeslot.startTime]
                                    ? 'selected'
                                    : ''
                                }
                                onClick={() =>
                                  setShowCalendar({
                                    CalendarID:
                                      day.timeSlotTaken[timeslot.startTime]
                                        .CalendarID,
                                    resident: {
                                      ...day.timeSlotTaken[timeslot.startTime]
                                    }
                                  })
                                }
                                disabled={
                                  !day.timeSlotTaken[timeslot.startTime]
                                }
                                fullWidth>
                                {timeslot.startTime}
                              </Button>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              : null}
          </div>
        </div>
      ) : null}

      {addMoveOut ? (
        <MoveOutResident
          profileKey={profileKey}
          closeFunc={() => setAddMoveOut(null)}
          afterSave={() => {
            loadOnboardingDashboard();
            setAddMoveOut(null);
          }}
        />
      ) : null}

      {editResident ? (
        <EditResidentPopup
          profileKey={profileKey}
          resident={editResident}
          label={
            activeRoute === '/property-applicants' ? 'Applicant' : 'Resident'
          }
          closeFunc={() => setEditResident(null)}
          afterSave={() => {
            loadOnboardingDashboard();
            setEditResident(null);
          }}
        />
      ) : null}

      {showCalendar ? (
        <GeneralPopup
          title={'Keys Appointment'}
          message={
            residentOnboarding &&
            residentOnboarding.Header.ResidentID ===
              showCalendar.resident.ResidentID ? (
              <CustomerEventCalendar
                calendar={
                  residentOnboarding.CalendarList.filter(
                    (calendar) =>
                      calendar.CalendarID === showCalendar.CalendarID
                  )[0]
                }
                minDate={showCalendar.resident.DateLeaseStart}
                residentID={showCalendar.resident.ResidentID}
              />
            ) : (
              <LinearProgress />
            )
          }
          closeFunc={() => {
            loadOnboardingDashboard();
            setShowCalendar(null);
          }}
          closeLabel={'Close'}
          className={'calendar-timeslot-popup'}
        />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingDashboard);
