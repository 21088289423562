import React, { useState, useRef, useEffect, version } from 'react';
import { Button, IconButton, TextField, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/material/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { withPrefix } from 'gatsby-link';
import { navigate } from 'gatsby-link';

import './index.css';
import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import { ArrowUpward, SwitchVideo } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import ChecklistProgress from '../ChecklistProgress';
import Map from '../AreaMap';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AreaMap from '../AreaMap';
import CheckIcon from '@mui/icons-material/Check';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PinDropIcon from '@mui/icons-material/PinDrop';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import CloseIcon from '@mui/icons-material/Close';
import ChecklistPrompt from '../ChecklistPrompt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ErrorIcon from '@mui/icons-material/Error';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Confetti from '../Confetti';
import SchoolList from '../SchoolList';

import * as dataApi from '../../api/dataApi';

function TaskList({
  actions,
  serviceAddress,
  mode,
  siteConfig,
  isConfirmationPage
}) {
  const route = dataApi.getCurrentRoute();

  const [hoverSection, setHoverSection] = useState(null);
  const overHoverTimeout = useRef();
  const outHoverTimeout = useRef();
  const [showChecklistPrompt, setShowChecklistPrompt] = useState(null);

  const [electricProvider, setElectricProvider] = useState(null);
  const [checklist, setChecklist] = useState(null);

  useEffect(() => {
    let _electricProvider = null;

    if (serviceAddress && serviceAddress.Checklist) {
      setChecklist(
        serviceAddress.Checklist.map((section) => {
          let _section = { ...section };

          if (
            _section.Route === '/connect-utilities' ||
            _section.Route === '/electric-rates'
          ) {
            _section.ServiceList = _section.ServiceList.map((service) => {
              let _service = { ...service };
              if (_service.Title === 'Electric') {
                if (isConfirmationPage) {
                  _service.ProviderList = _service.ProviderList.filter(
                    (_provider) =>
                      _provider.ProviderID === isConfirmationPage.ProviderID
                  );
                } else {
                  if (
                    _service.ProviderList &&
                    _service.ProviderList.length === 1
                  ) {
                    _electricProvider = _service.ProviderList[0];
                  }
                }
              }

              return _service;
            });
          }

          return _section;
        })
      );
    } else {
      setChecklist(null);
    }

    if (_electricProvider) {
      setElectricProvider(_electricProvider);
    } else {
      setElectricProvider(null);
    }

    console.log('dashboard serviceAddressChanged', serviceAddress);
  }, [serviceAddress, isConfirmationPage]);

  function getUniqueProviderList(providerList, limit) {
    let dictProvider = {};
    let updatedProviderList = [];

    providerList.map((provider, providerIndex) => {
      if (!dictProvider[provider.DisplayName]) {
        if (providerIndex < limit) {
          updatedProviderList.push(provider);
        }

        dictProvider[provider.DisplayName] = true;
      }
    });

    return updatedProviderList;
  }

  function isRoutableLink(section, service, providerList) {
    return (
      service.OfferCount ||
      providerList.length > 1 ||
      section.Route === '/change-address'
    );
  }

  function selectSection(section, service, providerList) {
    setHoverSection(null);

    if (isRoutableLink(section, service, providerList)) {
      navigateToSection(section, providerList);
    } else if (providerList && providerList.length) {
      trackOffsiteOrder(providerList[0]);

      setTimeout(() => {
        setShowChecklistPrompt(providerList[0]);
      }, 500);
    }
  }

  function trackOffsiteOrder(provider) {
    actions.saveOrder(
      serviceAddress.Address.Address,
      serviceAddress.Address.Secondary,
      serviceAddress.Address.City,
      serviceAddress.Address.State,
      serviceAddress.Address.Zip,
      provider.ProviderID,
      0,
      2,
      provider.ServiceStartUrl,
      null
    );
  }

  function navigateToSection(section, providerList) {
    navigate(
      section.Route +
        (providerList && providerList.length && providerList[0].RequestParams
          ? providerList[0].RequestParams
          : '')
    );
  }

  function getSectionLogo(section) {
    switch (section.Route) {
      case '/connect-utilities':
        return 'stock-vector-utility-services-set-with-flat-icons-of-community-facilities-infrastructure-elements-human-character.png';
      case '/home-services':
        return 'internet_305333024.png';
      case '/streaming':
        return 'streaming_305333024.png';
      case '/moving-services':
        return 'moving_274240608.png';
      case '/change-address':
        return 'changeaddress_376486785.png';
      default:
        return 'utilities_422750846.png';
    }
  }

  function getOffsiteUrl(providerList) {
    const redirectUrl =
      providerList.length === 1 && providerList[0].ServiceStartUrl
        ? providerList[0].ServiceStartUrl
        : null;

    return redirectUrl ? redirectUrl : null;
  }

  return serviceAddress && serviceAddress.Checklist ? (
    <>
      <div className={'task-list-container' + (mode ? ' ' + mode : '')}>
        {mode !== 'full' ? (
          siteConfig.id === 'mi' &&
          route !== '/connect-utilities' &&
          route !== '/electric-rates' ? (
            <div className="hero">
              <div className="background"></div>

              {isConfirmationPage ? (
                <div className="hero-content">
                  <Confetti />
                  <div className="progress-container">
                    <h1>Let's keep the momentum going...</h1>
                  </div>
                </div>
              ) : (
                <>
                  <div className="hero-content">
                    <img
                      src={withPrefix(
                        '/images/misc/family-dribbble-transparent.gif'
                      )}
                      className="family"
                    />
                    <div className="progress-container">
                      {serviceAddress &&
                      serviceAddress.Address &&
                      serviceAddress.Address.PropertyStatusID === 3 ? (
                        <div className="intro-message">
                          <p>
                            <strong>
                              Welcome to {serviceAddress.Address.PropertyName}!
                            </strong>{' '}
                            Now that you've found the perfect place to move to
                            all you have to do is…well, move. Admit it - the
                            very word fills you with equal parts excitement and
                            dread. That’s why your new community partnered with
                            MovingIN.com, to help with the not so fun part so
                            you can get back to focusing on the exciting part!
                          </p>
                          <h1>Now, let's get you ready for moving day!</h1>
                          <p>
                            Use the dashboard below to quickly set up your
                            utilities, schedule internet service, choose your
                            entertainment package, secure renter's insurance,
                            update all your addresses and more…all in one place.
                          </p>
                        </div>
                      ) : (
                        <h1>Let's get you ready for moving day</h1>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <>
              <div className="hero node pts">
                {electricProvider ? (
                  <div className="pts-hero node">
                    <p>
                      <ErrorIcon />
                      Based on the address you entered,{' '}
                      <em>{electricProvider.ProviderName}</em> is your electric
                      provider. You'll need to contact the provider directly
                      about options for reducing your electric bill.{' '}
                      <strong>In the meantime...</strong>
                    </p>
                  </div>
                ) : (
                  <div className="pts-hero node">
                    <p>
                      <ErrorIcon />
                      It doesn't look like we have any electric offers in your
                      area right now. <strong>However...</strong>
                    </p>
                  </div>
                )}
                {isConfirmationPage ? <Confetti /> : null}
                <h1>Let's look at some other ways you can save some money</h1>
              </div>
            </>
          )
        ) : null}

        {checklist && mode === 'full' ? (
          <>
            <ul className="task-list menu">
              {checklist.map((section, sectionIndex) => {
                return (
                  <li
                    className={
                      'node' +
                      (route && route == section.Route ? ' selected' : '')
                    }
                    onMouseOver={() => {
                      if (overHoverTimeout.current) {
                        clearTimeout(overHoverTimeout.current);
                      }

                      // overHoverTimeout.current = setTimeout(() => {
                      if (outHoverTimeout.current) {
                        clearTimeout(outHoverTimeout.current);
                      }
                      setHoverSection(section.Route);
                      //}, 100);
                    }}
                    onMouseOut={() => {
                      outHoverTimeout.current = setTimeout(
                        () => setHoverSection(null),
                        200
                      );
                    }}
                    onClick={() => {
                      if (outHoverTimeout.current) {
                        clearTimeout(outHoverTimeout.current);
                      }
                      setHoverSection(section.Route);
                    }}
                    style={{
                      width: 100 / checklist.length - 1 + '%'
                    }}>
                    <div className="task-progress">
                      <div className="connector"></div>
                      <div className="pointer"></div>
                      {section.ServiceList.map((service) => (
                        <div
                          className="task-box"
                          style={{
                            width: 100 / section.ServiceList.length + '%'
                          }}></div>
                      ))}
                    </div>
                    <Button>
                      <ExpandMoreIcon />
                      <img
                        src={withPrefix(
                          '/images/misc/' + getSectionLogo(section)
                        )}
                        className="section-logo"
                      />
                      <span className="button-text">{section.ShortTitle}</span>
                    </Button>
                  </li>
                );
              })}
            </ul>
            {hoverSection ? <div className="mobile-blocker"></div> : null}
          </>
        ) : null}

        {checklist ? (
          <ul className="task-list">
            {checklist.map((section, sectionIndex) => {
              return (
                <li
                  className={
                    'node' + (section.Route === hoverSection ? ' hover' : '')
                  }
                  onMouseOver={() => {
                    if (mode === 'full') {
                      if (outHoverTimeout.current) {
                        clearTimeout(outHoverTimeout.current);
                      }
                      setHoverSection(section.Route);
                    }
                  }}
                  onMouseOut={() => {
                    if (mode === 'full') {
                      outHoverTimeout.current = setTimeout(
                        () => setHoverSection(null),
                        100
                      );
                    }
                  }}
                  onClick={() => {
                    if (section.ServiceList.length === 1) {
                      selectSection(section, section.ServiceList[0], []);
                    }
                  }}>
                  <Button
                    className="mobile-close"
                    onClick={(event) => {
                      setHoverSection(null);
                      event.stopPropagation();
                    }}>
                    <CloseIcon />
                  </Button>

                  <div className="task-progress">
                    <div className="connector"></div>
                    <div className="pointer"></div>
                    {section.ServiceList.map((service) => (
                      <div
                        className="task-box"
                        style={{
                          height: 100 / section.ServiceList.length + '%'
                        }}></div>
                    ))}
                  </div>

                  <div className="task-icon">
                    <div
                      className="backdrop"
                      style={{
                        backgroundColor: section.BackgroundColor
                      }}></div>
                    <div
                      className="backdrop-border"
                      style={{ borderColor: section.BackgroundColor }}></div>
                    <img
                      src={withPrefix(
                        '/images/misc/' + getSectionLogo(section)
                      )}
                    />
                  </div>
                  <div className="description">
                    <h2>{section.Title}</h2>
                    <h3>{section.Description}</h3>
                  </div>
                  <div className="brand">
                    {section.ServiceList.map((service) => {
                      let limit =
                        section.ServiceList.length === 1
                          ? 5
                          : section.ServiceList.length === 2
                          ? 3
                          : 1;

                      let width = 100 / section.ServiceList.length;

                      //If this is the utilities section and there is more than one choice for electric,
                      //make the electric box span the whole area
                      let full = false;
                      if (section.Title === 'Connect Utilities') {
                        const electric = section.ServiceList.filter(
                          (_section) => _section.Headline === 'Electric'
                        )[0];
                        if (
                          electric != null &&
                          electric.ProviderList &&
                          electric.ProviderList.length > 1
                        ) {
                          if (service.Headline === 'Electric') {
                            width = 100;
                            limit = 5;
                            full = true;
                          } else {
                            width = 100 / (section.ServiceList.length - 1);
                          }
                        }
                      }

                      const providerList = getUniqueProviderList(
                        service.ProviderList,
                        //limit
                        999 //Limited via CSS, scroll on mobile
                      );

                      const providerCount = getUniqueProviderList(
                        service.ProviderList,
                        limit
                      ).length;

                      const totalProviderCount = getUniqueProviderList(
                        service.ProviderList,
                        999
                      ).length;

                      return (
                        <div
                          style={{
                            width: width + '%'
                          }}>
                          <div
                            className={
                              'container ' +
                              section.Title.split(' ').join('-').toLowerCase() +
                              (!service.Headline ? ' no-headline' : '') +
                              (service.IsCompleted ? ' completed' : '') +
                              (providerCount < totalProviderCount
                                ? ' allow-scroll'
                                : '')
                            }
                            onClick={() =>
                              selectSection(section, service, providerList)
                            }>
                            {full ? (
                              <div
                                className="full-border"
                                style={{
                                  borderColor: section.BackgroundColor
                                }}></div>
                            ) : null}

                            <Button
                              href={getOffsiteUrl(providerList)}
                              target={
                                providerList.length === 1 &&
                                providerList[0].ServiceStartUrl
                                  ? 'mi_offsite'
                                  : '_self'
                              }></Button>

                            <div
                              className="task-border"
                              style={{
                                borderColor: section.BackgroundColor
                              }}></div>

                            <div
                              className="task-hover"
                              style={{
                                backgroundColor: section.BackgroundColor
                              }}></div>

                            {service.Headline ? (
                              <h5>
                                {service.IsCompleted ? (
                                  <div className="complete-check">
                                    <CheckIcon />
                                  </div>
                                ) : null}

                                {service.Headline}
                                <div
                                  className="task-fill"
                                  style={{
                                    backgroundColor: section.BackgroundColor
                                  }}></div>
                                <div
                                  className="task-border-horizontal"
                                  style={{
                                    borderColor: section.BackgroundColor
                                  }}></div>

                                <span className="arrow-icon">
                                  {isRoutableLink(
                                    section,
                                    service,
                                    providerList
                                  ) ? (
                                    <>
                                      {section.Route !== '/change-address' ? (
                                        <span className="see-all">See All</span>
                                      ) : null}
                                      <ArrowForwardIcon />
                                    </>
                                  ) : (
                                    <>
                                      <OpenInNewIcon />
                                    </>
                                  )}
                                </span>
                              </h5>
                            ) : null}

                            <div className="provider-container">
                              <div className="provider-name-spacer"></div>

                              {providerList.map((provider) => {
                                return (
                                  <div
                                    className="provider-name"
                                    style={{
                                      width: 100 / providerCount + '%'
                                    }}>
                                    {provider.LogoFile ? (
                                      <img
                                        src={provider.LogoFile}
                                        className="provider-logo"
                                      />
                                    ) : (
                                      <span>{provider.DisplayName}</span>
                                    )}
                                  </div>
                                );
                              })}
                            </div>

                            {/* <div className="arrow-container">
                            <ChevronRightIcon />
                          </div> */}

                            {providerCount < totalProviderCount ? (
                              <>
                                <div className="provider-count">
                                  + {totalProviderCount - limit} more
                                </div>
                              </>
                            ) : null}
                            {section.Route !== '/change-address' ? (
                              <div className="provider-scroll-indicator">
                                <ArrowRightAltIcon
                                  className="left"
                                  style={{
                                    visibility:
                                      providerCount < totalProviderCount
                                        ? 'visible'
                                        : 'hidden'
                                  }}
                                />
                                {totalProviderCount} option
                                {totalProviderCount > 1 ? 's' : ''}
                                <ArrowRightAltIcon
                                  style={{
                                    visibility:
                                      providerCount < totalProviderCount
                                        ? 'visible'
                                        : 'hidden'
                                  }}
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </li>
              );
            })}
          </ul>
        ) : null}

        {mode === 'full' ? (
          <div className={'menu-spacer' + (hoverSection ? ' open' : '')}></div>
        ) : null}

        <SchoolList serviceAddress={serviceAddress} siteConfig={siteConfig} />
        {/* <AreaMap /> */}
      </div>
      {showChecklistPrompt ? (
        <ChecklistPrompt
          provider={showChecklistPrompt}
          trackOffsiteOrder={trackOffsiteOrder}
          closeFunc={() => setShowChecklistPrompt(null)}
        />
      ) : null}
    </>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      saveOrder: bindActionCreators(serviceAddressActions.saveOrder, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
