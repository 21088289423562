import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import { navigate } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { listUnit } from '../../api/propertyProfileApi';
import {
  saveServiceAddress,
  setCookie,
  setSessionStorage
} from '../../api/dataApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as userAuthActions from '../../redux/actions/userAuthActions';
import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import AddressSearch from '../AddressSearch';

import './index.css';

export function selectSampleResident(
  pageLoading,
  serviceAddressUpdated,
  selectUnit,
  property,
  clearResidentOnboarding,
  brandId
) {
  setSelectedResident(
    pageLoading,
    serviceAddressUpdated,
    selectUnit,
    null,
    property.SampleResident_Address,
    property.SampleResident_Secondary,
    property.SampleResident_City,
    property.SampleResident_State,
    property.SampleResident_Zip,
    property.SampleResident_UniqueAddressId,
    property.SampleResident_Previous_Address,
    property.SampleResident_Previous_Secondary,
    property.SampleResident_Previous_City,
    property.SampleResident_Previous_State,
    property.SampleResident_Previous_Zip,
    property.SampleResident_ResidentID,
    property.SampleResident_FirstName,
    property.SampleResident_LastName,
    property.SampleResident_Email,
    property.SampleResident_Phone,
    property.SampleResident_DateLeaseStart,
    property.SampleResident_TenantId,
    clearResidentOnboarding,
    'Resident',
    brandId
  );
}

export function setSelectedResident(
  pageLoading,
  serviceAddressUpdated,
  selectUnit,
  closeFunc,
  address,
  secondary,
  city,
  state,
  zip,
  uniqueAddressId,
  previousAddress,
  previousSecondary,
  previousCity,
  previousState,
  previousZip,
  residentID,
  firstName,
  lastName,
  email,
  phone,
  defaultStartDate,
  tenantId,
  clearResidentOnboarding,
  role,
  brandId
) {
  pageLoading(true, 'Saving your selection...');

  const resident = {
    ResidentID: residentID,
    FirstName: firstName,
    LastName: lastName,
    DefaultStartDate: defaultStartDate,
    TenantId: tenantId,
    Email: email,
    Phone: phone,
    Previous_Address: previousAddress,
    Previous_Secondary: previousSecondary,
    Previous_City: previousCity,
    Previous_State: previousState,
    Previous_Zip: previousZip,
    Role: role ? role : 'Resident'
  };

  setSessionStorage('mi-selected-resident', JSON.stringify(resident));

  saveServiceAddress(
    address,
    secondary,
    city,
    state,
    zip,
    10,
    uniqueAddressId,
    previousAddress,
    previousSecondary,
    previousCity,
    previousState,
    previousZip
  ).then((result) => {
    if (result && result.Address) {
      if (clearResidentOnboarding) {
        clearResidentOnboarding();
      }

      serviceAddressUpdated({
        ...result,
        Address: {
          ...result.Address
        }
      });
    }

    pageLoading(false);

    navigate(brandId === 'sc' ? '/electric-rates' : '/dashboard');

    if (closeFunc) {
      closeFunc();
    }
  });
}

function UserPropertySelect({
  closeFunc,
  authUser,
  actions,
  selectedProperty,
  lastRoute,
  setShowPopover
}) {
  const [expandProperty, setExpandProperty] = useState(null);
  const [searchString, setSearchString] = useState('');
  const requestedProperty = useQueryParam('property', '');
  const [showAddressSearch, setShowAddressSearch] = useState(false);
  const [addressSearched, setAddressSearched] = useState(false);
  const [sortField, setSortField] = useState('UnitNumber');
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    if (authUser.PropertyList.length === 1) {
      setExpandProperty(authUser.PropertyList[0]);
    } else if (selectedProperty) {
      setExpandProperty(
        authUser.PropertyList.filter(
          (property) => property.PropertyID === selectedProperty
        )[0]
      );
    }
  }, []);

  useEffect(() => {
    if (expandProperty && !expandProperty.unitList) {
      listUnit(expandProperty.ProfileKey, true).then((unitList) =>
        setExpandProperty({ ...expandProperty, unitList })
      );
    }
  }, [expandProperty]);

  useEffect(() => {
    if (requestedProperty && !expandProperty && !selectedProperty) {
      setExpandProperty(
        authUser.PropertyList.filter(
          (property) => property.ProfileKey === requestedProperty
        )[0]
      );
    }

    console.log('requestedProperty', requestedProperty, selectedProperty);
  }, [requestedProperty]);

  function updateSortField(field) {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  }

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="user-property-select-popup"
      fullWidth={true}>
      <DialogTitle id="form-dialog-title">
        {closeFunc ? (
          <IconButton
            style={{ float: 'right' }}
            aria-label="close"
            onClick={() => {
              closeFunc();
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
        <img
          style={{ marginTop: '-5px' }}
          src={withPrefix('/images/logo-icon.svg')}
          className="logo-icon"
        />
        View Resident Dashboard
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        {authUser.User.IsAdmin ? (
          <div className="admin-menu" style={{ marginBottom: '20px' }}>
            <span className="admin-title">Admin Only</span>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setShowAddressSearch(true);
              }}
              fullWidth>
              Address Search
            </Button>
          </div>
        ) : null}

        <p>
          To view the Resident Dashboard, select a unit from the list below.
        </p>
        <ul className="property-list">
          {authUser.PropertyList.filter((property) =>
            selectedProperty
              ? property.PropertyID === selectedProperty
              : requestedProperty
              ? requestedProperty === property.ProfileKey
              : !selectedProperty && !requestedProperty
          ).map((property) => (
            <li
              className={
                expandProperty &&
                expandProperty.PropertyID === property.PropertyID
                  ? 'expanded'
                  : ''
              }>
              {expandProperty &&
              expandProperty.PropertyID === property.PropertyID ? (
                <div className="search">
                  <FormControl variant="standard">
                    <Input
                      id="input-with-icon-adornment"
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                      placeholder={'Search'}
                      value={searchString}
                      onChange={(event) => setSearchString(event.target.value)}
                    />
                  </FormControl>
                </div>
              ) : null}
              <Button
                disabled={authUser.PropertyList.length === 1}
                onClick={() =>
                  setExpandProperty(
                    !expandProperty ||
                      expandProperty.PropertyID !== property.PropertyID
                      ? property
                      : null
                  )
                }>
                {property.PropertyName}
                <ExpandMoreIcon />
              </Button>
              {expandProperty &&
              expandProperty.PropertyID === property.PropertyID ? (
                expandProperty.unitList ? (
                  <ul className="unit-list">
                    {expandProperty.unitList.length ? (
                      <>
                        <li className="header">
                          <div className="unit">
                            <Button
                              onClick={() => updateSortField('UnitNumber')}>
                              Unit{' '}
                              {sortField === 'UnitNumber' ? (
                                sortDirection === 'asc' ? (
                                  <ArrowDropDownIcon />
                                ) : (
                                  <ArrowDropUpIcon />
                                )
                              ) : null}
                            </Button>
                          </div>
                          <div className="resident">
                            <Button onClick={() => updateSortField('LastName')}>
                              Resident{' '}
                              {sortField === 'LastName' ? (
                                sortDirection === 'asc' ? (
                                  <ArrowDropDownIcon />
                                ) : (
                                  <ArrowDropUpIcon />
                                )
                              ) : null}
                            </Button>
                          </div>
                          <div className="resident">
                            <Button
                              onClick={() =>
                                updateSortField('ResidentStatusName')
                              }>
                              Status{' '}
                              {sortField === 'ResidentStatusName' ? (
                                sortDirection === 'asc' ? (
                                  <ArrowDropDownIcon />
                                ) : (
                                  <ArrowDropUpIcon />
                                )
                              ) : null}
                            </Button>
                          </div>
                          <div className="move-in">
                            <Button
                              onClick={() => updateSortField('DateLeaseStart')}>
                              Move-in Date{' '}
                              {sortField === 'DateLeaseStart' ? (
                                sortDirection === 'asc' ? (
                                  <ArrowDropDownIcon />
                                ) : (
                                  <ArrowDropUpIcon />
                                )
                              ) : null}
                            </Button>
                          </div>
                        </li>
                        {expandProperty.unitList
                          .filter((unit) =>
                            searchString
                              ? unit.UnitNumber.toLowerCase() ===
                                  searchString.toLowerCase() ||
                                (unit.FirstName &&
                                  unit.FirstName.toLowerCase().indexOf(
                                    searchString.toLowerCase()
                                  ) !== -1) ||
                                (unit.LastName &&
                                  unit.LastName.toLowerCase().indexOf(
                                    searchString.toLowerCase()
                                  ) !== -1) ||
                                (unit.FirstName &&
                                  unit.LastName &&
                                  (unit.FirstName + ' ' + unit.LastName)
                                    .toLowerCase()
                                    .indexOf(searchString.toLowerCase()) !==
                                    -1) ||
                                (unit.TenantId &&
                                  unit.TenantId.toLowerCase() ===
                                    searchString.toLowerCase())
                              : true
                          )
                          .sort((unitA, unitB) => {
                            const valueA = unitA[sortField]
                              ? unitA[sortField]
                              : sortDirection === 'asc'
                              ? 'ZZZZZ'
                              : 'AAAAA';
                            const valueB = unitB[sortField]
                              ? unitB[sortField]
                              : sortDirection === 'asc'
                              ? 'ZZZZZ'
                              : 'AAAAA';
                            let compare =
                              valueA.localeCompare(valueB) ||
                              unitA.UnitNumber.localeCompare(unitB.UnitNumber);

                            if (sortDirection === 'desc') {
                              compare *= -1;
                            }

                            return compare;
                          })
                          .map((unit) => (
                            <li>
                              <Button
                                onClick={() => {
                                  setCookie(
                                    'mi-profile-key',
                                    expandProperty.ProfileKey +
                                      ':' +
                                      expandProperty.SampleResident_Address
                                  );

                                  if (lastRoute) {
                                    setSessionStorage(
                                      'mi-last-route',
                                      lastRoute
                                    );
                                  } else {
                                    setSessionStorage('mi-last-route', '');
                                  }

                                  setSelectedResident(
                                    actions.pageLoading,
                                    actions.serviceAddressUpdated,
                                    actions.selectUnit,
                                    closeFunc,
                                    unit.Address_Address,
                                    unit.Address_Secondary,
                                    unit.Address_City,
                                    unit.Address_State,
                                    unit.Address_Zip,
                                    unit.Address_UniqueAddressId,
                                    unit.Previous_Address,
                                    unit.Previous_Secondary,
                                    unit.Previous_City,
                                    unit.Previous_State,
                                    unit.Previous_Zip,
                                    unit.ResidentID
                                      ? unit.ResidentID
                                      : 99999999,
                                    unit.FirstName ? unit.FirstName : 'VACANT',
                                    unit.LastName ? unit.LastName : 'UNIT',

                                    unit.Email
                                      ? unit.Email
                                      : authUser.User.Email,
                                    unit.Phone ? unit.Phone : 'VACANT',
                                    unit.DateLeaseStart
                                      ? unit.DateLeaseStart
                                      : moment().format('YYYY-MM-DD'),

                                    unit.TenantId ? unit.TenantId : 'VACANT',
                                    actions.clearResidentOnboarding
                                  );

                                  if (setShowPopover) {
                                    setShowPopover(null);
                                  }
                                }}>
                                <div className="unit">
                                  Unit #{unit.UnitNumber}
                                </div>
                                <div className="resident">
                                  {unit.ResidentID
                                    ? unit.FirstName + ' ' + unit.LastName
                                    : '--VACANT--'}
                                </div>
                                <div className="status">
                                  {unit.ResidentStatusName}
                                </div>
                                <div className="move-in">
                                  {unit.ResidentID
                                    ? moment(unit.DateLeaseStart).format(
                                        'M/D/YYYY'
                                      )
                                    : ''}
                                </div>
                              </Button>
                            </li>
                          ))}
                      </>
                    ) : (
                      <li>No unit data is being reported for this property.</li>
                    )}
                  </ul>
                ) : (
                  <LinearProgress />
                )
              ) : null}
            </li>
          ))}
        </ul>

        {showAddressSearch ? (
          <Dialog
            open={true}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            className={
              'address-search' + (addressSearched ? ' address-searched' : '')
            }>
            <DialogTitle id="form-dialog-title">
              <img
                src={withPrefix('/images/logo-icon.svg')}
                className="logo-icon"
              />
              Address Search
              <IconButton
                style={{ float: 'right' }}
                aria-label="close"
                onClick={() => {
                  setShowAddressSearch(null);
                  setAddressSearched(false);
                }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent width="100%">
              <AddressSearch
                modal={true}
                onSelected={() => {
                  setShowAddressSearch(false);
                  setAddressSearched(false);
                }}
                hideSearchBy
                onAddressSearched={() => setAddressSearched(true)}
              />
            </DialogContent>
          </Dialog>
        ) : null}
      </DialogContent>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    authUser: state.authUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      ),
      selectUnit: bindActionCreators(userAuthActions.selectUnit, dispatch),
      clearResidentOnboarding: bindActionCreators(
        residentOnboardingActions.clearResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPropertySelect);
