import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby-link';

import { IconButton, Popover, Button } from '@mui/material';
import TextField from '@mui/material/TextField';

import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as userAuthActions from '../../redux/actions/userAuthActions';
import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import { setCookie } from '../../api/dataApi';

import UserPropertySelect, {
  selectSampleResident,
  setSelectedResident
} from '../UserPropertySelect';

import './index.css';

function PropertyMenuItem({ href, children, setShowPopover, onClick }) {
  return (
    <li className="property-menu-item-from-property-menu-item-component">
      <Button
        onClick={(event) => {
          if (onClick) {
            onClick();
          } else {
            navigate(href);
          }

          event.stopPropagation();
          event.preventDefault();
        }}
        href={href}>
        {children}
      </Button>
    </li>
  );
}

function PropertyMenu({ siteConfig, authUser, actions, setShowPopover }) {
  const minPropertyCountForSearch = 6;
  const [showPropertySelect, setShowPropertySelect] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [searchString, setSearchString] = useState('');

  function searchProperty() {
    return authUser.PropertyList.map((property) => {
      let _property = { ...property };
      // Convert the search string to lower case for easy comparison
      const searchStringLower = searchString.toLowerCase();
      // Determine which fields match, if any return the matching data as 'SearchMatch' element that can be added as a badge below the property name
      _property.SearchMatch =
        property.PropertyName &&
        property.PropertyName.toLowerCase().indexOf(searchStringLower) !==
          -1 ? (
          property.PropertyName
        ) : (property.PropertyAddressLine1 &&
            property.PropertyAddressLine1.toLowerCase().indexOf(
              searchStringLower
            ) !== -1) ||
          (property.PropertyZipCode &&
            property.PropertyZipCode.toLowerCase().indexOf(
              searchStringLower
            ) !== -1) ||
          (property.PropertyAddressLine2 &&
            property.PropertyAddressLine2.toLowerCase().indexOf(
              searchStringLower
            ) !== -1) ? (
          <>
            {property.PropertyAddressLine1}{' '}
            {property.PropertyAddressLine2 ? (
              <>{property.PropertyAddressLine2}</>
            ) : null}{' '}
            {property.PropertyCity}, {property.PropertyState}{' '}
            {property.PropertyZipCode}
          </>
        ) : (property.PropertyCity &&
            property.PropertyCity.toLowerCase().indexOf(searchStringLower) !==
              -1) ||
          (property.PropertyState &&
            property.PropertyState.toLowerCase().indexOf(searchStringLower) !==
              -1) ? (
          property.PropertyCity + ', ' + property.PropertyState
        ) : property.PropertyStatusName &&
          property.PropertyStatusName.toLowerCase().indexOf(
            searchStringLower
          ) !== -1 ? (
          property.PropertyStatusName
        ) : property.ManagementCompanyName &&
          property.ManagementCompanyName.toLowerCase().indexOf(
            searchStringLower
          ) !== -1 ? (
          property.ManagementCompanyName
        ) : null;
      return _property;
    }).filter((property) => property.SearchMatch);
  }

  return authUser &&
    authUser.User &&
    authUser.User.Token &&
    authUser.PropertyList &&
    authUser.PropertyList.length ? (
    <>
      <div>
        <div className="property-menu-title-and-search">
          <h6 className="title">Your Properties</h6>
          {authUser.PropertyList.length >= minPropertyCountForSearch ? (
            <TextField
              style={{ marginTop: '10px' }}
              name="propertySearch"
              fullWidth
              id="propertySearch"
              label="Search Properties"
              variant="outlined"
              value={searchString}
              inputProps={{
                autocomplete: 'new-password',
                form: {
                  autocomplete: 'off'
                }
              }}
              onChange={(event) => setSearchString(event.target.value)}
            />
          ) : null}
        </div>

        <div className="property-menu-list">
          <ul>
            {/* {authUser &&
            authUser.PropertyList &&
            authUser.PropertyList.length > 1 &&
            authUser.User.IsAdmin ? (
              <li className="property-name">
                <Button
                  onClick={() => {
                    navigate('/property-events?property=All');
                  }}>
                  All Properties Dashboard
                </Button>
              </li>
            ) : null} */}
            {(searchString ? searchProperty() : authUser.PropertyList).map(
              (property, propertyIndex) => (
                <>
                  <li className="property-name">
                    <Button
                      onClick={() => {
                        setSelectedProperty(
                          selectedProperty &&
                            selectedProperty.PropertyID === property.PropertyID
                            ? null
                            : { ...property }
                        );
                      }}>
                      {property.PropertyStatusID === 5 &&
                      (!authUser || !authUser.User || !authUser.User.IsAdmin)
                        ? 'Demo ' + (propertyIndex + 1)
                        : property.PropertyName}{' '}
                      {authUser.PropertyList.length >=
                      minPropertyCountForSearch ? (
                        <div className="expand-icons">
                          {selectedProperty &&
                          selectedProperty.PropertyID ===
                            property.PropertyID ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </div>
                      ) : null}
                      {/* Display the Badge */}
                      {property.SearchMatch &&
                      property.SearchMatch !== property.PropertyName ? (
                        <div className="badge">{property.SearchMatch}</div>
                      ) : null}
                    </Button>
                  </li>
                  {(selectedProperty &&
                    selectedProperty.PropertyID === property.PropertyID) ||
                  authUser.PropertyList.length < minPropertyCountForSearch ? (
                    <>
                      {property.PropertyID !== 63560 &&
                      siteConfig.id === 'mi' ? (
                        <>
                          <PropertyMenuItem
                            onClick={() => {
                              if (property.SampleResident_ResidentID) {
                                setCookie(
                                  'mi-profile-key',
                                  property.ProfileKey +
                                    ':' +
                                    property.SampleResident_Address
                                );

                                selectSampleResident(
                                  actions.pageLoading,
                                  actions.serviceAddressUpdated,
                                  actions.selectUnit,
                                  property,
                                  actions.clearResidentOnboarding
                                );
                                if (setShowPopover) {
                                  setShowPopover(null);
                                }
                              } else {
                                setShowPropertySelect(property.PropertyID);
                              }
                            }}
                            setShowPopover={setShowPopover}>
                            <div className="property-menu-item-title">
                              View Resident Dashboard
                            </div>
                          </PropertyMenuItem>
                          <PropertyMenuItem
                            href={
                              '/property-events?property=' + property.ProfileKey
                            }
                            onClick={() => {
                              navigate(
                                '/property-events?property=' +
                                  property.ProfileKey
                              );

                              if (setShowPopover) {
                                setShowPopover(null);
                              }
                            }}
                            setShowPopover={setShowPopover}>
                            <div className="property-menu-item-title">
                              View {siteConfig.brand} Dashboard
                            </div>
                          </PropertyMenuItem>
                        </>
                      ) : null}
                      <PropertyMenuItem
                        href={'/property-setup?property=' + property.ProfileKey}
                        onClick={() => {
                          navigate(
                            '/property-setup?property=' + property.ProfileKey
                          );
                          if (setShowPopover) {
                            setShowPopover(null);
                          }
                        }}
                        setShowPopover={setShowPopover}>
                        <div className="property-menu-item-title">
                          {siteConfig.id === 'mi'
                            ? 'Edit Property Profile'
                            : 'View Property Dashboard'}
                        </div>
                      </PropertyMenuItem>

                      <li className="spacer"></li>
                    </>
                  ) : null}
                </>
              )
            )}
            {searchString && !searchProperty().length ? (
              <div className="property-menu-empty">
                No properties found matching '{searchString}'
              </div>
            ) : null}
          </ul>
        </div>
      </div>

      {showPropertySelect ? (
        <UserPropertySelect
          selectedProperty={showPropertySelect}
          closeFunc={() => setShowPropertySelect(null)}
          setShowPopover={setShowPopover}
        />
      ) : null}
    </>
  ) : null;
}

const mapStateToProps = (state) => ({
  serviceAddress: state.serviceAddress,
  authUser: state.authUser,
  siteConfig: state.commonData.siteConfig
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      ),
      selectUnit: bindActionCreators(userAuthActions.selectUnit, dispatch),
      clearResidentOnboarding: bindActionCreators(
        residentOnboardingActions.clearResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyMenu);
