import React, { useState, useRef, useEffect, version } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert } from '@mui/material';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import ResidentForm from '../ResidentForm';

import './index.css';

function ApplicantOtherSigner({ webSocket, siteConfig, residentOnboarding }) {
  const [residentForm, setResidentForm] = useState(null);

  useEffect(() => {
    if (
      residentOnboarding &&
      residentOnboarding.Checklist &&
      webSocket &&
      webSocket.invitation &&
      webSocket.invitation.Role
    ) {
      setResidentForm(
        residentOnboarding.Checklist.filter(
          (item) => item.OtherSigner === webSocket.invitation.Role
        )[0]
      );
    }
  }, [residentOnboarding]);

  return (
    <>
      <div className="page-container applicant-other-signer">
        {residentForm ? (
          <>
            <h1>{residentForm.Description}</h1>
            <div className="page">
              <div className="inner">
                <ResidentForm
                  residentForm={residentForm}
                  role={webSocket.invitation.Role}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <h1>Form Not Available</h1>
            <div className="page">
              <div className="inner">
                <Alert severity="warning" style={{ textAlign: 'center' }}>
                  We're sorry, but we are having trouble finding the form you're
                  looking for. You've either used an invalid link or it has
                  expired.
                  <br />
                  <br />
                  <strong>
                    {' '}
                    Please call {siteConfig.phone} or email {siteConfig.email}{' '}
                    for assistance.
                  </strong>
                </Alert>
              </div>
            </div>
          </>
        )}
      </div>
      ;
    </>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      saveOrder: bindActionCreators(serviceAddressActions.saveOrder, dispatch),
      mockRouteUpdated: bindActionCreators(
        webSocketActions.mockRouteUpdated,
        dispatch
      ),
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicantOtherSigner);
