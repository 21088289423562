import React, { useState, useRef, useEffect } from 'react';
import { useQueryParam } from 'gatsby-query-params';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby-link';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import CloseIcon from '@mui/icons-material/Close';

import ProviderLogo from '../OfferGrid/ProviderLogo';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as offerCalc from '../../api/offerCalc';
import { AppBar, CircularProgress } from '@mui/material';

import OfferDetailsPopup from '../OfferGrid/OfferDetailsPopup';
import InternetChart from '../OfferGrid/InternetChart';

const mostPopularPlanOption = { 'Fiber Internet 1000': true };

function StepPlanBuilder({
  actions,
  serviceAddress,
  siteConfig,
  offerOptionSettings,
  setOfferOptionSettings,
  offerOptionDictionary,
  setOfferOptionDictionary,
  offerList,
  getOfferSettings,
  selectedFeaturedPlan,
  preferredProviderDictionary
}) {
  const [bundleComboDictionary, setBundleComboDictionary] = useState(null);
  const scrollTimeout = useRef();
  const [showOfferDetails, setShowOfferDetails] = useState(null);
  const [contractFilter, setContractFilter] = useState('no-contract');
  const mostPopularInitted = useRef();

  useEffect(() => {
    if (offerOptionDictionary && offerList) {
      if (!bundleComboDictionary) {
        let _bundleComboDictionary = {};

        Object.keys(offerOptionDictionary).map((providerService) => {
          if (offerOptionDictionary[providerService].offerList) {
            offerOptionDictionary[providerService].offerList.map((offer) => {
              if (offer.availableInBundleList) {
                offer.availableInBundleList.map((bundlePointer) => {
                  const bundle = offerList.filter(
                    (offer) => offer.OfferID === bundlePointer.OfferID
                  )[0];
                  if (bundle && bundle.bundleOfferList) {
                    bundle.bundleOfferList.map((bundleOffer) => {
                      const bundleKey =
                        bundleOffer.ProviderName + '=>' + bundleOffer.OfferName;
                      if (!_bundleComboDictionary[bundleKey]) {
                        _bundleComboDictionary[bundleKey] = {};
                      }

                      bundle.bundleOfferList.map((otherBundleOffer) => {
                        if (otherBundleOffer.OfferID !== bundleOffer.OfferID) {
                          const otherBundleKey =
                            otherBundleOffer.ProviderName +
                            '=>' +
                            otherBundleOffer.OfferName;

                          _bundleComboDictionary[bundleKey][otherBundleKey] =
                            otherBundleOffer.OfferTypeID;
                        }
                      });
                    });
                  }
                });
              }
            });
          }
        });

        setBundleComboDictionary(_bundleComboDictionary);
      } else {
        //Make sure the selected offers exist in bundle combinations
        Object.keys(offerOptionDictionary).map((providerService) => {
          if (providerService !== 'Internet') {
            if (offerOptionDictionary[providerService].selectedOffer) {
              if (
                !checkBundleOptionExists(
                  providerService,
                  offerOptionDictionary[providerService].selectedOffer
                )
              ) {
                updateOfferSelection(providerService, null);
              }
            }
          }
        });
      }

      let _orderDetailToSave = {};
      Object.keys(offerOptionDictionary).map((providerService) => {
        if (
          offerOptionDictionary[providerService].selectedOfferID !== null &&
          offerOptionDictionary[providerService].selectedOfferID !== undefined
        ) {
          const selectedOffer = offerOptionDictionary[
            providerService
          ].offerList.filter(
            (offer) =>
              offer.OfferID ===
              offerOptionDictionary[providerService].selectedOfferID
          )[0];
          _orderDetailToSave[providerService] = {
            selectedOfferID: selectedOffer ? selectedOffer.OfferID : null,
            chargeDictionary: selectedOffer
              ? selectedOffer.chargeDictionary
              : null
          };
        }
      });

      setOfferOptionSettings({
        ...offerOptionSettings,
        ..._orderDetailToSave,
        autoSubmit: offerOptionDictionary.autoSubmit
      });
    }
  }, [offerOptionDictionary]);

  useEffect(() => {
    if (offerOptionSettings && offerOptionSettings.autoSubmit) {
      setOfferOptionSettings({ ...offerOptionSettings, autoSubmit: false });
      navigate('/internet-checkout');
    }
  }, [offerOptionSettings]);

  useEffect(() => {
    if (offerOptionDictionary) {
      let _offerOptionDictionary = { ...offerOptionDictionary };

      Object.keys(_offerOptionDictionary).map((providerService) => {
        let _offerOption = { ..._offerOptionDictionary[providerService] };
        _offerOption.offerList = _offerOption.offerList.map((offer) => {
          let _offer = { ...offer };

          if (
            _offer.chargeDictionary &&
            _offer.chargeDictionary[''] &&
            _offer.chargeDictionary[''].chargeList
          ) {
            _offer.chargeDictionary[''].chargeList.map((charge) => {
              let _charge = { ...charge };
              if (_charge.contractRequired) {
                _offer.chargeDictionary[''].selectedValueList[
                  charge.name
                ] = true;
              }
              return _charge;
            });
          }

          return _offer;
        });

        _offerOptionDictionary[providerService] = _offerOption;
      });

      setOfferOptionDictionary({ ..._offerOptionDictionary });
    }
  }, [contractFilter]);

  useEffect(() => {
    if (selectedFeaturedPlan) {
      let _offerOptionDictionary = { ...offerOptionDictionary };

      selectedFeaturedPlan.offerList.map((offer, offerIndex) => {
        _offerOptionDictionary = {
          ..._offerOptionDictionary,
          ...updateOfferSelection(
            offerIndex === 0 ? 'Internet' : 'TV',
            offer,
            _offerOptionDictionary,
            true
          )
        };
      });

      setOfferOptionDictionary({ ..._offerOptionDictionary, autoSubmit: true });
    }
  }, [selectedFeaturedPlan]);

  useEffect(() => {
    //Auto-select the 'most popular' plan if no plan selected
    if (
      !mostPopularInitted.current &&
      offerOptionDictionary &&
      offerOptionDictionary.Internet
    ) {
      if (!offerOptionDictionary.Internet.selectedOfferID) {
        mostPopularInitted.current = true;

        //Per Bud 11/8/2023 do not pre-select a plan in the demo
        // const defaultSelectOffer =
        //   offerOptionDictionary.Internet.offerList.filter(
        //     (offer) => mostPopularPlanOption[offer.DisplayName] === true
        //   )[0];
        // if (defaultSelectOffer) {
        //   updateOfferSelection('Internet', defaultSelectOffer);
        // }
      }
    }
  }, [offerOptionDictionary]);

  function scrollToProviderService(providerService, openOptions) {
    const offerOptionsDiv = document.getElementById(
      'offer-option-container-' + providerService.toLowerCase()
    );

    if (offerOptionsDiv) {
      const yOffset = -80;
      const y =
        offerOptionsDiv.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });

      if (openOptions) {
        setOfferOptionDictionary(openOptions);
      }
    }
  }

  function doOfferSort(offerList) {
    let sortedList = offerList.concat([]);

    console.log('doOfferSort', preferredProviderDictionary);

    sortedList.sort((a, b) => {
      const preferredValueA =
        preferredProviderDictionary && preferredProviderDictionary[a.ProviderID]
          ? preferredProviderDictionary[a.ProviderID]
          : 2;
      const preferredValueB =
        preferredProviderDictionary && preferredProviderDictionary[b.ProviderID]
          ? preferredProviderDictionary[b.ProviderID]
          : 2;
      const preferredProviderCompare =
        preferredValueA > preferredValueB
          ? 1
          : preferredValueA < preferredValueB
          ? -1
          : 0;

      if (preferredProviderCompare === 0) {
        const nameCompare = a.ProviderName.localeCompare(b.ProviderName);

        if (nameCompare === 0) {
          let valueA = parseFloat(offerCalc.getInternetPrice(a).monthlyAmount);
          let valueB = parseFloat(offerCalc.getInternetPrice(b).monthlyAmount);

          let result = valueA > valueB ? 1 : valueA < valueB ? -1 : 0;

          if (valueB === null) {
            result = -1;
          }

          if (valueA === null) {
            result = 1;
          }

          return result;
        }

        return nameCompare;
      }

      return preferredProviderCompare;
    });

    return sortedList;
  }

  // function checkIfValidBundleOffer(providerService, offer) {
  //   let isBundleable =
  //     offer.ProviderServiceList === providerService &&
  //     offer.availableInBundleList &&
  //     offer.availableInBundleList.length &&
  //     offer.PriceDollars;

  //   switch (providerService) {
  //     case 'Internet':
  //       if (!offer.InternetDownloadSpeedMb) {
  //         isBundleable = false;
  //       }
  //       break;
  //     case 'TV':
  //       if (!offer.TVChannelCount) {
  //         isBundleable = false;
  //       }
  //       break;
  //   }

  //   return isBundleable;
  // }

  function checkPriceDifference(selectedOffer, offer) {
    let _offer = { ...offer };

    if (selectedOffer && selectedOffer.OfferID != offer.OfferID) {
      let priceDifference =
        offerCalc.getInternetPrice(offer).monthlyAmount -
        offerCalc.getInternetPrice(selectedOffer).monthlyAmount;
      let comparedDifference = Math.round(priceDifference);

      _offer.priceDifferenceClass = 'same';
      _offer.priceDifferenceText = '';

      if (priceDifference > 0) {
        _offer.priceDifferenceClass = 'more';
        _offer.priceDifferenceText =
          '$' + Math.abs(priceDifference).toFixed(2) + ' more';
      } else if (priceDifference < 0) {
        _offer.priceDifferenceClass = 'less';
        _offer.priceDifferenceText =
          'Save ' + '$' + Math.abs(priceDifference).toFixed(2);
      }

      if (_offer.priceDifferenceText) {
        _offer.priceDifferenceText = '(' + _offer.priceDifferenceText + ')';
      }
    }

    return _offer;
  }

  function updateOfferSelection(
    providerService,
    offer,
    _offerOptionDictionary,
    returnOnly
  ) {
    if (offerOptionDictionary) {
      if (!_offerOptionDictionary) {
        _offerOptionDictionary = { ...offerOptionDictionary };
      }

      _offerOptionDictionary = {
        ..._offerOptionDictionary,
        [providerService]: {
          ...offerOptionDictionary[providerService],
          offerList: offerOptionDictionary[providerService].offerList.map(
            (_offer) =>
              offer && _offer.OfferID === offer.OfferID
                ? {
                    ..._offer
                    //, expanded: true
                  }
                : { ..._offer }
          ),
          selectedOfferID: offer ? offer.OfferID : 0,
          selectedOffer: offer ? offer : null,
          showOptions: false,
          expanded: false
        }
      };

      if (returnOnly) {
        return _offerOptionDictionary;
      } else {
        setOfferOptionDictionary(_offerOptionDictionary);

        //Scroll to either the options for this provider service or the next provider service section
        if (scrollTimeout.current) {
          clearTimeout(scrollTimeout.current);
        }
      }
    }
  }

  function groupOffersByProvider(storedOfferList, latestOfferList) {
    let _providerDictionary = {};
    storedOfferList
      .filter((storedOffer) => storedOffer.OfferID > 0)
      .map((storedOffer) => {
        const offer = latestOfferList.filter(
          (latestOffer) =>
            latestOffer.ProviderID === storedOffer.ProviderID &&
            latestOffer.ApiName === storedOffer.ApiName
        )[0];

        if (offer) {
          if (!_providerDictionary[offer.ProviderName]) {
            _providerDictionary[offer.ProviderName] = {
              ...offer,
              offerList: []
            };
          }

          _providerDictionary[offer.ProviderName].offerList.push(offer);
        }
      });

    return Object.keys(_providerDictionary).map(
      (providerName) => _providerDictionary[providerName]
    );
  }

  function checkBundleOptionExists(providerService, offer) {
    let exists = true;

    switch (offer.BundleOptionID) {
      case 1: //Bundle within provider
        if (offerOptionDictionary) {
          Object.keys(offerOptionDictionary).map((_providerService) => {
            if (_providerService !== providerService) {
              if (offerOptionDictionary[_providerService].selectedOffer) {
                if (
                  offerOptionDictionary[_providerService].selectedOffer
                    .ProviderID !== offer.ProviderID
                ) {
                  exists = false;
                }
              }
            }
          });
        }
        break;
      case 2: //Do nothing - allow bundle
        break;
      case 3: //Never bundle
        exists = false;
        break;
    }

    //TODO: Return false if:
    // 1) there is a selected internet plan
    // 2) the supplied offer parameter does not have the provider-level setting of 'Allow Bundle with Other providers'

    // if (bundleComboDictionary) {
    //   const bundleKey = offer.ProviderName + '=>' + offer.OfferName;

    //   let selectedOfferList = [];

    //   if (offerOptionDictionary) {
    //     Object.keys(offerOptionDictionary).map((_providerService) => {
    //       if (_providerService !== providerService) {
    //         if (offerOptionDictionary[_providerService].selectedOffer) {
    //           selectedOfferList.push(
    //             offerOptionDictionary[_providerService].selectedOffer
    //           );
    //         }
    //       }
    //     });
    //   }

    //   if (selectedOfferList.length) {
    //     //Check if a bundle exists for all of the selected offers
    //     let doesNotExistForAtLeastOne = false;
    //     selectedOfferList.map((selectedOffer) => {
    //       const otherBundleKey =
    //         selectedOffer.ProviderName + '=>' + selectedOffer.OfferName;

    //       if (
    //         !bundleComboDictionary[bundleKey] ||
    //         !bundleComboDictionary[bundleKey][otherBundleKey]
    //       ) {
    //         doesNotExistForAtLeastOne = true;
    //       }
    //     });

    //     if (!doesNotExistForAtLeastOne) {
    //       exists = true;
    //     }
    //   } else {
    //     //If no offers are selected, make the offer available if it's available as a standalone offer
    //     if (offer.OfferTypeID === 1) {
    //       exists = true;
    //     }
    //   }
    // }

    return exists;
  }

  function getChargeOptionList(offer) {
    return Object.keys(offer.chargeDictionary).filter(
      (chargeGroup) =>
        offer.chargeDictionary[chargeGroup].chargeList &&
        (offer.chargeDictionary[chargeGroup].chargeList.length > 1 ||
          (offer.chargeDictionary[chargeGroup].chargeList.length > 0 &&
            !offer.chargeDictionary[chargeGroup].chargeList[0]
              .includedInPrice &&
            offer.chargeDictionary[chargeGroup].chargeList[0].monthlyAmount) ||
          offer.chargeDictionary[chargeGroup].chargeList[0].maxCount > 1 ||
          (!offer.chargeDictionary[chargeGroup].chargeList[0].monthlyAmount &&
            !offer.chargeDictionary[chargeGroup].chargeList[0].purchaseAmount))
    );
  }

  return offerOptionDictionary ? (
    <>
      {Object.keys(offerOptionDictionary).map((providerService) =>
        offerOptionDictionary[providerService].offerList &&
        offerOptionDictionary[providerService].offerList.length ? (
          <div id={'offer-option-container-' + providerService.toLowerCase()}>
            <AppBar color="default" position="sticky">
              <h4 id={'offer-title-' + providerService.toLowerCase()}>
                {providerService}{' '}
                <span>- Choose the plan that is best for you.</span>
                {/* {offerOptionDictionary[providerService].selectedOffer &&
                !offerOptionDictionary[providerService].expanded ? (
                  <Button
                    className="view-all"
                    onClick={() => {
                      setOfferOptionDictionary({
                        ...offerOptionDictionary,
                        [providerService]: {
                          ...offerOptionDictionary[providerService],
                          expanded: true
                        }
                      });
                    }}>
                    Change {providerService} Plan <KeyboardArrowDownIcon />
                  </Button>
                ) : (
                  <span>
                    {offerOptionDictionary[providerService].shortDirections}
                  </span>
                )} */}
              </h4>
            </AppBar>
            <div className="content-area with-sticky-title">
              {offerOptionDictionary[providerService].longDirections ? (
                <p className="long-directions">
                  {offerOptionDictionary[providerService].longDirections}
                </p>
              ) : null}

              {/* <div className="contract-filter">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contractFilter === 'no-contract'}
                      onChange={(event) => setContractFilter('no-contract')}
                      name="no-contract"
                    />
                  }
                  label="No Contract"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contractFilter === 'with-contract'}
                      onChange={(event) => setContractFilter('with-contract')}
                      name="with-contract"
                    />
                  }
                  label="With Contract"
                />
              </div> */}

              <div className={'internet-option-list'}>
                <FormControl component="fieldset" className="internet-option">
                  <RadioGroup
                    name={'offer' + providerService}
                    value={
                      offerOptionDictionary[providerService].selectedOfferID
                    }
                    onChange={(event, newValue) => {
                      const offerID = parseInt(newValue);
                      const offer = offerOptionDictionary[
                        providerService
                      ].offerList.filter(
                        (_offer) => _offer.OfferID === offerID
                      )[0];
                      updateOfferSelection(providerService, offer);
                    }}>
                    {groupOffersByProvider(
                      doOfferSort(
                        offerOptionDictionary[providerService].offerList
                      ),
                      offerList
                    ).map((grouping) => (
                      <div
                        className={
                          'offer-grouping' +
                          (!grouping.ApiID ? ' offsite-order' : '')
                        }>
                        <ProviderLogo offer={grouping} />

                        {grouping.offerList &&
                        grouping.offerList.filter(
                          (offer) =>
                            offer.IsConnectedCommunity &&
                            offer.ConnectedCommunityMessage
                        ).length ? (
                          <div
                            className="connected-community-message"
                            dangerouslySetInnerHTML={{
                              __html:
                                grouping.offerList[0].ConnectedCommunityMessage
                            }}></div>
                        ) : grouping.offerList &&
                          grouping.offerList.filter(
                            (offer) => offer.ApiExtractionInProgress
                          ).length ? (
                          <div className="extraction-in-progress">
                            <p>
                              Checking with {grouping.ProviderName} for the
                              latest plan pricing... This process usually takes
                              less than 30 seconds and the plan list will update
                              below when it completes.
                            </p>
                            <CircularProgress />
                          </div>
                        ) : (
                          <>
                            <>
                              {grouping.ApiID ? (
                                <FormControlLabel
                                  value={0}
                                  className={
                                    offerOptionDictionary[providerService]
                                      .selectedOfferID === 0
                                      ? ' selected'
                                      : ''
                                  }
                                  control={<Radio color="primary" />}
                                  label={
                                    <Table>
                                      <TableRow>
                                        <TableCell>
                                          No {providerService} service{' '}
                                        </TableCell>
                                        <TableCell className="download-speed"></TableCell>
                                        <TableCell className="price">
                                          $0.00 per month
                                        </TableCell>
                                      </TableRow>
                                    </Table>
                                  }
                                />
                              ) : null}
                            </>

                            {grouping.offerList
                              // .map((offer) =>
                              //   checkPriceDifference(
                              //     offerOptionDictionary[providerService]
                              //       .selectedOffer,
                              //     offer
                              //   )
                              // )
                              .filter((offer) => offer.OfferID > 0)
                              .map((offer) =>
                                true ? (
                                  // !offerOptionDictionary[providerService]
                                  //   .selectedOffer ||
                                  // offerOptionDictionary[providerService].expanded ||
                                  // offerOptionDictionary[providerService]
                                  //.selectedOfferID === offer.OfferID
                                  <FormControlLabel
                                    value={offer.OfferID}
                                    className={
                                      (offerOptionDictionary[providerService]
                                        .selectedOfferID === offer.OfferID
                                        ? 'selected'
                                        : '') +
                                      (mostPopularPlanOption[
                                        offer.DisplayName
                                      ] === true
                                        ? ' most-popular'
                                        : '')
                                    }
                                    disabled={
                                      providerService !== 'Internet' &&
                                      !checkBundleOptionExists(
                                        providerService,
                                        offer
                                      )
                                    }
                                    checked={
                                      offerOptionDictionary[providerService] &&
                                      offerOptionDictionary[providerService]
                                        .selectedOfferID === offer.OfferID
                                    }
                                    control={<Radio color="primary" />}
                                    label={
                                      <>
                                        {mostPopularPlanOption[
                                          offer.DisplayName
                                        ] ? (
                                          <div className="most-popular-banner">
                                            Most Popular Plan
                                          </div>
                                        ) : null}
                                        <Table>
                                          <TableRow>
                                            <TableCell>
                                              <strong>
                                                {offer.ProviderName}
                                              </strong>
                                              <span className="offer-name">
                                                {' - '}
                                                {offer.DisplayName.split(
                                                  offer.ProviderName
                                                )
                                                  .join('')
                                                  .split(
                                                    offer.ProviderName.toUpperCase()
                                                  )
                                                  .join('')}
                                              </span>{' '}
                                              <span
                                                className={
                                                  'keep-together price-difference ' +
                                                  offer.priceDifferenceClass
                                                }>
                                                {offer.priceDifferenceText}
                                              </span>
                                              <div className="detail-row">
                                                <div className="term">
                                                  {/* If the contract filter is off and there */}
                                                  {(!offer.contractTerm ||
                                                    !offer.contractRequired) &&
                                                  !offerCalc.getInternetPrice(
                                                    offer
                                                  ).isContract ? (
                                                    'No Contract'
                                                  ) : (
                                                    <>&nbsp;</>
                                                  )}

                                                  <Button
                                                    variant="outlined"
                                                    className="details-button"
                                                    size="small"
                                                    onClick={(event) => {
                                                      setOfferOptionDictionary({
                                                        ...offerOptionDictionary,
                                                        [providerService]: {
                                                          ...offerOptionDictionary[
                                                            providerService
                                                          ],
                                                          offerList:
                                                            offerOptionDictionary[
                                                              providerService
                                                            ].offerList.map(
                                                              (_offer) =>
                                                                _offer.OfferID ===
                                                                offer.OfferID
                                                                  ? {
                                                                      ..._offer,
                                                                      expanded:
                                                                        !_offer.expanded
                                                                    }
                                                                  : {
                                                                      ..._offer
                                                                    }
                                                            )
                                                        }
                                                      });
                                                      event.stopPropagation();
                                                    }}>
                                                    View Details
                                                  </Button>
                                                </div>

                                                <div className="term">
                                                  {offer.contractTerm &&
                                                  offerCalc.getInternetPrice(
                                                    offer
                                                  ).isContract ? (
                                                    <>
                                                      {offer.contractTerm}-mo
                                                      <br />
                                                      Contract
                                                    </>
                                                  ) : offer.TermLength > 1 ? (
                                                    <>
                                                      {offer.TermLength}-mo
                                                      <br />
                                                      Price Lock
                                                    </>
                                                  ) : null}
                                                </div>

                                                <div className="download-speed">
                                                  {providerService ===
                                                  'Internet' ? (
                                                    <>
                                                      {
                                                        offer.InternetDownloadSpeedMb
                                                      }
                                                      <br />
                                                      Mbps
                                                    </>
                                                  ) : providerService ===
                                                    'TV' ? (
                                                    <>
                                                      {offer.TVChannelCount}+
                                                      <br />
                                                      channels
                                                    </>
                                                  ) : null}
                                                </div>
                                                <div className="price">
                                                  <span>
                                                    $
                                                    {offerCalc
                                                      .getInternetPrice(offer)
                                                      .monthlyAmount.toFixed(
                                                        2
                                                      )}{' '}
                                                    <br />
                                                    per month
                                                  </span>
                                                </div>
                                              </div>
                                              {offer.expanded ? (
                                                <div
                                                  id={
                                                    'offer-options-' +
                                                    providerService.toLowerCase()
                                                  }
                                                  className={
                                                    'offer-options' +
                                                    (offer.expanded
                                                      ? ' options-visible'
                                                      : '')
                                                  }>
                                                  {offer.chargeDictionary
                                                    ? getChargeOptionList(
                                                        offer
                                                      ).map(
                                                        (
                                                          chargeGroup,
                                                          chargeGoupIndex
                                                        ) =>
                                                          chargeGroup ? (
                                                            <>
                                                              <h5>
                                                                {getChargeOptionList(
                                                                  offer
                                                                ).length > 1
                                                                  ? 'Step ' +
                                                                    (chargeGoupIndex +
                                                                      1) +
                                                                    ' of ' +
                                                                    getChargeOptionList(
                                                                      offer
                                                                    ).length +
                                                                    ': ' +
                                                                    chargeGroup
                                                                  : chargeGroup}
                                                              </h5>

                                                              <div className="internet-option-list">
                                                                <FormControl
                                                                  component="fieldset"
                                                                  className="internet-option">
                                                                  <RadioGroup
                                                                    name={
                                                                      chargeGroup
                                                                    }
                                                                    value={
                                                                      offer
                                                                        .chargeDictionary[
                                                                        chargeGroup
                                                                      ]
                                                                        .selectedValue
                                                                    }
                                                                    onChange={(
                                                                      event,
                                                                      newValue
                                                                    ) => {
                                                                      setOfferOptionDictionary(
                                                                        {
                                                                          ...offerOptionDictionary,
                                                                          [providerService]:
                                                                            {
                                                                              ...offerOptionDictionary[
                                                                                providerService
                                                                              ],
                                                                              offerList:
                                                                                offerOptionDictionary[
                                                                                  providerService
                                                                                ].offerList.map(
                                                                                  (
                                                                                    _offer
                                                                                  ) =>
                                                                                    _offer.OfferID ===
                                                                                    offer.OfferID
                                                                                      ? {
                                                                                          ..._offer,
                                                                                          chargeDictionary:
                                                                                            {
                                                                                              ...offerOptionDictionary[
                                                                                                providerService
                                                                                              ]
                                                                                                .chargeDictionary,
                                                                                              [chargeGroup]:
                                                                                                {
                                                                                                  ...offerOptionDictionary[
                                                                                                    providerService
                                                                                                  ]
                                                                                                    .chargeDictionary[
                                                                                                    chargeGroup
                                                                                                  ],
                                                                                                  selectedValue:
                                                                                                    newValue
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                      : {
                                                                                          ..._offer
                                                                                        }
                                                                                )
                                                                            }
                                                                        }
                                                                      );

                                                                      //If all radio options are complete and there are no checkboxes, scroll to the first provider service that isn't yet complete
                                                                      if (
                                                                        offerOptionDictionary
                                                                      ) {
                                                                        let checkboxesExist = false;
                                                                        let allOptionsHaveValues = true;
                                                                        Object.keys(
                                                                          offer.chargeDictionary
                                                                        ).map(
                                                                          (
                                                                            _chargeGroup
                                                                          ) => {
                                                                            if (
                                                                              _chargeGroup ===
                                                                              ''
                                                                            ) {
                                                                              checkboxesExist = true;
                                                                            }
                                                                            if (
                                                                              _chargeGroup !==
                                                                              chargeGroup
                                                                            ) {
                                                                              if (
                                                                                offer
                                                                                  .chargeDictionary[
                                                                                  _chargeGroup
                                                                                ]
                                                                                  .selectedValue ===
                                                                                undefined
                                                                              ) {
                                                                                allOptionsHaveValues = false;
                                                                              }
                                                                            }
                                                                          }
                                                                        );

                                                                        if (
                                                                          allOptionsHaveValues &&
                                                                          !checkboxesExist
                                                                        ) {
                                                                          let scroll = false;
                                                                          Object.keys(
                                                                            offerOptionDictionary
                                                                          ).map(
                                                                            (
                                                                              providerService
                                                                            ) => {
                                                                              if (
                                                                                !offerOptionDictionary[
                                                                                  providerService
                                                                                ]
                                                                                  .selectedOffer &&
                                                                                !scroll
                                                                              ) {
                                                                                scrollToProviderService(
                                                                                  providerService
                                                                                );
                                                                                scroll = true;
                                                                              }
                                                                            }
                                                                          );
                                                                        }
                                                                      }
                                                                    }}>
                                                                    {offer
                                                                      .chargeDictionary[
                                                                      chargeGroup
                                                                    ].chargeList
                                                                      .length >
                                                                      1 &&
                                                                    !offer
                                                                      .chargeDictionary[
                                                                      chargeGroup
                                                                    ]
                                                                      .isRequired &&
                                                                    offer.chargeDictionary[
                                                                      chargeGroup
                                                                    ].chargeList.filter(
                                                                      (
                                                                        charge
                                                                      ) =>
                                                                        !charge.monthlyAmount
                                                                    ).length ==
                                                                      0 ? (
                                                                      <RadioOption
                                                                        charge={{
                                                                          name:
                                                                            'No ' +
                                                                            chargeGroup,
                                                                          monthlyAmount:
                                                                            null
                                                                        }}
                                                                        providerService={
                                                                          providerService
                                                                        }
                                                                        chargeGroup={
                                                                          chargeGroup
                                                                        }
                                                                        offerOptionDictionary={
                                                                          offerOptionDictionary
                                                                        }
                                                                        setOfferOptionDictionary={
                                                                          setOfferOptionDictionary
                                                                        }
                                                                      />
                                                                    ) : null}
                                                                    {offer.chargeDictionary[
                                                                      chargeGroup
                                                                    ].chargeList.map(
                                                                      (
                                                                        charge
                                                                      ) => (
                                                                        <RadioOption
                                                                          charge={
                                                                            charge
                                                                          }
                                                                          providerService={
                                                                            providerService
                                                                          }
                                                                          chargeGroup={
                                                                            chargeGroup
                                                                          }
                                                                          offerOptionDictionary={
                                                                            offerOptionDictionary
                                                                          }
                                                                          setOfferOptionDictionary={
                                                                            setOfferOptionDictionary
                                                                          }
                                                                        />
                                                                      )
                                                                    )}
                                                                  </RadioGroup>
                                                                </FormControl>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <h5>
                                                                <IconButton
                                                                  className="details-close"
                                                                  onClick={(
                                                                    event
                                                                  ) => {
                                                                    setOfferOptionDictionary(
                                                                      {
                                                                        ...offerOptionDictionary,
                                                                        [providerService]:
                                                                          {
                                                                            ...offerOptionDictionary[
                                                                              providerService
                                                                            ],
                                                                            offerList:
                                                                              offerOptionDictionary[
                                                                                providerService
                                                                              ].offerList.map(
                                                                                (
                                                                                  _offer
                                                                                ) =>
                                                                                  _offer.OfferID ===
                                                                                  offer.OfferID
                                                                                    ? {
                                                                                        ..._offer,
                                                                                        expanded:
                                                                                          !_offer.expanded
                                                                                      }
                                                                                    : {
                                                                                        ..._offer
                                                                                      }
                                                                              )
                                                                          }
                                                                      }
                                                                    );
                                                                    event.stopPropagation();
                                                                    event.preventDefault();
                                                                  }}>
                                                                  <CloseIcon />
                                                                </IconButton>
                                                                Included in
                                                                Price
                                                              </h5>

                                                              <div className="internet-option-list">
                                                                {offer.chargeDictionary[
                                                                  chargeGroup
                                                                ].chargeList
                                                                  //.filter((addOn) => addOn.monthlyAmount > 0)
                                                                  .map(
                                                                    (addOn) => (
                                                                      <CheckboxOption
                                                                        offerOptionDictionary={
                                                                          offerOptionDictionary
                                                                        }
                                                                        providerService={
                                                                          providerService
                                                                        }
                                                                        chargeGroup={
                                                                          chargeGroup
                                                                        }
                                                                        addOn={
                                                                          addOn
                                                                        }
                                                                        setOfferOptionDictionary={
                                                                          setOfferOptionDictionary
                                                                        }
                                                                        offer={
                                                                          offer
                                                                        }
                                                                      />
                                                                    )
                                                                  )}
                                                              </div>

                                                              <InternetChart
                                                                offer={offer}
                                                                noDefaultTab
                                                              />
                                                            </>
                                                          )
                                                      )
                                                    : null}
                                                </div>
                                              ) : null}
                                            </TableCell>
                                          </TableRow>
                                        </Table>
                                      </>
                                    }
                                  />
                                ) : null
                              )}
                          </>
                        )}

                        {!grouping.ApiID ? (
                          <>
                            <p className="offsite-link-direction">
                              Go to the {grouping.ProviderName} website to sign
                              up
                              <ChevronRightIcon />
                            </p>
                            <Button
                              className="offsite-button"
                              href={grouping.OffsiteSignUpUrl}
                              target="mi_offsite"></Button>
                          </>
                        ) : null}
                      </div>
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>

              {offerOptionDictionary[providerService].offsiteSignUpOfferList &&
              offerOptionDictionary[providerService].offsiteSignUpOfferList
                .length &&
              (!offerOptionDictionary[providerService].selectedOfferID ||
                offerOptionDictionary[providerService].expanded) ? (
                <ul className="offsite-offer-list">
                  {offerOptionDictionary[
                    providerService
                  ].offsiteSignUpOfferList.map((offer) => (
                    <li>
                      <strong>{offer.ProviderName}</strong>
                      This provider may offer{' '}
                      {offer.InternetDownloadSpeedMb
                        ? ' Internet speeds up to ' +
                          offer.InternetDownloadSpeedMb +
                          ' Mbps '
                        : ' service '}{' '}
                      at this address, but we don't have their plan details or
                      pricing.{' '}
                      <a
                        className="link"
                        href={offer.OffsiteSignUpUrl}
                        target="mi_offsite">
                        Click to go to their site
                      </a>
                    </li>
                  ))}
                </ul>
              ) : null}

              {offerOptionDictionary[providerService].satelliteOfferList &&
              offerOptionDictionary[providerService].satelliteOfferList
                .length &&
              (!offerOptionDictionary[providerService].selectedOfferID ||
                offerOptionDictionary[providerService].expanded) ? (
                <>
                  <h4
                    className={
                      'satellite-option-expand' +
                      (offerOptionDictionary[providerService].satelliteExpanded
                        ? ' expanded'
                        : '')
                    }>
                    <Button
                      onClick={() =>
                        setOfferOptionDictionary({
                          ...offerOptionDictionary,
                          [providerService]: {
                            ...offerOptionDictionary[providerService],
                            satelliteExpanded:
                              !offerOptionDictionary[providerService]
                                .satelliteExpanded
                          }
                        })
                      }>
                      Satellite Options <KeyboardArrowDownIcon />
                    </Button>
                  </h4>
                  {offerOptionDictionary[providerService].satelliteExpanded ? (
                    <ul className={'offsite-offer-list'}>
                      {offerOptionDictionary[
                        providerService
                      ].satelliteOfferList.map((offer) => (
                        <li>
                          <strong>{offer.ProviderName}</strong>
                          This provider may offer{' '}
                          {offer.InternetDownloadSpeedMb
                            ? ' Internet speeds up to ' +
                              offer.InternetDownloadSpeedMb +
                              ' Mbps '
                            : ' service '}{' '}
                          at this address, but we don't have their plan details
                          or pricing.{' '}
                          <a
                            className="link"
                            href={
                              offer.OffsiteSignUpUrl
                                ? offer.OffsiteSignUpUrl
                                : offer.WebsiteUrl
                            }
                            target="mi_offsite">
                            Click to go to their site
                          </a>
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        ) : null
      )}

      {showOfferDetails ? (
        <OfferDetailsPopup
          offer={showOfferDetails}
          closeFunc={() => setShowOfferDetails(null)}
        />
      ) : null}
    </>
  ) : null;
}

function CheckboxOption({
  offerOptionDictionary,
  providerService,
  chargeGroup,
  addOn,
  setOfferOptionDictionary,
  offer
}) {
  return (
    <FormControl
      component="fieldset"
      className={
        'internet-option checkbox' + (addOn.isRequired ? ' required' : '')
      }>
      <FormControlLabel
        control={
          <Checkbox
            checked={
              offer.chargeDictionary[chargeGroup].selectedValueList &&
              offer.chargeDictionary[chargeGroup].selectedValueList[
                addOn.name
              ] === true
            }
            disabled={
              (addOn.monthlyAmount === 0 && !addOn.standardPrice) ||
              (addOn.isNoOption &&
                offer.chargeDictionary[chargeGroup].selectedValueList &&
                Object.keys(
                  offer.chargeDictionary[chargeGroup].selectedValueList
                ).filter(
                  (chargeName) =>
                    chargeName !== addOn.name &&
                    offer.chargeDictionary[chargeGroup].selectedValueList[
                      chargeName
                    ]
                ).length > 0) ||
              addOn.isRequired
            }
            onChange={(event) =>
              setOfferOptionDictionary({
                ...offerOptionDictionary,
                [providerService]: {
                  ...offerOptionDictionary[providerService],
                  offerList: offerOptionDictionary[
                    providerService
                  ].offerList.map((_offer) =>
                    _offer.OfferID === offer.OfferID
                      ? {
                          ..._offer,
                          chargeDictionary: {
                            ..._offer.chargeDictionary,
                            [chargeGroup]: {
                              ..._offer.chargeDictionary[chargeGroup],
                              selectedValueList: {
                                ..._offer.chargeDictionary[chargeGroup]
                                  .selectedValueList,
                                ['No ' + providerService + ' Add-ons']: false,
                                [addOn.name]: event.target.checked
                              }
                            }
                          }
                        }
                      : { ..._offer }
                  )
                }
              })
            }
          />
        }
        label={
          <Table>
            <TableRow>
              <TableCell>{addOn.name}</TableCell>
              <TableCell className="price">
                {addOn.monthlyAmount ? (
                  <>${parseFloat(addOn.monthlyAmount).toFixed(2)} per month</>
                ) : addOn.purchaseAmount ? (
                  <>
                    ${parseFloat(addOn.purchaseAmount).toFixed(2)} one-time fee
                  </>
                ) : (
                  <>
                    {addOn.monthlyAmount === 0
                      ? addOn.standardPriceText
                        ? addOn.standardPriceText
                        : addOn.contractRequired
                        ? ''
                        : 'No Charge'
                      : ''}
                  </>
                )}
              </TableCell>
            </TableRow>
          </Table>
        }
      />
    </FormControl>
  );
}

function getEnumeration(max) {
  let values = [];
  for (let i = 1; i <= max; i++) {
    values.push(i);
  }
  return values;
}

function RadioOption({
  charge,
  providerService,
  chargeGroup,
  offerOptionDictionary,
  setOfferOptionDictionary
}) {
  return (
    <FormControlLabel
      value={charge.name}
      className={
        'radio' +
        (offer.chargeDictionary[chargeGroup].selectedValue === charge.name
          ? ' selected'
          : '')
      }
      control={<Radio color="primary" />}
      disabled={
        offer.chargeDictionary[chargeGroup].isRequired &&
        offer.chargeDictionary[chargeGroup].chargeList.length === 1
      }
      label={
        <Table>
          <TableRow>
            <TableCell>
              {charge.name}
              {offer.chargeDictionary[chargeGroup].isRequired &&
              offer.chargeDictionary[chargeGroup].chargeList.length === 1
                ? ' (Required)'
                : ''}
            </TableCell>
            <TableCell className="quantity-menu">
              {charge.maxCount > 1 &&
              offer.chargeDictionary[chargeGroup].selectedValue ===
                charge.name ? (
                <Select
                  variant="standard"
                  key={charge.name}
                  value={offer.chargeDictionary[chargeGroup].selectedItemCount}
                  onChange={(event) => {
                    setOfferOptionDictionary({
                      ...offerOptionDictionary,
                      [providerService]: {
                        ...offerOptionDictionary[providerService],
                        offerList: offerOptionDictionary[
                          providerService
                        ].offerList.map((_offer) =>
                          _offer.OfferID === offer.OfferID
                            ? {
                                ..._offer,
                                chargeDictionary: {
                                  ...offerOptionDictionary[providerService]
                                    .chargeDictionary,
                                  [chargeGroup]: {
                                    ...offerOptionDictionary[providerService]
                                      .chargeDictionary[chargeGroup],
                                    selectedItemCount: parseInt(
                                      event.target.value
                                    )
                                  }
                                }
                              }
                            : { ..._offer }
                        )
                      }
                    });
                  }}>
                  {getEnumeration(charge.maxCount).map((value) => (
                    <MenuItem value={value}>{value}</MenuItem>
                  ))}
                </Select>
              ) : null}
            </TableCell>
            <TableCell className="price">
              {charge.monthlyAmount ? (
                <>${parseFloat(charge.monthlyAmount).toFixed(2)} per month</>
              ) : charge.purchaseAmount ? (
                <>
                  ${parseFloat(charge.purchaseAmount).toFixed(2)} one-time fee
                </>
              ) : (
                <>
                  {charge.monthlyAmount === 0
                    ? 'Included'
                    : !charge.monthlyAmount && !charge.purchaseAmount
                    ? 'No charge'
                    : ''}
                </>
              )}
            </TableCell>
          </TableRow>
          {charge.name === 'Use My Own Equipment' ? (
            <TableRow>
              <TableCell colSpan={3} className="equipment-disclaimer">
                I understand that only modems and gateways purchased or rented
                directly from{' '}
                {
                  offerOptionDictionary[providerService].selectedOffer
                    .ProviderName
                }{' '}
                are supported by{' '}
                {
                  offerOptionDictionary[providerService].selectedOffer
                    .ProviderName
                }{' '}
                Customer Support.
              </TableCell>
            </TableRow>
          ) : null}
        </Table>
      }
    />
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    stateList: state.commonData.stateList,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StepPlanBuilder);
