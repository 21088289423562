import React from 'react';
import Dropzone from 'react-dropzone-uploader';

function ServiceAddendumFileUpload({
  profileKey,
  providerService,
  setProviderServiceValue,
  label,
  fyi,
  optional
}) {
  return (
    <>
      <p>
        {optional ? 'Optional:' : ''}
        <Dropzone
          getUploadParams={() => {
            return {
              url:
                process.env.GATSBY_API_URL +
                'PropertyProfileUpload/UploadLeaseAddendum?profileKey=' +
                profileKey +
                '&providerServiceID=' +
                providerService.ProviderServiceID,
              method: 'POST',
              withCredentials: false,
              headers: {
                'Cache-Control': ''
              }
            };
          }}
          onChangeStatus={({ meta, remove, xhr }, status) => {
            if (status == 'done') {
              remove();
              try {
                const result = JSON.parse(xhr.response);
                if (result && result.ServiceAddendumFile) {
                  setProviderServiceValue(providerService, {
                    ServiceAddendumFile: result.ServiceAddendumFile
                  });
                }
              } catch (e) {}
            }
          }}
          maxFiles={1}
          accept=".pdf"
          multiple={false}
          canCancel={false}
          inputContent={<> {label} </>}
        />{' '}
        {fyi ? (
          <>
            <br />
            {fyi}
          </>
        ) : null}
      </p>
    </>
  );
}

export default ServiceAddendumFileUpload;
