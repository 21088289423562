import React from 'react';
import moment from 'moment';

import { formatPhone } from '../../api/dataApi';

export function getFormFields(
  residentFormID,
  residentOnboarding,
  role,
  occupantID,
  isProperty
) {
  let _formFields = {};

  const occupant =
    occupantID &&
    residentOnboarding &&
    residentOnboarding.OccupantList &&
    residentOnboarding.OccupantList.length
      ? residentOnboarding.OccupantList.filter(
          (_occupant) => _occupant.OccupantID === occupantID
        )[0]
      : null;

  const residentName = occupantID
    ? occupant
      ? occupant.FirstName + ' ' + occupant.LastName
      : ''
    : residentOnboarding.Header.FirstName +
      ' ' +
      residentOnboarding.Header.LastName;

  switch (residentFormID) {
    case 1: //Employment Verification
      if (!role || role === 'Resident') {
        _formFields = {
          Applicant: {
            Title: 'Authorization to Release Employment Information',
            Fields: {}
          }
        };

        _formFields.Applicant.Fields.Applicant_FullName = {
          Title: 'Applicant:',
          Label: 'Applicant Full Name',
          Required: true,
          Value: ''
        };

        _formFields.Applicant.Fields.Applicant_IsEmployed = {
          Label: 'Is this applicant currently employed?',
          Required: true,
          FieldType: 'Radio',
          Value: ''
        };

        _formFields.Applicant.Fields.Applicant_CurrentAddress_Line1 = {
          Label: 'Applicant Current Street Address',
          Value: '',
          RequiredField: 'Applicant_IsEmployed',
          RequiredFieldValue: 'Yes'
        };

        _formFields.Applicant.Fields.Applicant_CurrentAddress_Line2 = {
          Label: 'Address Line 2',
          Required: false,
          Value: '',
          HideIfNoValue: true
        };

        _formFields.Applicant.Fields.Applicant_CurrentAddress_City = {
          Label: 'City',
          Value: '',
          Width: '50%',
          RequiredField: 'Applicant_IsEmployed',
          RequiredFieldValue: 'Yes'
        };

        _formFields.Applicant.Fields.Applicant_CurrentAddress_State = {
          Label: 'State',
          Value: '',
          Width: '25%',
          FieldType: 'state',
          RequiredField: 'Applicant_IsEmployed',
          RequiredFieldValue: 'Yes'
        };

        _formFields.Applicant.Fields.Applicant_CurrentAddress_Zip = {
          Label: 'Zip',
          Value: '',
          Width: '25%',
          TextType: 'number',
          RequiredField: 'Applicant_IsEmployed',
          RequiredFieldValue: 'Yes'
        };

        _formFields.Applicant.Fields.Applicant_Email = {
          Label: "Applicant's Email Address",
          Value: '',
          TextType: 'email',
          RequiredField: 'Applicant_IsEmployed',
          RequiredFieldValue: 'Yes'
        };

        _formFields.Applicant.Fields.Applicant_Employer_Name = {
          Title: "Applicant's Employer:",
          Label: 'Employer Name',
          SpaceBefore: true,
          Value: '',
          RequiredField: 'Applicant_IsEmployed',
          RequiredFieldValue: 'Yes'
        };

        _formFields.Applicant.Fields.Applicant_Employer_Address_Line1 = {
          Label: 'Employer Street Address',
          Value: '',
          RequiredField: 'Applicant_IsEmployed',
          RequiredFieldValue: 'Yes'
        };

        _formFields.Applicant.Fields.Applicant_Employer_Address_Line2 = {
          Label: 'Address Line 2',

          Value: '',
          HideIfNoValue: true
        };

        _formFields.Applicant.Fields.Applicant_Employer_Address_City = {
          Label: 'City',
          Value: '',
          Width: '50%',
          RequiredField: 'Applicant_IsEmployed',
          RequiredFieldValue: 'Yes'
        };

        _formFields.Applicant.Fields.Applicant_Employer_Address_State = {
          Label: 'State',
          Value: '',
          Width: '25%',
          FieldType: 'state',
          RequiredField: 'Applicant_IsEmployed',
          RequiredFieldValue: 'Yes'
        };

        _formFields.Applicant.Fields.Applicant_Employer_Address_Zip = {
          Label: 'Zip',
          Value: '',
          Width: '25%',
          TextType: 'number',
          RequiredField: 'Applicant_IsEmployed',
          RequiredFieldValue: 'Yes'
        };

        _formFields.Applicant.Fields.Applicant_Employer_Phone = {
          Label: 'Employer Phone',
          Value: '',
          TextType: 'phone',
          RequiredField: 'Applicant_IsEmployed',
          RequiredFieldValue: 'Yes'
        };

        _formFields.Applicant.Fields.Applicant_Employer_Email = {
          Label: 'Employer Email Address',
          Value: '',
          TextType: 'email',
          RequiredField: 'Applicant_IsEmployed',
          RequiredFieldValue: 'Yes'
        };

        _formFields.Applicant.Fields.Applicant_Authorized_ThirdParty = {
          SpaceBefore: true,
          Title: 'Authorized Recipients:',
          Label: 'Authorized Third-Party Agent',
          Value: 'MovingIN LLC',
          ReadOnly: true,
          RequiredField: 'Applicant_IsEmployed',
          RequiredFieldValue: 'Yes'
        };

        _formFields.Applicant.Fields.Applicant_Authorized_ManagementCompany = {
          Label: 'Authorized Management Company',
          Value: residentOnboarding.Header.ManagementCompanyName,
          ReadOnly: true,
          HideIfNoValue: true
        };

        _formFields.Applicant.Fields.Applicant_Authorized_PropertyName = {
          Label: 'Community Name',
          Value: residentOnboarding.Header.PropertyName,
          ReadOnly: true,
          HideIfNoValue: true
        };

        _formFields.Applicant.Fields.Applicant_Authorized_PropertyPhone = {
          Label: 'Community Phone',
          Value: residentOnboarding.Header.PropertyPhone,
          ReadOnly: true,
          HideIfNoValue: true
        };

        _formFields.Applicant.Fields.Applicant_Authorized_PropertyEmail = {
          Label: 'Community Email',
          Value: residentOnboarding.Header.PropertyEmail,
          ReadOnly: true,
          HideIfNoValue: true
        };

        _formFields.Applicant.Fields.Applicant_Signature_Value = {
          Label: "Applicant's Signature",
          Required: true,
          Value: '',
          Placeholder: 'Type name to sign',
          Width: '65%',
          SpaceBefore: true,
          DirectionsComponent: ({ formFields }) => {
            return formFields ? (
              <p>
                <strong>
                  {formFields.Applicant.Fields.Applicant_IsEmployed.Value ==
                  'Yes' ? (
                    <>
                      I hereby authorize and request that{' '}
                      {formFields.Applicant.Fields.Applicant_Employer_Name.Value
                        ? formFields.Applicant.Fields.Applicant_Employer_Name
                            .Value + ' '
                        : ' Employer '}{' '}
                      provide the Authorized Recipients above with my employment
                      information upon request.
                    </>
                  ) : null}{' '}
                  By typing my name below, I am consenting to the use of this
                  digital form and intending to electronically sign this
                  authorization.
                </strong>
              </p>
            ) : null;
          }
        };

        _formFields.Applicant.Fields.Applicant_Signature_Date = {
          Label: 'Date Signed',
          Required: true,
          ReadOnly: true,
          Value: moment().format('MM/DD/YYYY'),
          Width: '35%',
          TextType: 'date'
        };
      } else {
        _formFields = {
          Employer: {
            Title: isProperty
              ? 'Employment Verification - Manual Confirmation Process'
              : 'Employment Information to be Completed by Employer',
            Fields: {}
          }
        };

        if (isProperty) {
          _formFields.Employer.Fields.Employer_PropertyOverride_OperatorName = {
            Title: 'Who is completing this form?',
            Label: 'Your name and title',
            Required: true,
            Value: ''
          };

          _formFields.Employer.Fields.Employer_PropertyOverride_VerifierName = {
            Label: 'Who did you speak with - name and title?',
            Required: true,
            Value: ''
          };

          _formFields.Employer.Fields.Employer_PropertyOverride_Employer_Name =
            {
              Label: 'Employer Name',
              Required: true,
              ReadOnly: true,
              Value: ''
            };

          _formFields.Employer.Fields.Employer_PropertyOverride_Employer_Phone =
            {
              Label: 'Employer Phone',
              Required: true,
              ReadOnly: true,
              Value: '',
              Formatter: formatPhone
            };
        }

        _formFields.Employer.Fields.Employer_EmployeeName = {
          Title: isProperty
            ? "Ask and answer the following questions while speaking with the applicant's employer."
            : null,
          Label: 'Employee Name',
          Required: true,
          Value: ''
        };

        _formFields.Employer.Fields.Employer_EmployeeTitle = {
          Label: "Employee's Position or Title",
          Required: true,
          Value: ''
        };

        _formFields.Employer.Fields.Employer_EmployeeIsEmployed = {
          Label: 'Is Employee Presently Employed?',
          Required: true,
          Value: '',
          FieldType: 'Radio'
        };

        _formFields.Employer.Fields.Employer_EmploymentStartDate = {
          Label: 'Approximate Start Date of Employment',
          Required: true,
          Value: '',
          TextType: 'date'
        };

        _formFields.Employer.Fields.Employer_EmployeeSalary_Amount = {
          Label: 'Current Wage or Salary',
          Required: true,
          Value: '',
          TextType: 'number',
          StartAdornment: '$',
          Width: '60%'
        };

        _formFields.Employer.Fields.Employer_EmployeeSalary_Frequency = {
          Label: 'Pay Frequency',
          Required: true,
          Value: '',
          Width: '40%',
          FieldType: 'PayFrequency'
        };

        _formFields.Employer.Fields.Employer_Employee_HoursPerWeek = {
          Label: 'Average Number of Hours Worked Per Week',
          Required: true,
          Value: '',
          TextType: 'number'
        };

        _formFields.Employer.Fields.Employer_Employee_OverTime_Hours = {
          Label: 'Average Overtime Hours Per Week',
          Value: '',
          TextType: 'number'
        };

        _formFields.Employer.Fields.Employer_Employee_OverTime_PayAmount = {
          Label: 'Overtime Rate of Pay (Hourly Employees Only)',
          Value: '',
          TextType: 'number',
          Width: '60%',
          StartAdornment: '$'
        };

        _formFields.Employer.Fields.Employer_Employee_OverTime_PayFrequency = {
          Label: 'Pay Frequency',
          Value: '',
          Width: '40%',
          FieldType: 'PayFrequency'
        };

        _formFields.Employer.Fields.Employer_Signature_Value = {
          Label: "Verifier's Signature",
          Placeholder: 'Type name to sign',
          Required: true,
          Value: '',
          Width: '35%',
          SpaceBefore: true,
          DirectionsComponent: ({ formFields }) => {
            return formFields ? (
              <p>
                <strong>
                  The above information is correct to the best of my knowledge
                  as of the date below. By typing my name below, I am consenting
                  to the use of this digital form and intending to
                  electronically sign this verification.
                </strong>
              </p>
            ) : null;
          }
        };

        _formFields.Employer.Fields.Employer_Signature_Title = {
          Label: "Verifier's Title",
          Required: true,
          Value: '',
          Width: '35%'
        };

        _formFields.Employer.Fields.Employer_Signature_Date = {
          Label: 'Date Signed',
          Required: true,
          ReadOnly: true,
          Value: moment().format('MM/DD/YYYY'),
          Width: '35%',
          TextType: 'date'
        };
      }

      break;

    case 2: //Rental History Verification
      if (!role || role === 'Resident') {
        _formFields = {
          Applicant: {
            Title: 'Authorization to Release Rental History',
            Fields: {}
          }
        };

        _formFields.Applicant.Fields.Applicant_FullName = {
          Title: 'Applicant:',
          Label: 'Applicant Full Name',
          Required: true,
          Value: ''
        };

        _formFields.Applicant.Fields.Applicant_Landlord_History = {
          Label: 'Are you currently renting your home?',
          Required: true,
          SpaceBefore: true,
          FieldType: 'Radio',
          OptionList: [
            'Yes',
            'No, I am moving from a home I own(ed) and do not have a landlord',
            'No, I am a first time renter and do not have a current landlord'
          ],
          Value: ''
        };

        _formFields.Applicant.Fields.Applicant_CurrentAddress_Line1 = {
          Label: 'Applicant Current Street Address',
          RequiredField: 'Applicant_Landlord_History',
          RequiredFieldValue: 'Yes',
          Value: ''
        };

        _formFields.Applicant.Fields.Applicant_CurrentAddress_Line2 = {
          Label: 'Address Line 2',

          HideIfNoValue: true
        };

        _formFields.Applicant.Fields.Applicant_CurrentAddress_City = {
          Label: 'City',
          RequiredField: 'Applicant_Landlord_History',
          RequiredFieldValue: 'Yes',
          Value: '',
          Width: '50%'
        };

        _formFields.Applicant.Fields.Applicant_CurrentAddress_State = {
          Label: 'State',
          RequiredField: 'Applicant_Landlord_History',
          RequiredFieldValue: 'Yes',
          Value: '',
          Width: '25%',
          FieldType: 'state'
        };

        _formFields.Applicant.Fields.Applicant_CurrentAddress_Zip = {
          Label: 'Zip',
          RequiredField: 'Applicant_Landlord_History',
          RequiredFieldValue: 'Yes',
          Value: '',
          Width: '25%',
          TextType: 'number'
        };

        _formFields.Applicant.Fields.Applicant_Email = {
          Label: "Applicant's Email Address",
          RequiredField: 'Applicant_Landlord_History',
          RequiredFieldValue: 'Yes',
          Value: '',
          TextType: 'email'
        };

        // _formFields.Applicant.Fields.Applicant_Landlord_Type = {
        //   Label: 'The following is my (choose one):',
        //   RequiredField: 'Applicant_Landlord_History',
        //   RequiredFieldValue: 'Yes',
        //   SpaceBefore: true,
        //   FieldType: 'Radio',
        //   OptionList: ['Current Landlord', 'Former Landlord'],
        //   Value: ''
        // };

        _formFields.Applicant.Fields.Applicant_Landlord_Name = {
          Label: 'Landlord Name',
          RequiredField: 'Applicant_Landlord_History',
          RequiredFieldValue: 'Yes',
          Value: ''
        };

        _formFields.Applicant.Fields.Applicant_Landlord_Address_Line1 = {
          Label: 'Landlord Street Address',
          RequiredField: 'Applicant_Landlord_History',
          RequiredFieldValue: 'Yes',
          Value: ''
        };

        _formFields.Applicant.Fields.Applicant_Landlord_Address_Line2 = {
          Label: 'Address Line 2',
          Required: false,
          Value: '',
          HideIfNoValue: true
        };

        _formFields.Applicant.Fields.Applicant_Landlord_Address_City = {
          Label: 'City',
          RequiredField: 'Applicant_Landlord_History',
          RequiredFieldValue: 'Yes',
          Value: '',
          Width: '50%'
        };

        _formFields.Applicant.Fields.Applicant_Landlord_Address_State = {
          Label: 'State',
          RequiredField: 'Applicant_Landlord_History',
          RequiredFieldValue: 'Yes',
          Value: '',
          Width: '25%',
          FieldType: 'state'
        };

        _formFields.Applicant.Fields.Applicant_Landlord_Address_Zip = {
          Label: 'Zip',
          RequiredField: 'Applicant_Landlord_History',
          RequiredFieldValue: 'Yes',
          Value: '',
          Width: '25%',
          TextType: 'number'
        };

        _formFields.Applicant.Fields.Applicant_Landlord_Phone = {
          Label: 'Landlord Phone',
          RequiredField: 'Applicant_Landlord_History',
          RequiredFieldValue: 'Yes',
          Value: '',
          TextType: 'phone'
        };

        _formFields.Applicant.Fields.Applicant_Landlord_Email = {
          Label: 'Landlord Email Address',
          RequiredField: 'Applicant_Landlord_History',
          RequiredFieldValue: 'Yes',
          Value: '',
          TextType: 'email'
        };

        _formFields.Applicant.Fields.Applicant_Authorized_ThirdParty = {
          SpaceBefore: true,
          Title: 'Authorized Recipients:',
          Label: 'Authorized Third-Party Agent',
          Value: 'MovingIN LLC',
          ReadOnly: true
        };

        _formFields.Applicant.Fields.Applicant_Authorized_ManagementCompany = {
          Label: 'Authorized Management Company',
          Value: residentOnboarding.Header.ManagementCompanyName,
          ReadOnly: true,
          HideIfNoValue: true
        };

        _formFields.Applicant.Fields.Applicant_Authorized_PropertyName = {
          Label: 'Community Name',
          Value: residentOnboarding.Header.PropertyName,
          ReadOnly: true,
          HideIfNoValue: true
        };

        _formFields.Applicant.Fields.Applicant_Authorized_PropertyPhone = {
          Label: 'Community Phone',
          Value: residentOnboarding.Header.PropertyPhone,
          ReadOnly: true,
          HideIfNoValue: true
        };

        _formFields.Applicant.Fields.Applicant_Authorized_PropertyEmail = {
          Label: 'Community Email',
          Value: residentOnboarding.Header.PropertyEmail,
          ReadOnly: true,
          HideIfNoValue: true
        };

        _formFields.Applicant.Fields.Applicant_Signature_Value = {
          Label: "Applicant's Signature",
          Required: true,
          Value: '',
          Placeholder: 'Type name to sign',
          Width: '65%',
          SpaceBefore: true,
          DirectionsComponent: ({ formFields }) => {
            return formFields ? (
              <p>
                <strong>
                  {formFields.Applicant.Fields.Applicant_Landlord_History
                    .Value === 'Yes' ? (
                    <>
                      I hereby authorize and request that{' '}
                      {formFields.Applicant.Fields.Applicant_Landlord_Name.Value
                        ? formFields.Applicant.Fields.Applicant_Landlord_Name
                            .Value + ' '
                        : ' Landlord '}{' '}
                      provide the Authorized Recipients above with my rental
                      history upon request.
                    </>
                  ) : null}{' '}
                  By typing my name below, I am consenting to the use of this
                  digital form and intending to electronically sign this
                  authorization.
                </strong>
              </p>
            ) : null;
          }
        };

        _formFields.Applicant.Fields.Applicant_Signature_Date = {
          Label: 'Date Signed',
          Required: true,
          ReadOnly: true,
          Value: moment().format('MM/DD/YYYY'),
          Width: '35%',
          TextType: 'date'
        };
      } else {
        _formFields = {
          Landlord: {
            Title: isProperty
              ? 'Rental History - Manual Confirmation Process'
              : 'Rental History to be Completed by Landlord',
            Fields: {}
          }
        };

        if (isProperty) {
          _formFields.Landlord.Fields.Landlord_PropertyOverride_OperatorName = {
            Title: 'Who is completing this form?',
            Label: 'Your name and title',
            Required: true,
            Value: ''
          };

          _formFields.Landlord.Fields.Landlord_PropertyOverride_VerifierName = {
            Label: 'Who did you speak with - name and title?',
            Required: true,
            Value: ''
          };

          _formFields.Landlord.Fields.Landlord_PropertyOverride_Landlord_Name =
            {
              Label: 'Landlord Name',
              Required: true,
              ReadOnly: true,
              Value: ''
            };

          _formFields.Landlord.Fields.Landlord_PropertyOverride_Landlord_Phone =
            {
              Label: 'Landlord Phone',
              Required: true,
              ReadOnly: true,
              Value: '',
              Formatter: formatPhone
            };
        }

        _formFields.Landlord.Fields.Landlord_IsCurrent = {
          Title: isProperty
            ? 'Ask and answer the following questions while speaking with the applicant landlord.'
            : null,
          Label: 'Are you the applicant’s current landlord?',
          Required: true,
          Value: '',
          FieldType: 'Radio'
        };

        _formFields.Landlord.Fields.Landlord_IsRelativeOrFriend = {
          Label: 'Are you a relative or friend of the applicant?',
          Required: true,
          Value: '',
          FieldType: 'Radio'
        };

        _formFields.Landlord.Fields.Landlord_IsCurrentLease = {
          Label: 'Does the applicant have a current lease agreement with you?',
          Required: true,
          Value: '',
          FieldType: 'Radio'
        };

        _formFields.Landlord.Fields.Landlord_Current_LeaseDateStart = {
          Label: 'Start date of current lease?',
          IsFollowUp: true,
          Required: false,
          RequiredField: 'Landlord_IsCurrentLease',
          RequiredFieldValue: 'Yes',
          Value: '',
          TextType: 'date',
          Width: '50%'
        };

        _formFields.Landlord.Fields.Landlord_Current_LeaseDateEnd = {
          Label: 'End date of current lease?',
          IsFollowUp: true,
          Required: false,
          RequiredField: 'Landlord_IsCurrentLease',
          RequiredFieldValue: 'Yes',
          Value: '',
          TextType: 'date',
          Width: '50%'
        };

        _formFields.Landlord.Fields.Landlord_GaveNotice = {
          SpaceBefore: true,
          Label:
            'Has the applicant given you proper notice of their intent to vacate?',
          Required: true,
          Value: '',
          FieldType: 'Radio'
        };

        _formFields.Landlord.Fields.Landlord_Current_MonthlyRent = {
          Label: 'Amount of current monthly rent?',
          Required: true,
          Value: '',
          TextType: 'number',
          StartAdornment: '$',
          Width: '50%'
        };

        _formFields.Landlord.Fields.Landlord_IsPrompt = {
          SpaceBefore: true,
          Label: 'Is the applicant a prompt payer?',
          Required: true,
          Value: '',
          FieldType: 'Radio'
        };

        _formFields.Landlord.Fields.Landlord_HasLateOrBouncedPayment = {
          Label: 'Have there been any late payments or bounced checks?',
          Required: true,
          Value: '',
          FieldType: 'Radio'
        };

        _formFields.Landlord.Fields.Landlord_CountLatePayment = {
          Label: 'Number of late payments?',
          IsFollowUp: true,
          Required: false,
          RequiredField: 'Landlord_HasLateOrBouncedPayment',
          RequiredFieldValue: 'Yes',
          Value: '',
          TextType: 'number',
          Width: '50%'
        };

        _formFields.Landlord.Fields.Landlord_CountBouncedCheck = {
          Label: 'Number of bounced checks?',
          IsFollowUp: true,
          Required: false,
          RequiredField: 'Landlord_HasLateOrBouncedPayment',
          RequiredFieldValue: 'Yes',
          Value: '',
          TextType: 'number',
          Width: '50%'
        };

        _formFields.Landlord.Fields.Landlord_InGoodStanding = {
          SpaceBefore: true,
          Label: 'Is the applicant in good standing with you (paid in full)?',
          Required: true,
          Value: '',
          FieldType: 'Radio'
        };

        _formFields.Landlord.Fields.Landlord_CurrentStanding = {
          Label:
            'What is the applicant’s current standing with you (choose one)?',
          Required: false,
          IsFollowUp: true,
          RequiredField: 'Landlord_InGoodStanding',
          RequiredFieldValue: 'No',
          Value: '',
          FieldType: 'Radio',
          OptionList: [
            'Past Due',
            'Eviction Proceeding',
            'Collections',
            'Other'
          ]
        };

        _formFields.Landlord.Fields.Landlord_CurrentStanding_Detail = {
          Label: 'Please add more details here',
          Required: false,
          IsFollowUp: true,
          RequiredField: 'Landlord_InGoodStanding',
          RequiredFieldValue: 'No',
          Value: ''
        };

        _formFields.Landlord.Fields.Landlord_IsClean = {
          SpaceBefore: true,
          Label:
            'Does the applicant keep the apartment clean, safe, and sanitary?',
          Required: true,
          Value: '',
          FieldType: 'Radio'
        };

        _formFields.Landlord.Fields.Landlord_IsClean_Detail = {
          Label: 'Please add more details here',
          Required: false,
          IsFollowUp: true,
          RequiredField: 'Landlord_IsClean',
          RequiredFieldValue: 'No',
          Value: ''
        };

        _formFields.Landlord.Fields.Landlord_HasDamage = {
          Label:
            'Has the applicant caused any known or documented damage to the property or their unit (vandalism, misuse, neglect, etc.)?',
          Required: true,
          Value: '',
          FieldType: 'Radio'
        };

        _formFields.Landlord.Fields.Landlord_HasDamage_Detail = {
          Label: 'Please explain the damage',
          Required: false,
          IsFollowUp: true,
          RequiredField: 'Landlord_HasDamage',
          RequiredFieldValue: 'Yes',
          Value: ''
        };

        _formFields.Landlord.Fields.Landlord_HasInfestation = {
          SpaceBefore: true,
          Label:
            'Are you aware of any bedbug or other pest infestations within their unit?',
          Required: true,
          Value: '',
          FieldType: 'Radio'
        };

        _formFields.Landlord.Fields.Landlord_HasInfestation_Detail = {
          Label: 'Please explain the infestation',
          IsFollowUp: true,
          Required: false,
          RequiredField: 'Landlord_HasInfestation',
          RequiredFieldValue: 'Yes',
          Value: ''
        };

        _formFields.Landlord.Fields.Landlord_InfestationStatus = {
          Label:
            'What is the status of the infestation, please choose one of the following:',
          Required: false,
          RequiredField: 'Landlord_HasInfestation',
          RequiredFieldValue: 'Yes',
          Value: '',
          FieldType: 'Radio',
          OptionList: [
            'Active infestation',
            'Treated within the last three (3) months',
            'Treated more than three (3) months ago'
          ]
        };

        _formFields.Landlord.Fields.Landlord_HasViolation = {
          SpaceBefore: true,
          Label:
            'Are there any documented lease violations (noise, violence, parking, etc.)',
          Required: true,
          Value: '',
          FieldType: 'Radio'
        };

        _formFields.Landlord.Fields.Landlord_HasViolation_Detail = {
          Label: 'Please explain documented lease violations',
          IsFollowUp: true,
          Required: false,
          RequiredField: 'Landlord_HasViolation',
          RequiredFieldValue: 'Yes',
          Value: ''
        };

        _formFields.Landlord.Fields.Landlord_HasPet = {
          Label: 'Does applicant have any pets?',
          SpaceBefore: true,
          Required: true,
          Value: '',
          FieldType: 'Radio'
        };

        _formFields.Landlord.Fields.Landlord_PetIsAuthorized = {
          Label: 'Were the pets authorized by you to be there?',
          RequiredField: 'Landlord_HasPet',
          RequiredFieldValue: 'Yes',
          Value: '',
          FieldType: 'Radio'
        };

        _formFields.Landlord.Fields.Landlord_PetDamage = {
          Label: 'Have the pets caused any damage?',
          RequiredField: 'Landlord_HasPet',
          RequiredFieldValue: 'Yes',
          Value: '',
          FieldType: 'Radio'
        };

        _formFields.Landlord.Fields.Landlord_UnauthorizedOccupant = {
          Label:
            'Has the applicant allowed any unauthorized occupants to live in their unit?',
          Required: true,
          Value: '',
          FieldType: 'Radio'
        };

        _formFields.Landlord.Fields.Landlord_UnauthorizedOccupant_Detail = {
          Label: 'Please explain the unauthorized occupants',
          IsFollowUp: true,
          Required: false,
          RequiredField: 'Landlord_UnauthorizedOccupant',
          RequiredFieldValue: 'Yes',
          Value: ''
        };

        _formFields.Landlord.Fields.Landlord_RentAgain = {
          Label: 'Would you re-rent to the applicant again?',
          Required: true,
          Value: '',
          FieldType: 'Radio'
        };

        if (!isProperty) {
          _formFields.Landlord.Fields.Landlord_Signature_Value = {
            Label: "Verifier's Signature",
            Placeholder: 'Type name to sign',
            Required: true,
            Value: '',
            Width: '35%',
            SpaceBefore: true,
            DirectionsComponent: ({ formFields }) => {
              return formFields ? (
                <p>
                  <strong>
                    The above information is correct to the best of my knowledge
                    as of the date below. By typing my name below, I am
                    consenting to the use of this digital form and intending to
                    electronically sign this verification.
                  </strong>
                </p>
              ) : null;
            }
          };

          _formFields.Landlord.Fields.Landlord_Signature_Title = {
            Label: "Verifier's Title",
            Required: true,
            Value: '',
            Width: '35%'
          };

          _formFields.Landlord.Fields.Landlord_Signature_Date = {
            Label: 'Date Signed',
            Required: true,
            ReadOnly: true,
            Value: moment().format('MM/DD/YYYY'),
            Width: '35%',
            TextType: 'date'
          };
        }
      }

      break;

    case 5: //Written Notice to Vacate
      {
        _formFields = {};

        if (!occupantID) {
          _formFields.Gate = {
            Title:
              'Written Notice of Intent to Vacate' +
              (residentName ? ' - ' + residentName : ''),
            RequiredField: 'Resident_ProceedWithNotice',
            Fields: {
              ['Resident_ConfirmAppliesToAll']: {
                Label:
                  'I confirm that this applies to all occupants of this unit.',
                Required: true,
                FieldType: 'Checkbox',
                DirectionsComponent: ({}) => {
                  return (
                    <p>
                      This online form can only be used if ALL occupants will be
                      vacating the unit on or prior to the move out date that
                      you provide in your Written Notice of Intent to Vacate. If
                      the previous statement is not true you cannot use this
                      online form and must submit your notice to the leasing
                      office in person.
                    </p>
                  );
                }
              },
              ['Resident_Preparer']: {
                Label: 'Which leaseholder is preparing this notice?',
                RequiredField: 'Resident_ConfirmAppliesToAll',
                RequiredFieldValue: 'Yes',
                Value: '',
                FieldType: 'Radio',
                Wide: true,
                OptionList: [
                  residentOnboarding.Header.FirstName +
                    ' ' +
                    residentOnboarding.Header.LastName
                ].concat(
                  residentOnboarding.OccupantList.map(
                    (occupant) => occupant.FirstName + ' ' + occupant.LastName
                  )
                )
              },
              ['Resident_ProceedWithNotice']: {
                Label: 'Proceed with Notice to Vacate',
                RequiredField: 'Resident_Preparer',
                Value: '',
                ButtonValue: 'Yes',
                FieldType: 'Button',
                ClassName: 'green'
              }
            }
          };
        }

        let _role = occupantID ? 'Occupant_' + occupantID : 'Resident';

        _formFields[_role] = {
          Title:
            'Written Notice of Intent to Vacate' +
            (residentName ? ' - ' + residentName : ''),
          Fields: {}
        };

        _formFields[_role].Fields[_role + '_UnitNumber'] = {
          Label: 'Unit Number',
          ReadOnly: true,
          Value: residentOnboarding.Header.UnitNumber,
          DirectionsComponent: ({ residentOnboarding }) => {
            return residentOnboarding && residentOnboarding.Header ? (
              <p>
                This Notice of Intent to Vacate is given in accordance to the
                terms of my lease. I understand that those terms require a
                minimum {residentOnboarding.Header.NoticeDaysOut} days advanced
                notice of my intent to vacate. I further understand that if I
                fail to provide the full{' '}
                {residentOnboarding.Header.NoticeDaysOut} days advance notice I
                may be responsible for rent and other fees up to the end of the
                required notice period, even if that period exceeds my lease end
                date.
              </p>
            ) : null;
          }
        };

        if (residentOnboarding.Header.DateLeaseRenewal) {
          _formFields[_role].Fields[_role + '_DateLeaseRenewal'] = {
            Label: 'Lease End Date',
            ReadOnly: true,
            Value: moment(residentOnboarding.Header.DateLeaseRenewal).format(
              'MM/DD/YYYY'
            )
          };
        }

        _formFields[_role].Fields[_role + '_MoveOutDate'] = {
          Label: 'Move Out Date',
          Required: true,
          Value: '',
          TextType: 'date'
        };

        _formFields[_role].Fields[_role + '_ReasonForMoving'] = {
          Label: 'Reason for Moving',
          Required: true,
          Value: '',
          Multiline: true
        };

        _formFields[_role].Fields[_role + '_RefundRecipient'] = {
          Label: 'All refunds and other communications should be sent to:',
          Required: true,
          Value: '',
          FieldType: occupantID ? 'Text' : 'Radio',
          Wide: true,
          OptionList: [
            residentOnboarding.Header.FirstName +
              ' ' +
              residentOnboarding.Header.LastName
          ].concat(
            residentOnboarding.OccupantList.map(
              (occupant) => occupant.FirstName + ' ' + occupant.LastName
            )
          )
        };

        _formFields[_role].Fields[_role + '_AcknowledgeTerms'] = {
          Label:
            'I acknowledge the Move Out Terms and Conditions outlined below and agree to them and their applicability.',
          Required: true,
          Value: '',
          FieldType: 'Checkbox',
          TermsComponent: ({}) => (
            <>
              <p align="center">
                <strong>MOVE OUT TERMS AND CONDITIONS</strong>
              </p>
              <p>
                <strong>CHANGES IN MOVE-OUT DATE.</strong> Under the Lease
                Contract, you must obtain our prior written approval to change
                or retract the move-out date. You may not hold over beyond the
                above move-out date. If the dwelling is relet to others after we
                receive this notice, you won&rsquo;t be granted any extensions.
                We and any new residents may rely on this move-out notice for
                all purposes.
              </p>
              <p>
                <strong>DATE OF SURRENDER.</strong> Under the Lease Contract,
                you surrender the dwelling unit for all purposes (including
                security deposit refund, cleaning, and all repairs) when you do
                any of the following:
              </p>
              <p>
                &bull; turn in all keys/access devices where you pay the rent;
              </p>
              <p>
                &bull; the move-out date has passed and no one is living in the
                dwelling
              </p>
              <p>
                &bull; abandon the dwelling (as defined in the Lease Contract).
              </p>
              <p>
                All residents and occupants lose their right of possession on
                the move-out date. Any resident who wishes to remain lawfully in
                the dwelling unit must sign a new Lease Contract.
              </p>
              <p>
                <strong>
                  EARLY MOVE-OUT AND OTHER LEASE CONTRACT VIOLATIONS.
                </strong>{' '}
                Under the Lease Contract, our representative&rsquo;s receipt of
                this notice does not constitute approval of an early moveout and
                does not constitute a release of any resident&rsquo;s liability
                for money due under the Lease Contract. We reserve all
                contractual and statutory remedies for unauthorized early
                move-out, including late payment charges, returned-check
                charges, damages, attorney&rsquo;s fees, and liability for
                increased holdover rents and Lease Contract extensions.
              </p>
              <p>
                <strong>HOLDOVER.</strong> If you stay beyond the move-out date,
                you will be subject to increased rent for the holdover period
                and liable for all damages as outlined in the Lease Contract.
              </p>
              <p>
                <strong>CLEANING.</strong> Under the Lease Contract, you must
                leave the dwelling unit in a clean condition. Please follow any
                written move-out cleaning instructions that we&rsquo;ve
                furnished.
              </p>
              <p>
                <strong>FORWARDING ADDRESSES.</strong> You must provide a
                forwarding address where we should mail the security deposit
                refund and/or accounting. If no forwarding address is provided,
                it will be mailed to your current apartment address as shown on
                your current lease with us. If your failure to provide us with a
                forwarding address causes us to have to stop payment on the
                check and reissue another one, you will be responsible for any
                bank charges and other related expenses that we may incur.
              </p>
              <p>
                <strong>RETAINING RECEIPT.</strong> After our representative
                signs and acknowledges receiving this notice, you should keep
                the bottom portion of this notice as verification that you gave
                written move-out notice.
              </p>
              <p>
                <strong>PROPER NOTICE.</strong> When you use this form, notice
                from one resident is notice from all, except when a co-resident
                (other than the terminating resident&rsquo;s spouse or
                dependent) terminates because of the Servicemembers Civil Relief
                Act (SCRA). Your advance notice must be at least the number of
                days&rsquo; notice required in the Lease Contract, even if your
                contract has become a month-to-month lease.
              </p>
              <p>
                <strong>MOVE-OUT INSPECTION.</strong> You should meet with our
                representative for a move-out inspection. Our representative has
                no authority to bind or limit us regarding deductions for
                repairs, damages, or charges. Any statements or estimates by us
                or our representative are subject to our correction,
                modification or disapproval before final returning or
                accounting.
              </p>
              <p>
                <strong>
                  PER YOUR LEASE, ADDITIONAL TERMS AND CONDITIONS MAY APPLY.
                </strong>
              </p>
            </>
          )
        };

        _formFields[_role].Fields[_role + '_Signature_Value'] = {
          Label: "Resident's Signature",
          Required: true,
          Value: '',
          Placeholder: 'Type name to sign',
          Width: '65%',
          SpaceBefore: true,
          DirectionsComponent: ({ formFields }) => {
            return formFields ? (
              <>
                <p>
                  By typing my name below, I am consenting to the use of this
                  digital form and intending to electronically sign this notice.
                  If there are other leaseholders shown below, a copy of this
                  notice will be emailed to them requesting their acceptance and
                  signature once you submit it.
                </p>
                <p>
                  ALL PERSONS APPEARING AS LEASEHOLDERS BELOW MUST SIGN BEFORE
                  THIS NOTICE OF INTENT TO VACATE CAN BE CONSIDERED VALID.
                </p>
              </>
            ) : null;
          }
        };

        _formFields[_role].Fields[_role + '_Signature_Date'] = {
          Label: 'Date Signed',
          Required: true,
          ReadOnly: true,
          Value: moment().format('MM/DD/YYYY'),
          Width: '35%',
          TextType: 'date'
        };

        if (residentOnboarding.OccupantList.length > 0 && !occupantID) {
          residentOnboarding.OccupantList.map((occupant, occupantIndex) => {
            let _field = {
              Label: occupant.FirstName + ' ' + occupant.LastName,
              ReadOnly: true,
              Value: occupant.Email
            };

            if (occupantIndex === 0) {
              _field.DirectionsComponent = ({ formFields }) => (
                <p>
                  Upon submittal, the following leaseholders will be emailed and
                  asked to digitally sign this notice.{' '}
                </p>
              );

              _field.SpaceBefore = true;
            }

            _formFields[_role].Fields[
              _role + '_OccupantEmail_' + occupant.OccupantID
            ] = _field;
          });
        }
      }

      break;

    case 6: //Forwarding Address
      {
        let _role = occupantID ? 'Occupant_' + occupantID : 'Resident';

        _formFields[_role] = {
          Title:
            'Forwarding Address' + (residentName ? ' - ' + residentName : ''),
          Fields: {}
        };

        _formFields[_role].Fields[_role + '_CurrentAddress'] = {
          Label: 'Current Address',
          ReadOnly: true,
          Value:
            residentOnboarding.Header.Address +
            ' ' +
            (residentOnboarding.Header.Secondary
              ? residentOnboarding.Header.Secondary
              : '') +
            '\n' +
            residentOnboarding.Header.City +
            ', ' +
            residentOnboarding.Header.State +
            ' ' +
            residentOnboarding.Header.Zip,
          Multiline: true,
          DirectionsComponent: ({ residentOnboarding }) => {
            return residentOnboarding && residentOnboarding.Header ? (
              <>
                <p>
                  Please provide your forwarding address. If no forwarding
                  address is provided, all correspondence will be mailed to your
                  current address as shown below, even if you have already
                  vacated the unit.
                </p>
              </>
            ) : null;
          }
        };

        _formFields[_role].Fields[_role + '_HasForwardingAddress'] = {
          Label: 'Do you know your forwarding address?',
          Required: true,
          Wide: true,
          SpaceBefore: true,
          FieldType: 'Radio',
          OptionList: [
            'I know my forwarding address and can enter it below.',
            'I do not have forwarding address yet, but will provide one to the property prior to moving out.'
          ],
          Value: ''
        };

        if (occupantID > 0) {
          _formFields[_role].Fields[_role + '_ForwardingAddress_Name'] = {
            Label: 'Name',
            RequiredField: _role + '_HasForwardingAddress',
            RequiredFieldValue:
              'I know my forwarding address and can enter it below.',
            Value: ''
          };
        } else {
          _formFields[_role].Fields[_role + '_ForwardingAddress_Name'] = {
            Label: 'Name',
            RequiredField: _role + '_HasForwardingAddress',
            RequiredFieldValue:
              'I know my forwarding address and can enter it below.',
            Value:
              residentOnboarding.Header.FirstName +
              ' ' +
              residentOnboarding.Header.LastName,
            ReadOnly: true
          };
        }

        _formFields[_role].Fields[_role + '_ForwardingAddress_Line1'] = {
          Label: 'Street Address',
          RequiredField: _role + '_HasForwardingAddress',
          RequiredFieldValue:
            'I know my forwarding address and can enter it below.',
          Value: ''
        };

        _formFields[_role].Fields[_role + '_ForwardingAddress_Line2'] = {
          Label: 'Address Line 2',
          Value: '',
          HideIfNoValue: true
        };

        _formFields[_role].Fields[_role + '_ForwardingAddress_City'] = {
          Label: 'City',
          RequiredField: _role + '_HasForwardingAddress',
          RequiredFieldValue:
            'I know my forwarding address and can enter it below.',
          Value: '',
          Width: '50%'
        };

        _formFields[_role].Fields[_role + '_ForwardingAddress_State'] = {
          Label: 'State',
          RequiredField: _role + '_HasForwardingAddress',
          RequiredFieldValue:
            'I know my forwarding address and can enter it below.',
          Value: '',
          Width: '25%',
          FieldType: 'state'
        };

        _formFields[_role].Fields[_role + '_ForwardingAddress_Zip'] = {
          Label: 'Zip',
          RequiredField: _role + '_HasForwardingAddress',
          RequiredFieldValue:
            'I know my forwarding address and can enter it below.',
          Value: '',
          Width: '25%',
          TextType: 'number'
        };

        if (!occupantID) {
          _formFields[_role].Fields[
            _role + '_NoForwardingAddress_Authorization'
          ] = {
            Label:
              'I understand that I will be responsible for any bank charges and other related expenses that the community may incur if my failure to provide a forwarding address causes the community to have to stop payment on a check and reissue another payment.',
            RequiredField: _role + '_HasForwardingAddress',
            RequiredFieldValue:
              'I do not have forwarding address yet, but will provide one to the property prior to moving out.',
            Value: '',
            FieldType: 'Checkbox'
          };
        }

        _formFields[_role].Fields[_role + '_Signature_Value'] = {
          Label: "Resident's Signature",
          Required: true,
          Value: '',
          Placeholder: 'Type name to sign',
          Width: '65%',

          DirectionsComponent: ({ formFields }) => {
            return formFields ? (
              <p>
                <strong>
                  By typing my name below, I am consenting to the use of this
                  digital form and intending to electronically sign this notice.
                </strong>
              </p>
            ) : null;
          }
        };

        _formFields[_role].Fields[_role + '_Signature_Date'] = {
          Label: 'Date Signed',
          Required: true,
          ReadOnly: true,
          Value: moment().format('MM/DD/YYYY'),
          Width: '35%',
          TextType: 'date'
        };
      }
      break;
    case 7: {
      //Schedule Inspection
      let _role = 'Resident';

      _formFields[_role] = {
        Title: 'Schedule Initial Pre-move Out Inspection',
        Fields: {}
      };

      _formFields[_role].Fields[_role + '_PermissionToEnter'] = {
        Label:
          'How would you like to schedule the pre-move out inspection of your unit?',
        DirectionsComponent: ({ formFields }) => {
          return formFields ? (
            <p>
              Per your lease, community staff needs to conduct a pre-move out
              inspection 2-3 weeks before your move out date. You are welcome to
              be present for this inspection, but your presence is not required.
            </p>
          ) : null;
        },
        Required: true,
        Wide: true,
        SpaceBefore: true,
        FieldType: 'Radio',
        OptionList: [
          'I grant community staff permission to enter my unit, without the need for me to be present, during normal business hours on the date(s) selected below.',
          'I want to be present for the pre-move out inspection and request that it be conducted on the date and time selected below.'
        ],
        Value: ''
      };

      if (
        residentOnboarding &&
        residentOnboarding.Header &&
        residentOnboarding.Header.DateMoveOut
      ) {
        const _moveOutDate = moment(residentOnboarding.Header.DateMoveOut);
        let _date = moment(_moveOutDate.format('YYYY-MM-DD')).add('week', -2);

        //Make sure the first date is a Monday
        while (_date.format('ddd') !== 'Mon') {
          _date.add('day', -1);
        }

        let _dateOptionList = [];
        while (_dateOptionList.length < 5) {
          if (_date.format('ddd') !== 'Sat' && _date.format('ddd') !== 'Sun') {
            _dateOptionList.push({
              Label: (
                <>
                  {_date.format('ddd')}
                  <br />
                  {_date.format('MMM DD')}
                </>
              ),
              Value: _date.format('MM/DD/YYYY')
            });
          }

          _date.add('day', 1);
        }

        _formFields[_role].Fields[_role + '_Inspection_DateRange'] = {
          Label: 'Inspection date(s)',
          DirectionsComponent: ({}) => {
            return (
              <p style={{ marginBottom: '-10px' }}>
                Select 1 or more dates below for the inspection to occur:
              </p>
            );
          },
          RequiredField: _role + '_PermissionToEnter',
          RequiredFieldValue:
            'I grant community staff permission to enter my unit, without the need for me to be present, during normal business hours on the date(s) selected below.',
          Value: '',
          AllowMultiple: true,
          ButtonOptionList: _dateOptionList,
          FieldType: 'ButtonList'
        };

        _formFields[_role].Fields[_role + '_Inspection_Date'] = {
          Label: 'Inspection date',
          DirectionsComponent: ({}) => {
            return (
              <p style={{ marginBottom: '-10px' }}>
                Select the date and time below to conduct this inspection:
              </p>
            );
          },
          RequiredField: _role + '_PermissionToEnter',
          RequiredFieldValue:
            'I want to be present for the pre-move out inspection and request that it be conducted on the date and time selected below.',
          Value: '',
          ButtonOptionList: _dateOptionList,
          FieldType: 'ButtonList'
        };
      }

      _formFields[_role].Fields[_role + '_Inspection_Timeframe'] = {
        Label: 'Inspection time frame',
        RequiredField: _role + '_PermissionToEnter',
        RequiredFieldValue:
          'I want to be present for the pre-move out inspection and request that it be conducted on the date and time selected below.',
        Value: '',
        ButtonOptionList: [
          { Value: 'Anytime between 8:00am and 12:00pm' },
          { Value: 'Anytime between 12:00pm and 4:00pm' }
        ],
        Wide: true,
        FieldType: 'ButtonList'
      };

      _formFields[_role].Fields[_role + '_Signature_Value'] = {
        Label: "Resident's Signature",
        Required: true,
        Value: '',
        Placeholder: 'Type name to sign',
        Width: '65%',

        DirectionsComponent: ({ formFields }) => {
          return formFields ? (
            <p>
              <strong>
                By typing my name below, I am consenting to the use of this
                digital form and intending to electronically sign this notice.
              </strong>
            </p>
          ) : null;
        }
      };

      _formFields[_role].Fields[_role + '_Signature_Date'] = {
        Label: 'Date Signed',
        Required: true,
        ReadOnly: true,
        Value: moment().format('MM/DD/YYYY'),
        Width: '35%',
        TextType: 'date'
      };
    }
  }

  return _formFields;
}
