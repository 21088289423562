import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import './index.css';
import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';

import * as dataApi from '../../api/dataApi';
import MovingTrucks from '../MovingTrucks';
import PlaceGrid from '../PlaceGrid';
import StorageUnits from '../StorageUnits';
import RentersInsurance from '../RentersInsurance';
import IntroText from '../IntroText';
import TaskMenu from '../TaskMenu';
import BackToTopButton from '../BackToTopButton';

import ResidentOnboardingPopup from '../ResidentOnboarding/ResidentOnboardingPopup';
import ServiceProfileNotice from '../ServiceProfileNotice';

function ServiceItemContainer({
  serviceAddress,
  actionTimeout,
  setShowChecklistPrompt,
  service,
  title
}) {
  return (
    <ul className="provider-list">
      {service.ProviderList.map((provider) => (
        <li className={provider.Description ? 'with-description' : ''}>
          <Button href={provider.ServiceStartUrl} target="mi_offsite">
            {provider.LogoFile ? (
              <img src={provider.LogoFile} className={'provider-logo'} />
            ) : (
              <span className="provider-name">
                <span className="sizer"></span>
                <span>{provider.DisplayName}</span>
              </span>
            )}
            {provider.Description ? (
              <p
                dangerouslySetInnerHTML={{
                  __html: provider.Description
                }}></p>
            ) : null}
          </Button>
        </li>
      ))}
    </ul>
  );
}

function ServiceHeader({
  mobileCollapse,
  providerServiceCategoryName,
  providerServiceCategoryIndex,
  expanded,
  serviceList,
  setExpanded,
  alwaysCollapse
}) {
  function getTopProviderLogos(grouping) {
    let _providerList = [];
    grouping.map((service) => {
      _providerList = _providerList.concat(
        service.ProviderList.filter(
          (provider, providerIndex) =>
            providerIndex === 0 || (grouping.length === 1 && providerIndex < 4)
        )
      );
    });
    return _providerList;
  }

  return alwaysCollapse ? (
    <h3
      className={
        'always-collapsible' +
        (expanded[providerServiceCategoryIndex] ? ' expanded' : '')
      }>
      <Button
        onClick={() =>
          setExpanded({
            ...expanded,
            [providerServiceCategoryIndex]:
              !expanded[providerServiceCategoryIndex]
          })
        }>
        {providerServiceCategoryName} <KeyboardArrowDownIcon />{' '}
      </Button>
    </h3>
  ) : mobileCollapse ? (
    <>
      <h3>{providerServiceCategoryName}</h3>
      <h3 className="mobile">
        <Button
          onClick={() =>
            setExpanded({
              ...expanded,
              [providerServiceCategoryIndex]:
                !expanded[providerServiceCategoryIndex]
            })
          }>
          {providerServiceCategoryName}{' '}
          <ul className="mobile-provider-logo-list">
            {getTopProviderLogos(serviceList).map((provider) => (
              <li>
                <img src={provider.LogoFile} className={'provider-logo'} />
              </li>
            ))}
          </ul>
          <KeyboardArrowDownIcon />{' '}
        </Button>
      </h3>
    </>
  ) : (
    <>
      <h3>{providerServiceCategoryName}</h3>
    </>
  );
}

function ServiceContainerInner({
  serviceAddress,
  selectedSection,
  actionTimeout,
  showChecklistPrompt,
  setShowChecklistPrompt,
  providerGrouping,
  expandedProviderServiceCategory,
  setExpandedProviderServiceCategory,
  mobileCollapse,
  dashboard
}) {
  const [expanded, setExpanded] = useState({});
  const [showResidentOnboarding, setShowResidentOnboarding] = useState(null);
  return (
    <>
      <ul
        className={'service-list' + (mobileCollapse ? ' mobile-collapse' : '')}>
        {providerGrouping
          ? Object.keys(providerGrouping).map(
              (providerServiceCategoryName, providerServiceCategoryIndex) => (
                <li
                  className={
                    'node' +
                    (expanded[providerServiceCategoryIndex] ? ' expanded' : '')
                  }>
                  <div>
                    <ServiceHeader
                      mobileCollapse={mobileCollapse}
                      providerServiceCategoryName={providerServiceCategoryName}
                      providerServiceCategoryIndex={
                        providerServiceCategoryIndex
                      }
                      expanded={expanded}
                      serviceList={
                        providerGrouping[providerServiceCategoryName]
                      }
                      setExpanded={setExpanded}
                    />

                    {providerGrouping[providerServiceCategoryName].map(
                      (service, serviceIndex) => (
                        <div
                          id={
                            'section_' +
                            providerServiceCategoryIndex +
                            '_' +
                            serviceIndex
                          }>
                          {dashboard ? (
                            <h5 className="checklist-title">{service.Title}</h5>
                          ) : (
                            <h2>{service.Title}</h2>
                          )}

                          <ul className="provider-list">
                            {service.ProviderList.filter(
                              (provider, providerIndex) =>
                                providerIndex < 5 ||
                                expandedProviderServiceCategory[
                                  service.Title
                                ] ||
                                expanded[providerServiceCategoryIndex] ||
                                service.ProviderList.filter(
                                  (provider) => provider.Description
                                ).length === 0
                            ).map((provider) => (
                              <li
                                className={
                                  provider.Description ? 'with-description' : ''
                                }>
                                <Button
                                  href={provider.ServiceStartUrl}
                                  target="mi_offsite">
                                  {provider.LogoFile ? (
                                    <img
                                      src={provider.LogoFile}
                                      className={'provider-logo'}
                                    />
                                  ) : (
                                    <span className="provider-name">
                                      <span className="sizer"></span>
                                      <span>{provider.DisplayName}</span>
                                    </span>
                                  )}
                                  {provider.Description ? (
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: provider.Description
                                      }}></p>
                                  ) : null}
                                </Button>
                              </li>
                            ))}
                          </ul>

                          {service.ProviderList.length > 5 &&
                          service.ProviderList.filter(
                            (provider) => provider.Description
                          ).length > 0 &&
                          !expandedProviderServiceCategory[service.Title] &&
                          !expanded[providerServiceCategoryIndex] ? (
                            <Button
                              className="show-all"
                              onClick={() =>
                                setExpandedProviderServiceCategory({
                                  ...expandedProviderServiceCategory,
                                  [service.Title]: true
                                })
                              }>
                              More {service.Title}
                              <KeyboardArrowDownIcon />
                            </Button>
                          ) : null}
                        </div>
                      )
                    )}
                  </div>
                </li>
              )
            )
          : selectedSection.ServiceList.map((service, serviceIndex) =>
              service.ProviderServiceID === 12 ? ( //DIY Truck Rental
                <li
                  className={
                    'node' + (expanded[serviceIndex] ? ' expanded' : '')
                  }>
                  <div>
                    <ServiceHeader
                      mobileCollapse={mobileCollapse}
                      providerServiceCategoryName={service.Title}
                      providerServiceCategoryIndex={serviceIndex}
                      expanded={expanded}
                      serviceList={[service]}
                      setExpanded={setExpanded}
                    />

                    <MovingTrucks
                      placeList={serviceAddress.placeList}
                      service={service}
                    />
                  </div>
                </li>
              ) : service.ProviderServiceID === 13 ? ( //Hire Local Movers
                <li
                  className={
                    'node' + (expanded[serviceIndex] ? ' expanded' : '')
                  }>
                  <div>
                    <ServiceHeader
                      mobileCollapse={mobileCollapse}
                      providerServiceCategoryName={service.Title}
                      providerServiceCategoryIndex={serviceIndex}
                      expanded={expanded}
                      serviceList={[service]}
                      setExpanded={setExpanded}
                    />
                    <PlaceGrid
                      placeList={
                        serviceAddress && serviceAddress.placeList
                          ? serviceAddress.placeList['Mover']
                          : null
                      }
                      label={'local movers'}
                    />
                  </div>
                </li>
              ) : service.ProviderServiceID === 20 ? ( //Storage Units
                <li
                  className={
                    'node' + (expanded[serviceIndex] ? ' expanded' : '')
                  }>
                  <div>
                    <ServiceHeader
                      mobileCollapse={mobileCollapse}
                      providerServiceCategoryName={service.Title}
                      providerServiceCategoryIndex={serviceIndex}
                      expanded={expanded}
                      serviceList={[service]}
                      setExpanded={setExpanded}
                    />
                    <StorageUnits
                      placeList={serviceAddress.placeList}
                      service={service}
                    />
                  </div>
                </li>
              ) : service.ProviderServiceID === 37 ? ( //Renters Insurance
                <li
                  className={
                    'node' + (expanded[serviceIndex] ? ' expanded' : '')
                  }>
                  <div>
                    <RentersInsurance
                      preferredProvider={
                        service.ProviderList.filter(
                          (provider) => provider.IsProviderPreferred
                        )[0]
                      }
                      coverageOptions={service.ProviderList[0]}
                      providerService={service}
                    />
                    {service.IsServiceRequired &&
                    service.ProviderList.filter(
                      (provider) => !provider.IsProviderPreferred
                    ).length ? (
                      <ServiceHeader
                        mobileCollapse={mobileCollapse}
                        providerServiceCategoryName={
                          'More ' + service.Title + ' Providers'
                        }
                        providerServiceCategoryIndex={serviceIndex}
                        expanded={expanded}
                        serviceList={[service]}
                        setExpanded={setExpanded}
                        alwaysCollapse
                      />
                    ) : null}
                    {expanded[serviceIndex] ? (
                      <ServiceItemContainer
                        serviceAddress={serviceAddress}
                        service={{
                          ...service,
                          ProviderList: service.ProviderList.filter(
                            (provider) => !provider.IsProviderPreferred
                          )
                        }}
                        actionTimeout={actionTimeout}
                        setShowChecklistPrompt={setShowChecklistPrompt}
                        mobileCollapse={mobileCollapse}
                      />
                    ) : null}
                    {(service.ProviderServiceID === 11 ||
                      service.ProviderServiceID === 37) &&
                    service.IsServiceRequired &&
                    service.ProviderList.filter(
                      (provider) => provider.IsServiceProofUpload
                    ).length ? (
                      <Button
                        style={{ width: '100%', maxWidth: '300px' }}
                        variant="outlined"
                        color="secondary"
                        onClick={() => setShowResidentOnboarding(service)}
                        className="proof-of-coverage-button">
                        Upload Proof of Coverage
                        <ChevronRightIcon />
                      </Button>
                    ) : null}
                  </div>
                </li>
              ) : (
                <li
                  className={
                    'node' + (expanded[serviceIndex] ? ' expanded' : '')
                  }>
                  <div>
                    <ServiceHeader
                      mobileCollapse={mobileCollapse}
                      providerServiceCategoryName={
                        service.ProviderServiceID === 11
                          ? service.Title + ' Providers'
                          : service.Title
                      }
                      providerServiceCategoryIndex={serviceIndex}
                      expanded={expanded}
                      serviceList={[service]}
                      setExpanded={setExpanded}
                    />
                    <ServiceItemContainer
                      serviceAddress={serviceAddress}
                      service={service}
                      actionTimeout={actionTimeout}
                      setShowChecklistPrompt={setShowChecklistPrompt}
                      mobileCollapse={mobileCollapse}
                    />
                  </div>
                </li>
              )
            )}
      </ul>
      {showResidentOnboarding ? (
        <ResidentOnboardingPopup
          providerServiceList={[showResidentOnboarding]}
          closeFunc={() => setShowResidentOnboarding(null)}
        />
      ) : null}
    </>
  );
}

function ServiceContainer({
  actions,
  serviceAddress,
  innerOnly,
  providerGrouping,
  mobileCollapse,
  routeOverride,
  dashboard
}) {
  const route = routeOverride ? routeOverride : dataApi.getCurrentRoute();

  const actionTimeout = useRef();
  const progressTimeout = useRef();
  const [showChecklistPrompt, setShowChecklistPrompt] = useState(null);
  const [showChecklistProgress, setShowChecklistProgress] = useState(null);
  const [viewType, setViewType] = useState(null);
  const [mapSummary, setMapSummary] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [expandedProviderServiceCategory, setExpandedProviderServiceCategory] =
    useState({});

  const open = Boolean(showChecklistProgress);

  useEffect(() => {
    // cleanup function to cancel the timeout if it hasn't finished.
    return () => {
      if (actionTimeout.current) {
        clearTimeout(actionTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    // cleanup function to cancel the timeout if it hasn't finished.
    return () => {
      if (progressTimeout.current) {
        clearTimeout(progressTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (serviceAddress && serviceAddress.Checklist) {
      const _route = routeOverride
        ? routeOverride
        : route === '/electric-rates'
        ? '/connect-utilities'
        : route;
      setSelectedSection(
        serviceAddress.Checklist.filter(
          (section) => section.Route === _route
        )[0]
      );
    }
  }, [serviceAddress]);

  useEffect(() => {
    if (
      serviceAddress &&
      serviceAddress.Address &&
      route === '/moving-services' &&
      !serviceAddress.placeList
    ) {
      actions.pageLoading(true, 'Finding local businesses...');

      actions.updatePlaceList(serviceAddress.Address).then((result) => {
        actions.pageLoading(false);
      });
    }
  }, [serviceAddress]);

  function getSectionClassName() {
    return route ? ' ' + route.split('/').join('') : '';
  }

  return serviceAddress && selectedSection ? (
    !innerOnly ? (
      <div
        className={'page-container service-container' + getSectionClassName()}>
        <BackToTopButton />

        <h1>
          <TaskMenu section={selectedSection} page />
          {selectedSection.Title}
        </h1>

        <IntroText
          trackAs={selectedSection.Title.toLowerCase().split(' ').join('-')}
          text={selectedSection.IntroText}
          mobileText={selectedSection.IntroTextMobile}
        />

        <div className="page">
          {selectedSection.ServiceSectionID === 4 ? (
            <ServiceProfileNotice residentSection={'insurance'} />
          ) : null}

          <div className="inner">
            <ServiceContainerInner
              serviceAddress={serviceAddress}
              selectedSection={selectedSection}
              actionTimeout={actionTimeout}
              showChecklistPrompt={showChecklistPrompt}
              setShowChecklistPrompt={setShowChecklistPrompt}
              expandedProviderServiceCategory={expandedProviderServiceCategory}
              setExpandedProviderServiceCategory={
                setExpandedProviderServiceCategory
              }
              mobileCollapse={mobileCollapse}
            />
          </div>
        </div>
      </div>
    ) : (
      <ServiceContainerInner
        serviceAddress={serviceAddress}
        selectedSection={selectedSection}
        actionTimeout={actionTimeout}
        showChecklistPrompt={showChecklistPrompt}
        setShowChecklistPrompt={setShowChecklistPrompt}
        providerGrouping={providerGrouping}
        expandedProviderServiceCategory={expandedProviderServiceCategory}
        setExpandedProviderServiceCategory={setExpandedProviderServiceCategory}
        mobileCollapse={mobileCollapse}
        dashboard={dashboard}
      />
    )
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      markTaskComplete: bindActionCreators(
        serviceAddressActions.markTaskComplete,
        dispatch
      ),
      updatePlaceList: bindActionCreators(
        serviceAddressActions.updatePlaceList,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceContainer);
