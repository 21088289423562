import React, { useState, useRef, useEffect } from 'react';
import { withPrefix } from 'gatsby-link';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

function ResidentOnboardingSaveWarning({
  dataTypeLabel,
  remainingItems,
  continueFunc,
  closeFunc
}) {
  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="resident-onboarding-save-warning">
      {/* <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        <img src={withPrefix('/images/logo-icon.svg')} className="logo-icon" />
        <div>My Checklist</div>
      </DialogTitle> */}
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        <p>
          Your changes have been saved, but the following information is still
          required before this {dataTypeLabel ? dataTypeLabel : ''} can be
          submitted to the property for approval:
        </p>
        <ul>
          {remainingItems
            .split(',')
            .map((item) => (item ? <li>{item}</li> : null))}
        </ul>
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'right' }}>
        <Button
          onClick={() => {
            continueFunc();
          }}
          variant="contained"
          color="secondary">
          Continue Editing
        </Button>
        <Button
          onClick={() => {
            closeFunc();
          }}
          variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ResidentOnboardingSaveWarning;
