import React from 'react';

import './index.css';

function Confetti({}) {
  function getNumbers() {
    let numbers = [];
    for (let i = 0; i < 149; i++) {
      numbers.push(i);
    }

    return numbers;
  }

  return (
    <div className="confetti-wrapper">
      {getNumbers().map((number) => (
        <div className={'confetti-' + number}></div>
      ))}
    </div>
  );
}

export default Confetti;
