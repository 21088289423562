import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import { navigate } from 'gatsby-link';

import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Alert
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import { getCookie, getCurrentRoute } from '../../../api/dataApi';

import * as webSocketActions from '../../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../../redux/actions/serviceAddressActions';
import ResidentLoginForm from '../../ResidentLoginForm';
import ContactPage from '../../ContactPage';
import LoginForm from '../../LoginForm';

import PropertyMenuPopup from '../../PropertyMenu/PropertyMenuPopup';

import './index.css';
import { useQueryParam } from 'gatsby-query-params';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

function LoginButton({
  siteConfig,
  serviceAddress,
  actions,
  onLoginToggle,
  authUser,
  formOnly,
  webSocket
}) {
  const [showLogin, setShowLogin] = useState(formOnly);
  const [creds, setCreds] = useState({});
  const [expanded, setExpanded] = useState(null);
  const [contactRequest, setContactRequest] = useState({});
  const [showPropertyPopup, setShowPropertyPopup] = useState(null);
  const hideLogin = useQueryParam('hideLogin', null) == '1';

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    if (onLoginToggle) {
      onLoginToggle(showLogin);
    }
  }, [showLogin]);

  function handleLoggedInUser(user) {
    if (user && user.PropertyList && user.PropertyList.length) {
      setShowLogin(null);
      //actions.pageLoading(true, 'Logging in...');
      if (getCurrentRoute() === '/') {
        // const firstProperty = user.PropertyList[0];
        // navigate(
        //   (firstProperty.ServiceProfileComplete
        //     ? '/property-onboarding'
        //     : '/property-setup') +
        //     '?property=' +
        //     firstProperty.ProfileKey
        //);

        if (user.PropertyList.length === 1) {
          navigate(
            '/property-events?property=' + user.PropertyList[0].ProfileKey
          );
        } else {
          setShowPropertyPopup(true);
        }
      } else {
        window.location.reload();
      }
    }
  }

  return (
    <>
      {showPropertyPopup ? (
        <PropertyMenuPopup closeFunc={() => setShowPropertyPopup(null)} />
      ) : null}

      {!formOnly && webSocket && webSocket.connected && !hideLogin ? (
        <Button
          variant="outlined"
          className="login-button"
          onClick={() => {
            if (
              authUser &&
              authUser.User &&
              authUser.User.Token &&
              authUser.PropertyList &&
              authUser.PropertyList.length
            ) {
              handleLoggedInUser(authUser);
            } else if (getCookie('mi-invitation')) {
              window.location = '/dashboard';
            } else {
              setShowLogin(true);
            }
          }}>
          Log In
        </Button>
      ) : null}

      {showLogin ? (
        <Dialog
          open={true}
          aria-labelledby="form-dialog-title"
          className={'login-popup' + (formOnly ? ' form-only' : '')}
          fullWidth={true}>
          <DialogTitle id="form-dialog-title">
            <img
              src={withPrefix('/images/logo-icon.svg')}
              className="logo-icon"
            />
            Log In to {siteConfig.brand}
            {!formOnly ? (
              <IconButton
                style={{ float: 'right' }}
                aria-label="close"
                onClick={() => setShowLogin(null)}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>

          <DialogContent width="100%">
            <p>
              To log in to your account, please select one of the following:
            </p>
            {siteConfig.id === 'mi' ? (
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
                className="resident">
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header">
                  <h2>
                    <LocalShippingOutlinedIcon /> <strong>Moving In</strong>
                    <br />
                    I'm moving in to a new place
                  </h2>
                </AccordionSummary>
                <AccordionDetails>
                  <ResidentLoginForm />
                </AccordionDetails>
              </Accordion>
            ) : null}
            <Accordion
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
              className="property">
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header">
                <h2>
                  <DomainOutlinedIcon /> <strong>Multi-family Partner</strong>
                  <br />
                  I'm a property manager partnered with {siteConfig.brand}
                </h2>
              </AccordionSummary>
              <AccordionDetails>
                <LoginForm
                  afterLoginFunc={(user) => handleLoggedInUser(user)}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
              className="contact">
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header">
                <h2>
                  <ContactSupportOutlinedIcon /> <strong>Contact Us</strong>
                  <br />
                  I'd like to get access to {siteConfig.brand}
                </h2>
              </AccordionSummary>
              <AccordionDetails>
                <ContactPage />
              </AccordionDetails>
            </Accordion>
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    authUser: state.authUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginButton);
