import React, { useState, useRef, useEffect, version } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import IntroText from '../IntroText';

import NoticeItemCard from './NoticeItemCard';

import '../OfferGrid/index.css';
import './index.css';

function NoticeTasks({ residentOnboarding, webSocket }) {
  console.log('residentOnboarding', residentOnboarding);
  console.log('webSocket', webSocket);
  function getItemList() {
    return residentOnboarding.Checklist.filter(
      (item) => item.OnboardingSection === 'notice'
    );
  }

  return (
    <>
      <div
        className={
          'page-container notice-dashboard' + (' total-' + getItemList().length)
        }>
        <h1>Moving Out Requirements</h1>

        {residentOnboarding && residentOnboarding.Header ? (
          <IntroText
            trackAs="notice-tasks"
            text={
              'Hello ' +
              (webSocket &&
              webSocket.invitation &&
              webSocket.invitation.FirstName
                ? webSocket.invitation.FirstName
                : residentOnboarding.Header.FirstName) +
              ". We are sorry to see that you're leaving. Per your lease, to make your decision final, you must complete and sign the Notice to Vacate form below. Then complete each of the other items shown and you will be all set."
            }
            mobileText={
              'Hello ' +
              (webSocket &&
              webSocket.invitation &&
              webSocket.invitation.FirstName
                ? webSocket.invitation.FirstName
                : residentOnboarding.Header.FirstName) +
              ". We are sorry to see that you're leaving. Per your lease, to make your decision final, you must complete and sign the Notice to Vacate form below. Then complete each of the other items shown and you will be all set."
            }
          />
        ) : null}
        <div className="page">
          <div className="inner">
            <ul className={'notice-item-list'}>
              {getItemList().map((item) => (
                <li className="notice-item">
                  <NoticeItemCard item={item} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      ;
    </>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      saveOrder: bindActionCreators(serviceAddressActions.saveOrder, dispatch),
      mockRouteUpdated: bindActionCreators(
        webSocketActions.mockRouteUpdated,
        dispatch
      ),
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NoticeTasks);
