import React, { useState, useEffect } from 'react';
import { withPrefix } from 'gatsby-link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Checkbox
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PetsIcon from '@mui/icons-material/Pets';
import InputMask from 'react-input-mask';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import {
  getResidentPetInterview,
  saveResidentPetInterview
} from '../../api/residentOnboardingApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import { loadResidentOnboarding } from '../../redux/actions/residentOnboardingActions';

import PropertyFilterCard from '../PropertyFilterGrid/PropertyFilterCard';
import PetProfile from '../PropertyPets/PetProfile';

import { getImageDownloadUrl } from '../ResidentOnboarding/ResidentOnboardingPhotoUpload';

import '../PropertyFilterGrid/index.css';

function PetInterview({
  residentID,
  residentOnboarding,
  closeFunc,
  actions,
  profileKey,
  pet
}) {
  const [interview, setInterview] = useState(null);
  const [showPetProfile, setShowPetProfile] = useState(null);

  useEffect(() => {
    if (!residentOnboarding) {
      actions.loadResidentOnboarding(residentID);
    }

    loadInterview();
  }, []);

  function loadInterview() {
    getResidentPetInterview(residentID, pet.ResidentPetID).then(
      (_interview) => {
        let _fields = {};
        _interview.FieldList.map((field) => {
          _fields[field.FieldName] = field.Answer;
        });

        setInterview({
          ..._interview,
          Header: {
            ..._interview.Header,
            DateInterview:
              _interview.Header && _interview.Header.DateInterview
                ? moment(_interview.Header.DateInterview).format('MM/DD/YYYY')
                : moment().format('MM/DD/YYYY')
          },
          Fields: _fields,
          FieldList: [
            {
              FieldName: 'Personality',
              Title: "Questions Based on Interviewer's Observations:",
              Question:
                'Does the animal appear to be calm, friendly, approachable, and well-mannered?',
              DesiredAnswer: 'Yes'
            },
            {
              FieldName: 'Appearance',
              Title: "Questions Based on Interviewer's Observations:",
              Question:
                'Does the animal appear to be clean, healthy, and well cared for?',
              DesiredAnswer: 'Yes'
            },
            {
              FieldName: 'Control1',
              Title: "Questions Based on Interviewer's Observations:",
              Question:
                'Does the animal appear to be obedient and under the owner’s control at all times?',
              DesiredAnswer: 'Yes'
            },
            {
              FieldName: 'Control2',
              Title: "Questions Based on Interviewer's Observations:",
              Question:
                'Does the animal appear to be attentive, and able to follow basic instructions?',
              DesiredAnswer: 'Yes'
            },
            {
              FieldName: 'Temperament1',
              Title: "Questions Based on Interviewer's Observations:",
              Question:
                'Is the animal showing any aggressive behavior, such as growling or showing its teeth?',
              DesiredAnswer: 'No'
            },
            {
              FieldName: 'Temperament2',
              Title: "Questions Based on Interviewer's Observations:",
              Question:
                'Is the animal barking or acting in other ways that may become disruptive to other residents?',
              DesiredAnswer: 'No'
            },
            {
              FieldName: 'Temperament3',
              Title: "Questions Based on Interviewer's Observations:",
              Question:
                'Is the animal showing any indications of hyperactivity, anxiety, or skittishness that might lead to disruptive behavior?',
              DesiredAnswer: 'No'
            },
            {
              FieldName: 'Question1',
              Title: 'Questions for the Owner:',
              Question:
                'Has the animal ever shown aggressive behavior towards people or other animals?',
              DesiredAnswer: 'No'
            },
            {
              FieldName: 'Question2',
              Title: 'Questions for the Owner:',
              Question: 'Has the animal ever bitten a person or other animal?',
              DesiredAnswer: 'No'
            },
            {
              FieldName: 'Question3',
              Title: 'Questions for the Owner:',
              Question:
                'Does the animal have a history of damaging property such as doors, interior trim, carpet, or landscaping?',
              DesiredAnswer: 'No'
            },
            {
              FieldName: 'Question4',
              Title: 'Questions for the Owner:',
              Question:
                'Does the animal have a history of disruptive behavior such as barking, moaning, or scratching?',
              DesiredAnswer: 'No'
            },
            {
              FieldName: 'Question5',
              Title: 'Questions for the Owner:',
              Question:
                'Does the animal have any health issues that may impact other animals or people?',
              DesiredAnswer: 'No'
            },
            {
              FieldName: 'Question6',
              Title: 'Questions for the Owner:',
              Question:
                'Do you understand that approving an animal to live onsite is conditioned on its behavior and that if the animal shows aggression towards others, or becomes disruptive, that approval can be revoked at any time at the property’s sole discretion?',
              DesiredAnswer: 'Yes'
            },
            {
              FieldName: 'Decision1',
              Title: "Interviewer's Determination of Fitness:",
              Question:
                'Do you believe, based on what you saw and heard during this interview, that a reasonable person (you) would have any reason to believe that this animal poses any kind of risk or threat to the health or safety of other people or animals?',
              DesiredAnswer: 'Yes'
            },
            {
              FieldName: 'Decision2',
              Title: "Interviewer's Determination of Fitness:",
              Question:
                'In your opinion, should this animal be approved to live at your community?',
              DesiredAnswer: 'Yes'
            }
          ]
        });
      }
    );
  }

  return residentOnboarding && interview ? (
    <div className="pet-interview">
      <div className={'multi-item'}>
        <div className={'form-header'}>
          <h4>
            <PetsIcon /> Pet Interview for {pet.Name}
          </h4>

          <div className="save-button">
            <Button
              variant="contained"
              color="primary"
              disabled={
                !interview.Header.InterviewerName ||
                !interview.Header.DateInterview ||
                !interview.Header.Location ||
                interview.FieldList.filter(
                  (field) => !interview.Fields[field.FieldName]
                ).length
              }
              onClick={() => {
                saveResidentPetInterview(residentID, {
                  ...interview,
                  Header: {
                    ...interview.Header,
                    IsPassed: interview.Fields['Decision2'] === 'Yes'
                  },
                  FieldList: interview.FieldList.map((field) => {
                    return {
                      ...field,
                      Answer: interview.Fields[field.FieldName]
                        ? interview.Fields[field.FieldName]
                        : ''
                    };
                  })
                }).then((result) => closeFunc());
              }}>
              Save
            </Button>

            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                closeFunc();
              }}>
              Cancel
            </Button>
          </div>
        </div>

        <div className="interview-form">
          <PropertyFilterCard
            key={pet.ResidentPetID}
            unitNumber={residentOnboarding.Header.UnitNumber}
            firstName={residentOnboarding.Header.FirstName}
            lastName={residentOnboarding.Header.LastName}
            fileListString={residentOnboarding.FileList.filter(
              (file) =>
                file.ResidentFileTypeID === 4 &&
                file.ResidentPetID === pet.ResidentPetID
            )
              .map(
                (file) => file.Description + '=>' + getImageDownloadUrl(file)
              )
              .join(',')}
            title={pet.Name ? pet.Name : 'No Name'}
            profileLabel={'Pet Profile'}
            profileClick={() => setShowPetProfile(pet)}
            //descriptionFilter={filterDictionary.View.selectedValue}
          />

          <div className="interview-form-fields">
            <TextField
              value={pet.OccupantName ? pet.OccupantName : pet.ResidentName}
              disabled
              label="Owner"
              fullWidth
            />

            <TextField
              value={
                interview.Header.InterviewerName
                  ? interview.Header.InterviewerName
                  : ''
              }
              className={!interview.Header.InterviewerName ? 'no-answer' : ''}
              required
              onChange={(event) =>
                setInterview({
                  ...interview,
                  Header: {
                    ...interview.Header,
                    InterviewerName: event.target.value
                  }
                })
              }
              label="Person Conducting Interview"
              fullWidth
            />

            <InputMask
              mask="99/99/9999"
              maskChar={'_'}
              required
              value={
                interview.Header.DateInterview
                  ? interview.Header.DateInterview
                  : ''
              }
              onChange={(event) =>
                setInterview({
                  ...interview,
                  Header: {
                    ...interview.Header,
                    DateInterview: event.target.value
                  }
                })
              }>
              {() => (
                <TextField
                  fullWidth
                  className={!interview.Header.DateInterview ? 'no-answer' : ''}
                  label={'Date of Interview'}
                  required
                  inputProps={{
                    inputMode: 'numeric'
                  }}
                />
              )}
            </InputMask>

            <TextField
              className={!interview.Header.Location ? 'no-answer' : ''}
              value={interview.Header.Location ? interview.Header.Location : ''}
              required
              onChange={(event) =>
                setInterview({
                  ...interview,
                  Header: { ...interview.Header, Location: event.target.value }
                })
              }
              label="Place of Interview"
              fullWidth
            />

            {interview.FieldList.map((field, fieldIndex) => (
              <>
                {!fieldIndex ||
                interview.FieldList[fieldIndex - 1].Title != field.Title ? (
                  <h4>{field.Title}</h4>
                ) : null}
                <div
                  className={
                    'radio-container' +
                    (!interview.Fields[field.FieldName] ? ' no-answer' : '')
                  }>
                  <p>{field.Question}</p>

                  <FormControl component="fieldset">
                    <RadioGroup
                      name={field.FieldName}
                      key={field.FieldName}
                      className="yes-no"
                      value={
                        interview.Fields[field.FieldName]
                          ? interview.Fields[field.FieldName]
                          : ''
                      }
                      onChange={(event, newValue) => {
                        setInterview({
                          ...interview,
                          Fields: {
                            ...interview.Fields,
                            [field.FieldName]: newValue
                          }
                        });
                      }}>
                      <FormControlLabel
                        value={'Yes'}
                        className={
                          interview.Fields[field.FieldName] === 'Yes'
                            ? 'selected'
                            : ''
                        }
                        control={<Radio color="primary" />}
                        label={'Yes'}
                      />
                      <FormControlLabel
                        value={'No'}
                        className={
                          interview.Fields[field.FieldName] === 'No'
                            ? 'selected'
                            : ''
                        }
                        control={<Radio color="primary" />}
                        label={'No'}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </>
            ))}

            <TextField
              value={interview.Header.Notes ? interview.Header.Notes : ''}
              multiline
              onChange={(event) =>
                setInterview({
                  ...interview,
                  Header: { ...interview.Header, Notes: event.target.value }
                })
              }
              label="Additional Notes"
              fullWidth
            />
          </div>
        </div>
      </div>

      {showPetProfile ? (
        <PetProfile
          pet={{ ...pet, ...residentOnboarding.Header }}
          closeFunc={() => setShowPetProfile(null)}
          profileKey={profileKey}
        />
      ) : null}
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      loadResidentOnboarding: bindActionCreators(
        loadResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PetInterview);
