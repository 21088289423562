import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { connect } from 'react-redux';
import { globalHistory } from '@reach/router';

import Header from '../Header';
import Footer from '../Footer';
import LandingMITop from '../LandingMITop';

import { getCurrentRoute } from '../../api/dataApi';

const path = getCurrentRoute();

const MainContainer = (props) => {
  const [isHomePage, setIsHomePage] = useState(true);
  const { children, footerFixed, contentBackground } = props;

  useEffect(() => {
    checkIsHomePage(path);
  }, [path]);

  useEffect(() => {
    return globalHistory.listen((event) => {
      checkIsHomePage(path);
    });
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (isHomePage) {
        document.body.classList.remove('not-home-page');
      } else {
        document.body.classList.add('not-home-page');
      }
    }
  }, [isHomePage]);

  function checkIsHomePage(_path) {
    setIsHomePage(
      (_path === '/' || _path === '/landing-mi') && props.siteConfig.id === 'mi'
    );
  }

  return (
    <Fragment>
      <div
        className={
          clsx('app-wrapper', contentBackground) +
          ' ' +
          process.env.GATSBY_BRAND_ID
        }>
        {props.siteConfig.id === 'sc' ? (
          <div className="shop-smarter">shop smarter with </div>
        ) : null}

        <Header />
        <div
          className={
            clsx('app-main', {}) + (!isHomePage ? ' not-home-page' : '')
          }>
          <div
            className={clsx('app-content', {
              'app-content-footer-fixed': footerFixed
            })}>
            <div className="app-content--inner">
              <div className="app-content--inner__wrapper">{children}</div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

MainContainer.propTypes = {
  children: PropTypes.node,
  toDo: PropTypes.object
};

const mapStateToProps = (state) => ({
  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

  footerFixed: state.ThemeOptions.footerFixed,

  contentBackground: state.ThemeOptions.contentBackground,
  siteConfig: state.commonData.siteConfig,
  webSocket: state.webSocket
});

export default connect(mapStateToProps)(MainContainer);
