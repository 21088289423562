import React, { useEffect, useRef, useState } from 'react';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import TextField from '@mui/material/TextField';

import RadioOption from './RadioOption';
import ServiceAddendumFileUpload from './ServiceAddendumFileUpload';

export default function ServiceRequiredOption({
  setProviderServiceValue,
  providerService,
  question,
  requireSubOptionIfYes,
  profileKey,
  serviceProfile,
  isRenter
}) {
  function checkRequireSubOption() {
    if (requireSubOptionIfYes) {
      return providerService.IsServiceRequired === true;
    } else {
      return providerService.IsServiceRequired === false;
    }
  }

  return providerService ? (
    <RadioOption
      setProviderServiceValue={setProviderServiceValue}
      providerService={providerService}
      field={'IsServiceRequired'}
      question={
        question
          ? question
          : 'Are residents required to put ' +
            providerService.ProviderServiceName +
            ' service in their name?'
      }>
      <div
        className={
          'sub-option' + (!checkRequireSubOption() ? ' disabled' : '')
        }>
        {providerService.ProviderServiceID === 25 ? (
          <ServiceAddendumFileUpload
            profileKey={profileKey}
            providerService={providerService}
            setProviderServiceValue={setProviderServiceValue}
            label={'Upload ' + providerService.ProviderServiceName + ' flyer'}
            fyi={`We'll add a link to remind residents of rules and restrictions.`}
            optional
          />
        ) : (
          <>
            {' '}
            <p>If {requireSubOptionIfYes ? 'YES' : 'NO'}, choose one:</p>
            <RadioGroup
              value={
                providerService.IsServiceAvailable === false
                  ? 'ServiceNotAvailable'
                  : providerService.IsServiceIncludedInRent
                  ? 'IsServiceIncludedInRent'
                  : providerService.IsServicePaidWithRent
                  ? 'IsServicePaidWithRent'
                  : providerService.IsServicePaidToThirdParty
                  ? 'IsServicePaidToThirdParty'
                  : providerService.IsServicePaidToOther
                  ? 'IsServicePaidToOther'
                  : null
              }
              onChange={(event) => {
                const _field =
                  event.target.value === 'ServiceNotAvailable'
                    ? 'IsServiceAvailable'
                    : event.target.value;
                const _value =
                  event.target.value === 'ServiceNotAvailable' ? false : true;
                setProviderServiceValue(providerService, {
                  IsServiceAvailable: true,
                  IsServiceIncludedInRent: false,
                  IsServicePaidWithRent: false,
                  IsServicePaidToThirdParty: false,
                  IsServicePaidToOther: false,
                  [_field]: _value
                });
              }}>
              {providerService.ProviderServiceID === 8 && (
                <FormControlLabel
                  value={'ServiceNotAvailable'}
                  control={
                    <Radio
                      color="primary"
                      disabled={!checkRequireSubOption()}
                    />
                  }
                  label={
                    'Nothing in their home uses ' +
                    providerService.ProviderServiceName +
                    ', so there is no need to set up ' +
                    providerService.ProviderServiceName +
                    ' service.'
                  }
                />
              )}

              {isRenter ? (
                <>
                  <FormControlLabel
                    value={'IsServiceIncludedInRent'}
                    control={
                      <Radio
                        color="primary"
                        disabled={!checkRequireSubOption()}
                      />
                    }
                    label={
                      providerService.ProviderServiceName +
                      ' service to their home is provided by the ' +
                      (serviceProfile.Header.PropertyID
                        ? 'property'
                        : 'landlord') +
                      ' as part of their rent, at no additional cost.'
                    }
                  />

                  <FormControlLabel
                    value={'IsServicePaidWithRent'}
                    control={
                      <Radio
                        color="primary"
                        disabled={!checkRequireSubOption()}
                      />
                    }
                    label={
                      providerService.ProviderServiceName +
                      ' service to their home is provided by the ' +
                      (serviceProfile.Header.PropertyID
                        ? 'property'
                        : 'landlord') +
                      ' but charged back to them according to their lease.'
                    }
                  />

                  {/* <FormControlLabel
                value={'IsServicePaidToThirdParty'}
                control={
                  <Radio color="primary" disabled={!checkRequireSubOption()} />
                }
                label={
                  providerService.ProviderServiceName +
                  ' service is paid to a third party per their lease.'
                }
              /> */}
                </>
              ) : null}

              <FormControlLabel
                value={'IsServicePaidToOther'}
                control={
                  <Radio color="primary" disabled={!checkRequireSubOption()} />
                }
                label={'Other:'}
              />
              <TextField
                disabled={!providerService.IsServicePaidToOther}
                value={providerService.CustomMessage}
                onChange={(event) =>
                  setProviderServiceValue(providerService, {
                    CustomMessage: event.target.value
                  })
                }
                inputProps={{ maxLength: 120 }}
                label={'Enter a custom message'}
              />
            </RadioGroup>
          </>
        )}
      </div>
    </RadioOption>
  ) : null;
}
