import React, { useState, useRef, useEffect } from 'react';
import { withPrefix } from 'gatsby-link';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

import { capitalize } from '../../../api/dataApi';

import SendResidentMessage from '../../OnboardingDashboard/SendResidentMessage';

const cannedResponse = {
  insurance: {
    exists: [
      'Does not meet the minimum Personal Liability cover of {insuranceMinCoverageLiabilityValue}',
      'Does not meet the minimum Personal Property cover of {insuranceMinCoveragePropertyValue}',
      'Does not show or correctly list the Interested Party as {insuranceInterestedPartyText}',
      'You stated that you signed up through our Preferred Provider, but we do not have a record of it. Please resubmit proof of coverage.',
      'Proof of Coverage document not legible. Please upload a better copy.'
    ],
    noExists: [
      'You stated that you provided this information at lease signing, but we do not have a record of it. Please resubmit proof of coverage.'
    ]
  },
  utility: {
    exists: [
      'Proof of Service document does not show Account Number.',
      'Proof of Service document does not show Service Start Date',
      'Proof of Service document does not show Service Address with Unit Number',

      'Proof of Service document not legible. Please upload a better copy.'
    ],
    noExists: [
      'You stated that you provided this information at lease signing, but we do not have a record of it. Please resubmit proof of service.'
    ]
  },
  pet: {
    exists: [
      'Poor quality photo/image - please upload better quality photos.',
      'Pet policy violation - weight limit exceeded.',
      'Pet policy violation - prohibited breed.',
      'Pet policy violation - maximum number of pets exceeded.'
    ],
    noExists: [
      'You stated that you do not have pets, but you indicated when you signed your lease that you do have pets. Please upload your pet registration information.'
    ]
  },
  vehicle: {
    exists: ['Poor quality photo/image - please upload better quality photos.'],
    noExists: [
      'You stated that you do not have a vehicle, but you indicated when you signed your lease that you do have a vehicle. Please upload your vehicle registration information.'
    ]
  },
  'resident-form': {
    exists: ['Missing or incorrect form fields (explain below)'],
    noExists: []
  }
};

function ResidentOnboardingStatusUpdate({
  dataTypeLabel,
  residentOnboardingStatusID,
  saveFunc,
  closeFunc,
  type,
  item,
  exists,
  profileKey,
  resident
}) {
  const [note, setNote] = useState('');
  const [cannedResponseList, setCannedResponseList] = useState(null);

  useEffect(() => {
    let _cannedResponseList = null;

    if (residentOnboardingStatusID === 1 && type && cannedResponse[type]) {
      _cannedResponseList = [];
      cannedResponse[type][exists ? 'exists' : 'noExists'].map((response) => {
        let _response = response;

        if (item) {
          _response = _response
            .split('{insuranceMinCoverageLiabilityValue}')
            .join(
              item.InsuranceMinCoverageLiabilityValue
                ? '$' + item.InsuranceMinCoverageLiabilityValue.toLocaleString()
                : '{'
            )
            .split('{insuranceMinCoveragePropertyValue}')
            .join(
              item.InsuranceMinCoveragePropertyValue
                ? '$' + item.InsuranceMinCoveragePropertyValue.toLocaleString()
                : '{'
            )
            .split('{insuranceInterestedPartyText}')
            .join(
              item.InsuranceInterestedPartyText
                ? '<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
                    item.InsuranceInterestedPartyText.split('\n').join(
                      '<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
                    )
                : '{'
            );
        }

        if (_response.indexOf('{') === -1) {
          _cannedResponseList.push(_response);
        }
      });
    }

    setCannedResponseList(_cannedResponseList);
  }, []);

  return residentOnboardingStatusID === 3 ? (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="resident-onboarding-status-update">
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        <p>Please confirm you are accepting this {dataTypeLabel} submittal.</p>
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'right' }}>
        <Button
          onClick={() => saveFunc(note)}
          disabled={residentOnboardingStatusID !== 3 && !note}
          variant="contained"
          color="secondary"
          className={residentOnboardingStatusID === 3 ? 'accept' : ''}>
          {residentOnboardingStatusID === 3 ? (
            <>
              <DoneIcon /> Confirm Accept
            </>
          ) : (
            <>
              <ClearIcon /> Confirm Reject
            </>
          )}
        </Button>
        <Button
          onClick={() => {
            closeFunc();
          }}
          variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <SendResidentMessage
      resident={resident}
      profileKey={profileKey}
      closeFunc={() => {
        closeFunc();
      }}
      fixedAction={''}
      isRejection={dataTypeLabel}
      title={capitalize(dataTypeLabel) + ' - Submittal Rejected'}
      afterSend={(reason) => saveFunc(reason)}
      fixedMessageType={'resident-submittal-rejection'}
      fixedMessageDescription={
        capitalize(dataTypeLabel) + ' - Submittal Rejected'
      }
      fixedMessageParts={cannedResponseList}
    />
  );
}

export default ResidentOnboardingStatusUpdate;
