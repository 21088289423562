import React, { useEffect, useState } from 'react';
import { withPrefix } from 'gatsby-link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DocViewer, { DocViewerRenderers, PDFRenderer } from 'react-doc-viewer';

import ProviderLogo from '../OfferGrid/ProviderLogo';

import './index.css';
import { Button } from '@mui/material';

function ResidentInternetHeader({
  serviceAddress,
  setupProviderList,
  setUpIncludedInRent,
  setupInstructionsFile,
  setInternetIncludedInRent,
  setupSpecialCircumstancesHeader,
  setupSpecialCircumstanceDescription,
  setPreferredProviderDictionary
}) {
  const [providerList, setProviderList] = useState();
  const [preferredProvider, setPreferredProvider] = useState(null);
  const [preInstalledProviderList, setPreInstalledProviderList] =
    useState(null);
  const [includedInRent, setIncludedInRent] = useState(null);
  const [instructionsFile, setInstructionsFile] = useState(null);
  const [headerText, setHeaderText] = useState(null);
  const [descriptionText, setDescriptionText] = useState(null);

  useEffect(() => {
    if (setupProviderList) {
      setProviderList(
        setupProviderList.map((provider) => {
          return { ...provider };
        })
      );

      setIncludedInRent(setUpIncludedInRent);
      setInstructionsFile(setupInstructionsFile);
      setHeaderText(setupSpecialCircumstancesHeader);
      setDescriptionText(setupSpecialCircumstanceDescription);
    } else if (serviceAddress && serviceAddress.Checklist) {
      serviceAddress.Checklist.filter(
        (section) => section.ServiceSectionID === 2
      ).map((section) => {
        section.ServiceList.filter(
          (service) => service.ProviderServiceID === 1
        ).map((service) => {
          if (service.ProviderList) {
            let _providerList = {};
            let _includedInRent = false;
            service.ProviderList.filter(
              (provider) =>
                provider.ProviderServiceList &&
                provider.ProviderServiceList.indexOf('Internet') !== -1
            ).map((provider) => {
              _providerList[provider.ProviderID] = provider;

              if (
                provider.InternetIsPartOfRent ||
                provider.InternetSpecialCircumstanceChecked ||
                provider.InternetHasInHousePackage
              ) {
                _includedInRent = true;

                if (provider.ServiceAddendumFile) {
                  setInstructionsFile(provider.ServiceAddendumFile);
                }

                if (provider.InternetSpecialCircumstanceHeader) {
                  setHeaderText(provider.InternetSpecialCircumstanceHeader);
                }

                if (provider.InternetSpecialCircumstanceDescription) {
                  setDescriptionText(
                    provider.InternetSpecialCircumstanceDescription
                  );
                }
              }
            });

            setProviderList(
              Object.keys(_providerList)
                .map((key) => {
                  return { ..._providerList[key] };
                })
                .sort((a, b) => a.ProviderName.localeCompare(b.ProviderName))
            );

            setIncludedInRent(_includedInRent);
          }
        });
      });
    }
  }, [
    setupProviderList,
    serviceAddress,
    setUpIncludedInRent,
    setupInstructionsFile
  ]);

  useEffect(() => {
    if (providerList) {
      setPreferredProvider(
        providerList.filter((provider) => provider.IsProviderPreferred)[0]
      );

      setPreInstalledProviderList(
        providerList
          .filter((provider) => provider.IsProviderPreInstalled)
          .sort((a, b) => a.ProviderName.localeCompare(b.ProviderName))
      );
    }
  }, [providerList]);

  useEffect(() => {
    if (setInternetIncludedInRent) {
      setInternetIncludedInRent(includedInRent);
    }
  }, [includedInRent]);

  useEffect(() => {
    if (setPreferredProviderDictionary) {
      let _preferredProviderDictionary = {};
      if (preInstalledProviderList && preInstalledProviderList.length) {
        preInstalledProviderList.map((provider) => {
          _preferredProviderDictionary[provider.ProviderID] = 1;
        });
      }

      if (preferredProvider) {
        _preferredProviderDictionary[preferredProvider.ProviderID] = 0;
      }

      setPreferredProviderDictionary(_preferredProviderDictionary);
    }
  }, [preferredProvider, preInstalledProviderList]);

  return (
    <div className="resident-internet-header">
      <div className="resident-internet-header-content">
        {includedInRent ? (
          <h3>{headerText ? headerText : 'Internet Included in Rent'}</h3>
        ) : (
          <h3>Wi-Fi Internet Service</h3>
        )}
        {includedInRent ? (
          <p>
            {descriptionText
              ? descriptionText
              : 'Your Internet equipment is pre-installed and ready to go. Just follow the instructions below and you’ll be up and running in a matter of minutes.'}
            {instructionsFile ? (
              <>
                <br />
                <br />
                <Button
                  href={
                    'https://mi-service-profile.s3.us-west-1.amazonaws.com/' +
                    instructionsFile
                  }
                  variant="contained"
                  color="primary"
                  target="mi_offsite">
                  Download Setup Instructions
                </Button>
              </>
            ) : null}
          </p>
        ) : preferredProvider ? (
          <p>
            All of the Internet Providers below service your unit, but the
            property recommends:
          </p>
        ) : preInstalledProviderList && preInstalledProviderList.length ? (
          <p>
            Your unit is Wi-Fi Ready, meaning equipment is pre-installed so you
            can be up and running in a matter of minutes. No appointment needed!
          </p>
        ) : (
          <p>All of the Internet providers below service your unit:</p>
        )}

        {!includedInRent ? (
          <div className="provider-list">
            {providerList
              ? (preferredProvider
                  ? [preferredProvider]
                  : preInstalledProviderList && preInstalledProviderList.length
                  ? preInstalledProviderList
                  : providerList
                ).map((provider) => <ProviderLogo offer={{ ...provider }} />)
              : null}
          </div>
        ) : null}
      </div>
      <div className="resident-internet-header-image">
        <img src={withPrefix('/images/misc/internet-checkout-family.png')} />
      </div>

      {/* {instructionsFile ? (
        <DocViewer
          pluginRenderers={DocViewerRenderers}
          config={{
            header: {
              //overrideComponent: myHeader,
              //disableFileName: true
            }
          }}
          documents={[
            'https://mi-service-profile.s3.us-west-1.amazonaws.com/' +
              instructionsFile
          ]}
        />
      ) : null} */}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    stateList: state.commonData.stateList,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResidentInternetHeader);
