import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby-link';
import { withPrefix } from 'gatsby-link';

import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Alert,
  LinearProgress,
  Tooltip
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CanvasConfetti from '../CanvasConfetti';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/Edit';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import InfoIcon from '@mui/icons-material/Info';

import {
  QontoConnector,
  QontoStepIconRoot,
  QontoStepIcon,
  CircularProgressWithLabel,
  dayOfWeekList,
  businessHourList
} from '../ServiceProfileShared/config';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import { getServiceProfile } from '../../api/partnerDataApi';

import ServiceRequiredOption from './ServiceRequiredOption';
import RadioOption from './RadioOption';
import ProviderSearch from './ProviderSearch';
import ProofOfServiceOption from './ProofOfServiceOption';
import ProviderMenu from './ProviderMenu';
import ResidentInternetHeader from '../ResidentInternetHeader';
import ServiceAddendumFileUpload from '../ServiceProfileShared/ServiceAddendumFileUpload';

import { checkProviderServiceHasDefaultProvider } from './config';

import '../PropertyProfile/index.css';
import './index.css';

function StepRentersInsurance({
  siteConfig,
  authUser,
  serviceProfile,
  setProviderServiceValue,
  activeStep,
  profileKey,
  setServiceProfile,
  providerService,
  setProviderValue,
  alreadyCompleted
}) {
  return (
    <div className="section">
      <h3>Renters Insurance</h3>

      <div className="form-content">
        <RadioOption
          setProviderServiceValue={setProviderServiceValue}
          providerService={providerService}
          field={'IsServiceRequired'}
          question={
            'Does your lease require residents to have Renters Insurance?'
          }></RadioOption>

        <RadioOption
          setProviderServiceValue={setProviderServiceValue}
          disabled={!providerService.IsServiceRequired}
          providerService={providerService}
          field={'HasPreferredProvider'}
          question={
            'Do you have a preferred provider you promote? Click logo below to choose.'
          }></RadioOption>

        <div
          className={
            'sub-option' +
            (!providerService.HasPreferredProvider ||
            !providerService.IsServiceRequired
              ? ' disabled'
              : '')
          }>
          <ProviderMenu
            providerService={providerService}
            setProviderServiceValue={setProviderServiceValue}
            setProviderValue={setProviderValue}
            providerList={serviceProfile.ServiceSectionList[
              activeStep
            ].ProviderList.filter(
              (provider) => provider.ProviderServiceID === 37
            )}
            disabled={!providerService.HasPreferredProvider}
            noRadio></ProviderMenu>
        </div>

        {/* <RadioOption
        setProviderServiceValue={
          setProviderServiceValue
        }
        disabled={
          !providerService.IsServiceRequired ||
          !providerService.HasPreferredProvider
        }
        providerService={providerService}
        field={'IsPreferredProviderExclusive'}
        question={
          'Show the residents the Preferred Provider exclusively. Do not show any other providers.'
        }></RadioOption> */}

        <RadioOption
          setProviderServiceValue={setProviderServiceValue}
          disabled={!providerService.IsServiceRequired}
          providerService={providerService}
          field={'InsuranceIsMinCoverageRequired'}
          question={
            'Does your lease specify minimum policy requirements and coverage levels?'
          }>
          <div
            className={
              'sub-option' +
              (!providerService.InsuranceIsMinCoverageRequired
                ? ' disabled'
                : '')
            }>
            <p>
              If YES, what coverages are required and what are the minimum
              coverage levels?
            </p>

            <div className="checkbox-option coverage">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        providerService.InsuranceMinCoverageLiabilityRequired
                      }
                      onChange={(event) =>
                        setProviderServiceValue(providerService, {
                          InsuranceMinCoverageLiabilityRequired:
                            event.target.checked
                        })
                      }
                      disabled={!providerService.InsuranceIsMinCoverageRequired}
                    />
                  }
                  label="Personal Liability"
                />
              </FormGroup>
              <TextField
                disabled={
                  !providerService.InsuranceIsMinCoverageRequired ||
                  !providerService.InsuranceMinCoverageLiabilityRequired
                }
                value={providerService.InsuranceMinCoverageLiabilityValue}
                onChange={(event) =>
                  setProviderServiceValue(providerService, {
                    InsuranceMinCoverageLiabilityValue: formatThousands(
                      event.target.value
                    )
                  })
                }
                label="Minimum Coverage"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
                className="min-coverage"
                onKeyDown={(event) => restrictNumber(event)}
              />
            </div>

            <div className="checkbox-option">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        providerService.InsuranceMinCoveragePropertyRequired
                      }
                      onChange={(event) =>
                        setProviderServiceValue(providerService, {
                          InsuranceMinCoveragePropertyRequired:
                            event.target.checked
                        })
                      }
                      disabled={!providerService.InsuranceIsMinCoverageRequired}
                    />
                  }
                  label="Personal Poperty"
                />
              </FormGroup>

              <TextField
                disabled={
                  !providerService.InsuranceIsMinCoverageRequired ||
                  !providerService.InsuranceMinCoveragePropertyRequired
                }
                value={providerService.InsuranceMinCoveragePropertyValue}
                onChange={(event) =>
                  setProviderServiceValue(providerService, {
                    InsuranceMinCoveragePropertyValue: formatThousands(
                      event.target.value
                    )
                  })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
                label="Minimum Coverage"
                onKeyDown={(event) => restrictNumber(event)}
                className="min-coverage"
              />
            </div>

            <div className="checkbox-option">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        providerService.InsuranceMinCoverageOtherRequired
                      }
                      onChange={(event) =>
                        setProviderServiceValue(providerService, {
                          InsuranceMinCoverageOtherRequired:
                            event.target.checked
                        })
                      }
                      disabled={!providerService.InsuranceIsMinCoverageRequired}
                    />
                  }
                  label="Other:"
                />
              </FormGroup>

              <TextField
                disabled={
                  !providerService.InsuranceIsMinCoverageRequired ||
                  !providerService.InsuranceMinCoverageOtherRequired
                }
                value={providerService.InsuranceMinCoverageOtherValue}
                onChange={(event) =>
                  setProviderServiceValue(providerService, {
                    InsuranceMinCoverageOtherValue: formatThousands(
                      event.target.value
                    )
                  })
                }
                label="Minimum Coverage"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
                className="min-coverage"
                onKeyDown={(event) => restrictNumber(event)}
              />

              <TextField
                disabled={
                  !providerService.InsuranceIsMinCoverageRequired ||
                  !providerService.InsuranceMinCoverageOtherRequired
                }
                value={providerService.InsuranceMinCoverageOtherLabel}
                onChange={(event) =>
                  setProviderServiceValue(providerService, {
                    InsuranceMinCoverageOtherLabel: event.target.value
                  })
                }
                label="Type of Insurance"
              />
            </div>
          </div>
        </RadioOption>

        <RadioOption
          setProviderServiceValue={setProviderServiceValue}
          providerService={providerService}
          field={'InsuranceIsInterestedPartyRequired'}
          className="interested-party"
          disabled={!providerService.IsServiceRequired}
          question={
            <>
              Do you require residents to list the property or anyone else as an
              "Interested Party" on their policy?{' '}
              <Tooltip
                title="Being listed as an interested party (or
              additional interest) on a resident's
              renters insurance policy allows a landlord
              to stay informed of any policy
              modifications or non-renewals. It's a
              simple way for a landlord to ensure their
              rental property is protected. A landlord
              listed as an interested party does not
              have coverage via the policy, and cannot
              file claims (unless the claim is made
              against the resident's liability coverage
              for damages or injuries they caused).">
                <InfoIcon />
              </Tooltip>
            </>
          }>
          <div
            className={
              'sub-option' +
              (!providerService.InsuranceIsInterestedPartyRequired
                ? ' disabled'
                : '')
            }>
            <TextField
              label="If YES, please specify the exact name or language to be used. Hit enter to add a new line."
              disabled={!providerService.InsuranceIsInterestedPartyRequired}
              value={providerService.InsuranceInterestedPartyText}
              onChange={(event) =>
                setProviderServiceValue(providerService, {
                  InsuranceInterestedPartyText: event.target.value
                })
              }
              fullWidth
              multiline
            />
          </div>
        </RadioOption>

        <ProofOfServiceOption
          setProviderServiceValue={setProviderServiceValue}
          providerService={providerService}
          serviceProfile={serviceProfile}
          alreadyCompleted={alreadyCompleted}
          authUser={authUser}
          siteConfig={siteConfig}></ProofOfServiceOption>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  serviceAddress: state.serviceAddress,
  authUser: state.authUser,
  siteConfig: state.commonData.siteConfig
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepRentersInsurance);
