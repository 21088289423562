import React, { useState, useRef, useEffect } from 'react';
import { navigate } from 'gatsby-link';

import { Button, IconButton, TextField, Tooltip, AppBar } from '@mui/material';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Scrollbars } from 'react-custom-scrollbars';
import ProviderLogo from '../OfferGrid/ProviderLogo';

import * as offerCalc from '../../api/offerCalc';

import IFrameDialog from '../IFrameDialog';

function OrderSummaryInner({ bundledOfferSummary, offerID }) {
  return (
    <>
      {' '}
      {Object.keys(bundledOfferSummary)
        .filter(
          (providerName) =>
            bundledOfferSummary[providerName].offerOptionList.length ||
            bundledOfferSummary[providerName].hasOffers
        )
        .map((providerName) => (
          <div
            className={
              'order-summary-container offer' +
              (bundledOfferSummary[providerName].offerOptionList.length
                ? ' has-offer'
                : '') +
              (offerID ? ' single-product' : '')
            }>
            {offerID ? (
              <h4>Plan Details</h4>
            ) : (
              <h4>
                Selected {bundledOfferSummary[providerName].providerServiceList}{' '}
                Plan
              </h4>
            )}

            {bundledOfferSummary[providerName].offerOptionList.length ? (
              <div className="order-detail internet">
                <ProviderLogo
                  offer={
                    bundledOfferSummary[providerName].offerOptionList[0]
                      .selectedOffer
                  }
                />
                <h5 className="offer-name">
                  <strong>
                    {bundledOfferSummary[providerName].offerOptionList.map(
                      (offerOption, offerOptionIndex) =>
                        (offerOptionIndex > 0 ? ' + ' : '') +
                        offerOption.selectedOffer.DisplayName
                    )}
                  </strong>
                </h5>

                <div className="charge-breakdown">
                  <Table>
                    {bundledOfferSummary[providerName].offerOptionList.map(
                      (offerOption) => (
                        <TableBody>
                          {Object.keys(
                            offerOption.selectedOffer.chargeDictionary
                          )
                            .filter(
                              (chargeGroup) =>
                                offerOption.selectedOffer.chargeDictionary[
                                  chargeGroup
                                ].chargeList.filter(
                                  (charge) =>
                                    (charge.monthlyAmount !== null &&
                                      chargeGroup &&
                                      charge.name ===
                                        offerOption.selectedOffer
                                          .chargeDictionary[chargeGroup]
                                          .selectedValue) ||
                                    (offerOption.selectedOffer.chargeDictionary[
                                      chargeGroup
                                    ].selectedValueList &&
                                      offerOption.selectedOffer
                                        .chargeDictionary[chargeGroup]
                                        .selectedValueList[charge.name])
                                ).length > 0
                            )
                            .map((chargeGroup) =>
                              offerOption.selectedOffer.chargeDictionary[
                                chargeGroup
                              ].chargeList
                                .filter(
                                  (charge) =>
                                    (chargeGroup &&
                                      charge.name ===
                                        offerOption.selectedOffer
                                          .chargeDictionary[chargeGroup]
                                          .selectedValue) ||
                                    (offerOption.selectedOffer.chargeDictionary[
                                      chargeGroup
                                    ].selectedValueList &&
                                      offerOption.selectedOffer
                                        .chargeDictionary[chargeGroup]
                                        .selectedValueList[charge.name] &&
                                      (charge.purchaseAmount === null ||
                                        charge.purchaseAmount === undefined))
                                )
                                .map((charge, chargeIndex) => {
                                  return (
                                    <TableRow className="subtotal">
                                      <TableCell className="line-item">
                                        {charge.name}
                                        {offerOption.selectedOffer
                                          .chargeDictionary[chargeGroup]
                                          .selectedValueList[charge.name] &&
                                        offerOption.selectedOffer
                                          .chargeDictionary[chargeGroup]
                                          .selectedValueList[charge.name] > 1
                                          ? ' (' +
                                            offerOption.selectedOffer
                                              .chargeDictionary[chargeGroup]
                                              .selectedValueList[charge.name] +
                                            ')'
                                          : ''}{' '}
                                        {charge.maxCount > 1
                                          ? '$' +
                                            charge.monthlyAmount.toFixed(2) +
                                            ' x ' +
                                            (offerOption.selectedOffer
                                              .chargeDictionary[chargeGroup]
                                              .selectedItemCount
                                              ? offerOption.selectedOffer
                                                  .chargeDictionary[chargeGroup]
                                                  .selectedItemCount
                                              : 1)
                                          : ''}
                                      </TableCell>
                                      <TableCell className="price">
                                        {charge.monthlyAmount === 0
                                          ? charge.standardPriceText
                                            ? charge.standardPriceText
                                            : charge.contractRequired
                                            ? ''
                                            : 'Included'
                                          : '$' +
                                            (
                                              charge.monthlyAmount *
                                              (charge.maxCount > 1
                                                ? offerOption.selectedOffer
                                                    .chargeDictionary[
                                                    chargeGroup
                                                  ].selectedItemCount
                                                  ? offerOption
                                                      .chargeDictionary[
                                                      chargeGroup
                                                    ].selectedItemCount
                                                  : 1
                                                : 1)
                                            ).toFixed(2)}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })
                            )}
                        </TableBody>
                      )
                    )}

                    <TableFooter>
                      <TableRow className="subtotal">
                        <TableCell>Monthly Total for {providerName}</TableCell>
                        <TableCell className="price">
                          $
                          {bundledOfferSummary[
                            providerName
                          ].subTotalMonthly.toFixed(2)}
                        </TableCell>
                      </TableRow>
                      {bundledOfferSummary[providerName].subTotalPurchase ? (
                        <TableRow className="subtotal">
                          <TableCell>
                            One-time Charges for {providerName}
                          </TableCell>
                          <TableCell className="price">
                            $
                            {bundledOfferSummary[
                              providerName
                            ].subTotalPurchase.toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableFooter>
                  </Table>
                </div>
              </div>
            ) : (
              <div className="no-offer-selected">
                No {bundledOfferSummary[providerName].providerServiceList} plan
                selected
              </div>
            )}
          </div>
        ))}
    </>
  );
}

function getContentHeight(isProductPage) {
  const contentHeight =
    (typeof window !== 'undefined' && window.innerHeight) ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  return contentHeight - (isProductPage ? 260 : 150);
}

function OrderSummaryInternet({
  serviceAddress,
  offerOptionDictionary,
  grandTotal,
  setGrandTotal,
  isProductPage,
  offerID
}) {
  const [bundledOfferSummary, setBundledOfferSummary] = useState(null);
  const [selectedOfferDictionary, setSelectedOfferDictionary] = useState(null);

  const [scrollbarHeight, setScrollbarHeight] = useState(
    getContentHeight(isProductPage)
  );

  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);

      timeoutId = setTimeout(
        () => setScrollbarHeight(getContentHeight(isProductPage)),
        150
      );
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    let _selectedOfferDictionary = {};

    if (offerOptionDictionary) {
      Object.keys(offerOptionDictionary).map((providerService) => {
        _selectedOfferDictionary[providerService] = {
          ...offerOptionDictionary[providerService],
          selectedOfferID:
            offerOptionDictionary[providerService].selectedOfferID,
          selectedOffer: offerOptionDictionary[providerService].selectedOfferID
            ? offerOptionDictionary[providerService].offerList.filter(
                (_offer) =>
                  _offer.OfferID ===
                  offerOptionDictionary[providerService].selectedOfferID
              )[0]
            : null
        };
      });

      setSelectedOfferDictionary({ ..._selectedOfferDictionary });

      console.log('offerOptionDictionary updated');
    }
  }, [offerOptionDictionary]);

  useEffect(() => {
    if (selectedOfferDictionary) {
      let _bundledOfferSummary = {};
      let _grandTotalMonthly = 0;
      let _grandTotalPurchase = 0;

      Object.keys(selectedOfferDictionary)
        .filter(
          (providerService) =>
            !offerID ||
            (selectedOfferDictionary[providerService].selectedOffer &&
              selectedOfferDictionary[providerService].selectedOffer.OfferID ===
                offerID)
        )
        .map((providerService) => {
          const offerOption = selectedOfferDictionary[providerService];

          if (offerOption.selectedOffer) {
            const summaryGroup = providerService;
            if (!_bundledOfferSummary[summaryGroup]) {
              _bundledOfferSummary[summaryGroup] = {
                subTotalMonthly: 0,
                subTotalPurchase: 0,
                providerServiceList: '',
                offerOptionList: []
              };
            }

            const _calcPrice = offerCalc.getInternetPrice(
              offerOption.selectedOffer
            );

            _bundledOfferSummary[summaryGroup].offerOptionList.push({
              ...offerOption,
              providerService,
              offerOptionMonthlyTotal: _calcPrice.monthlyAmount,
              offerOptionPurchaseTotal: _calcPrice.purchaseAmount
            });

            _grandTotalMonthly += _calcPrice.monthlyAmount;
            _grandTotalPurchase += _calcPrice.purchaseAmount;
          } else {
            _bundledOfferSummary[providerService + ' Provider'] = {
              subTotalMonthly: 0,
              subTotalPurchase: 0,
              providerServiceList: providerService,
              offerOptionList: [],
              hasOffers:
                serviceAddress.OfferList.filter(
                  (offer) =>
                    offer.ProviderServiceList.indexOf(providerService) !== -1
                ).length > 0
            };
          }
        });

      Object.keys(_bundledOfferSummary).map((providerName) => {
        _bundledOfferSummary[providerName].subTotalMonthly = 0;
        _bundledOfferSummary[providerName].offerOptionList.map(
          (offerOption, offerOptionIndex) => {
            _bundledOfferSummary[providerName].subTotalMonthly +=
              offerOption.offerOptionMonthlyTotal;

            _bundledOfferSummary[providerName].subTotalPurchase +=
              offerOption.offerOptionPurchaseTotal;

            _bundledOfferSummary[providerName].providerServiceList +=
              (offerOptionIndex > 0
                ? offerOptionIndex ===
                  _bundledOfferSummary[providerName].offerOptionList.length - 1
                  ? ' and '
                  : ', '
                : '') + offerOption.providerService;
          }
        );
      });

      setBundledOfferSummary(_bundledOfferSummary);

      if (setGrandTotal) {
        setGrandTotal({
          monthly: _grandTotalMonthly,
          purchase: _grandTotalPurchase
        });
      }
    } else {
      setBundledOfferSummary(null);
    }
  }, [selectedOfferDictionary]);

  useEffect(() => {
    console.log('bundle offer summary updated', bundledOfferSummary);
  }, [bundledOfferSummary]);

  return bundledOfferSummary ? (
    <>
      <div className="internet-order-summary-container">
        {isProductPage ? (
          <Scrollbars autoHide={true} style={{ height: scrollbarHeight }}>
            <OrderSummaryInner
              bundledOfferSummary={bundledOfferSummary}
              offerID={offerID}
            />
          </Scrollbars>
        ) : (
          <OrderSummaryInner
            bundledOfferSummary={bundledOfferSummary}
            offerID={offerID}
          />
        )}
      </div>

      <div className={'internet-order-summary-container mobile'}>
        <OrderSummaryInner
          bundledOfferSummary={bundledOfferSummary}
          offerID={offerID}
        />
      </div>
    </>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    stateList: state.commonData.stateList,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderSummaryInternet);
