import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby-link';
import { withPrefix } from 'gatsby-link';

import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Alert,
  LinearProgress
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CanvasConfetti from '../CanvasConfetti';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/Edit';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {
  QontoConnector,
  QontoStepIconRoot,
  QontoStepIcon,
  CircularProgressWithLabel,
  dayOfWeekList,
  businessHourList
} from '../ServiceProfileShared/config';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import { getServiceProfile } from '../../api/partnerDataApi';

import ServiceRequiredOption from './ServiceRequiredOption';
import RadioOption from './RadioOption';
import ProviderSearch from './ProviderSearch';
import ProofOfServiceOption from './ProofOfServiceOption';
import ResidentInternetHeader from '../ResidentInternetHeader';
import ServiceAddendumFileUpload from '../ServiceProfileShared/ServiceAddendumFileUpload';

import { checkProviderServiceHasDefaultProvider } from './config';

import '../PropertyProfile/index.css';
import './index.css';

function InternetTextEdit({
  providerService,
  setProviderServiceValue,
  editInternetText,
  setEditInternetText,
  profileKey,
  show
}) {
  return (
    <div className={'edit-internet-text' + (show ? ' show' : '')}>
      <Button onClick={() => setEditInternetText(!editInternetText)}>
        Customize Text{' '}
        <KeyboardArrowDownIcon
          style={{ transform: editInternetText ? 'rotate(180deg)' : 'none' }}
        />
      </Button>
      {editInternetText && show ? (
        <div className="checkbox-option internet">
          <TextField
            fullWidth
            value={providerService.InternetSpecialCircumstanceHeader}
            label="Header Text"
            multiline
            onChange={(event) =>
              setProviderServiceValue(providerService, {
                InternetSpecialCircumstanceHeader: event.target.value
              })
            }
          />

          <TextField
            fullWidth
            value={providerService.InternetSpecialCircumstanceDescription}
            label="Message to Resident"
            multiline
            onChange={(event) =>
              setProviderServiceValue(providerService, {
                InternetSpecialCircumstanceDescription: event.target.value
              })
            }
          />

          <ServiceAddendumFileUpload
            profileKey={profileKey}
            providerService={providerService}
            setProviderServiceValue={setProviderServiceValue}
            label={
              providerService.ServiceAddendumFile ? (
                <>
                  <CheckCircleIcon
                    style={{ color: 'green', marginRight: '5px' }}
                  />
                  {providerService.ProviderServiceName +
                    ' Setup Instructions uploaded. Click to edit'}
                </>
              ) : (
                'Upload ' +
                providerService.ProviderServiceName +
                ' Setup Instructions'
              )
            }
          />
        </div>
      ) : null}
    </div>
  );
}

function StepInternetOptions({
  siteConfig,
  authUser,
  serviceProfile,
  setProviderServiceValue,
  activeStep,
  profileKey,
  setServiceProfile
}) {
  const [editInternetText, setEditInternetText] = useState(null);

  return serviceProfile.ServiceSectionList[
    activeStep
  ].ProviderServiceList.filter(
    (providerService) => providerService.ProviderServiceID === 1
  ).map((providerService, providerServiceIndex) => (
    <div className="section internet">
      <h3>Internet Providers</h3>
      <div className="internet-preview">
        <ResidentInternetHeader
          setupProviderList={serviceProfile.ServiceSectionList[
            activeStep
          ].ProviderList.filter(
            (provider) =>
              provider.ProviderServiceList.indexOf('Internet') !== -1 &&
              provider.IsProviderAvailable !== false
          )}
          setUpIncludedInRent={
            providerService.InternetIsPartOfRent ||
            providerService.InternetSpecialCircumstanceChecked ||
            providerService.InternetHasInHousePackage
          }
          setupInstructionsFile={providerService.ServiceAddendumFile}
          setupSpecialCircumstancesHeader={
            providerService.InternetSpecialCircumstanceHeader
          }
          setupSpecialCircumstanceDescription={
            providerService.InternetSpecialCircumstanceDescription
          }
        />
      </div>

      <div className="internet-options">
        <Alert severity="info">
          Please confirm that the following Internet providers service your
          property. If one is missing, please add it.
        </Alert>

        <div className="form-content internet-provider-list">
          {/* <ProviderMenu
    providerService={providerService}
    setProviderServiceValue={setProviderServiceValue}
    setProviderValue={setProviderValue}
    providerList={serviceProfile.ServiceSectionList[
      activeStep
    ].ProviderList.filter(
      (provider) => provider.ProviderServiceID === 1
    )}></ProviderMenu> */}

          {serviceProfile.ServiceSectionList[activeStep].ProviderList.filter(
            (provider) =>
              provider.ProviderServiceList.indexOf('Internet') !== -1
          ).map((provider) => (
            <FormControl>
              <RadioGroup
                row
                name="service-available"
                value={
                  provider.IsProviderAvailable !== null
                    ? provider.IsProviderAvailable
                    : 'true'
                }
                onChange={(event) => {
                  setServiceProfile({
                    ...serviceProfile,
                    ServiceSectionList: serviceProfile.ServiceSectionList.map(
                      (step, stepIndex) =>
                        stepIndex === activeStep
                          ? {
                              ...step,
                              ProviderList: step.ProviderList.map((_provider) =>
                                _provider.ProviderID === provider.ProviderID
                                  ? {
                                      ..._provider,
                                      IsProviderAvailable:
                                        event.target.value == 'true'
                                    }
                                  : { ..._provider }
                              )
                            }
                          : { ...step }
                    )
                  });
                }}>
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={'Yes'}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={'No'}
                />

                {provider.LogoFile ? (
                  <img src={provider.LogoFile} className="provider-logo" />
                ) : (
                  <span className="provider-name">{provider.ProviderName}</span>
                )}
              </RadioGroup>
            </FormControl>
          ))}
          <FormControl>
            {providerService.ProviderServiceID === 1 ? (
              <ProviderSearch
                value={providerService.ProviderNameEntered}
                onChange={(event, value) => {
                  setProviderServiceValue(providerService, {
                    ProviderNameEntered: value
                  });
                }}
                providerList={(serviceProfile.OtherProviderDictionary[
                  providerService.ProviderServiceID
                ]
                  ? serviceProfile.OtherProviderDictionary[
                      providerService.ProviderServiceID
                    ]
                  : []
                ).map((option) => option.ProviderName)}
                label={'Add another Internet provider'}
                buttonText={'Click to Add'}
                buttonClick={(event) => {
                  let _provider = serviceProfilev.OtherProviderDictionary[
                    providerService.ProviderServiceID
                  ].filter(
                    (provider) =>
                      provider.ProviderName ===
                      providerService.ProviderNameEntered
                  )[0];

                  if (_provider) {
                    const _existingProvider = serviceProfile.ServiceSectionList[
                      activeStep
                    ].ProviderList.filter(
                      (provider) => provider.ProviderID === _provider.ProviderID
                    )[0];
                    if (_existingProvider) {
                      setServiceProfile({
                        ...serviceProfile,
                        ServiceSectionList:
                          serviceProfile.ServiceSectionList.map(
                            (step, stepIndex) =>
                              stepIndex === activeStep
                                ? {
                                    ...step,
                                    ProviderList: step.ProviderList.map(
                                      (__provider) =>
                                        __provider.ProviderID ===
                                        _existingProvider.ProviderID
                                          ? {
                                              ...__provider,
                                              IsProviderAvailable: true
                                            }
                                          : {
                                              ...__provider
                                            }
                                    )
                                  }
                                : { ...step }
                          )
                      });
                    } else {
                      setServiceProfile({
                        ...serviceProfile,
                        ServiceSectionList:
                          serviceProfile.ServiceSectionList.map(
                            (step, stepIndex) =>
                              stepIndex === activeStep
                                ? {
                                    ...step,
                                    ProviderServiceList:
                                      step.ProviderServiceList.map(
                                        (_providerService) =>
                                          _providerService.ProviderServiceID ===
                                          providerService.ProviderServiceID
                                            ? {
                                                ..._providerService,
                                                ProviderNameEntered: ''
                                              }
                                            : {
                                                ..._providerService
                                              }
                                      ),
                                    ProviderList: step.ProviderList.concat([
                                      {
                                        ..._provider,
                                        ProviderServiceList: 'Internet',
                                        IsProviderAvailable: true
                                      }
                                    ])
                                  }
                                : { ...step }
                          )
                      });
                    }
                  } else {
                    setProviderServiceValue(providerService, {
                      ProviderNameEntered: ''
                    });
                  }
                }}
              />
            ) : (
              <TextField
                value={providerService.ProviderNameEntered}
                onChange={(event) => {
                  setProviderServiceValue(providerService, {
                    ProviderNameEntered: event.target.value
                  });
                }}
                className="text"
                label={
                  'Add Another ' +
                  providerService.ProviderServiceName +
                  ' Provider'
                }
                placeholder={'Enter provider name here'}
                fullWidth
              />
            )}
          </FormControl>
        </div>

        {[
          serviceProfile.ServiceSectionList[activeStep].ProviderList.filter(
            (provider) =>
              provider.ProviderServiceList.indexOf('Internet') !== -1 &&
              provider.IsProviderAvailable !== false
          ).length
        ].map((length) =>
          length > 0 ? (
            <>
              <Alert severity="info">
                {length > 1
                  ? 'Do any of these providers '
                  : 'Does this provider '}
                have Wi-Fi ready equipment pre-installed in each unit? If they
                do, we'll let the residents know.
              </Alert>

              <div className="form-content internet-provider-list">
                {serviceProfile.ServiceSectionList[
                  activeStep
                ].ProviderList.filter(
                  (provider) =>
                    provider.ProviderServiceList.indexOf('Internet') !== -1 &&
                    provider.IsProviderAvailable !== false
                ).map((provider) => (
                  <>
                    <FormControl>
                      <RadioGroup
                        row
                        name="service-available"
                        value={
                          provider.IsProviderPreInstalled !== null
                            ? provider.IsProviderPreInstalled
                            : false
                        }
                        onChange={(event) => {
                          setServiceProfile({
                            ...serviceProfile,
                            ServiceSectionList:
                              serviceProfile.ServiceSectionList.map(
                                (step, stepIndex) =>
                                  stepIndex === activeStep
                                    ? {
                                        ...step,
                                        ProviderList: step.ProviderList.map(
                                          (_provider) =>
                                            _provider.ProviderID ===
                                            provider.ProviderID
                                              ? {
                                                  ..._provider,
                                                  IsProviderPreInstalled:
                                                    event.target.value == 'true'
                                                }
                                              : {
                                                  ..._provider
                                                }
                                        )
                                      }
                                    : { ...step }
                              )
                          });
                        }}>
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label={'Yes'}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label={'No'}
                        />

                        {provider.LogoFile ? (
                          <img
                            src={provider.LogoFile}
                            className="provider-logo"
                          />
                        ) : (
                          <span className="provider-name">
                            {provider.ProviderName}
                          </span>
                        )}
                      </RadioGroup>
                    </FormControl>
                  </>
                ))}
              </div>
            </>
          ) : null
        )}

        {[
          serviceProfile.ServiceSectionList[activeStep].ProviderList.filter(
            (provider) =>
              provider.ProviderServiceList.indexOf('Internet') !== -1 &&
              provider.IsProviderAvailable !== false
          ).length
        ].map((length) =>
          length > 0 ? (
            <>
              <Alert severity="info">
                {length > 1
                  ? 'Would you like to highlight ONE of these providers as being "Recommended by the Property"?'
                  : 'Would you like to highlight this provider as being "Recommended by the Property"?'}
              </Alert>

              <div className="form-content internet-provider-list">
                {serviceProfile.ServiceSectionList[
                  activeStep
                ].ProviderList.filter(
                  (provider) =>
                    provider.ProviderServiceList.indexOf('Internet') !== -1 &&
                    provider.IsProviderAvailable !== false
                ).map((provider) => (
                  <FormControl>
                    <RadioGroup
                      row
                      name="service-available"
                      value={provider.IsProviderPreferred === 1}
                      onChange={(event) => {
                        setServiceProfile({
                          ...serviceProfile,
                          ServiceSectionList:
                            serviceProfile.ServiceSectionList.map(
                              (step, stepIndex) =>
                                stepIndex === activeStep
                                  ? {
                                      ...step,
                                      ProviderList: step.ProviderList.map(
                                        (_provider) =>
                                          _provider.ProviderID ===
                                          provider.ProviderID
                                            ? {
                                                ..._provider,
                                                IsProviderPreferred:
                                                  event.target.value === 'true'
                                                    ? 1
                                                    : 0
                                              }
                                            : {
                                                ..._provider,
                                                IsProviderPreferred: 0
                                              }
                                      )
                                    }
                                  : { ...step }
                            )
                        });
                      }}>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={'Yes'}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={'No'}
                      />

                      {provider.LogoFile ? (
                        <img
                          src={provider.LogoFile}
                          className="provider-logo"
                        />
                      ) : (
                        <span className="provider-name">
                          {provider.ProviderName}
                        </span>
                      )}
                    </RadioGroup>
                  </FormControl>
                ))}
              </div>
            </>
          ) : null
        )}

        <Alert severity="info">
          Does your property have any{' '}
          <strong>
            <em>special circumstances</em>
          </strong>{' '}
          that residents need to be reminded of or told about?
        </Alert>

        <div className={'checkbox-option internet special-circumstances'}>
          <FormControl fullWidth>
            <RadioGroup
              row
              name="InternetSettings"
              value={
                providerService.InternetSpecialCircumstanceChecked
                  ? 'InternetSpecialCircumstanceChecked'
                  : providerService.InternetIsPartOfRent
                  ? 'InternetIsPartOfRent'
                  : providerService.InternetHasInHousePackage
                  ? 'InternetHasInHousePackage'
                  : ''
              }
              onChange={(event) => {
                setProviderServiceValue(providerService, {
                  InternetSpecialCircumstanceChecked: false,
                  InternetHasInHousePackage: false,
                  InternetIsPartOfRent: false,
                  InternetSpecialCircumstanceHeader: '',
                  InternetSpecialCircumstanceDescription: '',
                  [event.target.value]: true
                });
                setEditInternetText(
                  event.target.value === 'InternetSpecialCircumstanceChecked'
                );
              }}>
              <FormControlLabel
                fullWidth
                value={''}
                control={<Radio />}
                label={<div>None</div>}
              />

              <FormControlLabel
                fullWidth
                value={'InternetIsPartOfRent'}
                control={<Radio />}
                label={
                  <div>
                    Each unit has Free Wi-Fi Internet included as part of their
                    rent
                  </div>
                }
              />

              <InternetTextEdit
                show={providerService.InternetIsPartOfRent}
                providerService={providerService}
                setProviderServiceValue={setProviderServiceValue}
                editInternetText={editInternetText}
                setEditInternetText={setEditInternetText}
                profileKey={profileKey}
              />

              <FormControlLabel
                fullWidth
                value={'InternetHasInHousePackage'}
                control={<Radio />}
                label={
                  <div>
                    We offer an in-house Internet package that is paid to us
                    directly, along with rent.
                  </div>
                }
              />

              <InternetTextEdit
                show={providerService.InternetHasInHousePackage}
                providerService={providerService}
                setProviderServiceValue={setProviderServiceValue}
                editInternetText={editInternetText}
                setEditInternetText={setEditInternetText}
                profileKey={profileKey}
              />
              <FormControlLabel
                fullWidth
                value={'InternetSpecialCircumstanceChecked'}
                control={<Radio />}
                label={<div>Other (explain below)</div>}
              />

              <InternetTextEdit
                show={providerService.InternetSpecialCircumstanceChecked}
                providerService={providerService}
                setProviderServiceValue={setProviderServiceValue}
                editInternetText={editInternetText}
                setEditInternetText={setEditInternetText}
                profileKey={profileKey}
              />
            </RadioGroup>
          </FormControl>
        </div>

        {/* <div className={'checkbox-option internet'}>
      <FormControlLabel
        control={
          <Checkbox
            checked={
              providerService.InternetIsMarketingAgreement
            }
            onChange={(event) =>
              setProviderServiceValue(providerService, {
                InternetIsMarketingAgreement:
                  event.target.checked
              })
            }
          />
        }
        label={
          <div>
            We have an Exclusive Marketing Agreement
            with a Preferred Provider.
          </div>
        }
      />
    </div>
    <div className={'checkbox-option internet'}>
      <FormControlLabel
        control={
          <Checkbox
            checked={
              providerService.InternetIsSpecialPricing
            }
            onChange={(event) =>
              setProviderServiceValue(providerService, {
                InternetIsSpecialPricing:
                  event.target.checked
              })
            }
          />
        }
        label={
          <div>
            Residents get Special Pricing from a
            provider through a custom URL (website)
          </div>
        }
      />
    </div>
    <div className="internet-special-pricing-url">
      <TextField
        value={
          providerService.InternetSpecialPricingUrl
        }
        onChange={(event) => {
          setProviderServiceValue(providerService, {
            InternetSpecialPricingUrl:
              event.target.value
          });
        }}
        disabled={
          !providerService.InternetIsSpecialPricing
        }
        className="text"
        label={
          'Enter Special Pricing Website (URL) Here'
        }
        placeholder={'https://'}
        fullWidth
      />
    </div> */}
      </div>

      {/* <div className="section">
<h3>In-House Internet or TV Package</h3>

<div className="form-content">
  <RadioOption
    setProviderServiceValue={setProviderServiceValue}
    providerService={providerService}
    field={'InternetHasInHousePackage'}
    question={
      'Does your property offer an "In-House" Internet or TV package that you\'d like us to promote?'
    }>
    <div
      className={
        'sub-option' +
        (!providerService.InternetHasInHousePackage
          ? ' disabled'
          : '')
      }>
      <p>
        If YES, please enter your contact information so
        we can reach out to you for the details.
      </p>

      <div className="contact-form">
        <TextField
          label="Name"
          value={serviceProfile.Header.UpdaterName}
          onChange={(event) =>
            setServiceProfile({
              ...serviceProfile,
              Header: {
                ...serviceProfile.Header,
                UpdaterName: event.target.value
              }
            })
          }
          fullWidth
          disabled={
            !providerService.InternetHasInHousePackage
          }
        />
        <InputMask
          mask="999-999-9999"
          maskChar="_"
          value={serviceProfile.Header.UpdaterPhone}
          onChange={(event) =>
            setServiceProfile({
              ...serviceProfile,
              Header: {
                ...serviceProfile.Header,
                UpdaterPhone: event.target.value
              }
            })
          }>
          {() => (
            <TextField
              required
              variant="outlined"
              id="phone"
              name="phone"
              label={'Phone Number'}
              type="tel"
              fullWidth
            />
          )}
        </InputMask>
        <TextField
          label="Email Address"
          type="email"
          value={serviceProfile.Header.UpdaterEmail}
          onChange={(event) =>
            setServiceProfile({
              ...serviceProfile,
              Header: {
                ...serviceProfile.Header,
                UpdaterEmail: event.target.value
              }
            })
          }
          fullWidth
          disabled={
            !providerService.InternetHasInHousePackage
          }
        />
      </div>
    </div>
  </RadioOption>
</div>
</div> */}

      {/* <div className="section">
    <h3>Satellite TV Service</h3>

    <div className="form-content">
      <RadioOption
        setProviderServiceValue={
          setProviderServiceValue
        }
        providerService={providerService}
        field={'InternetIsAllowSatellite'}
        question={
          'Can residents install satellite TV services such as DirecTV or Dish TV? These services require the installation of a satellite dish.'
        }></RadioOption>
    </div>
  </div> */}
    </div>
  ));
}

const mapStateToProps = (state) => ({
  serviceAddress: state.serviceAddress,
  authUser: state.authUser,
  siteConfig: state.commonData.siteConfig
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepInternetOptions);
