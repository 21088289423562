import React, { useState, useRef, useEffect, version } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import GeneralPopup from '../GeneralPopup';
import ApplicantItemCard from './ApplicantItemCard';

import './index.css';

export function ApplicantGroup({
  groupItem,
  residentOnboarding,
  loadResidentOnboarding,
  buttonAction,
  buttonLabel,
  buttonSelectedItem
}) {
  useEffect(() => {
    if (
      !residentOnboarding ||
      residentOnboarding.Header.ResidentID !== groupItem.ResidentID
    ) {
      console.log('load it', groupItem);
      loadResidentOnboarding(groupItem.ResidentID);
    }
  }, []);

  function getItemList() {
    return residentOnboarding
      ? residentOnboarding.Checklist.filter(
          (item) =>
            item.OnboardingSection === 'applicant' &&
            item.OnboardingColumn === groupItem.OnboardingColumn
        ).map((item) => {
          const occ = item.OccupantID
            ? residentOnboarding.OccupantList.filter(
                (occ) => occ.OccupantID === item.OccupantID
              )[0]
            : null;

          return {
            ...item,
            ResidentName: occ
              ? occ.FirstName + ' ' + occ.LastName
              : residentOnboarding.Header.FirstName +
                ' ' +
                residentOnboarding.Header.LastName,
            Relationship: occ
              ? occ.ResidentRelationshipName
              : 'Primary Applicant'
          };
        })
      : [];
  }

  return residentOnboarding ? (
    <div className="applicant-dashboard">
      <ul className={'applicant-item-list'}>
        {getItemList().map((item) => (
          <li
            className={'applicant-item'}
            style={{
              width: 100 / groupItem.itemCount + '%'
            }}>
            <ApplicantItemCard
              item={item}
              buttonAction={buttonAction}
              buttonLabel={buttonLabel}
              buttonSelectedItem={buttonSelectedItem}
            />
          </li>
        ))}
      </ul>
    </div>
  ) : null;
}

function ApplicantGroupPopup({
  residentOnboarding,
  groupItem,
  closeFunc,
  actions
}) {
  return (
    <GeneralPopup
      title={groupItem.OnboardingColumn}
      className={
        'applicant-group-popup' + (' item-count-' + groupItem.itemCount)
      }
      message={
        <>
          <p style={{ paddingTop: '20px' }}>
            Your application lists {groupItem.itemCount} occupants that are
            responsible for the lease.{' '}
            {groupItem.ResidentFormID === 3
              ? "A copy of each applicant's government-issued photo ID is required for identity verification."
              : 'Please sign the authorization forms below, so we can complete the ' +
                groupItem.OnboardingColumn.toLowerCase() +
                ' for each applicant.'}
          </p>

          <ApplicantGroup
            residentOnboarding={residentOnboarding}
            groupItem={groupItem}
            loadResidentOnboarding={actions.loadResidentOnboarding}
          />
        </>
      }
      closeFunc={closeFunc}
      closeLabel={'Close'}
    />
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      saveOrder: bindActionCreators(serviceAddressActions.saveOrder, dispatch),
      mockRouteUpdated: bindActionCreators(
        webSocketActions.mockRouteUpdated,
        dispatch
      ),
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicantGroupPopup);
