import React, { useState, useRef, useEffect } from 'react';
import { Button, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import InfoIcon from '@mui/icons-material/Info';

import './index.css';
import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';

import * as dataApi from '../../api/dataApi';
import Empty from '../Common/Empty';
import ProviderLogo from '../OfferGrid/ProviderLogo';
import ProviderListMap from './ProviderListMap';
import AddressSearch from '../AddressSearch';
import PlaceGrid from '../PlaceGrid';
import TaskMenu from '../TaskMenu';

function MovingTrucks({ actions, serviceAddress, service, placeList }) {
  const [providerList, setProviderList] = useState(null);
  const [placeListPrevious, setPlaceListPrevious] = useState(null);
  const [placeListNew, setPlaceListNew] = useState(null);
  const [providerToHighlight, setProviderToHighlight] = useState(null);
  const providerToHighlightTimeout = useRef();
  const [previousAddressSearched, setPreviousAddressSearched] = useState(null);
  const [showAllResults, setShowAllResults] = useState(false);

  useEffect(() => {
    if (service && placeList) {
      const _providerList = service.ProviderList.map((provider) => {
        let _provider = {
          ...provider,
          ProviderName: provider.DisplayName,
          LogoFile: provider.LogoFile,
          distancePrevious: null,
          distanceNew: null
        };

        let placePrevious = placeList['Moving Trucks - Previous']
          ? placeList['Moving Trucks - Previous'].filter(
              (place) =>
                place.GooglePlaceName.toLowerCase().indexOf(
                  _provider.ProviderName.toLowerCase()
                ) !== -1
            )[0]
          : null;

        if (placePrevious) {
          _provider.distancePrevious = placePrevious.CalculatedDistance;
          _provider.latitudePrevious = placePrevious.Latitude;
          _provider.longitudePrevious = placePrevious.Longitude;
          _provider.placeIdPrevious = placePrevious.GooglePlaceId;
        }

        let placeNew = placeList['Moving Trucks - New']
          ? placeList['Moving Trucks - New'].filter(
              (place) =>
                place.GooglePlaceName.toLowerCase().indexOf(
                  _provider.ProviderName.toLowerCase()
                ) !== -1
            )[0]
          : null;

        if (placeNew) {
          _provider.distanceNew = placeNew.CalculatedDistance;
          _provider.latitudeNew = placeNew.Latitude;
          _provider.longitudeNew = placeNew.Longitude;
          _provider.placeIdNew = placeNew.GooglePlaceId;
        }

        return _provider;
      });

      console.log('_providerList', _providerList);
      setProviderList(
        sortProviders(_providerList)
        // .filter(
        //   (provider) =>
        //     (provider.distancePrevious && provider.distanceNew) ||
        //     (provider.distanceNew && !serviceAddress.Address.Previous_Address)
        // )
      );

      setPlaceListPrevious(
        placeList['Moving Trucks - Previous']
          ? placeList['Moving Trucks - Previous'].map((place, placeIndex) => {
              return {
                GooglePlaceId: place.GooglePlaceId,
                ProviderName: place.GooglePlaceName,
                latitudePrevious: place.Latitude,
                longitudePrevious: place.Longitude
              };
            })
          : null
      );

      setPlaceListNew(
        placeList['Moving Trucks - New']
          ? placeList['Moving Trucks - New'].map((place, placeIndex) => {
              return {
                GooglePlaceId: place.GooglePlaceId,
                ProviderName: place.GooglePlaceName,
                latitudeNew: place.Latitude,
                longitudeNew: place.Longitude
              };
            })
          : null
      );
    }
  }, [placeList]);

  function sortProviders(_providerList) {
    let sortedList = _providerList.concat([]);

    sortedList.sort((a, b) => {
      let valueA =
        (a.distanceNew ? a.distanceNew : 999999) +
        (a.distancePrevious ? a.distancePrevious : 999999);
      let valueB =
        (b.distanceNew ? b.distanceNew : 999999) +
        (b.distancePrevious ? b.distancePrevious : 999999);

      let result = valueA > valueB ? 1 : valueA < valueB ? -1 : 0;

      return result;
    });

    return sortedList;
  }

  return (
    <div className="moving-trucks">
      {providerList ? (
        <>
          <div className="provider-map-container left">
            {serviceAddress.Address.Previous_Address ? (
              <ProviderListMap
                address={{
                  address: serviceAddress.Address.Previous_Address,
                  secondary: serviceAddress.Address.Previous_Secondary,
                  city: serviceAddress.Address.Previous_City,
                  state: serviceAddress.Address.Previous_State,
                  zip: serviceAddress.Address.Previous_Zip,
                  latitude: serviceAddress.Address.Previous_Latitude,
                  longitude: serviceAddress.Address.Previous_Longitude
                }}
                providerList={placeListPrevious}
                providerFieldKey={'placeIdPrevious'}
                providerFieldLatitude={'latitudePrevious'}
                providerFieldLongitude={'longitudePrevious'}
                providerToHighlight={providerToHighlight}
                title={
                  'Moving from: ' +
                  serviceAddress.Address.Previous_Address +
                  (serviceAddress.Address.Previous_Secondary
                    ? ' ' + serviceAddress.Address.Previous_Secondary
                    : '')
                }
              />
            ) : (
              <div className="previous-address-lookup-container">
                <div
                  className={
                    'previous-address-lookup' +
                    (previousAddressSearched ? ' address-searched' : '')
                  }>
                  <AddressSearch
                    hideSearchBy
                    isPreviousAddress
                    addressLabel={'Enter the address you are moving from'}
                    onAddressSearched={() => setPreviousAddressSearched(true)}
                    onSelected={() =>
                      actions.pageLoading(true, 'Saving your address...')
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div className="list">
            {providerList.length ? (
              <TableContainer component={Paper}>
                <Table aria-label="simple table" stickyHeader={true}>
                  <TableHead>
                    <TableCell style={{ width: '35%' }}>
                      <ArrowForwardIcon className="left-icon" />
                      Current Home
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      Distance To
                    </TableCell>
                    <TableCell style={{ textAlign: 'right', width: '35%' }}>
                      New Home <ArrowForwardIcon />
                    </TableCell>
                  </TableHead>
                </Table>

                {providerList.map((provider) => (
                  <Button
                    className="provider-link"
                    href={provider.ServiceStartUrl}
                    target="mi_offsite"
                    onMouseOver={() => {
                      if (providerToHighlightTimeout.current) {
                        clearTimeout(providerToHighlightTimeout.current);
                      }

                      setProviderToHighlight(provider);
                    }}
                    onMouseOut={() => {
                      providerToHighlightTimeout.current = setTimeout(
                        () => setProviderToHighlight(null),
                        200
                      );
                    }}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            className={
                              !provider.distancePrevious ? 'empty' : ''
                            }
                            style={{ width: '35%' }}>
                            {provider.distancePrevious ? (
                              provider.distancePrevious.toFixed(1) + ' mi'
                            ) : serviceAddress.Address.Previous_Address ? (
                              <>
                                No locations
                                <br />
                                within 30 mi
                              </>
                            ) : (
                              'Not Set'
                            )}
                          </TableCell>
                          <TableCell
                            style={{ textAlign: 'center', padding: '0px' }}>
                            <ProviderLogo offer={provider} />
                            {provider.ProviderName === 'Enterprise' ? (
                              <Tooltip title="Trucks must be returned to the same location">
                                <p>
                                  Local moves only <InfoIcon />
                                </p>
                              </Tooltip>
                            ) : null}
                          </TableCell>
                          <TableCell
                            className={!provider.distanceNew ? 'empty' : ''}
                            style={{ width: '35%', textAlign: 'right' }}>
                            {provider.distanceNew ? (
                              provider.distanceNew.toFixed(1) + ' mi'
                            ) : (
                              <>
                                No locations
                                <br />
                                within 30 mi
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Button>
                ))}
              </TableContainer>
            ) : (
              <Empty>No Truck Rental companies found near you</Empty>
            )}
          </div>
          <div className="provider-map-container right">
            <ProviderListMap
              address={{
                address: serviceAddress.Address.Address,
                secondary: serviceAddress.Address.Secondary,
                city: serviceAddress.Address.City,
                state: serviceAddress.Address.State,
                zip: serviceAddress.Address.Zip,
                latitude: serviceAddress.Address.Latitude,
                longitude: serviceAddress.Address.Longitude
              }}
              providerList={placeListNew}
              providerFieldKey={'placeIdNew'}
              providerFieldLatitude={'latitudeNew'}
              providerFieldLongitude={'longitudeNew'}
              providerToHighlight={providerToHighlight}
              title={
                'Moving to: ' +
                serviceAddress.Address.Address +
                (serviceAddress.Address.Secondary
                  ? ' ' + serviceAddress.Address.Secondary
                  : '')
              }
            />
          </div>

          {/* <Table>
            <TableFooter>
              <TableCell style={{ textAlign: 'right', border: 'none' }}>
                <Button onClick={() => setShowAllResults(!showAllResults)}>
                  View All{' '}
                  <KeyboardArrowDownIcon
                    style={{
                      transform: showAllResults ? 'rotate(180deg)' : null
                    }}
                  />
                </Button>
                {showAllResults ? (
                  <div className="all-results">
                    <div>
                      <h3>Current Address</h3>
                      <PlaceGrid
                        placeList={placeList}
                        field={'previous'}
                        include={{ distance: true }}
                      />
                    </div>
                    <div>
                      <h3>New Address</h3>
                      <PlaceGrid
                        placeList={placeList}
                        field={'new'}
                        include={{ distance: true }}
                      />
                    </div>
                  </div>
                ) : null}
              </TableCell>
            </TableFooter>
          </Table> */}
        </>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      markTaskComplete: bindActionCreators(
        serviceAddressActions.markTaskComplete,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MovingTrucks);
