import React, { useState, useRef, useEffect, version } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby-link';
import { Alert, Button } from '@mui/material';

import { getCookie, setSessionStorage } from '../../api/dataApi';

import './index.css';
import { ChevronRight } from '@mui/icons-material';

function ServiceProfileNotice({
  serviceAddress,
  residentSection,
  serviceProfileStep
}) {
  const profileKey = getCookie('mi-profile-key');
  const [noticeList, setNoticeList] = useState(null);

  useEffect(() => {
    if (profileKey && serviceAddress && serviceAddress.NoticeList) {
      setNoticeList(
        serviceAddress.NoticeList[
          residentSection ? residentSection : serviceProfileStep
        ]
      );
    }
  }, [profileKey, serviceAddress]);

  useEffect(() => {
    console.log('noticeList', noticeList);
  }, [noticeList]);

  return noticeList && noticeList.length ? (
    <div
      className={
        'service-profile-notice-list' +
        (residentSection
          ? ' resident-' + residentSection
          : ' service-profile-' + serviceProfileStep)
      }>
      {noticeList.map((notice) => (
        <Alert severity={notice.Severity}>
          <span
            dangerouslySetInnerHTML={{
              __html: residentSection
                ? notice.ResidentNotice
                : notice.ServiceProfileNotice
            }}></span>
          {residentSection ? (
            <Button
              onClick={() => {
                setSessionStorage('mi-last-route', '');
                navigate(
                  '/property-setup?property=' +
                    profileKey.split(':')[0] +
                    '&step=' +
                    notice.ServiceProfileStep
                );
              }}>
              Edit Property Settings <ChevronRight />
            </Button>
          ) : null}
        </Alert>
      ))}
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceProfileNotice);
