import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';

import './index.css';
import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';

import * as dataApi from '../../api/dataApi';
import Empty from '../Common/Empty';
import ProviderLogo from '../OfferGrid/ProviderLogo';
import ProviderListMap from '../MovingTrucks/ProviderListMap';
import AddressSearch from '../AddressSearch';
import PlaceGrid from '../PlaceGrid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TaskMenu from '../TaskMenu';

function StorageUnits({ serviceAddress, placeList, service }) {
  const [providerList, setProviderList] = useState(null);
  const [showList, setShowList] = useState(true);
  const [field, setField] = useState('Storage - New');
  const [placeListForMap, setPlaceListForMap] = useState(null);
  const [providerToHighlight, setProviderToHighlight] = useState(null);
  const mouseOutTimeout = useRef();

  useEffect(() => {
    if (service && service.ProviderList && placeList && placeList[field]) {
      setProviderList(
        sortProviders(
          service.ProviderList.map((provider) => {
            const place = placeList[field].filter(
              (_place) =>
                _place.GooglePlaceName.toLowerCase().indexOf(
                  provider.ProviderName.toLowerCase()
                ) !== -1 ||
                _place.GooglePlaceName.toLowerCase().indexOf(
                  provider.DisplayName.toLowerCase()
                ) !== -1
            )[0];

            return {
              ...provider,
              CalculatedDistance: place ? place.CalculatedDistance : null,
              GooglePlaceId: place ? place.GooglePlaceId : null
            };
          }),
          'CalculatedDistance'
        ).filter((provider, providerIndex) => providerIndex < 5)
      );

      setPlaceListForMap(
        placeList[field].map((place, placeIndex) => {
          return {
            GooglePlaceId: place.GooglePlaceId,
            ProviderName: place.GooglePlaceName,
            Latitude: place.Latitude,
            Longitude: place.Longitude
          };
        })
      );
    }
  }, [service, placeList, field]);

  function sortProviders(providerList, column, desc) {
    let sortedList = (providerList ? providerList : []).concat([]);

    sortedList.sort((a, b) => {
      let valueA = a[column];
      let valueB = b[column];

      let result = valueA > valueB ? 1 : valueA < valueB ? -1 : 0;

      if (valueB === null) {
        result = -1;
      }

      if (valueA === null) {
        result = 1;
      }

      if (desc) {
        result = result * -1;
      }

      return result;
    });

    return sortedList;
  }

  return (
    <>
      <h2 className="storage-units-title">
        {providerList && placeList ? (
          <>
            <Tabs
              value={field}
              onChange={(event, newValue) => {
                setField(newValue);
                setShowList(true);
              }}>
              <Tab label="Near Current Home" value={'Storage - Previous'} />
              <Tab label="Near New Home" value={'Storage - New'} />
            </Tabs>
            <Button className="view-all" onClick={() => setShowList(!showList)}>
              View All{' '}
              <KeyboardArrowDownIcon
                style={{ transform: showList ? 'rotate(180deg)' : 'none' }}
              />
            </Button>
          </>
        ) : null}
      </h2>
      {providerList && placeList && placeList[field] ? (
        <div className="storage-units">
          <ul className="provider-list">
            {providerList
              .filter((provider) => provider.CalculatedDistance)
              .map((provider) => (
                <li
                  onMouseOver={(place) => {
                    if (mouseOutTimeout.current) {
                      clearTimeout(mouseOutTimeout.current);
                    }

                    setProviderToHighlight(provider);
                  }}
                  onMouseOut={(place) => {
                    mouseOutTimeout.current = setTimeout(
                      () => setProviderToHighlight(null),
                      500
                    );
                  }}>
                  <Button href={provider.ServiceStartUrl} target="mi_offsite">
                    {provider.LogoFile ? (
                      <img
                        src={provider.LogoFile}
                        className={'provider-logo'}
                      />
                    ) : (
                      provider.DisplayName
                    )}
                    <p>
                      {provider.CalculatedDistance
                        ? provider.CalculatedDistance.toFixed(1) + ' mi'
                        : 'No locations'}
                    </p>
                  </Button>
                </li>
              ))}
          </ul>

          <Button
            className="view-all mobile"
            onClick={() => setShowList(!showList)}>
            View All Storage Units{' '}
            <KeyboardArrowDownIcon
              style={{ transform: showList ? 'rotate(180deg)' : 'none' }}
            />
          </Button>

          {showList ? (
            <div className="storage-units-content">
              {placeListForMap ? (
                <div className="place-map-container">
                  {' '}
                  <ProviderListMap
                    key={
                      field +
                      '_' +
                      serviceAddress.Address.ServiceAddressID +
                      '_' +
                      serviceAddress.Address.PreviousAddressID
                    }
                    address={
                      field === 'Storage - New'
                        ? {
                            address: serviceAddress.Address.Address,
                            secondary: serviceAddress.Address.Secondary,
                            city: serviceAddress.Address.City,
                            state: serviceAddress.Address.State,
                            zip: serviceAddress.Address.Zip,
                            latitude: serviceAddress.Address.Latitude,
                            longitude: serviceAddress.Address.Longitude
                          }
                        : {
                            address: serviceAddress.Address.Previous_Address,
                            secondary:
                              serviceAddress.Address.Previous_Secondary,
                            city: serviceAddress.Address.Previous_City,
                            state: serviceAddress.Address.Previous_State,
                            zip: serviceAddress.Address.Previous_Zip,
                            latitude: serviceAddress.Address.Previous_Latitude,
                            longitude: serviceAddress.Address.Previous_Longitude
                          }
                    }
                    providerList={placeListForMap}
                    providerFieldKey={'GooglePlaceId'}
                    providerFieldLatitude={'Latitude'}
                    providerFieldLongitude={'Longitude'}
                    providerToHighlight={providerToHighlight}
                    mapOffsetX={0.2}
                    zoomLevel={12}
                  />
                </div>
              ) : null}

              <PlaceGrid
                placeList={placeList[field]}
                label="storage units"
                include={{ distance: true, rating: true }}
                onRowMouseOver={(place) => {
                  if (mouseOutTimeout.current) {
                    clearTimeout(mouseOutTimeout.current);
                  }

                  setProviderToHighlight(place);
                }}
                onRowMouseOut={(place) => {
                  mouseOutTimeout.current = setTimeout(
                    () => setProviderToHighlight(null),
                    500
                  );
                }}
              />
            </div>
          ) : null}
        </div>
      ) : field !== 'Storage - Previous' ||
        (serviceAddress &&
          serviceAddress.Address &&
          serviceAddress.Address.Previous_Zip) ? (
        <LinearProgress />
      ) : (
        <div className="no-previous-address">
          <AddressSearch
            hideSearchBy
            isPreviousAddress
            autoFocus
            addressLabel={'Enter the address you are moving from'}
          />
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      markTaskComplete: bindActionCreators(
        serviceAddressActions.markTaskComplete,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StorageUnits);
