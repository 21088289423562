import React, { useEffect, useRef, useState } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export default function ProviderSearch({
  providerList,
  disabled,
  value,
  onChange,
  label,
  freeSolo,
  buttonText,
  buttonClick
}) {
  const providerFilterOptions = createFilterOptions({
    limit: 100
  });

  return (
    <div className="autocomplete-container">
      <Autocomplete
        freeSolo={freeSolo}
        fullWidth
        disabled={disabled}
        value={value}
        disableClearable
        onChange={(event, value) => {
          onChange(event, value);
        }}
        options={providerList}
        filterOptions={providerFilterOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            value={value}
            label={label}
            InputProps={{
              ...params.InputProps,
              type: 'search'
            }}
            onChange={(event) => onChange(event, event.target.value)}
          />
        )}
      />
      {buttonText && buttonClick ? (
        <Button
          variant="contained"
          color="secondary"
          onClick={(event) => buttonClick(event)}
          disabled={!value}>
          {buttonText}
        </Button>
      ) : null}
    </div>
  );
}
