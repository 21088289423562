import React, { useState, useEffect } from 'react';
import { withPrefix } from 'gatsby-link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Checkbox
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputMask from 'react-input-mask';
import FormControl from '@mui/material/FormControl';
import CloseIcon from '@mui/icons-material/Close';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  listUnit,
  listOccupant,
  setResidentOnNotice,
  saveResident
} from '../../api/propertyProfileApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import '../UserPropertySelect/index.css';

function EditResidentPopup({
  profileKey,
  closeFunc,
  afterSave,
  resident,
  label
}) {
  const [saving, setSaving] = useState(null);
  const [unitList, setUnitList] = useState(null);
  const [editResident, setEditResident] = useState(null);
  const [saveResult, setSaveResult] = useState(null);

  useEffect(() => {
    if (profileKey && !unitList) {
      listUnit(profileKey).then((_unitList) => setUnitList(_unitList));
    }
  }, [profileKey]);

  useEffect(() => {
    if (resident) {
      setEditResident({
        ...resident,
        DateApplied: resident.DateApplied
          ? moment(resident.DateApplied).format('MM/DD/YYYY')
          : '',
        DateLeaseRenewal: resident.DateLeaseRenewal
          ? moment(resident.DateLeaseRenewal).format('MM/DD/YYYY')
          : '',
        DateLeaseStart: resident.DateLeaseStart
          ? moment(resident.DateLeaseStart).format('MM/DD/YYYY')
          : ''
      });
    }
  }, [resident]);

  return (
    editResident && (
      <Dialog
        open={true}
        aria-labelledby="form-dialog-title"
        className={'edit-resident-form-popup'}>
        <DialogTitle id="form-dialog-title">
          <IconButton
            style={{ float: 'right' }}
            aria-label="close"
            onClick={() => {
              closeFunc();
            }}>
            <CloseIcon />
          </IconButton>
          <img
            src={withPrefix('/images/logo-icon.svg')}
            className="logo-icon"
          />
          <div>
            {resident.ResidentID ? 'Edit' : 'Add'} {label}
          </div>
        </DialogTitle>
        <DialogContent width="100%">
          {unitList ? (
            <div className="form">
              {saveResult && (
                <Alert severity="error" style={{ marginTop: '20px' }}>
                  {saveResult.AppMessage}
                </Alert>
              )}

              {!editResident.ResidentID && (
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="unit-label">Unit #</InputLabel>
                  <Select
                    labelId="unit-label"
                    label="Unit"
                    id="unit-label"
                    required
                    value={editResident.UnitID || ''}
                    onChange={(event) =>
                      setEditResident({
                        ...editResident,
                        UnitID: Number(event.target.value)
                      })
                    }>
                    {unitList.map((unit) => (
                      <MenuItem key={unit.UnitID} value={unit.UnitID}>
                        {unit.UnitNumber}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {editResident.ResidentID > 0 && (
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="resident-status-label">Status</InputLabel>
                  <Select
                    labelId="resident-status-label"
                    label="Status"
                    id="resident-status-label"
                    required
                    value={editResident.ResidentStatusID || ''}
                    onChange={(event) =>
                      setEditResident({
                        ...editResident,
                        ResidentStatusID: event.target.value
                      })
                    }>
                    <MenuItem value={4}>Applicant</MenuItem>
                    <MenuItem value={1}>Pending</MenuItem>
                    <MenuItem value={2}>Current</MenuItem>
                    <MenuItem value={3}>Former</MenuItem>
                  </Select>
                </FormControl>
              )}

              {/* <FormControl variant="outlined" fullWidth>
              <InputLabel id="resident-relationship-label">
                Occupant Type
              </InputLabel>
              <Select
                labelId="resident-relationship-label"
                label="Occupant Type"
                id="resident-relationship-label"
                required
                value={editResident.ResidentRelationshipID || ''}
                onChange={(event) =>
                  setEditResident({
                    ...editResident,
                    ResidentRelationshipID: event.target.value
                  })
                }>
                <MenuItem value={1}>Head of Household</MenuItem>
                <MenuItem value={2}>Guarantor</MenuItem>
                <MenuItem value={3}>Spouse</MenuItem>
                <MenuItem value={4}>Adult Co-head of Household</MenuItem>
                <MenuItem value={5}>Other Adult Family Member</MenuItem>
                <MenuItem value={6}>Dependent</MenuItem>
                <MenuItem value={7}>Foster Child</MenuItem>
                <MenuItem value={8}>Live in Attendant</MenuItem>
                <MenuItem value={9}>Foster Adult</MenuItem>
                <MenuItem value={10}>Minor Child</MenuItem>
                <MenuItem value={11}>Other</MenuItem>
                <MenuItem value={12}>Roommate</MenuItem>
              </Select>
            </FormControl> */}

              <TextField
                required
                variant="outlined"
                id="first-name"
                name="first-name"
                label="First Name"
                value={editResident.FirstName || ''}
                onChange={(event) =>
                  setEditResident({
                    ...editResident,
                    FirstName: event.target.value
                  })
                }
                fullWidth
              />

              <TextField
                required
                variant="outlined"
                id="last-name"
                name="last-name"
                label="Last Name"
                value={editResident.LastName || ''}
                onChange={(event) =>
                  setEditResident({
                    ...editResident,
                    LastName: event.target.value
                  })
                }
                fullWidth
              />

              <TextField
                required
                variant="outlined"
                id="email"
                name="email"
                label="Email"
                value={editResident.Email || ''}
                onChange={(event) =>
                  setEditResident({
                    ...editResident,
                    Email: event.target.value
                  })
                }
                type="email"
                fullWidth
              />

              <InputMask
                mask="999-999-9999"
                maskChar="_"
                value={editResident.Phone || ''}
                onChange={(event) =>
                  setEditResident({
                    ...editResident,
                    Phone: event.target.value
                  })
                }>
                {() => (
                  <TextField
                    variant="outlined"
                    id="phone"
                    name="phone"
                    label={'Phone Number'}
                    type="tel"
                    fullWidth
                  />
                )}
              </InputMask>

              {editResident.ResidentStatusID === 4 && (
                <InputMask
                  mask="99/99/9999"
                  maskChar={'_'}
                  value={editResident.DateApplied}
                  onChange={(event) =>
                    setEditResident({
                      ...editResident,
                      DateApplied: event.target.value
                    })
                  }>
                  {() => (
                    <TextField
                      label={'Application Date'}
                      fullWidth
                      variant="outlined"
                    />
                  )}
                </InputMask>
              )}

              <InputMask
                mask="99/99/9999"
                maskChar={'_'}
                value={editResident.DateLeaseStart}
                onChange={(event) =>
                  setEditResident({
                    ...editResident,
                    DateLeaseStart: event.target.value
                  })
                }>
                {() => (
                  <TextField
                    required
                    label={'Lease Start Date'}
                    fullWidth
                    variant="outlined"
                  />
                )}
              </InputMask>

              <InputMask
                mask="99/99/9999"
                maskChar={'_'}
                value={editResident.DateLeaseRenewal}
                onChange={(event) =>
                  setEditResident({
                    ...editResident,
                    DateLeaseRenewal: event.target.value
                  })
                }>
                {() => (
                  <TextField
                    label={'Lease Renewal Date'}
                    fullWidth
                    variant="outlined"
                  />
                )}
              </InputMask>
            </div>
          ) : (
            <LinearProgress />
          )}
        </DialogContent>
        <DialogActions style={{ display: 'block', textAlign: 'right' }}>
          <Button
            onClick={() => {
              setSaveResult(null);
              setSaving(true);
              saveResident(
                profileKey,
                editResident,
                editResident.DateApplied
              ).then((result) => {
                if (result.RecordID > 0) {
                  afterSave();
                } else {
                  setSaving(false);
                  setSaveResult(result);
                }
              });
            }}
            variant="contained"
            color="secondary"
            disabled={
              !editResident.UnitID ||
              !editResident.ResidentRelationshipID ||
              !editResident.ResidentStatusID ||
              !editResident.FirstName ||
              !editResident.LastName ||
              !editResident.Email ||
              !editResident.DateLeaseStart
            }>
            {saving ? 'Saving...' : 'Save'}
          </Button>

          <Button
            onClick={() => {
              closeFunc();
            }}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditResidentPopup);
