import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';

import * as dataApi from '../../api/dataApi';

import './index.css';

function BackToTopButton({}) {
  const [visible, setVisible] = useState(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', checkVisible);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', checkVisible);
      }
    };
  }, []);

  function checkVisible() {
    if (typeof window !== 'undefined') {
      const windowScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;

      setVisible(windowScrollTop > windowHeight);
    }
  }

  return (
    <Button
      variant="outlined"
      color="secondary"
      className={'back-to-top' + (visible ? ' visible' : '')}
      onClick={() => dataApi.scrollToTop()}>
      Back to Top
    </Button>
  );
}

export default BackToTopButton;
