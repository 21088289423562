import * as api from './apiUtils';
import React, { useState, useRef, useEffect } from 'react';

export function saveServiceAddress(
  address,
  secondary,
  city,
  state,
  zip,
  serviceAddressSourceID,
  uniqueAddressId,
  previousAddress,
  previousSecondary,
  previousCity,
  previousState,
  previousZip,
  serviceProfileID
) {
  clearAddressSpecificCookies();

  console.log('saveServiceAddress', serviceProfileID);

  return api
    .post(
      'API/Data/SaveServiceAddress?address=' +
        encodeURIComponent(address) +
        '&secondary=' +
        (secondary ? encodeURIComponent(secondary) : '') +
        '&city=' +
        encodeURIComponent(city) +
        '&state=' +
        encodeURIComponent(state) +
        '&zip=' +
        encodeURIComponent(zip) +
        '&serviceAddressSourceID=' +
        serviceAddressSourceID +
        (uniqueAddressId ? '&uniqueAddressId=' + uniqueAddressId : '') +
        (previousAddress ? '&previousAddress=' + previousAddress : '') +
        (previousSecondary ? '&previousSecondary=' + previousSecondary : '') +
        (previousCity ? '&previousCity=' + previousCity : '') +
        (previousState ? '&previousState=' + previousState : '') +
        (previousZip ? '&previousAddress=' + previousZip : '') +
        (serviceProfileID ? '&serviceProfileID=' + serviceProfileID : '')
    )
    .then((response) => {
      if (
        response &&
        response.data &&
        response.data.Address &&
        response.data.Address.ServiceAddressID
      ) {
        setSessionStorage(
          'mi-service-address',
          response.data.Address.ServiceAddressID
        );
      } else {
        setSessionStorage('mi-service-address', '0');
      }

      return response.data;
    });
}

export function listServiceProfile(
  address,
  secondary,
  city,
  state,
  zip,
  serviceAddressSourceID
) {
  return api
    .post(
      'API/Data/ListServiceProfile?address=' +
        encodeURIComponent(address) +
        '&secondary=' +
        (secondary ? encodeURIComponent(secondary) : '') +
        '&city=' +
        encodeURIComponent(city) +
        '&state=' +
        encodeURIComponent(state) +
        '&zip=' +
        encodeURIComponent(zip) +
        '&serviceAddressSourceID=' +
        serviceAddressSourceID
    )
    .then((response) => {
      return response.data;
    });
}

export function saveVisitorUserInfo(serviceAddress) {
  return api
    .post('API/Data/SaveVisitorUserInfo', serviceAddress)
    .then((response) => {
      return response.data;
    });
}

export function toggleChecklistItemComplete(
  serviceAddress,
  serviceSectionID,
  taskStatusID,
  subSection,
  subSectionCount
) {
  return api
    .post(
      'API/Data/ToggleChecklistItemComplete?serviceSectionID=' +
        serviceSectionID +
        '&taskStatusID=' +
        taskStatusID +
        (subSection
          ? '&subSection=' +
            encodeURIComponent(subSection) +
            (subSectionCount ? '&subSectionCount=' + subSectionCount : '')
          : ''),
      serviceAddress
    )
    .then((response) => {
      return response.data;
    });
}

function clearAddressSpecificCookies() {
  setCookie('uhv', JSON.stringify({}), 8760);
}

export function listOfferDetail(offerID) {
  return api
    .get('API/Data/ListOfferDetail?offerID=' + offerID)
    .then((response) => {
      return response.data;
    });
}

export function saveOrder(
  address,
  secondary,
  city,
  state,
  zip,
  uniqueAddressId,
  providerID,
  offerID,
  orderStatusID,
  offsiteOrderUrl,
  providerAccountNumber,
  additionalDetails,
  checkoutStep,
  productTypeID,
  offer,
  createOrderOnly,
  loadLatestIfAvailable,
  formMode
) {
  let orderRequest = {
    Address: address,
    Secondary: secondary,
    City: city,
    State: state,
    Zip: zip,
    UniqueAddressId: uniqueAddressId,
    ProviderID: providerID,
    OfferID: offerID,
    OrderStatusID: orderStatusID,
    OffsiteOrderUrl: offsiteOrderUrl,
    ProviderAccountNumber: providerAccountNumber,
    AdditionalDetails: {},
    CheckoutStep: checkoutStep,
    ProductTypeID: productTypeID,
    Account: {},
    Sensitive: {},
    PassThrough: {},
    Offer: offer,
    FormMode: formMode
  };

  if (createOrderOnly) {
    orderRequest.CreateOrderOnly = true;
  }

  if (loadLatestIfAvailable) {
    orderRequest.LoadLatestIfAvailable = true;
  }

  if (additionalDetails) {
    Object.keys(additionalDetails).map((key) => {
      switch (key) {
        case 'SSN':
        case 'DL':
        case 'DL_State':
        case 'DOB':
        case 'CallInPin':
          orderRequest.Sensitive[key] = additionalDetails[key];
          break;
        case 'CCType':
        case 'CCNumber':
        case 'CCExpirationMonth':
        case 'CCExpirationYear':
        case 'CCV':
        case 'CCName':
        case 'ConfirmAppointmentNow':
          orderRequest.PassThrough[key] = additionalDetails[key];
          break;
        default:
          orderRequest.Account[key] = additionalDetails[key];
          break;
      }
    });
  }

  return api.post('API/Data/SaveOrder', orderRequest).then((response) => {
    return response.data;
  });
}

export function getCheckoutSetup(
  address,
  secondary,
  city,
  state,
  zip,
  uniqueAddressId,
  providerID,
  offerID,
  productTypeID,
  offer
) {
  let orderRequest = {
    Address: address,
    Secondary: secondary,
    City: city,
    State: state,
    Zip: zip,
    ProviderID: providerID,
    UniqueAddressId: uniqueAddressId,
    OfferID: offerID,
    ProductTypeID: productTypeID,
    Offer: offer
  };

  return api
    .post('API/Data/GetCheckoutSetup', orderRequest)
    .then((response) => {
      return response.data;
    });
}

export function listDegregulatedAddress(serviceAddressID) {
  return api
    .get(
      'API/Data/ListDegregulatedAddress?serviceAddressID=' + serviceAddressID
    )
    .then((response) => {
      return response.data;
    });
}

export function saveDegregulatedAddress(serviceAddress, deregulatedAddressID) {
  return api
    .post(
      'API/Data/SaveDegregulatedAddress?deregulatedAddressID=' +
        deregulatedAddressID,
      serviceAddress
    )
    .then((response) => {
      return response.data;
    });
}

export function saveUsageRequest(usageRequest) {
  return api
    .post('API/Data/SaveUsageRequest', usageRequest)
    .then((response) => {
      return response.data;
    });
}

export function getUsageHistory(serviceAddress, emailAlreadyReceived) {
  return api
    .post(
      'API/Data/GetUsageHistory?emailAlreadyReceived=' +
        (emailAlreadyReceived ? 'true' : 'false'),
      serviceAddress
    )
    .then((response) => {
      return response.data;
    });
}

export function listAddressSecondary(address, zip) {
  return api
    .get(
      'API/Data/ListAddressSecondary?address=' +
        encodeURIComponent(address) +
        '&zip=' +
        zip
    )
    .then((response) => {
      return response.data;
    });
}

export function getCookie(name) {
  if (typeof window !== 'undefined') {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v && v[2] && v[2] !== 'null' ? v[2] : null;
  }

  return null;
}

export function setCookie(name, value, hours) {
  if (typeof window !== 'undefined') {
    var d = new Date();
    if (hours) {
      var time = d.getTime();
      time += 3600 * hours * 1000;
      d.setTime(time);
    } else {
      d.setFullYear(d.getFullYear() + 1);
    }
    document.cookie = name + '=' + value + ';path=/;expires=' + d.toUTCString();
  }
}

export function getSessionStorage(name) {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    return window.sessionStorage.getItem(name);
  }

  return '';
}

export function setSessionStorage(name, value) {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    window.sessionStorage.setItem(name, value);
  }
}

export function getMonthsOfYear() {
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
}

export function getCurrentRoute() {
  if (typeof window !== 'undefined') {
    const path = location.pathname;
    let pathParts = path
      .split(process.env.GATSBY_PATH_PREFIX)
      .join('')
      .split('/');

    if (pathParts[pathParts.length - 1] === '') {
      pathParts.pop();
    }

    let route = pathParts.join('/');
    if (!route) {
      route = '/';
    }

    return route;
  }
  return '';
}

export function isExcludeRoute(route) {
  if (route.indexOf('/property-') === 0) {
    return true;
  } else if (route.indexOf('/company-') === 0) {
    return true;
  } else if (route.indexOf('/partner-') === 0) {
    return true;
  }

  switch (route) {
    case '/':
    case '/privacy-policy':
    case '/terms':
    case '/ccpa':
    case '/ccpa-request':
    case '/texas-disclosure':
    case '/demo1':
    case '/demo2':
    case '/plan-preview':
    case '/set-address':
    case '/platform-overview':
    case '/resident-email-process':
    case '/mobile-login':
    case '/contact':
    case '/copyright-form':
    case '/resident-dashboard-overview':
    case '/service-profile':
      return true;
    default:
      return false;
  }
}

export function listElectricOfferAverageComparison(offerID) {
  return api
    .get('API/Data/ListElectricOfferAverageComparison?offerID=' + offerID)
    .then((response) => {
      return response.data;
    });
}

export function requestInvitation(request) {
  return api.post('API/Data/RequestInvitation', request).then((response) => {
    return response.data;
  });
}

export function saveInquiry(subject, request) {
  return api
    .post(
      'API/Data/SaveInquiry?subject=' + encodeURIComponent(subject),
      request
    )
    .then((response) => {
      return response.data;
    });
}

export function listPlaces(serviceAddress) {
  return api.post('API/Data/ListPlaces', serviceAddress).then((response) => {
    return response.data;
  });
}

export function listAllSchool(serviceAddress) {
  return api.post('API/Data/ListAllSchool', serviceAddress).then((response) => {
    return response.data;
  });
}

export function sortPlaces(_placeList, column, desc) {
  let sortedList = (_placeList ? _placeList : []).concat([]);

  sortedList.sort((a, b) => {
    let valueA = a[column];
    let valueB = b[column];

    let result = valueA > valueB ? 1 : valueA < valueB ? -1 : 0;

    if (valueB === null) {
      result = -1;
    }

    if (valueA === null) {
      result = 1;
    }

    if (desc) {
      result = result * -1;
    }

    return result;
  });

  return sortedList;
}

export function scrollToElementInContainer(container, elm, duration) {
  var pos = getRelativePos(elm);
  scrollTo(container, pos.top, 2); // duration in seconds
}

function getRelativePos(elm) {
  var pPos = elm.parentNode.getBoundingClientRect(), // parent pos
    cPos = elm.getBoundingClientRect(), // target pos
    pos = {};

  (pos.top = cPos.top - pPos.top + elm.parentNode.scrollTop),
    (pos.right = cPos.right - pPos.right),
    (pos.bottom = cPos.bottom - pPos.bottom),
    (pos.left = cPos.left - pPos.left);

  return pos;
}

function scrollTo(element, to, duration, onDone) {
  var start = element.scrollTop,
    change = to - start,
    startTime = performance.now(),
    val,
    now,
    elapsed,
    t;

  function animateScroll() {
    now = performance.now();
    elapsed = (now - startTime) / 1000;
    t = elapsed / duration;

    element.scrollTop = start + change * easeInOutQuad(t);

    if (t < 1) window.requestAnimationFrame(animateScroll);
    else onDone && onDone();
  }

  animateScroll();
}

function easeInOutQuad(t) {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

export function scrollToElementInPage(element, offset, instant) {
  if (element) {
    let scrollArgs = {
      top: element.getBoundingClientRect().top + window.pageYOffset + offset
    };

    if (!instant) {
      scrollArgs.behavior = 'smooth';
    }

    window.scrollTo(scrollArgs);
  }
}

export function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

export function createDemoServiceProfile(address, secondary, city, state, zip) {
  clearAddressSpecificCookies();

  return api
    .post('API/Data/CreateDemoServiceProfile', {
      Address: address,
      Secondary: secondary,
      City: city,
      State: state,
      Zip: zip
    })
    .then((response) => {
      return response.data;
    });
}

export function listOffer(serviceAddressID) {
  return api
    .get('API/Data/ListOffer?serviceAddressID=' + serviceAddressID)
    .then((response) => {
      return response.data;
    });
}

export function getOffer(offerID) {
  return api.get('API/Data/GetOffer?offerID=' + offerID).then((response) => {
    return response.data;
  });
}

export function setServiceAddressTitle(serviceAddress) {
  if (
    serviceAddress &&
    serviceAddress.Address &&
    typeof window !== 'undefined' &&
    getCurrentRoute().indexOf('/property') === -1
  ) {
    if (serviceAddress.mock) {
      document.title = 'Welcome to MovingIN';
    } else {
      document.title =
        (serviceAddress.Address.Address
          ? serviceAddress.Address.Address +
            (serviceAddress.Address.Secondary
              ? ' ' + serviceAddress.Address.Secondary
              : '') +
            ' | '
          : '') +
        serviceAddress.Address.City +
        ', ' +
        serviceAddress.Address.State +
        ' ' +
        serviceAddress.Address.Zip;
    }
  }
}

export function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);
  const observer = useRef();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      observer.current = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting);
      });

      observer.current.observe(ref.current);
    }
    // Remove the observer as soon as the component is unmounted
    return () => {
      if (typeof window !== 'undefined') {
        observer.current.disconnect();
      }
    };
  }, []);

  return isIntersecting;
}

export function compareElectric6Month(usage) {
  return api
    .get('API/Data/CompareElectric6Month?usage=' + usage)
    .then((response) => {
      return response.data;
    });
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatPhone(value) {
  if (value) {
    const numbersOnly = String(value.replace(/\D/g, ''));

    if (numbersOnly.length === 10) {
      return (
        numbersOnly.substring(0, 3) +
        '-' +
        numbersOnly.substring(3, 6) +
        '-' +
        numbersOnly.substring(6, 10)
      );
    }

    return value;
  }

  return '';
}

export function requestOrderVerificationCode(phone, channel) {
  return api
    .post(
      'API/Data/RequestOrderVerificationCode?phone=' +
        encodeURIComponent(phone) +
        '&channel=' +
        encodeURIComponent(channel)
    )
    .then((response) => {
      return response.data;
    });
}

export function confirmOrderVerificationCode(phone, code) {
  return api
    .post(
      'API/Data/ConfirmOrderVerificationCode?phone=' +
        encodeURIComponent(phone) +
        '&code=' +
        encodeURIComponent(code)
    )
    .then((response) => {
      return response.data;
    });
}

export function getOrderByPaymentReference(paymentReferenceId) {
  return api
    .get(
      'API/Data/GetOrderByPaymentReference?paymentReferenceId=' +
        encodeURIComponent(paymentReferenceId)
    )
    .then((response) => {
      return response.data;
    });
}
