import React, { useEffect, useRef, useState } from 'react';

import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';

export default function ProviderOption({
  providerService,
  setProviderServiceValue,
  setProviderValue,
  provider,
  disabled,
  noRadio
}) {
  return (
    <div
      className={
        'radio-option property' +
        (noRadio ? ' no-radio' : '') +
        (noRadio && provider.ProviderID === providerService.preferredProviderID
          ? ' selected'
          : '')
      }
      key={provider.ProviderID}>
      <FormControlLabel
        value={provider.ProviderID}
        className="radio"
        control={
          <Radio
            checked={
              provider.IsProviderAvailable !== false &&
              provider.ProviderID === providerService.preferredProviderID
            }
            disabled={disabled || provider.IsProviderAvailable === false}
          />
        }
        label={
          provider.ProviderID === null
            ? 'No Preferred ' +
              providerService.ProviderServiceName +
              ' Provider'
            : ''
        }
      />

      {provider.ProviderID !== 0 ? (
        <p
          onClick={(event) => {
            if (noRadio) {
              setProviderServiceValue(providerService, {
                preferredProviderID: provider.ProviderID
              });
            }
          }}
          className={
            (provider.ProviderID ? 'with-provider' : '') +
            (disabled || provider.IsProviderAvailable === false
              ? ' disabled'
              : '')
          }>
          {provider.LogoFile ? (
            <img src={provider.LogoFile} className="provider-logo" />
          ) : (
            provider.ProviderName
          )}
        </p>
      ) : null}

      {provider.ProviderID !== null ? (
        provider.ProviderID === 0 ? (
          <>
            <TextField
              value={providerService.ProviderNameEntered}
              onChange={(event) => {
                setProviderServiceValue(providerService, {
                  ProviderNameEntered: event.target.value
                });
              }}
              disabled={providerService.preferredProviderID != 0 || disabled}
              className="text"
              label={
                'Other ' +
                providerService.ProviderServiceName.split(' (Preferred)').join(
                  ''
                ) +
                ' Provider'
              }
              placeholder={'Enter provider name here'}
              fullWidth
            />

            <TextField
              value={providerService.ProviderUrlEntered}
              onChange={(event) => {
                setProviderServiceValue(providerService, {
                  ProviderUrlEntered: event.target.value
                });
              }}
              disabled={providerService.preferredProviderID != 0 || disabled}
              className="text"
              label={'Provider Website Link'}
              placeholder={'https://'}
              fullWidth
              style={{ marginLeft: '38px' }}
            />
          </>
        ) : null
      ) : // <FormControlLabel
      //   control={
      //     <Checkbox
      //       checked={provider.IsProviderAvailable !== false}
      //       disabled={disabled}
      //       onChange={(event) => {
      //         setProviderValue(provider, {
      //           IsProviderAvailable: event.target.checked
      //         });

      //         if (
      //           provider.ProviderID ===
      //             providerService.preferredProviderID &&
      //           !event.target.checked
      //         ) {
      //           setProviderServiceValue(providerService, {
      //             preferredProviderID: null
      //           });
      //         }
      //       }}
      //     />
      //   }
      //   className="switch"
      //   label={'Available at Property'}
      // />
      null}
    </div>
  );
}
