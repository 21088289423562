import React, { useState, useRef, useEffect, version } from 'react';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import { withPrefix } from 'gatsby-link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Alert,
  LinearProgress,
  TextField,
  Tooltip,
  Checkbox,
  IconButton,
  Switch,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import InputMask from 'react-input-mask';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';

import {
  getCookie,
  getSessionStorage,
  scrollToElementInPage
} from '../../api/dataApi';
import {
  getResidentSubmittal,
  saveResidentSubmittal,
  saveResidentSubmittalStatus
} from '../../api/residentOnboardingApi';

import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import ResidentOnboardingStatusUpdate from '../ResidentOnboarding/ResidentOnboardingStatusUpdate';
import ResidentDocumentViewer from '../ResidentOnboarding/ResidentDocumentViewer';
import ResidentFormPopup from './ResidentFormPopup';

import './index.css';

export function getReportPath(
  profileKey,
  residentID,
  occupantID,
  residentFormID,
  signer,
  download
) {
  let invitation = getCookie('mi-invitation');

  if (!profileKey && !invitation) {
    profileKey = getCookie('mi-profile-key');
    if (profileKey) {
      profileKey = profileKey.split(':')[0];
    }
  }

  if (!residentID && !invitation) {
    try {
      const resident = JSON.parse(getSessionStorage('mi-selected-resident'));
      if (resident && resident.ResidentID) {
        residentID = resident.ResidentID;
      }
    } catch (e) {}
  }

  let _reportName = 'residentSubmittal';

  if (residentFormID === 3 && !signer) {
    _reportName = 'residentIDCompare';
  }

  return (
    process.env.GATSBY_EXPORT_URL +
    'docs/' +
    _reportName +
    '?property=' +
    (profileKey ? profileKey : '') +
    '&resident=' +
    (residentID ? residentID : '') +
    '&residentFormID=' +
    residentFormID +
    '&pdfViewer=' +
    (download ? 'false' : 'true') +
    '&signer=' +
    signer +
    (occupantID ? '&occupantID=' + occupantID : '') +
    '&invitation=' +
    (invitation && !profileKey ? invitation : '')
  );
}

function ResidentFormReport({
  actions,
  serviceAddress,
  siteConfig,
  residentOnboarding,
  refreshResidentOnboarding,
  residentID,
  residentForm,
  closeOnboardingRow,
  stateList,
  profileKey,
  webSocket,
  role
}) {
  const ref = useRef();
  const [residentStatusUpdate, setResidentStatusUpdate] = useState(null);
  const [residentSubmittal, setResidentSubmittal] = useState(null);
  const [showResidentForm, setShowResidentForm] = useState(null);
  const [selectedForm, setSelectedForm] = useState('second');

  useEffect(() => {
    if (!residentOnboarding) {
      actions.loadResidentOnboarding(residentID);
    }

    if (residentForm && residentOnboarding) {
      loadFormData();
    }
  }, [residentForm, residentOnboarding]);

  function loadFormData() {
    getResidentSubmittal(
      residentID,
      residentForm.ResidentFormID,
      residentForm.OccupantID
    ).then((_residentSubmittal) => {
      setResidentSubmittal(_residentSubmittal);
      setTimeout(() => {
        if (ref && ref.current) {
          scrollToElementInPage(ref.current, -400);
        }
      }, 100);
    });
  }

  return residentSubmittal && residentSubmittal.ResidentSubmittal ? (
    <div className="resident-form-report" ref={ref}>
      {residentID &&
      residentSubmittal &&
      (residentSubmittal.ResidentSubmittal.ResidentOnboardingStatusID === 2 ||
        (residentSubmittal.ResidentSubmittal.ResidentOnboardingStatusID === 1 &&
          residentSubmittal.ResidentSubmittal
            .ResidentOnboardingStatusNameOverride)) ? (
        <div className="save-button">
          {residentID &&
          residentSubmittal &&
          residentSubmittal.ResidentSubmittal.ResidentOnboardingStatusID ===
            2 ? (
            <Button
              variant="contained"
              className="accept"
              color="primary"
              onClick={() => {
                setResidentStatusUpdate({
                  dataTypeLabel:
                    residentSubmittal.ResidentSubmittal.ResidentFormName,
                  residentOnboardingStatusID: 3
                });
              }}>
              <DoneIcon /> Accept
            </Button>
          ) : null}

          {residentID &&
          residentSubmittal &&
          residentSubmittal.ResidentSubmittal.ResidentOnboardingStatusID ===
            1 &&
          residentSubmittal.ResidentSubmittal
            .ResidentOnboardingStatusNameOverride ? (
            <Button
              variant="outlined"
              color="primary"
              className="manual-confirmation"
              onClick={() => {
                setShowResidentForm(true);
              }}>
              Manual Confirmation
            </Button>
          ) : null}
        </div>
      ) : null}

      <ul className="report-list">
        {residentSubmittal.ResidentSubmittal.ResidentFormTypeID === 1 ? (
          <>
            <li
              className={
                'report' + (selectedForm === 'first' ? ' selected' : '')
              }>
              <h3>
                {residentForm.ResidentFormID === 3
                  ? 'Applicant Upload'
                  : 'Applicant Authorization'}
              </h3>
              <div
                className={
                  'badge resident-onboarding-badge status-' +
                  (residentForm.ResidentOnboardingStatusID === 1 &&
                  !residentForm.ResidentOnboardingStatusNameOverride
                    ? 1
                    : 3)
                }>
                {residentForm.ResidentOnboardingStatusID === 1 &&
                !residentForm.ResidentOnboardingStatusNameOverride
                  ? 'Waiting on Applicant'
                  : 'Completed'}
              </div>

              {residentForm.ResidentOnboardingStatusID > 1 ||
              residentForm.ResidentOnboardingStatusNameOverride ? (
                <ResidentDocumentViewer
                  residentID={residentID}
                  isReport
                  fileList={[
                    getReportPath(
                      profileKey,
                      residentID,
                      residentForm.OccupantID,
                      residentForm.ResidentFormID,
                      'Applicant'
                    )
                  ]}
                />
              ) : (
                <div className="report-container"></div>
              )}

              <Button
                className="selector"
                onClick={() => setSelectedForm('first')}></Button>
            </li>

            <li
              className={
                'report' + (selectedForm === 'second' ? ' selected' : '')
              }>
              <h3>
                {residentForm.ResidentFormID === 3
                  ? 'Comparative Analysis'
                  : residentSubmittal.ResidentSubmittal.OtherSigner +
                    ' Verification'}
              </h3>

              <div
                className={
                  'badge resident-onboarding-badge status-' +
                  (residentForm.IsNotApplicable
                    ? '5'
                    : residentSubmittal.ResidentSubmittal
                        .ResidentOnboardingStatusID)
                }>
                {residentForm.IsNotApplicable
                  ? 'Not Applicable'
                  : residentSubmittal.ResidentSubmittal
                      .ResidentOnboardingStatusNameOverride
                  ? residentSubmittal.ResidentSubmittal
                      .ResidentOnboardingStatusNameOverride
                  : residentSubmittal.ResidentSubmittal
                      .ResidentOnboardingStatusName}
              </div>

              {residentForm.ResidentOnboardingStatusID > 1 &&
              !residentForm.IsNotApplicable ? (
                <ResidentDocumentViewer
                  residentID={residentID}
                  isReport
                  fileList={[
                    getReportPath(
                      profileKey,
                      residentID,
                      residentForm.OccupantID,
                      residentForm.ResidentFormID,
                      residentSubmittal.ResidentSubmittal.OtherSigner
                    )
                  ]}
                />
              ) : (
                <div className="report-container"></div>
              )}

              <Button
                className="selector"
                onClick={() => setSelectedForm('second')}></Button>
            </li>
          </>
        ) : (
          <li className="report selected">
            <h3>Resident Submittal</h3>
            <div
              className={
                'badge resident-onboarding-badge status-' +
                (residentForm.ResidentOnboardingStatusID === 1 ? 1 : 3)
              }>
              {residentForm.ResidentOnboardingStatusID === 1
                ? residentForm.ResidentOnboardingStatusNameOverride
                  ? residentForm.ResidentOnboardingStatusNameOverride
                  : 'Waiting on Resident'
                : 'Completed'}
            </div>

            {residentForm.ResidentOnboardingStatusID > 1 ||
            residentForm.ResidentOnboardingStatusNameOverride ? (
              <ResidentDocumentViewer
                residentID={residentID}
                isReport
                fileList={[
                  getReportPath(
                    profileKey,
                    residentID,
                    residentForm.OccupantID,
                    residentForm.ResidentFormID,
                    'All'
                  )
                ]}
              />
            ) : (
              <div className="report-container"></div>
            )}
          </li>
        )}
      </ul>

      {residentStatusUpdate ? (
        <ResidentOnboardingStatusUpdate
          type="resident-form"
          dataTypeLabel={residentSubmittal.ResidentSubmittal.ResidentFormName}
          residentOnboardingStatusID={
            residentStatusUpdate.residentOnboardingStatusID
          }
          exists={true}
          profileKey={profileKey}
          resident={residentOnboarding.Header}
          saveFunc={(note) => {
            saveResidentSubmittalStatus(
              residentID,
              residentSubmittal.OccupantID,
              residentSubmittal.ResidentSubmittal.ResidentFormID,
              residentStatusUpdate.residentOnboardingStatusID
            ).then((result) => {
              actions.loadResidentOnboarding(residentID);
              setResidentStatusUpdate(null);
              if (closeOnboardingRow) {
                closeOnboardingRow();
              }
            });
          }}
          closeFunc={() => setResidentStatusUpdate(null)}
        />
      ) : null}

      {showResidentForm ? (
        <ResidentFormPopup
          closeFunc={() => {
            setShowResidentForm(null);
            loadFormData();
          }}
          item={residentForm}
          propertyRole={residentForm.OtherSigner}
          residentID={residentID}
        />
      ) : null}
    </div>
  ) : (
    <LinearProgress style={{ marginTop: '10px', marginBottom: '10px' }} />
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    residentOnboarding: state.residentOnboarding,
    stateList: state.commonData.stateList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResidentFormReport);
