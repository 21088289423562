import React, { useState, useEffect } from 'react';
import { withPrefix } from 'gatsby-link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Checkbox
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import {
  listResidentMessage,
  sendResidentMessage
} from '../../api/propertyProfileApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import { loadResidentOnboarding } from '../../redux/actions/residentOnboardingActions';
import moment from 'moment';

const defaultActionList = [
  {
    key: 'resident-required-reminder',
    description: 'Property Required Items Reminder',
    title: 'Pre-arrival: Required Items Still Missing',
    includeRequiredItems: true,
    subject: 'IMPORTANT: Required Items Still Missing',
    directions:
      "Select the items below you would like to remind this resident about. Add a personalized message by selecting the 'Custom Message' section at the bottom.",
    introEmail:
      'Your move to {propertyName} is scheduled for {dateLeaseStart}, but we still need the following items before we can release your keys:',
    introText:
      'Your move to {propertyName} is scheduled for {dateLeaseStart}, but we still need the following items before we can release your keys:\n\n',
    closingEmail:
      " Please click the 'Go to My MovingIN Dashboard' button below to upload the required information before your arrival.",
    closingText:
      'Please go to {residentUniqueLink} to upload the required information before your arrival.',
    includeButton: true
  },
  {
    key: 'existing-insurance',
    description: 'Update Proof of Insurance',
    title: 'Post Move-in: Update Proof of Insurance',
    subject: 'IMPORTANT: Please Update Proof of Insurance',
    directions:
      "Send the message shown to the right requesting updated Proof of Insurance information from this resident by clicking the green 'Send Message' button below. You may also add a custom message by checking the box below.",
    postMove: true,
    introEmail:
      'You are receiving this email because you are a resident at {propertyName}. The Proof of Insurance information the property has on file is out of date.',
    introText:
      'You are receiving this text message because you are a resident at {propertyName}. The Proof of Insurance information the property has on file is out of date.',
    closingEmail:
      ' Please visit the link below and upload the latest information as soon as possible.',
    closingText:
      ' Please go to {residentUniqueLink} to upload the latest information as soon as possible.',
    includeButton: true
  },
  {
    key: 'existing-pet',
    description: 'New Pet Registration Link',

    title: 'Post Move-in: New Pet Registration Link',
    subject: 'IMPORTANT: Please Register Your New Pet',
    directions:
      "Send this resident a link to allow them to register their new pet. Add a personalized message by selecting the 'Custom Message' section at the bottom.",
    postMove: true,
    introEmail:
      'You are receiving this email because you are a resident at {propertyName}. Your property manager is requesting that you update your pet registration information online.',
    introText:
      'You are receiving this text message because you are a resident at {propertyName}. Your property manager is requesting that you update your pet registration information online.',
    closingEmail:
      ' Please visit the link below and upload the latest information as soon as possible.',
    closingText:
      ' Please go to {residentUniqueLink} to upload the latest information as soon as possible.',
    includeButton: true
  },
  {
    key: 'existing-vehicle',
    description: 'New Vehicle Registration Link',
    title: 'Post Move-in: New Vehicle Registration Link',
    subject: 'IMPORTANT: Please Register Your New Vehicle',
    directions:
      "Send this resident a link to allow them to register their new vehicle. Add a personalized message by selecting the 'Custom Message' section at the bottom.",
    postMove: true,
    introEmail:
      'You are receiving this email because you are a resident at {propertyName}. Your property manager is requesting that you update your vehicle registration information online.',
    introText:
      'You are receiving this text message because you are a resident at {propertyName}. Your property manager is requesting that you update your vehicle registration information online.',
    closingEmail:
      ' Please visit the link below and upload the latest information as soon as possible.',
    closingText:
      ' Please go to {residentUniqueLink} to upload the latest information as soon as possible.',
    includeButton: true
  },
  {
    key: 'direct',
    description: 'Direct Message to Resident',
    title: 'Direct Message to Resident',
    subject: 'IMPORTANT: New Message from Property',
    directions:
      "If you'd like to send a direct message to this resident, enter your custom message below.",
    subjectEditable: true,
    requireMessage: true
  }
];

function SendResidentMessage({
  resident,
  residentOnboarding,
  closeFunc,
  actions,
  profileKey,
  defaultRequiredItem,
  afterSend,
  isRejection,
  title,
  fixedMessageType,
  fixedMessageDescription,
  fixedMessageParts
}) {
  const actionList = defaultActionList.concat(
    fixedMessageType
      ? [
          {
            key: fixedMessageType,
            description: fixedMessageDescription,
            subject: title,
            directions:
              'Please select one or more of the reasons below to explain why this submittal is being rejected. We will notify the resident and ask that they correct the issue and resubmit.',
            introEmail:
              'Your ' +
              isRejection +
              ' submittal was rejected for the following reason(s):<br /><br />',
            introText:
              'Your ' +
              isRejection +
              ' submittal was rejected for the following reason(s):\n\n',
            closingEmail:
              "Please click the 'Go to My MovingIN Dashboard' button below to resubmit the required information.",
            closingText:
              'Please go to {residentUniqueLink} to resubmit the required information.',
            includeButton: true
          }
        ]
      : []
  );

  const [selectedAction, setSelectedAction] = useState(null);
  const [allowActionSelection, setAllowActionSection] = useState(true);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [subject, setSubject] = useState(null);
  const [message, setMessage] = useState('');
  const [pendingMessageList, setPendingMessageList] = useState(null);
  const [messageParts, setMessageParts] = useState(null);
  const [previewMode, setPreviewMode] = useState(null);
  const [outgoing, setOutgoing] = useState({ Email: '', Text: '' });
  const [sending, setSending] = useState(null);

  useEffect(() => {
    actions.loadResidentOnboarding(resident.ResidentID);

    listResidentMessage(profileKey, resident.ResidentID).then((_messageList) =>
      setPendingMessageList(
        _messageList.filter(
          (message) =>
            !message.Email_DateSent &&
            !message.Text_DateSent &&
            moment(message.MessageCreated).isAfter(new Date())
        )
      )
    );

    setSelectedMethod(
      resident.Email && resident.Phone
        ? 'Email,Text'
        : resident.Email
        ? 'Email'
        : 'Text'
    );

    if (defaultRequiredItem) {
      const actionIndex = actionList.findIndex(
        (action) => action.key === 'resident-required-reminder'
      );
      if (actionIndex !== -1) {
        setSelectedAction(String(actionIndex));
        setAllowActionSection(false);
      }
    } else if (fixedMessageType) {
      const actionIndex = actionList.findIndex(
        (action) => action.key === fixedMessageType
      );
      if (actionIndex !== -1) {
        setSelectedAction(String(actionIndex));
        setAllowActionSection(false);
      }
    }
  }, []);

  useEffect(() => {
    setPreviewMode(
      selectedMethod && selectedMethod.indexOf('Email') !== -1
        ? 'Email'
        : 'Text'
    );
  }, [selectedMethod]);

  useEffect(() => {
    let _messageParts = {};

    if (fixedMessageParts) {
      fixedMessageParts.map(
        (part) => (_messageParts[part] = fixedMessageParts.length === 1)
      );
    } else if (
      selectedAction &&
      actionList[selectedAction].includeRequiredItems
    ) {
      residentOnboarding.Checklist.filter((item) => item.RequiredItemText).map(
        (item) => {
          _messageParts[item.RequiredItemText] = defaultRequiredItem
            ? defaultRequiredItem === item.RequiredItemText
            : true;
        }
      );
    }

    setSubject(
      selectedAction && actionList[selectedAction].subject
        ? actionList[selectedAction].subject
        : ''
    );

    setMessageParts(_messageParts);

    setMessage('');
  }, [selectedAction, fixedMessageParts]);

  useEffect(() => {
    const _outgoing = { Email: '', Text: '' };

    if (selectedAction) {
      _outgoing.Email = 'Hello ' + resident.FirstName + ',<br /><br />';
      _outgoing.Text = 'MOVINGIN UPDATE: ';

      if (actionList[selectedAction].introEmail) {
        _outgoing.Email += actionList[selectedAction].introEmail;
      }

      if (actionList[selectedAction].introText) {
        _outgoing.Text += actionList[selectedAction].introText;
      }

      let _hasParts = false;
      if (
        Object.keys(messageParts).filter(
          (key) => messageParts[key] && key !== 'Custom'
        ).length
      ) {
        let _partsText = '';
        let _partsEmail = '';

        Object.keys(messageParts).map((key) => {
          if (messageParts[key]) {
            if (key !== 'Custom') {
              _partsEmail += '<li>' + key + '</li>';

              _partsText += ' - ' + key + '\n';
            }
          }
        });

        if (_partsText) {
          _outgoing.Text += _partsText + '\n';
        }

        if (_partsEmail) {
          _outgoing.Email += '<br /><br /><ul>' + _partsEmail + '</ul>';
          _hasParts = true;
        }
      }

      if (
        (messageParts.Custom || actionList[selectedAction].requireMessage) &&
        message
      ) {
        _outgoing.Email +=
          (_hasParts ? '' : '<br /><br />') +
          message.split('\n').join('<br />') +
          '<br /><br />';

        _outgoing.Text += message + '\n\n';
      }

      if (actionList[selectedAction].closingEmail) {
        _outgoing.Email += actionList[selectedAction].closingEmail;
      }

      if (actionList[selectedAction].closingText) {
        _outgoing.Text += actionList[selectedAction].closingText;
      }

      if (actionList[selectedAction].includeButton) {
        _outgoing.Email += `<div align="center" style="padding-top: 20px"><br /><!--[if mso]>
        <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="{invitationLink}" style="height:40px;v-text-anchor:middle;width:300px;" arcsize="13%" strokecolor="#cccccc" fillcolor="#337eff">
          <w:anchorlock/>
          <center style="color:#ffffff;font-family:sans-serif;font-size:16px;font-weight:normal;">Go To My MovingIN Dashboard</center>
        </v:roundrect>
      <![endif]--><a href="{invitationLink}"
      style="background-color:#337eff;border:1px solid #cccccc;border-radius:5px;color:#ffffff;display:inline-block;font-family:sans-serif;font-size:16px;font-weight:normal;line-height:40px;text-align:center;text-decoration:none;width:300px;-webkit-text-size-adjust:none;mso-hide:all;">Go To My MovingIN Dashboard</a></div>`;
      }

      _outgoing.Email = interpolate(_outgoing.Email);
      _outgoing.Text = interpolate(_outgoing.Text);

      setOutgoing(_outgoing);
    }

    setOutgoing(_outgoing);
  }, [selectedAction, selectedMethod, messageParts, message]);

  function interpolate(text) {
    return text
      .split('{propertyName}')
      .join(resident.PropertyName)
      .split('{dateLeaseStart}')
      .join(moment(resident.DateLeaseStart).format('M/D/YYYY'));
  }

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className={'resident-send-message-popup' + (sending ? ' sending' : '')}
      fullWidth={true}>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        <img src={withPrefix('/images/logo-icon.svg')} className="logo-icon" />
        <div>
          {title ? (
            title
          ) : (
            <>
              {sending ? 'Sending' : 'Send'} Message to {resident.FirstName}{' '}
              {resident.LastName}
            </>
          )}
        </div>
      </DialogTitle>
      <DialogContent width="100%">
        {residentOnboarding &&
        residentOnboarding.Header &&
        residentOnboarding.Header.ResidentID === resident.ResidentID ? (
          <>
            {pendingMessageList && pendingMessageList.length ? (
              <Alert severity="warning">
                Note: There are pending automated messages scheduled to be sent
                to this resident.
                <TableContainer>
                  <Table aria-label="simple table" stickyHeader={true}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Message</TableCell>
                        <TableCell>Method</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pendingMessageList.map((message) => (
                        <TableRow>
                          <TableCell>
                            {moment(message.MessageCreated).format('M/D/YYYY')}
                          </TableCell>
                          <TableCell>
                            {message.MessageTypeDescription}
                          </TableCell>
                          <TableCell>
                            {message.Email ? 'Email' : ''}
                            {message.Phone
                              ? (message.Email ? ', ' : '') + 'Text'
                              : ''}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Alert>
            ) : null}

            <div className="panel message-options">
              <h3>Message Options</h3>

              {allowActionSelection ? (
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel id="action-list-label">
                    Notification Type
                  </InputLabel>
                  <Select
                    labelId="action-list-label"
                    label="Notification Type"
                    id="action-list"
                    value={selectedAction}
                    onChange={(event) => setSelectedAction(event.target.value)}>
                    {Object.keys(actionList)
                      .filter(
                        (key) =>
                          (!actionList[key].includeRequiredItems ||
                            residentOnboarding.Checklist.filter(
                              (item) =>
                                item.ResidentOnboardingStatusID <= 1 &&
                                item.Grouping !== 'walkthrough'
                            ).length) &&
                          (!actionList[key].postMove ||
                            moment(resident.DateLeaseStart).isSameOrBefore(
                              new Date()
                            ))
                      )
                      .map((key) => (
                        <MenuItem value={key}>{actionList[key].title}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              ) : null}

              {selectedAction ? (
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel id="method-list-label">
                    Notification Method
                  </InputLabel>
                  <Select
                    labelId="method-list-label"
                    label="Notification Method"
                    id="method-list"
                    value={selectedMethod}
                    onChange={(event) => setSelectedMethod(event.target.value)}>
                    <MenuItem value={'Email,Text'}>
                      Send Email and Text Message
                    </MenuItem>
                    <MenuItem value={'Email'}>Send Email Only</MenuItem>
                    <MenuItem value={'Text'}>Send Text Message Only</MenuItem>
                  </Select>
                </FormControl>
              ) : null}

              {selectedAction && actionList[selectedAction].directions ? (
                <p className="directions">
                  {actionList[selectedAction].directions}
                </p>
              ) : null}

              {selectedAction &&
              selectedMethod &&
              selectedMethod.indexOf('Email') !== -1 &&
              actionList[selectedAction].subjectEditable ? (
                <TextField
                  value={subject}
                  fullWidth
                  key="subject"
                  onChange={(event) => setSubject(event.target.value)}
                  label={'Email Subject'}
                  className={
                    !actionList[selectedAction].subjectEditable
                      ? 'readonly'
                      : ''
                  }
                  required={actionList[selectedAction].subjectEditable}
                  InputProps={{
                    readOnly: !actionList[selectedAction].subjectEditable
                  }}
                />
              ) : null}

              {selectedAction && messageParts ? (
                <ul className="selected-message-parts">
                  {selectedAction && messageParts
                    ? Object.keys(messageParts)
                        .filter((key) => key !== 'Custom')
                        .map((key) => (
                          <li key={key}>
                            <Checkbox
                              checked={messageParts[key]}
                              onChange={(event) =>
                                setMessageParts({
                                  ...messageParts,
                                  [key]: !messageParts[key]
                                })
                              }
                              name="gilad"
                            />
                            <div
                              className={
                                'value' +
                                (!messageParts[key] ? ' disabled' : '')
                              }
                              dangerouslySetInnerHTML={{ __html: key }}></div>
                          </li>
                        ))
                    : null}

                  {selectedAction ? (
                    <li>
                      <Checkbox
                        key="customMessageCheckbox"
                        checked={
                          messageParts['Custom'] ||
                          actionList[selectedAction].requireMessage
                            ? true
                            : false
                        }
                        disabled={actionList[selectedAction].requireMessage}
                        onChange={(event) => {
                          setMessageParts({
                            ...messageParts,
                            Custom: !messageParts['Custom']
                          });

                          setTimeout(
                            () =>
                              document.getElementById('customMessage').focus(),
                            100
                          );
                        }}
                        name="gilad"
                      />

                      <TextField
                        id="customMessage"
                        value={message}
                        disabled={
                          !messageParts.Custom &&
                          !actionList[selectedAction].requireMessage
                        }
                        onChange={(event) => setMessage(event.target.value)}
                        label={
                          fixedMessageParts
                            ? 'Other explanation: Type message to resident here'
                            : (selectedAction &&
                              actionList[selectedAction].requireMessage
                                ? ''
                                : 'Optional: ') + 'Add a custom message'
                        }
                        autoFocus
                        placeholder="Type the message you would like us to send the resident here"
                        fullWidth
                        required={
                          selectedAction &&
                          actionList[selectedAction].requireMessage
                        }
                        multiline
                        inputProps={{
                          style: {
                            minHeight: '100px'
                          }
                        }}
                      />
                    </li>
                  ) : null}
                </ul>
              ) : null}
            </div>

            <div className="panel message-preview">
              <h3>Message Preview</h3>

              {selectedAction ? (
                <>
                  <Tabs
                    value={previewMode}
                    onChange={(event, newValue) => {
                      setPreviewMode(newValue);
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="standard"
                    aria-label="full width tabs example">
                    <Tab
                      label="Email"
                      value="Email"
                      disabled={
                        !selectedMethod ||
                        selectedMethod.indexOf('Email') === -1
                      }
                    />
                    <Tab
                      label="Text Message"
                      value="Text"
                      disabled={
                        !selectedMethod || selectedMethod.indexOf('Text') === -1
                      }
                    />
                  </Tabs>
                  <div className="preview-box">
                    {previewMode === 'Email' ? (
                      <>
                        <div className="preview-header">
                          From:{' '}
                          <strong>
                            {resident.PropertyName}{' '}
                            <em>{'<support@movingin.com>'}</em>
                          </strong>
                          <br />
                          To:{' '}
                          <strong>
                            {resident.FirstName} {resident.LastName}{' '}
                            <em>{'<' + resident.Email + '>'}</em>
                          </strong>
                          <br />
                          Subject: <strong>{subject}</strong>
                        </div>

                        <div
                          className="message-body"
                          dangerouslySetInnerHTML={{
                            __html: outgoing.Email
                          }}></div>
                      </>
                    ) : (
                      <>
                        <div className="preview-header">
                          From: <strong>1-844-329-3073</strong>
                          <br />
                          To: <strong>1-{resident.Phone}</strong>
                        </div>

                        <div
                          className="message-body"
                          dangerouslySetInnerHTML={{
                            __html: outgoing.Text.split('\n').join('<br />')
                          }}></div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <div className="preview-box empty">
                  Select a notification type from the menu on the left to get
                  started.
                </div>
              )}
            </div>
          </>
        ) : (
          <LinearProgress />
        )}
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'right' }}>
        <Button
          variant="contained"
          color="secondary"
          className={isRejection ? 'reject' : ''}
          onClick={() => {
            setSending(true);
            sendResidentMessage(profileKey, {
              ResidentID: resident.ResidentID,
              Method: selectedMethod,
              MessageType: actionList[selectedAction].key,
              MessageTypeDescription: actionList[selectedAction].description,
              RequiredItem: null,
              EmailSubject: subject,
              EmailBody: outgoing.Email,
              TextBody: outgoing.Text
            }).then((result) => {
              if (afterSend) {
                let _reason = '';
                Object.keys(messageParts)
                  .filter((part) => part !== 'Custom' && messageParts[part])
                  .map((part) => {
                    _reason += (_reason ? ' ' : '') + part;
                  });

                if (message) {
                  _reason += (_reason ? ' ' : '') + message;
                }

                afterSend(_reason);
              } else {
                closeFunc();
              }
            });
          }}
          disabled={
            sending ||
            !messageParts ||
            !selectedAction ||
            (Object.keys(messageParts).filter(
              (key) => key !== 'Custom' || messageParts[key]
            ).length &&
              !Object.keys(messageParts).filter((key) => messageParts[key])
                .length) ||
            ((messageParts.Custom ||
              actionList[selectedAction].requireMessage) &&
              !message)
          }>
          {sending
            ? 'Sending Message...'
            : isRejection
            ? 'Confirm Rejection and Send Message'
            : 'Send Message'}{' '}
          <SendIcon style={{ marginLeft: '10px' }} />
        </Button>
        <Button
          onClick={() => {
            closeFunc();
          }}
          variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      loadResidentOnboarding: bindActionCreators(
        loadResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendResidentMessage);
