import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import Rating from '@mui/material/Rating';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import './index.css';
import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';

import * as dataApi from '../../api/dataApi';
import Empty from '../Common/Empty';

function SortColumn({ sortColumn, setSortColumn, field, label }) {
  return (
    <Button
      onClick={() => {
        let _sortColumn;

        switch (sortColumn) {
          case field:
            _sortColumn = '-' + field;
            break;
          default:
            _sortColumn = field;
            break;
        }

        setSortColumn(_sortColumn);
      }}>
      {label}
      {sortColumn.indexOf(field) !== -1 ? (
        <ArrowDropDownIcon
          style={{
            transform:
              sortColumn.indexOf('-') !== -1 ? 'rotate(180deg)' : 'none'
          }}
        />
      ) : null}
    </Button>
  );
}

function PlaceGrid({
  actions,
  serviceAddress,
  placeList,
  include,
  label,
  onRowMouseOver,
  onRowMouseOut
}) {
  const [searchString, setSearchString] = useState(null);
  const [sortColumn, setSortColumn] = useState(
    include && include.distance ? '-CalculatedDistance' : 'RatingCount'
  );
  const [sortedPlaceList, setSortedPlaceList] = useState(null);

  useEffect(() => {
    if (placeList) {
      let allProviderList = [];
      serviceAddress.Checklist.map((section) => {
        section.ServiceList.map((service) => {
          service.ProviderList.map((provider) => {
            allProviderList.push(provider);
          });
        });
      });

      setSortedPlaceList(
        dataApi
          .sortPlaces(
            placeList.filter((place) => place.Rating),
            sortColumn.split('-').join('').split('RatingValue').join('Rating'),
            sortColumn.indexOf('-') === -1
          )
          .map((place) => {
            let _place = { ...place };

            _place.LinkUrl =
              'https://www.google.com/maps/place/?q=place_id:' +
              place.GooglePlaceId;

            const provider = allProviderList.filter(
              (_provider) =>
                place.GooglePlaceName.toLowerCase().indexOf(
                  _provider.DisplayName.toLowerCase()
                ) !== -1
            )[0];

            if (provider && provider.ServiceStartUrl) {
              _place.LinkUrl = provider.ServiceStartUrl;
            }

            return _place;
          })
      );
    }
  }, [placeList, sortColumn]);

  return (
    <div className="places">
      {placeList && sortedPlaceList ? (
        <div className="list">
          {sortedPlaceList && sortedPlaceList.length ? (
            <TableContainer component={Paper} style={{ maxHeight: '500px' }}>
              <Table aria-label="simple table" stickyHeader={true}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormControl variant="standard">
                        <Input
                          id="input-with-icon-adornment"
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                          placeholder={'Search ' + label}
                          value={searchString}
                          onChange={(event) =>
                            setSearchString(event.target.value)
                          }
                        />
                      </FormControl>
                    </TableCell>

                    {include && include.distance ? (
                      <TableCell style={{ textAlign: 'right' }}>
                        <SortColumn
                          field={'CalculatedDistance'}
                          label={'Distance'}
                          sortColumn={sortColumn}
                          setSortColumn={setSortColumn}
                        />
                      </TableCell>
                    ) : null}

                    {!include || include.rating ? (
                      <TableCell className="rating">
                        <div style={{ float: 'right' }}>
                          <SortColumn
                            field={'RatingCount'}
                            label={'Reviews'}
                            sortColumn={sortColumn}
                            setSortColumn={setSortColumn}
                          />
                        </div>
                        <SortColumn
                          field={'RatingValue'}
                          label={'Google Rating'}
                          sortColumn={sortColumn}
                          setSortColumn={setSortColumn}
                        />
                      </TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedPlaceList
                    .filter(
                      (place) =>
                        !searchString ||
                        place.GooglePlaceName.toLowerCase().indexOf(
                          searchString.toLowerCase()
                        ) !== -1
                    )
                    .map((place) => (
                      <TableRow key={place.GooglePlaceId}>
                        <TableCell>
                          <a
                            href={place.LinkUrl}
                            onMouseOver={() => {
                              if (onRowMouseOver) {
                                onRowMouseOver(place);
                              }
                            }}
                            onMouseOut={() => {
                              if (onRowMouseOut) {
                                onRowMouseOut(place);
                              }
                            }}
                            className="link"
                            target="mi_offsite">
                            {place.GooglePlaceName}
                          </a>
                        </TableCell>

                        {include && include.distance ? (
                          <TableCell style={{ textAlign: 'right' }}>
                            {place.CalculatedDistance.toFixed(1)} mi
                          </TableCell>
                        ) : null}

                        {!include || include.rating ? (
                          <TableCell className="rating">
                            <span className="value">
                              {place.Rating.toFixed(1)}
                            </span>
                            <Rating
                              name="read-only"
                              value={place.Rating}
                              readOnly
                              precision={0.1}
                            />
                            <span className="value">({place.RatingCount})</span>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Empty>No {label} found for this address</Empty>
          )}
        </div>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      markTaskComplete: bindActionCreators(
        serviceAddressActions.markTaskComplete,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaceGrid);
