import React, { useState, useRef, useEffect, version } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LinearProgress } from '@mui/material';
import { Alert } from '@mui/material';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import ResidentOnboardingProviderService from './ResidentOnboardingProviderService';
import ResidentOnboardingVehicle from './ResidentOnboardingVehicle';
import ResidentOnboardingPet from './ResidentOnboardingPet';
import ResidentOnboardingReservation from './ResidentOnboardingReservation';
import ResidentOnboardingStatusBadge from './ResidentOnboardingStatusBadge';
import ResidentWalkthrough from './ResidentWalkthrough';
import ResidentOnboardingChecklist from './ResidentOnboardingChecklist';
import CustomerEventCalendar from '../CustomerEventCalendar';

import './index.css';

function ResidentOnboarding({
  actions,
  serviceAddress,
  siteConfig,
  residentOnboarding,
  isPage,
  otherTasks,
  providerServiceList,
  residentID,
  closeOnboardingRow,
  profileKey,
  includeChecklist,
  residentFormID,
  grouping,
  onboardingDashboardRow
}) {
  const ref = useRef();
  const [saveInProgress, setSaveInProgress] = useState(null);

  useEffect(() => {
    if (
      (serviceAddress &&
        serviceAddress.Checklist &&
        serviceAddress.Address.ResidentID) ||
      residentID
    ) {
      loadResidentOnboarding();
    }
  }, [serviceAddress]);

  function loadResidentOnboarding() {
    actions.loadResidentOnboarding(residentID);
  }

  function updateResidentOnboardingHeader(header) {
    setSaveInProgress(true);
    return actions.saveResidentOnboarding(header, residentID).then((result) => {
      setSaveInProgress(false);

      return result;
    });
  }

  return residentOnboarding &&
    residentOnboarding.Header &&
    (!residentID || residentOnboarding.Header.ResidentID === residentID) ? (
    <div className="section resident-onboarding" ref={ref}>
      {!isPage ? (
        <>
          <>
            {residentOnboarding.Header &&
            residentOnboarding.Header.ResidentStatusID !== 4 &&
            residentOnboarding.Checklist &&
            residentOnboarding.Checklist.length &&
            !otherTasks &&
            (!residentID ||
              (providerServiceList && providerServiceList.length > 1) ||
              (otherTasks &&
                residentOnboarding.Checklist.filter(
                  (item) => item.Grouping === otherTasks
                ).length > 1) ||
              includeChecklist) ? (
              <ResidentOnboardingChecklist
                residentID={residentID}
                title={
                  !otherTasks && !providerServiceList && !residentID
                    ? 'My Checklist'
                    : null
                }
                otherTasks={otherTasks}
                providerServiceList={providerServiceList}
                parentRef={ref}
                residentOnboarding={residentOnboarding}
              />
            ) : null}
          </>

          {!otherTasks &&
          residentOnboarding.Header &&
          residentOnboarding.Header.ResidentStatusID !== 4 ? (
            <>
              {residentOnboarding.Checklist.filter(
                (item) =>
                  item.ProviderServiceID &&
                  (!providerServiceList ||
                    !providerServiceList.length ||
                    providerServiceList.filter(
                      (providerService) =>
                        item.ProviderServiceID ===
                        providerService.ProviderServiceID
                    ).length)
              ).map((item) => (
                <div
                  className={
                    'onboarding-item provider-service-' + item.ProviderServiceID
                  }>
                  <ResidentOnboardingProviderService
                    residentOnboarding={residentOnboarding}
                    refreshResidentOnboarding={loadResidentOnboarding}
                    item={item}
                    residentID={residentID}
                    profileKey={profileKey}
                    closeOnboardingRow={closeOnboardingRow}
                  />
                </div>
              ))}
            </>
          ) : null}
        </>
      ) : null}

      {residentOnboarding.Header ? (
        <>
          {!providerServiceList || !providerServiceList.length ? (
            <>
              {residentOnboarding.Header.VehicleRegistrationInclude &&
              (!otherTasks ||
                otherTasks === true ||
                otherTasks === 'vehicle' ||
                otherTasks === 'vehicle-applicant') ? (
                <ResidentOnboardingVehicle
                  residentOnboarding={residentOnboarding}
                  refreshResidentOnboarding={loadResidentOnboarding}
                  updateResidentOnboardingHeader={
                    updateResidentOnboardingHeader
                  }
                  residentID={residentID}
                  closeOnboardingRow={closeOnboardingRow}
                  profileKey={profileKey}
                />
              ) : null}

              {residentOnboarding.Header.PetInclude &&
              (!otherTasks ||
                otherTasks === true ||
                otherTasks === 'pet' ||
                otherTasks === 'pet-applicant' ||
                otherTasks === 'pet-interview' ||
                otherTasks === 'pet-esa') ? (
                <ResidentOnboardingPet
                  residentOnboarding={residentOnboarding}
                  refreshResidentOnboarding={loadResidentOnboarding}
                  updateResidentOnboardingHeader={
                    updateResidentOnboardingHeader
                  }
                  residentID={residentID}
                  closeOnboardingRow={closeOnboardingRow}
                  profileKey={profileKey}
                  grouping={grouping}
                  onboardingDashboardRow={onboardingDashboardRow}
                />
              ) : null}

              {residentOnboarding.CalendarList &&
              residentOnboarding.CalendarList.length &&
              (!otherTasks ||
                otherTasks === true ||
                otherTasks === 'reservation' ||
                otherTasks === 'reservation-notice')
                ? (otherTasks === 'reservation-notice'
                    ? residentOnboarding.CalendarList.filter(
                        (calendar) =>
                          calendar.CalendarName === 'Service Elevator'
                      )
                    : residentOnboarding.CalendarList
                  ).map((calendar) => (
                    <div
                      className={
                        'resident-onboarding-item reservation' +
                        (' reservation-' + calendar.CalendarID)
                      }>
                      <div className="form-header">
                        <h3>{calendar.CalendarName}</h3>
                      </div>
                      {!residentID && calendar.Description ? (
                        <Alert severity="info" className="top-notice">
                          {calendar.Description}
                        </Alert>
                      ) : null}

                      <CustomerEventCalendar
                        calendar={calendar}
                        minDate={residentOnboarding.Header.DateLeaseStart}
                        residentID={residentOnboarding.Header.ResidentID}
                      />
                    </div>
                  ))
                : null}

              {!otherTasks ||
              otherTasks === true ||
              otherTasks === 'walkthrough'
                ? residentOnboarding.Checklist.filter(
                    (item) => item.ResidentWalkthroughID !== null
                  ).map((walkthrough) => (
                    <ResidentWalkthrough
                      walkthrough={walkthrough}
                      residentID={residentID}
                      residentOnboarding={residentOnboarding}
                      refreshResidentOnboarding={loadResidentOnboarding}
                      closeOnboardingRow={closeOnboardingRow}
                    />
                  ))
                : null}
            </>
          ) : null}
        </>
      ) : null}
    </div>
  ) : (
    <LinearProgress style={{ marginTop: '10px', marginBottom: '10px' }} />
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      ),
      saveResidentOnboarding: bindActionCreators(
        residentOnboardingActions.saveResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResidentOnboarding);
