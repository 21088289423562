import React, { useState, useRef, useEffect, version } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import Grid from '@mui/material/Grid';

import './index.css';
import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

const $ = typeof window !== 'undefined' ? window.$ : {};

import { useQueryParam } from 'gatsby-query-params';

import UtilityBox from './UtilityBox';
import IntroText from '../IntroText';
import TaskMenu from '../TaskMenu';

function ConnectUtilities({
  actions,
  serviceAddress,
  mode,
  residentOnboarding
}) {
  const [selectedSection, setSelectedSection] = useState(null);

  useEffect(() => {
    if (serviceAddress && serviceAddress.Checklist) {
      setSelectedSection(
        serviceAddress.Checklist.filter(
          (section) => section.Route === '/connect-utilities'
        )[0]
      );

      if (
        serviceAddress &&
        serviceAddress.Address &&
        serviceAddress.Address.ResidentID &&
        !residentOnboarding
      ) {
        actions.loadResidentOnboarding();
      }
    }
  }, [serviceAddress]);

  function getServiceList() {
    return selectedSection.ServiceList.filter(
      (service) =>
        !residentOnboarding ||
        !residentOnboarding.Checklist ||
        !residentOnboarding.Checklist.filter((item) => {
          console.log('ServiceList', service, item);
          return item.ProviderServiceID == service.ProviderServiceID;
        }).length
    );
  }

  return selectedSection ? (
    <>
      <div
        className={
          'page-container connect-utilities-container' +
          (selectedSection &&
          selectedSection.ServiceList &&
          selectedSection.ServiceList.length === 5
            ? ' wider'
            : '')
        }>
        <h1>
          <TaskMenu section={selectedSection} page />
          {selectedSection.Title}
        </h1>

        <IntroText
          trackAs="connect-utilities"
          text={selectedSection.IntroText}
          mobileText={selectedSection.IntroTextMobile}
        />

        <div className={'page connect-utilities'}>
          <div className="inner">
            <Grid
              container
              spacing={2}
              display="flex"
              justifyContent="center"
              alignItems="center">
              {getServiceList().map((service, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={
                    getServiceList().length === 4
                      ? 3
                      : getServiceList().length === 5
                      ? 2.4
                      : 4
                  }
                  className={service.IsServiceRequired ? 'required' : ''}>
                  <UtilityBox
                    service={service}
                    serviceAddress={serviceAddress}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
    </>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectUtilities);
