import React, { useState, useEffect, useRef } from 'react';
import DocViewer, { DocViewerRenderers, PDFRenderer } from 'react-doc-viewer';

import IconButton from '@mui/material/IconButton';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { getConfig } from '../../../api/residentOnboardingApi';
import { getCookie } from '../../../api/dataApi';

export default function ResidentDocumentViewer({
  residentID,
  fileList,
  isReport
}) {
  const fileListTimeout = useRef();
  const docListTimeout = useRef();
  const [docList, setDocList] = useState(null);

  useEffect(() => {
    if (fileListTimeout.current) {
      clearTimeout(fileListTimeout.current);
    }

    fileListTimeout.current = setTimeout(() => {
      if (fileList) {
        setDocList(null);

        let _docList = [];
        if (docListTimeout.current) {
          clearTimeout(docListTimeout.current);
        }

        if (fileList.length) {
          _docList = fileList.map((file) => {
            return {
              uri: isReport
                ? file
                : process.env.GATSBY_API_URL +
                  'ResidentFile/DownloadFilePreview?residentFileID=' +
                  file.ResidentFileID +
                  '&token=' +
                  getResidentFileToken()
            };
          });
        }

        docListTimeout.current = setTimeout(() => setDocList(_docList), 0);
      }
    }, 100);
  }, [fileList]);

  // useEffect(() => {
  //   console.log('docList updated', docList);
  // }, [docList]);

  useEffect(() => {
    // cleanup function to cancel the timeout if it hasn't finished.
    return () => {
      if (fileListTimeout.current) {
        clearTimeout(fileListTimeout.current);
      }

      if (docListTimeout.current) {
        clearTimeout(docListTimeout.current);
      }
    };
  }, []);

  function getResidentFileToken() {
    const headers = getConfig(residentID).headers;

    return btoa(
      headers.key +
        ':' +
        headers.visitorKey +
        ':' +
        headers.visitKey +
        ':' +
        getCookie('mi-invitation') +
        ':' +
        getCookie('mi-d') +
        (headers.property ? ':' + headers.property + ':' + residentID : '') +
        (headers.user ? ':' + headers.user : '')
    );
  }

  const myHeader = (props) => {
    console.log('props: ', props);
    return (
      <>
        {props.disableFileName ? <div>{props.currentDocument.uri}</div> : null}

        <div className="new-viewer-icons">
          <IconButton className="control-button">
            <CloudDownloadIcon />
          </IconButton>

          <IconButton className="control-button">
            <ZoomOutIcon />
          </IconButton>

          <IconButton className="control-button">
            <ZoomInIcon />
          </IconButton>

          <IconButton className="control-button">
            <FitScreenIcon />
          </IconButton>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="document-viewer">
        {docList ? (
          docList.length ? (
            <DocViewer
              pluginRenderers={DocViewerRenderers}
              config={{
                header: {
                  //overrideComponent: myHeader,
                  disableFileName: true
                }
              }}
              documents={docList}
            />
          ) : (
            <div className="document-viewer-empty">
              No files have been uploaded
            </div>
          )
        ) : null}
      </div>
    </>
  );
}
