import { Button } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';

import SignaturePad from 'signature_pad';

import './index.css';

function SignaturePadWrapper({ label, onChange }) {
  const ref = useRef();
  const signaturePad = useRef();

  useEffect(() => {
    const canvas = ref.current.querySelector('canvas');

    signaturePad.current = new SignaturePad(canvas);

    signaturePad.current.addEventListener(
      'beginStroke',
      () => {
        console.log('Signature started');
        if (onChange) {
          signatureChanged();
        }
      },
      { once: false }
    );

    signaturePad.current.addEventListener(
      'endStroke',
      () => {
        console.log('Signature started');
        if (onChange) {
          signatureChanged();
        }
      },
      { once: false }
    );

    return () => {
      signaturePad.current = null;
    };
  }, []);

  function signatureChanged() {
    if (onChange) {
      onChange(
        signaturePad.current.isEmpty() === false,
        signaturePad.current.toData()
      );
    }
  }

  return (
    <div ref={ref} className="signature-pad-wrapper">
      {label ? <label>{label}</label> : null}
      <canvas className="desktop" width="510" height="100"></canvas>
      <canvas className="mobile" width="330" height="100"></canvas>

      <div className="controls">
        <Button
          onClick={() => {
            signaturePad.current.clear();
            if (onChange) {
              onChange(false, null);
            }
          }}>
          Clear
        </Button>
      </div>
    </div>
  );
}

export default SignaturePadWrapper;
