import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby-link';
import { withPrefix } from 'gatsby-link';

import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Alert,
  LinearProgress,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CanvasConfetti from '../CanvasConfetti';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FormControl from '@mui/material/FormControl';

import {
  QontoConnector,
  QontoStepIconRoot,
  QontoStepIcon,
  CircularProgressWithLabel,
  dayOfWeekList,
  businessHourList
} from '../ServiceProfileShared/config';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import { getServiceProfile } from '../../api/partnerDataApi';

import ServiceRequiredOption from './ServiceRequiredOption';
import RadioOption from './RadioOption';
import ProviderSearch from './ProviderSearch';
import ProofOfServiceOption from './ProofOfServiceOption';
import UtilityBox from '../ConnectUtilities/UtilityBox';

import { checkProviderServiceHasDefaultProvider } from './config';

import '../PropertyProfile/index.css';
import './index.css';

function StepServicesRequired({
  siteConfig,
  authUser,
  serviceProfile,
  activeStep,
  setProviderServiceValue,
  alreadyCompleted,
  profileKey,

  setServiceProfile
}) {
  const [changeFlash, setChangeFlash] = useState(null);
  const [isRenter, setIsRenter] = useState(null);

  useEffect(() => {
    setIsRenter(serviceProfile.Header.Role !== 'Owner');
  }, [serviceProfile]);

  function getUtilityBoxProviderService(providerService) {
    let _providerService = { ...providerService };

    if (_providerService.ProviderServiceID === 7) {
      //If Trash, check if valet trash should be shown
      const _valetTrash = serviceProfile.ServiceSectionList[
        activeStep
      ].ProviderServiceList.filter(
        (providerService) => providerService.ProviderServiceID === 25
      )[0];

      if (_valetTrash && _valetTrash.IsServiceRequired) {
        //_providerService = { ..._valetTrash, IsServiceRequired: false };
        _providerService.ServiceAddendumFile = _valetTrash.ServiceAddendumFile
          ? _valetTrash.ServiceAddendumFile
          : '1';
      }
    }

    if (!_providerService.IsServiceRequired) {
      _providerService.ServiceStartUrl = '';
      _providerService.ServiceTransferUrl = '';
    }

    return {
      ProviderServiceName: _providerService.ProviderServiceName,
      ProviderServiceHeaderName: providerService.ProviderServiceName,
      IsServiceRequired: _providerService.IsServiceRequired,
      ProviderList: getUtilityBoxProviderList(
        serviceProfile,
        _providerService,
        activeStep
      )
    };
  }

  function getUtilityBoxProviderList(
    serviceProfile,
    providerService,
    activeStep
  ) {
    let provider = providerService.IsDefaultProviderCorrect
      ? serviceProfile.ServiceSectionList[activeStep].ProviderList.filter(
          (provider) =>
            provider.ProviderServiceID === providerService.ProviderServiceID
        )[0]
      : providerService.ProviderNameEntered
      ? (serviceProfile.OtherProviderDictionary[
          providerService.ProviderServiceID
        ]
          ? serviceProfile.OtherProviderDictionary[
              providerService.ProviderServiceID
            ]
          : []
        ).filter(
          (provider) =>
            provider.ProviderName == providerService.ProviderNameEntered
        ).length
        ? serviceProfile.OtherProviderDictionary[
            providerService.ProviderServiceID
          ].filter(
            (provider) =>
              provider.ProviderName == providerService.ProviderNameEntered
          )[0]
        : {
            ProviderName: providerService.ProviderNameEntered
          }
      : null;

    if (!provider && providerService.IsServiceRequired === false) {
      provider = { ProviderID: 0 };
    }

    return providerService.IsDeregulatedAreaConfirmed
      ? serviceProfile.ServiceSectionList[activeStep].ProviderList.map(
          (provider) => {
            return {
              ...providerService,
              ...provider,
              IsServiceRequired: providerService.IsServiceRequired,
              IsCustomer: true
            };
          }
        )
      : provider
      ? [
          {
            ...provider,
            ...providerService,
            IsCustomer: true
          }
        ]
      : [];
  }

  return serviceProfile.ServiceSectionList[
    activeStep
  ].ProviderServiceList.filter(
    (providerService) => providerService.ProviderServiceID !== 25
  ).map((providerService) => (
    <div className="section utility">
      <h3>In-Unit {providerService.ProviderServiceName} Service</h3>

      <div className="form-content">
        <ServiceRequiredOption
          setProviderServiceValue={setProviderServiceValue}
          providerService={providerService}
          serviceProfile={serviceProfile}
          isRenter={isRenter}
        />

        {providerService.IsServiceRequired ? (
          <>
            {providerService.IsDeregulatedArea ? (
              <RadioOption
                setProviderServiceValue={setProviderServiceValue}
                providerService={providerService}
                field={'IsDeregulatedAreaConfirmed'}
                question={
                  "Your property appears to be in a 'Consumer Choice' part of " +
                  serviceProfile.Header.StateName +
                  ', meaning you can choose who provides your  ' +
                  providerService.ProviderServiceName +
                  ' service. Is this correct?'
                }></RadioOption>
            ) : null}

            {providerService.ProviderServiceID === 5 ? ( //Water/Sewer
              <div className={'radio-option'}>
                <p>
                  Is Water / Sewer service provided by the same service
                  provider?
                </p>

                <FormControl className="radio-options">
                  <RadioGroup
                    row
                    name="service-available"
                    value={serviceProfile.Header.IsWaterSewerSameProvider}
                    onChange={(event) => {
                      const isWaterSewerSameProvider =
                        event.target.value === 'true';
                      let serviceSection = null;
                      let waterProviderService = null;
                      serviceProfile.ServiceSectionList.map(
                        (_serviceSection) => {
                          if (_serviceSection.ProviderServiceList) {
                            _serviceSection.ProviderServiceList.map(
                              (providerService) => {
                                if (providerService.ProviderServiceID === 5) {
                                  waterProviderService = {
                                    ...providerService
                                  };
                                  serviceSection = {
                                    ..._serviceSection
                                  };
                                }
                              }
                            );
                          }
                        }
                      );

                      if (waterProviderService && serviceSection) {
                        const providerServiceName = isWaterSewerSameProvider
                          ? 'Water / Sewer'
                          : 'Water';

                        let providerServiceList =
                          serviceSection.ProviderServiceList.map(
                            (_providerService) =>
                              _providerService.ProviderServiceID ===
                              waterProviderService.ProviderServiceID
                                ? {
                                    ...waterProviderService,
                                    ProviderServiceName: providerServiceName
                                  }
                                : { ..._providerService }
                          );

                        if (isWaterSewerSameProvider) {
                          //Make sure Sewer is removed from the provider service list
                          let _index = -1;
                          providerServiceList.map(
                            (_providerService, _providerServiceIndex) => {
                              if (_providerService.ProviderServiceID == 6) {
                                _index = _providerServiceIndex;
                              }
                            }
                          );

                          if (_index !== -1) {
                            providerServiceList.splice(_index, 1);
                          }
                        } else {
                          //Add a Sewer element to the array if not already there
                          //Make sure Sewer is removed from the provider service list
                          let _waterSewerIndex = -1;
                          let _sewerIndex = -1;
                          providerServiceList.map(
                            (_providerService, _providerServiceIndex) => {
                              if (_providerService.ProviderServiceID == 5) {
                                _waterSewerIndex = _providerServiceIndex;
                              } else if (
                                _providerService.ProviderServiceID == 6
                              ) {
                                _sewerIndex = _providerServiceIndex;
                              }
                            }
                          );

                          if (_sewerIndex === -1 && _waterSewerIndex !== -1) {
                            providerServiceList.splice(
                              _waterSewerIndex + 1,
                              0,
                              {
                                ...waterProviderService,
                                ProviderServiceID: 6,
                                ProviderServiceName: 'Sewer',
                                IsDefaultProviderCorrect: false,
                                IsServiceAvailable: true
                              }
                            );
                          }
                        }

                        setServiceProfile({
                          ...serviceProfile,
                          Header: {
                            ...serviceProfile.Header,
                            IsWaterSewerSameProvider: isWaterSewerSameProvider
                          },
                          ServiceSectionList:
                            serviceProfile.ServiceSectionList.map(
                              (_serviceSection) =>
                                _serviceSection.ServiceSectionID ===
                                serviceSection.ServiceSectionID
                                  ? {
                                      ..._serviceSection,
                                      ProviderServiceList: providerServiceList
                                    }
                                  : { ..._serviceSection }
                            )
                        });
                      }
                    }}>
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label={'Yes'}
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label={'No'}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            ) : null}

            {providerService.ProviderServiceID !== 7 &&
            (!providerService.IsDeregulatedArea ||
              providerService.IsDeregulatedAreaConfirmed === false) ? (
              !providerService.IsDeregulatedArea ||
              providerService.IsDeregulatedAreaConfirmed === false ||
              checkProviderServiceHasDefaultProvider(
                serviceProfile,
                activeStep,
                providerService
              ) ? (
                <RadioOption
                  setProviderServiceValue={setProviderServiceValue}
                  subOptionOnly={providerService.IsDeregulatedArea}
                  hideRadioOptions={
                    !serviceProfile.ServiceSectionList[
                      activeStep
                    ].ProviderList.filter(
                      (provider) =>
                        provider.ProviderServiceID ===
                        providerService.ProviderServiceID
                    ).length ||
                    !checkProviderServiceHasDefaultProvider(
                      serviceProfile,
                      activeStep,
                      providerService
                    )
                  }
                  providerService={providerService}
                  field={'IsDefaultProviderCorrect'}
                  question={
                    providerService.IsDeregulatedArea ||
                    !checkProviderServiceHasDefaultProvider(
                      serviceProfile,
                      activeStep,
                      providerService
                    )
                      ? 'Who is your ' +
                        providerService.ProviderServiceName +
                        ' service provider?'
                      : serviceProfile.ServiceSectionList[
                          activeStep
                        ].ProviderList.filter(
                          (provider) =>
                            provider.ProviderServiceID ===
                            providerService.ProviderServiceID
                        ).length > 0
                      ? 'Is ' +
                        serviceProfile.ServiceSectionList[
                          activeStep
                        ].ProviderList.filter(
                          (provider) =>
                            provider.ProviderServiceID ===
                            providerService.ProviderServiceID
                        )[0].ProviderName +
                        ' your ' +
                        providerService.ProviderServiceName +
                        ' service provider?'
                      : 'Who is  your ' +
                        providerService.ProviderServiceName +
                        ' service provider?'
                  }>
                  <div
                    className={
                      'sub-option' +
                      (providerService.IsDefaultProviderCorrect !== false &&
                      !providerService.IsDeregulatedArea &&
                      checkProviderServiceHasDefaultProvider(
                        serviceProfile,
                        activeStep,
                        providerService
                      )
                        ? ' disabled'
                        : '')
                    }>
                    <ProviderSearch
                      disabled={
                        providerService.IsDefaultProviderCorrect !== false &&
                        !providerService.IsDeregulatedArea &&
                        checkProviderServiceHasDefaultProvider(
                          serviceProfile,
                          activeStep,
                          providerService
                        )
                      }
                      freeSolo
                      value={providerService.ProviderNameEntered}
                      onChange={(event, value) => {
                        let _options = {
                          ProviderNameEntered: value,
                          IsServiceAvailable: true
                        };

                        if (
                          !checkProviderServiceHasDefaultProvider(
                            serviceProfile,
                            activeStep,
                            providerService
                          )
                        ) {
                          _options.IsDefaultProviderCorrect = false;
                          _options.IsMissingDefaultProvider = true;
                        }

                        setProviderServiceValue(providerService, _options);
                      }}
                      providerList={(serviceProfile.OtherProviderDictionary[
                        providerService.ProviderServiceID
                      ]
                        ? serviceProfile.OtherProviderDictionary[
                            providerService.ProviderServiceID
                          ]
                        : []
                      ).map((option) => option.ProviderName)}
                      label={
                        providerService.IsDeregulatedArea ||
                        !checkProviderServiceHasDefaultProvider(
                          serviceProfile,
                          activeStep,
                          providerService
                        )
                          ? 'Search for your provider here'
                          : 'If NO, search for the correct provider here'
                      }
                    />
                  </div>
                </RadioOption>
              ) : (
                <RadioOption
                  setProviderServiceValue={setProviderServiceValue}
                  subOptionOnly={providerService.IsDeregulatedArea}
                  providerService={providerService}
                  field={'IsMissingDefaultProvider'}
                  question={
                    'Do you know who your ' +
                    providerService.ProviderServiceName +
                    ' service provider is?'
                  }>
                  <div
                    className={
                      'sub-option' +
                      (providerService.IsMissingDefaultProvider !== true
                        ? ' disabled'
                        : '')
                    }>
                    <ProviderSearch
                      freeSolo
                      value={providerService.ProviderNameEntered}
                      onChange={(event, value) => {
                        setProviderServiceValue(providerService, {
                          ProviderNameEntered: value
                        });
                      }}
                      providerList={(serviceProfile.OtherProviderDictionary[
                        providerService.ProviderServiceID
                      ]
                        ? serviceProfile.OtherProviderDictionary[
                            providerService.ProviderServiceID
                          ]
                        : []
                      ).map((option) => option.ProviderName)}
                      label={'Enter the provider name here'}
                    />
                  </div>
                </RadioOption>
              )
            ) : null}

            {providerService.ProviderServiceID !== 7 && isRenter ? (
              <>
                <ProofOfServiceOption
                  setProviderServiceValue={setProviderServiceValue}
                  providerService={providerService}
                  serviceProfile={serviceProfile}
                  alreadyCompleted={alreadyCompleted}
                  authUser={authUser}
                  siteConfig={siteConfig}></ProofOfServiceOption>
              </>
            ) : null}
          </>
        ) : null}

        {providerService.ProviderServiceID === 7 ? (
          <ServiceRequiredOption
            setProviderServiceValue={setProviderServiceValue}
            providerService={
              serviceProfile.ServiceSectionList[
                activeStep
              ].ProviderServiceList.filter(
                (providerService) => providerService.ProviderServiceID === 25
              )[0]
            }
            question={
              "Do you offer a separate 'Valet' type doorside trash pick-up service?"
            }
            profileKey={profileKey}
            requireSubOptionIfYes
          />
        ) : null}
      </div>

      <div className="preview-content connect-utilities-container">
        {isRenter || providerService.IsServiceRequired ? (
          <div className="connect-utilities">
            <UtilityBox
              service={getUtilityBoxProviderService(providerService)}
              serviceAddress={{}}
              isDeregulated={providerService.IsDeregulatedAreaConfirmed}>
              <div
                className={
                  'change-flash' +
                  (changeFlash === providerService.ProviderServiceID
                    ? ' show'
                    : '')
                }></div>
            </UtilityBox>
          </div>
        ) : null}
      </div>
    </div>
  ));
}

const mapStateToProps = (state) => ({
  serviceAddress: state.serviceAddress,
  authUser: state.authUser,
  siteConfig: state.commonData.siteConfig
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepServicesRequired);
