import React, { useState, useRef, useEffect, version } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dropzone from 'react-dropzone-uploader';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import * as webSocketActions from '../../../redux/actions/webSocketActions';
import * as residentOnboardingActions from '../../../redux/actions/residentOnboardingActions';

import ResidentOnboardingFileUpload from '../ResidentOnboardingFileUpload';
import ResidentOnboardingStatusBadge from '../ResidentOnboardingStatusBadge';
import ResidentOnboardingStatusUpdate from '../ResidentOnboardingStatusUpdate';
import ResidentDocumentViewer from '../ResidentDocumentViewer';
import GeneralPopup from '../../GeneralPopup';

import 'react-dropzone-uploader/dist/styles.css';
import '../../RentersInsurance/index.css';
import '../index.css';
import { Tooltip } from '@mui/material';

function ResidentOnboardingProviderService({
  actions,
  serviceAddress,
  siteConfig,
  residentOnboarding,
  refreshResidentOnboarding,
  item,
  residentID,
  closeOnboardingRow,
  profileKey
}) {
  const [serviceSetting, setServiceSetting] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [saving, setSaving] = useState(null);
  const [isDisabled, setIsDisabled] = useState(null);
  const [residentStatusUpdate, setResidentStatusUpdate] = useState(null);
  const [fileList, setFileList] = useState(null);
  const [validationErrorList, setValidationErrorList] = useState(null);
  const [nameOnDocumentDictionary, setNameOnDocumentDictionary] = useState([]);
  const [addNameOnDocument, setAddNameOnDocument] = useState(null);

  useEffect(() => {
    if (residentOnboarding) {
      if (!serviceSetting) {
        loadServiceSetting();
      }
    }
  }, [residentOnboarding, item]);

  useEffect(() => {
    if (residentOnboarding) {
      const _fileList = residentOnboarding.FileList.filter(
        (file) =>
          file.ResidentFileTypeID === 1 &&
          file.ProviderServiceID === item.ProviderServiceID
      );

      if (
        !fileList ||
        fileList.length !== _fileList.length ||
        fileList.filter(
          (file) =>
            !_fileList.filter(
              (_file) => _file.ResidentFileID === file.ResidentFileID
            ).length
        ).length
      )
        setFileList(_fileList);
    }
  }, [residentOnboarding, item]);

  useEffect(() => {
    checkIsDisabled();
  }, [serviceSetting, editMode]);

  useEffect(() => {
    let _validationErrorList = [];
    let _allCheckboxesChecked = true;

    if (serviceSetting && residentOnboarding) {
      if (!serviceSetting.IsAlreadyProvidedChecked) {
        if (
          item.ServiceProofIncludeProviderName &&
          !serviceSetting.ProviderName
        ) {
          _validationErrorList.push(
            'Enter your ' +
              (isRentersInsurance() ? 'Insurance Company' : 'Provider Name')
          );
        }

        if (
          item.ServiceProofIncludeAccountNumber &&
          !serviceSetting.ProviderAccountNumber
        ) {
          _validationErrorList.push(
            'Enter your ' +
              (isRentersInsurance() ? 'Policy Number' : 'Account Number')
          );
        }

        if (
          item.ServiceProofIncludeStartDate &&
          (!serviceSetting.DateServiceStart ||
            serviceSetting.DateServiceStart.indexOf('_') !== -1)
        ) {
          _validationErrorList.push(
            'Enter your ' +
              (isRentersInsurance()
                ? 'Policy Start Date'
                : 'Service Start Date') +
              ' using for the format MM/DD/YYYY'
          );
        }

        if (
          item.ServiceProofIncludeEndDate &&
          item.ProviderServiceID === 37 &&
          (!serviceSetting.DateServiceEnd ||
            serviceSetting.DateServiceEnd.indexOf('_') !== -1)
        ) {
          _validationErrorList.push(
            'Enter your ' +
              (isRentersInsurance() ? 'Policy End Date' : 'Service End Date') +
              ' using for the format MM/DD/YYYY'
          );
        }

        if (
          item.ServiceProofIncludeDocUpload &&
          (!residentOnboarding.FileList ||
            !residentOnboarding.FileList.filter(
              (file) =>
                file.ResidentFileTypeID === 1 &&
                file.ProviderServiceID === item.ProviderServiceID
            ).length)
        ) {
          _validationErrorList.push(
            'Upload your ' +
              (isRentersInsurance()
                ? 'Proof of Coverage'
                : 'Proof of Service') +
              ' document'
          );
        }

        if (
          item.ProviderServiceID === 37 &&
          item.InsuranceIsMinCoverageRequired &&
          item.InsuranceMinCoverageLiabilityRequired &&
          item.InsuranceMinCoverageLiabilityValue &&
          !serviceSetting.InsuranceMinCoverageLiabilityChecked
        ) {
          _allCheckboxesChecked = false;
        }

        if (
          item.ProviderServiceID === 37 &&
          item.InsuranceIsMinCoverageRequired &&
          item.InsuranceMinCoveragePropertyRequired &&
          item.InsuranceMinCoveragePropertyValue &&
          !serviceSetting.InsuranceMinCoveragePropertyChecked
        ) {
          _allCheckboxesChecked = false;
        }

        if (
          item.ProviderServiceID === 37 &&
          item.InsuranceIsMinCoverageRequired &&
          item.InsuranceMinCoverageOtherRequired &&
          item.InsuranceMinCoverageOtherLabel &&
          item.InsuranceMinCoverageOtherValue &&
          !serviceSetting.InsuranceMinCoverageOtherChecked
        ) {
          _allCheckboxesChecked = false;
        }

        if (
          item.ProviderServiceID === 37 &&
          item.InsuranceIsInterestedPartyRequired &&
          item.InsuranceInterestedPartyText &&
          !serviceSetting.InsuranceIsInterestedPartyChecked
        ) {
          _allCheckboxesChecked = false;
        }

        if (item.CheckboxInclude && !serviceSetting.CheckboxChecked) {
          _allCheckboxesChecked = false;
        }

        if (
          item.ProviderServiceID === 37 &&
          (!serviceSetting.NameOnDocumentList ||
            !serviceSetting.NameOnDocumentList.split(',').length)
        ) {
          _validationErrorList.push('Select at least one Named Insured');
        }

        if (!_allCheckboxesChecked) {
          _validationErrorList.push('Check all required checkboxes');
        }
      }
    }

    setValidationErrorList(_validationErrorList);
  }, [serviceSetting, residentOnboarding]);

  useEffect(() => {
    if (serviceSetting) {
      setServiceSetting({
        ...serviceSetting,
        NameOnDocumentList: Object.keys(nameOnDocumentDictionary)
          .filter((name) => nameOnDocumentDictionary[name] === true)
          .map((name) => name)
          .join(', ')
      });
    }
  }, [nameOnDocumentDictionary]);

  function loadServiceSetting(cancelEdit) {
    const existingServiceSetting =
      residentOnboarding.ProviderServiceList.filter(
        (providerService) =>
          providerService.ProviderServiceID === item.ProviderServiceID
      )[0];

    let _serviceSetting = existingServiceSetting
      ? { ...existingServiceSetting }
      : {
          ProviderServiceID: item.ProviderServiceID,
          ResidentOnboardingStatusID: 1,
          ResidentOnboardingStatusName: 'Required',
          ProviderName: '',
          ProviderAccountNumber: '',
          DateServiceStart: '',
          DateServiceEnd: '',
          CheckboxChecked: false
        };

    if (!cancelEdit && serviceSetting) {
      _serviceSetting = {
        ..._serviceSetting,
        // ...serviceSetting,
        ResidentOnboardingStatusID: _serviceSetting.ResidentOnboardingStatusID,
        ResidentOnboardingStatusName:
          _serviceSetting.ResidentOnboardingStatusName,
        ResidentOnboardingStatusNote:
          _serviceSetting.ResidentOnboardingStatusNote
      };
    }

    if (editMode === null && _serviceSetting.ResidentOnboardingStatusID === 1) {
      setEditMode(true);
    }

    const dateServiceStartDatePart = _serviceSetting.DateServiceStart
      ? _serviceSetting.DateServiceStart.split('-')
      : [];

    if (_serviceSetting && dateServiceStartDatePart.length === 3) {
      _serviceSetting.DateServiceStart =
        dateServiceStartDatePart[1] +
        '/' +
        dateServiceStartDatePart[2].split('T')[0] +
        '/' +
        dateServiceStartDatePart[0];
    }

    const dateServiceEndDatePart = _serviceSetting.DateServiceEnd
      ? _serviceSetting.DateServiceEnd.split('-')
      : [];

    if (_serviceSetting && dateServiceEndDatePart.length === 3) {
      _serviceSetting.DateServiceEnd =
        dateServiceEndDatePart[1] +
        '/' +
        dateServiceEndDatePart[2].split('T')[0] +
        '/' +
        dateServiceEndDatePart[0].split('T')[0];
    }

    setServiceSetting({
      ..._serviceSetting,
      OccupantID: _serviceSetting.OccupantID ? _serviceSetting.OccupantID : -1
    });

    let _nameOnDocumentDictionary = {};
    if (_serviceSetting.NameOnDocumentList) {
      _serviceSetting.NameOnDocumentList.split(',').map((name) => {
        _nameOnDocumentDictionary[name] = true;
      });
    }

    [residentOnboarding.Header]
      .concat(residentOnboarding.OccupantList)
      .map((occupant) => {
        const _occupantName = (occupant.FirstName + ' ' + occupant.LastName)
          .split(',')
          .join('');
        if (!_nameOnDocumentDictionary[_occupantName]) {
          _nameOnDocumentDictionary[_occupantName] = false;
        }
      });

    setNameOnDocumentDictionary(_nameOnDocumentDictionary);
  }

  function checkIsDisabled() {
    setIsDisabled(!editMode);
  }

  function saveServiceSetting() {
    actions
      .saveProviderService({ ...serviceSetting }, residentID)
      .then((result) => {
        if (result) {
          setEditMode(false);
        }
      });
  }

  function isRentersInsurance() {
    return item.ProviderServiceID === 37;
  }

  function checkRentersProofOfCoverageDocCanUpload() {
    if (item && serviceSetting) {
      if (item.InsuranceIsMinCoverageRequired) {
        if (
          item.InsuranceMinCoverageLiabilityRequired &&
          item.InsuranceMinCoverageLiabilityValue &&
          !serviceSetting.InsuranceMinCoverageLiabilityChecked
        ) {
          return false;
        }

        if (
          item.InsuranceMinCoveragePropertyRequired &&
          item.InsuranceMinCoveragePropertyValue &&
          !serviceSetting.InsuranceMinCoveragePropertyChecked
        ) {
          return false;
        }

        if (
          item.InsuranceMinCoverageOtherRequired &&
          item.InsuranceMinCoverageOtherLabel &&
          item.InsuranceMinCoverageOtherValue &&
          !serviceSetting.InsuranceMinCoverageOtherChecked
        ) {
          return false;
        }

        if (
          item.InsuranceIsInterestedPartyRequired &&
          item.InsuranceInterestedPartyText &&
          !serviceSetting.InsuranceIsInterestedPartyChecked
        ) {
          return false;
        }
      }
    }
    return true;
  }

  return serviceSetting && item && residentOnboarding ? (
    <div className="resident-onboarding-item insurance provider-service">
      <div
        className={
          'form-header' +
          (serviceSetting.ResidentOnboardingStatusNote &&
          serviceSetting.ResidentOnboardingStatusID !== 2
            ? ' with-note'
            : '')
        }>
        <h3>
          {closeOnboardingRow ? (
            <IconButton
              className="close-button"
              onClick={() => closeOnboardingRow()}>
              <CloseIcon />
            </IconButton>
          ) : null}

          {/* <ResidentOnboardingStatusBadge
            status={serviceSetting}
            isProperty={residentID ? true : false}
          /> */}

          {item.Description}
        </h3>

        {!editMode ? (
          <div className="save-button">
            {editMode ? null : residentID &&
              serviceSetting.ResidentOnboardingStatusID === 2 ? (
              <>
                <Button
                  variant="contained"
                  className="accept"
                  color="primary"
                  onClick={() => {
                    setResidentStatusUpdate({
                      dataTypeLabel: item.Description,
                      residentOnboardingStatusID: 3
                    });
                  }}>
                  <DoneIcon /> Accept
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setResidentStatusUpdate({
                      dataTypeLabel: item.Description,
                      residentOnboardingStatusID: 1
                    });
                  }}>
                  <ClearIcon /> Reject
                </Button>
              </>
            ) : null}
            {!editMode ? (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setEditMode(true)}>
                Edit
              </Button>
            ) : null}
          </div>
        ) : null}

        {serviceSetting.ResidentOnboardingStatusNote &&
        serviceSetting.ResidentOnboardingStatusID !== 2 ? (
          <Alert
            severity={
              serviceSetting.ResidentOnboardingStatusID === 3
                ? 'success'
                : 'warning'
            }>
            Note from Property: {serviceSetting.ResidentOnboardingStatusNote}
          </Alert>
        ) : null}
      </div>

      {residentID && serviceSetting.IsAlreadyProvidedChecked && !editMode ? (
        <div className="does-not-have-property">
          Resident has indicated that they used the Preferred Provider when
          signing their lease. If you agree with that statement, click the
          Accept button above. If you do not agree, click the Reject button.
        </div>
      ) : (
        <>
          <div className="form-content">
            {!residentID ? (
              <p>
                <strong>
                  The following information must be provided to, and accepted
                  by, the property prior to your move-in date.
                </strong>
              </p>
            ) : null}
            {/* {isRentersInsurance() ? (
              <div className={!editMode ? 'disabled' : ''}>
                <FormControlLabel
                  control={
                    <div>
                      <Checkbox
                        disabled={!editMode}
                        checked={serviceSetting.IsAlreadyProvidedChecked}
                        onChange={(event) =>
                          setServiceSetting({
                            ...serviceSetting,
                            dataUpdated: true,
                            IsAlreadyProvidedChecked: event.target.checked
                          })
                        }
                        name="gilad"
                      />
                    </div>
                  }
                  label={
                    residentID ? (
                      <>
                        Resident provided this information at lease signing (no
                        upload needed).
                      </>
                    ) : (
                      <>
                        I already provided this when I signed my lease (no
                        upload needed).
                      </>
                    )
                  }
                />
              </div>
            ) : null} */}

            {item.ServiceProofIncludeProviderName ? (
              <TextField
                className={!editMode ? 'disabled' : ''}
                variant="outlined"
                required
                fullWidth
                key={'textfieldProviderName_' + item.ProviderServiceID}
                id={'textfieldProviderName_' + item.ProviderServiceID}
                label={
                  isRentersInsurance() ? 'Insurance Company' : 'Provider Name'
                }
                value={serviceSetting.ProviderName}
                disabled={isDisabled}
                onChange={(event) =>
                  setServiceSetting({
                    ...serviceSetting,
                    dataUpdated: true,
                    ProviderName: event.target.value
                  })
                }
              />
            ) : null}
            {item.ServiceProofIncludeAccountNumber ? (
              <TextField
                variant="outlined"
                className={!editMode ? 'disabled' : ''}
                fullWidth
                required
                key={'textfieldProviderAccountNumber_' + item.ProviderServiceID}
                id={'textfieldProviderAccountNumber_' + item.ProviderServiceID}
                label={
                  isRentersInsurance() ? 'Policy Number' : 'Account Number'
                }
                value={serviceSetting.ProviderAccountNumber}
                disabled={isDisabled}
                onChange={(event) =>
                  setServiceSetting({
                    ...serviceSetting,
                    dataUpdated: true,
                    ProviderAccountNumber: event.target.value
                  })
                }
              />
            ) : null}
            {item.ServiceProofIncludeStartDate ? (
              <InputMask
                mask="99/99/9999"
                maskChar={'_'}
                value={serviceSetting.DateServiceStart}
                onChange={(event) => {
                  setServiceSetting({
                    ...serviceSetting,
                    dataUpdated: true,
                    DateServiceStart: event.target.value
                  });
                }}>
                {() => (
                  <TextField
                    required
                    key={'textfieldStartDate_' + item.ProviderServiceID}
                    id={'textfieldStartDate_' + item.ProviderServiceID}
                    variant="outlined"
                    label={
                      isRentersInsurance()
                        ? 'Policy Start Date'
                        : 'Service Start Date'
                    }
                    inputProps={{
                      inputMode: 'numeric'
                    }}
                    className={
                      'date-field' +
                      (item.ServiceProofIncludeEndDate
                        ? ' with-other start '
                        : '') +
                      (isDisabled ? ' disabled' : '')
                    }
                  />
                )}
              </InputMask>
            ) : null}
            {item.ServiceProofIncludeEndDate &&
            item.ProviderServiceID === 37 ? (
              <InputMask
                mask="99/99/9999"
                maskChar={'_'}
                value={serviceSetting.DateServiceEnd}
                onChange={(event) =>
                  setServiceSetting({
                    ...serviceSetting,
                    dataUpdated: true,
                    DateServiceEnd: event.target.value
                  })
                }
                disabled={isDisabled}>
                {() => (
                  <TextField
                    required
                    key={'textfieldEndDate_' + item.ProviderServiceID}
                    id={'textfieldEndDate_' + item.ProviderServiceID}
                    variant="outlined"
                    label={
                      isRentersInsurance()
                        ? 'Policy End Date'
                        : 'Service End Date'
                    }
                    inputProps={{
                      inputMode: 'numeric'
                    }}
                    className={
                      'date-field' +
                      (item.ServiceProofIncludeStartDate
                        ? ' with-other '
                        : '') +
                      (isDisabled ? ' disabled' : '')
                    }
                  />
                )}
              </InputMask>
            ) : null}

            {item.ProviderServiceID === 37 ? (
              <div className="acknowledgment">
                <p>Who is the "Named Insured" on this Policy?</p>
                {Object.keys(nameOnDocumentDictionary).map((name) => (
                  <>
                    <FormControlLabel
                      control={
                        <div>
                          <Checkbox
                            checked={nameOnDocumentDictionary[name] === true}
                            disabled={isDisabled}
                            onChange={(event) =>
                              setNameOnDocumentDictionary({
                                ...nameOnDocumentDictionary,
                                [name]: event.target.checked
                              })
                            }
                            name="gilad"
                          />
                        </div>
                      }
                      label={name}
                    />
                    <br />
                  </>
                ))}

                {serviceSetting.ResidentOnboardingStatusID === 1 ? (
                  <>
                    {' '}
                    <br />
                    <Button
                      variant="outlined"
                      style={{ marginTop: '10px', fontSize: '.8em' }}
                      onClick={() =>
                        setAddNameOnDocument({ label: 'Named Insured' })
                      }>
                      Add Other Name +
                    </Button>
                  </>
                ) : null}
              </div>
            ) : null}

            {item.ProviderServiceID === 37 ? (
              item && item.InsuranceIsMinCoverageRequired ? (
                <div
                  className="coverage-requirements"
                  style={{ marginTop: '20px' }}>
                  <p>
                    <strong>
                      You must confirm that the Proof of Coverage document you
                      attach below shows:
                    </strong>
                  </p>

                  {item.InsuranceIsMinCoverageRequired &&
                  item.InsuranceMinCoverageLiabilityRequired &&
                  item.InsuranceMinCoverageLiabilityValue ? (
                    <div
                      className={
                        'acknowledgment' + (isDisabled ? ' disabled' : '')
                      }>
                      <FormControlLabel
                        control={
                          <div>
                            <Checkbox
                              checked={
                                serviceSetting.InsuranceMinCoverageLiabilityChecked
                              }
                              disabled={isDisabled}
                              onChange={(event) =>
                                setServiceSetting({
                                  ...serviceSetting,
                                  dataUpdated: true,
                                  InsuranceMinCoverageLiabilityChecked:
                                    event.target.checked
                                })
                              }
                              name="gilad"
                            />
                          </div>
                        }
                        label={
                          <>
                            {' '}
                            <span className="required">*Required</span>
                            You have at least $
                            {item.InsuranceMinCoverageLiabilityValue.toLocaleString()}{' '}
                            in Personal Liability coverage.
                          </>
                        }
                      />
                    </div>
                  ) : null}

                  {item.InsuranceIsMinCoverageRequired &&
                  item.InsuranceMinCoveragePropertyRequired &&
                  item.InsuranceMinCoveragePropertyValue ? (
                    <div
                      className={
                        'acknowledgment' + (isDisabled ? ' disabled' : '')
                      }>
                      <FormControlLabel
                        control={
                          <div>
                            <Checkbox
                              checked={
                                serviceSetting.InsuranceMinCoveragePropertyChecked
                              }
                              disabled={isDisabled}
                              onChange={(event) =>
                                setServiceSetting({
                                  ...serviceSetting,
                                  dataUpdated: true,
                                  InsuranceMinCoveragePropertyChecked:
                                    event.target.checked
                                })
                              }
                              name="gilad"
                            />
                          </div>
                        }
                        label={
                          <>
                            {' '}
                            <span className="required">*Required</span>
                            You have at least $
                            {item.InsuranceMinCoveragePropertyValue.toLocaleString()}{' '}
                            in Personal Property coverage.
                          </>
                        }
                      />
                    </div>
                  ) : null}

                  {item.InsuranceIsMinCoverageRequired &&
                  item.InsuranceMinCoverageOtherRequired &&
                  item.InsuranceMinCoverageOtherLabel &&
                  item.InsuranceMinCoverageOtherValue ? (
                    <div
                      className={
                        'acknowledgment' + (isDisabled ? ' disabled' : '')
                      }>
                      <FormControlLabel
                        control={
                          <div>
                            <Checkbox
                              checked={
                                serviceSetting.InsuranceMinCoverageOtherChecked
                              }
                              disabled={isDisabled}
                              onChange={(event) =>
                                setServiceSetting({
                                  ...serviceSetting,
                                  dataUpdated: true,
                                  InsuranceMinCoverageOtherChecked:
                                    event.target.checked
                                })
                              }
                              name="gilad"
                            />
                          </div>
                        }
                        label={
                          <>
                            {' '}
                            <span className="required">*Required</span>
                            You have at least $
                            {item.InsuranceMinCoverageOtherValue.toLocaleString()}{' '}
                            in {item.InsuranceMinCoverageOtherLabel} coverage.
                          </>
                        }
                      />
                    </div>
                  ) : null}

                  {item.InsuranceIsInterestedPartyRequired &&
                  item.InsuranceInterestedPartyText ? (
                    <div
                      className={
                        'acknowledgment' + (isDisabled ? ' disabled' : '')
                      }>
                      <FormControlLabel
                        control={
                          <div>
                            <Checkbox
                              checked={
                                serviceSetting.InsuranceIsInterestedPartyChecked
                              }
                              disabled={isDisabled}
                              onChange={(event) =>
                                setServiceSetting({
                                  ...serviceSetting,
                                  dataUpdated: true,
                                  InsuranceIsInterestedPartyChecked:
                                    event.target.checked
                                })
                              }
                              name="gilad"
                            />
                          </div>
                        }
                        label={
                          <>
                            {' '}
                            <span className="required">*Required</span>
                            The following is shown as an "Interested Party" on
                            your policy:
                          </>
                        }
                      />
                      <div
                        className="value"
                        style={{ textAlign: 'center' }}
                        dangerouslySetInnerHTML={{
                          __html:
                            item.InsuranceInterestedPartyText.split('\n').join(
                              '<br />'
                            )
                        }}></div>
                    </div>
                  ) : null}
                </div>
              ) : null
            ) : null}

            {item.ServiceProofIncludeDocUpload && editMode ? (
              <ResidentOnboardingFileUpload
                residentOnboarding={residentOnboarding}
                refreshResidentOnboarding={refreshResidentOnboarding}
                providerServiceID={item.ProviderServiceID}
                residentFileTypeID={1}
                disabled={
                  isDisabled || !checkRentersProofOfCoverageDocCanUpload()
                }
                disabledText={
                  !isDisabled && !checkRentersProofOfCoverageDocCanUpload()
                    ? 'Confirm the Proof of Coverage document required checkboxes above'
                    : null
                }
                residentID={residentID}
                label={
                  item.Description +
                  ' Proof of ' +
                  (isRentersInsurance() ? ' Coverage' : ' Service') +
                  ' document'
                }
              />
            ) : null}
            {item.CheckboxInclude ? (
              <div
                className={'acknowledgment' + (isDisabled ? ' disabled' : '')}>
                <FormControlLabel
                  control={
                    <div>
                      <Checkbox
                        checked={serviceSetting.CheckboxChecked}
                        disabled={isDisabled}
                        onChange={(event) =>
                          setServiceSetting({
                            ...serviceSetting,
                            dataUpdated: true,
                            CheckboxChecked: event.target.checked
                          })
                        }
                        name="gilad"
                      />
                    </div>
                  }
                  label={
                    <>
                      {' '}
                      <span className="required">*Required</span>
                      Resident acknowledgment of{' '}
                      {isRentersInsurance() ? ' insurance' : ' service'}{' '}
                      requirements
                    </>
                  }
                />
                {item.CheckboxText ? (
                  <div
                    className="message"
                    dangerouslySetInnerHTML={{
                      __html: item.CheckboxText.split('\n').join('<br />')
                    }}></div>
                ) : null}
              </div>
            ) : null}
          </div>

          <div className={'doc-preview'}>
            <ResidentDocumentViewer
              residentID={residentID}
              fileList={fileList}
            />
          </div>
        </>
      )}

      {editMode ? (
        <div className="save-cancel">
          {validationErrorList.length ? (
            <Tooltip
              title={
                <div className="resident-onboarding-submit-cover-tooltip">
                  <p>
                    Please check the form above and make sure you provided all
                    fields:
                  </p>
                  <ul>
                    {validationErrorList.map((error) => (
                      <li>{error}</li>
                    ))}
                  </ul>
                </div>
              }
              placement="top-start">
              <div className="submit-cover"></div>
            </Tooltip>
          ) : null}
          <Button
            variant="contained"
            color="primary"
            className={
              validationErrorList && validationErrorList.length
                ? 'disabled'
                : ''
            }
            disabled={validationErrorList && validationErrorList.length}
            onClick={() => {
              if (!validationErrorList || !validationErrorList.length) {
                saveServiceSetting();
              }
            }}>
            Save
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              loadServiceSetting(true);
              setEditMode(false);
            }}>
            Cancel
          </Button>
        </div>
      ) : null}

      {residentStatusUpdate ? (
        <ResidentOnboardingStatusUpdate
          dataTypeLabel={residentStatusUpdate.dataTypeLabel}
          residentOnboardingStatusID={
            residentStatusUpdate.residentOnboardingStatusID
          }
          profileKey={profileKey}
          resident={residentOnboarding.Header}
          exists={!serviceSetting.IsAlreadyProvidedChecked}
          item={item}
          type={item.ProviderServiceID === 37 ? 'insurance' : 'utility'}
          saveFunc={(note) => {
            actions
              .saveProviderServiceStatus(
                residentID,
                { ...serviceSetting },
                residentStatusUpdate.residentOnboardingStatusID,
                note
              )
              .then((result) => {
                setResidentStatusUpdate(null);
                closeOnboardingRow();
              });
          }}
          closeFunc={() => setResidentStatusUpdate(null)}
        />
      ) : null}

      {addNameOnDocument ? (
        <GeneralPopup
          title={'Add ' + addNameOnDocument.label}
          className={'add-name-on-doc-popup'}
          message={
            <div style={{ marginTop: '20px' }}>
              <TextField
                fullWidth
                label={'Type name here'}
                value={addNameOnDocument.value}
                onChange={(event) =>
                  setAddNameOnDocument({
                    ...addNameOnDocument,
                    value: event.target.value
                  })
                }
              />
            </div>
          }
          submitLabel={'Save'}
          submitDisabled={!addNameOnDocument.value}
          submitFunc={() => {
            setNameOnDocumentDictionary({
              ...nameOnDocumentDictionary,
              [addNameOnDocument.value]: true
            });
            setAddNameOnDocument(null);
          }}
          closeFunc={() => setAddNameOnDocument(null)}
          closeLabel={'Cancel'}
        />
      ) : null}
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      saveProviderService: bindActionCreators(
        residentOnboardingActions.saveProviderService,
        dispatch
      ),
      saveProviderServiceStatus: bindActionCreators(
        residentOnboardingActions.saveProviderServiceStatus,
        dispatch
      )
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResidentOnboardingProviderService);
