import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import { logIn } from '../../api/userAuthApi';
import { setCookie } from '../../api/dataApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as userAuthActions from '../../redux/actions/userAuthActions';
import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import Dashboard from '../Dashboard';
import { selectSampleResident } from '../UserPropertySelect';

export const demoKey = {
  demo1: {
    key: 'QzVFQzYxNzMtMzQ2Ri00MzU5LTk0NjItMUYxNTMwRUJCNUY3',
    title: 'Demo 1'
  },
  demo2: {
    key: 'N0VCMTYzMTktOUE5My00OUE1LUI2QUYtQTk4OTcyNzU1RDVC',
    title: 'Demo 2'
  }
};

function PropertyDemo({
  profileKey,
  demoName,
  actions,
  webSocket,
  serviceAddress,
  siteConfig
}) {
  const initted = useRef();

  useEffect(() => {
    showLoading();
  }, []);

  useEffect(() => {
    if (!initted.current && webSocket.connected) {
      initted.current = true;

      actions.pageLoading('Preparing demo...');

      logIn('demo@movingin.com', 'MID3mo1984').then((result) => {
        if (
          result &&
          result.User &&
          result.User.Token &&
          result.PropertyList &&
          result.PropertyList.length
        ) {
          actions.userLoggedIn(result);

          const property = result.PropertyList.filter(
            (property) => property.ProfileKey === profileKey
          )[0];

          if (property) {
            setCookie(
              'mi-profile-key',
              profileKey + ':' + property.SampleResident_Address
            );

            selectSampleResident(
              actions.pageLoading,
              actions.serviceAddressUpdated,
              actions.selectUnit,
              property,
              actions.clearResidentOnboarding,
              siteConfig.id
            );
          }
        } else {
          setUserError(<>Invalid email address or password.</>);
        }
      });

      //showLoading();

      // if (go) {
      //   navigate(go + '?property=' + profileKey);
      // } else {
      //   propertyProfileApi
      //     .getPropertyProfile(profileKey)
      //     .then((_propertyProfile) => {
      //       if (
      //         _propertyProfile.ServiceProfile &&
      //         _propertyProfile.ServiceProfile.Header &&
      //         _propertyProfile.ServiceProfile.Header.Address &&
      //         _propertyProfile.ServiceProfile.SampleResident
      //       ) {
      //         dataApi.setCookie(
      //           'mi-profile-key',
      //           profileKey +
      //             ':' +
      //             _propertyProfile.ServiceProfile.Header.Address
      //         );

      //         dataApi.setCookie(
      //           'mi-invitation',
      //           btoa(_propertyProfile.ServiceProfile.Header.SampleInvitationKey)
      //         );

      //         if (
      //           !serviceAddress ||
      //           !serviceAddress.Address ||
      //           serviceAddress.Address.Address !==
      //             _propertyProfile.ServiceProfile.SampleResident.Address
      //         ) {
      //           dataApi
      //             .saveServiceAddress(
      //               _propertyProfile.ServiceProfile.SampleResident.Address,
      //               _propertyProfile.ServiceProfile.SampleResident.Secondary,
      //               _propertyProfile.ServiceProfile.SampleResident.City,
      //               _propertyProfile.ServiceProfile.SampleResident.State,
      //               _propertyProfile.ServiceProfile.SampleResident.Zip,
      //               10,
      //               _propertyProfile.ServiceProfile.SampleResident
      //                 .UniqueAddressId,
      //               _propertyProfile.ServiceProfile.SampleResident
      //                 .Previous_Address,
      //               _propertyProfile.ServiceProfile.SampleResident
      //                 .Previous_Secondary,
      //               _propertyProfile.ServiceProfile.SampleResident
      //                 .Previous_City,
      //               _propertyProfile.ServiceProfile.SampleResident
      //                 .Previous_State,
      //               _propertyProfile.ServiceProfile.SampleResident.Previous_Zip
      //             )
      //             .then((result) => {
      //               if (result && result.Address) {
      //                 updateServiceAddressWithResident(
      //                   { ...result },
      //                   _propertyProfile.ServiceProfile.SampleResident,
      //                   _propertyProfile.ServiceProfile.Header
      //                     .SampleInvitationKey
      //                 );
      //               }

      //               actions.pageLoading(false);
      //             });
      //         } else {
      //           //Make sure the sample resident info is set
      //           updateServiceAddressWithResident(
      //             { ...serviceAddress },
      //             _propertyProfile.ServiceProfile.SampleResident,
      //             _propertyProfile.ServiceProfile.Header.SampleInvitationKey
      //           );

      //           actions.pageLoading(false);
      //         }
      //       }
      //     });
      // }
    }
  }, [webSocket]);

  function showLoading() {
    actions.pageLoading(true, 'Preparing ' + demoName + '...');
  }

  function updateServiceAddressWithResident(
    _serviceAddress,
    _resident,
    invitationKey
  ) {
    actions.serviceAddressUpdated({
      ..._serviceAddress,
      //demo: true,
      Address: {
        ..._serviceAddress.Address,
        ResidentID: _resident.ResidentID,
        FirstName: _resident.FirstName,
        LastName: _resident.LastName,
        DefaultStartDate: _resident.DateLeaseStart,
        TenantId: _resident.TenantId,
        Email: _resident.Email,
        Phone: '555-555-2848',
        Previous_Address: _resident.Previous_Address,
        Previous_Secondary: _resident.Previous_Secondary,
        Previous_City: _resident.Previous_City,
        Previous_State: _resident.Previous_State,
        Previous_Zip: _resident.Previous_Zip
      }
    });
  }

  return serviceAddress && serviceAddress.Address ? (
    <Dashboard />
  ) : (
    <div className="page-container">
      <div className="page"></div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      ),
      userLoggedIn: bindActionCreators(userAuthActions.userLoggedIn, dispatch),
      selectUnit: bindActionCreators(userAuthActions.selectUnit, dispatch),
      clearResidentOnboarding: bindActionCreators(
        residentOnboardingActions.clearResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDemo);
