import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby-link';
import { withPrefix } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';

import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Alert,
  LinearProgress
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CanvasConfetti from '../CanvasConfetti';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import {
  QontoConnector,
  QontoStepIconRoot,
  QontoStepIcon,
  CircularProgressWithLabel,
  dayOfWeekList,
  businessHourList
} from '../ServiceProfileShared/config';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';
import * as userAuthActions from '../../redux/actions/userAuthActions';

import {
  getServiceProfile,
  listPartner,
  saveServiceProfile
} from '../../api/partnerDataApi';
import { listServiceProfile } from '../../api/dataApi';

import StepServicesRequired from '../ServiceProfileShared/StepServicesRequired';
import StepInternetOptions from '../ServiceProfileShared/StepInternetOptions';
import StepRentersInsurance from '../ServiceProfileShared/StepRentersInsurance';
import StepSchools from '../ServiceProfileShared/StepSchools';
import UserPropertySelect, { setSelectedResident } from '../UserPropertySelect';

import '../PropertyProfile/index.css';
import './index.css';

function ServiceAddressProfile({
  siteConfig,
  authUser,
  serviceAddress,
  actions,
  closeFunc
}) {
  const requestedAddress = useQueryParam('address', null);
  const requestedSecondary = useQueryParam('secondary', null);
  const requestedCity = useQueryParam('city', null);
  const requestedState = useQueryParam('state', null);
  const requestedZip = useQueryParam('zip', null);
  const requestedPropertyID = useQueryParam('propertyID', null);
  const [address, setAddress] = useState(null);
  const [partnerList, setPartnerList] = useState(null);
  const [serviceProfileList, setServiceProfileList] = useState(null);
  const [selectedServiceProfile, setSelectedServiceProfile] = useState(null);
  const [serviceProfile, setServiceProfile] = useState(null);
  const [activeStep, setActiveStep] = useState(null);
  const [justCompleted, setJustCompleted] = useState(null);
  const [isCompleted, setIsCompleted] = useState(null);
  const [alreadyCompleted, setAlreadyCompleted] = useState(null);
  const [showPropertySelect, setShowPropertySelect] = useState(null);

  useEffect(() => {
    if (
      authUser &&
      authUser.User &&
      authUser.User.Token &&
      authUser.User.IsAdmin
    ) {
      if (requestedAddress) {
        setAddress({
          Address: requestedAddress,
          Secondary: requestedSecondary,
          City: requestedCity,
          State: requestedState,
          Zip: requestedZip,
          PropertyID: requestedPropertyID
        });
      } else if (serviceAddress && serviceAddress.Address) {
        setAddress({ ...serviceAddress.Address });
      }
    }
  }, [requestedAddress, authUser]);

  useEffect(() => {
    if (!serviceProfileList && address) {
      loadServiceProfileList().then((result) =>
        listPartner().then((_partnerList) =>
          setPartnerList(
            _partnerList.filter(
              (_partner) => _partner.BrandId === siteConfig.id
            )
          )
        )
      );
    }
  }, [address]);

  useEffect(() => {
    if (selectedServiceProfile) {
      loadServiceProfile();
    }
  }, [selectedServiceProfile]);

  useEffect(() => {
    if (
      serviceProfileList &&
      !serviceProfileList.length &&
      !selectedServiceProfile &&
      !serviceProfile
    ) {
      setSelectedServiceProfile({ ServiceProfileID: 0 });
    }
  }, [serviceProfileList]);

  useEffect(() => {
    if (serviceProfile && serviceProfile.Header && activeStep === null) {
      if (serviceProfile.Header.ServiceProfileStatusID > 1) {
        setActiveStep(999);
        setIsCompleted(true);
        setAlreadyCompleted(true);
      } else {
        setActiveStep(
          serviceProfile.Header.ActiveStep
            ? serviceProfile.Header.ActiveStep
            : 0
        );
      }
    }
  }, [serviceProfile]);

  function loadServiceProfileList() {
    return listServiceProfile(
      address.Address,
      address.Secondary,
      address.City,
      address.State,
      address.Zip,
      12
    ).then((_serviceProfileList) => setServiceProfileList(_serviceProfileList));
  }

  function loadServiceProfile() {
    actions.pageLoading(true, 'Loading address details...');

    getServiceProfile(
      address.Address,
      address.Secondary,
      address.City,
      address.State,
      address.Zip,
      selectedServiceProfile.ServiceProfileID,
      address.PropertyID
    ).then((_serviceProfile) => {
      setServiceProfile(_serviceProfile);
      actions.pageLoading(false);
    });
  }

  function setProviderServiceValue(
    providerService,
    options,
    skipActiveStepCheck
  ) {
    setServiceProfile({
      ...serviceProfile,
      ServiceSectionList: serviceProfile.ServiceSectionList.map(
        (step, stepIndex) =>
          stepIndex === activeStep || skipActiveStepCheck
            ? {
                ...step,
                ProviderServiceList: step.ProviderServiceList.map(
                  (_providerService) =>
                    _providerService.ProviderServiceID ===
                    providerService.ProviderServiceID
                      ? {
                          ..._providerService,
                          ...options
                        }
                      : { ..._providerService }
                )
              }
            : { ...step }
      )
    });
  }

  function setProviderValue(provider, options) {
    setServiceProfile({
      ...serviceProfile,
      ServiceSectionList: serviceProfile.ServiceSectionList.map(
        (step, stepIndex) =>
          stepIndex === activeStep
            ? {
                ...step,
                ProviderList: step.ProviderList.map((_provider) =>
                  _provider.ProviderID === provider.ProviderID
                    ? {
                        ..._provider,
                        ...options
                      }
                    : { ..._provider }
                )
              }
            : { ...step }
      )
    });
  }

  return authUser &&
    authUser.User &&
    authUser.User.Token &&
    address &&
    address.Address ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="form-dialog-title"
        className={'service-profile-popup'}
        fullScreen>
        <DialogTitle id="form-dialog-title">
          <img
            src={withPrefix('/images/logo-icon.svg')}
            className="logo-icon"
          />
          Service Profile for {address.Address}{' '}
          {address.Secondary ? ' ' + address.Secondary : ''}
          {closeFunc ? (
            <IconButton
              style={{ float: 'right', marginTop: '-20px' }}
              aria-label="close"
              onClick={() => closeFunc()}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent width="100%">
          {serviceProfile && partnerList ? (
            <div className="service-profile-container service-address">
              <Stack sx={{ width: '100%' }} spacing={4}>
                <Stepper
                  alternativeLabel
                  activeStep={
                    alreadyCompleted
                      ? serviceProfile.ServiceSectionList.length
                      : activeStep
                  }
                  className={alreadyCompleted ? 'completed' : ''}
                  connector={<QontoConnector />}>
                  {serviceProfile.ServiceSectionList.map((step, stepIndex) => (
                    <Step
                      key={step.ServiceSectionID}
                      onClick={() => {
                        setJustCompleted(false);
                        setIsCompleted(false);
                        setActiveStep(stepIndex);
                      }}
                      className={
                        activeStep === stepIndex && !isCompleted ? 'active' : ''
                      }>
                      <StepLabel StepIconComponent={QontoStepIcon}>
                        {step.ServiceSectionName}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Stack>

              <div className="form">
                {!isCompleted ? (
                  serviceProfile.ServiceSectionList[activeStep] ? (
                    serviceProfile.ServiceSectionList[activeStep]
                      .ServiceSectionID === -3 ? (
                      <div className="section overview">
                        <h3>Overview</h3>
                        <div className="form-content">
                          <p>
                            Completing this service profile should only take a
                            few minutes and will ensure that we have accurate
                            and up-to-date information about this address.
                          </p>
                          <p>
                            Should you have any questions or need further
                            assistance, please don't hesitate to reach out to
                            our support team at support@movingin.com.
                          </p>
                          <p>
                            Thank you for choosing us as your partner. We look
                            forward to enhancing the moving in experience with
                            our services.
                          </p>
                          <p>
                            <strong>
                              Please fill out the fields below, and then click
                              the pink button at the bottom to continue to the
                              next step. The changes you make here will not be
                              active for this address until you complete the
                              final step.
                            </strong>
                          </p>

                          <TextField
                            label="Service Profile Name"
                            placeholder="Enter a name to describe the target user"
                            value={serviceProfile.Header.ServiceProfileName}
                            fullWidth
                            onChange={(event) =>
                              setServiceProfile({
                                ...serviceProfile,
                                Header: {
                                  ...serviceProfile.Header,
                                  ServiceProfileName: event.target.value
                                }
                              })
                            }
                          />

                          <FormControl fullWidth className="user-role">
                            <InputLabel>Partner</InputLabel>
                            <Select
                              label="Partner"
                              value={serviceProfile.Header.PartnerID}
                              onChange={(event) =>
                                setServiceProfile({
                                  ...serviceProfile,
                                  Header: {
                                    ...serviceProfile.Header,
                                    PartnerID: event.target.value
                                  }
                                })
                              }>
                              <MenuItem value={0}>Not Specified</MenuItem>
                              {partnerList.map((partner) => (
                                <MenuItem value={partner.PartnerID}>
                                  {partner.PartnerName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <p>
                            Is this service profile for a user who will be
                            owning or renting this property?
                          </p>
                          <FormControl
                            component="fieldset"
                            className="service-order-type">
                            <RadioGroup
                              aria-label="serviceOrderType"
                              name="serviceOrderType"
                              value={serviceProfile.Header.Role}
                              onChange={(event) =>
                                setServiceProfile({
                                  ...serviceProfile,
                                  Header: {
                                    ...serviceProfile.Header,
                                    Role: event.target.value
                                  }
                                })
                              }>
                              <FormControlLabel
                                value="Owner"
                                className={
                                  serviceProfile.Header.Role === 'Owner'
                                    ? 'selected'
                                    : ''
                                }
                                control={<Radio color="primary" />}
                                label="Owner"
                              />

                              <FormControlLabel
                                value="Renter"
                                className={
                                  serviceProfile.Header.Role === 'Renter'
                                    ? 'selected'
                                    : ''
                                }
                                control={<Radio color="primary" />}
                                label="Renter"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    ) : serviceProfile.ServiceSectionList[activeStep]
                        .ServiceSectionID === 1 ? (
                      <div className="form">
                        <StepServicesRequired
                          serviceProfile={serviceProfile}
                          activeStep={activeStep}
                          setProviderServiceValue={setProviderServiceValue}
                          alreadyCompleted={alreadyCompleted}
                          setServiceProfile={setServiceProfile}
                        />
                      </div>
                    ) : serviceProfile.ServiceSectionList[activeStep]
                        .ServiceSectionID === 2 ? (
                      <div className="form">
                        <StepInternetOptions
                          serviceProfile={serviceProfile}
                          activeStep={activeStep}
                          setProviderServiceValue={setProviderServiceValue}
                          setServiceProfile={setServiceProfile}
                        />
                      </div>
                    ) : serviceProfile.ServiceSectionList[activeStep]
                        .ServiceSectionID === 4 ? (
                      <div className="form">
                        {serviceProfile.Header.Role === 'Owner' ? (
                          <>Owner insurance options here</>
                        ) : (
                          <StepRentersInsurance
                            serviceProfile={serviceProfile}
                            activeStep={activeStep}
                            setProviderServiceValue={setProviderServiceValue}
                            setServiceProfile={setServiceProfile}
                            providerService={
                              serviceProfile.ServiceSectionList[
                                activeStep
                              ].ProviderServiceList.filter(
                                (providerService) =>
                                  providerService.ProviderServiceID === 37
                              )[0]
                            }
                            setProviderValue={setProviderValue}
                            alreadyCompleted={alreadyCompleted}
                          />
                        )}
                      </div>
                    ) : serviceProfile.ServiceSectionList[activeStep]
                        .ServiceSectionID === -1 ? (
                      <div className="form">
                        <StepSchools
                          serviceProfile={serviceProfile}
                          activeStep={activeStep}
                          setProviderServiceValue={setProviderServiceValue}
                          setServiceProfile={setServiceProfile}
                        />
                      </div>
                    ) : null
                  ) : null
                ) : (
                  <>
                    {!alreadyCompleted ? <CanvasConfetti /> : null}
                    <div className="section complete">
                      <div className="form-content">
                        <p>
                          <strong>
                            This service profile is complete.
                            <br />
                            <br />
                            Click the button below to test the resident
                            dashboard,
                            <br />
                            or click on any section above to make additional
                            edits.
                          </strong>

                          <Button
                            variant="outlined"
                            onClick={() => {
                              if (serviceProfile.SampleResident) {
                                setSelectedResident(
                                  actions.pageLoading,
                                  actions.serviceAddressUpdated,
                                  actions.selectUnit,
                                  null,
                                  serviceProfile.SampleResident.Address,
                                  serviceProfile.SampleResident.Secondary,
                                  serviceProfile.SampleResident.City,
                                  serviceProfile.SampleResident.State,
                                  serviceProfile.SampleResident.Zip,
                                  serviceProfile.SampleResident.UniqueAddressId,
                                  serviceProfile.SampleResident
                                    .Previous_Address,
                                  serviceProfile.SampleResident
                                    .Previous_Secondary,
                                  serviceProfile.SampleResident.Previous_City,
                                  serviceProfile.SampleResident.Previous_State,
                                  serviceProfile.SampleResident.Previous_Zip,
                                  serviceProfile.SampleResident.ResidentID,
                                  serviceProfile.SampleResident.FirstName,
                                  serviceProfile.SampleResident.LastName,
                                  serviceProfile.SampleResident.Email,
                                  serviceProfile.SampleResident.Phone,
                                  serviceProfile.SampleResident.DateLeaseStart,
                                  serviceProfile.SampleResident.TenantId,
                                  actions.clearResidentOnboarding,
                                  serviceProfile.Header.Role
                                );
                              } else {
                                setShowPropertySelect(true);
                              }
                            }}>
                            View Resident Dashboard
                          </Button>
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : serviceProfileList && serviceProfileList.length && partnerList ? (
            <div className="service-profile-list-container">
              <div className="service-profile-list">
                <p>
                  <strong>
                    Please select a service profile from the list below.
                  </strong>
                </p>

                <TableContainer>
                  <Table aria-label="simple table" stickyHeader={true}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Service Profile</TableCell>
                        <TableCell>Partner</TableCell>
                        <TableCell>Role</TableCell>

                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {serviceProfileList.map((_serviceProfile) => (
                        <>
                          <TableRow key={_serviceProfile.ServiceProfileID}>
                            <TableCell>
                              {_serviceProfile.ServiceProfileName ? (
                                _serviceProfile.ServiceProfileName
                              ) : (
                                <em>In Progress</em>
                              )}
                            </TableCell>
                            <TableCell>{_serviceProfile.PartnerName}</TableCell>
                            <TableCell>{_serviceProfile.Role}</TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  setSelectedServiceProfile({
                                    ..._serviceProfile
                                  })
                                }>
                                Edit
                              </Button>
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Button
                  variant="contained"
                  className="add-new"
                  color="primary"
                  onClick={() =>
                    setSelectedServiceProfile({
                      ServiceProfileID: 0
                    })
                  }>
                  Add New
                </Button>
              </div>
            </div>
          ) : (
            <LinearProgress />
          )}
        </DialogContent>
        <DialogActions style={{ display: 'block', textAlign: 'right' }}>
          {serviceProfile && !isCompleted ? (
            <Button
              onClick={() => {
                actions.pageLoading(true, 'Saving...');
                saveServiceProfile(
                  { ...serviceProfile },
                  activeStep < serviceProfile.ServiceSectionList.length - 1
                    ? false
                    : true
                ).then((result) => {
                  actions.pageLoading(false);

                  if (
                    activeStep <
                    serviceProfile.ServiceSectionList.length - 1
                  ) {
                    setActiveStep(activeStep + 1);
                  } else {
                    setIsCompleted(true);
                    setJustCompleted(true);
                    setActiveStep(999);
                  }
                });
              }}
              className="submit"
              variant="contained"
              disabled={
                !activeStep &&
                (!serviceProfile.Header.ServiceProfileName ||
                  serviceProfile.Header.PartnerID === undefined ||
                  !serviceProfile.Header.Role)
              }
              color="secondary">
              {activeStep >= serviceProfile.ServiceSectionList.length - 1 ? (
                'Save & Finish'
              ) : (
                <>
                  Save & Continue <ChevronRightIcon />
                </>
              )}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>

      {showPropertySelect ? (
        <UserPropertySelect
          selectedProperty={serviceProfile.Header.PropertyID}
          closeFunc={() => setShowPropertySelect(null)}
        />
      ) : null}
    </>
  ) : null;
}

const mapStateToProps = (state) => ({
  serviceAddress: state.serviceAddress,
  authUser: state.authUser,
  siteConfig: state.commonData.siteConfig
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      ),
      selectUnit: bindActionCreators(userAuthActions.selectUnit, dispatch),
      clearResidentOnboarding: bindActionCreators(
        residentOnboardingActions.clearResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceAddressProfile);
