import React, { useState, useRef, useEffect, version } from 'react';
import ReactDOMServer from 'react-dom/server';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PetsIcon from '@mui/icons-material/Pets';

import { Alert } from '@material-ui/lab';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress
} from '@mui/material';
import { saveResidentPetNotes } from '../../../api/propertyProfileApi';
import { scrollToElementInPage } from '../../../api/dataApi';
import {
  listResidentEvent,
  createResidentEvent,
  updateResidentEvent,
  deleteResidentEvent,
  updateResidentFile
} from '../../../api/residentOnboardingApi';

import * as webSocketActions from '../../../redux/actions/webSocketActions';
import * as residentOnboardingActions from '../../../redux/actions/residentOnboardingActions';

import ResidentOnboardingFileUpload from '../ResidentOnboardingFileUpload';
import GeneralPopup from '../../GeneralPopup';

import { getImageDownloadUrl } from '../ResidentOnboardingPhotoUpload';

import 'react-dropzone-uploader/dist/styles.css';
import './index.css';
import moment from 'moment';

function ResidentEventList({
  actions,
  serviceAddress,
  siteConfig,
  residentOnboarding,
  refreshResidentOnboarding,
  residentEventTypeID,
  residentID,
  pet,
  authUser,
  afterSaveSuccess,
  afterSaveCancel,
  children,
  className,
  setOutputResidentEventList
}) {
  const [residentEventList, setResidentEventList] = useState(null);
  const [editEvent, setEditEvent] = useState(null);
  const [deleteEvent, setDeleteEvent] = useState(null);
  const [uploadedFileList, setUploadedFileList] = useState([]);

  useEffect(() => {
    if (residentID) {
      loadEventList();
    }
  }, []);

  useEffect(() => {
    if (residentEventList) {
      if (setOutputResidentEventList) {
        setOutputResidentEventList(
          residentEventList.map((event) => {
            return { ...event };
          })
        );
      }
    }
  }, [residentEventList]);

  function loadEventList() {
    listResidentEvent(residentID).then((_eventList) => {
      setResidentEventList(_eventList);
    });
  }

  function afterEventSave(result) {
    if (afterSaveSuccess) {
      afterSaveSuccess();
    }

    setEditEvent(null);
    setUploadedFileList([]);
    loadEventList();
  }

  return (
    <div className={'resident-event-list' + (className ? ' ' + className : '')}>
      {residentEventList ? (
        residentEventList.filter((event) =>
          event.ResidentEventTypeID === residentEventTypeID && pet
            ? event.ResidentPetID === pet.ResidentPetID
            : true
        ).length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '175px' }}>Date</TableCell>
                <TableCell>Action Taken</TableCell>
                {authUser && authUser.User && authUser.User.IsAdmin ? (
                  <TableCell style={{ width: '120px' }}></TableCell>
                ) : null}
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {residentEventList
                .filter((event) =>
                  event.ResidentEventTypeID === residentEventTypeID && pet
                    ? event.ResidentPetID === pet.ResidentPetID
                    : true
                )
                .map((event) => (
                  <TableRow>
                    <TableCell style={{ verticalAlign: 'top' }}>
                      {moment
                        .utc(event.Created)
                        .local()
                        .format('MM/DD/YYYY h:mm a')}
                    </TableCell>
                    <TableCell style={{ verticalAlign: 'top' }}>
                      {event.EventDescription}
                    </TableCell>
                    {authUser && authUser.User && authUser.User.IsAdmin ? (
                      <TableCell
                        className="actions"
                        style={{ verticalAlign: 'top', paddingTop: '5px' }}>
                        <IconButton onClick={() => setEditEvent({ ...event })}>
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => setDeleteEvent({ ...event })}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    ) : null}
                    <TableCell style={{ verticalAlign: 'top' }}>
                      {event.DateDropped
                        ? 'Bounced'
                        : event.DateRead
                        ? 'Read'
                        : event.DateDelivered
                        ? 'Delivered'
                        : event.ProcessorMessageId
                        ? 'Sent'
                        : ''}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ) : (
          <p>No actions taken on this accommodation request.</p>
        )
      ) : (
        <LinearProgress />
      )}

      {deleteEvent ? (
        <GeneralPopup
          title={'Delete Event'}
          message={
            <p style={{ marginTop: '20px' }}>
              Are you sure you want to delete this event? Deleting cannot be
              undone.
            </p>
          }
          className={
            'resident-event-edit-popup' + (className ? ' ' + className : '')
          }
          submitLabel={'Delete Event'}
          submitFunc={() => {
            deleteResidentEvent(residentID, deleteEvent.ResidentEventID).then(
              () => {
                setDeleteEvent(null);
                loadEventList();
              }
            );
          }}
          closeFunc={() => {
            setDeleteEvent(null);
          }}
          closeLabel={'Cancel'}
        />
      ) : editEvent ? (
        <GeneralPopup
          title={'Record Event'}
          message={
            <div className="response-form">
              <div className="field-container">
                <TextField
                  variant="outlined"
                  fullWidth
                  label={'Name'}
                  value={editEvent.Name ? editEvent.Name : ''}
                  onChange={(event) =>
                    setEditEvent({ ...editEvent, Name: event.target.value })
                  }
                />
              </div>

              <div className="field-container">
                <TextField
                  variant="outlined"
                  fullWidth
                  label={'Company'}
                  value={editEvent.Company ? editEvent.Company : ''}
                  onChange={(event) =>
                    setEditEvent({ ...editEvent, Company: event.target.value })
                  }
                />
              </div>

              <div className="field-container">
                <TextField
                  variant="outlined"
                  fullWidth
                  type="email"
                  label={'Email'}
                  value={editEvent.Email ? editEvent.Email : ''}
                  onChange={(event) =>
                    setEditEvent({ ...editEvent, Email: event.target.value })
                  }
                />
              </div>

              <div className="field-container">
                <InputMask
                  mask="999-999-9999"
                  maskChar={'_'}
                  value={editEvent.PhoneNumber ? editEvent.PhoneNumber : ''}
                  onChange={(event) =>
                    setEditEvent({
                      ...editEvent,
                      PhoneNumber: event.target.value
                    })
                  }>
                  {() => (
                    <TextField
                      fullWidth
                      label={'Phone Number'}
                      inputProps={{
                        inputMode: 'numeric'
                      }}
                    />
                  )}
                </InputMask>
              </div>

              <TextField
                required
                variant="outlined"
                fullWidth
                multiline
                label={
                  'Describe Event - this text is for public view and will appear in timeline'
                }
                value={
                  editEvent.EventDescription ? editEvent.EventDescription : ''
                }
                onChange={(event) =>
                  setEditEvent({
                    ...editEvent,
                    EventDescription: event.target.value
                  })
                }
              />

              <TextField
                variant="outlined"
                fullWidth
                multiline
                label={
                  'Private Note - this text is only viewable by ' +
                  siteConfig.brand
                }
                value={editEvent.PrivateNote ? editEvent.PrivateNote : ''}
                onChange={(event) =>
                  setEditEvent({
                    ...editEvent,
                    PrivateNote: event.target.value
                  })
                }
              />

              <ResidentOnboardingFileUpload
                residentOnboarding={residentOnboarding}
                refreshResidentOnboarding={refreshResidentOnboarding}
                residentEventID={editEvent.ResidentEventID}
                residentFileTypeID={11}
                label={'Document or Attachment'}
                residentID={residentID}
                requireFileName={true}
                optional
                uploadedFileList={uploadedFileList}
                setUploadedFileList={setUploadedFileList}
              />

              {children}
            </div>
          }
          className={
            'resident-event-edit-popup' + (className ? ' ' + className : '')
          }
          submitLabel={'Save Event'}
          submitFunc={() => {
            (editEvent.ResidentEventID
              ? updateResidentEvent(residentID, { ...editEvent })
              : createResidentEvent(residentID, { ...editEvent })
            ).then((result) => {
              if (uploadedFileList && uploadedFileList.length) {
                updateResidentFile(
                  residentID,
                  uploadedFileList,
                  result.ResidentEventID
                ).then((result) => {
                  afterEventSave(result);
                });
              } else {
                afterEventSave(result);
              }
            });
          }}
          submitDisabled={!editEvent.EventDescription}
          closeFunc={() => {
            if (afterSaveCancel) {
              afterSaveCancel();
            }
            setEditEvent(null);
            setUploadedFileList([]);
          }}
          closeLabel={'Cancel'}
        />
      ) : authUser &&
        authUser.User &&
        authUser.User.Token &&
        authUser.User.IsAdmin ? (
        <div className="add-response-button">
          <Button
            variant="contained"
            color="primary"
            style={{ marginBottom: '20px' }}
            onClick={() =>
              setEditEvent({
                ResidentEventID: 0,
                ResidentEventTypeID: residentEventTypeID,
                ResidentID: residentID,
                ResidentPetID: pet ? pet.ResidentPetID : null
              })
            }>
            Record Event
          </Button>
        </div>
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    stateList: state.commonData.stateList,
    authUser: state.authUser,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResidentEventList);
