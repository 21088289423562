import React, { useState, useRef, useEffect, version } from 'react';
import moment from 'moment';
import { withPrefix } from 'gatsby-link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Alert,
  LinearProgress,
  TextField,
  Tooltip,
  Checkbox,
  IconButton,
  Switch,
  FormControlLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';

import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';

import {
  listResidentWalkthroughItem,
  saveWalkthroughChecklist,
  saveResidentWalkthroughStatus
} from '../../../api/residentOnboardingApi';

import * as webSocketActions from '../../../redux/actions/webSocketActions';

import ResidentOnboardingStatusBadge from '../ResidentOnboardingStatusBadge';
import ResidentOnboardingPhotoUpload from '../ResidentOnboardingPhotoUpload';
import ResidentOnboardingStatusUpdate from '../ResidentOnboardingStatusUpdate';
import SignaturePad from '../../SignaturePad';

import './index.css';

function ResidentWalkthrough({
  actions,
  serviceAddress,
  siteConfig,
  residentOnboarding,
  refreshResidentOnboarding,
  residentID,
  walkthrough,
  closeOnboardingRow
}) {
  const [itemList, setItemList] = useState(null);
  const [editMode, setEditMode] = useState(
    walkthrough.ResidentOnboardingStatusID === 1
  );
  const [residentStatusUpdate, setResidentStatusUpdate] = useState(null);
  const [showSubmit, setShowSubmit] = useState(null);
  const [submitSettings, setSubmitSettings] = useState({});
  const submitSettingsRef = useRef();

  submitSettingsRef.current = submitSettings;

  useEffect(() => {
    if (walkthrough) {
      loadItemList();
    }
  }, [walkthrough]);

  function loadItemList() {
    listResidentWalkthroughItem(
      walkthrough.ResidentWalkthroughID,
      residentID
    ).then((result) => setItemList(result));
  }

  return walkthrough && itemList ? (
    <div className="resident-onboarding-item walkthrough">
      <div className="form-header">
        <ResidentOnboardingStatusBadge
          status={walkthrough}
          isProperty={residentID ? true : false}
        />

        <h3>{walkthrough.Description}</h3>

        <div className="save-button">
          {editMode ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setShowSubmit(true);
                }}>
                Save
              </Button>

              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  loadItemList();
                  setEditMode(false);
                }}>
                Cancel
              </Button>
            </>
          ) : residentID && walkthrough.ResidentOnboardingStatusID === 2 ? (
            <>
              <Button
                variant="contained"
                className="accept"
                color="primary"
                onClick={() => {
                  setResidentStatusUpdate({
                    dataTypeLabel: walkthrough.Description,
                    residentOnboardingStatusID: 3
                  });
                }}>
                <DoneIcon /> Accept
              </Button>

              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setResidentStatusUpdate({
                    dataTypeLabel: walkthrough.Description,
                    residentOnboardingStatusID: 1
                  });
                }}>
                <ClearIcon /> Reject
              </Button>
            </>
          ) : null}
          {!editMode ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setEditMode(true)}>
              Edit
            </Button>
          ) : null}
        </div>

        {walkthrough.ResidentOnboardingStatusNote &&
        walkthrough.ResidentOnboardingStatusID !== 2 ? (
          <Alert
            severity={
              walkthrough.ResidentOnboardingStatusID === 3
                ? 'success'
                : 'warning'
            }>
            Note from Property: {walkthrough.ResidentOnboardingStatusNote}
          </Alert>
        ) : walkthrough.ResidentOnboardingStatusNote &&
          walkthrough.ResidentOnboardingStatusID === 2 ? (
          <Alert
            severity={
              walkthrough.ResidentOnboardingStatusID === 3
                ? 'success'
                : 'warning'
            }>
            Note from Resident: {walkthrough.ResidentOnboardingStatusNote}
          </Alert>
        ) : null}
      </div>

      {itemList.length ? (
        <div
          className={'walkthrough-checklist' + (!editMode ? ' disabled' : '')}>
          <Alert severity="info">
            You have five (5) days after your Lease Start Date to note all
            defects or damage to your unit on this form and return it to our
            representative. Otherwise, everything will be considered to be in
            Good and Working Condition. This form protects both you (the
            resident) and us (the owner). We’ll use it in determining what
            should and should not be considered your responsibility upon
            move-out. You are entitled to a copy of this form after it is filled
            out and signed by you and us.
          </Alert>

          <ol>
            {itemList.map((item, itemIndex) => (
              <>
                {itemIndex === 0 ||
                item.WalkthroughCategoryName !==
                  itemList[itemIndex - 1].WalkthroughCategoryName ? (
                  <li className="title">
                    <div className="item-name">
                      {item.WalkthroughCategoryName}
                    </div>
                  </li>
                ) : null}

                <li>
                  <div className="item-name">
                    {item.WalkthroughChecklistItemName}
                  </div>
                  <div className="toggle">
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={!editMode}
                          key={'switch_' + item.ResidentWalkthroughItemID}
                          color="primary"
                          checked={item.HasIssue}
                          onChange={(event) =>
                            setItemList(
                              itemList.map((_item) =>
                                _item.ResidentWalkthroughItemID ===
                                item.ResidentWalkthroughItemID
                                  ? {
                                      ..._item,
                                      HasIssue: event.target.checked,
                                      isChanged: true
                                    }
                                  : { ..._item }
                              )
                            )
                          }
                        />
                      }
                      label={item.HasIssue ? 'ISSUE' : 'No Issue'}
                      labelPlacement="start"
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </div>

                  {item.HasIssue ? (
                    <>
                      <div className="notes">
                        <TextField
                          key={'textboxNote_' + item.ResidentWalkthroughItemID}
                          multiline
                          disabled={!editMode}
                          autoFocus={item.isChanged}
                          fullWidth
                          required
                          variant="standard"
                          value={item.Notes}
                          placeholder={'Describe Issue (required)'}
                          onChange={(event) =>
                            setItemList(
                              itemList.map((_item) =>
                                _item.ResidentWalkthroughItemID ===
                                item.ResidentWalkthroughItemID
                                  ? { ..._item, Notes: event.target.value }
                                  : { ..._item }
                              )
                            )
                          }
                        />
                      </div>

                      <div className="attachments">
                        <Tooltip title="Attach Photos">
                          <IconButton
                            disabled={!editMode}
                            onClick={() =>
                              setItemList(
                                itemList.map((_item) =>
                                  _item.ResidentWalkthroughItemID ===
                                  item.ResidentWalkthroughItemID
                                    ? {
                                        ..._item,
                                        showFiles: !_item.showFiles
                                      }
                                    : { ..._item }
                                )
                              )
                            }>
                            <CameraAltIcon />
                          </IconButton>
                        </Tooltip>

                        <FormControlLabel
                          key={'checkbox' + item.ResidentWalkthroughItemID}
                          control={
                            <Checkbox
                              disabled={!editMode}
                              checked={item.IsRequestService}
                              onChange={(event) =>
                                setItemList(
                                  itemList.map((_item) =>
                                    _item.ResidentWalkthroughItemID ===
                                    item.ResidentWalkthroughItemID
                                      ? {
                                          ..._item,
                                          IsRequestService: event.target.checked
                                        }
                                      : { ..._item }
                                  )
                                )
                              }
                            />
                          }
                          label="Request Service"
                        />
                      </div>

                      {item.showFiles ? (
                        <>
                          <IconButton
                            className="close"
                            onClick={() =>
                              setItemList(
                                itemList.map((_item) =>
                                  _item.ResidentWalkthroughItemID ===
                                  item.ResidentWalkthroughItemID
                                    ? {
                                        ..._item,
                                        showFiles: false
                                      }
                                    : { ..._item }
                                )
                              )
                            }>
                            <CloseIcon />
                          </IconButton>
                          <ResidentOnboardingPhotoUpload
                            residentOnboarding={residentOnboarding}
                            refreshResidentOnboarding={
                              refreshResidentOnboarding
                            }
                            residentWalkthroughItemID={
                              item.ResidentWalkthroughItemID
                            }
                            residentFileTypeID={6}
                            label={'Issue Photos'}
                            disabled={!editMode}
                            residentID={residentID}
                          />
                        </>
                      ) : null}
                    </>
                  ) : null}
                </li>
              </>
            ))}
          </ol>
        </div>
      ) : (
        <div className="walkthrough-scheduled">
          {residentID ? (
            <p style={{ textAlign: 'left' }}>
              {walkthrough.ResidentOnboardingStatusID === 4 ? (
                <>
                  MovingIN emailed the tenant on{' '}
                  {moment(residentOnboarding.Header.DateLeaseStart).format(
                    'M/D/YYYY'
                  )}{' '}
                  reminding them of their responsibility to complete and submit
                  a Move-in Condition Form. But the tenant did not respond or
                  submit the form in the time allotted, so per the terms of
                  their lease, the unit is assumed to be in good and working
                  condition.
                </>
              ) : (
                <>
                  Tenant will receive an email from {siteConfig.brand} on{' '}
                  {moment(residentOnboarding.Header.DateLeaseStart).format(
                    'M/D/YYYY'
                  )}{' '}
                  reminding them of their responsibility to complete a Move-In
                  Condition Form by{' '}
                  {moment(residentOnboarding.Header.DateLeaseStart)
                    .add(5, 'days')
                    .format('M/D/YYYY')}
                  . Once they have completed and submitted this form, the
                  Available button above will change to a Ready to Review
                  button. Then, once you review and accept the submittal, a hard
                  copy PDF will be emailed to the tenant and another copy will
                  be added to the documents folder.
                </>
              )}
            </p>
          ) : (
            <>
              Walk-through scheduled for
              <strong>
                {' '}
                {moment(residentOnboarding.Header.DateLeaseStart).format(
                  'M/D/YYYY'
                )}
              </strong>
            </>
          )}
        </div>
      )}

      {residentStatusUpdate ? (
        <ResidentOnboardingStatusUpdate
          type="walkthrough"
          dataTypeLabel={'Move-in Condition Form'}
          residentOnboardingStatusID={
            residentStatusUpdate.residentOnboardingStatusID
          }
          saveFunc={(note) => {
            saveResidentWalkthroughStatus(
              residentID,
              walkthrough.ResidentWalkthroughID,
              residentStatusUpdate.residentOnboardingStatusID,
              note
            ).then((result) => {
              refreshResidentOnboarding();
              setResidentStatusUpdate(null);
              closeOnboardingRow();
            });
          }}
          closeFunc={() => setResidentStatusUpdate(null)}
        />
      ) : null}

      {showSubmit ? (
        <Dialog
          open={true}
          aria-labelledby="form-dialog-title"
          className="resident-walkthrough-submit">
          <DialogTitle id="form-dialog-title">
            <IconButton
              style={{ float: 'right' }}
              aria-label="close"
              onClick={() => {
                setShowSubmit(null);
              }}>
              <CloseIcon />
            </IconButton>
            <img
              src={withPrefix('/images/logo-icon.svg')}
              className="logo-icon"
            />
            Sign and Submit {walkthrough.Description}
          </DialogTitle>
          <DialogContent width="100%">
            <TextField
              multiline
              label="Additional notes or comments before submitting"
              fullWidth
              value={submitSettings.note}
              onChange={(event) =>
                setSubmitSettings({
                  ...submitSettings,
                  note: event.target.value
                })
              }
              variant="outlined"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={submitSettings.auth1}
                  onChange={(event) =>
                    setSubmitSettings({
                      ...submitSettings,
                      auth1: event.target.checked
                    })
                  }
                />
              }
              label={
                <>
                  You agree that this {walkthrough.Description} accurately
                  reflects the condition of the apartment when delivered to you.
                  All items above will be considered to be in good and working
                  condition unless otherwise noted.
                </>
              }
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={submitSettings.auth2}
                  onChange={(event) =>
                    setSubmitSettings({
                      ...submitSettings,
                      auth2: event.target.checked
                    })
                  }
                />
              }
              label={
                <>
                  In signing below, you are agreeing to add this form to the
                  existing Lease Contract and understand that it may be used in
                  determining any refund due you at move out.
                </>
              }
            />

            <TextField
              label="Today's date"
              fullWidth
              disabled
              value={moment(new Date()).format('M/D/YYYY')}
              variant="outlined"
            />

            <TextField
              label="Type your name"
              required
              fullWidth
              value={submitSettings.name}
              onChange={(event) =>
                setSubmitSettings({
                  ...submitSettings,
                  name: event.target.value
                })
              }
              variant="outlined"
            />

            <SignaturePad
              label={'Sign your name'}
              onChange={(hasValue, data) => {
                console.log(hasValue);
                setSubmitSettings({
                  ...submitSettingsRef.current,
                  hasSignature: hasValue,
                  signature: data
                });
              }}
            />
          </DialogContent>
          <DialogActions style={{ display: 'block', textAlign: 'right' }}>
            <Button
              onClick={() => {
                actions.pageLoading(true, 'Saving...');
                saveWalkthroughChecklist(
                  walkthrough.ResidentWalkthroughID,
                  residentID,
                  itemList,
                  submitSettings.name,
                  submitSettings.note,
                  JSON.stringify(submitSettings.signature)
                ).then((result) => {
                  refreshResidentOnboarding();
                  setShowSubmit(null);
                  actions.pageLoading(false);
                });
                setEditMode(false);
              }}
              disabled={
                !submitSettings.auth1 ||
                !submitSettings.auth2 ||
                !submitSettings.name ||
                !submitSettings.signature
              }
              variant="contained"
              color="secondary">
              Submit Form
            </Button>
            <Button
              onClick={() => {
                setShowSubmit(null);
              }}
              variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  ) : (
    <LinearProgress style={{ marginTop: '10px', marginBottom: '10px' }} />
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResidentWalkthrough);
