import React, { useState, useRef, useEffect, version } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import IntroText from '../IntroText';

import ApplicantItemCard from './ApplicantItemCard';

import '../OfferGrid/index.css';
import './index.css';

function ApplicantTasks({ residentOnboarding }) {
  function getItemList() {
    let _itemDictionary = {};

    residentOnboarding.Checklist.filter(
      (item) =>
        item.OnboardingSection === 'applicant' && item.Grouping !== 'pet-esa'
    ).map((item) => {
      if (!_itemDictionary[item.OnboardingColumn]) {
        _itemDictionary[item.OnboardingColumn] = { ...item, itemCount: 1 };
      } else {
        _itemDictionary[item.OnboardingColumn].itemCount++;

        if (
          _itemDictionary[item.OnboardingColumn].ResidentOnboardingStatusID >
            item.ResidentOnboardingStatusID ||
          (_itemDictionary[item.OnboardingColumn].ResidentOnboardingStatusID ===
            1 &&
            item.ResidentOnboardingStatusID === 1 &&
            !item.ResidentOnboardingStatusNameOverride)
        ) {
          _itemDictionary[item.OnboardingColumn].ResidentOnboardingStatusID =
            item.ResidentOnboardingStatusID;
          _itemDictionary[
            item.OnboardingColumn
          ].ResidentOnboardingStatusNameOverride = null;

          _itemDictionary[item.OnboardingColumn].ResidentPetID =
            item.ResidentPetID;
        }
      }
    });

    return Object.keys(_itemDictionary).map(
      (itemKey) => _itemDictionary[itemKey]
    );
  }

  return (
    <>
      <div
        className={
          'page-container applicant-dashboard' +
          (' total-' + getItemList().length)
        }>
        <h1>Additional Application Requirements</h1>

        {residentOnboarding && residentOnboarding.Header ? (
          <IntroText
            trackAs="applicant-tasks"
            text={
              'Hello ' +
              residentOnboarding.Header.FirstName +
              '. We received your initial rental application for Unit #' +
              residentOnboarding.Header.UnitNumber +
              ' at ' +
              residentOnboarding.Header.PropertyName +
              ', but we need some additional information before approving your lease. Please complete the following items so that we can continue the approval process. Your application cannot move forward until these items are provided.'
            }
            mobileText={
              'Hello ' +
              residentOnboarding.Header.FirstName +
              '. We received your initial rental application for unit #' +
              residentOnboarding.Header.UnitNumber +
              ' at ' +
              residentOnboarding.Header.PropertyName +
              ', but we need some additional information before approving your lease. Please complete the following items so that we can continue the approval process. Your application cannot move forward until these items are provided.'
            }
          />
        ) : null}
        <div className="page">
          <div className="inner">
            <ul className={'applicant-item-list'}>
              {getItemList().map((item) => (
                <li
                  className="applicant-item"
                  style={{
                    width: 100 / getItemList().length + '%'
                  }}>
                  <ApplicantItemCard item={item} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      saveOrder: bindActionCreators(serviceAddressActions.saveOrder, dispatch),
      mockRouteUpdated: bindActionCreators(
        webSocketActions.mockRouteUpdated,
        dispatch
      ),
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantTasks);
