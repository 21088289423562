import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix, navigate } from 'gatsby-link';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import InputMask from 'react-input-mask';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ReactCodeInput from 'react-code-input';

import {
  validateEmail,
  requestAccessCode,
  confirmAccessCode
} from '../../api/userAuthApi';
import { setCookie, getCookie, getCurrentRoute } from '../../api/dataApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as userAuthActions from '../../redux/actions/userAuthActions';

import './index.css';

function ResidentLoginForm({ webSocket, actions }) {
  const [deliveryCredentials, setDeliveryCredentials] = useState({});
  const [deliveryPreference, setDeliveryPreference] = useState('email');

  useEffect(() => {
    if (getCookie('mi-invitation') && !deliveryCredentials.waitForWebsocket) {
      setDeliveryCredentials({
        ...deliveryCredentials,
        waitForWebsocket: true
      });
    }

    if (webSocket.invitation) {
      navigate('/dashboard');
    }
  }, [webSocket]);

  function doRequestCode() {
    if (deliveryCredentials.email === 'demo@movingin.com') {
      setDeliveryCredentials({
        ...deliveryCredentials,
        askCode: true
      });
    } else {
      setDeliveryCredentials({ ...deliveryCredentials, requesting: true });
      requestAccessCode(
        deliveryPreference === 'email' ? deliveryCredentials.email : '',
        deliveryPreference === 'phone' ? deliveryCredentials.phone : ''
      ).then((result) => {
        actions.pageLoading(false);
        setDeliveryCredentials({
          ...deliveryCredentials,
          askCode: true,
          requesting: false
        });
      });
    }
  }

  function checkRequestDisabled() {
    return !webSocket.connected || deliveryPreference === 'email'
      ? !validateEmail(deliveryCredentials.email)
      : !deliveryCredentials.phone ||
          isNaN(deliveryCredentials.phone.split('-').join(''));
  }

  function doLogin() {
    if (
      deliveryCredentials.email === 'demo@movingin.com' &&
      deliveryCredentials.code === '987654'
    ) {
      actions.pageLoading(true, 'Logging in...');
      setCookie('mi-invitation', btoa('C144A460-3681-4AF8-84BD-B1F3915BCAE5'));
      if (getCurrentRoute() !== '/dashboard') {
        window.location = '/dashboard';
      } else {
        window.location.reload();
      }
    } else {
      setDeliveryCredentials({
        ...deliveryCredentials,
        invalid: false,
        validating: true
      });

      confirmAccessCode(
        deliveryPreference === 'email' ? deliveryCredentials.email : '',
        deliveryPreference === 'phone' ? deliveryCredentials.phone : '',
        deliveryCredentials.code
      ).then((result) => {
        if (result && result.InvitationKey) {
          actions.pageLoading(true, 'Logging in...');
          setCookie('mi-invitation', btoa(result.InvitationKey));
          if (getCurrentRoute() !== '/dashboard') {
            window.location = '/dashboard';
          } else {
            window.location.reload();
          }
        } else {
          setDeliveryCredentials({
            ...deliveryCredentials,
            invalid: true,
            validating: false
          });
        }
      });
    }
  }

  function checkLoginDisabled() {
    return (
      !webSocket.connected ||
      !deliveryCredentials.code ||
      String(deliveryCredentials.code).length !== 6
    );
  }

  return (
    <div className="page-container mobile-login">
      {!deliveryCredentials.waitForWebsocket ||
      (webSocket.connected && !webSocket.invitation) ? (
        <div className="page">
          <div className="inner">
            <div className="mobile-login-form-container">
              <div className="welcome-image">
                <img src={withPrefix('/images/misc/welcome-home.png')} />
              </div>
              <div className="summary">
                <p className="summary-title">
                  Welcome to your MovingIN Dashboard
                </p>
                <p className="summary-text">
                  To log in, please enter your email address or phone number
                  below. We'll send you a code to access your personalized
                  dashboard.
                </p>
              </div>
              {deliveryCredentials.askCode ? (
                <div className="mobile-login-form confirm">
                  <p>
                    <strong>Please enter your access code below:</strong>
                  </p>
                  {deliveryCredentials.invalid ? (
                    <Alert severity="error" style={{ marginBottom: '20px' }}>
                      The code you entered was not recognized. Please try again.
                    </Alert>
                  ) : null}
                  <div className="code-input-container">
                    <ReactCodeInput
                      type="number"
                      fields={6}
                      value={deliveryCredentials.code}
                      onChange={(value) => {
                        setDeliveryCredentials({
                          ...deliveryCredentials,
                          code: value
                        });
                      }}
                    />

                    <Button
                      className="mobile-login-button"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={checkLoginDisabled()}
                      onClick={() => {
                        doLogin();
                      }}>
                      {deliveryCredentials.validating
                        ? 'Checking Code...'
                        : 'Log In'}
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="mobile-login-form">
                  <FormControl component="fieldset" className="options">
                    <RadioGroup
                      row
                      value={deliveryPreference}
                      onChange={(event) => {
                        setDeliveryPreference(event.target.value);
                      }}>
                      <FormControlLabel
                        value="email"
                        control={<Radio />}
                        label="Get Code by Email"
                      />
                      <FormControlLabel
                        value="phone"
                        control={<Radio />}
                        label="Text Me"
                      />
                    </RadioGroup>
                  </FormControl>

                  {deliveryPreference === 'phone' ? (
                    <InputMask
                      mask="999-999-9999"
                      maskChar="_"
                      value={deliveryCredentials.phone}
                      onChange={(event) =>
                        setDeliveryCredentials({
                          ...deliveryCredentials,
                          phone: event.target.value
                        })
                      }
                      disabled={!webSocket.connected}>
                      {() => (
                        <TextField
                          required
                          variant="outlined"
                          id="phone"
                          name="phone"
                          label={'Phone Number'}
                          type="tel"
                          autoFocus
                          fullWidth
                          onKeyDown={(event) => {
                            if (event.keyCode === 13) {
                              if (!checkRequestDisabled()) {
                                doRequestCode();
                              }
                            }
                          }}
                        />
                      )}
                    </InputMask>
                  ) : (
                    <TextField
                      required
                      variant="outlined"
                      id="email"
                      name="email"
                      label={'Email Address'}
                      type="email"
                      value={deliveryCredentials.email}
                      fullWidth
                      autoFocus
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          if (!checkRequestDisabled()) {
                            doRequestCode();
                          }
                        }
                      }}
                      onChange={(event) =>
                        setDeliveryCredentials({
                          ...deliveryCredentials,
                          email: event.target.value
                        })
                      }
                      disabled={!webSocket.connected}
                    />
                  )}

                  <Button
                    className="mobile-login-button"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={
                      checkRequestDisabled() || deliveryCredentials.requesting
                    }
                    onClick={() => {
                      doRequestCode();
                    }}>
                    {deliveryCredentials.requesting
                      ? 'Requesting Code...'
                      : 'Get Code'}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      userLoggedIn: bindActionCreators(userAuthActions.userLoggedIn, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResidentLoginForm);
