import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby-link';
import { withPrefix } from 'gatsby-link';

import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Alert,
  LinearProgress
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CanvasConfetti from '../CanvasConfetti';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import {
  QontoConnector,
  QontoStepIconRoot,
  QontoStepIcon,
  CircularProgressWithLabel,
  dayOfWeekList,
  businessHourList
} from '../ServiceProfileShared/config';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import { getServiceProfile } from '../../api/partnerDataApi';
import { listAllSchool } from '../../api/dataApi';

import ServiceRequiredOption from './ServiceRequiredOption';
import RadioOption from './RadioOption';
import ProviderSearch from './ProviderSearch';
import ProofOfServiceOption from './ProofOfServiceOption';
import UtilityBox from '../ConnectUtilities/UtilityBox';

import { checkProviderServiceHasDefaultProvider } from './config';

import '../PropertyProfile/index.css';
import './index.css';

function StepSchools({
  siteConfig,
  authUser,
  serviceProfile,
  activeStep,
  setProviderServiceValue,
  alreadyCompleted,
  profileKey,
  setServiceProfile
}) {
  const [allSchoolList, setAllSchoolList] = useState(null);

  useEffect(() => {
    if (
      serviceProfile &&
      serviceProfile.SchoolList &&
      serviceProfile.SchoolList.filter((school) => !school.IsCorrect).length &&
      !allSchoolList
    ) {
      listAllSchool({
        ServiceAddressID: serviceProfile.Header.ServiceAddressID
      }).then((schoolList) => setAllSchoolList(schoolList));
    }
  }, [serviceProfile]);

  return (
    <div className="section">
      <h3>Confirm Your Local Schools</h3>
      {serviceProfile.Header.HasSchoolChoice ? (
        <>
          <div className="form-content">
            <FormControl>
              <RadioGroup
                row
                name="service-available"
                value={serviceProfile.Header.HasSchoolChoiceConfirmed}
                onChange={(event) =>
                  setServiceProfile({
                    ...serviceProfile,
                    Header: {
                      ...serviceProfile.Header,
                      HasSchoolChoiceConfirmed: event.target.value === 'true'
                    }
                  })
                }>
                <p
                  style={{
                    paddingTop: '12px'
                  }}>
                  It appears your property is in a 'School Choice' school
                  district. Parents can select the campus they want their
                  children to attend and truly customize the student's
                  educational experience.
                </p>
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={'Yes'}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={'No'}
                />
                <p
                  style={{
                    paddingTop: '12px'
                  }}>
                  <strong>Is this correct?</strong>
                </p>
              </RadioGroup>
            </FormControl>
          </div>
        </>
      ) : (
        <>
          <Alert severity="info">
            Please confirm that the following schools are correct for your
            property. If any are wrong check NO. Enter the correct school name
            and we'll update our records.
          </Alert>
          <div className="form-content">
            {serviceProfile.SchoolList.filter(
              (school) =>
                !school.SchoolIDSelected ||
                !serviceProfile.SchoolList.filter(
                  (_school) => _school.SchoolID === school.SchoolIDSelected
                ).length
            ).map((school) => (
              <div className={'radio-option'}>
                <FormControl>
                  <RadioGroup
                    row
                    name="service-available"
                    value={school.IsCorrect}
                    onChange={(event) =>
                      setServiceProfile({
                        ...serviceProfile,
                        SchoolList: serviceProfile.SchoolList.map((_school) =>
                          _school.SchoolID === school.SchoolID
                            ? {
                                ..._school,
                                IsCorrect: event.target.value === 'true'
                              }
                            : { ..._school }
                        )
                      })
                    }>
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label={'Yes'}
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label={'No'}
                    />
                  </RadioGroup>
                </FormControl>

                <p>
                  <span className="school-detail">
                    <strong>
                      {school.SchoolName
                        ? school.SchoolName
                        : school.SchoolNameEntered}
                    </strong>
                    <br />
                    {school.LowGrade && school.HighGrade ? (
                      <>
                        Grades {school.LowGrade} - {school.HighGrade}
                      </>
                    ) : null}
                  </span>
                  {school.Address ? (
                    <span className="school-detail">
                      {school.Address}
                      <br />
                      {school.City}, {school.State} {school.Zip}
                    </span>
                  ) : school.SchoolID < 0 ? (
                    <span className="school-detail alert">
                      School address and grade details will be updated shortly.
                    </span>
                  ) : null}
                </p>

                {school.IsCorrect === false ? (
                  <div className={'sub-option'}>
                    <Autocomplete
                      freeSolo
                      fullWidth
                      value={school.SchoolNameEntered}
                      disableClearable
                      onFocus={() =>
                        setServiceProfile({
                          ...serviceProfile,
                          SchoolList: serviceProfile.SchoolList.map(
                            (_school, _schoolIndex) =>
                              _school.SchoolID === school.SchoolID
                                ? {
                                    ..._school,
                                    PromptToAdd: false
                                  }
                                : { ..._school }
                          )
                        })
                      }
                      onBlur={() =>
                        setServiceProfile({
                          ...serviceProfile,
                          SchoolList: serviceProfile.SchoolList.map(
                            (_school, _schoolIndex) =>
                              _school.SchoolID === school.SchoolID
                                ? {
                                    ..._school,
                                    PromptToAdd: !_school.SchoolIDSelected
                                  }
                                : { ..._school }
                          )
                        })
                      }
                      onChange={(event, value) => {
                        const schoolSelected = allSchoolList.filter(
                          (school) => school.SchoolName === value
                        )[0];

                        if (schoolSelected) {
                          let addSchoolAtIndex = 0;
                          let _schoolList = serviceProfile.SchoolList.map(
                            (_school, _schoolIndex) => {
                              if (_school.SchoolID === school.SchoolID) {
                                addSchoolAtIndex = _schoolIndex + 1;
                                return {
                                  ..._school,
                                  SchoolIDSelected: schoolSelected.SchoolID,
                                  SchoolNameEntered: schoolSelected.SchoolName
                                };
                              } else {
                                return { ..._school };
                              }
                            }
                          );

                          _schoolList.splice(addSchoolAtIndex, 0, {
                            ...schoolSelected,
                            IsDefault: false,
                            IsCorrect: true
                          });

                          setServiceProfile({
                            ...serviceProfile,
                            SchoolList: _schoolList
                          });
                        }
                      }}
                      options={(allSchoolList ? allSchoolList : []).map(
                        (option) => option.SchoolName
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={school.SchoolNameEntered}
                          label="If NO, search for the correct school here"
                          InputProps={{
                            ...params.InputProps,
                            type: 'search'
                          }}
                          autoFocus
                          onChange={(event) => {
                            const schoolSelected = allSchoolList.filter(
                              (school) =>
                                school.SchoolName === event.target.value
                            )[0];

                            setServiceProfile({
                              ...serviceProfile,
                              SchoolList: serviceProfile.SchoolList.map(
                                (_school) =>
                                  _school.SchoolID === school.SchoolID
                                    ? {
                                        ..._school,
                                        SchoolIDSelected: schoolSelected
                                          ? schoolSelected.SchoolID
                                          : null,
                                        SchoolNameEntered: event.target.value
                                      }
                                    : { ..._school }
                              )
                            });
                          }}
                        />
                      )}
                    />

                    {(school.SchoolNameEntered &&
                      (school.PromptToAdd ||
                        !allSchoolList.filter(
                          (_school) =>
                            _school.SchoolName &&
                            _school.SchoolName.toLowerCase().indexOf(
                              school.SchoolNameEntered.toLowerCase()
                            ) !== -1
                        ).length)) ||
                    school.IsNameChange ? (
                      <>
                        <FormControl>
                          <RadioGroup
                            row
                            name="is-onsite-contact"
                            value={school.IsNameChange}
                            onChange={(event) => {
                              setServiceProfile({
                                ...serviceProfile,
                                SchoolList: serviceProfile.SchoolList.map(
                                  (_school) =>
                                    _school.SchoolID === school.SchoolID
                                      ? {
                                          ..._school,
                                          IsNameChange:
                                            event.target.value === 'true'
                                        }
                                      : { ..._school }
                                )
                              });
                            }}>
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label={'Yes'}
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label={'No'}
                            />
                            <p
                              style={{
                                paddingTop: '12px'
                              }}>
                              Did this school's name change to{' '}
                              <strong>{school.SchoolNameEntered}?</strong>
                            </p>
                          </RadioGroup>
                        </FormControl>

                        {school.IsNameChange === false ? (
                          <>
                            <br />
                            <FormControl>
                              <RadioGroup
                                row
                                name="is-onsite-contact"
                                value={school.IsNew}
                                onChange={(event) => {
                                  setServiceProfile({
                                    ...serviceProfile,
                                    SchoolList: serviceProfile.SchoolList.map(
                                      (_school) =>
                                        _school.SchoolID === school.SchoolID
                                          ? {
                                              ..._school,

                                              IsNew:
                                                event.target.value === 'true'
                                            }
                                          : { ..._school }
                                    )
                                  });
                                }}>
                                <FormControlLabel
                                  value={true}
                                  control={<Radio />}
                                  label={'Yes'}
                                />
                                <FormControlLabel
                                  value={false}
                                  control={<Radio />}
                                  label={'No'}
                                />
                                <p
                                  style={{
                                    paddingTop: '12px'
                                  }}>
                                  Is <strong>{school.SchoolNameEntered}</strong>{' '}
                                  a new school?
                                </p>
                              </RadioGroup>
                            </FormControl>
                          </>
                        ) : null}

                        {school.IsNew === false &&
                        school.IsNameChange === false ? (
                          <TextField
                            multiline
                            variant="outlined"
                            label="Please add any other details you know about this school here"
                            fullWidth
                            style={{
                              marginTop: '0px'
                            }}
                            value={school.OtherDetailsEntered}
                            onChange={(event) =>
                              setServiceProfile({
                                ...serviceProfile,
                                SchoolList: serviceProfile.SchoolList.map(
                                  (_school) =>
                                    _school.SchoolID === school.SchoolID
                                      ? {
                                          ..._school,
                                          OtherDetailsEntered:
                                            event.target.value
                                        }
                                      : { ..._school }
                                )
                              })
                            }
                          />
                        ) : null}

                        {school.IsNew || school.IsNameChange === false ? (
                          <>
                            <br />
                            <Button
                              variant="contained"
                              className={
                                'green' +
                                (school.IsNew !== true &&
                                school.IsNameChange !== true &&
                                !school.OtherDetailsEntered
                                  ? ' disabled'
                                  : '')
                              }
                              color="primary"
                              disabled={
                                school.IsNew !== true &&
                                school.IsNameChange !== true &&
                                !school.OtherDetailsEntered
                              }
                              onClick={() => {
                                let _lowestSchoolID = -1;
                                let _updatingSchoolIndex = -1;

                                let _schoolList = serviceProfile.SchoolList.map(
                                  (_school, _schoolIndex) => {
                                    if (_school.SchoolID === school.SchoolID) {
                                      _updatingSchoolIndex = _schoolIndex;
                                    }

                                    if (_school.SchoolID < _lowestSchoolID) {
                                      _lowestSchoolID = _school.SchoolID - 1;
                                    }

                                    return { ..._school };
                                  }
                                );

                                if (_updatingSchoolIndex !== -1) {
                                  _schoolList[_updatingSchoolIndex] = {
                                    ..._schoolList[_updatingSchoolIndex],
                                    SchoolIDSelected: _lowestSchoolID
                                  };
                                }

                                _schoolList.push({
                                  SchoolID: _lowestSchoolID,
                                  SchoolNameEntered: school.SchoolNameEntered,
                                  IsNew: school.IsNew,
                                  IsNameChange: school.IsNameChange,
                                  OtherDetailsEntered:
                                    school.OtherDetailsEntered,
                                  IsCorrect: true,
                                  IsDefault: false
                                });

                                setServiceProfile({
                                  ...serviceProfile,
                                  SchoolList: _schoolList
                                });
                              }}>
                              Save School Information
                              <ChevronRightIcon />
                            </Button>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  serviceAddress: state.serviceAddress,
  authUser: state.authUser,
  siteConfig: state.commonData.siteConfig
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StepSchools);
