import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby';

import { IconButton, TextField, Tooltip, Alert } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import { getReportPath } from '../ResidentForm/ResidentFormReport';

import {
  getPetStepList,
  getPetActiveStep
} from '../ResidentOnboarding/ResidentOnboardingPet';

import ResidentOnboardingPopup from '../ResidentOnboarding/ResidentOnboardingPopup';
import ResidentFormPopup from '../ResidentForm/ResidentFormPopup';
import ApplicantGroupPopup from './ApplicantGroupPopup';

import './index.css';

function ApplicantItemCard({
  totalItems,
  item,
  residentOnboarding,
  actions,
  buttonAction,
  buttonLabel,
  buttonSelectedItem
}) {
  const [steps, setSteps] = useState(null);
  const [showResidentOnboarding, setShowResidentOnboarding] = useState(null);
  const [showCalendar, setShowCalendar] = useState(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [showResidentForm, setShowResidentForm] = useState(null);
  const [showGroupPopup, setShowGroupPopup] = useState(null);

  useEffect(() => {
    let pet = residentOnboarding.PetList.filter(
      (_pet) => _pet.ResidentPetID === item.ResidentPetID
    )[0];

    if (!pet) {
      pet = { ResidentOnboardingStatusID: 1 };
    }

    switch (item.Grouping) {
      case 'pet-applicant': {
        setSteps(
          getPetStepList(pet, residentOnboarding).map((label) => {
            return { label };
          })
        );
        break;
      }
      case 'vehicle-applicant':
        setSteps([
          {
            label:
              item.ResidentOnboardingStatusID !== 1
                ? 'Registration Submitted'
                : 'Vehicle Registration',
            action: () => {}
          },
          {
            label:
              item.ResidentOnboardingStatusID !== 1
                ? 'Vehicle Policy Signed'
                : 'Sign Vehicle Policy',
            action: () => {}
          },
          {
            label:
              item.ResidentOnboardingStatusID > 2
                ? 'Accepted by Property'
                : 'Pending Property Review'
          },
          { label: 'Registration Complete' }
        ]);
        break;
      case 'resident-form':
        setSteps([
          {
            label:
              item.ResidentOnboardingStatusNameOverride ||
              item.ResidentOnboardingStatusID !== 1
                ? 'Authorization Submitted'
                : item.OnboardingColumn
                ? item.ResidentFormID === 3
                  ? 'Upload Photo ID'
                  : 'Sign Authorization'
                : item.Description,
            action: () => {}
          },
          item.ResidentFormID === 3
            ? {
                label:
                  item.ResidentOnboardingStatusID !== 1
                    ? item.ResidentOnboardingStatusNameOverride
                      ? item.ResidentOnboardingStatusNameOverride
                      : 'Data Captured Successfully'
                    : 'Capture Data'
              }
            : {
                label: item.IsNotApplicable
                  ? 'No ' + item.OtherSigner + ' Noted'
                  : (item.ResidentOnboardingStatusID !== 1
                      ? 'Completed by '
                      : 'Waiting on ') + item.OtherSigner
              },
          {
            label:
              item.ResidentOnboardingStatusID > 2
                ? 'Accepted by Property'
                : 'Pending Property Review'
          },
          {
            label: 'Verification Complete'
          }
        ]);
        break;
    }

    if (item.Grouping === 'pet-applicant') {
      setActiveStep(getPetActiveStep(pet));
    } else {
      if (item.ResidentOnboardingStatusID === 3) {
        switch (item.Grouping) {
          case 'vehicle-applicant':
            setActiveStep(999);
            break;
          default:
            setActiveStep(999);
            break;
        }
      } else if (item.ResidentOnboardingStatusID === 2) {
        switch (item.Grouping) {
          case 'vehicle-applicant':
            setActiveStep(2);
            break;
          default:
            setActiveStep(2);
            break;
        }
      } else if (item.ResidentOnboardingStatusID === 1) {
        switch (item.Grouping) {
          case 'vehicle-applicant':
            setActiveStep(0);
            break;
          default:
            if (
              item.OtherSigner &&
              item.ResidentOnboardingStatusNameOverride &&
              item.ResidentOnboardingStatusNameOverride.indexOf(
                item.OtherSigner
              ) !== -1
            ) {
              setActiveStep(1);
            } else {
              setActiveStep(0);
            }
            break;
        }
      }
    }
  }, [item, residentOnboarding]);

  function getButtonLabel() {
    let description = item.OnboardingColumn
      ? item.OnboardingColumn
      : item.Description;

    if (item.ResidentOnboardingStatusID === 1 && item.SubmitButtonLabel) {
      return item.SubmitButtonLabel;
    }

    return (
      (item.ResidentOnboardingStatusID === 3
        ? 'View '
        : item.ResidentOnboardingStatusID === 1
        ? 'Start '
        : 'Edit ') + description
    );
  }

  return steps ? (
    <div className={'applicant-item-card-container'}>
      <div
        className={
          'applicant-item-card' +
          (buttonAction
            ? ' resident-onboarding-summary' +
              (buttonSelectedItem &&
              buttonSelectedItem.Description === item.Description
                ? ' selected'
                : '')
            : '') +
          (item.ResidentOnboardingStatusID === 3 ? ' completed' : '')
        }>
        <div className="card-title">
          <h5>
            {item.ResidentName
              ? item.ResidentName
              : item.OnboardingColumn
              ? item.OnboardingColumn
              : item.Description}
          </h5>
          {item.Relationship ? <h6>{item.Relationship}</h6> : null}
        </div>
        {!item.ResidentName ? (
          <div className="card-icon">
            {item.Grouping === 'resident-form' ? (
              item.Description.indexOf('Employment') !== -1 ? (
                <img src={withPrefix('/images/misc/id-card.png')} />
              ) : item.ResidentFormID === 3 ? (
                <img src={withPrefix('/images/misc/id-card-upload.png')} />
              ) : (
                <img src={withPrefix('/images/misc/rent.png')} />
              )
            ) : item.Grouping === 'pet-applicant' ? (
              <img
                src={
                  'https://mi-provider-logo.s3-us-west-1.amazonaws.com/85631_Email_dreamstime_xxl_133279622 png.png'
                }
              />
            ) : item.Grouping === 'vehicle-applicant' ? (
              <img
                src={
                  'https://mi-provider-logo.s3-us-west-1.amazonaws.com/85630_Email_southeast.jfif'
                }
              />
            ) : null}
          </div>
        ) : null}

        {!item.ResidentName ? (
          <div className="card-description">
            <p>{item.RequiredItemText}</p>
          </div>
        ) : null}

        <div className="card-buttons">
          <Box sx={{ maxWidth: 400 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel>{step.label}</StepLabel>
                  {/* {step.action ? (
                    <StepContent>
                      <Box sx={{ mb: 2 }}>
                        <div>
                          <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={step.action}
                            sx={{ mt: 1, mr: 1 }}>
                            Complete Form
                          </Button>
                        </div>
                      </Box>
                    </StepContent>
                  ) : null} */}
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>

        {buttonAction ? (
          <Button
            variant={
              item.ResidentOnboardingStatusID === 2 ? 'contained' : 'outlined'
            }
            color="primary"
            // style={{
            //   visibility:
            //     item.ResidentOnboardingStatusID > 1 ||
            //     item.ResidentOnboardingStatusNameOverride
            //       ? 'visible'
            //       : 'hidden'
            // }}
            fullWidth
            onClick={() => {
              buttonAction(item);
            }}>
            {buttonLabel ? buttonLabel(item) : 'View Submittal'}
          </Button>
        ) : item.ResidentOnboardingStatusNameOverride ||
          item.ResidentOnboardingStatusID > 1 ? (
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            style={{
              visibility:
                !item.ResidentFormID || item.itemCount > 1
                  ? 'visible'
                  : 'hidden'
            }} //Do not show this button in case there are multiple occupants, for privacy reasons
            // href={getReportPath(
            //   null,
            //   null,
            //   item.ResidentFormID,
            //   'Applicant',
            //   true
            // )}
            onClick={() => {
              if (item.ResidentFormID && item.itemCount > 1) {
                setShowGroupPopup(true);
              } else {
                setShowResidentOnboarding({ ...item });
              }
            }}>
            View Status
          </Button>
        ) : (
          <Button
            variant={
              item.ResidentOnboardingStatusID === 1 ? 'contained' : 'outlined'
            }
            color={
              item.ResidentOnboardingStatusID === 1 ? 'secondary' : 'primary'
            }
            onClick={() => {
              if (
                item.ResidentOnboardingStatusID !== 1 ||
                item.ResidentOnboardingStatusNameOverride
              ) {
                //DO NOTHING - LOCKED
              } else {
                if (item.ResidentFormID && item.itemCount > 1) {
                  setShowGroupPopup(true);
                } else if (item.Grouping === 'resident-form') {
                  setShowResidentForm(true);
                } else {
                  setShowResidentOnboarding({ ...item });
                }
              }
            }}
            disabled={
              item.ResidentOnboardingStatusID !== 1 ||
              item.ResidentOnboardingStatusNameOverride
            }
            fullWidth>
            {getButtonLabel()}
          </Button>
        )}
      </div>
      {showResidentOnboarding ? (
        <ResidentOnboardingPopup
          otherTasks={showResidentOnboarding.Grouping}
          closeFunc={() => {
            setShowResidentOnboarding(false);
            actions.loadResidentOnboarding();
          }}
        />
      ) : null}
      {showResidentForm ? (
        <ResidentFormPopup
          closeFunc={() => setShowResidentForm(null)}
          item={item}
        />
      ) : null}

      {showGroupPopup ? (
        <ApplicantGroupPopup
          closeFunc={() => setShowGroupPopup(null)}
          groupItem={item}
          fullScreen
        />
      ) : null}
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      ),
      saveResidentOnboarding: bindActionCreators(
        residentOnboardingActions.saveResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantItemCard);
