import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, IconButton, TextField, Tooltip } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ResidentOnboardingPopup from '../ResidentOnboarding/ResidentOnboardingPopup';
import ResidentOnboardingStatusBadge from '../ResidentOnboarding/ResidentOnboardingStatusBadge';

import './index.css';
import { withPrefix } from 'gatsby';

export function UtilityButtonList({
  provider,
  isTaskCompleted,
  setShowResidentOnboarding
}) {
  return (
    <>
      {provider.ServiceStartUrl ? (
        <Button
          variant={
            provider.StartButtonVariant
              ? provider.StartButtonVariant
              : 'outlined'
          }
          color="primary"
          style={{
            width: '100%',
            fontWeight: '700',
            maxWidth: '300px'
          }}
          href={provider.ServiceStartUrl}
          target="mi_offsite">
          {provider.StartButtonLabel
            ? provider.StartButtonLabel
            : 'Schedule New Service'}
          <ChevronRightIcon />
        </Button>
      ) : null}

      {provider.ServiceTransferUrl ? (
        <Button
          variant="outlined"
          color="primary"
          style={{
            width: '100%',
            fontWeight: '700',
            maxWidth: '300px'
          }}
          href={provider.ServiceTransferUrl}
          target="mi_offsite">
          Transfer Existing Service
          <ChevronRightIcon />
        </Button>
      ) : null}

      {provider.IsServiceProofRequired ? (
        provider.IsServiceProofUpload ? (
          <Button
            style={{ width: '100%', maxWidth: '300px' }}
            //variant={isTaskCompleted ? 'outlined' : 'contained'}
            variant="outlined"
            color="secondary"
            onClick={() => setShowResidentOnboarding(true)}
            className="proof-of-service"
            target="mi_offsite">
            Upload Proof of Service
            <ChevronRightIcon />
          </Button>
        ) : provider.IsServiceProofUseUrl ? (
          <Button
            variant={isTaskCompleted ? 'outlined' : 'contained'}
            style={{
              width: '100%',
              maxWidth: '300px',
              pointerEvents: 'none'
            }}
            href={provider.ServiceProofUrl}
            color="secondary"
            className="proof-of-service taller">
            Deliver Proof of Service
            <br />
            to Resident Portal
          </Button>
        ) : provider.IsServiceProofDeliver ? (
          <Button
            variant={isTaskCompleted ? 'outlined' : 'contained'}
            style={{
              width: '100%',
              maxWidth: '300px',
              pointerEvents: 'none'
            }}
            color="secondary"
            className="proof-of-service taller">
            Deliver Proof of Service
            <br />
            to Leasing Office
          </Button>
        ) : null
      ) : null}
    </>
  );
}

function ValetTrashText({ provider }) {
  return provider.ServiceAddendumFile ? (
    <>
      <p>Doorside Trash Pick-up service is provided as part of your lease.</p>
      {provider.ServiceAddendumFile != '1' ? (
        <a
          href={
            'https://mi-service-profile.s3.us-west-1.amazonaws.com/' +
            provider.ServiceAddendumFile
          }
          target="_blank"
          className="link">
          View Rules and Restrictions
        </a>
      ) : null}
    </>
  ) : null;
}

function RequiredText({ service, provider, isServiceRequired }) {
  return provider.IsCustomer ? (
    isServiceRequired ? (
      <div className="provider-description">
        <div className="text">
          <p>
            Your lease requires you to set up {service.ProviderServiceName} in
            your name prior to moving in.
          </p>

          <ValetTrashText provider={provider} />
        </div>
      </div>
    ) : isServiceRequired === false ? (
      <div className="provider-description">
        <div className="text">
          <p>
            {provider.IsServiceAvailable === false
              ? 'Nothing in your home uses ' +
                service.ProviderServiceName +
                ', so there is no need to set up ' +
                service.ProviderServiceName +
                ' service.'
              : provider.IsServiceIncludedInRent
              ? service.ProviderServiceName +
                ' service to your home is provided by the property as part of your rent at no additional cost.'
              : provider.IsServicePaidWithRent
              ? service.ProviderServiceName +
                ' service to your home is provided by the property but charged back to you according to your lease.'
              : provider.IsServicePaidToThirdParty
              ? service.ProviderServiceName +
                ' service is paid to a third party per your lease.'
              : provider.CustomMessage
              ? provider.CustomMessage
              : null}
          </p>

          <ValetTrashText provider={provider} />
        </div>
      </div>
    ) : null
  ) : provider.Description ? (
    <div className="provider-description">
      <div className="text">
        <p
          dangerouslySetInnerHTML={{
            __html: provider.Description
          }}></p>
        <ValetTrashText provider={provider} />
      </div>
    </div>
  ) : provider.IsCustomer ? (
    <div className="provider-description">
      <div className="text">
        <p>
          Your lease requires you to set up {service.ProviderServiceName}{' '}
          service in your name prior to moving in.
        </p>
        <ValetTrashText provider={provider} />
      </div>
    </div>
  ) : (
    <div className="provider-description">
      <div className="text">
        We've identified {provider.ProviderName} as the{' '}
        {service.ProviderServiceName} provider at this address.
      </div>
    </div>
  );
}

function ProvidedByProperty({ service }) {
  return (
    <div className="logo-container provided-by-property">
      <div className="spacer"></div>
      {service.ProviderServiceName &&
      service.ProviderServiceName.indexOf('Gas') !== -1 ? (
        <img src={withPrefix('/images/misc/icon-gas.svg')} />
      ) : service.ProviderServiceName === 'Sewer' ? (
        <img
          src={withPrefix('/images/misc/icon-sewer.svg')}
          style={{ top: '30px' }}
        />
      ) : service.ProviderServiceName &&
        service.ProviderServiceName.indexOf('Water') !== -1 ? (
        <img
          src={withPrefix('/images/misc/icon-water.svg')}
          style={{ top: '30px' }}
        />
      ) : service.ProviderServiceName === 'Trash' ? (
        <img src={withPrefix('/images/misc/icon-trash.svg')} />
      ) : (
        <LightbulbIcon />
      )}

      <strong>
        Provided by
        <br />
        Property
      </strong>
    </div>
  );
}

function UtilityBox({
  service,
  serviceAddress,
  children,
  residentOnboarding,
  isDeregulated
}) {
  const isServiceRequired =
    service.ProviderList && service.ProviderList.length
      ? service.ProviderList[0].IsServiceRequired
      : service.IsServiceRequired;

  const [showResidentOnboarding, setShowResidentOnboarding] = useState(null);
  const [isTaskCompleted, setIsTaskCompleted] = useState(null);

  useEffect(() => {
    if (residentOnboarding && residentOnboarding.Checklist) {
      const _item = residentOnboarding.Checklist.filter(
        (item) => item.ProviderServiceID === service.ProviderServiceID
      )[0];

      setIsTaskCompleted(_item && _item.ResidentOnboardingStatusID > 1);
    }
  }, [residentOnboarding]);

  return (
    <div className={'card' + (isServiceRequired ? ' required' : '')}>
      {children}
      {residentOnboarding && residentOnboarding.Checklist ? (
        residentOnboarding.Checklist.filter(
          (item) => item.ProviderServiceID === service.ProviderServiceID
        ).length ? (
          residentOnboarding.Checklist.filter(
            (item) => item.ProviderServiceID === service.ProviderServiceID
          ).map((item) => <ResidentOnboardingStatusBadge status={item} />)
        ) : (
          <ResidentOnboardingStatusBadge
            status={{
              ResidentOnboardingStatusID: 5,
              ResidentOnboardingStatusName: 'No Action Needed'
            }}
          />
        )
      ) : isServiceRequired ? (
        <div className="badge required">Required</div>
      ) : isServiceRequired === false ? (
        <div className="badge not-required">No Action Needed</div>
      ) : null}

      <h2>
        {service.ProviderServiceID === 25
          ? 'Trash'
          : service.ProviderServiceName}{' '}
        Service
      </h2>

      {isDeregulated ? (
        isServiceRequired === true ? (
          <div className="logo-list">
            {service.ProviderList.filter((provider) => provider.LogoFile)
              .filter((provider, providerIndex) => providerIndex < 4)
              .map((provider) => (
                <img src={provider.LogoFile} alt={provider.DisplayName} />
              ))}
            {service.ProviderList.filter((provider) => provider.IsCustomer)
              .length ? (
              <RequiredText
                service={service}
                provider={service.ProviderList[0]}
                isServiceRequired={isServiceRequired}
              />
            ) : null}
          </div>
        ) : isServiceRequired === false ? (
          <>
            <ProvidedByProperty service={service} />
            <RequiredText
              service={service}
              provider={service.ProviderList[0]}
              isServiceRequired={isServiceRequired}
            />
          </>
        ) : null
      ) : (
        service.ProviderList.map((provider) => (
          <>
            {isServiceRequired ? (
              <div className="logo-container">
                <div className="spacer"></div>
                {provider.LogoFile ? (
                  <img src={provider.LogoFile} alt={provider.DisplayName} />
                ) : provider.DisplayName ? (
                  provider.DisplayName
                ) : (
                  provider.ProviderName
                )}
              </div>
            ) : (
              <ProvidedByProperty service={service} />
            )}

            <RequiredText
              service={service}
              provider={provider}
              isServiceRequired={isServiceRequired}
            />

            <div className="solid-button">
              <UtilityButtonList
                provider={provider}
                isTaskCompleted={isTaskCompleted}
                setShowResidentOnboarding={setShowResidentOnboarding}
              />
            </div>
          </>
        ))
      )}

      {showResidentOnboarding ? (
        <ResidentOnboardingPopup
          providerServiceList={[service]}
          closeFunc={() => setShowResidentOnboarding(false)}
        />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UtilityBox);
