import * as types from './actionTypes';
import * as userAuthApi from '../../api/userAuthApi';
import { beginApiCall, apiCallError } from './apiStatusActions';
import { setCookie, setSessionStorage } from '../../api/dataApi';
import showNotification from '../../components/Common/Notification';

export function userAuthSuccess(authUser) {
  return { type: types.USER_AUTH_SUCCESS, authUser: authUser };
}

export function userAuthFailure(email) {
  return {
    type: types.USER_AUTH_FAILURE,
    authUser: { email: email, unauthorized: true }
  };
}

export function userAuthLogOut() {
  return { type: types.USER_AUTH_LOGOUT, authUser: null };
}

export function mockAuthorizedUser() {
  return function (dispatch, getState) {
    dispatch(userAuthSuccess({ email: 'Not Set' }));
  };
}

export function userUnitSelected(unit) {
  return { type: types.USER_UNIT_SELECTED, unit };
}

// export function checkAuthorizedUser(user, connectionId) {
//   return function(dispatch, getState) {
//     dispatch(beginApiCall());
//     return userAuthApi
//       .checkAuthorizedUser(user, connectionId)
//       .then(authUser => {
//         if (authUser) {
//           dispatch(userAuthSuccess(authUser));
//         } else {
//           dispatch(userAuthFailure(user.email));
//         }
//       })
//       .catch(error => {
//         dispatch(apiCallError(error));
//         showNotification({
//           key: 'authenticationError',
//           message: 'Unable to authenticate with admin service.',
//           duration: 0
//         });
//         throw error;
//       });
//   };
// }

export function saveConnectionId(user, connectionId) {
  return function (dispatch, getState) {
    dispatch(beginApiCall());
    return userAuthApi
      .saveConnectionId(connectionId)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        showNotification({
          key: 'connectionError',
          message: 'Unable to connect. Please refresh the page to continue.',
          duration: 0
        });
        throw error;
      });
  };
}

export function userLoggedIn(user) {
  return function (dispatch, getState) {
    return dispatch(userAuthSuccess(user));
  };
}

export function logOutUser() {
  return function (dispatch, getState) {
    var state = getState();

    if (state.authUser) {
      userAuthApi.setUserToken('');
      setCookie('mi-profile-key', '');
      setCookie('mi-visitor', '');
      setSessionStorage('mi-visit', '');
      setSessionStorage('mi-selected-resident', '');
      setSessionStorage('mi-service-address', '');

      dispatch(userAuthLogOut());
    }
  };
}

export function selectUnit(unit) {
  return function (dispatch, getState) {
    return dispatch(userUnitSelected(unit));
  };
}
