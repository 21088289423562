import * as api from './apiUtils';
import { getCookie, getSessionStorage } from './dataApi';

export function getConfig(residentID) {
  let profileKey = getCookie('mi-profile-key');

  if (profileKey) {
    profileKey = profileKey.split(':')[0];
  }

  let userToken = getCookie('mi-user');

  if (!residentID) {
    const residentJson = getSessionStorage('mi-selected-resident');
    if (residentJson) {
      try {
        const resident = JSON.parse(residentJson);
        if (resident && resident.ResidentID) {
          residentID = resident.ResidentID;
        }
      } catch (e) {}
    }
  }

  console.log('mi-invitation', getCookie('mi-invitation'));
  console.log('profileKey', profileKey);
  console.log('deviceToken', getCookie('mi-d'));

  return api.getConfigObject({
    headers: {
      invitationKey: getCookie('mi-invitation'),
      deviceToken: getCookie('mi-d'),
      property: profileKey,
      residentID: residentID ? residentID : null,
      user: userToken ? userToken : ''
    }
  });
}

export function getResidentOnboarding(residentID) {
  return api
    .get('API/ResidentOnboarding/GetResidentOnboarding', getConfig(residentID))
    .then((response) => {
      return response.data;
    });
}

export function saveResidentOnboarding(header, residentID) {
  return api
    .post(
      'API/ResidentOnboarding/SaveResidentOnboarding',
      header,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function saveProviderService(providerService, residentID) {
  return api
    .post(
      'API/ResidentOnboarding/SaveProviderService',
      providerService,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function saveProviderServiceStatus(
  residentID,
  providerServiceID,
  residentOnboardingStatusID,
  note
) {
  return api
    .post(
      'API/ResidentOnboarding/UpdateResidentProviderServiceStatus?providerServiceID=' +
        providerServiceID +
        '&residentOnboardingStatusID=' +
        residentOnboardingStatusID,
      { Value: note },
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function deleteResidentFile(residentFileID, residentID) {
  return api
    .post(
      'API/ResidentOnboarding/DeleteResidentFile?residentFileID=' +
        residentFileID,
      null,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function cropResidentFile(
  residentFileID,
  residentID,
  percentWidth,
  percentHeight,
  percentX,
  percentY
) {
  return api
    .post(
      'API/ResidentOnboarding/CropImage?residentFileID=' +
        residentFileID +
        '&percentWidth=' +
        parseInt(percentWidth) +
        '&percentHeight=' +
        parseInt(percentHeight) +
        '&percentX=' +
        parseInt(percentX) +
        '&percentY=' +
        parseInt(percentY),
      null,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function updateCroppedImage(
  residentFileID,
  residentID,
  croppedImageData
) {
  return api
    .post(
      'API/ResidentOnboarding/UpdateCroppedImage?residentFileID=' +
        residentFileID,
      { Base64ImageData: croppedImageData },
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function createResidentVehicle(residentID) {
  return api
    .post(
      'API/ResidentOnboarding/CreateResidentVehicle',
      null,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function saveResidentVehicle(vehicle, residentID) {
  return api
    .post(
      'API/ResidentOnboarding/SaveResidentVehicle',
      vehicle,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function saveResidentVehicleStatus(
  residentID,
  residentVehicleID,
  residentOnboardingStatusID,
  note
) {
  return api
    .post(
      'API/ResidentOnboarding/UpdateResidentVehicleStatus?residentVehicleID=' +
        residentVehicleID +
        '&residentOnboardingStatusID=' +
        residentOnboardingStatusID,
      { Value: note },
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function deleteResidentVehicle(residentVehicleID, residentID) {
  return api
    .post(
      'API/ResidentOnboarding/DeleteResidentVehicle?residentVehicleID=' +
        residentVehicleID,
      null,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function createResidentPet(residentID) {
  return api
    .post(
      'API/ResidentOnboarding/CreateResidentPet',
      null,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function saveResidentPet(pet, residentID) {
  return api
    .post('API/ResidentOnboarding/SaveResidentPet', pet, getConfig(residentID))
    .then((response) => {
      return response.data;
    });
}

export function saveResidentPetStatus(
  residentID,
  residentPetID,
  residentOnboardingStatusID,
  note
) {
  return api
    .post(
      'API/ResidentOnboarding/UpdateResidentPetStatus?residentPetID=' +
        residentPetID +
        '&residentOnboardingStatusID=' +
        residentOnboardingStatusID,
      { Value: note },
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function deleteResidentPet(residentPetID, residentID) {
  return api
    .post(
      'API/ResidentOnboarding/DeleteResidentPet?residentPetID=' + residentPetID,
      null,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function saveResidentTimeslotApproval(
  residentID,
  moveTimeslotApproved,
  note
) {
  return api
    .post(
      'API/ResidentOnboarding/UpdateResidentMoveTimeslot?moveTimeslotApproved=' +
        (moveTimeslotApproved ? encodeURIComponent(moveTimeslotApproved) : ''),
      { Value: note },
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function listResidentWalkthroughItem(residentWalkthroughID, residentID) {
  return api
    .get(
      'API/ResidentOnboarding/ListResidentWalkthroughItem?residentWalkthroughID=' +
        residentWalkthroughID,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function saveWalkthroughChecklist(
  residentWalkthroughID,
  residentID,
  itemList,
  submittedName,
  submittedNote,
  submittedSignaturedData
) {
  return api
    .post(
      'API/ResidentOnboarding/SaveWalkthroughChecklist',
      {
        ItemList: itemList,
        ResidentWalkthroughID: residentWalkthroughID,
        SubmittedName: submittedName,
        SubmittedNote: submittedNote,
        SubmittedSignatureData: submittedSignaturedData
      },
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function saveResidentWalkthroughStatus(
  residentID,
  residentWalkthroughID,
  residentOnboardingStatusID,
  note
) {
  return api
    .post(
      'API/ResidentOnboarding/UpdateResidentWalkthroughStatus?residentWalkthroughID=' +
        residentWalkthroughID +
        '&residentOnboardingStatusID=' +
        residentOnboardingStatusID,
      { Value: note },
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function listCalendarEvent(calendarID, residentID) {
  return api
    .get(
      'API/ResidentOnboarding/ListCalendarEvent?calendarID=' + calendarID,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function saveCalendarEvent(calendarID, residentID, eventList) {
  return api
    .post(
      'API/ResidentOnboarding/SaveCalendarEvent?calendarID=' + calendarID,
      eventList,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function getResidentSubmittal(residentID, residentFormID, occupantID) {
  return api
    .get(
      'API/ResidentOnboarding/GetResidentSubmittal?residentFormID=' +
        residentFormID +
        '&occupantID=' +
        occupantID,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function saveResidentSubmittal(
  residentID,
  residentSubmittal,
  propertyRole
) {
  return api
    .post(
      'API/ResidentOnboarding/SaveResidentSubmittal' +
        (propertyRole
          ? '?propertyRole=' + encodeURIComponent(propertyRole)
          : ''),
      residentSubmittal,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function saveResidentSubmittalStatus(
  residentID,
  occupantID,
  residentFormID,
  residentOnboardingStatusID
) {
  return api
    .post(
      'API/ResidentOnboarding/SaveResidentSubmittalStatus?residentFormID=' +
        residentFormID +
        (occupantID ? '&occupantID=' + occupantID : '') +
        '&residentOnboardingStatusID=' +
        residentOnboardingStatusID,
      null,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function captureIDData(residentID, residentFileID, residentFormID) {
  return api
    .post(
      'API/ResidentOnboarding/CaptureIDData?residentFileID=' +
        residentFileID +
        '&residentFormID=' +
        residentFormID,
      null,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function getResidentPet(residentID, residentPetID) {
  return api
    .get(
      'API/ResidentOnboarding/GetResidentPet?residentPetID=' + residentPetID,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function updateResidentPetESAProviderResponse(residentID, pet) {
  return api
    .post(
      'API/ResidentOnboarding/UpdateResidentPetESAProviderResponse',
      pet,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function getResidentPetInterview(residentID, residentPetID) {
  return api
    .get(
      'API/ResidentOnboarding/GetResidentPetInterview?residentPetID=' +
        residentPetID,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function saveResidentPetInterview(residentID, interview) {
  return api
    .post(
      'API/ResidentOnboarding/SaveResidentPetInterview',
      interview,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function cancelResidentESA(
  residentID,
  residentPetID,
  signature,
  dateSigned
) {
  return api
    .post(
      'API/ResidentOnboarding/CancelResidentESA?residentPetID=' +
        residentPetID +
        '&signature=' +
        encodeURIComponent(signature) +
        '&dateSigned=' +
        encodeURIComponent(dateSigned),
      null,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function createPaymentIntent(residentID, paymentTypeID) {
  return api
    .post(
      'API/ResidentOnboarding/CreatePaymentIntent?paymentTypeID=' +
        paymentTypeID,
      null,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function listResidentEvent(residentID) {
  return api
    .get('API/ResidentOnboarding/ListResidentEvent', getConfig(residentID))
    .then((response) => {
      return response.data;
    });
}

export function createResidentEvent(residentID, request) {
  return api
    .post(
      'API/ResidentOnboarding/CreateResidentEvent',
      request,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function updateResidentEvent(residentID, request) {
  return api
    .post(
      'API/ResidentOnboarding/UpdateResidentEvent',
      request,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function deleteResidentEvent(residentID, residentEventID) {
  return api
    .post(
      'API/ResidentOnboarding/DeleteResidentEvent?residentEventID=' +
        residentEventID,
      null,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function updateResidentFile(
  residentID,
  residentFileList,
  residentEventID
) {
  return api
    .post(
      'API/ResidentOnboarding/UpdateResidentFile?residentEventID=' +
        residentEventID,
      residentFileList,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function getResidentOnboardingAudit(
  residentID,
  grouping,
  providerServiceID,
  residentFormID
) {
  return api
    .get(
      'API/ResidentOnboarding/GetResidentOnboardingAudit?grouping=' +
        encodeURIComponent(grouping) +
        (providerServiceID ? '&providerServiceID=' + providerServiceID : '') +
        (residentFormID ? '&residentFormID=' + residentFormID : ''),
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function saveResidentOnboardingAudit(residentID, audit) {
  return api
    .post(
      'API/ResidentOnboarding/SaveResidentOnboardingAudit',
      audit,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}

export function saveResidentOnboardingTask(
  residentID,
  taskKey,
  residentOnboardingStatusID
) {
  return api
    .post(
      'API/ResidentOnboarding/SaveResidentOnboardingTask?taskKey=' +
        encodeURIComponent(taskKey) +
        '&residentOnboardingStatusID=' +
        residentOnboardingStatusID,
      null,
      getConfig(residentID)
    )
    .then((response) => {
      return response.data;
    });
}
