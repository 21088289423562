import React from 'react';
import './index.css';

function UserIcon({ user }) {
  return user && user.FirstName ? (
    <div className="user-icon">
      <div
        className="color"
        style={user.ColorCode ? { backgroundColor: user.ColorCode } : {}}>
        <span className="initials">
          {user.FirstName.split(' ')
            .map((word) => word.substring(0, 1))
            .join('')}
          {user.LastName ? user.LastName.substring(0, 1) : ''}
        </span>
      </div>
    </div>
  ) : null;
}

export default UserIcon;
