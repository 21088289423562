import React from 'react';
import moment from 'moment';
import { Tooltip, Button } from '@mui/material';

import './index.css';

export function doesItemQualify(item) {
  switch (item.ResidentOnboardingStatusID) {
    case 1:
    case 9:
    case 10:
      if (!item.ResidentOnboardingStatusNameOverride) {
        switch (item.OnboardingSection) {
          case 'applicant':
            return item.InApplicantProcess;
          case 'pending':
            return item.IsResidentOnboarding;
          case 'notice':
            return item.InOnNoticeProcess;
          case 'pending':
            return item.InCurrentResidentProcess;
        }
      }
      break;
  }

  return true;
}

function ResidentOnboardingStatusBadge({ status, button, isProperty }) {
  return status ? (
    <Tooltip
      placement={button ? 'bottom' : 'left'}
      title={
        !doesItemQualify(status) ? (
          status.ResidentOnboardingStatusID === 1 ? (
            'This ' +
            (status.OnboardingSection === 'applicant'
              ? 'applicant'
              : 'resident') +
            ' has a lease start date before the MovingIN go live date and may have already submitted their paperwork to the leasing office.'
          ) : (
            ''
          )
        ) : status.Reason ? (
          <div
            dangerouslySetInnerHTML={{
              __html: status.Reason.split(',').join('<br />')
            }}></div>
        ) : (
          ''
        )
      }>
      <div
        className={
          'badge resident-onboarding-badge status-' +
          status.ResidentOnboardingStatusID +
          (status.ResidentOnboardingStatusName &&
          status.ResidentOnboardingStatusName.indexOf('Sent') === 0
            ? ' pending'
            : '') +
          (status.ResidentOnboardingStatusNameOverride ? ' override' : '') +
          (!doesItemQualify(status) ? ' not-qualified' : '')
        }>
        {isProperty &&
        !doesItemQualify(status) &&
        status.ResidentOnboardingStatusID === 1
          ? 'Manual Audit Required'
          : isProperty &&
            status.ResidentOnboardingStatusNameOverride &&
            status.ResidentOnboardingStatusID === 1 &&
            !status.IsNotApplicable
          ? status.ResidentOnboardingStatusNameOverride
          : isProperty &&
            status.ResidentOnboardingStatusID !== 0 &&
            status.ResidentOnboardingStatusID !== 3 &&
            status.ResidentOnboardingStatusID !== 4
          ? status.ResidentOnboardingStatusID === 1
            ? 'Waiting on Resident'
            : status.ResidentOnboardingStatusID === 2
            ? 'Ready to Review'
            : status.ResidentOnboardingStatusID === 5
            ? 'No Action Required'
            : status.ResidentOnboardingStatusID === 7
            ? 'Passed'
            : status.ResidentOnboardingStatusID === 8
            ? 'Failed'
            : status.ResidentOnboardingStatusID === 9 ||
              status.ResidentOnboardingStatusID === 10
            ? status.ResidentOnboardingStatusName
            : 'Optional'
          : status.ResidentOnboardingStatusName
          ? status.ResidentOnboardingStatusNameOverride &&
            status.OtherSigner &&
            status.ResidentOnboardingStatusNameOverride.indexOf(
              status.OtherSigner
            ) !== -1
            ? status.ResidentOnboardingStatusNameOverride
            : status.ResidentOnboardingStatusName
          : 'Action Required'}
      </div>
    </Tooltip>
  ) : null;
}

function ResidentOnboardingStatusBadgeWrapper({
  status,
  button,
  onClick,
  isProperty
}) {
  return button ? (
    <Button
      className="resident-onboarding-badge-button"
      onClick={(event) => {
        if (onClick) {
          onClick(event, status);
        }
      }}>
      <ResidentOnboardingStatusBadge
        status={status}
        button
        isProperty={isProperty}
      />
    </Button>
  ) : (
    <ResidentOnboardingStatusBadge status={status} isProperty={isProperty} />
  );
}

export default ResidentOnboardingStatusBadgeWrapper;
