import React, { useState, useRef, useEffect } from 'react';
import { withPrefix } from 'gatsby-link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import ResidentForm from './';

function ResidentFormPopup({
  closeFunc,
  item,
  actions,
  residentOnboarding,
  propertyRole,
  residentID
}) {
  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="resident-onboarding-checklist-popup resident-onboarding"
      fullWidth={true}>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        <img src={withPrefix('/images/logo-icon.svg')} className="logo-icon" />
        <div>{item.Description}</div>
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        <ResidentForm
          residentForm={item}
          closeFunc={closeFunc}
          propertyRole={propertyRole}
          residentID={residentID}
        />
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'right' }}>
        <Button
          onClick={() => {
            closeFunc();
          }}
          variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResidentFormPopup);
