//import firebase from 'firebase';
import React, { lazy } from 'react';
import * as api from './apiUtils';
import * as dataApi from './dataApi';

import AssessmentIcon from '@mui/icons-material/Assessment';
import PersonIcon from '@mui/icons-material/Person';
import StorageIcon from '@mui/icons-material/Storage';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BuildIcon from '@mui/icons-material/Build';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';

let isAuthenticated = false;
let connectInitted = false;

// export function checkAuthorizedUser(user, connectionId) {
//   return firebase
//     .auth()
//     .currentUser.getIdToken(/* forceRefresh */ true)
//     .then(function (idToken) {
//       var authRequest = {
//         login: user.email ? user.email : user.phoneNumber,
//         password: user.uid,
//         token: idToken
//       };

//       api.setAuthUserConfig(user.email, user.uid, idToken);

//       return api
//         .post(
//           (window.location.href.indexOf('localhost') !== -1
//             ? 'https://localhost:44319'
//             : 'https://' +
//               (window ? window.location.host : '') +
//               '/mi-admin-api') + '/API/Auth/LogIn',
//           authRequest
//         )
//         .then(function (response) {
//           if (response && response.data && response.data === 'WELCOME') {
//             var authUser = {
//               email: user.email,
//               uid: user.uid,
//               token: idToken,
//               adminName: response.data,
//               phoneNumber: user.phoneNumber,
//               displayName: user.displayName,
//               photoURL: user.photoURL,
//               preferenceDictionary: {},
//               links: {}
//             };

//             isAuthenticated = true;

//             authUser.userMenu = getUserMenu();
//             return authUser;
//           } else {
//             return {
//               unauthorized: true,
//               email: user.email,
//               links: 'unavailable'
//             };
//           }
//         })
//         .catch((error) => {
//           return {
//             unauthorized: true,
//             email: user.email,
//             links: 'unavailable'
//           };
//         });
//     });
// }

export function pageRequiresServiceAddress() {
  const route = dataApi.getCurrentRoute();
  if (route.indexOf('/property-') === 0) {
    return false;
  } else if (route.indexOf('/company-') === 0) {
    return false;
  } else if (route.indexOf('/partner-') === 0) {
    return false;
  }

  switch (route) {
    case '/':
      if (process.env.GATSBY_BRAND_ID === 'sc') {
        return true;
      }
      return false;
    case '/privacy-policy':
    case '/terms':
    case '/ccpa':
    case '/ccpa-request':
    case '/texas-disclosure':
    case '/contact':
      return false;
  }

  return true;
}

export function saveConnectionId(connectionId, deviceInfo) {
  var visitInfo = getVisitInfo();

  const invitation = getInvitationKey();

  let fsUrl = '';
  if (
    typeof window !== 'undefined' &&
    window.FS &&
    window.FS.getCurrentSessionURL
  ) {
    fsUrl = window.FS.getCurrentSessionURL();
  }

  const saveWebSocketConnectionOnly = connectInitted;
  const knownServiceAddressID = dataApi.getSessionStorage('mi-service-address');
  connectInitted = true;

  return api
    .post(
      'API/User/SaveConnectionId?connectionId=' +
        (connectionId ? connectionId : '') +
        (saveWebSocketConnectionOnly
          ? '&saveWebSocketConnectionOnly=true'
          : '') +
        '&knownServiceAddressID=' +
        (knownServiceAddressID ? knownServiceAddressID : '0') +
        '&visitorKey=' +
        (visitInfo.VisitorKey && visitInfo.VisitorKey != 'undefined'
          ? visitInfo.VisitorKey
          : '') +
        '&visitKey=' +
        (visitInfo.VisitKey && visitInfo.VisitorKey != 'undefined'
          ? visitInfo.VisitKey
          : '') +
        '&brandId=' +
        process.env.GATSBY_BRAND_ID +
        (invitation.invitationKey
          ? '&invitationKey=' +
            invitation.invitationKey +
            '&invitationFromCookie=' +
            (invitation.fromCookie ? 'true' : 'false')
          : '') +
        (visitInfo.DeviceToken ? '&deviceToken=' + visitInfo.DeviceToken : '') +
        (deviceInfo
          ? '&os=' +
            deviceInfo.osName +
            ' ' +
            deviceInfo.osVersion +
            '&browserName=' +
            deviceInfo.browserName +
            '&browserVersion=' +
            deviceInfo.browserFullVersion
          : '') +
        (!pageRequiresServiceAddress()
          ? '&pageRequiresServiceAddress=false'
          : '') +
        (visitInfo.UserToken
          ? '&user=' + encodeURIComponent(visitInfo.UserToken)
          : ''),
      {
        FSUrl: fsUrl
      }
    )
    .then((response) => {
      if (response && response.data) {
        api.setDefaultConfig(
          connectionId,
          response.data.VisitorKey,
          response.data.VisitKey
        );

        setVisitInfo(
          response.data.VisitorKey,
          response.data.VisitKey,
          response.data && response.data.Invitation
            ? response.data.Invitation.DeviceToken
            : null
        );

        if (
          response &&
          response.data &&
          response.data.UserLoginResult &&
          response.data.UserLoginResult.User &&
          response.data.UserLoginResult.User.Token
        ) {
          setUserToken(response.data.UserLoginResult.User.Token);
        }

        if (typeof window !== 'undefined') {
          if (window.FS) {
            console.log('FS identified');

            window.FS.identify(response.data.VisitorKey);

            if (!fsUrl) {
              //If it wasn't initialized before, store it now
              if (
                typeof window !== 'undefined' &&
                window.FS &&
                window.FS.getCurrentSessionURL
              ) {
                fsUrl = window.FS.getCurrentSessionURL();
              }

              if (fsUrl) {
                saveUserMetric({ FSUrl: fsUrl });
              } else {
                console.log('No FS Url');
              }
            }
          }

          if (window.gonative) {
            try {
              if (window.gonative.deviceInfo) {
                window.gonative.deviceInfo().then(function (deviceInfo) {
                  console.log('Mobile device info', deviceInfo);
                  saveUserMetric({
                    MobileAppDevice: JSON.stringify(deviceInfo)
                  });
                });
              }

              if (window.gonative.onesignal) {
                window.gonative.onesignal
                  .onesignalInfo()
                  .then(function (oneSignalInfo) {
                    saveUserMetric({
                      OneSignalInfo: JSON.stringify(oneSignalInfo)
                    });
                  });
              }
            } catch (e) {
              if (e) {
                alert(e.message);
              }
            }
          }

          if (location.href) {
            const urlArr = location.href.split('?');
            if (urlArr[1]) {
              const varArr = urlArr[1].split('&');
              if (varArr && varArr.length) {
                varArr.map((nameVal) => {
                  const nameValArr = nameVal.split('=');
                  switch (nameValArr[0]) {
                    case 'uc':
                      saveUserMetric({ Campaign: nameValArr[1] });
                      break;
                    case 'partner':
                      saveUserMetric({ Partner: nameValArr[1] });
                      break;
                  }
                });
              }
            }

            if (!dataApi.getSessionStorage('mi-initial-load')) {
              dataApi.setSessionStorage('mi-initial-load', '1');

              let metrics = { LandingPage: location.href };
              if (document.referrer) {
                metrics.Referrer = document.referrer;
              }

              saveUserMetric(metrics);
            }
          }
        }
      }
      return response;
    });
}

export function saveUserMetric(userMetrics) {
  var visitInfo = getVisitInfo();

  const invitation = getInvitationKey();

  return api
    .post(
      'API/User/SaveUserMetric?visitorKey=' +
        (visitInfo.VisitorKey && visitInfo.VisitorKey != 'undefined'
          ? visitInfo.VisitorKey
          : '') +
        '&visitKey=' +
        (visitInfo.VisitKey && visitInfo.VisitorKey != 'undefined'
          ? visitInfo.VisitKey
          : '') +
        (invitation.invitationKey
          ? '&invitationKey=' +
            invitation.invitationKey +
            '&invitationFromCookie=' +
            (invitation.fromCookie ? 'true' : 'false')
          : ''),
      userMetrics
    )
    .then((response) => {
      return response;
    });
}

export function saveUserEvent(eventCategory, eventName, eventValue) {
  var visitInfo = getVisitInfo();

  return api
    .post(
      'API/User/SaveUserEvent?visitorKey=' +
        (visitInfo.VisitorKey && visitInfo.VisitorKey != 'undefined'
          ? visitInfo.VisitorKey
          : '') +
        '&visitKey=' +
        (visitInfo.VisitKey && visitInfo.VisitorKey != 'undefined'
          ? visitInfo.VisitKey
          : '') +
        '&eventCategory=' +
        encodeURIComponent(eventCategory) +
        '&eventName=' +
        encodeURIComponent(eventName),
      { Value: eventValue }
    )
    .then((response) => {
      return response;
    });
}

export function getVisitInfo() {
  return {
    VisitorKey: dataApi.getCookie('mi-visitor'),
    VisitKey: dataApi.getSessionStorage('mi-visit'),
    DeviceToken: dataApi.getCookie('mi-d'),
    UserToken: dataApi.getCookie('mi-user')
  };
}

function getInvitationKey() {
  let invitationKey = dataApi.getCookie('mi-invitation');
  let fromCookie = invitationKey ? true : false;

  if (typeof window !== 'undefined') {
    let requestUrl = window.location.search;
    if (requestUrl && requestUrl.indexOf('invite=') !== -1) {
      invitationKey = requestUrl.split('invite=')[1];
      if (invitationKey.indexOf('&') !== -1) {
        invitationKey = invitationKey.split('&')[0];
      }

      fromCookie = false;
    }
  }

  if (invitationKey) {
    dataApi.setCookie('mi-invitation', invitationKey);
    dataApi.setCookie('mi-user', '');

    if (dataApi.getSessionStorage('mi-selected-resident')) {
      dataApi.setSessionStorage('mi-selected-resident', '');
    }

    if (dataApi.getSessionStorage('mi-selected-address')) {
      dataApi.setSessionStorage('mi-selected-address', '');
    }
  }

  return { invitationKey, fromCookie };
}

function setVisitInfo(visitorKey, visitKey, deviceToken) {
  dataApi.setCookie('mi-visitor', visitorKey);
  dataApi.setSessionStorage('mi-visit', visitKey);

  if (deviceToken) {
    dataApi.setCookie('mi-d', deviceToken);
  }
}

function getUserMenu() {
  let arrMenu = [
    {
      label: 'Home',
      to: '/'
    }
  ];

  return arrMenu;
}

export function signOutUser() {
  api.setDefaultConfig(null, null, null);
  api.setAuthUserConfig(null, null, null);
}

export function registerUser(email, firstName, lastName, password, profileKey) {
  return api
    .post('API/User/RegisterUser', {
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      Password: password,
      Property: profileKey
    })
    .then((response) => {
      return response.data;
    });
}

export const PasswordPolicy =
  'Passwords must be between 6 and 20 characters and must contain at least one letter and one number';
export function validatePassword(str) {
  const { length: l } = str;
  const strArr = str.split('');
  if (l < 6 || l > 20) {
    console.log('too short');
    return false;
  }
  const alphabets = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const checkWith = (char, set) => set.includes(char);
  const containsLowercase = strArr.some((char) => checkWith(char, alphabets));
  const containsUppercase = strArr.some((char) =>
    checkWith(char, alphabets.toUpperCase())
  );
  const containsNumber = strArr.some((char) => checkWith(char, numbers));
  console.log('containsLowercase', containsLowercase);
  console.log('containsUppercase', containsUppercase);
  console.log('containsNumber', containsNumber);
  console.log('***');
  return (containsLowercase || containsUppercase) && containsNumber;
}

export function validateEmail(email) {
  if (/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm.test(email)) {
    return true;
  }
  return false;
}

export function requestPasswordReset(email) {
  return api
    .post('API/User/RequestPasswordReset?email=' + encodeURIComponent(email))
    .then((response) => {
      return response.data;
    });
}

export function confirmPasswordReset(request) {
  return api.post('API/User/ConfirmPasswordReset', request).then((response) => {
    return response.data;
  });
}

export function setUserToken(token) {
  dataApi.setCookie('mi-user', token);
  dataApi.setCookie('mi-invitation', '');
}

export function logIn(email, password) {
  return api
    .post('API/User/Login', { Email: email, Password: password })
    .then((response) => {
      if (response.data && response.data.User && response.data.User.Token) {
        setUserToken(response.data.User.Token);
      }

      return response.data;
    });
}

export function getUserSetup(setupKey) {
  return api
    .get('API/User/GetUserSetup?setupKey=' + encodeURIComponent(setupKey))
    .then((response) => {
      return response.data;
    });
}

export function saveUserSetup(request) {
  return api.post('API/User/SaveUserSetup', request).then((response) => {
    return response.data;
  });
}

export function requestAccessCode(email, phone) {
  return api
    .post(
      'API/User/RequestAccessCode?email=' +
        (email ? encodeURIComponent(email) : '') +
        '&phone=' +
        (phone ? encodeURIComponent(phone) : '')
    )
    .then((response) => {
      return response.data;
    });
}

export function confirmAccessCode(email, phone, accessCode) {
  return api
    .post(
      'API/User/ConfirmAccessCode?email=' +
        (email ? encodeURIComponent(email) : '') +
        '&phone=' +
        (phone ? encodeURIComponent(phone) : '') +
        '&accessCode=' +
        (accessCode ? encodeURIComponent(accessCode) : '')
    )
    .then((response) => {
      return response.data;
    });
}
