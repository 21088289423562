import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { Alert, LinearProgress } from '@mui/material';

import PaymentForm from './PaymentForm';

const stripePromise = loadStripe(
  'pk_test_51Nj2SWC8lc2mAxFKr2asvqHXZ6BsCxxiC2Qh7eCIYnI0JIFkvalgCY5eitjRO1GxyNeWI5yJsBD0hdZvdvXUvT8r00LyNsDpHs'
);

function OnlinePaymentForm({ paymentIntentFunc }) {
  const [clientSecret, setClientSecret] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    paymentIntentFunc().then((result) => {
      if (result.ClientSecret) {
        setClientSecret(result.ClientSecret);
      } else {
        setError(result.Message);
      }
    });
  }, []);

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#337eff'
    }
  };
  const options = {
    clientSecret,
    appearance
  };

  return (
    <div className="online-payment-form">
      {clientSecret ? (
        <div className="form-content">
          <Elements options={options} stripe={stripePromise}>
            <PaymentForm />
          </Elements>
        </div>
      ) : error ? (
        <Alert severity="error">
          <p>An error occurred loading the payment form: {error}</p>
          <p>Please contact {siteConfig.email} for assistance.</p>
        </Alert>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
}

export default OnlinePaymentForm;
