import React from 'react';
import Check from '@mui/icons-material/Check';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import DeckIcon from '@mui/icons-material/Deck';
import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector';
import CircularProgress, {
  circularProgressClasses
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';

export const vehicleField = {
  VehicleRegistrationIncludeYear: 'Year',
  VehicleRegistrationIncludeMake: 'Make',
  VehicleRegistrationIncludeModel: 'Model',
  VehicleRegistrationIncludeColor: 'Color',
  VehicleRegistrationIncludeLicensePlateNumber: 'License Plate Number',
  VehicleRegistrationIncludeLicensePlateState: 'License Plate State',
  VehicleRegistrationIncludeVIN: 'Vehicle Identification Number',
  VehicleRegistrationIncludeProofOfInsurance: 'Proof of Insurance'
};

export const petField = {
  PetIncludeName: 'Name',
  PetIncludeType: 'Pet Type (Dog, Cat, etc.)',
  PetIncludeBreed: 'Breed (Dogs only)',

  PetIncludeColor: 'Primary Color',
  PetIncludeWeight: 'Weight',
  PetIncludeOwnerName: 'Owner (responsible party)',
  PetIncludeAge: 'Age',
  PetIncludeGender: 'Gender',
  PetIncludeSpayedNeutered: 'Spayed or Neutered?',

  //PetIncludeCoatLength: 'Coat Length',
  PetIncludeProofOfVaccinations: 'Proof of Vaccinations'
};

export const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#337eff'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#337eff'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1
  }
}));

export const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#337eff'
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#337eff',
    zIndex: 1,
    fontSize: 18
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  }
}));

export function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

export function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
        }}
        size={60}
        thickness={2}
        {...props}
        value={100}
      />

      <CircularProgress
        variant="determinate"
        {...props}
        disableShrink
        sx={{
          color: '#337eff',
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round'
          }
        }}
        size={60}
        thickness={2}
      />

      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          style={{ fontWeight: 'bold' }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export const dayOfWeekList = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

export const businessHourList = [
  '5:00 am',
  '6:00 am',
  '7:00 am',
  '8:00 am',
  '9:00 am',
  '10:00 am',
  '11:00 am',
  '12:00 pm',
  '1:00 pm',
  '2:00 pm',
  '3:00 pm',
  '4:00 pm',
  '5:00 pm',
  '6:00 pm',
  '7:00 pm',
  '8:00 pm',
  '9:00 pm'
];

export function checkProviderServiceHasDefaultProvider(
  serviceProfile,
  activeStep,
  providerService
) {
  return serviceProfile
    ? serviceProfile.ServiceSectionList[activeStep].ProviderList.filter(
        (provider) =>
          provider.ProviderServiceID === providerService.ProviderServiceID &&
          provider.ProviderName
      ).length > 0
    : null;
}
