import React, { useState, useRef, useEffect, version } from 'react';
import ReactDOMServer from 'react-dom/server';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PetsIcon from '@mui/icons-material/Pets';

import { Alert } from '@material-ui/lab';

import { Tooltip } from '@material-ui/core';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import DownloadIcon from '@mui/icons-material/Download';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress
} from '@mui/material';
import { saveResidentPetNotes } from '../../../api/propertyProfileApi';
import { scrollToElementInPage } from '../../../api/dataApi';
import {
  getResidentPet,
  updateResidentPetESAProviderResponse,
  cancelResidentESA,
  listResidentEvent,
  createResidentEvent,
  updateResidentEvent,
  deleteResidentEvent
} from '../../../api/residentOnboardingApi';

import * as webSocketActions from '../../../redux/actions/webSocketActions';
import * as residentOnboardingActions from '../../../redux/actions/residentOnboardingActions';

import ResidentOnboardingFileUpload, {
  getFileDownloadUrl
} from '../ResidentOnboardingFileUpload';
import ResidentOnboardingStatusBadge from '../ResidentOnboardingStatusBadge';
import ResidentOnboardingPhotoUpload, {
  getImageDownloadUrl
} from '../ResidentOnboardingPhotoUpload';
import ResidentOnboardingSaveWarning from '../ResidentOnboardingSaveWarning';
import ResidentOnboardingStatusUpdate from '../ResidentOnboardingStatusUpdate';
import GeneralPopup from '../../GeneralPopup';
import PetInterview from '../../OnboardingDashboard/PetInterview';
import ResidentPetPayment from './ResidentPetPayment';
import ResidentEventList from '../ResidentEventList';

import 'react-dropzone-uploader/dist/styles.css';
import '../index.css';
import moment from 'moment';

const registrationFeeReasonText = 'Registration fee required,';

export const petTypeList = ['Dog', 'Cat', 'Other'];
export const genderList = ['Male', 'Female'];
export const sizeList = [
  'Small (0-25 lbs)',
  'Medium (26-60 lbs)',
  'Large (61-100 lbs)',
  'Extra Large (101 lbs or more)'
];
export const coatLengthList = ['Short', 'Medium', 'Long'];
export const colorList = [
  'Black',
  'Brown / Chocolate',
  'Tan / Yellow / Blond / Fawn',
  'Gray / Blue / Silver',
  'Red / Chestnut / Orange',
  'White / Cream'
];

export function getPetStepList(pet, residentOnboarding) {
  console.log('getPetStepList', residentOnboarding);
  let _stepList = [
    !pet.ResidentOnboardingStatusID || pet.ResidentOnboardingStatusID === 1
      ? 'Pet Registration'
      : 'Registration Submitted',
    !pet.ResidentOnboardingStatusID || pet.ResidentOnboardingStatusID === 1
      ? !residentOnboarding ||
        !residentOnboarding.Header ||
        !residentOnboarding.Header.PetRegistrationFeeAmount
        ? 'Queue for Review'
        : residentOnboarding.Header.PetRegistrationFeeAmountPaid
        ? 'Registration Fee Paid'
        : 'Pay Registration Fee'
      : pet.AnimalCategoryID === 2
      ? pet.AssistanceAnimalTypeID === 1 &&
        pet.ESAProvider_IsValidResponse === null
        ? 'ESA Hold - YES'
        : 'Accommodation ' +
          (pet.ResidentOnboardingStatusID > 2 ? 'Approved' : 'Requested')
      : residentOnboarding.Header.PetRegistrationFeeAmountPaid
      ? 'Registration Fee Paid'
      : 'Queued for Review',
    pet.ResidentOnboardingStatusID <= 2
      ? 'Pending Property Review'
      : 'Accepted by Property',
    'Registration Complete'
  ];

  return _stepList;
}

export function getPetActiveStep(pet) {
  return !pet.ResidentOnboardingStatusID || pet.ResidentOnboardingStatusID === 1
    ? pet.AnimalCategoryID === 1 && pet.Reason === registrationFeeReasonText
      ? 1
      : 0
    : pet.ResidentOnboardingStatusID === 2
    ? pet.AnimalCategoryID === 2
      ? pet.AssistanceAnimalTypeID !== 1 ||
        pet.ESAProvider_IsValidResponse !== null
        ? 2
        : 1
      : 2
    : 999;
}

function PetPolicyCheckbox({
  residentOnboardingSetting,
  isDisabled,
  residentOnboarding,
  updateResidentOnboardingHeader,
  setResidentOnboardingSetting
}) {
  return (
    <div className={'acknowledgment'}>
      <FormControlLabel
        control={
          <Checkbox
            checked={residentOnboardingSetting.IsPetPolicyCheckboxChecked}
            disabled={isDisabled}
            onChange={(event) => {
              updateResidentOnboardingHeader({
                ...residentOnboarding.Header,
                IsPetPolicyCheckboxChecked: event.target.checked
              });
              setResidentOnboardingSetting({
                ...residentOnboardingSetting,
                IsPetPolicyCheckboxChecked: event.target.checked
              });
            }}
            name="gilad"
          />
        }
        label={<>Resident acknowledgment of pet policy</>}
      />
      {residentOnboarding.Header.PetPolicyText ? (
        <div
          className="message"
          dangerouslySetInnerHTML={{
            __html:
              residentOnboarding.Header.PetPolicyText.split('\n').join('<br />')
          }}></div>
      ) : null}
    </div>
  );
}

function PetPassNoField({ value, editPet, pet, field, isDisabled }) {
  return (
    <FormControlLabel
      value={value}
      className={
        editPet.Fields[field.FieldName] &&
        editPet.Fields[field.FieldName].Answer === value
          ? 'selected'
          : ''
      }
      disabled={isDisabled || pet.ResidentOnboardingStatusID > 1}
      control={<Radio color="primary" />}
      label={
        <>
          {value}
          <div className="icon">
            {editPet.Fields[field.FieldName] &&
            editPet.Fields[field.FieldName].Answer &&
            editPet.Fields[field.FieldName].Answer === value ? (
              editPet.Fields[field.FieldName].Answer ===
              editPet.Fields[field.FieldName].DesiredAnswer ? (
                <CheckCircleOutlineIcon />
              ) : (
                <HighlightOffIcon className="negative" />
              )
            ) : null}
          </div>
        </>
      }
    />
  );
}

function ResidentOnboardingPet({
  actions,
  serviceAddress,
  siteConfig,
  residentOnboarding,
  refreshResidentOnboarding,
  updateResidentOnboardingHeader,
  stateList,
  residentID,
  closeOnboardingRow,
  residentPetID,
  profileKey,
  grouping,
  onboardingDashboardRow,
  authUser
}) {
  const [editPet, setEditPet] = useState(null);
  const [residentOnboardingStatus, setResidentOnboardingStatus] =
    useState(null);
  const [residentOnboardingSetting, setResidentOnboardingSetting] =
    useState(null);

  const [residentPetList, setResidentPetList] = useState(null);
  const [deletePet, setDeletePet] = useState(null);
  const [isDisabled, setIsDisabled] = useState(null);
  const [saveWarning, setSaveWarning] = useState(null);
  const [residentStatusUpdate, setResidentStatusUpdate] = useState(null);
  const [editNote, setEditNote] = useState({ edit: false, note: null });
  const [showHudGuidance, setShowHudGuidance] = useState(null);
  const [showPetInterview, setShowPetInterview] = useState(null);
  const [petUpdated, setPetUpdated] = useState(null);
  const [cancelESA, setCancelESA] = useState(null);
  const [showPetPayment, setShowPetPayment] = useState(null);
  const [validationErrorList, setValidationErrorList] = useState(null);

  useEffect(() => {
    if (
      residentOnboarding &&
      (!residentPetList ||
        residentPetList.length !== residentOnboarding.PetList.length ||
        petUpdated)
    ) {
      setPetUpdated(false);
      loadResidentPetList();
    }
  }, [residentOnboarding]);

  useEffect(() => {
    checkIsDisabled();
  }, [residentOnboardingSetting]);

  useEffect(() => {
    if (document.getElementById('textboxPetNote')) {
      document.getElementById('textboxPetNote').focus();
    }
  }, [editNote]);

  useEffect(() => {
    if (residentOnboarding) {
      let _residentOnboardingStatus = null;
      if (residentOnboarding.Header.HasNoPet) {
        if (residentOnboarding.Header.HasNoPetConfirmed === true) {
          _residentOnboardingStatus = {
            ResidentOnboardingStatusID: 3,
            ResidentOnboardingStatusName: 'Completed'
          };
        } else if (residentOnboarding.Header.HasNoPetConfirmed === false) {
          _residentOnboardingStatus = {
            ResidentOnboardingStatusID: 1,
            ResidentOnboardingStatusName: 'Action Required'
          };
        } else {
          _residentOnboardingStatus = {
            ResidentOnboardingStatusID: 2,
            ResidentOnboardingStatusName: 'Ready to Review'
          };
        }
      } else if (residentPetList && residentPetList.length) {
        residentPetList.map((pet) => {
          if (
            !_residentOnboardingStatus ||
            pet.ResidentOnboardingStatusID <
              _residentOnboardingStatus.ResidentOnboardingStatusID
          ) {
            _residentOnboardingStatus = { ...pet };
          }
        });
      } else {
        _residentOnboardingStatus = {
          ResidentOnboardingStatusID: 1,
          ResidentOnboardingStatusName: 'Action Required'
        };
      }

      if (!residentOnboardingSetting) {
        loadResidentOnboardingSetting();
      }

      setResidentOnboardingStatus(_residentOnboardingStatus);

      if (!editPet && residentPetID && residentPetList) {
        setEditPet({
          loading: true,
          petIndex: 0
        });
      }
    }
  }, [residentOnboarding, residentPetList]);

  useEffect(() => {
    if (editPet) {
      if (editPet.loading) {
        var _pet = residentPetList[editPet.petIndex];
        if (_pet) {
          getResidentPet(_pet.ResidentID, _pet.ResidentPetID).then(
            (_editPet) => {
              let _fields = {};

              _editPet.FieldList.map((_field) => {
                _fields[_field.FieldName] = { ..._field };
              });

              setEditPet({
                ..._editPet,

                petIndex: editPet.petIndex,
                FieldList: [
                  {
                    FieldName: 'HasRegularVetVisit',
                    Question: (
                      <>
                        Does your animal go to a veterinarian on at least an
                        annual basis for shots and checkups?
                      </>
                    ),
                    DesiredAnswer: 'Yes'
                  },
                  {
                    FieldName: 'IsRegularlyVaccinated',
                    Question: (
                      <>
                        Do you agree to keep your animal fully vaccinated and
                        up-to-date on booster shots, as recommended by The
                        American Animal Hospital Association (
                        <a href={'https://www.aaha.org'} target="mi_offsite">
                          www.aaha.org
                        </a>
                        ), during the entirety of your lease?
                      </>
                    ),
                    DesiredAnswer: 'Yes'
                  },
                  {
                    FieldName: 'IsFriendly',
                    Question: (
                      <>
                        Does your animal interact well with other animals and
                        people, especially small children?
                      </>
                    ),
                    DesiredAnswer: 'Yes'
                  },
                  {
                    FieldName: 'IsAggressive',
                    Question: (
                      <>
                        Has your animal ever shown aggressive behavior towards
                        other animals or people, especially small children?
                      </>
                    ),

                    DesiredAnswer: 'No'
                  },
                  {
                    FieldName: 'HasBitten',
                    Question: (
                      <>Has your animal ever bitten another animal or person?</>
                    ),
                    DesiredAnswer: 'No'
                  },
                  {
                    FieldName: 'IsSafetyRisk',
                    Question: (
                      <>
                        Does your animal pose a potential safety risk to someone
                        entering your unit in your absence, such as maintenance
                        personnel?
                      </>
                    ),
                    DesiredAnswer: 'No'
                  },
                  {
                    FieldName: 'IsOwnerResponsible',
                    Question: (
                      <>
                        Do you agree to take personal responsibility for the
                        actions of your animal?
                      </>
                    ),
                    DesiredAnswer: 'Yes'
                  },
                  {
                    FieldName: 'IsOwnerInsured',
                    Question: (
                      <>
                        Do you agree to maintain adequate insurance to cover
                        potential animal liability and property damage claims?
                      </>
                    ),
                    DesiredAnswer: 'Yes'
                  },
                  {
                    FieldName: 'IsOwnerAcceptResponsbility',
                    Question: (
                      <>
                        Do you agree to defend, indemnify, and hold harmless the
                        property owner, management company, and staff against
                        all actions, litigation, and consequences resulting from
                        the actions or behavior of you animal?
                      </>
                    ),
                    DesiredAnswer: 'Yes'
                  },
                  {
                    FieldName: 'IsResearchVaccination',
                    Question: (
                      <>
                        Do you agree to research and abide by all state and
                        local animal vaccination laws and requirements?
                      </>
                    ),
                    DesiredAnswer: 'Yes'
                  },
                  {
                    FieldName: 'IsResearchRegistration',
                    Question: (
                      <>
                        Do you agree to research and abide by all state and
                        local animal registration laws, ordinances, and
                        requirements?
                      </>
                    ),
                    DesiredAnswer: 'Yes'
                  }
                ],
                Fields: _fields
              });
            }
          );
        }
      }
    }
  }, [editPet]);

  useEffect(() => {
    let _validationErrorList = [];
    let _allCheckboxesChecked = true;

    console.log(
      '_validationErrorList',
      editPet,
      residentOnboarding,
      residentPetList
    );

    if (editPet && editPet.Pet && residentOnboarding && residentPetList) {
      residentPetList.map((pet, petIndex) => {
        if (pet.ResidentPetID === editPet.Pet.ResidentPetID) {
          if (residentOnboarding.Header.PetIncludeName && !pet.Name) {
            _validationErrorList.push("Enter your animal's Name");
          }

          if (residentOnboarding.Header.PetIncludeType && !pet.PetType) {
            _validationErrorList.push("Enter your animal's Type");
          }

          if (
            residentOnboarding.Header.PetIncludeBreed &&
            pet.PetType === 'Dog' &&
            !pet.Breed
          ) {
            _validationErrorList.push("Enter your animal's Breed");
          }

          if (
            residentOnboarding.Header.PetIncludeColor &&
            pet.PetType !== 'Other' &&
            !pet.Color
          ) {
            _validationErrorList.push("Enter your animal's Color");
          }

          if (residentOnboarding.Header.PetIncludeWeight && !pet.Weight) {
            _validationErrorList.push("Enter your animal's Weight");
          }

          if (residentOnboarding.Header.PetIncludeGender && !pet.Gender) {
            _validationErrorList.push("Enter your animal's Gender");
          }

          if (residentOnboarding.Header.PetIncludeAge && !pet.Age) {
            _validationErrorList.push("Enter your animal's Age");
          }

          if (
            residentOnboarding.Header.PetIncludeSpayedNeutered &&
            (pet.IsSpayedNeutered === undefined ||
              pet.IsSpayedNeutered === null)
          ) {
            _validationErrorList.push(
              'Specify whether your animal is spayed/neutered'
            );
          }

          let _allPhotosUploaded = true;

          getRequiredPhotoList()
            .filter((description) => description.indexOf('Favorite') === -1)
            .map((description) => {
              if (
                !residentOnboarding.FileList ||
                !residentOnboarding.FileList.filter(
                  (file) =>
                    file.ResidentFileTypeID === 4 &&
                    file.ResidentPetID === pet.ResidentPetID &&
                    file.Description === description
                ).length
              ) {
                _allPhotosUploaded = false;
              }
            });

          if (!_allPhotosUploaded) {
            _validationErrorList.push('Upload all animal photos');
          }

          if (
            residentOnboarding.Header.PetIncludeProofOfVaccinations &&
            pet.PetType !== 'Other' &&
            (!residentOnboarding.FileList ||
              !residentOnboarding.FileList.filter(
                (file) =>
                  file.ResidentFileTypeID === 5 &&
                  file.ResidentPetID === pet.ResidentPetID
              ).length)
          ) {
            _validationErrorList.push('Upload proof of vaccination');
          }

          if (!_allPhotosUploaded) {
            _validationErrorList.push('Upload all animal photos');
          }

          if (petIndex === 0) {
            if (
              residentOnboarding.Header.PetPolicyIncludeCheckbox &&
              !residentOnboardingSetting.IsPetPolicyCheckboxChecked
            ) {
              _allCheckboxesChecked = false;
            }

            if (
              residentOnboarding.Header.PetLiabilityIncludeCheckbox &&
              !residentOnboardingSetting.IsPetLiabilityCheckboxChecked
            ) {
              _allCheckboxesChecked = false;
            }

            if (
              residentOnboarding.Header.PetInterviewRequired &&
              !residentOnboardingSetting.IsPetInterviewCheckboxChecked
            ) {
              _allCheckboxesChecked = false;
            }
          }

          if (!pet.AnimalCategoryID) {
            _validationErrorList.push(
              'Specify what kind of animal this is (Pet or Assistance Animal)'
            );
          }

          if (pet.AnimalCategoryID == 2 && !pet.AssistanceAnimalTypeID) {
            _validationErrorList.push(
              'Specify the type of assistance animal this is'
            );
          }

          if (
            pet.AnimalCategoryID == 2 &&
            pet.AssistanceAnimalTypeID == 1 &&
            (!pet.ESA_PolicyCheckbox ||
              !pet.ESAPatient_Name ||
              !pet.ESAPatient_DOB ||
              pet.ESAPatient_DOB.indexOf('_') !== -1 ||
              !pet.ESAProvider_Name ||
              !pet.ESAProvider_DateDocument ||
              pet.ESAProvider_DateDocument.indexOf('_') !== -1 ||
              !pet.ESAProvider_Email ||
              !pet.ESAProvider_Phone ||
              !pet.ESAProvider_AddressLine1 ||
              !pet.ESAProvider_City ||
              !pet.ESAProvider_State ||
              !pet.ESAProvider_Zip ||
              !residentOnboarding.FileList ||
              !residentOnboarding.FileList.filter(
                (file) =>
                  file.ResidentPetID === pet.ResidentPetID &&
                  file.ResidentFileTypeID === 8
              ).length)
          ) {
            _validationErrorList.push(
              'Enter all fields required to submit this Reasonable Accommodation Request'
            );
          }

          if (
            pet.AnimalCategoryID == 2 &&
            pet.AssistanceAnimalTypeID == 2 &&
            (pet.SA_IsDefinitionMatch == undefined ||
              pet.SA_IsDefinitionMatch == undefined ||
              !pet.SA_TrainingDescription)
          ) {
            _validationErrorList.push(
              'Enter all fields required related to the ADA definition of a service animal'
            );
          }

          if (
            pet.AnimalCategoryID == 2 &&
            pet.AssistanceAnimalTypeID == 3 &&
            (pet.AA_HasDisability == undefined ||
              pet.AA_IsObservable == undefined ||
              pet.AA_IsAnimalProvidesAssistance == undefined ||
              (pet.AA_IsObservable === false &&
                (!residentOnboarding.FileList ||
                  !residentOnboarding.FileList.filter(
                    (file) =>
                      file.ResidentPetID === pet.ResidentPetID &&
                      file.ResidentFileTypeID === 10
                  ).length)) ||
              (pet.AA_IsAnimalProvidesAssistance &&
                !pet.AA_TrainingDescription))
          ) {
            _validationErrorList.push(
              "Enter all fields required related to Assistance Animal type of 'Other'"
            );
          }

          if (
            editPet.FieldList.filter(
              (field) =>
                !editPet.Fields[field.FieldName] ||
                editPet.Fields[field.FieldName].Answer == undefined
            ).length
          ) {
            _validationErrorList.push(
              'Add a response for each field under the Statement of Facts'
            );
          }

          if (!pet.FieldAnswerCheckbox || !pet.SignerSignature) {
            _validationErrorList.push(
              'Provide your digital signature by checking the box above and typing your name'
            );
          }
        }
      });
    }

    setValidationErrorList(_validationErrorList);
  }, [editPet, residentOnboardingSetting, residentOnboarding, residentPetList]);

  function loadResidentOnboardingSetting() {
    setResidentOnboardingSetting({ ...residentOnboarding.Header });
  }

  function loadResidentPetList(cancelEdit) {
    let _residentPetList = [].concat(residentOnboarding.PetList);

    _residentPetList = _residentPetList.map((pet) => {
      let _pet = { ...pet };

      let _color = '';
      let _petType = '';
      let _gender = '';

      if (_pet.Color) {
        _color = colorList.filter(
          (c) => c.toLowerCase() === _pet.Color.toLowerCase()
        )[0];
      }

      if (_pet.Gender) {
        _gender = genderList.filter(
          (g) => g.toLowerCase() === _pet.Gender.toLowerCase()
        )[0];
      }

      if (_pet.PetType) {
        _petType = petTypeList.filter(
          (t) => t.toLowerCase() === _pet.PetType.toLowerCase()
        )[0];
      }

      const _item = residentOnboarding.Checklist.filter(
        (item) =>
          (item.Grouping === 'pet-applicant' ||
            item.Grouping === 'pet-registration') &&
          item.ResidentPetID === pet.ResidentPetID
      )[0];

      return {
        ..._item,
        ..._pet,
        Color: _color ? _color : '',
        PetType: _petType ? _petType : '',
        Gender: _gender ? _gender : ''
      };
    });

    if (!cancelEdit && residentPetList && editPet) {
      _residentPetList = _residentPetList.map((pet) => {
        const editedPet = residentPetList.filter(
          (_pet) => _pet.ResidentPetID === pet.ResidentPetID
        )[0];
        if (editedPet) {
          return {
            ...pet,
            ...editedPet,
            ResidentOnboardingStatusID: pet.ResidentOnboardingStatusID,
            ResidentOnboardingStatusName: pet.ResidentOnboardingStatusName,
            ResidentOnboardingStatusNote: pet.ResidentOnboardingStatusNote
          };
        } else {
          return { ...pet };
        }
      });
    }

    if (editPet) {
      setEditPet({ loading: true, petIndex: editPet.petIndex });
    }

    if (residentPetID) {
      const _pet = _residentPetList.filter(
        (pet) => pet.ResidentPetID === residentPetID
      )[0];
      if (_pet) {
        _residentPetList = [_pet];
        setEditNote({ edit: false, note: _pet.Notes });
      }
    }

    if (
      grouping === 'pet-interview' &&
      onboardingDashboardRow &&
      onboardingDashboardRow.data
    ) {
      let itemDescription = null;
      Object.keys(onboardingDashboardRow.data).map((description) => {
        if (
          onboardingDashboardRow.data[description].Grouping === grouping &&
          onboardingDashboardRow.data[description].ItemList
        ) {
          itemDescription = description;
        }
      });

      if (itemDescription) {
        _residentPetList = _residentPetList.map((pet) => {
          let _interviewItem = onboardingDashboardRow.data[
            itemDescription
          ].ItemList.filter(
            (item) => item.ResidentPetID === pet.ResidentPetID
          )[0];
          if (_interviewItem) {
            return {
              ...pet,
              ResidentOnboardingStatusID:
                _interviewItem.ResidentOnboardingStatusID,
              ResidentOnboardingStatusName:
                _interviewItem.ResidentOnboardingStatusName,
              ResidentOnboardingStatusNameOverride:
                _interviewItem.ResidentOnboardingStatusNameOverride
            };
          } else {
            return { ...pet };
          }
        });
      }
    }

    setResidentPetList(_residentPetList);
  }

  function getRequiredPhotoList() {
    let _descriptionList = [];

    if (residentOnboarding.Header.PetPhotoIncludeFace) {
      _descriptionList.push('Face');
    }

    if (residentOnboarding.Header.PetPhotoIncludeStanding) {
      _descriptionList.push('Standing/Side');
    }

    if (residentOnboarding.Header.PetPhotoIncludeSitting) {
      _descriptionList.push('Favorite 1');
    }

    if (residentOnboarding.Header.PetPhotoIncludeAny) {
      _descriptionList.push('Favorite 2');
    }

    return _descriptionList;
  }

  function checkIsDisabled() {
    setIsDisabled(
      !residentOnboardingSetting || residentOnboardingSetting.HasNoPet
    );
  }

  function getESADocumentList(pet) {
    if (residentOnboarding && residentOnboarding.FileList) {
      return residentOnboarding.FileList.filter(
        (file) =>
          //It's a ESA documentation file uploaded by the resident
          (file.ResidentPetID === pet.Pet.ResidentPetID &&
            file.ResidentFileTypeID === 8) ||
          //It's a resident event file for this pet
          (file.ResidentFileTypeID === 11 &&
            pet.residentEventList &&
            pet.residentEventList.filter(
              (event) => event.ResidentEventID === file.ResidentEventID
            ).length)
      );
    }

    return [];
  }

  return residentOnboardingStatus ? (
    <div className="resident-onboarding-item pet">
      <div className="form-header">
        {/* {!residentPetList.length ? (
          <ResidentOnboardingStatusBadge
            status={residentOnboardingStatus}
            isProperty={residentID ? true : false}
          />
        ) : null} */}

        <h3>
          {closeOnboardingRow ? (
            <IconButton
              className="close-button"
              onClick={() => closeOnboardingRow()}>
              <CloseIcon />
            </IconButton>
          ) : null}
          Pet{' '}
          {residentOnboarding.Header.ResidentStatusID === 4
            ? 'Application'
            : grouping === 'pet-interview'
            ? 'Interview'
            : 'Registration'}
        </h3>
      </div>

      {!residentID ? (
        <Alert severity="info" className="top-notice">
          Per your lease, all pets must be registered and approved by the
          property.
        </Alert>
      ) : null}

      {!residentID &&
      residentOnboardingSetting.HasNoPet &&
      residentOnboardingSetting.HasNoPetConfirmed === false &&
      residentOnboardingSetting.HasNoPetReason ? (
        <Alert severity={'warning'}>
          Note from Property: {residentOnboardingSetting.HasNoPetReason}
        </Alert>
      ) : null}

      <div className="form-content">
        {residentID &&
        residentOnboardingSetting.HasNoPet &&
        residentOnboardingSetting.HasNoPetConfirmed === null &&
        grouping !== 'pet-interview' ? (
          <div>
            <div className="multi-item does-not-have-property">
              <div className="save-button">
                <Button
                  variant="contained"
                  className="accept"
                  color="primary"
                  onClick={() => {
                    setResidentStatusUpdate({
                      dataTypeLabel: 'Resident does not have pets',
                      residentOnboardingStatusID: 3
                    });
                  }}>
                  <DoneIcon /> Accept
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setResidentStatusUpdate({
                      dataTypeLabel: 'Resident does not have pets',
                      residentOnboardingStatusID: 1
                    });
                  }}>
                  <ClearIcon /> Reject
                </Button>
              </div>
              <p>
                Resident has indicated that they do not have pets. If you agree
                with that statement, click the Accept button above. If you do
                not agree, click the Reject button.
              </p>
            </div>
          </div>
        ) : (
          <>
            {residentOnboardingSetting.HasNoPet ||
            !residentPetList ||
            !residentPetList.length ||
            (grouping === 'pet-interview' &&
              !residentPetList.filter(
                (pet) => pet.ResidentOnboardingStatusID > 2
              ).length) ? (
              grouping === 'pet-interview' ? (
                <div className="pet-interview-waiting">
                  {residentOnboardingSetting.HasNoPet
                    ? 'This resident has indicated that they do not have any pets.'
                    : !residentPetList || !residentPetList.length
                    ? 'No pets have been added for this resident.'
                    : 'Pet Registration must be Accepted before Pet Interview is made available.'}
                </div>
              ) : (
                <div
                  className="does-not-have-checkbox"
                  style={{ padding: '10px' }}>
                  <FormControlLabel
                    control={
                      <div>
                        <Checkbox
                          checked={residentOnboardingSetting.HasNoPet}
                          onChange={(event) => {
                            updateResidentOnboardingHeader({
                              ...residentOnboarding.Header,
                              HasNoPet: event.target.checked
                            });
                            setResidentOnboardingSetting({
                              ...residentOnboardingSetting,
                              HasNoPet: event.target.checked
                            });
                          }}
                          disabled={residentOnboardingSetting.HasNoPetConfirmed}
                          name="gilad"
                        />
                      </div>
                    }
                    label={
                      residentID ? (
                        <>Resident does not have pets or animals.</>
                      ) : (
                        <>I do not have any pets or animals.</>
                      )
                    }
                  />
                </div>
              )
            ) : null}

            {!residentOnboardingSetting.HasNoPet && !residentPetID ? (
              <>
                {residentPetList.length ? (
                  <div className="multi-item-summary-container">
                    <ul className="multi-item-summary-list">
                      {residentPetList
                        .filter(
                          (pet) =>
                            grouping !== 'pet-interview' ||
                            pet.ResidentOnboardingStatusID >= 2
                        )
                        .map((pet, petIndex) => (
                          <li className="multi-item-summary">
                            <div
                              className={
                                'multi-item-summary-card' +
                                (editPet &&
                                editPet.Pet &&
                                editPet.Pet.ResidentPetID === pet.ResidentPetID
                                  ? ' selected'
                                  : '') +
                                (pet.ResidentOnboardingStatusID >= 3
                                  ? ' completed'
                                  : '')
                              }>
                              <h5>
                                {!pet.ResidentOnboardingStatusID ||
                                pet.ResidentOnboardingStatusID === 1 ? (
                                  <div className="delete">
                                    <IconButton
                                      onClick={() =>
                                        setDeletePet({ ...pet, petIndex })
                                      }>
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                ) : null}
                                {pet.Name
                                  ? pet.Name
                                  : 'Animal ' + (petIndex + 1)}
                              </h5>
                              <h6>
                                {pet.PetType
                                  ? pet.PetType +
                                    (pet.AnimalCategoryID === 1
                                      ? ' - Household Pet'
                                      : pet.AnimalCategoryID === 2
                                      ? pet.AssistanceAnimalTypeName
                                        ? ' - ' + pet.AssistanceAnimalTypeName
                                        : ''
                                      : '')
                                  : 'More Detail Needed'}
                              </h6>

                              {grouping === 'pet-interview' ? (
                                <div className="pet-interview-item">
                                  <div className="photo">
                                    {residentOnboarding &&
                                    residentOnboarding.FileList &&
                                    residentOnboarding.FileList.filter(
                                      (file) =>
                                        file.ResidentFileTypeID === 4 &&
                                        file.ResidentPetID === pet.ResidentPetID
                                    ).length ? (
                                      <img
                                        src={getImageDownloadUrl(
                                          residentOnboarding.FileList.filter(
                                            (file) =>
                                              file.ResidentFileTypeID === 4 &&
                                              file.ResidentPetID ===
                                                pet.ResidentPetID
                                          )[0]
                                        )}
                                        alt={pet.Name}
                                      />
                                    ) : null}
                                  </div>

                                  <Button
                                    variant={
                                      pet.ResidentOnboardingStatusID === 2
                                        ? 'contained'
                                        : 'outlined'
                                    }
                                    color="primary"
                                    onClick={() => {
                                      setShowPetInterview({ ...pet });
                                    }}>
                                    {pet.ResidentOnboardingStatusID === 2 ||
                                    (pet.PetType !== 'Dog' &&
                                      pet.ResidentOnboardingStatusID > 3)
                                      ? 'Start Interview'
                                      : 'View Interview'}
                                  </Button>
                                </div>
                              ) : (
                                <>
                                  <Stepper
                                    activeStep={getPetActiveStep(pet)}
                                    orientation="vertical">
                                    {getPetStepList(
                                      pet,
                                      residentOnboarding
                                    ).map((step, index) => (
                                      <Step key={step}>
                                        <StepLabel>
                                          {step}{' '}
                                          {getPetActiveStep(pet) === 1 &&
                                          index === 1 &&
                                          pet.AnimalCategoryID === 2 &&
                                          pet.AssistanceAnimalTypeID === 1 &&
                                          !residentID ? (
                                            <Button
                                              variant="outlined"
                                              color="secondary"
                                              style={{
                                                width: '90px',
                                                padding: '3px',
                                                marginTop: '-5px',
                                                marginLeft: '5px',
                                                fontSize: '.7em'
                                              }}
                                              onClick={() =>
                                                setCancelESA({ ...pet })
                                              }>
                                              Cancel Request
                                            </Button>
                                          ) : null}
                                        </StepLabel>
                                      </Step>
                                    ))}
                                  </Stepper>

                                  <Button
                                    variant={
                                      !pet.ResidentOnboardingStatusID ||
                                      pet.ResidentOnboardingStatusID <= 2
                                        ? 'contained'
                                        : 'outlined'
                                    }
                                    color={
                                      !pet.ResidentOnboardingStatusID ||
                                      pet.ResidentOnboardingStatusID === 1
                                        ? 'secondary'
                                        : 'primary'
                                    }
                                    onClick={() => {
                                      if (
                                        pet.Reason === registrationFeeReasonText
                                      ) {
                                        setShowPetPayment({ ...pet });
                                      } else {
                                        setEditPet({ loading: true, petIndex });

                                        setTimeout(() => {
                                          if (residentID) {
                                            scrollToElementInPage(
                                              document.getElementById(
                                                'petEditForm'
                                              ),
                                              -400
                                            );
                                          } else if (
                                            document.getElementById(
                                              'petEditForm'
                                            )
                                          ) {
                                            document
                                              .getElementById('petEditForm')
                                              .scrollIntoView();
                                          }
                                        }, 200);
                                      }
                                    }}>
                                    {!pet.ResidentOnboardingStatusID ||
                                    pet.ResidentOnboardingStatusID === 1
                                      ? 'Continue Processing'
                                      : residentID
                                      ? 'View Submittal'
                                      : 'View Status'}
                                  </Button>
                                </>
                              )}
                            </div>
                          </li>
                        ))}
                    </ul>
                    {grouping !== 'pet-interview' ? (
                      <Button
                        className="add-button"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          actions.addResidentPet(residentID);
                          setEditPet(null);
                        }}>
                        <PetsIcon /> Add{' '}
                        {residentPetList.length ? 'Another' : ''} Animal
                      </Button>
                    ) : null}
                  </div>
                ) : grouping !== 'pet-interview' ? (
                  <>
                    <div className="pet-count-checkbox">
                      <FormControlLabel
                        control={
                          <div>
                            <Checkbox
                              onChange={(event) => {
                                actions.addResidentPet(residentID);
                              }}
                            />
                          </div>
                        }
                        label={
                          residentID ? (
                            <>
                              Resident has ONE pet <PetsIcon />
                            </>
                          ) : (
                            <>
                              I have ONE pet <PetsIcon />
                            </>
                          )
                        }
                      />
                    </div>

                    <div className="pet-count-checkbox">
                      <FormControlLabel
                        control={
                          <div>
                            <Checkbox
                              onChange={(event) => {
                                actions
                                  .addResidentPet(residentID)
                                  .then((result) => {
                                    actions.addResidentPet(residentID);
                                  });
                              }}
                            />
                          </div>
                        }
                        label={
                          residentID ? (
                            <>
                              Resident has TWO pets <PetsIcon />
                              <PetsIcon />
                            </>
                          ) : (
                            <>
                              I have TWO pets <PetsIcon />
                              <PetsIcon />
                            </>
                          )
                        }
                      />
                    </div>

                    <div className="pet-count-checkbox">
                      <FormControlLabel
                        control={
                          <div>
                            <Checkbox
                              onChange={(event) => {
                                actions
                                  .addResidentPet(residentID)
                                  .then((result) => {
                                    actions
                                      .addResidentPet(residentID)
                                      .then((result) => {
                                        actions.addResidentPet(residentID);
                                      });
                                  });
                              }}
                            />
                          </div>
                        }
                        label={
                          residentID ? (
                            <>
                              Resident has THREE pets <PetsIcon />
                              <PetsIcon />
                              <PetsIcon />
                            </>
                          ) : (
                            <>
                              I have THREE pets <PetsIcon />
                              <PetsIcon />
                              <PetsIcon />
                            </>
                          )
                        }
                      />
                    </div>
                  </>
                ) : null}
              </>
            ) : residentOnboarding.Header.PetPolicyIncludeCheckbox ? (
              <>
                {/* <PetPolicyCheckbox
                  residentOnboardingSetting={residentOnboardingSetting}
                  residentOnboarding={residentOnboarding}
                  updateResidentOnboardingHeader={
                    updateResidentOnboardingHeader
                  }
                  setResidentOnboardingSetting={setResidentOnboardingSetting}
                /> */}
              </>
            ) : null}

            {!residentOnboardingSetting.HasNoPet ? (
              <div>
                {residentPetList
                  .filter(
                    (pet) =>
                      editPet &&
                      editPet.Pet &&
                      editPet.Pet.ResidentPetID === pet.ResidentPetID
                  )
                  .map((pet, petIndex) => (
                    <>
                      <div
                        id="petEditForm"
                        key={'pet-form-' + pet.ResidentPetID}
                        className={
                          'multi-item pet-' +
                          pet.ResidentPetID +
                          (!pet.ResidentOnboardingStatusID ||
                          pet.ResidentOnboardingStatusID === 1
                            ? ' edit-mode'
                            : '')
                        }>
                        <div
                          className={
                            'form-header' +
                            (pet.ResidentOnboardingStatusNote &&
                            pet.ResidentOnboardingStatusID !== 2
                              ? ' with-note'
                              : '')
                          }>
                          <h4>
                            <ResidentOnboardingStatusBadge
                              status={pet}
                              isProperty={residentID ? true : false}
                            />
                            <PetsIcon /> Animal {editPet.petIndex + 1}{' '}
                            {pet.Name ? ' - ' + pet.Name : ''}
                            {!pet.ResidentOnboardingStatusID ||
                            pet.ResidentOnboardingStatusID === 1 ? (
                              <div className="delete">
                                <IconButton
                                  onClick={() =>
                                    setDeletePet({
                                      ...pet,
                                      petIndex: editPet.petIndex
                                    })
                                  }>
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            ) : null}
                          </h4>

                          <div className="save-button">
                            {!pet.ResidentOnboardingStatusID ||
                            pet.ResidentOnboardingStatusID ===
                              1 ? null : residentID &&
                              pet.ResidentOnboardingStatusID === 2 ? (
                              pet.AnimalCategoryID === 2 &&
                              pet.AssistanceAnimalTypeID === 1 ? (
                                <>
                                  <div className="esa-recommendation-alert">
                                    <Alert
                                      severity={
                                        editPet.Pet
                                          .ESAProvider_IsValidResponse === true
                                          ? 'success'
                                          : editPet.Pet
                                              .ESAProvider_IsValidResponse ===
                                            false
                                          ? 'warning'
                                          : 'info'
                                      }>
                                      <p>
                                        {editPet.Pet.Name
                                          ? editPet.Pet.Name
                                          : 'This animal'}{' '}
                                        is an Emotional Support Animal (ESA)
                                        recommended by my health care provider
                                        and I am requesting a reasonable
                                        accommodation for this animal under The
                                        Fair Housing Act. Therapy, comfort, and
                                        companion animals also fall within this
                                        category.
                                      </p>
                                      <div className="esa-recommendation-action">
                                        {editPet.Pet
                                          .ESAProvider_IsValidResponse ===
                                        true ? (
                                          <p>
                                            Approval of accommodation request is{' '}
                                            <strong>Recommended</strong>
                                            <Button
                                              variant="contained"
                                              className="accept"
                                              color="primary"
                                              onClick={() => {
                                                setResidentStatusUpdate({
                                                  dataTypeLabel:
                                                    'Animal ' + (petIndex + 1),
                                                  pet,
                                                  residentOnboardingStatusID: 3
                                                });
                                              }}>
                                              <DoneIcon /> Accept Request
                                            </Button>
                                            <Button
                                              variant="outlined"
                                              color="secondary"
                                              onClick={() => {
                                                setResidentStatusUpdate({
                                                  dataTypeLabel:
                                                    'Animal ' + (petIndex + 1),
                                                  pet,
                                                  residentOnboardingStatusID: 1
                                                });
                                              }}>
                                              <ClearIcon /> Reject Request
                                            </Button>
                                          </p>
                                        ) : editPet.Pet
                                            .ESAProvider_IsValidResponse ===
                                          false ? (
                                          <p>
                                            Approval of accommodation request is{' '}
                                            <strong>NOT Recommended</strong>
                                            <Button
                                              variant="outlined"
                                              className="accept"
                                              color="primary"
                                              onClick={() => {
                                                setResidentStatusUpdate({
                                                  dataTypeLabel:
                                                    'Animal ' + (petIndex + 1),
                                                  pet,
                                                  residentOnboardingStatusID: 3
                                                });
                                              }}>
                                              <DoneIcon /> Accept Request
                                            </Button>
                                            <Button
                                              variant="contained"
                                              color="secondary"
                                              onClick={() => {
                                                setResidentStatusUpdate({
                                                  dataTypeLabel:
                                                    'Animal ' + (petIndex + 1),
                                                  pet,
                                                  residentOnboardingStatusID: 1
                                                });
                                              }}>
                                              <ClearIcon /> Reject Request
                                            </Button>
                                          </p>
                                        ) : (
                                          <p>
                                            Accommodation request is{' '}
                                            <strong>UNDER REVIEW</strong>
                                            <Button
                                              variant="outlined"
                                              color="primary"
                                              onClick={() => {
                                                setResidentStatusUpdate({
                                                  dataTypeLabel:
                                                    'Animal ' + (petIndex + 1),
                                                  pet,
                                                  residentOnboardingStatusID: 3
                                                });
                                              }}>
                                              Override and Accept Request
                                            </Button>
                                          </p>
                                        )}
                                      </div>
                                    </Alert>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <Button
                                    variant="contained"
                                    className="accept"
                                    color="primary"
                                    onClick={() => {
                                      setResidentStatusUpdate({
                                        dataTypeLabel:
                                          'Animal ' + (petIndex + 1),
                                        pet,
                                        residentOnboardingStatusID: 3
                                      });
                                    }}>
                                    <DoneIcon /> Accept
                                  </Button>

                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                      setResidentStatusUpdate({
                                        dataTypeLabel:
                                          'Animal ' + (petIndex + 1),
                                        pet,
                                        residentOnboardingStatusID: 1
                                      });
                                    }}>
                                    <ClearIcon /> Reject
                                  </Button>
                                </>
                              )
                            ) : null}
                          </div>

                          {pet.AnimalCategoryID === 1 &&
                          pet.ESACancelDateSigned ? (
                            <Alert severity="info">
                              This animal was originally submitted as an
                              Emotional Support Animal but the application was
                              cancelled and converted to a Household Pet
                              Registration.
                            </Alert>
                          ) : null}

                          {pet.ResidentOnboardingStatusNote &&
                          pet.ResidentOnboardingStatusID !== 2 ? (
                            <Alert
                              severity={
                                pet.ResidentOnboardingStatusID === 3
                                  ? 'success'
                                  : 'warning'
                              }>
                              Note from Property:{' '}
                              {pet.ResidentOnboardingStatusNote}
                            </Alert>
                          ) : null}
                        </div>

                        {residentID &&
                        pet.AnimalCategoryID === 2 &&
                        pet.AssistanceAnimalTypeID === 1 ? (
                          <div
                            className={
                              'esa-recommendation' +
                              (pet.ResidentOnboardingStatusID === 2
                                ? ' pending-approval'
                                : '')
                            }>
                            <div className="email-log">
                              <p style={{ marginTop: '20px' }}>
                                <strong>
                                  Process for Determining Document Authenticity
                                  and Reliability
                                </strong>
                              </p>

                              <ResidentEventList
                                residentEventTypeID={1}
                                residentID={residentID}
                                pet={pet}
                                className={'resident-onboarding-item'}
                                afterSaveSuccess={() => {
                                  updateResidentPetESAProviderResponse(
                                    residentID,
                                    {
                                      ...pet
                                    }
                                  ).then((result) => {
                                    setPetUpdated(true);
                                    refreshResidentOnboarding();
                                  });
                                }}
                                afterSaveCancel={() => {}}
                                setOutputResidentEventList={(
                                  residentEventList
                                ) =>
                                  setEditPet({ ...editPet, residentEventList })
                                }>
                                <div className="radio-container">
                                  <p>
                                    <strong>
                                      Does {siteConfig.brand} recommend
                                      approving this ESA accommodation?
                                    </strong>
                                  </p>

                                  <FormControl component="fieldset">
                                    <RadioGroup
                                      name="ESA_ResponseIsValid"
                                      className="yes-no"
                                      value={pet.ESAProvider_IsValidResponse}
                                      onChange={(event, newValue) => {
                                        setResidentPetList(
                                          residentPetList.map((_pet) =>
                                            _pet.ResidentPetID ===
                                            pet.ResidentPetID
                                              ? {
                                                  ..._pet,
                                                  dataUpdated: true,
                                                  ESAProvider_IsValidResponse:
                                                    String(newValue) === 'true'
                                                      ? true
                                                      : String(newValue) ===
                                                        'false'
                                                      ? false
                                                      : null
                                                }
                                              : { ..._pet }
                                          )
                                        );
                                      }}>
                                      <FormControlLabel
                                        value={true}
                                        className={
                                          pet.ESAProvider_IsValidResponse
                                            ? 'selected'
                                            : ''
                                        }
                                        control={<Radio color="primary" />}
                                        label={'Yes'}
                                      />
                                      <FormControlLabel
                                        value={false}
                                        className={
                                          pet.ESAProvider_IsValidResponse ===
                                          false
                                            ? 'selected'
                                            : ''
                                        }
                                        control={<Radio color="primary" />}
                                        label={'No'}
                                      />
                                      <FormControlLabel
                                        value={null}
                                        className={
                                          pet.ESAProvider_IsValidResponse ===
                                          null
                                            ? 'selected'
                                            : ''
                                        }
                                        control={<Radio color="primary" />}
                                        label={'No Answer'}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                              </ResidentEventList>

                              <div className="documents-and-other">
                                <p>
                                  <strong>Documents and Other Uploads</strong>
                                </p>

                                {getESADocumentList(editPet).length ? (
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell style={{ width: '175px' }}>
                                          Date
                                        </TableCell>
                                        <TableCell style={{ width: '175px' }}>
                                          User
                                        </TableCell>
                                        <TableCell>Description</TableCell>

                                        <TableCell style={{ width: '100px' }}>
                                          Download
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {getESADocumentList(editPet).map(
                                        (file) => (
                                          <TableRow>
                                            <TableCell>
                                              {moment
                                                .utc(file.Created)
                                                .local()
                                                .format('MM/DD/YYYY h:mm a')}
                                            </TableCell>
                                            <TableCell>
                                              {file.CreatedBy}
                                            </TableCell>
                                            <TableCell>
                                              {file.Description
                                                ? file.Description
                                                : file.ResidentFileTypeName}
                                            </TableCell>

                                            <TableCell
                                              style={{ textAlign: 'center' }}>
                                              <IconButton
                                                href={getFileDownloadUrl(
                                                  file,
                                                  false,
                                                  residentID
                                                )}
                                                target="_blank">
                                                <DownloadIcon />
                                              </IconButton>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                ) : (
                                  <p>
                                    No documents or other uploads have been
                                    added.
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {editPet.loading ? (
                          <LinearProgress />
                        ) : (
                          <>
                            {residentPetID ? (
                              <div className="property-notes">
                                <TextField
                                  label="Property Notes"
                                  value={editNote.note}
                                  onChange={(event) =>
                                    setEditNote({
                                      ...editNote,
                                      note: event.target.value
                                    })
                                  }
                                  id="textboxPetNote"
                                  disabled={!editNote.edit}
                                  multiline
                                  fullWidth
                                  rows={5}
                                  autoFocus={editNote.edit}
                                  variant="filled"
                                  onClick={() =>
                                    setEditNote({ ...editNote, edit: true })
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {editNote.edit ? (
                                          <>
                                            <Button
                                              onClick={(event) => {
                                                saveResidentPetNotes(
                                                  profileKey,
                                                  residentID,
                                                  residentPetID,
                                                  editNote.note
                                                ).then(() => {
                                                  setEditNote({
                                                    ...editNote,
                                                    edit: false
                                                  });
                                                });

                                                event.stopPropagation();
                                              }}
                                              variant="contained"
                                              color="secondary">
                                              Save
                                            </Button>
                                            <Button
                                              onClick={(event) => {
                                                setEditNote({
                                                  note: pet.Notes
                                                    ? pet.Notes
                                                    : '',
                                                  edit: false
                                                });
                                                event.stopPropagation();
                                              }}>
                                              Cancel
                                            </Button>
                                          </>
                                        ) : (
                                          <IconButton
                                            onClick={() =>
                                              setEditNote({
                                                note: pet.Notes
                                                  ? pet.Notes
                                                  : '',
                                                edit: true
                                              })
                                            }>
                                            {' '}
                                            <EditIcon />
                                          </IconButton>
                                        )}
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </div>
                            ) : null}

                            <div
                              className={
                                'multi-item-content' +
                                (isDisabled ||
                                pet.ResidentOnboardingStatusID > 1
                                  ? ' disabled'
                                  : '')
                              }>
                              <div className="form-fields">
                                {residentOnboarding.Header
                                  .PetIncludeOwnerName ? (
                                  <>
                                    <div className="field-container">
                                      <FormControl
                                        variant="outlined"
                                        fullWidth
                                        required
                                        disabled={
                                          isDisabled ||
                                          (editPet.Pet.PropertyPetId &&
                                            editPet.Pet.OccupantID)
                                        }>
                                        <InputLabel
                                          id="petOwner-select-label"
                                          fullWidth>
                                          Owner (Responsible Party)
                                        </InputLabel>
                                        <Select
                                          fullWidth
                                          labelId="petOwner-select-label"
                                          id="petOwner-select"
                                          value={pet.OccupantID}
                                          label="Owner (Responsible Party)"
                                          onChange={(event) =>
                                            setResidentPetList(
                                              residentPetList.map((_pet) =>
                                                _pet.ResidentPetID ===
                                                pet.ResidentPetID
                                                  ? {
                                                      ..._pet,
                                                      dataUpdated: true,
                                                      OccupantID: event.target
                                                        .value
                                                        ? event.target.value
                                                        : -1
                                                    }
                                                  : { ..._pet }
                                              )
                                            )
                                          }>
                                          <MenuItem value={-1}>
                                            {residentOnboarding.Header
                                              .FirstName +
                                              ' ' +
                                              residentOnboarding.Header
                                                .LastName}
                                          </MenuItem>
                                          {residentOnboarding.OccupantList.map(
                                            (occupant) => (
                                              <MenuItem
                                                value={occupant.OccupantID}>
                                                {occupant.FirstName +
                                                  ' ' +
                                                  occupant.LastName}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      </FormControl>
                                    </div>
                                    <div className="field-container"></div>
                                  </>
                                ) : null}

                                {residentOnboarding.Header.PetIncludeName ? (
                                  <div className="field-container">
                                    <TextField
                                      required
                                      variant="outlined"
                                      fullWidth
                                      key={'textfieldName_' + petIndex}
                                      id={'textfieldName_' + petIndex}
                                      label={"Animal's Name"}
                                      value={pet.Name}
                                      disabled={
                                        isDisabled ||
                                        (editPet.Pet.PropertyPetId &&
                                          editPet.Pet.Name)
                                      }
                                      onChange={(event) =>
                                        setResidentPetList(
                                          residentPetList.map((_pet) =>
                                            _pet.ResidentPetID ===
                                            pet.ResidentPetID
                                              ? {
                                                  ..._pet,
                                                  dataUpdated: true,
                                                  Name: event.target.value
                                                }
                                              : { ..._pet }
                                          )
                                        )
                                      }
                                    />
                                  </div>
                                ) : null}

                                {residentOnboarding.Header.PetIncludeType ? (
                                  <div className="field-container">
                                    <FormControl
                                      variant="outlined"
                                      fullWidth
                                      required
                                      disabled={isDisabled}>
                                      <InputLabel
                                        id="petType-select-label"
                                        fullWidth>
                                        Animal Type
                                      </InputLabel>
                                      <Select
                                        fullWidth
                                        labelId="petType-select-label"
                                        id="petType-select"
                                        value={pet.PetType}
                                        label="Animal Type"
                                        onChange={(event) =>
                                          setResidentPetList(
                                            residentPetList.map((_pet) =>
                                              _pet.ResidentPetID ===
                                              pet.ResidentPetID
                                                ? {
                                                    ..._pet,
                                                    dataUpdated: true,
                                                    PetType: event.target.value
                                                  }
                                                : { ..._pet }
                                            )
                                          )
                                        }>
                                        {petTypeList.map((petType) => (
                                          <MenuItem value={petType}>
                                            {petType}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                ) : null}

                                {residentOnboarding.Header.PetIncludeBreed &&
                                pet.PetType === 'Dog' ? (
                                  <div className="field-container">
                                    <TextField
                                      variant="outlined"
                                      fullWidth
                                      key={'textfieldBreed_' + petIndex}
                                      id={'textfieldBreed_' + petIndex}
                                      label={'Breed'}
                                      value={pet.Breed}
                                      disabled={isDisabled}
                                      onChange={(event) =>
                                        setResidentPetList(
                                          residentPetList.map((_pet) =>
                                            _pet.ResidentPetID ===
                                            pet.ResidentPetID
                                              ? {
                                                  ..._pet,
                                                  dataUpdated: true,
                                                  Breed: event.target.value
                                                }
                                              : { ..._pet }
                                          )
                                        )
                                      }
                                    />
                                  </div>
                                ) : null}

                                {residentOnboarding.Header.PetIncludeColor &&
                                pet.PetType !== 'Other' ? (
                                  <div className="field-container">
                                    <FormControl
                                      variant="outlined"
                                      fullWidth
                                      required
                                      className="resident-onboarding-multi-select">
                                      <InputLabel
                                        id="color-select-label"
                                        fullWidth>
                                        Primary Color
                                      </InputLabel>
                                      <Select
                                        fullWidth
                                        labelId="color-select-label"
                                        id="color-select"
                                        value={
                                          pet.Color ? pet.Color.split(', ') : []
                                        }
                                        renderValue={(data) =>
                                          pet.Color.split(', ').join(', ')
                                        }
                                        label="Primary Color"
                                        multiple
                                        onChange={(event) =>
                                          setResidentPetList(
                                            residentPetList.map((_pet) => {
                                              console.log(event.target.value);
                                              return _pet.ResidentPetID ===
                                                pet.ResidentPetID
                                                ? {
                                                    ..._pet,
                                                    dataUpdated: true,
                                                    Color:
                                                      event.target.value.join(
                                                        ', '
                                                      )
                                                  }
                                                : { ..._pet };
                                            })
                                          )
                                        }>
                                        {colorList.map((color) => (
                                          <MenuItem value={color}>
                                            <CheckIcon />
                                            {color}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                ) : null}

                                {residentOnboarding.Header.PetIncludeWeight ? (
                                  <div className="field-container">
                                    <TextField
                                      required
                                      variant="outlined"
                                      fullWidth
                                      type="number"
                                      key={'textfieldWeight_' + petIndex}
                                      id={'textfieldWeight_' + petIndex}
                                      label={'Weight (lbs.)'}
                                      value={pet.Weight}
                                      disabled={
                                        isDisabled ||
                                        (editPet.Pet.PropertyPetId &&
                                          editPet.Pet.Weight)
                                      }
                                      onChange={(event) =>
                                        setResidentPetList(
                                          residentPetList.map((_pet) =>
                                            _pet.ResidentPetID ===
                                            pet.ResidentPetID
                                              ? {
                                                  ..._pet,
                                                  dataUpdated: true,
                                                  Weight: event.target.value
                                                }
                                              : { ..._pet }
                                          )
                                        )
                                      }
                                    />
                                  </div>
                                ) : null}

                                {residentOnboarding.Header.PetIncludeGender ? (
                                  <div className="field-container">
                                    <FormControl
                                      variant="outlined"
                                      fullWidth
                                      disabled={
                                        isDisabled ||
                                        (editPet.Pet.PropertyPetId &&
                                          editPet.Pet.Gender)
                                      }
                                      required>
                                      <InputLabel
                                        id="gender-select-label"
                                        fullWidth>
                                        Gender
                                      </InputLabel>
                                      <Select
                                        fullWidth
                                        labelId="gender-select-label"
                                        id="gender-select"
                                        value={pet.Gender}
                                        label="Gender"
                                        onChange={(event) =>
                                          setResidentPetList(
                                            residentPetList.map((_pet) =>
                                              _pet.ResidentPetID ===
                                              pet.ResidentPetID
                                                ? {
                                                    ..._pet,
                                                    dataUpdated: true,
                                                    Gender: event.target.value
                                                  }
                                                : { ..._pet }
                                            )
                                          )
                                        }>
                                        {genderList.map((gender) => (
                                          <MenuItem value={gender}>
                                            {gender}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                ) : null}

                                {residentOnboarding.Header.PetIncludeAge ? (
                                  <div className="field-container">
                                    <TextField
                                      required
                                      variant="outlined"
                                      fullWidth
                                      type="number"
                                      key={'textfieldAge_' + petIndex}
                                      id={'textfieldAge_' + petIndex}
                                      label={'Age (years)'}
                                      value={pet.Age}
                                      disabled={
                                        isDisabled ||
                                        (editPet.Pet.PropertyPetId &&
                                          editPet.Pet.Age)
                                      }
                                      onChange={(event) =>
                                        setResidentPetList(
                                          residentPetList.map((_pet) =>
                                            _pet.ResidentPetID ===
                                            pet.ResidentPetID
                                              ? {
                                                  ..._pet,
                                                  dataUpdated: true,
                                                  Age: event.target.value
                                                }
                                              : { ..._pet }
                                          )
                                        )
                                      }
                                    />
                                  </div>
                                ) : null}

                                {residentOnboarding.Header
                                  .PetIncludeSpayedNeutered ? (
                                  <div className="field-container">
                                    <FormControl
                                      variant="outlined"
                                      fullWidth
                                      disabled={
                                        isDisabled ||
                                        (editPet.Pet.PropertyPetId &&
                                          editPet.Pet.IsSpayedNeutered)
                                      }
                                      required>
                                      <InputLabel
                                        id="spayed-select-label"
                                        fullWidth>
                                        Spayed or Neutered?
                                      </InputLabel>
                                      <Select
                                        fullWidth
                                        labelId="spayed-select-label"
                                        id="spayed-select"
                                        value={
                                          pet.IsSpayedNeutered
                                            ? 'Yes'
                                            : pet.IsSpayedNeutered === false
                                            ? 'No'
                                            : ''
                                        }
                                        label="Spayed or Neutered?"
                                        onChange={(event) =>
                                          setResidentPetList(
                                            residentPetList.map((_pet) =>
                                              _pet.ResidentPetID ===
                                              pet.ResidentPetID
                                                ? {
                                                    ..._pet,
                                                    dataUpdated: true,
                                                    IsSpayedNeutered:
                                                      event.target.value ===
                                                      'Yes'
                                                  }
                                                : { ..._pet }
                                            )
                                          )
                                        }>
                                        <MenuItem value={'Yes'}>Yes</MenuItem>
                                        <MenuItem value={'No'}>No</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </div>
                                ) : null}
                              </div>

                              <div className="photo-fields">
                                <ResidentOnboardingPhotoUpload
                                  residentOnboarding={residentOnboarding}
                                  refreshResidentOnboarding={
                                    refreshResidentOnboarding
                                  }
                                  residentPetID={pet.ResidentPetID}
                                  residentFileTypeID={4}
                                  label={'Animal Photos'}
                                  disabled={isDisabled}
                                  requiredPhotoList={getRequiredPhotoList()}
                                  residentID={residentID}
                                />
                              </div>

                              {residentOnboarding.Header
                                .PetIncludeProofOfVaccinations &&
                              pet.PetType !== 'Other' &&
                              editPet &&
                              editPet.Pet.ResidentPetID ===
                                pet.ResidentPetID ? (
                                <ResidentOnboardingFileUpload
                                  residentOnboarding={residentOnboarding}
                                  refreshResidentOnboarding={
                                    refreshResidentOnboarding
                                  }
                                  residentPetID={pet.ResidentPetID}
                                  residentFileTypeID={5}
                                  disabled={isDisabled}
                                  label={'Proof of Pet Vaccination'}
                                  residentID={residentID}
                                />
                              ) : null}

                              {petIndex === 0 ? (
                                <>
                                  {residentOnboarding.Header
                                    .PetPolicyIncludeCheckbox ? (
                                    <PetPolicyCheckbox
                                      residentOnboardingSetting={
                                        residentOnboardingSetting
                                      }
                                      isDisabled={isDisabled}
                                      residentOnboarding={residentOnboarding}
                                      updateResidentOnboardingHeader={
                                        updateResidentOnboardingHeader
                                      }
                                      setResidentOnboardingSetting={
                                        setResidentOnboardingSetting
                                      }
                                    />
                                  ) : null}

                                  {residentOnboarding.Header
                                    .PetLiabilityIncludeCheckbox ? (
                                    <div className={'acknowledgment'}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              residentOnboardingSetting.IsPetLiabilityCheckboxChecked
                                            }
                                            disabled={isDisabled}
                                            onChange={(event) => {
                                              updateResidentOnboardingHeader({
                                                ...residentOnboarding.Header,
                                                IsPetLiabilityCheckboxChecked:
                                                  event.target.checked
                                              });
                                              setResidentOnboardingSetting({
                                                ...residentOnboardingSetting,
                                                IsPetLiabilityCheckboxChecked:
                                                  event.target.checked
                                              });
                                            }}
                                            name="gilad"
                                          />
                                        }
                                        label={
                                          <>
                                            Resident acknowledgment of pet
                                            liability
                                          </>
                                        }
                                      />
                                      {residentOnboarding.Header
                                        .PetLiabilityText ? (
                                        <div
                                          className="message"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              residentOnboarding.Header.PetLiabilityText.split(
                                                '\n'
                                              ).join('<br />')
                                          }}></div>
                                      ) : null}
                                    </div>
                                  ) : null}

                                  {residentOnboarding.Header
                                    .PetInterviewRequired ? (
                                    <div className={'acknowledgment'}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              residentOnboardingSetting.IsPetInterviewCheckboxChecked
                                            }
                                            disabled={isDisabled}
                                            onChange={(event) => {
                                              updateResidentOnboardingHeader({
                                                ...residentOnboarding.Header,
                                                IsPetInterviewCheckboxChecked:
                                                  event.target.checked
                                              });
                                              setResidentOnboardingSetting({
                                                ...residentOnboardingSetting,
                                                IsPetInterviewCheckboxChecked:
                                                  event.target.checked
                                              });
                                            }}
                                            name="gilad"
                                          />
                                        }
                                        label={
                                          <>
                                            Resident acknowledgment of pet
                                            interview requirement
                                          </>
                                        }
                                      />
                                      {residentOnboarding.Header
                                        .PetInterviewText ? (
                                        <div
                                          className="message"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              residentOnboarding.Header.PetInterviewText.split(
                                                '\n'
                                              ).join('<br />')
                                          }}></div>
                                      ) : null}
                                    </div>
                                  ) : null}
                                </>
                              ) : null}
                            </div>

                            <div
                              className={
                                'radio-container' +
                                (isDisabled ||
                                pet.ResidentOnboardingStatusID > 1
                                  ? ' disabled'
                                  : '')
                              }>
                              <p>
                                <strong>
                                  Please tell us what kind of animal{' '}
                                  {pet.Name ? pet.Name : 'this'} is so we can
                                  properly process your registration.
                                </strong>
                              </p>

                              <FormControl component="fieldset">
                                <RadioGroup
                                  aria-label="Pet Category"
                                  name="petCategory"
                                  value={pet.AnimalCategoryID}
                                  onChange={(event, newValue) => {
                                    setResidentPetList(
                                      residentPetList.map((_pet) =>
                                        _pet.ResidentPetID === pet.ResidentPetID
                                          ? {
                                              ..._pet,
                                              dataUpdated: true,
                                              AnimalCategoryID: newValue
                                            }
                                          : { ..._pet }
                                      )
                                    );
                                  }}>
                                  <FormControlLabel
                                    value="1"
                                    className={
                                      pet.AnimalCategoryID === '1'
                                        ? 'selected'
                                        : ''
                                    }
                                    disabled={
                                      isDisabled ||
                                      pet.ResidentOnboardingStatusID > 1
                                    }
                                    control={<Radio color="primary" />}
                                    label={
                                      <>
                                        {pet.Name ? pet.Name : 'This'} is a
                                        Household Pet
                                      </>
                                    }
                                  />
                                  <FormControlLabel
                                    value="2"
                                    className={
                                      pet.AnimalCategoryID === '2'
                                        ? 'selected'
                                        : ''
                                    }
                                    disabled={
                                      isDisabled ||
                                      pet.ResidentOnboardingStatusID > 1
                                    }
                                    control={<Radio color="primary" />}
                                    label={
                                      <>
                                        {pet.Name ? pet.Name : 'This'} is an
                                        Assistance Animal
                                      </>
                                    }
                                  />
                                </RadioGroup>
                              </FormControl>

                              {!pet.AnimalCategoryID ||
                              pet.AnimalCategoryID == 2 ? (
                                <>
                                  <p style={{ marginTop: '10px' }}>
                                    A household pet is any animal that does not
                                    qualify as an assistance animal. <br />
                                  </p>
                                  <p>
                                    According to HUD: “Assistance animals are
                                    not pets. They are animals that do work,
                                    perform tasks, assist, and/or provide
                                    therapeutic emotional support for
                                    individuals with disabilities. There are two
                                    types of assistance animals: (1) service
                                    animals, and (2) other animals that do work,
                                    perform tasks, provide assistance, and/or
                                    provide therapeutic emotional support for
                                    individuals with disabilities.” (
                                    <a
                                      href="https://www.hud.gov/sites/dfiles/PA/documents/HUDAsstAnimalNC1-28-2020.pdf"
                                      className="link"
                                      target="mi_offsite">
                                      HUD notice FHEO-2020-1
                                    </a>
                                    )
                                  </p>
                                </>
                              ) : null}
                            </div>

                            {pet.AnimalCategoryID == 2 ? (
                              <div
                                className={
                                  'radio-container' +
                                  (isDisabled ||
                                  pet.ResidentOnboardingStatusID > 1
                                    ? ' disabled'
                                    : '')
                                }>
                                <p>
                                  <strong>
                                    What type of Assistance Animal is{' '}
                                    {pet.Name ? pet.Name : 'this'}?
                                  </strong>
                                </p>

                                <FormControl component="fieldset">
                                  <RadioGroup
                                    aria-label="Assistance Animal Type"
                                    name="asstistanceAnimalType"
                                    value={pet.AssistanceAnimalTypeID}
                                    onChange={(event, newValue) => {
                                      setResidentPetList(
                                        residentPetList.map((_pet) =>
                                          _pet.ResidentPetID ===
                                          pet.ResidentPetID
                                            ? {
                                                ..._pet,
                                                dataUpdated: true,
                                                AssistanceAnimalTypeID: newValue
                                              }
                                            : { ..._pet }
                                        )
                                      );
                                    }}>
                                    <FormControlLabel
                                      value="1"
                                      className={
                                        pet.AssistanceAnimalTypeID == '1'
                                          ? 'selected'
                                          : ''
                                      }
                                      disabled={
                                        isDisabled ||
                                        pet.ResidentOnboardingStatusID > 1
                                      }
                                      control={<Radio color="primary" />}
                                      label={
                                        <>
                                          {pet.Name ? pet.Name : 'This'} is an
                                          Emotional Support Animal (ESA)
                                          recommended by my health care provider
                                          and I am requesting a reasonable
                                          accommodation for this animal under
                                          The Fair Housing Act. Therapy,
                                          comfort, and companion animals also
                                          fall within this category.
                                        </>
                                      }
                                    />
                                    {pet.PetType == 'Dog' ? (
                                      <>
                                        <FormControlLabel
                                          value="2"
                                          className={
                                            pet.AssistanceAnimalTypeID == '2'
                                              ? 'selected'
                                              : ''
                                          }
                                          disabled={
                                            isDisabled ||
                                            pet.ResidentOnboardingStatusID > 1
                                          }
                                          control={<Radio color="primary" />}
                                          label={
                                            <>
                                              {pet.Name ? pet.Name : 'This'} is
                                              a trained Service Animal as
                                              defined by the Americans with
                                              Disabilities Act (ADA). Please
                                              note that animals whose sole
                                              function is to provide comfort or
                                              emotional support do not qualify
                                              as service animals under the ADA
                                              definition.
                                            </>
                                          }
                                        />
                                        <FormControlLabel
                                          value="3"
                                          className={
                                            pet.AssistanceAnimalTypeID == '3'
                                              ? 'selected'
                                              : ''
                                          }
                                          disabled={
                                            isDisabled ||
                                            pet.ResidentOnboardingStatusID > 1
                                          }
                                          control={<Radio color="primary" />}
                                          label={
                                            <>
                                              OTHER -{' '}
                                              {pet.Name ? pet.Name : 'This'} is
                                              NOT an Emotional Support Animal,
                                              nor a Service Animal, but does do
                                              work, perform tasks, or provides
                                              assistance that helps with my
                                              disability and I am requesting a
                                              reasonable accommodation for this
                                              animal under The Fair Housing Act.
                                            </>
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </RadioGroup>
                                </FormControl>

                                {pet.AssistanceAnimalTypeID == 1 ? (
                                  <>
                                    <p style={{ marginTop: '10px' }}>
                                      <strong>
                                        About ESA Reasonable Accommodation
                                        Requests - Please Read Carefully
                                      </strong>
                                    </p>

                                    <p>
                                      It is our policy to verify the
                                      authenticity and reliability of Emotional
                                      Support Animal documentation before
                                      processing reasonable accommodation
                                      requests. We do that by contacting the
                                      health care provider that authored the
                                      documentation, by email or phone, and
                                      asking them to confirm the following:
                                    </p>
                                    <ul>
                                      <li>
                                        That they prepared and signed the
                                        document you attached as part of your
                                        accommodation request.{' '}
                                      </li>
                                      <li>
                                        That they have or had a professional
                                        relationship with you involving the
                                        provision of health care or
                                        disability-related services.
                                      </li>
                                      <li>
                                        That they used their personal knowledge
                                        of you in their diagnoses of your
                                        disability.
                                      </li>
                                      <li>
                                        That they believe an emotional support
                                        animal will help alleviate a symptom or
                                        effect of the disability.
                                      </li>
                                    </ul>
                                    <p>
                                      No personal details about you will be
                                      asked of your provider. We just need to
                                      confirm that they authored the document
                                      you attached and that it can be relied
                                      upon as reliable support for your request.
                                    </p>
                                    <p>
                                      WE CANNOT APPROVE YOUR REASONABLE
                                      ACCOMMODATION REQUEST UNTIL YOUR
                                      HEALTHCARE PROVIDER CONFIRMS THE
                                      AUTHENTICITY OF YOUR SUPPORTING
                                      DOCUMENTATION.
                                    </p>

                                    <FormControlLabel
                                      control={
                                        <div>
                                          <Checkbox
                                            checked={pet.ESA_PolicyCheckbox}
                                            onChange={(event) =>
                                              setResidentPetList(
                                                residentPetList.map((_pet) =>
                                                  _pet.ResidentPetID ===
                                                  pet.ResidentPetID
                                                    ? {
                                                        ..._pet,
                                                        dataUpdated: true,
                                                        ESA_PolicyCheckbox:
                                                          event.target.checked
                                                      }
                                                    : { ..._pet }
                                                )
                                              )
                                            }
                                            name="gilad"
                                          />
                                        </div>
                                      }
                                      label={
                                        <>
                                          I have read and consent to this policy
                                          and authorize the author of my ESA
                                          documentation to confirm its
                                          authenticity to {siteConfig.brand}{' '}
                                          LLC.
                                        </>
                                      }
                                    />

                                    <p style={{ marginTop: '20px' }}>
                                      <strong>ESA Documentation</strong>
                                    </p>
                                    <p>
                                      Please provide the following information
                                      about the healthcare provider that issued
                                      your documentation. The information you
                                      enter below must match the information on
                                      the documentation that you attach below.
                                    </p>

                                    <div style={{ marginTop: '-10px' }}>
                                      <div className="field-container">
                                        <TextField
                                          required
                                          variant="outlined"
                                          fullWidth
                                          label="Patient's Name"
                                          value={pet.ESAPatient_Name}
                                          disabled={isDisabled}
                                          onChange={(event) =>
                                            setResidentPetList(
                                              residentPetList.map((_pet) =>
                                                _pet.ResidentPetID ===
                                                pet.ResidentPetID
                                                  ? {
                                                      ..._pet,
                                                      dataUpdated: true,
                                                      ESAPatient_Name:
                                                        event.target.value
                                                    }
                                                  : { ..._pet }
                                              )
                                            )
                                          }
                                        />
                                      </div>

                                      <div className="field-container">
                                        <InputMask
                                          key={'ESA_Patient_DOB_' + petIndex}
                                          mask="99/99/9999"
                                          maskChar={'_'}
                                          disabled={isDisabled}
                                          value={
                                            pet.ESAPatient_DOB &&
                                            pet.ESAPatient_DOB.indexOf('_') ===
                                              -1
                                              ? moment(
                                                  pet.ESAPatient_DOB
                                                ).format('MM/DD/YYYY')
                                              : pet.ESAPatient_DOB
                                          }
                                          onChange={(event) =>
                                            setResidentPetList(
                                              residentPetList.map((_pet) =>
                                                _pet.ResidentPetID ===
                                                pet.ResidentPetID
                                                  ? {
                                                      ..._pet,
                                                      dataUpdated: true,
                                                      ESAPatient_DOB:
                                                        event.target.value
                                                    }
                                                  : { ..._pet }
                                              )
                                            )
                                          }>
                                          {() => (
                                            <TextField
                                              fullWidth
                                              label={'Date of Birth'}
                                              required
                                              inputProps={{
                                                inputMode: 'numeric'
                                              }}
                                              disabled={isDisabled}
                                            />
                                          )}
                                        </InputMask>
                                      </div>
                                    </div>

                                    <p style={{ marginTop: '20px' }}>
                                      <strong>
                                        Healthcare Provider Information
                                      </strong>
                                    </p>

                                    <div>
                                      <div className="field-container">
                                        <TextField
                                          required
                                          variant="outlined"
                                          fullWidth
                                          label="Healthcare Provider's Full Name"
                                          value={pet.ESAProvider_Name}
                                          disabled={isDisabled}
                                          onChange={(event) =>
                                            setResidentPetList(
                                              residentPetList.map((_pet) =>
                                                _pet.ResidentPetID ===
                                                pet.ResidentPetID
                                                  ? {
                                                      ..._pet,
                                                      dataUpdated: true,
                                                      ESAProvider_Name:
                                                        event.target.value
                                                    }
                                                  : { ..._pet }
                                              )
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="field-container">
                                        <InputMask
                                          mask="99/99/9999"
                                          key={
                                            'ESAProvider_DateDocument_' +
                                            petIndex
                                          }
                                          maskChar={'_'}
                                          disabled={isDisabled}
                                          value={
                                            pet.ESAProvider_DateDocument &&
                                            pet.ESAProvider_DateDocument.indexOf(
                                              '_'
                                            ) === -1
                                              ? moment(
                                                  pet.ESAProvider_DateDocument
                                                ).format('MM/DD/YYYY')
                                              : pet.ESAProvider_DateDocument
                                          }
                                          onChange={(event) =>
                                            setResidentPetList(
                                              residentPetList.map((_pet) =>
                                                _pet.ResidentPetID ===
                                                pet.ResidentPetID
                                                  ? {
                                                      ..._pet,
                                                      dataUpdated: true,
                                                      ESAProvider_DateDocument:
                                                        event.target.value
                                                    }
                                                  : { ..._pet }
                                              )
                                            )
                                          }>
                                          {() => (
                                            <TextField
                                              fullWidth
                                              label={'Date on Document'}
                                              required
                                              inputProps={{
                                                inputMode: 'numeric'
                                              }}
                                              disabled={isDisabled}
                                            />
                                          )}
                                        </InputMask>
                                      </div>
                                      <div className="field-container">
                                        <TextField
                                          required
                                          variant="outlined"
                                          fullWidth
                                          label="Healthcare Provider's Email"
                                          value={pet.ESAProvider_Email}
                                          disabled={isDisabled}
                                          onChange={(event) =>
                                            setResidentPetList(
                                              residentPetList.map((_pet) =>
                                                _pet.ResidentPetID ===
                                                pet.ResidentPetID
                                                  ? {
                                                      ..._pet,
                                                      dataUpdated: true,
                                                      ESAProvider_Email:
                                                        event.target.value
                                                    }
                                                  : { ..._pet }
                                              )
                                            )
                                          }
                                        />
                                      </div>

                                      <div className="field-container">
                                        <InputMask
                                          mask="999-999-9999"
                                          maskChar={'_'}
                                          disabled={isDisabled}
                                          value={pet.ESAProvider_Phone}
                                          onChange={(event) =>
                                            setResidentPetList(
                                              residentPetList.map((_pet) =>
                                                _pet.ResidentPetID ===
                                                pet.ResidentPetID
                                                  ? {
                                                      ..._pet,
                                                      dataUpdated: true,
                                                      ESAProvider_Phone:
                                                        event.target.value
                                                    }
                                                  : { ..._pet }
                                              )
                                            )
                                          }>
                                          {() => (
                                            <TextField
                                              fullWidth
                                              label={
                                                "Heathcare Provider's Phone"
                                              }
                                              required
                                              inputProps={{
                                                inputMode: 'numeric'
                                              }}
                                              disabled={isDisabled}
                                            />
                                          )}
                                        </InputMask>
                                      </div>

                                      <div className="field-container">
                                        <TextField
                                          required
                                          variant="outlined"
                                          fullWidth
                                          label="Street Address"
                                          value={pet.ESAProvider_AddressLine1}
                                          disabled={isDisabled}
                                          onChange={(event) =>
                                            setResidentPetList(
                                              residentPetList.map((_pet) =>
                                                _pet.ResidentPetID ===
                                                pet.ResidentPetID
                                                  ? {
                                                      ..._pet,
                                                      dataUpdated: true,
                                                      ESAProvider_AddressLine1:
                                                        event.target.value
                                                    }
                                                  : { ..._pet }
                                              )
                                            )
                                          }
                                        />
                                      </div>

                                      <div className="field-container">
                                        <TextField
                                          required
                                          variant="outlined"
                                          fullWidth
                                          label="City"
                                          value={pet.ESAProvider_City}
                                          disabled={isDisabled}
                                          onChange={(event) =>
                                            setResidentPetList(
                                              residentPetList.map((_pet) =>
                                                _pet.ResidentPetID ===
                                                pet.ResidentPetID
                                                  ? {
                                                      ..._pet,
                                                      dataUpdated: true,
                                                      ESAProvider_City:
                                                        event.target.value
                                                    }
                                                  : { ..._pet }
                                              )
                                            )
                                          }
                                        />
                                      </div>

                                      <div style={{ marginTop: '-10px' }}>
                                        <div className="field-container">
                                          <FormControl
                                            variant="outlined"
                                            fullWidth
                                            required>
                                            <InputLabel
                                              id={
                                                'textfieldESAProvider_' +
                                                petIndex
                                              }>
                                              State
                                            </InputLabel>
                                            <Select
                                              labelId={
                                                'textfieldESAProvider_' +
                                                petIndex
                                              }
                                              label="License Plate State"
                                              id={
                                                'textfieldESAProvider_' +
                                                petIndex +
                                                '_Menu'
                                              }
                                              required
                                              value={pet.ESAProvider_State}
                                              onChange={(event) =>
                                                setResidentPetList(
                                                  residentPetList.map((_pet) =>
                                                    _pet.ResidentPetID ===
                                                    pet.ResidentPetID
                                                      ? {
                                                          ..._pet,
                                                          dataUpdated: true,
                                                          ESAProvider_State:
                                                            event.target.value
                                                        }
                                                      : { ..._pet }
                                                  )
                                                )
                                              }>
                                              {stateList.map((state) => (
                                                <MenuItem value={state}>
                                                  {state}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </div>

                                        <div className="field-container">
                                          <TextField
                                            required
                                            variant="outlined"
                                            fullWidth
                                            label="Zip Code"
                                            value={pet.ESAProvider_Zip}
                                            disabled={isDisabled}
                                            onChange={(event) =>
                                              setResidentPetList(
                                                residentPetList.map((_pet) =>
                                                  _pet.ResidentPetID ===
                                                  pet.ResidentPetID
                                                    ? {
                                                        ..._pet,
                                                        dataUpdated: true,
                                                        ESAProvider_Zip:
                                                          event.target.value
                                                      }
                                                    : { ..._pet }
                                                )
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      <p style={{ marginTop: '20px' }}>
                                        <strong>
                                          Upload Documentation – do not upload
                                          confidential medical records.
                                        </strong>
                                      </p>

                                      <p>
                                        The documentation that you upload should
                                        establish: (1) that you are disabled,
                                        and (2) that you have a
                                        disability-related need for the
                                        assistance animal.{' '}
                                      </p>

                                      <ResidentOnboardingFileUpload
                                        residentOnboarding={residentOnboarding}
                                        refreshResidentOnboarding={
                                          refreshResidentOnboarding
                                        }
                                        residentPetID={pet.ResidentPetID}
                                        residentFileTypeID={8}
                                        label={'ESA Documentation'}
                                        residentID={residentID}
                                        disabled={
                                          isDisabled ||
                                          pet.ResidentOnboardingStatusID > 1
                                        }
                                      />
                                    </div>
                                  </>
                                ) : pet.AssistanceAnimalTypeID == 2 ? (
                                  <>
                                    <p style={{ marginTop: '10px' }}>
                                      <strong>
                                        What is the ADA definition of a Service
                                        Animal?
                                      </strong>
                                    </p>
                                    <p>
                                      Under the ADA, “service animal means any
                                      dog that is individually trained to do
                                      work or perform tasks for the benefit of
                                      an individual with a disability, including
                                      a physical, sensory, psychiatric,
                                      intellectual, or other mental disability.
                                      Other species of animals, whether wild or
                                      domestic, trained or untrained, are not
                                      service animals for the purposes of this
                                      definition. The work or tasks performed by
                                      a service animal must be directly related
                                      to the individual's disability.”{' '}
                                    </p>

                                    <p style={{ marginTop: '10px' }}>
                                      <strong>
                                        Does the ADA definition of a Service
                                        Animal describe{' '}
                                        {pet.Name ? pet.Name : 'this animal'}?
                                      </strong>
                                    </p>

                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        aria-label="Matches ADA definition of service animal"
                                        name="SA_IsDefinitionMatch"
                                        className="yes-no"
                                        value={pet.SA_IsDefinitionMatch}
                                        onChange={(event, newValue) => {
                                          setResidentPetList(
                                            residentPetList.map((_pet) =>
                                              _pet.ResidentPetID ===
                                              pet.ResidentPetID
                                                ? {
                                                    ..._pet,
                                                    dataUpdated: true,
                                                    SA_IsDefinitionMatch:
                                                      String(newValue) ===
                                                      'true'
                                                  }
                                                : { ..._pet }
                                            )
                                          );
                                        }}>
                                        <FormControlLabel
                                          value={true}
                                          className={
                                            pet.SA_IsDefinitionMatch
                                              ? 'selected'
                                              : ''
                                          }
                                          disabled={
                                            isDisabled ||
                                            pet.ResidentOnboardingStatusID > 1
                                          }
                                          control={<Radio color="primary" />}
                                          label={'Yes'}
                                        />
                                        <FormControlLabel
                                          value={false}
                                          className={
                                            pet.SA_IsDefinitionMatch === false
                                              ? 'selected'
                                              : ''
                                          }
                                          disabled={
                                            isDisabled ||
                                            pet.ResidentOnboardingStatusID > 1
                                          }
                                          control={<Radio color="primary" />}
                                          label={'No'}
                                        />
                                      </RadioGroup>
                                    </FormControl>

                                    <p style={{ marginTop: '10px' }}>
                                      <strong>
                                        Is the dog a service animal required
                                        because of a disability?
                                      </strong>
                                    </p>

                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        aria-label="Required for Disability"
                                        name="SA_IsRequiredForDisability"
                                        className="yes-no"
                                        value={pet.SA_IsRequiredForDisability}
                                        onChange={(event, newValue) => {
                                          setResidentPetList(
                                            residentPetList.map((_pet) =>
                                              _pet.ResidentPetID ===
                                              pet.ResidentPetID
                                                ? {
                                                    ..._pet,
                                                    dataUpdated: true,
                                                    SA_IsRequiredForDisability:
                                                      String(newValue) ===
                                                      'true'
                                                  }
                                                : { ..._pet }
                                            )
                                          );
                                        }}>
                                        <FormControlLabel
                                          value={true}
                                          className={
                                            pet.SA_IsRequiredForDisability
                                              ? 'selected'
                                              : ''
                                          }
                                          disabled={
                                            isDisabled ||
                                            pet.ResidentOnboardingStatusID > 1
                                          }
                                          control={<Radio color="primary" />}
                                          label={'Yes'}
                                        />
                                        <FormControlLabel
                                          value={false}
                                          className={
                                            pet.SA_IsRequiredForDisability ===
                                            false
                                              ? 'selected'
                                              : ''
                                          }
                                          disabled={
                                            isDisabled ||
                                            pet.ResidentOnboardingStatusID > 1
                                          }
                                          control={<Radio color="primary" />}
                                          label={'No'}
                                        />
                                      </RadioGroup>
                                    </FormControl>

                                    <TextField
                                      fullWidth
                                      required
                                      label={
                                        'What work or task has the dog been trained to perform?'
                                      }
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      value={pet.SA_TrainingDescription}
                                      onChange={(event) =>
                                        setResidentPetList(
                                          residentPetList.map((_pet) =>
                                            _pet.ResidentPetID ===
                                            pet.ResidentPetID
                                              ? {
                                                  ..._pet,
                                                  dataUpdated: true,
                                                  SA_TrainingDescription:
                                                    event.target.value
                                                }
                                              : { ..._pet }
                                          )
                                        )
                                      }
                                    />
                                  </>
                                ) : pet.AssistanceAnimalTypeID == 3 ? (
                                  <>
                                    <p style={{ marginTop: '10px' }}>
                                      <strong>
                                        Do you have a disability? The ADA
                                        defines disability as a physical or
                                        mental impairment that substantially
                                        limits one or more major life
                                        activities.
                                      </strong>
                                    </p>
                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        name="AA_HasDisability"
                                        className="yes-no"
                                        value={pet.AA_HasDisability}
                                        onChange={(event, newValue) => {
                                          setResidentPetList(
                                            residentPetList.map((_pet) =>
                                              _pet.ResidentPetID ===
                                              pet.ResidentPetID
                                                ? {
                                                    ..._pet,
                                                    dataUpdated: true,
                                                    AA_HasDisability:
                                                      String(newValue) ===
                                                      'true'
                                                  }
                                                : { ..._pet }
                                            )
                                          );
                                        }}>
                                        <FormControlLabel
                                          value={true}
                                          className={
                                            pet.AA_HasDisability
                                              ? 'selected'
                                              : ''
                                          }
                                          disabled={
                                            isDisabled ||
                                            pet.ResidentOnboardingStatusID > 1
                                          }
                                          control={<Radio color="primary" />}
                                          label={'Yes'}
                                        />
                                        <FormControlLabel
                                          value={false}
                                          className={
                                            pet.AA_HasDisability === false
                                              ? 'selected'
                                              : ''
                                          }
                                          disabled={
                                            isDisabled ||
                                            pet.ResidentOnboardingStatusID > 1
                                          }
                                          control={<Radio color="primary" />}
                                          label={'No'}
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                    <p style={{ marginTop: '10px' }}>
                                      <strong>
                                        Is your disability Observable or
                                        Non-observable?
                                      </strong>
                                      <br />
                                      Not sure?{' '}
                                      <a
                                        className="link"
                                        onClick={() =>
                                          setShowHudGuidance(true)
                                        }>
                                        Click here
                                      </a>{' '}
                                      to view HUD guidance.
                                    </p>
                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        name="AA_IsObservable"
                                        value={pet.AA_IsObservable}
                                        onChange={(event, newValue) => {
                                          setResidentPetList(
                                            residentPetList.map((_pet) =>
                                              _pet.ResidentPetID ===
                                              pet.ResidentPetID
                                                ? {
                                                    ..._pet,
                                                    dataUpdated: true,
                                                    AA_IsObservable:
                                                      String(newValue) ===
                                                      'true'
                                                  }
                                                : { ..._pet }
                                            )
                                          );
                                        }}>
                                        <FormControlLabel
                                          value={true}
                                          className={
                                            pet.AA_IsObservable
                                              ? 'selected'
                                              : ''
                                          }
                                          disabled={
                                            isDisabled ||
                                            pet.ResidentOnboardingStatusID > 1
                                          }
                                          control={<Radio color="primary" />}
                                          label={'Observable'}
                                        />
                                        <FormControlLabel
                                          value={false}
                                          className={
                                            pet.AA_IsObservable === false
                                              ? 'selected'
                                              : ''
                                          }
                                          disabled={
                                            isDisabled ||
                                            pet.ResidentOnboardingStatusID > 1
                                          }
                                          control={<Radio color="primary" />}
                                          label={'Non-observable'}
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                    {pet.AA_IsObservable === false ? (
                                      <>
                                        <p style={{ marginTop: '20px' }}>
                                          <strong>
                                            Non-observable Disability
                                            Documentation – do not upload
                                            confidential medical records.
                                          </strong>
                                        </p>

                                        <p>
                                          Before processing your request, we
                                          need third-party information that
                                          reasonably supports that you have a
                                          disability. HUD guidance mentions the
                                          following information as acceptable
                                          support. Please note that this is not
                                          a comprehensive list of acceptable
                                          supporting information. There may be
                                          other acceptable forms of supporting
                                          information not mentioned below.
                                        </p>

                                        <ul>
                                          <li>
                                            A determination of disability from a
                                            federal, state, or local government
                                            agency.
                                          </li>
                                          <li>
                                            Receipt of disability benefits or
                                            services (Social Security Disability
                                            Income (SSDI)), Medicare or
                                            Supplemental Security Income (SSI)
                                            for a person under age 65, veterans’
                                            disability benefits, services from a
                                            vocational rehabilitation agency, or
                                            disability benefits or services from
                                            another federal, state, or local
                                            agency.
                                          </li>
                                          <li>
                                            Eligibility for housing assistance
                                            or a housing voucher received
                                            because of disability.
                                          </li>
                                          <li>
                                            Information confirming disability
                                            from a health care professional –
                                            e.g., physician, optometrist,
                                            psychiatrist, psychologist,
                                            physician’s assistant, nurse
                                            practitioner, or nurse.
                                          </li>
                                        </ul>

                                        <ResidentOnboardingFileUpload
                                          residentOnboarding={
                                            residentOnboarding
                                          }
                                          refreshResidentOnboarding={
                                            refreshResidentOnboarding
                                          }
                                          residentPetID={pet.ResidentPetID}
                                          residentFileTypeID={10}
                                          label={
                                            'Non-observable Disability Documentation'
                                          }
                                          residentID={residentID}
                                          disabled={
                                            isDisabled ||
                                            pet.ResidentOnboardingStatusID > 1
                                          }
                                        />
                                      </>
                                    ) : null}
                                    <p style={{ marginTop: '20px' }}>
                                      <strong>
                                        Does this animal do work, perform tasks,
                                        or provide assistance that helps with
                                        your disability?
                                      </strong>
                                    </p>
                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        name="AA_IsAnimalProvidesAssistance"
                                        value={
                                          pet.AA_IsAnimalProvidesAssistance
                                        }
                                        onChange={(event, newValue) => {
                                          setResidentPetList(
                                            residentPetList.map((_pet) =>
                                              _pet.ResidentPetID ===
                                              pet.ResidentPetID
                                                ? {
                                                    ..._pet,
                                                    dataUpdated: true,
                                                    AA_IsAnimalProvidesAssistance:
                                                      String(newValue) ===
                                                      'true'
                                                  }
                                                : { ..._pet }
                                            )
                                          );
                                        }}>
                                        <FormControlLabel
                                          value={true}
                                          className={
                                            pet.AA_IsAnimalProvidesAssistance
                                              ? 'selected'
                                              : ''
                                          }
                                          disabled={
                                            isDisabled ||
                                            pet.ResidentOnboardingStatusID > 1
                                          }
                                          control={<Radio color="primary" />}
                                          label={'Yes'}
                                        />
                                        <FormControlLabel
                                          value={false}
                                          className={
                                            pet.AA_IsAnimalProvidesAssistance ===
                                            false
                                              ? 'selected'
                                              : ''
                                          }
                                          disabled={
                                            isDisabled ||
                                            pet.ResidentOnboardingStatusID > 1
                                          }
                                          control={<Radio color="primary" />}
                                          label={'No'}
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                    {pet.AA_IsAnimalProvidesAssistance ? (
                                      <TextField
                                        fullWidth
                                        required
                                        label={
                                          'What work or task has the animal been trained to perform?'
                                        }
                                        InputLabelProps={{
                                          shrink: true
                                        }}
                                        value={pet.AA_TrainingDescription}
                                        onChange={(event) =>
                                          setResidentPetList(
                                            residentPetList.map((_pet) =>
                                              _pet.ResidentPetID ===
                                              pet.ResidentPetID
                                                ? {
                                                    ..._pet,
                                                    dataUpdated: true,
                                                    AA_TrainingDescription:
                                                      event.target.value
                                                  }
                                                : { ..._pet }
                                            )
                                          )
                                        }
                                      />
                                    ) : null}
                                  </>
                                ) : null}
                              </div>
                            ) : null}

                            <div
                              className={
                                'radio-container' +
                                (isDisabled ||
                                pet.ResidentOnboardingStatusID > 1
                                  ? ' disabled'
                                  : '')
                              }
                              style={{ marginBottom: '10px' }}>
                              <p>
                                <strong>
                                  Statement of Facts and Agreements -{' '}
                                </strong>
                                Please answer the following questions.
                              </p>

                              {editPet.FieldList.map((field) => (
                                <div className="pass-fail">
                                  <p style={{ marginTop: '20px' }}>
                                    <strong>{field.Question}</strong>
                                  </p>
                                  <FormControl component="fieldset">
                                    <RadioGroup
                                      name={field.FieldName}
                                      value={
                                        editPet.Fields[field.FieldName]
                                          ? editPet.Fields[field.FieldName]
                                              .Answer
                                          : ''
                                      }
                                      onChange={(event, newValue) => {
                                        setEditPet({
                                          ...editPet,
                                          Fields: {
                                            ...editPet.Fields,
                                            [field.FieldName]: {
                                              ...field,
                                              Answer: newValue
                                            }
                                          }
                                        });
                                      }}>
                                      <PetPassNoField
                                        value={'Yes'}
                                        editPet={editPet}
                                        pet={pet}
                                        field={field}
                                        isDisabled={isDisabled}
                                      />
                                      <PetPassNoField
                                        value={'No'}
                                        editPet={editPet}
                                        pet={pet}
                                        field={field}
                                        isDisabled={isDisabled}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                              ))}

                              <div className={'acknowledgment'}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={pet.FieldAnswerCheckbox}
                                      disabled={
                                        isDisabled ||
                                        pet.ResidentOnboardingStatusID > 1
                                      }
                                      onChange={(event) => {
                                        setResidentPetList(
                                          residentPetList.map((_pet) =>
                                            _pet.ResidentPetID ===
                                            pet.ResidentPetID
                                              ? {
                                                  ..._pet,
                                                  dataUpdated: true,
                                                  FieldAnswerCheckbox:
                                                    event.target.checked
                                                }
                                              : { ..._pet }
                                          )
                                        );
                                      }}
                                      name="gilad"
                                    />
                                  }
                                  label={
                                    <>
                                      <p>
                                        I understand that the property will rely
                                        on the information I provided above in
                                        approving or rejecting this application
                                        and I accept full responsibility for its
                                        truthfulness and accuracy.
                                      </p>
                                      <p style={{ marginBottom: '0px' }}>
                                        By typing my name below, I am consenting
                                        to the use of this digital form and
                                        intending to electronically sign this
                                        submittal.
                                      </p>

                                      <div className="field-container">
                                        <TextField
                                          required
                                          variant="outlined"
                                          fullWidth
                                          key={
                                            'textfieldSignerSignature_' +
                                            petIndex
                                          }
                                          id={
                                            'textfieldSignerSignature_' +
                                            petIndex
                                          }
                                          label={'Type your name'}
                                          value={pet.SignerSignature}
                                          disabled={
                                            isDisabled ||
                                            pet.ResidentOnboardingStatusID > 1
                                          }
                                          onChange={(event) =>
                                            setResidentPetList(
                                              residentPetList.map((_pet) =>
                                                _pet.ResidentPetID ===
                                                pet.ResidentPetID
                                                  ? {
                                                      ..._pet,
                                                      dataUpdated: true,
                                                      SignerSignature:
                                                        event.target.value
                                                    }
                                                  : { ..._pet }
                                              )
                                            )
                                          }
                                        />
                                      </div>

                                      <div className="field-container">
                                        <InputMask
                                          mask="99/99/9999"
                                          key={'SignerDateSigned' + petIndex}
                                          maskChar={'_'}
                                          disabled
                                          value={
                                            pet.SignerDateSigned &&
                                            pet.SignerDateSigned.indexOf(
                                              '_'
                                            ) === -1
                                              ? moment(
                                                  pet.SignerDateSigned
                                                ).format('MM/DD/YYYY')
                                              : moment().format('MM/DD/YYYY')
                                          }
                                          onChange={(event) =>
                                            setResidentPetList(
                                              residentPetList.map((_pet) =>
                                                _pet.ResidentPetID ===
                                                pet.ResidentPetID
                                                  ? {
                                                      ..._pet,
                                                      dataUpdated: true,
                                                      SignerDateSigned:
                                                        event.target.value
                                                    }
                                                  : { ..._pet }
                                              )
                                            )
                                          }>
                                          {() => (
                                            <TextField
                                              fullWidth
                                              label={'Date Signed'}
                                              required
                                              inputProps={{
                                                inputMode: 'numeric'
                                              }}
                                              disabled
                                            />
                                          )}
                                        </InputMask>
                                      </div>
                                    </>
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      {!pet.ResidentOnboardingStatusID ||
                      pet.ResidentOnboardingStatusID === 1 ? (
                        <div className="save-cancel">
                          {validationErrorList && validationErrorList.length ? (
                            <Tooltip
                              title={
                                <div className="resident-onboarding-submit-cover-tooltip">
                                  <p>
                                    Please check the form above and make sure
                                    you provided all fields:
                                  </p>
                                  <ul>
                                    {validationErrorList.map((error) => (
                                      <li>{error}</li>
                                    ))}
                                  </ul>
                                </div>
                              }
                              placement="top-start">
                              <div className="submit-cover"></div>
                            </Tooltip>
                          ) : null}

                          <Button
                            variant="contained"
                            color="primary"
                            className={
                              validationErrorList && validationErrorList.length
                                ? 'disabled'
                                : ''
                            }
                            disabled={
                              validationErrorList && validationErrorList.length
                            }
                            onClick={() => {
                              if (
                                !validationErrorList ||
                                !validationErrorList.length
                              ) {
                                actions.pageLoading(
                                  true,
                                  'Saving animal information...' +
                                    (pet.ResidentOnboardingStatusID === 1 &&
                                    pet.AnimalCategoryID == 2 &&
                                    pet.AssistanceAnimalTypeID == 1 &&
                                    pet.ESAProvider_Email
                                      ? ' This process can take up to 15 seconds.'
                                      : '')
                                );

                                let _fieldList = [];

                                editPet.FieldList.map((field) => {
                                  const QuestionComponent = () =>
                                    field.Question;

                                  _fieldList.push({
                                    ...field,
                                    Question:
                                      ReactDOMServer.renderToStaticMarkup(
                                        <QuestionComponent />
                                      ),
                                    Answer: editPet.Fields[field.FieldName]
                                      ? editPet.Fields[field.FieldName].Answer
                                      : null
                                  });
                                });

                                actions
                                  .saveResidentPet(
                                    {
                                      Pet: {
                                        ...pet,
                                        SignerDateSigned: pet.SignerDateSigned
                                          ? pet.SignerDateSigned
                                          : moment().format('MM/DD/YYYY')
                                      },
                                      FieldList: _fieldList
                                    },
                                    residentID
                                  )
                                  .then((result) => {
                                    setPetUpdated(true);
                                    actions.pageLoading(false);

                                    actions.loadResidentOnboarding(residentID);

                                    let _reason =
                                      result.updateResult &&
                                      result.updateResult.Reason
                                        ? result.updateResult.Reason.split(
                                            registrationFeeReasonText
                                          ).join('')
                                        : '';

                                    if (_reason) {
                                      setSaveWarning({
                                        residentPetID: pet.ResidentPetID,
                                        reason: _reason
                                      });
                                    } else {
                                      setSaveWarning(null);
                                      setEditPet(null);

                                      if (
                                        result.updateResult.Reason ===
                                        registrationFeeReasonText
                                      ) {
                                        setShowPetPayment({ ...pet });
                                      }
                                    }
                                  });
                              }
                            }}>
                            Save
                          </Button>

                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              loadResidentOnboardingSetting();
                              loadResidentPetList(true);
                              setEditPet(null);
                            }}>
                            Cancel
                          </Button>
                        </div>
                      ) : null}
                    </>
                  ))}
              </div>
            ) : null}
          </>
        )}
      </div>

      {saveWarning ? (
        <ResidentOnboardingSaveWarning
          dataTypeLabel="pet"
          continueFunc={() => {
            let petIndex = -1;
            residentPetList.map((_pet, _petIndex) => {
              if (_pet.ResidentPetID === saveWarning.residentPetID) {
                petIndex = _petIndex;
              }
            });
            setEditPet({
              loading: true,
              petIndex
            });
            setSaveWarning(null);
          }}
          closeFunc={() => setSaveWarning(null)}
          remainingItems={saveWarning.reason}
        />
      ) : null}

      {residentStatusUpdate ? (
        <ResidentOnboardingStatusUpdate
          dataTypeLabel={'animal'}
          type="pet"
          residentOnboardingStatusID={
            residentStatusUpdate.residentOnboardingStatusID
          }
          profileKey={profileKey}
          resident={residentOnboarding.Header}
          exists={!residentOnboardingSetting.HasNoPet}
          saveFunc={(note) => {
            if (residentOnboardingSetting.HasNoPet) {
              return actions
                .saveResidentOnboarding(
                  {
                    ...residentOnboarding.Header,
                    HasNoPetConfirmed:
                      residentStatusUpdate.residentOnboardingStatusID === 3
                        ? true
                        : false,
                    HasNoPetReason: note
                  },
                  residentID
                )
                .then((result) => {
                  setResidentStatusUpdate(null);
                  closeOnboardingRow();
                });
            } else {
              actions
                .saveResidentPetStatus(
                  residentID,
                  { ...residentStatusUpdate.pet },
                  residentStatusUpdate.residentOnboardingStatusID,
                  note
                )
                .then((result) => {
                  setResidentStatusUpdate(null);
                  closeOnboardingRow();
                });
            }
          }}
          closeFunc={() => setResidentStatusUpdate(null)}
        />
      ) : null}

      {deletePet ? (
        <GeneralPopup
          title={'Delete Animal'}
          message={
            <>
              <p style={{ marginTop: '20px' }}>
                Are you sure you want to delete Animal {deletePet.petIndex + 1}?
                The animal will be permanently removed.
              </p>
            </>
          }
          submitLabel={'Confirm'}
          submitFunc={() =>
            actions.deleteResidentPet(deletePet, residentID).then((result) => {
              setDeletePet(null);
              actions.loadResidentOnboarding(residentID);
            })
          }
          closeFunc={() => setDeletePet(null)}
          closeLabel={'Cancel'}
        />
      ) : null}

      {cancelESA ? (
        <GeneralPopup
          title={'ESA Request Cancellation'}
          className={'cancel-esa-popup'}
          message={
            <>
              <p style={{ marginTop: '20px' }}>
                Please confirm your desire to cancel the Emotional Support
                Animal (ESA) accommodation request you submitted on{' '}
                {moment(cancelESA.SignerDateSigned).format('MM/DD/YYYY')} for a{' '}
                {cancelESA.PetType.toLowerCase()} named {cancelESA.Name} at{' '}
                {residentOnboarding.Header.PropertyName} by acknowledging the
                following:
              </p>

              <div
                className={
                  'acknowledgment' + (cancelESA.Checkbox1 ? ' completed' : '')
                }>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cancelESA.Checkbox1}
                      onChange={(event) => {
                        setCancelESA({
                          ...cancelESA,
                          Checkbox1: event.target.checked
                        });
                      }}
                      name="gilad"
                    />
                  }
                  label={
                    <>
                      <p>
                        I understand that by cancelling this ESA accommodation
                        request {cancelESA.Name} will be considered a household
                        pet by {residentOnboarding.Header.PropertyName} and will
                        be subject to the same pet policies as all other
                        household pets at{' '}
                        {residentOnboarding.Header.PropertyName}.
                      </p>
                    </>
                  }
                />
              </div>

              <div
                className={
                  'acknowledgment' + (cancelESA.Checkbox2 ? ' completed' : '')
                }>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cancelESA.Checkbox2}
                      onChange={(event) => {
                        setCancelESA({
                          ...cancelESA,
                          Checkbox2: event.target.checked
                        });
                      }}
                      name="gilad"
                    />
                  }
                  label={
                    <>
                      <p>
                        I understand that by cancelling this accommodation
                        request I am not waiving or limiting my rights in any
                        way and that all rights afforded me under the Fair
                        Housing Act, or any other statue or law, remain intact.
                      </p>
                    </>
                  }
                />
              </div>

              <div
                className={
                  'acknowledgment' + (cancelESA.Checkbox3 ? ' completed' : '')
                }>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cancelESA.Checkbox3}
                      onChange={(event) => {
                        setCancelESA({
                          ...cancelESA,
                          Checkbox3: event.target.checked
                        });
                      }}
                      name="gilad"
                    />
                  }
                  label={
                    <>
                      <p>
                        I understand that cancelling this accommodation request
                        is optional.
                      </p>
                    </>
                  }
                />
              </div>

              <div
                className={
                  'acknowledgment' + (cancelESA.Checkbox4 ? ' completed' : '')
                }>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cancelESA.Checkbox4}
                      onChange={(event) => {
                        setCancelESA({
                          ...cancelESA,
                          Checkbox4: event.target.checked
                        });
                      }}
                      name="gilad"
                    />
                  }
                  label={
                    <>
                      <p>
                        I understand and agree that by clicking here and typing
                        my name below, I am consenting to the use of this
                        digital form and intending to electronically sign this
                        form.
                      </p>

                      <div className="field-container">
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          label={'Type your name'}
                          value={cancelESA.Signature}
                          onChange={(event) =>
                            setCancelESA({
                              ...cancelESA,
                              Signature: event.target.value
                            })
                          }
                        />
                      </div>

                      <div className="field-container">
                        <InputMask
                          mask="99/99/9999"
                          maskChar={'_'}
                          disabled
                          value={
                            cancelESA.DateSigned &&
                            cancelESA.DateSigned.indexOf('_') === -1
                              ? moment(cancelESA.DateSigned).format(
                                  'MM/DD/YYYY'
                                )
                              : moment().format('MM/DD/YYYY')
                          }
                          onChange={(event) =>
                            setCancelESA({
                              ...cancelESA,
                              DateSigned: event.target.value
                            })
                          }>
                          {() => (
                            <TextField
                              fullWidth
                              label={'Date Signed'}
                              required
                              inputProps={{
                                inputMode: 'numeric'
                              }}
                              disabled
                            />
                          )}
                        </InputMask>
                      </div>
                    </>
                  }
                />
              </div>
            </>
          }
          submitLabel={
            cancelESA.cancelling ? 'Cancelling...' : 'Confirm ESA Cancellation'
          }
          submitDisabled={
            !cancelESA.Signature ||
            !cancelESA.Checkbox1 ||
            !cancelESA.Checkbox2 ||
            !cancelESA.Checkbox3 ||
            !cancelESA.Checkbox4 ||
            cancelESA.cancelling
          }
          submitFunc={() => {
            setCancelESA({ ...cancelESA, cancelling: true });
            cancelResidentESA(
              residentID,
              cancelESA.ResidentPetID,
              cancelESA.Signature,
              moment().format('YYYY-MM-DD')
            ).then((result) => {
              actions.loadResidentOnboarding(residentID);
              setPetUpdated(true);
              setCancelESA(null);
            });
          }}
          closeFunc={() => setCancelESA(null)}
          closeLabel={'Cancel'}
        />
      ) : null}

      {showPetPayment ? (
        <ResidentPetPayment
          pet={showPetPayment}
          residentID={residentID}
          closeFunc={() => setShowPetPayment(null)}
        />
      ) : null}

      {showHudGuidance ? (
        <GeneralPopup
          title={'Observable and Non-Observable Disabilities'}
          message={
            <>
              <p style={{ marginTop: '20px' }}>
                According to HUD guidance, under the FHA, a disability is a
                physical or mental impairment that substantially limits one or
                more major life activities. While some impairments may seem
                invisible, others can be readily observed. Observable
                impairments include blindness or low vision, deafness or being
                hard of hearing, mobility limitations, and other types of
                impairments with observable symptoms or effects, such as
                intellectual impairments (including some types of autism),
                neurological impairments (e.g., stroke, Parkinson’s disease,
                cerebral palsy, epilepsy, or brain injury), mental illness, or
                other diseases or conditions that affect major life activities
                or bodily functions.29 Observable impairments generally tend to
                be obvious and would not be reasonably attributable to
                non-medical causes by a lay person.
              </p>
              <p>
                Certain impairments, however, especially including impairments
                that may form the basis for a request for an emotional support
                animal, may not be observable. In those instances, a housing
                provider may request information regarding both the disability
                and the disability-related need for the animal. Housing
                providers are not entitled to know an individual’s diagnosis.
              </p>
            </>
          }
          closeFunc={() => setShowHudGuidance(false)}
          closeLabel={'Close'}
        />
      ) : null}

      {showPetInterview ? (
        <PetInterview
          pet={showPetInterview}
          residentID={residentID}
          profileKey={profileKey}
          key={'pet-interview-' + showPetInterview.ResidentPetID}
          closeFunc={() => {
            setShowPetInterview(null);
            actions.loadResidentOnboarding(residentID);
            closeOnboardingRow();
          }}
        />
      ) : null}
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    stateList: state.commonData.stateList,
    authUser: state.authUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      ),
      saveResidentPet: bindActionCreators(
        residentOnboardingActions.saveResidentPet,
        dispatch
      ),
      addResidentPet: bindActionCreators(
        residentOnboardingActions.addResidentPet,
        dispatch
      ),
      deleteResidentPet: bindActionCreators(
        residentOnboardingActions.deleteResidentPet,
        dispatch
      ),
      saveResidentPetStatus: bindActionCreators(
        residentOnboardingActions.saveResidentPetStatus,
        dispatch
      ),
      saveResidentOnboarding: bindActionCreators(
        residentOnboardingActions.saveResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResidentOnboardingPet);
