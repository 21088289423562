import React, { useState, useEffect } from 'react';
import { withPrefix } from 'gatsby-link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Checkbox
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputMask from 'react-input-mask';

import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Input from '@mui/material/Input';

import {
  listUnit,
  listOccupant,
  setResidentOnNotice
} from '../../api/propertyProfileApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import '../UserPropertySelect/index.css';

function MoveOutResident({ profileKey, closeFunc, afterSave }) {
  const [saving, setSaving] = useState(null);
  const [unitList, setUnitList] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [sortField, setSortField] = useState('UnitNumber');
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedResident, setSelectedResident] = useState(null);

  useEffect(() => {
    if (profileKey && !unitList) {
      listUnit(profileKey).then((_unitList) => setUnitList(_unitList));
    }
  }, [profileKey]);

  useEffect(() => {
    if (
      selectedResident &&
      !selectedResident.occupantList &&
      selectedResident.CountOccupant
    ) {
      listOccupant(profileKey, selectedResident.ResidentID).then(
        (occupantList) =>
          setSelectedResident({ ...selectedResident, occupantList })
      );
    }
  }, [selectedResident]);

  function updateSortField(field) {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  }

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className={'on-notice-form'}
      fullWidth={true}>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        <img src={withPrefix('/images/logo-icon.svg')} className="logo-icon" />
        <div>Add Move-out</div>
      </DialogTitle>
      <DialogContent width="100%">
        {unitList ? (
          <div className="form">
            <p className="intro">
              To start the on-notice process, find the resident that is moving
              out below. Once the resident is selected click the ‘Start
              On-Notice Process’ button and we will send them an email with a
              link to their MovingOUT dashboard where they can electronically
              file their Written Notice of Intent to Vacate, provide their
              forwarding address and schedule their Move-out Inspection.
            </p>

            <div className="search">
              <FormControl variant="standard" fullWidth>
                <Input
                  id="input-with-icon-adornment"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  placeholder={'Search'}
                  value={searchString}
                  onChange={(event) => setSearchString(event.target.value)}
                />
              </FormControl>
            </div>

            <ul className="unit-list">
              {unitList.length ? (
                <>
                  <li className="header">
                    <div className="unit">
                      <Button onClick={() => updateSortField('UnitNumber')}>
                        Unit{' '}
                        {sortField === 'UnitNumber' ? (
                          sortDirection === 'asc' ? (
                            <ArrowDropDownIcon />
                          ) : (
                            <ArrowDropUpIcon />
                          )
                        ) : null}
                      </Button>
                    </div>
                    <div className="resident">
                      <Button onClick={() => updateSortField('LastName')}>
                        Resident{' '}
                        {sortField === 'LastName' ? (
                          sortDirection === 'asc' ? (
                            <ArrowDropDownIcon />
                          ) : (
                            <ArrowDropUpIcon />
                          )
                        ) : null}
                      </Button>
                    </div>

                    <div className="email">
                      <Button onClick={() => updateSortField('Email')}>
                        Email{' '}
                        {sortField === 'Email' ? (
                          sortDirection === 'asc' ? (
                            <ArrowDropDownIcon />
                          ) : (
                            <ArrowDropUpIcon />
                          )
                        ) : null}
                      </Button>
                    </div>

                    <div className="status">
                      <Button
                        onClick={() => updateSortField('ResidentStatusName')}>
                        Status{' '}
                        {sortField === 'ResidentStatusName' ? (
                          sortDirection === 'asc' ? (
                            <ArrowDropDownIcon />
                          ) : (
                            <ArrowDropUpIcon />
                          )
                        ) : null}
                      </Button>
                    </div>
                    <div className="date">
                      <Button
                        onClick={() => updateSortField('DateLeaseRenewal')}>
                        Lease End{' '}
                        {sortField === 'DateLeaseRenewal' ? (
                          sortDirection === 'asc' ? (
                            <ArrowDropDownIcon />
                          ) : (
                            <ArrowDropUpIcon />
                          )
                        ) : null}
                      </Button>
                    </div>
                  </li>
                  {unitList
                    .filter((unit) =>
                      searchString
                        ? unit.UnitNumber.toLowerCase() ===
                            searchString.toLowerCase() ||
                          (unit.FirstName &&
                            unit.FirstName.toLowerCase().indexOf(
                              searchString.toLowerCase()
                            ) !== -1) ||
                          (unit.LastName &&
                            unit.LastName.toLowerCase().indexOf(
                              searchString.toLowerCase()
                            ) !== -1) ||
                          (unit.FirstName &&
                            unit.LastName &&
                            (unit.FirstName + ' ' + unit.LastName)
                              .toLowerCase()
                              .indexOf(searchString.toLowerCase()) !== -1) ||
                          (unit.TenantId &&
                            unit.TenantId.toLowerCase() ===
                              searchString.toLowerCase()) ||
                          (unit.Email &&
                            unit.Email.toLowerCase() ===
                              searchString.toLowerCase())
                        : true
                    )
                    .sort((unitA, unitB) => {
                      const valueA = unitA[sortField]
                        ? unitA[sortField]
                        : sortDirection === 'asc'
                        ? 'ZZZZZ'
                        : 'AAAAA';
                      const valueB = unitB[sortField]
                        ? unitB[sortField]
                        : sortDirection === 'asc'
                        ? 'ZZZZZ'
                        : 'AAAAA';
                      let compare =
                        valueA.localeCompare(valueB) ||
                        unitA.UnitNumber.localeCompare(unitB.UnitNumber);

                      if (sortDirection === 'desc') {
                        compare *= -1;
                      }

                      return compare;
                    })
                    .map((unit) => (
                      <li
                        className={
                          selectedResident &&
                          selectedResident.ResidentID === unit.ResidentID
                            ? 'selected'
                            : ''
                        }>
                        <Button
                          onClick={() => {
                            setSelectedResident({ ...unit });
                          }}
                          disabled={!unit.Email}>
                          <div className="unit">Unit #{unit.UnitNumber}</div>
                          <div className="resident">
                            {unit.ResidentID
                              ? unit.FirstName + ' ' + unit.LastName
                              : '--VACANT--'}
                          </div>
                          <div className="email">{unit.Email}</div>
                          <div className="status">
                            {unit.ResidentStatusName}
                          </div>
                          <div className="date">
                            {unit.ResidentID
                              ? moment(unit.DateLeaseStart).format('M/D/YYYY')
                              : ''}
                          </div>
                        </Button>

                        {selectedResident &&
                        selectedResident.ResidentID === unit.ResidentID ? (
                          <div className="resident-summary">
                            {selectedResident.CountOccupant ? (
                              selectedResident.occupantList ? (
                                selectedResident.occupantList.map(
                                  (occupant) => (
                                    <div className="occupant-row">
                                      <div className="unit"></div>
                                      <div className="resident">
                                        {occupant.FirstName +
                                          ' ' +
                                          occupant.LastName}
                                      </div>
                                      <div className="email">
                                        {occupant.Email}
                                      </div>
                                      <div className="status">
                                        {occupant.ResidentRelationshipName}
                                      </div>
                                      <div className="date">
                                        {occupant.MoveOutDate
                                          ? moment(occupant.MoveOutDate).format(
                                              'M/D/YYYY'
                                            )
                                          : ''}
                                      </div>
                                    </div>
                                  )
                                )
                              ) : (
                                <LinearProgress />
                              )
                            ) : null}
                            <div className="move-out">
                              <p>
                                Upon clicking the button below, we'll send an
                                email to the primary resident shown above (
                                {selectedResident.FirstName +
                                  ' ' +
                                  selectedResident.LastName}
                                ), requesting that they electronically sign and
                                submit their Written Notice to Vacate.
                              </p>
                              {selectedResident.occupantList &&
                              selectedResident.occupantList.length ? (
                                <p>
                                  Once the primary resident has completed their
                                  Written Notice to Vacate, all other occupants
                                  will be emailed a link to electronically sign
                                  and provide their forwarding address.
                                </p>
                              ) : null}
                              <p>
                                Enter the expected move-out date below. The
                                resident will be able to confirm or change this
                                date when they electronically submit their
                                Written Notice to Vacate.
                              </p>
                              <InputMask
                                mask="99/99/9999"
                                maskChar={'_'}
                                value={selectedResident.DateMoveOut}
                                onChange={(event) =>
                                  setSelectedResident({
                                    ...selectedResident,
                                    DateMoveOut: event.target.value
                                  })
                                }>
                                {() => (
                                  <TextField
                                    label={'Expected move-out date'}
                                    fullWidth
                                    variant="outlined"
                                    autoFocus
                                  />
                                )}
                              </InputMask>

                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                  setSaving(true);
                                  setResidentOnNotice(
                                    profileKey,
                                    selectedResident.ResidentID,
                                    selectedResident.DateMoveOut
                                  ).then((result) => afterSave());
                                }}
                                disabled={
                                  saving ||
                                  !selectedResident.DateMoveOut ||
                                  !moment(
                                    selectedResident.DateMoveOut
                                  ).isValid()
                                }>
                                {saving
                                  ? 'Saving...'
                                  : 'Start On-Notice Process'}
                              </Button>
                            </div>
                          </div>
                        ) : null}
                      </li>
                    ))}
                </>
              ) : (
                <li>No unit data is being reported for this property.</li>
              )}
            </ul>
          </div>
        ) : (
          <LinearProgress />
        )}
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'right' }}>
        <Button
          onClick={() => {
            closeFunc();
          }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveOutResident);
