import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LaunchIcon from '@mui/icons-material/Launch';

import { withPrefix } from 'gatsby-link';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import CloseIcon from '@mui/icons-material/Close';

import './index.css';

import AddressSearch from '../AddressSearch';

import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';

function ChecklistPrompt({
  provider,
  closeFunc,
  serviceAddress,
  trackOffsiteOrder,
  actions
}) {
  const [serviceConfirmed, setServiceConfirmed] = useState(null);
  const [providerAccountNumber, setProviderAccountNumber] = useState(null);
  const [activeStep, setActiveStep] = useState('Setup');
  const [allowedStepDictionary, setAllowedStepDictionary] = useState({});
  const stepList = [
    { key: 'Setup', title: 'Set Up Service' },
    { key: 'AccountNumber', title: 'Account Number' },
    { key: 'Confirmation', title: 'Complete' }
  ];

  useEffect(() => {
    if (provider) {
      if (provider.HasAccountNumber) {
        setActiveStep('Confirmation');
      } else if (
        provider.OrderStatusID === 2 &&
        serviceAddress &&
        serviceAddress.Address &&
        serviceAddress.Address.Secondary
      ) {
        setActiveStep('AccountNumber');
      }
    }
  }, [provider]);

  function checkStepDisabled(step) {
    if (allowedStepDictionary[step.key]) {
      return false;
    } else if (step.key === 'AccountNumber') {
      return !provider || provider.OrderStatusID !== 3;
    } else if (step.key === 'Confirmation') {
      return !provider || !provider.HasAccountNumber;
    } else {
      return !provider;
    }
  }

  return (
    <>
      <Dialog
        open={true}
        aria-labelledby="form-dialog-title"
        className="checkist-prompt-popup"
        fullWidth={true}>
        <DialogTitle id="form-dialog-title">
          <IconButton
            style={{ float: 'right' }}
            aria-label="close"
            onClick={() => {
              closeFunc();
            }}>
            <CloseIcon />
          </IconButton>
          <img
            src={withPrefix('/images/logo-icon.svg')}
            className="logo-icon"
          />
          {provider.DisplayName}
        </DialogTitle>
        <DialogContent width="100%">
          {provider.LogoFile ? (
            <img src={provider.LogoFile} className="provider-logo" />
          ) : null}

          {serviceAddress.Address.Secondary ? (
            <Tabs
              value={activeStep}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              onChange={(event, newValue) => setActiveStep(newValue)}>
              {stepList.map((step) => (
                <Tab
                  label={step.title}
                  value={step.key}
                  disabled={checkStepDisabled(step)}
                />
              ))}
            </Tabs>
          ) : null}

          <div className="content-area">
            {activeStep === 'Setup' ? (
              <p>
                <Button
                  onClick={() => trackOffsiteOrder(provider)}
                  href={provider.ServiceStartUrl}
                  className="order-button"
                  variant="outlined"
                  color="primary"
                  target="mi_offsite"
                  endIcon={<LaunchIcon />}>
                  Visit Provider Website
                </Button>
                How did it go with {provider.DisplayName}?<br />
                <br />
                <strong>
                  Were you able to set up {provider.ProviderServiceList}{' '}
                  service?
                </strong>
                <br />
                <br />
                <Button
                  onClick={() => trackOffsiteOrder(provider)}
                  href={provider.ServiceStartUrl}
                  className="order-button mobile"
                  variant="outlined"
                  color="primary"
                  target="mi_offsite"
                  endIcon={<LaunchIcon />}>
                  Visit Provider Website
                </Button>
              </p>
            ) : activeStep === 'AccountNumber' ? (
              <>
                <p>
                  Please provide your account number as soon as it is available,
                  so that we can notify your property manager that{' '}
                  {provider.ProviderServiceList} service has been set up in your
                  name.
                </p>

                <TextField
                  value={providerAccountNumber}
                  autoFocus={true}
                  fullWidth
                  onChange={(e) => setProviderAccountNumber(e.target.value)}
                  label={'What is your account number?'}
                />
              </>
            ) : activeStep === 'Confirmation' ? (
              <p>
                Thanks! We have notified your property manager. You should be
                all set with {provider.DisplayName}.
              </p>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions>
          {activeStep === 'AccountNumber' ? (
            <>
              <Button
                color="secondary"
                disabled={!providerAccountNumber}
                onClick={() => {
                  actions.saveOrder(
                    serviceAddress.Address.Address,
                    serviceAddress.Address.Secondary,
                    serviceAddress.Address.City,
                    serviceAddress.Address.State,
                    serviceAddress.Address.Zip,
                    serviceAddress.DeregulatedElectric
                      ? serviceAddress.DeregulatedElectric.DeregulatedAddressID
                      : null,
                    provider.ProviderID,
                    0,
                    3,
                    provider.ServiceStartUrl,
                    providerAccountNumber
                  );

                  setActiveStep('Confirmation');

                  setAllowedStepDictionary({
                    ...allowedStepDictionary,
                    ['Confirmation']: true
                  });
                }}
                variant="contained">
                Confirm Account Number
              </Button>
              <Button onClick={closeFunc} variant="contained" color="primary">
                Cancel
              </Button>
            </>
          ) : activeStep === 'Confirmation' ? (
            <>
              <Button onClick={closeFunc} variant="contained" color="primary">
                Close
              </Button>
            </>
          ) : (
            <>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  actions.saveOrder(
                    serviceAddress.Address.Address,
                    serviceAddress.Address.Secondary,
                    serviceAddress.Address.City,
                    serviceAddress.Address.State,
                    serviceAddress.Address.Zip,
                    provider.ProviderID,
                    0,
                    3,
                    provider.ServiceStartUrl,
                    null
                  );

                  if (serviceAddress.Address.Secondary) {
                    setAllowedStepDictionary({
                      ...allowedStepDictionary,
                      ['Setup']: true,
                      ['AccountNumber']: true
                    });
                    setActiveStep('AccountNumber');
                  } else {
                    closeFunc();
                  }
                }}>
                Yes, this task is complete
              </Button>
              <Button
                onClick={() => {
                  actions
                    .saveOrder(
                      serviceAddress.Address.Address,
                      serviceAddress.Address.Secondary,
                      serviceAddress.Address.City,
                      serviceAddress.Address.State,
                      serviceAddress.Address.Zip,
                      provider.ProviderID,
                      0,
                      11,
                      provider.ServiceStartUrl,
                      providerAccountNumber
                    )
                    .then((result) => closeFunc());
                }}
                variant="contained"
                color="primary">
                Not yet
              </Button>
            </>
          )}
          {/* <Button onClick={closeFunc} color="primary">
            Skip this task
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}

function mapStateToProps(state) {
  return {
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      saveOrder: bindActionCreators(serviceAddressActions.saveOrder, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistPrompt);
