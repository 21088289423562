import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Map,
  InfoWindow,
  Marker,
  GoogleApiWrapper,
  Polygon
} from 'google-maps-react';

import * as webSocketActions from '../../redux/actions/webSocketActions';

function ProviderListMap({
  address,
  google,
  providerList,
  providerFieldKey,
  providerFieldLatitude,
  providerFieldLongitude,
  providerToHighlight,
  mapOffsetX,
  mapOffsetY,
  zoomLevel,
  title
}) {
  const map = useRef();
  const infoWindow = useRef();
  const addressMarker = useRef();
  const providerMarkerDictionary = useRef();
  console.log('incoming', address);
  const [latLon, setLatLon] = useState({
    latitude: address ? address.latitude : null,
    longitude: address ? address.longitude : null
  });
  const geoCodeStarted = useRef();

  const markerIcon = new google.maps.MarkerImage(
    'https://mi-provider-logo.s3.us-west-1.amazonaws.com/map-marker.svg',
    null,
    null,
    null,
    new google.maps.Size(30, 30)
  );

  const markerIconHover = new google.maps.MarkerImage(
    'https://mi-provider-logo.s3.us-west-1.amazonaws.com/map-marker-hover.svg',
    null,
    null,
    null,
    new google.maps.Size(40, 40)
  );

  useEffect(() => {
    if (providerToHighlight) {
      zoomToProvider(providerToHighlight[providerFieldKey]);
    }
  }, [providerToHighlight]);

  useEffect(() => {
    if (providerList && map.current) {
      if (addressMarker.current) {
        addressMarker.current.setMap(null);
      }
      addressMarker.current = null;

      if (providerMarkerDictionary.current) {
        Object.keys(providerMarkerDictionary.current).map((placeID) =>
          providerMarkerDictionary.current[placeID].setMap(null)
        );
      }

      providerMarkerDictionary.current = null;

      addAddressMarker();
      addProviderMarkers();
    }
  }, [providerList]);

  useEffect(() => {
    if (latLon.latitude && latLon.longitude) {
      addAddressMarker();
      addProviderMarkers();
    }
  }, [latLon]);

  function getFullAddress() {
    return (
      address.address +
      (address.secondary ? ' ' + address.secondary : '') +
      ', ' +
      address.city +
      ', ' +
      address.state +
      ' ' +
      address.zip
    );
  }

  function addAddressMarker() {
    if (!addressMarker.current && map.current) {
      if (latLon.latitude && latLon.longitude) {
        var position = {
          lat: latLon.latitude,
          lng: latLon.longitude
        };

        const mapMarker = new google.maps.Marker({
          position: position,
          // icon: {
          //   ...marker,
          //   fillColor: loadServiceAddressOption[key].ColorCode
          // },
          title: getFullAddress(),
          map: map.current,
          icon: new google.maps.MarkerImage(
            'https://mi-provider-logo.s3.us-west-1.amazonaws.com/home-icon-silhouette.svg?v2',
            null,
            null,
            null,
            new google.maps.Size(30, 30)
          )
        });

        addressMarker.current = mapMarker;

        mapMarker.setMap(map.current);

        const offsetX = mapOffsetX ? mapOffsetX : 0; //-0.33; // move center one quarter map width left
        const offsetY = mapOffsetY ? mapOffsetY : 0; //-0.25; // move center one quarter map height down

        const span = map.current.getBounds().toSpan(); // a latLng - # of deg map spans

        var newCenter = {
          lat: position.lat + span.lat() * offsetY,
          lng: position.lng + span.lng() * offsetX
        };

        map.current.panTo(newCenter);
      } else if (!geoCodeStarted.current && address && address.address) {
        geoCodeStarted.current = true;

        const geocoder = new google.maps.Geocoder();
        const searchAddress =
          address.address +
          (address.secondary ? ' ' + address.secondary : '') +
          (address.city ? ' ' + address.city : '') +
          (address.state ? ' ' + address.state : '') +
          (address.zip ? ' ' + address.zip : '');
        if (searchAddress && geocoder) {
          geocoder.geocode(
            {
              address: searchAddress
            },
            function (results, status) {
              console.log('geocode result', searchAddress, results, status);
              if (status == google.maps.GeocoderStatus.OK) {
                if (status != google.maps.GeocoderStatus.ZERO_RESULTS) {
                  if (
                    results &&
                    results[0] &&
                    results[0].geometry &&
                    results[0].geometry.location
                  )
                    setLatLon({
                      latitude: results[0].geometry.location.lat(),
                      longitude: results[0].geometry.location.lng()
                    });
                }
              }
            }
          );
        }
      }
    }
  }

  function addProviderMarkers() {
    if (providerList && !providerMarkerDictionary.current && map.current) {
      providerMarkerDictionary.current = {};

      providerList.map((provider) => {
        if (
          provider[providerFieldLatitude] &&
          provider[providerFieldLongitude]
        ) {
          var position = {
            lat: provider[providerFieldLatitude],
            lng: provider[providerFieldLongitude]
          };

          const mapMarker = new google.maps.Marker({
            position: position,
            // icon: {
            //   ...marker,
            //   fillColor: loadServiceAddressOption[key].ColorCode
            // },
            title: provider.ProviderName,
            map: map.current,

            icon: markerIcon
          });

          providerMarkerDictionary.current[provider.GooglePlaceId] = mapMarker;

          mapMarker.setMap(map.current);
        }
      });
    }
  }

  function zoomToProvider(placeID) {
    if (providerMarkerDictionary.current) {
      Object.keys(providerMarkerDictionary.current).map((_placeID) => {
        providerMarkerDictionary.current[_placeID].setOptions({
          zIndex: null
        });

        console.log('_placeId', _placeID);

        providerMarkerDictionary.current[_placeID].setIcon(markerIcon);
      });

      if (providerMarkerDictionary.current[placeID]) {
        // map.current.panTo(
        //   providerMarkerDictionary.current[placeID].position
        // );

        providerMarkerDictionary.current[placeID].setOptions({
          zIndex: 999
        });

        providerMarkerDictionary.current[placeID].setIcon(markerIconHover);
      }
    }
  }

  function initMap(mapProps, _map) {
    map.current = _map;

    google.maps.event.addListener(_map, 'bounds_changed', function () {
      if (address) {
        addAddressMarker();
        addProviderMarkers();
      }
    });

    google.maps.Polygon.prototype.getBounds = function () {
      var bounds = new google.maps.LatLngBounds();
      var paths = this.getPaths();
      var path;
      for (var i = 0; i < paths.getLength(); i++) {
        path = paths.getAt(i);
        for (var ii = 0; ii < path.getLength(); ii++) {
          bounds.extend(path.getAt(ii));
        }
      }
      return bounds;
    };

    infoWindow.current = new google.maps.InfoWindow({
      content: '<div class="loading">Loading...</div>'
    });
  }

  return (
    <>
      {title ? <h2 className="title">{title}</h2> : null}
      <Map
        google={google}
        view
        mapType="roadmap"
        className={'map'}
        zoom={zoomLevel ? zoomLevel : 12}
        onReady={initMap}
        panControl={true}
        disableDefaultUI={true}
        gestureHandling={''}
        yesIWantToUseGoogleMapApiInternals
        initialCenter={{
          lat: 39.8282, //USA center
          lng: -98.5795
        }}></Map>
    </>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDvLqJv0ghq2bOYU8d9oK5DQUuV6vZ0pIM',
  libraries: ['drawing']
})(connect(mapStateToProps, mapDispatchToProps)(ProviderListMap));
