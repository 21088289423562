import React, { useState, useRef, useEffect, version } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createPaymentIntent } from '../../../api/residentOnboardingApi';

import * as webSocketActions from '../../../redux/actions/webSocketActions';
import * as residentOnboardingActions from '../../../redux/actions/residentOnboardingActions';

import GeneralPopup from '../../GeneralPopup';
import OnlinePaymentForm from '../../OnlinePaymentForm';

import '../index.css';

function ResidentPetPayment({
  actions,
  serviceAddress,
  siteConfig,
  residentOnboarding,
  closeFunc,
  residentID,
  pet
}) {
  return (
    <GeneralPopup
      title={'Pet Registration Fee'}
      className={'resident-pet-payment-popup'}
      message={
        <>
          <p>
            <strong>
              A one-item payment in the amount of $
              {residentOnboarding.Header.PetRegistrationFeeAmount.toFixed(2)} is
              required to process your Household Pet registration.
            </strong>
          </p>

          <OnlinePaymentForm
            paymentIntentFunc={() => createPaymentIntent(residentID, 1)}
          />
        </>
      }
      closeFunc={closeFunc}
      hideActions
    />
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    stateList: state.commonData.stateList,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResidentPetPayment);
