import React, { useState, useRef, useEffect, version } from 'react';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import { withPrefix } from 'gatsby-link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Alert,
  LinearProgress,
  TextField,
  Tooltip,
  Checkbox,
  IconButton,
  Switch,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import Dropzone from 'react-dropzone-uploader';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import InputMask from 'react-input-mask';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { scrollToTop } from '../../api/dataApi';

import {
  getResidentSubmittal,
  saveResidentSubmittal,
  captureIDData
} from '../../api/residentOnboardingApi';

import { getConfig } from '../../api/residentOnboardingApi';

import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import ResidentOnboardingStatusBadge from '../ResidentOnboarding/ResidentOnboardingStatusBadge';
import ResidentOnboardingStatusUpdate from '../ResidentOnboarding/ResidentOnboardingStatusUpdate';

import './index.css';
import { getFormFields } from './formFieldData';
import { getReportPath } from './ResidentFormReport';

const payFrequencyList = [
  'Hourly',
  'Weekly',
  'Semi-monthly',
  'Monthly',
  'Bi-monthly',
  'Quarterly',
  'Annually'
];

const requiredDateSigned = moment().format('MM/DD/YYYY');

function ResidentForm({
  actions,
  serviceAddress,
  siteConfig,
  residentOnboarding,
  refreshResidentOnboarding,
  residentID,
  residentForm,
  closeOnboardingRow,
  stateList,
  profileKey,
  webSocket,
  closeFunc,
  role,
  propertyRole
}) {
  const [formFields, setFormFields] = useState(null);
  const [residentSubmittal, setResidentSubmittal] = useState(null);
  const [saving, setSaving] = useState(null);
  const [complete, setComplete] = useState(null);
  const [bouncedEmail, setBouncedEmail] = useState(null);
  const [fieldWithFocus, setFieldWithFocus] = useState(null);
  const [idParseError, setIDParseError] = useState(null);
  const [expand, setExpand] = useState({});

  useEffect(() => {
    if (
      !residentOnboarding ||
      (residentID && residentOnboarding.Header.ResidentID !== residentID)
    ) {
      actions.loadResidentOnboarding(residentID);
    }
  }, []);

  useEffect(() => {
    loadFormData();
  }, [residentForm, residentOnboarding]);

  useEffect(() => {
    if (formFields) {
      let _missingFieldList = [];
      let _complete = true;
      Object.keys(formFields).map((signer) =>
        Object.keys(formFields[signer].Fields).map((fieldName) => {
          const field = formFields[signer].Fields[fieldName];

          let isRequired = field.Required === true;

          if (
            !isRequired &&
            formFields[signer].Fields[fieldName].RequiredField
          ) {
            if (formFields[signer].Fields[fieldName].RequiredFieldValue) {
              //A specific value is required
              if (
                formFields[signer].Fields[
                  formFields[signer].Fields[fieldName].RequiredField
                ].Value ===
                formFields[signer].Fields[fieldName].RequiredFieldValue
              ) {
                isRequired = true;
              }
            } else if (
              formFields[signer].Fields[
                formFields[signer].Fields[fieldName].RequiredField
              ].Value
            ) {
              //Any value is required
              isRequired = true;
            }
          }

          let hasValue =
            field.Value &&
            (field.TextType !== 'date' ||
              String(field.Value).indexOf('_') === -1);
          if (field.Label === 'Date Signed') {
            if (field.Value !== requiredDateSigned) {
              hasValue = false;
            }
          }

          if (isRequired && !hasValue) {
            _complete = false;
            _missingFieldList.push(field.Label);
          }
        })
      );

      setComplete(_complete);

      console.log('_missingFieldList', _missingFieldList);
    }
  }, [formFields]);

  useEffect(() => {
    if (
      residentSubmittal &&
      residentSubmittal.FieldList &&
      residentSubmittal.FieldList.length &&
      formFields
    ) {
      let _formFields = { ...formFields };

      Object.keys(_formFields).map((signer) =>
        Object.keys(_formFields[signer].Fields).map((fieldName) => {
          const field = residentSubmittal.FieldList.filter(
            (_field) => _field.FieldName === fieldName
          )[0];

          if (field && field.Value) {
            _formFields[signer].Fields[fieldName].Value = field.Value;
          }

          if (field && field.ReadOnly) {
            _formFields[signer].Fields[fieldName].ReadOnly = true;
          }
        })
      );

      setFormFields({ ..._formFields });
    }
  }, [residentSubmittal]);

  function loadFormData() {
    if (residentForm && residentOnboarding && residentOnboarding.Header) {
      const _formFields = getFormFields(
        residentForm.ResidentFormID,
        residentOnboarding,
        propertyRole
          ? propertyRole
          : webSocket && webSocket.invitation && webSocket.invitation.Role
          ? webSocket.invitation.Role
          : null,
        webSocket && webSocket.invitation && webSocket.invitation.OccupantID
          ? webSocket.invitation.OccupantID
          : null,
        propertyRole ? true : false
      );

      console.log('residentID', residentID);

      getResidentSubmittal(
        residentID,
        residentForm.ResidentFormID,
        residentForm.OccupantID
      ).then((_residentSubmittal) => setResidentSubmittal(_residentSubmittal));

      setFormFields(_formFields);
    }
  }

  function updateFormField(signer, fieldName, value) {
    setFormFields({
      ...formFields,
      [signer]: {
        ...formFields[signer],
        Fields: {
          ...formFields[signer].Fields,
          [fieldName]: {
            ...formFields[signer].Fields[fieldName],
            Value: value
          }
        }
      }
    });
  }

  function checkFieldDisabled(field) {
    if (field.ReadOnly) {
      return true;
    }
  }

  function getSavedFieldValue(fieldName) {
    let _value = '';

    if (residentSubmittal && residentSubmittal.FieldList) {
      const field = residentSubmittal.FieldList.filter(
        (_field) => _field.FieldName === fieldName
      )[0];
      if (field) {
        _value = field.Value;
      }
    }

    return _value;
  }

  function getFieldValue(fieldName) {
    let _value = '';

    if (formFields) {
      Object.keys(formFields).map((signer) =>
        Object.keys(formFields[signer].Fields).map((_fieldName) => {
          if (_fieldName === fieldName) {
            _value = formFields[signer].Fields[_fieldName].Value;
          }
        })
      );
    }

    return _value;
  }

  function interpolateValues(text) {
    return text
      .split('{applicantFullName}')
      .join(getSavedFieldValue('Applicant_FullName'))
      .split('{propertyName}')
      .join(residentSubmittal.ResidentSubmittal.PropertyName)
      .split('{employerName}')
      .join(getSavedFieldValue('Applicant_Employer_Name'))
      .split('{landlordName}')
      .join(getSavedFieldValue('Applicant_Landlord_Name'))
      .split('\n')
      .join('<br />');
  }

  function checkIfRequired(field) {
    if (field.Required) {
      return true;
    } else if (field.RequiredField) {
      if (field.RequiredFieldValue) {
        return field.RequiredFieldValue === getFieldValue(field.RequiredField); //If specific value is required
      }

      return getFieldValue(field.RequiredField); //If any value is required
    }

    return false;
  }

  return residentForm && residentOnboarding ? (
    <div className="resident-form">
      <div className="form-header">
        {residentSubmittal && !role ? (
          <ResidentOnboardingStatusBadge
            status={residentForm}
            isProperty={residentID ? true : false}
          />
        ) : null}
      </div>

      {formFields && residentSubmittal ? (
        residentSubmittal &&
        residentSubmittal.ResidentSubmittal &&
        residentSubmittal.ResidentSubmittal.ResidentOnboardingStatusID !== 1 ? (
          <div className={'resident-form-container'}>
            <Alert className="success">
              Thank you for your time! Your response has been recorded
              successfully.
            </Alert>

            <Button
              className="download-button"
              variant="outlined"
              color="primary"
              href={getReportPath(
                null,
                null,
                null,
                residentSubmittal.ResidentSubmittal.ResidentFormID,
                residentSubmittal.ResidentSubmittal.OtherSigner,
                true
              )}>
              Download Submitted Document
            </Button>
          </div>
        ) : residentForm.ResidentFormID === 3 ? (
          <div className="resident-form-container">
            <div className="section">
              <h4>Upload Photo ID</h4>
              <p>
                {residentSubmittal.ResidentSubmittal.PropertyName} requires you
                to upload a copy of your government-issued photo ID before your
                application can be processed. The information on your photo ID
                must match the information in the application you submitted.
              </p>

              {idParseError ? (
                <Alert severity="error" style={{ marginBottom: '20px' }}>
                  <p>{idParseError.message}</p>

                  {idParseError.missingFieldList &&
                  idParseError.missingFieldList.length ? (
                    <>
                      <p>The following fields could not be captured:</p>
                      <ul>
                        {idParseError.missingFieldList.map((field) => (
                          <li>{field.Label}</li>
                        ))}
                      </ul>
                    </>
                  ) : null}

                  <p>
                    <strong>
                      If you are sure the file you uploaded is correct,{' '}
                      <a
                        className="link"
                        onClick={() => {
                          let _residentSubmittal = {
                            ...residentSubmittal,
                            FieldList: []
                          };

                          if (
                            idParseError.missingFieldList &&
                            idParseError.missingFieldList.length
                          ) {
                            _residentSubmittal.FieldList =
                              idParseError.missingFieldList.map((field) => {
                                return {
                                  ...field,
                                  SectionTitle: 'Extracted ID Card Data',
                                  Required: false,
                                  Value: 'Unable to Capture',
                                  Signer: 'Applicant'
                                };
                              });
                          } else {
                            _residentSubmittal.FieldList.push({
                              FieldName: 'NoData',
                              SectionTitle: 'Extracted ID Card Data',
                              Label: 'Submit without data capture?',
                              Value: 'Yes',
                              Signer: 'Applicant'
                            });
                          }

                          actions.pageLoading(true, 'Saving...');

                          saveResidentSubmittal(
                            residentID,
                            _residentSubmittal,
                            propertyRole
                          ).then((result) => {
                            actions.pageLoading(false);
                            setResidentSubmittal(result);
                            setSaving(false);
                            actions.loadResidentOnboarding(residentID);

                            if (closeFunc) {
                              closeFunc();
                            } else {
                              scrollToTop();
                            }
                          });
                        }}>
                        click here
                      </a>{' '}
                      to continue with your submittal.
                    </strong>
                  </p>
                </Alert>
              ) : null}

              <Dropzone
                getUploadParams={() => {
                  let _headers = {
                    'Cache-Control': ''
                  };

                  return {
                    url:
                      process.env.GATSBY_API_URL +
                      'ResidentFile/UploadFile?residentFileTypeID=7&residentFormID=' +
                      residentForm.ResidentFormID +
                      (residentID ? '&residentID=' + residentID : '') +
                      (residentForm.OccupantID
                        ? '&occupantID=' + residentForm.OccupantID
                        : ''),
                    method: 'POST',
                    withCredentials: false,
                    headers: {
                      ..._headers,
                      ...getConfig(residentID).headers
                    }
                  };
                }}
                onChangeStatus={({ meta, remove, xhr }, status) => {
                  console.log('onChangeStatus', status);
                  if (status == 'done') {
                    try {
                      const result = JSON.parse(xhr.response);

                      if (
                        result &&
                        result.UpdatedFileList &&
                        result.UpdatedFileList.length
                      ) {
                        actions.pageLoading(true, 'Capturing data...');

                        captureIDData(
                          residentID,
                          result.UpdatedFileList[
                            result.UpdatedFileList.length - 1
                          ].ResidentFileID,
                          residentForm.ResidentFormID
                        ).then((result) => {
                          actions.pageLoading(false);

                          if (result && result.AllPertinentDataFound) {
                            actions.loadResidentOnboarding(residentID);

                            if (closeFunc) {
                              closeFunc();
                            }
                          } else {
                            let missingFieldList = [];
                            if (!result.FirstName) {
                              missingFieldList.push({
                                FieldName: 'FirstName',
                                Label: 'First Name'
                              });
                            }

                            if (!result.LastName) {
                              missingFieldList.push({
                                FieldName: 'LastName',
                                Label: 'Last Name'
                              });
                            }

                            if (!result.DateOfBirth) {
                              missingFieldList.push({
                                FieldName: 'DateOfBirth',
                                Label: 'Date of Birth'
                              });
                            }

                            if (!result.IDNumber) {
                              missingFieldList.push({
                                FieldName: 'IDNumber',
                                Label: 'ID Number'
                              });
                            }

                            if (!result.IssuingState) {
                              missingFieldList.push({
                                FieldName: 'IssuingState',
                                Label: 'ID Card Issuer'
                              });
                            }

                            if (!result.Address) {
                              missingFieldList.push({
                                FieldName: 'Address',
                                Label: 'Street Address'
                              });
                            }

                            if (!result.City) {
                              missingFieldList.push({
                                FieldName: 'City',
                                Label: 'City'
                              });
                            }

                            if (!result.State) {
                              missingFieldList.push({
                                FieldName: 'State',
                                Label: 'State'
                              });
                            }

                            if (!result.Zip) {
                              missingFieldList.push({
                                FieldName: 'Zip',
                                Label: 'Zip Code'
                              });
                            }

                            setIDParseError({
                              message:
                                "We're having trouble capturing data from the file you uploaded. Please double check that you uploaded a clear photo or scan of your ID and that all data fields are clearly visible.",
                              reason: 'Unable to capture all data fields',
                              missingFieldList
                            });
                            remove();
                          }
                        });
                      } else {
                        setIDParseError({
                          message:
                            'There was an error uploading your file. Please try again.',
                          reason: 'File upload failed'
                        });
                        remove();
                      }
                    } catch (e) {}
                  }
                }}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                accept=".jpeg,.jpg,.png,.gif,.bmp,.pdf"
                inputContent={
                  <>
                    <img
                      src={withPrefix('/images/misc/id-card-upload.png')}
                      className="icon-image"
                    />
                    Click to upload photo ID
                  </>
                }
              />
            </div>
          </div>
        ) : (
          <div className={'resident-form-container'}>
            {bouncedEmail ? (
              <div
                id="bounceError"
                style={{ clear: 'both', paddingTop: '20px' }}>
                <Alert severity="error">
                  The email address {bouncedEmail} is not valid. Please double
                  check that you entered the correct email address.
                </Alert>
              </div>
            ) : null}
            {Object.keys(formFields)
              .filter((signer) =>
                formFields.Gate
                  ? formFields.Gate.Fields[formFields.Gate.RequiredField] &&
                    formFields.Gate.Fields[formFields.Gate.RequiredField].Value
                    ? signer !== 'Gate'
                    : signer === 'Gate'
                  : true
              )
              .map((signer) => (
                <div className={'section'}>
                  <h4>{formFields[signer].Title}</h4>

                  {signer === role &&
                  residentSubmittal.ResidentSubmittal &&
                  residentSubmittal.ResidentSubmittal.SignerSalutation ? (
                    <div
                      className="salutation"
                      dangerouslySetInnerHTML={{
                        __html: interpolateValues(
                          residentSubmittal.ResidentSubmittal.SignerSalutation
                        )
                      }}></div>
                  ) : null}

                  {Object.keys(formFields[signer].Fields)
                    .filter((fieldName) =>
                      formFields[signer].Fields[fieldName].RequiredField
                        ? formFields[signer].Fields[fieldName]
                            .RequiredFieldValue
                          ? formFields[signer].Fields[
                              formFields[signer].Fields[fieldName].RequiredField
                            ].Value ===
                            formFields[signer].Fields[fieldName]
                              .RequiredFieldValue
                          : formFields[signer].Fields[
                              formFields[signer].Fields[fieldName].RequiredField
                            ].Value
                        : formFields[signer].Fields[fieldName].HideIfNoValue
                        ? formFields[signer].Fields[fieldName].Value
                        : true
                    )
                    .map((fieldName) =>
                      [
                        {
                          ...formFields[signer].Fields[fieldName],
                          Signer: signer
                        }
                      ].map((field) => (
                        <>
                          {field.SpaceBefore ? (
                            <div className="spacer"></div>
                          ) : null}

                          {field.Title ? <h5>{field.Title}</h5> : null}

                          {field.DirectionsComponent ? (
                            <div className="directions">
                              {[field].map((_field) => {
                                const DirectionsComponent =
                                  _field.DirectionsComponent;

                                return (
                                  <DirectionsComponent
                                    formFields={formFields}
                                    residentOnboarding={residentOnboarding}
                                  />
                                );
                              })}
                            </div>
                          ) : null}

                          <div
                            key={fieldName}
                            className={
                              'field-container' +
                              (checkIfRequired(field) && !field.Value
                                ? ' missing-data'
                                : '') +
                              (field.Width ? '' : ' full-width') +
                              (field.ReadOnly ? ' read-only' : '') +
                              (field.IsFollowUp ? ' follow-up' : '') +
                              (field.FieldType === 'Radio'
                                ? ' radio-horizontal' +
                                  (field.OptionList
                                    ? ' with-options' +
                                      (field.OptionList.length > 2 || field.Wide
                                        ? ' many'
                                        : '')
                                    : '')
                                : field.Wide
                                ? ' many'
                                : '')
                            }
                            style={field.Width ? { width: field.Width } : {}}
                            fullWidth
                            required={checkIfRequired(field)}
                            disabled={field.ReadOnly}>
                            {field.FieldType === 'Checkbox' ? (
                              <div className="checkbox-container">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={field.Value === 'Yes'}
                                      onChange={(event) =>
                                        updateFormField(
                                          signer,
                                          fieldName,
                                          event.target.checked ? 'Yes' : ''
                                        )
                                      }
                                    />
                                  }
                                  label={field.Label}
                                />

                                {field.TermsComponent ? (
                                  <div
                                    className={
                                      'terms' +
                                      (expand[fieldName] ? ' expanded' : '')
                                    }>
                                    {[field].map((_field) => {
                                      const TermsComponent =
                                        _field.TermsComponent;

                                      return <TermsComponent />;
                                    })}

                                    {!expand[fieldName] ? (
                                      <Button
                                        className="expand-button"
                                        onClick={() =>
                                          setExpand({
                                            ...expand,
                                            [fieldName]: true
                                          })
                                        }>
                                        Show all
                                        <ExpandMoreIcon />
                                      </Button>
                                    ) : null}
                                  </div>
                                ) : null}
                              </div>
                            ) : field.FieldType === 'Radio' ? (
                              <div className="radio-container">
                                <FormControl
                                  className={
                                    field.OptionList &&
                                    field.OptionList.filter(
                                      (option) => option.length > 30
                                    ).length
                                      ? 'full-width'
                                      : ''
                                  }>
                                  <FormLabel id={fieldName + '_Label'}>
                                    {field.Label}
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby={fieldName + '_Label'}
                                    name={fieldName}
                                    value={field.Value}
                                    onChange={(event) =>
                                      updateFormField(
                                        signer,
                                        fieldName,
                                        event.target.value
                                      )
                                    }
                                    disabled={field.ReadOnly}>
                                    {field.OptionList ? (
                                      field.OptionList.map((option) => (
                                        <FormControlLabel
                                          value={option}
                                          control={<Radio />}
                                          label={option}
                                          disabled={field.ReadOnly}
                                        />
                                      ))
                                    ) : (
                                      <>
                                        <FormControlLabel
                                          value="Yes"
                                          control={<Radio />}
                                          label="Yes"
                                          disabled={field.ReadOnly}
                                        />
                                        <FormControlLabel
                                          value="No"
                                          control={<Radio />}
                                          label="No"
                                          disabled={field.ReadOnly}
                                        />
                                      </>
                                    )}
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            ) : field.FieldType === 'PayFrequency' ? (
                              <FormControl
                                variant="outlined"
                                fullWidth
                                required={checkIfRequired(field)}
                                disabled={field.ReadOnly}>
                                <InputLabel
                                  labelId={fieldName + '_Label'}
                                  shrink>
                                  {field.Label}
                                </InputLabel>
                                <Select
                                  labelId={fieldName + '_Label'}
                                  label={field.Label}
                                  id={fieldName}
                                  name={fieldName}
                                  fullWidth
                                  key={fieldName}
                                  notched
                                  value={field.Value}
                                  disabled={checkFieldDisabled(field)}
                                  onChange={(event) =>
                                    updateFormField(
                                      signer,
                                      fieldName,
                                      event.target.value
                                    )
                                  }>
                                  {payFrequencyList.map((frequency) => (
                                    <MenuItem value={frequency}>
                                      {frequency}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            ) : field.FieldType === 'state' ? (
                              <FormControl
                                required={checkIfRequired(field)}
                                variant="outlined"
                                fullWidth
                                disabled={field.ReadOnly}>
                                <InputLabel labelId={fieldName + '_Label'}>
                                  {field.Label}
                                </InputLabel>
                                <Select
                                  labelId={fieldName + '_Label'}
                                  label={field.Label}
                                  notched
                                  id={fieldName}
                                  name={fieldName}
                                  fullWidth
                                  key={fieldName}
                                  value={field.Value}
                                  disabled={checkFieldDisabled(field)}
                                  onChange={(event) =>
                                    updateFormField(
                                      signer,
                                      fieldName,
                                      event.target.value
                                    )
                                  }>
                                  {stateList.map((state) => (
                                    <MenuItem value={state}>{state}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            ) : field.TextType === 'date' ? (
                              <InputMask
                                mask="99/99/9999"
                                maskChar={'_'}
                                disabled={checkFieldDisabled(field)}
                                value={field.Value}
                                onChange={(event) =>
                                  updateFormField(
                                    signer,
                                    fieldName,
                                    event.target.value
                                  )
                                }>
                                {() => (
                                  <TextField
                                    id={fieldName}
                                    name={fieldName}
                                    fullWidth
                                    key={fieldName}
                                    label={field.Label}
                                    placeholder={field.Placeholder}
                                    required={checkIfRequired(field)}
                                    inputProps={{ inputMode: 'numeric' }}
                                    disabled={checkFieldDisabled(field)}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                )}
                              </InputMask>
                            ) : field.TextType === 'phone' ? (
                              <InputMask
                                mask="999-999-9999"
                                maskChar={'_'}
                                disabled={checkFieldDisabled(field)}
                                value={field.Value}
                                onChange={(event) =>
                                  updateFormField(
                                    signer,
                                    fieldName,
                                    event.target.value
                                  )
                                }>
                                {() => (
                                  <TextField
                                    id={fieldName}
                                    name={fieldName}
                                    fullWidth
                                    key={fieldName}
                                    label={field.Label}
                                    placeholder={field.Placeholder}
                                    required={checkIfRequired(field)}
                                    inputProps={{ inputMode: 'numeric' }}
                                    type="tel"
                                    disabled={checkFieldDisabled(field)}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                )}
                              </InputMask>
                            ) : field.FieldType === 'Button' ? (
                              <Button
                                variant="contained"
                                className={
                                  field.ClassName ? field.ClassName : ''
                                }
                                onClick={() =>
                                  updateFormField(
                                    signer,
                                    fieldName,
                                    field.ButtonValue
                                  )
                                }>
                                {field.Label}
                              </Button>
                            ) : field.FieldType === 'ButtonList' ? (
                              <>
                                {field.AllowMultiple ? (
                                  <div className="select-all">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            field.Value &&
                                            field.Value.split(',').length ===
                                              field.ButtonOptionList.length
                                          }
                                          onChange={(event) =>
                                            updateFormField(
                                              signer,
                                              fieldName,
                                              event.target.checked
                                                ? field.ButtonOptionList.map(
                                                    (option) => option.Value
                                                  ).join(',')
                                                : ''
                                            )
                                          }
                                        />
                                      }
                                      label={'Select all of the dates below'}
                                    />
                                  </div>
                                ) : null}
                                <div className="button-list-container">
                                  {field.ButtonOptionList.map((option) => (
                                    <Button
                                      variant={
                                        field.Value &&
                                        field.Value.split(',').filter(
                                          (_val) => _val === option.Value
                                        ).length
                                          ? 'contained'
                                          : 'outlined'
                                      }
                                      onClick={() => {
                                        let _valueList = field.Value
                                          ? field.Value.split(',')
                                          : [];

                                        let _exists = false;

                                        _valueList = _valueList.filter(
                                          (_val) => {
                                            if (_val === option.Value) {
                                              _exists = true;
                                              return false;
                                            }

                                            return field.AllowMultiple; //Leave existing selection if allowed
                                          }
                                        );

                                        if (!_exists) {
                                          _valueList.push(option.Value);
                                        }

                                        updateFormField(
                                          signer,
                                          fieldName,
                                          _valueList.join(',')
                                        );
                                      }}>
                                      {option.Label
                                        ? option.Label
                                        : option.Value}
                                    </Button>
                                  ))}
                                </div>
                              </>
                            ) : (
                              <TextField
                                id={fieldName}
                                name={fieldName}
                                fullWidth
                                key={fieldName}
                                label={field.Label}
                                placeholder={field.Placeholder}
                                required={checkIfRequired(field)}
                                multiline={field.Multiline}
                                value={
                                  field.StartAdornment === '$' &&
                                  fieldWithFocus !== fieldName &&
                                  field.Value
                                    ? parseFloat(field.Value).toLocaleString()
                                    : field.ReadOnly && field.Formatter
                                    ? field.Formatter(field.Value)
                                    : field.Value
                                }
                                onFocus={(event) =>
                                  setFieldWithFocus(fieldName)
                                }
                                onBlur={(event) => setFieldWithFocus(null)}
                                type={
                                  field.TextType && fieldWithFocus === fieldName
                                    ? field.TextType
                                    : 'text'
                                }
                                disabled={checkFieldDisabled(field)}
                                onChange={(event) =>
                                  updateFormField(
                                    signer,
                                    fieldName,
                                    event.target.value
                                  )
                                }
                                InputProps={{
                                  startAdornment: field.StartAdornment ? (
                                    <InputAdornment position="start">
                                      {field.StartAdornment}
                                    </InputAdornment>
                                  ) : null
                                }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                          </div>
                        </>
                      ))
                    )}
                </div>
              ))}

            {!formFields.Gate ||
            (formFields.Gate.Fields[formFields.Gate.RequiredField] &&
              formFields.Gate.Fields[formFields.Gate.RequiredField].Value) ? (
              <div className={'section submit' + (complete ? ' ready' : '')}>
                <h2>Submit {residentForm.Description}</h2>
                {complete ? (
                  <p>
                    All required fields are completed.
                    <br />
                    <strong>Would you like to submit now?</strong>
                  </p>
                ) : (
                  <p>
                    Please complete ALL required fields above.
                    <br />
                    When the form is completed, the button below will activate.
                  </p>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!complete || saving}
                  onClick={() => {
                    setSaving(true);

                    let _uniqueFormField = {};
                    let _residentSubmittal = {
                      ...residentSubmittal,
                      FieldList: []
                    };

                    Object.keys(formFields).map((signer) =>
                      Object.keys(formFields[signer].Fields).map(
                        (fieldName) => {
                          const field = formFields[signer].Fields[fieldName];

                          const DirectionsComponent = field.DirectionsComponent;
                          const TermsComponent = field.TermsComponent;

                          _residentSubmittal.FieldList.push({
                            FieldName: fieldName,
                            SectionTitle: formFields[signer].Title,
                            Signer: signer,
                            Title: field.Title ? field.Title : null,
                            Label: field.Label ? field.Label : null,
                            Value: field.Value ? field.Value : null,
                            Directions: DirectionsComponent
                              ? ReactDOMServer.renderToStaticMarkup(
                                  <DirectionsComponent
                                    formFields={formFields}
                                    residentOnboarding={residentOnboarding}
                                  />
                                )
                              : null,
                            Terms: TermsComponent
                              ? ReactDOMServer.renderToStaticMarkup(
                                  <TermsComponent />
                                )
                              : null,
                            FieldType: field.FieldType ? field.FieldType : null,
                            TextType: field.TextType ? field.TextType : null,
                            OptionList: field.OptionList
                              ? field.OptionList.join(',')
                              : null,
                            Required: field.Required ? field.Required : false,
                            RequiredField: field.RequiredField
                              ? field.RequiredField
                              : null,
                            RequiredFieldValue: field.RequiredFieldValue
                              ? field.RequiredFieldValue
                              : null,
                            ReadOnly: field.ReadOnly ? field.ReadOnly : false,
                            IsFollowUp: field.IsFollowUp
                              ? field.IsFollowUp
                              : false,
                            Placeholder: field.Placeholder
                              ? field.Placeholder
                              : null,
                            SpaceBefore: field.SpaceBefore
                              ? field.SpaceBefore
                              : false,

                            StartAdornment: field.StartAdornment
                              ? field.StartAdornment
                              : null,
                            EndAdornment: field.EndAdornment
                              ? field.EndAdornment
                              : null,
                            Width: field.Width ? field.Width : null
                          });

                          Object.keys(formFields[signer].Fields[fieldName]).map(
                            (field) => {
                              _uniqueFormField[field] =
                                formFields[signer].Fields[fieldName][field];
                            }
                          );
                        }
                      )
                    );

                    actions.pageLoading(
                      true,
                      'Submitting ' +
                        residentForm.Description +
                        '... This process can take up to 15 seconds.'
                    );

                    setBouncedEmail(null);

                    saveResidentSubmittal(
                      residentID,
                      _residentSubmittal,
                      propertyRole
                    ).then((result) => {
                      actions.pageLoading(false);
                      setResidentSubmittal(result);
                      setSaving(false);
                      actions.loadResidentOnboarding(residentID);

                      if (
                        result.SaveResult &&
                        result.SaveResult.SignerInvitationResult &&
                        result.SaveResult.SignerInvitationResult.IsBounced &&
                        result.SaveResult.OtherSignerEmail
                      ) {
                        setBouncedEmail(result.SaveResult.OtherSignerEmail);
                        setTimeout(
                          () =>
                            document
                              .getElementById('bounceError')
                              .scrollIntoView(),
                          500
                        );
                      } else if (closeFunc) {
                        closeFunc();
                      } else {
                        scrollToTop();
                      }
                    });
                  }}>
                  Submit Form
                </Button>
              </div>
            ) : null}
          </div>
        )
      ) : null}
    </div>
  ) : (
    <LinearProgress style={{ marginTop: '10px', marginBottom: '10px' }} />
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    residentOnboarding: state.residentOnboarding,
    stateList: state.commonData.stateList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResidentForm);
