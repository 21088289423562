import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import './index.css';

function ChecklistProgress({ serviceAddress }) {
  const [totalTasks, setTotalTasks] = useState(0);
  const [completedTasks, setCompletedTasks] = useState(0);

  useEffect(() => {
    if (serviceAddress.Checklist) {
      let _totalTasks = 0;
      let _completedTasks = 0;
      serviceAddress.Checklist.map((section) => {
        _totalTasks += section.ServiceList.length;
        _completedTasks += section.ServiceList.filter(
          (service) => service.completed
        ).length;
      });

      setTotalTasks(_totalTasks);
      setCompletedTasks(_completedTasks);
    }
  }, [serviceAddress]);

  return totalTasks ? (
    <div className="checklist-progress">
      <LinearProgress
        variant="determinate"
        color="primary"
        value={(completedTasks / totalTasks) * 100}
      />
      <div className="progress-title">Overall progress</div>
      <div className="progress-label">
        {completedTasks} / {totalTasks} tasks completed
      </div>
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistProgress);
