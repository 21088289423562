import React, { useState, useEffect, lazy } from 'react';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';

import '../../src/assets/base.scss';
import CssBaseline from '@mui/material/CssBaseline';
import 'toasted-notes/src/styles.css';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@mui/material/styles';

import MuiTheme from '../../src/theme';

import { LinearProgress, CircularProgress } from '@mui/material';
import LeftSidebar from '../../src/components/MainContainer';
import LoginButton from '../../src/components/LandingMI/LoginButton';
import ErrorFallback from '../../src/components/ErrorFallback';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  fab,
  faFacebook,
  faTwitter,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub
} from '@fortawesome/free-brands-svg-icons';
import {
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar
} from '@fortawesome/free-regular-svg-icons';
import {
  fas,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faSmile,
  faHeart,
  faBatteryEmpty,
  faBatteryFull,
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkedAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink
} from '@fortawesome/free-solid-svg-icons';
library.add(
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar
);
library.add(
  fab,
  faFacebook,
  faTwitter,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub
);
library.add(
  fas,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faSmile,
  faHeart,
  faBatteryEmpty,
  faBatteryFull,
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkedAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink
);

import {
  setCookie,
  getCurrentRoute,
  isExcludeRoute,
  setSessionStorage,
  getSessionStorage
} from '../../src/api/dataApi';

import * as serviceAddressActions from '../../src/redux/actions/serviceAddressActions';

// import LogRocket from 'logrocket';

// LogRocket.init('elzhvj/movingin');

import '../../src/theme-override.css';

import UserPropertySelect from '../../src/components/UserPropertySelect';

function MITheme({
  webSocket,
  authUser,
  actions,
  siteConfig,
  serviceAddress,
  ...props
}) {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [showLoginUI, setShowLoginUI] = useState(false);
  const [showAnonymousUI, setShowAnonymousUI] = useState(false);

  const [showUnderDevelopmentPopup, setShowUnderDevelopmentPopup] =
    useState(false);

  const [showInvitationPopup, setShowInvitationPopup] = useState(null);

  const [showDeviceVerificationPopup, setShowDeviceVerificationPopup] =
    useState(false);

  const route = getCurrentRoute();
  const [showPropertySelect, setShowPropertySelect] = useState(null);

  useEffect(() => {
    if (siteConfig && webSocket) {
      if (
        siteConfig.requireInvitation &&
        webSocket.connected &&
        !webSocket.invitation &&
        !isExcludeRoute(route)
      ) {
        if (
          authUser &&
          authUser.User &&
          authUser.User.Token &&
          authUser.PropertyList &&
          authUser.PropertyList.length
        ) {
          if (
            authUser.User &&
            authUser.User.Token &&
            serviceAddress &&
            serviceAddress.Address
          ) {
            setShowPropertySelect(false);
          } else if (!getSessionStorage('mi-selected-resident')) {
            setShowPropertySelect(true);
          }
        } else {
          setShowInvitationPopup(true);
        }
      } else if (webSocket.connected) {
        setShowInvitationPopup(false);
        // setShowUnderDevelopmentPopup(
        //   dataApi.getCookie('mi-under-dev-shown') !== '1'
        // );
      }
    }
  }, [siteConfig, webSocket]);

  useEffect(() => {
    if (serviceAddress) {
      setShowDeviceVerificationPopup(
        serviceAddress.DeviceVerificationRequired && !isExcludeRoute(route)
      );
    }
  }, [serviceAddress, route]);

  function getUserMenu() {
    let arrUserMenu = [];

    [
      {
        label: 'Home',
        to: '/'
      }
    ].map((curMenuItem) => {
      var menuItem = { ...curMenuItem };
      if (curMenuItem.content) {
        menuItem.content = [];
        curMenuItem.content.map((curSubMenuItem) => {
          menuItem.content.push({ ...curSubMenuItem });
        });
      }

      arrUserMenu.push(menuItem);
    });

    return arrUserMenu;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <>
        <Helmet>
          <title>
            {siteConfig.brand}{' '}
            {siteConfig.tagline ? ' - ' + siteConfig.tagline : ''}
          </title>
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
          />
          {/* <meta name="viewport" content="width=1170" /> */}

          <meta name="theme-color" content="#000000" />
          <meta name="description" content={siteConfig.tagline} />
          {/* {siteConfig && siteConfig.id === 'mi' ? (
            <meta
              name="apple-itunes-app"
              content="app-id=com.movingin.MovingINDashboard"
            />
          ) : null} */}
          <link
            id="favIcon"
            rel="shortcut icon"
            href={withPrefix(siteConfig.favIcon)}
          />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;300;600&display=swap"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@1&display=swap"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Pacifico&display=swap"
            rel="stylesheet"></link>

          <link
            href="https://fonts.googleapis.com/css2?family=Quicksand&display=swap"
            rel="stylesheet"
          />

          <link rel="stylesheet" href="https://use.typekit.net/yxq7zhr.css" />

          <link
            href="https://fonts.googleapis.com/css2?family=Comforter+Brush&display=swap"
            rel="stylesheet"></link>

          <link
            id="appleTouchIcon"
            rel="apple-touch-icon"
            href={withPrefix(siteConfig.appleTouchIcon)}
          />
        </Helmet>

        <CssBaseline />

        <ThemeProvider theme={MuiTheme}>
          <AnimatePresence>
            {!siteConfig.requireInvitation ||
            webSocket.invitation ||
            isExcludeRoute(route) ||
            (authUser &&
              authUser.User &&
              authUser.User.Token &&
              serviceAddress &&
              serviceAddress.Address) ? (
              <LeftSidebar userMenu={getUserMenu()}>
                {props.children}
              </LeftSidebar>
            ) : null}
          </AnimatePresence>
        </ThemeProvider>

        {webSocket.pageLoading ? (
          <div className="page-loading">
            <div className="loading-message">
              <CircularProgress color="secondary" />
              <br />
              {webSocket.loadingMessage}
            </div>

            <LinearProgress />
          </div>
        ) : null}

        {showInvitationPopup && !webSocket.pageLoading ? (
          <Dialog
            open={true}
            aria-labelledby="form-dialog-title"
            className="usage-history-request"
            fullWidth={true}>
            <DialogTitle id="form-dialog-title">
              <img
                src={withPrefix('/images/logo-icon.svg')}
                className="logo-icon"
              />
              <div style={{ marginTop: '-5px' }}>
                Welcome to {siteConfig.brand}
              </div>
            </DialogTitle>
            <DialogContent width="100%" style={{ paddingTop: '20px' }}>
              <LoginButton formOnly />
            </DialogContent>
          </Dialog>
        ) : showPropertySelect ? (
          <UserPropertySelect />
        ) : showUnderDevelopmentPopup ? (
          <Dialog
            open={true}
            aria-labelledby="form-dialog-title"
            className="usage-history-request"
            fullWidth={true}>
            <DialogTitle id="form-dialog-title">
              <IconButton
                style={{ float: 'right' }}
                aria-label="close"
                onClick={() => {
                  setCookie('mi-under-dev-shown', '1');
                  setShowUnderDevelopmentPopup(false);
                }}>
                <CloseIcon />
              </IconButton>
              <img
                src={withPrefix('/images/logo-icon.svg')}
                className="logo-icon"
              />
              <div style={{ marginTop: '-5px' }}>Welcome!</div>
            </DialogTitle>
            <DialogContent width="100%" style={{ paddingTop: '20px' }}>
              <p>
                Thanks for visiting {siteConfig.brand}. This website is
                currently under development.
              </p>
              <p>
                Feel free to take a look around, but please note that any orders
                placed here are for test purposes only.
              </p>
              {siteConfig.id === 'sc' ? (
                <p>
                  <strong>
                    Also note that we are currently only showing electric plans
                    available in Dallas. We have pre-filled the zip code field
                    with a Dallas zip code (75254).
                  </strong>
                </p>
              ) : null}
            </DialogContent>
            <DialogActions style={{ display: 'block', textAlign: 'right' }}>
              <Button
                onClick={() => {
                  setCookie('mi-under-dev-shown', '1');
                  setShowUnderDevelopmentPopup(false);
                }}
                variant="outlined">
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        ) : showDeviceVerificationPopup ? (
          <Dialog
            open={true}
            aria-labelledby="form-dialog-title"
            className="usage-history-request"
            fullWidth={true}>
            <DialogTitle id="form-dialog-title">
              <img
                src={withPrefix('/images/logo-icon.svg')}
                className="logo-icon"
              />
              <div style={{ marginTop: '-5px' }}>
                Welcome back
                {(serviceAddress.Address && serviceAddress.Address.FirstName
                  ? ', ' + serviceAddress.Address.FirstName
                  : '') + '!'}
              </div>
            </DialogTitle>
            <DialogContent width="100%" style={{ paddingTop: '20px' }}>
              <p>
                Thanks for visiting {siteConfig.brand}. To continue, we need to
                verify that you have access to this account.
              </p>
            </DialogContent>
            <DialogActions style={{ display: 'block', textAlign: 'right' }}>
              {/* <Button
              onClick={() => {
                dataApi.setCookie('mi-under-dev-shown', '1');
                setShowDeviceVerificationPopup(false);
              }}
              variant="outlined">
              Continue
            </Button> */}
            </DialogActions>
          </Dialog>
        ) : null}
      </>
    </ErrorBoundary>
  );
}

MITheme.propTypes = {
  actions: PropTypes.object.isRequired,
  webSocket: PropTypes.object,
  authUser: PropTypes.object,
  children: PropTypes.node
};

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    authUser: state.authUser,
    loading: state.apiCallsInProgress > 0,
    siteConfig: state.commonData.siteConfig,
    serviceAddress: state.serviceAddress,
    authUser: state.authUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MITheme);
