import React, { useEffect, useState, useRef } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';

import Tooltip from '@mui/material/Tooltip';

import InfoIcon from '@mui/icons-material/Info';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import * as dataApi from '../../api/dataApi';
import * as offerCalc from '../../api/offerCalc';
import * as webSocketActions from '../../redux/actions/webSocketActions';

import SortIcon from '../OfferGrid/SortIcon';
import OfferRowElectric from '../OfferGrid/OfferRowElectric';
import UsageHistoryEntry from '../UsageHistoryEntry';
import IntroText from '../IntroText';
import TaskMenu from '../TaskMenu';
import TermTypePopup from './TermTypePopup';
import FiltersElectric from './FiltersElectric';
import SCLandingInline from './SCLandingInline';

import '../OfferGrid/index.css';
import './SCLandingInline.css';
import AddressSearch from '../AddressSearch';
import ProviderLogo from '../OfferGrid/ProviderLogo';
import FeaturedOffersElectric from './FeaturedOffersElectric';
import ResidentOnboardingPopup from '../ResidentOnboarding/ResidentOnboardingPopup';
import AddressSearchPopup from '../AddressSearch/AddressSearchPopup';
import NoDeregulatedElectric from './NoDeregulatedElectric';
import FeaturedOffer from '../OfferGrid/FeaturedOfferElectric';

const tooltips = {
  AdvertisedPrice:
    "The advertised price is what most sites show to make a plan appear cheaper than it is. It can be misleading because it assumes you'll use the same amount of energy each month without considering how your usage actually varies seasonally.",
  TruePrice:
    'True Price is calculated based on a seasonal usage curve, which will more closely match your actual usage pattern from month to month.',
  TrueCost:
    'True Cost offsets the advertised price with a projected seasonal usage curve to more accurately reflect what your average bill will look like for each plan.',
  TermType:
    'Did you know that you can cancel a long-term contract prior to its expiration date without penalty or early termination fees if you are moving to a new address?'
};

function getTermLengthLabel(termLength) {
  return termLength == null
    ? 'Unknown'
    : termLength && termLength > 1
    ? termLength + ' months'
    : '1 month';
}

function isGimmickOffer(offer, selectedTermLength, allOptionSelected) {
  return (
    (allOptionSelected.termLength ||
      selectedTermLength[getTermLengthLabel(offer.TermLength)]) &&
    !offer.ProviderUsageCharge_Tier2_Amount &&
    !offer.ProviderUsageCharge_Tier3_Amount &&
    !offer.ProviderUsageCharge_Tier4_Amount &&
    !offer.ProviderUsageCharge_Tier5_Amount &&
    !offer.ProviderBaseCharge_PerDay_Amount &&
    !offer.ProviderCredit_Tier1_Amount &&
    !offer.ProviderCredit_Tier2_Amount &&
    !offer.ProviderCredit_Tier3_Amount &&
    !offer.ProviderCredit_Tier4_Amount &&
    !offer.ProviderCredit_Tier5_Amount &&
    !offer.BaseChargeAdjustment_Provider &&
    !offer.BaseChargeAdjustment_Utility &&
    !offer.UsageChargeAdjustment_All
  );
}

const quickFilterList = [
  { label: 'ALL', value: 'all' },
  {
    label: '100% Green Energy',
    value: 'green',
    func: (offerList, selectedTermLength, allOptionSelected) => {
      return offerList.filter(
        (offer) =>
          (allOptionSelected.termLength ||
            selectedTermLength[getTermLengthLabel(offer.TermLength)]) &&
          offer.RenewableContent === 100
      );
    }
  },
  {
    label: 'Free Nights or Weekends',
    value: 'tou',
    requiredRateType: 'Time of Use',
    func: (offerList, selectedTermLength, allOptionSelected) => {
      return offerList.filter(
        (offer) =>
          (allOptionSelected.termLength ||
            selectedTermLength[getTermLengthLabel(offer.TermLength)]) &&
          offer.RateType === 'Time of Use'
      );
    }
  },
  {
    label: 'No Contract (Month-to-month)',
    value: 'nocontract',
    func: (offerList, selectedTermLength, allOptionSelected) => {
      return offerList.filter(
        (offer) =>
          (allOptionSelected.termLength ||
            selectedTermLength[getTermLengthLabel(offer.TermLength)]) &&
          offer.TermLength <= 1
      );
    }
  },
  {
    label: 'Short Term (< 12 months)',
    value: 'short',
    func: (offerList, selectedTermLength, allOptionSelected) => {
      return offerList.filter(
        (offer) =>
          (allOptionSelected.termLength ||
            selectedTermLength[getTermLengthLabel(offer.TermLength)]) &&
          offer.TermLength < 12
      );
    }
  },
  {
    label: 'No Credit Check (Prepaid)',
    value: 'prepaid',
    requiredRateType: 'Prepaid',
    func: (offerList, selectedTermLength, allOptionSelected) => {
      return offerList.filter(
        (offer) =>
          (allOptionSelected.termLength ||
            selectedTermLength[getTermLengthLabel(offer.TermLength)]) &&
          offer.RateType === 'Prepaid'
      );
    }
  },

  {
    label: 'Exclude plans with bill credits',
    value: 'no-credits',
    //requiredRateType: 'Prepaid',
    func: (offerList, selectedTermLength, allOptionSelected) => {
      return offerList.filter(
        (offer) =>
          !offer.ProviderCredit_Tier1_Amount &&
          !offer.ProviderCredit_Tier2_Amount &&
          !offer.ProviderCredit_Tier3_Amount &&
          !offer.ProviderCredit_Tier4_Amount &&
          !offer.ProviderCredit_Tier5_Amount
      );
    }
  },
  {
    label: 'No Pricing Gimmicks',
    value: 'no-gimmicks',
    hideInQuickFilters: true,
    requiredRateType: 'Fixed',

    func: (offerList, selectedTermLength, allOptionSelected) => {
      return offerList.filter((offer) =>
        isGimmickOffer(offer, selectedTermLength, allOptionSelected)
      );
    }
  }
];

function OfferGridElectric({
  serviceAddress,
  actions,
  webSocket,
  siteConfig,
  isLanding,
  onUsageChanged,
  preFilter,
  preFilterLabel
}) {
  const headerRef = useRef();
  const observer = useRef();
  const [headerIsSticky, setHeaderIsSticky] = useState(false);

  const [sortColumn, setSortColumn] = useState({
    estimated: 'SingleMonthPriceKWh',
    lastBill: 'SingleMonthPriceDollars',
    actual: 'TrueCost_Dollars'
  });
  const [offerList, setOfferList] = useState(null);
  const [filteredOfferList, setFilteredOfferList] = useState(null);
  const [offerDictionary, setOfferDictionary] = useState({});
  const [estimatedUsage, setEstimatedUsage] = useState(
    offerCalc.getEstimatedUsage()
  );
  const [visibleOfferCount, setVisibleOfferCount] = useState(null);

  const [lastBillUsage, setLastBillUsage] = useState(
    offerCalc.getLastBillUsage()
  );

  const [lastBillAmount, setLastBillAmount] = useState(
    offerCalc.getLastBillAmount()
  );

  const [selectedUsage, setSelectedUsage] = useState(null);
  const [lastSelectedUsage, setLastSelectedUsage] = useState(null);
  const [filtersExpanded, setFiltersExpanded] = useState(
    false //typeof window !== 'undefined' && window.innerWidth > 668
  );
  const [selectedProviderList, setSelectedProviderList] = useState(null);
  const [selectedRateType, setSelectedRateType] = useState(null);
  const [selectedTermLength, setSelectedTermLength] = useState(null);
  const [showUsageHistoryPrompt, setShowUsageHistoryPrompt] = useState(false);
  const [usageHistory, setUsageHistory] = useState({ CookieChecked: false });
  const [quickFilter, setQuickFilter] = useState('all');
  const [usageSetting, setUsageSetting] = useState(offerCalc.getUsageSetting());
  const offerCalculationTimeout = useRef();
  const [offersUpdating, setOffersUpdating] = useState(false);
  const estimatedUsageTimeout = useRef();
  const lastBillUsageTimeout = useRef();
  const [showUsageHistoryRequest, setShowUsageHistoryRequest] = useState(false);
  const [allOptionSelected, setAllOptionSelected] = useState({
    rateType: true,
    termLength: false,
    provider: true
  });
  const [filterCounts, setFilterCounts] = useState({});
  const [usageHistoryMode, setUsageHistoryMode] = useState(null);
  const [usageHistoryExists, setUsageHistoryExists] = useState(false);
  const [bestOffer, setBestOffer] = useState(null);
  const [showTrueCostExplanation, setShowTrueCostExplanation] = useState(
    false //dataApi.getCookie('mi-hide-true-cost-explanation') !== '1'
  );

  const [noOffers, setNoOffers] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [highlightPriceColumn, setHighlighPriceColumn] = useState(null);
  const [showTermTypePopup, setShowTermTypePopup] = useState();
  const [hasIntroText, setHasIntroText] = useState(false);
  const [filtersClicked, setFiltersClicked] = useState(null);
  const [showResidentOnboarding, setShowResidentOnboarding] = useState(null);
  const [preFilterRemoved, setPrefilterRemoved] = useState(null);
  const [showAddressSearch, setShowAddressSearch] = useState(null);
  const [truePriceClicked, setTruePriceClicked] = useState(null);

  useEffect(() => {
    if (serviceAddress && serviceAddress.OfferDictionary) {
      let _offerList = [];

      for (const key in serviceAddress.OfferDictionary) {
        if (key.indexOf('Electric') !== -1) {
          _offerList = _offerList
            .concat(serviceAddress.OfferDictionary[key])
            .map((offer) => {
              let _offer = { ...offer };

              _offer.RateType = offerCalc.getRateType(offer);

              return _offer;
            });
        }

        //As of 12/03/2023 we removed True Price as the default, so we are showing nextera plans
        // if (typeof window !== 'undefined' && window.innerWidth > 820) {
        //   setOfferList(_offerList.filter((offer) => offer.ApiID !== 603)); //Exlude NextEra except on mobile
        // } else {
        setOfferList(_offerList);
        // }
      }

      if (!_offerList.length) {
        setNoOffers(true);
      } else {
        setNoOffers(false);
      }
    }

    if (serviceAddress && serviceAddress.Checklist) {
      const _section = serviceAddress.Checklist.filter(
        (section) => section.ServiceSectionID === 19
      )[0];
      setSelectedSection(_section);
    }

    actions.pageLoading(false);
  }, [serviceAddress]);

  useEffect(() => {
    if (filteredOfferList) {
      doOfferSort(filteredOfferList);
    }

    let _sortColumn = sortColumn[usageSetting].split('-').join('');
    switch (_sortColumn) {
      case 'SingleMonthPriceKWh':
      case 'TrueCost_PerKWh':
      case 'SingleMonthPriceDollars':
      case 'AmountSaved':
        setHighlighPriceColumn(_sortColumn);
        break;
    }
  }, [sortColumn]);

  useEffect(() => {
    if (offerList) {
      let dictProvider = {};
      let dictTermLength = {};
      let dictRateType = {};

      offerList.map((offer) => {
        let providerFilter = dictProvider[offer.ProviderName]
          ? dictProvider[offer.ProviderName]
          : false;

        dictProvider[offer.ProviderName] = providerFilter;

        const termLengthLabel = getTermLengthLabel(offer.TermLength);

        let termLengthFilter = dictTermLength[termLengthLabel]
          ? dictTermLength[termLengthLabel]
          : offer.TermLength >= 12
          ? true
          : false;

        dictTermLength[termLengthLabel] = termLengthFilter;

        let rateTypeFilter = dictRateType[offer.RateType]
          ? dictRateType[offer.RateType]
          : false;

        dictRateType[offer.RateType] = rateTypeFilter;
      });

      setSelectedProviderList(dictProvider);
      setSelectedRateType(dictRateType);
      setSelectedTermLength(dictTermLength);
    }
  }, [offerList]);

  useEffect(() => {
    if (selectedRateType) {
      let someRateTypeSelected = false;
      Object.keys(selectedRateType).map((key) => {
        if (selectedRateType[key] === true) {
          someRateTypeSelected = true;
        }
      });

      setAllOptionSelected({
        ...allOptionSelected,
        rateType: !someRateTypeSelected
      });
    }

    //updateFilterCounts();
  }, [selectedRateType]);

  useEffect(() => {
    if (allOptionSelected.rateType && selectedRateType) {
      let _selectedRateType = { ...selectedRateType };
      let someRateTypeChanged = false;
      Object.keys(_selectedRateType).map((key) => {
        if (_selectedRateType[key]) {
          _selectedRateType[key] = false;
          someRateTypeChanged = true;
        }
      });

      if (someRateTypeChanged) {
        setSelectedRateType({ ..._selectedRateType });
      }
    }

    if (allOptionSelected.termLength && selectedTermLength) {
      let _selectedTermLength = { ...selectedTermLength };
      let someTermLengthChanged = false;
      Object.keys(_selectedTermLength).map((key) => {
        if (_selectedTermLength[key]) {
          _selectedTermLength[key] = false;
          someTermLengthChanged = true;
        }
      });

      if (someTermLengthChanged) {
        setSelectedTermLength({ ..._selectedTermLength });
      }
    }

    if (allOptionSelected.provider && selectedProviderList) {
      let _selectedProviderList = { ...selectedProviderList };
      let someProviderChanged = false;
      Object.keys(_selectedProviderList).map((key) => {
        if (_selectedProviderList[key]) {
          _selectedProviderList[key] = false;
          someProviderChanged = true;
        }
      });

      if (someProviderChanged) {
        setSelectedProviderList({ ..._selectedProviderList });
      }
    }
  }, [allOptionSelected]);

  useEffect(() => {
    if (selectedTermLength && Object.keys(selectedTermLength).length) {
      let someTermLengthSelected = false;
      Object.keys(selectedTermLength).map((key) => {
        if (selectedTermLength[key]) {
          someTermLengthSelected = true;
        }
      });

      const _allOptionsSelected = {
        ...allOptionSelected,
        termLength: !someTermLengthSelected
      };

      setAllOptionSelected(_allOptionsSelected);
    }

    updateFilterCounts();
  }, [selectedTermLength]);

  useEffect(() => {
    if (selectedProviderList) {
      let someProviderSelected = false;
      Object.keys(selectedProviderList).map((key) => {
        if (selectedProviderList[key]) {
          someProviderSelected = true;
        }
      });

      setAllOptionSelected({
        ...allOptionSelected,
        provider: !someProviderSelected
      });
    }
  }, [selectedProviderList]);

  useEffect(() => {
    if (offerList) {
      if (!preFilter || preFilterRemoved) {
        if (filtersClicked) {
          setOffersUpdating(true);
        }

        if (offerCalculationTimeout.current) {
          clearTimeout(offerCalculationTimeout.current);
        }

        offerCalculationTimeout.current = setTimeout(() => {
          let _offerList = offerList.filter((offer) => {
            let passesFilters = selectedProviderList
              ? (selectedProviderList[offer.ProviderName] &&
                  selectedProviderList[offer.ProviderName] === true) ||
                allOptionSelected.provider
              : true;

            const termLengthLabel = getTermLengthLabel(offer.TermLength);
            if (!allOptionSelected.termLength) {
              if (selectedTermLength && !selectedTermLength[termLengthLabel]) {
                passesFilters = false;
              }
            }

            if (!allOptionSelected.rateType) {
              if (
                selectedRateType &&
                selectedRateType[offer.RateType] !== true
              ) {
                passesFilters = false;
              }
            }

            if (quickFilter === 'green' && offer.RenewableContent !== 100) {
              passesFilters = false;
            } else if (
              quickFilter === 'no-gimmicks' &&
              !isGimmickOffer(offer, selectedTermLength, allOptionSelected)
            ) {
              passesFilters = false;
            } else if (quickFilter === 'no-credits') {
              if (
                offer.ProviderCredit_Tier1_Amount ||
                offer.ProviderCredit_Tier2_Amount ||
                offer.ProviderCredit_Tier3_Amount ||
                offer.ProviderCredit_Tier4_Amount ||
                offer.ProviderCredit_Tier5_Amount
              ) {
                passesFilters = false;
              }
            }

            return passesFilters;
          });

          doOfferSort(addFullCostDetail(_offerList));

          setOffersUpdating(false);

          updateFilterCounts();
        }, 300);
      } else {
        let _offerList = offerList.filter((offer) => {
          let rateTypeFilter = [];
          let termLengthFilter = [];

          if (preFilter.quickFilter) {
            rateTypeFilter = Object.keys(
              Object.keys(getQuickFilterRateType(preFilter.quickFilter)).map(
                (rateType) => {
                  return rateType;
                }
              )
            );

            termLengthFilter = Object.keys(
              Object.keys(getQuickFilterTermLength(preFilter.quickFilter)).map(
                (termLength) => {
                  return termLength;
                }
              )
            );
          } else {
            if (preFilter.rateType) {
              rateTypeFilter = Object.keys(preFilter.rateType).map(
                (rateType) => {
                  return rateType;
                }
              );
            }

            if (preFilter.termLength) {
              termLengthFilter = Object.keys(preFilter.termLength).map(
                (termLength) => {
                  return termLength;
                }
              );
            }
          }

          let passesFilters = true;

          const termLengthLabel = getTermLengthLabel(offer.TermLength);
          if (
            termLengthFilter &&
            termLengthFilter.length &&
            termLengthFilter.filter(
              (termLength) => termLength === termLengthLabel
            ).length === 0
          ) {
            passesFilters = false;
          }

          if (
            rateTypeFilter &&
            rateTypeFilter.length &&
            rateTypeFilter.filter((rateType) => rateType === offer.RateType)
              .length === 0
          ) {
            passesFilters = false;
          }

          if (
            preFilter.quickFilter === 'green' &&
            offer.RenewableContent !== 100
          ) {
            passesFilters = false;
          } else if (
            preFilter.quickFilter === 'simple-fixed' &&
            (offer.RateType !== 'Fixed' || offerCalc.checkIfTiered(offer))
          ) {
            passesFilters = false;
          } else if (
            preFilter.quickFilter === 'no-gimmicks' &&
            !isGimmickOffer(offer, selectedTermLength, allOptionSelected)
          ) {
            passesFilters = false;
          }

          return passesFilters;
        });

        doOfferSort(addFullCostDetail(_offerList));
      }
    }
  }, [
    offerList,
    selectedUsage,
    selectedProviderList,
    selectedRateType,
    selectedTermLength,
    quickFilter,
    usageHistory,
    allOptionSelected,
    lastBillAmount,
    preFilter,
    preFilterRemoved
  ]);

  useEffect(() => {
    if (selectedUsage) {
      offerCalc.setSelectedUsage(selectedUsage);
    }
  }, [selectedUsage]);

  useEffect(() => {
    if (usageSetting) {
      offerCalc.setUsageSetting(usageSetting);

      if (usageSetting === 'lastBill') {
        setSelectedUsage(lastBillUsage ? lastBillUsage : 1000);
      } else if (usageSetting === 'actual') {
        setSelectedUsage(1000);
      } else {
        setSelectedUsage(lastSelectedUsage ? lastSelectedUsage : 1000);
      }
    }
  }, [usageSetting]);

  useEffect(() => {
    if (
      estimatedUsage &&
      !isNaN(estimatedUsage) &&
      estimatedUsage > 0 &&
      selectedUsage
    ) {
      if (estimatedUsageTimeout.current) {
        clearTimeout(estimatedUsageTimeout.current);
      }

      estimatedUsageTimeout.current = setTimeout(() => {
        setSelectedUsage(estimatedUsage);
      }, 300);

      offerCalc.setEstimatedUsage(estimatedUsage);
    } else if (usageSetting === 'estimated') {
      setSelectedUsage(offerCalc.getSelectedUsage());

      offerCalc.setEstimatedUsage('');
    }
  }, [estimatedUsage]);

  useEffect(() => {
    if (
      lastBillUsage &&
      !isNaN(lastBillUsage) &&
      lastBillUsage > 0 &&
      selectedUsage
    ) {
      if (lastBillUsageTimeout.current) {
        clearTimeout(lastBillUsageTimeout.current);
      }

      lastBillUsageTimeout.current = setTimeout(() => {
        setSelectedUsage(lastBillUsage);
      }, 200);

      offerCalc.setLastBillUsage(lastBillUsage);
    } else {
      offerCalc.setLastBillUsage('');
    }
  }, [lastBillUsage]);

  useEffect(() => {
    if (
      lastBillAmount &&
      !isNaN(lastBillAmount) &&
      lastBillAmount > 0 &&
      selectedUsage
    ) {
      offerCalc.setLastBillAmount(lastBillAmount);
    } else {
      offerCalc.setLastBillAmount('');
    }
  }, [lastBillAmount]);

  useEffect(() => {
    for (const key in offerDictionary) {
      if (!offerDictionary[key].detailList) {
        dataApi.listOfferDetail(key).then((detailList) => {
          let _detailList = {};

          detailList.map((detail) => {
            _detailList[detail.Key] = detail.Value;
          });

          setOfferDictionary({
            ...offerDictionary,
            [key]: {
              ...offerDictionary[key],
              detailList: _detailList
            }
          });
        });
      }
    }
  }, [offerDictionary]);

  useEffect(() => {
    if (!usageHistory.CookieChecked) {
      const _usageHistory = offerCalc.getUsageHistory(serviceAddress);

      if (_usageHistory) {
        setUsageHistory({
          ..._usageHistory
        });
      }
    }
  }, []);

  useEffect(() => {
    offerCalc.setUsageHistory(usageHistory);

    let _usageHistoryExists = false;

    if (usageHistory) {
      dataApi.getMonthsOfYear().map((month) => {
        if (usageHistory[month] && !isNaN(usageHistory[month])) {
          _usageHistoryExists = true;
        }
      });
    }

    setUsageHistoryExists(_usageHistoryExists);

    if (_usageHistoryExists) {
      setUsageHistoryMode('exists');
    }
  }, [usageHistory]);

  useEffect(() => {
    clearFilters();

    switch (quickFilter) {
      case 'fixed':
      case 'tou':
      case 'prepaid':
        if (selectedRateType) {
          let _selectedRateType = getQuickFilterRateType(quickFilter);

          setSelectedRateType(_selectedRateType);
          setAllOptionSelected({ ...allOptionSelected, rateType: false });
        }
        break;
      case 'nocontract':
        if (selectedTermLength) {
          let _selectedTermLength = getQuickFilterTermLength(quickFilter);
          setSelectedTermLength(_selectedTermLength);
          setAllOptionSelected({ ...allOptionSelected, termLength: false });
        }
        break;
      case 'short':
        if (selectedTermLength) {
          let _selectedTermLength = getQuickFilterTermLength(quickFilter);

          setSelectedTermLength(_selectedTermLength);
          setAllOptionSelected({ ...allOptionSelected, termLength: false });
        }
        break;
      default:
        updateFilterCounts(); //Just re-filter in case a quick filter, like 'Green', is used
        break;
    }
  }, [quickFilter]);

  useEffect(() => {
    if (headerRef.current && !observer.current) {
      observer.current = new IntersectionObserver(
        ([e]) => {
          setHeaderIsSticky(e.intersectionRatio < 1);
        },
        { threshold: [1] }
      );

      observer.current.observe(headerRef.current);

      console.log('add observer');
    }
  }, [headerRef.current]);

  useEffect(() => {
    // unmount header observer
    return function () {
      if (observer.current && headerRef.current) {
        console.log('remove observer');
        observer.current.unobserve(headerRef.current);
        observer.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (filteredOfferList) {
      setVisibleOfferCount(filteredOfferList.length);
    }
  }, [filteredOfferList]);

  useEffect(() => {
    if (visibleOfferCount !== null) {
      if (filtersClicked) {
        if (typeof window !== 'undefined') {
          const top = document.getElementById('offerRowStart');
          if (top) {
            dataApi.scrollToElementInPage(top, -145, false);
          }
        }
      }
    }
  }, [visibleOfferCount, sortColumn]);

  useEffect(() => {
    if (onUsageChanged) {
      onUsageChanged(selectedUsage, usageSetting, sortColumn);
    }

    setOfferDictionary({});
  }, [selectedUsage]);

  function updateFilterCounts() {
    if (selectedRateType && selectedTermLength && selectedProviderList) {
      let dictProvider = {};
      for (const providerName in selectedProviderList) {
        dictProvider[providerName] = 0;
      }

      let dictTermLength = {};
      for (const termLength in selectedTermLength) {
        dictTermLength[termLength] = 0;
      }

      let dictRateType = {};
      for (const rateType in selectedRateType) {
        dictRateType[rateType] = 0;
      }

      offerList.map((offer) => {
        let passesRateType = true;
        let passesTermLength = true;
        let passesProvider = true;

        if (!allOptionSelected.rateType) {
          if (selectedRateType && selectedRateType[offer.RateType] !== true) {
            passesTermLength = false;
            passesProvider = false;
          }
        }

        const termLengthLabel = getTermLengthLabel(offer.TermLength);
        if (!allOptionSelected.termLength) {
          if (
            selectedTermLength &&
            selectedTermLength[termLengthLabel] !== true
          ) {
            passesRateType = false;
            passesProvider = false;
          }
        }

        if (!allOptionSelected.provider) {
          if (
            selectedProviderList &&
            selectedProviderList[offer.ProviderName] !== true
          ) {
            passesRateType = false;
            passesTermLength = false;
          }
        }

        if (quickFilter === 'green' && offer.RenewableContent !== 1) {
          passesRateType = false;
          passesTermLength = false;
          passesProvider = false;
        }

        if (passesRateType) {
          dictRateType[offer.RateType]++;
        }

        if (passesTermLength) {
          dictTermLength[termLengthLabel]++;
        }

        if (passesProvider) {
          dictProvider[offer.ProviderName]++;
        }
      });

      setFilterCounts({
        ...filterCounts,
        rateType: dictRateType,
        termLength: dictTermLength,
        provider: dictProvider
      });
    }
  }

  function sortOffers(column) {
    let _sortColumn = column;

    if (sortColumn[usageSetting] === column) {
      if (column.indexOf('-') !== -1) {
        _sortColumn = column.split('-')[1];
      } else {
        _sortColumn = '-' + column;
      }
    }

    setSortColumn({
      ...sortColumn,
      [usageSetting]: _sortColumn
    });
  }

  function addFullCostDetail(_offerList) {
    _offerList = _offerList.map((offer) => {
      let _offer = offerCalc.getFullCostDetail(
        offer,
        selectedUsage,
        serviceAddress,
        usageHistory,
        usageSetting,
        lastBillAmount
      );

      return _offer;
    });

    return _offerList;
  }

  function doOfferSort(offerList) {
    const column = sortColumn[usageSetting].split('-').join('');
    let desc = sortColumn[usageSetting].indexOf('-') === 0;

    let sortedList = [...offerList];

    switch (column) {
      case 'RenewableContent':
      case 'TermLength':
        if (desc) {
          sortedList.sort(
            (offerA, offerB) =>
              offerB[column] - offerA[column] ||
              offerA[highlightPriceColumn] - offerB[highlightPriceColumn]
          );
        } else {
          sortedList.sort(
            (offerA, offerB) =>
              offerA[column] - offerB[column] ||
              offerA[highlightPriceColumn] - offerB[highlightPriceColumn]
          );
        }
        break;
      default:
        sortedList.sort((a, b) => {
          const valueA = a[column];
          const valueB = b[column];

          let result = valueA > valueB ? 1 : valueA < valueB ? -1 : 0;

          if (valueB === null) {
            result = 1;
          }

          if (valueA === null) {
            result = -1;
          }

          if (desc) {
            result = result * -1;
          }

          return result;
        });
        break;
    }

    //Find the best offer
    //Removed per Bud 11/8/2023
    // if (siteConfig && siteConfig.id === 'mi') {
    //   let sortedListByBestOffer = [...offerList];
    //   if (sortedListByBestOffer.length > 0) {
    //     const bestOfferSortColumn =
    //       usageSetting === 'lastBill' && lastBillAmount && lastBillUsage
    //         ? 'SingleMonthPriceDollars'
    //         : 'TrueCost_Dollars';
    //     sortedListByBestOffer.sort((a, b) => {
    //       const valueA = a[bestOfferSortColumn];
    //       const valueB = b[bestOfferSortColumn];

    //       let result = valueA > valueB ? 1 : valueA < valueB ? -1 : 0;

    //       if (valueB === null) {
    //         result = 1;
    //       }

    //       if (valueA === null) {
    //         result = -1;
    //       }

    //       return result;
    //     });

    //     const _bestOffer = { ...sortedListByBestOffer[0] };

    //     setBestOffer(_bestOffer);
    //   }
    // }

    setFilteredOfferList(sortedList);
  }

  function clearFilters() {
    let _selectedRateType = { ...selectedRateType };
    let _selectedTermLength = { ...selectedTermLength };

    for (const field in _selectedRateType) {
      _selectedRateType[field] = false;
    }

    for (const field in _selectedTermLength) {
      const termLength = parseInt(field.split(' ')[0]);
      _selectedTermLength[field] = termLength >= 12;
    }

    setSelectedRateType(_selectedRateType);
    setSelectedTermLength(_selectedTermLength);
    setAllOptionSelected({
      ...allOptionSelected,
      rateType: true,
      termLength: false,
      provider: true
    });
  }

  function getQuickFilterRateType(_quickFilter) {
    let _selectedRateType = {};

    switch (_quickFilter) {
      case 'fixed':
      case 'tou':
      case 'prepaid':
        if (selectedRateType) {
          Object.keys(selectedRateType).map((key) => {
            _selectedRateType[key] =
              _quickFilter === 'fixed'
                ? key.indexOf('Fixed') !== -1
                : _quickFilter === 'tou'
                ? key.indexOf('Time of Use') !== -1
                : _quickFilter === 'prepaid'
                ? key.indexOf('Prepaid') !== -1
                : false;
          });
        }
        break;
    }

    return _selectedRateType;
  }

  function getQuickFilterTermLength(_quickFilter) {
    let _selectedTermLength = {};

    switch (_quickFilter) {
      case 'nocontract':
        if (selectedTermLength) {
          Object.keys(selectedTermLength).map((key) => {
            _selectedTermLength[key] = key === '1 month';
          });
        }
        break;
      case 'short':
        if (selectedTermLength) {
          Object.keys(selectedTermLength).map((key) => {
            const termLength = parseInt(key.split(' ')[0]);
            _selectedTermLength[key] = termLength < 12;
          });
        }
        break;
    }

    return _selectedTermLength;
  }

  return noOffers ? (
    <NoDeregulatedElectric />
  ) : (
    <>
      <div
        className={
          'page-container offer-grid-container' +
          (siteConfig.id === 'mi' && hasIntroText ? ' with-intro-text ' : '') +
          (offersUpdating ? ' updating' : '') +
          (selectedUsage === 500 ||
          selectedUsage === 1000 ||
          selectedUsage === 2000
            ? ' with-advertised'
            : '') +
          (preFilter && !preFilterRemoved ? ' prefilter ' : '')
        }>
        {' '}
        {!preFilter ? (
          <>
            {siteConfig.id === 'mi' ? (
              <h1>
                <TaskMenu section={selectedSection} page />
                <span className="keep-together">
                  Electric Plans for{' '}
                  <strong>
                    {' '}
                    {serviceAddress &&
                    serviceAddress.Address &&
                    serviceAddress.Address.Zip
                      ? serviceAddress.Address.Zip
                      : 'Texas'}
                  </strong>
                </span>
                {/* <div className="quick-filters">
            <Tabs
              value={quickFilter}
              onChange={(event, newValue) => setQuickFilter(newValue)}
              indicatorColor="primary"
              textColor="primary">
              {quickFilterList.map((_quickFilter) =>
                !_quickFilter.requiredRateType ||
                (selectedRateType &&
                  selectedRateType.hasOwnProperty(
                    _quickFilter.requiredRateType
                  )) ? (
                  <Tab value={_quickFilter.value} label={_quickFilter.label} />
                ) : null
              )}
            </Tabs>
          </div> */}
              </h1>
            ) : null}

            {selectedSection &&
            selectedSection.IntroText &&
            siteConfig.id === 'mi' ? (
              <IntroText
                trackAs="electric-rates"
                text={selectedSection.IntroText}
                mobileText={selectedSection.IntroTextMobile}
                onVisibleChange={(visible) => setHasIntroText(visible)}
              />
            ) : null}

            {selectedSection
              ? selectedSection.ServiceList.filter(
                  (service) =>
                    service.ProviderServiceID === 9 &&
                    service.ProviderList.filter(
                      (provider) => provider.IsServiceProofUpload
                    ).length
                ).map((service) => (
                  <div className="upload-proof-button">
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setShowResidentOnboarding(service)}>
                      Upload Proof of Service
                      <ChevronRightIcon />
                    </Button>
                  </div>
                ))
              : null}

            <FeaturedOffersElectric />

            {isLanding &&
            serviceAddress.OfferDictionary &&
            serviceAddress.OfferDictionary.Electric ? (
              <div className="best-header all">
                <h2>
                  {serviceAddress &&
                  serviceAddress.OfferDictionary &&
                  serviceAddress.OfferDictionary.Electric ? (
                    <span>
                      All electric plans for{' '}
                      <strong>
                        {serviceAddress.Address.Address ? (
                          <>
                            <br /> {serviceAddress.Address.Address}{' '}
                            {serviceAddress.Address.Secondary
                              ? serviceAddress.Address.Secondary
                              : ''}
                          </>
                        ) : (
                          serviceAddress.Address.Zip
                        )}
                      </strong>
                    </span>
                  ) : null}
                </h2>

                <p>
                  You can adjust the usage levels used to calculate prices using
                  the blue buttons below.
                </p>
              </div>
            ) : null}
          </>
        ) : null}
        <div className="page">
          {filteredOfferList && offerList.length ? (
            <div className="inner offer-grid electric">
              <FiltersElectric
                filtersExpanded={filtersExpanded}
                usageSetting={usageSetting}
                setUsageSetting={setUsageSetting}
                estimatedUsage={estimatedUsage}
                setEstimatedUsage={setEstimatedUsage}
                selectedUsage={selectedUsage}
                setSelectedUsage={setSelectedUsage}
                serviceAddress={serviceAddress}
                usageHistory={usageHistory}
                setUsageHistory={setUsageHistory}
                setShowUsageHistoryRequest={setShowUsageHistoryRequest}
                lastBillUsage={lastBillUsage}
                setLastBillUsage={setLastBillUsage}
                lastBillAmount={lastBillAmount}
                setLastBillAmount={setLastBillAmount}
                usageHistoryMode={usageHistoryMode}
                setUsageHistoryMode={setUsageHistoryMode}
                setShowUsageHistoryPrompt={setShowUsageHistoryPrompt}
                setLastSelectedUsage={setLastSelectedUsage}
                setFiltersExpanded={setFiltersExpanded}
                allOptionSelected={allOptionSelected}
                selectedRateType={selectedRateType}
                setSelectedRateType={setSelectedRateType}
                selectedTermLength={selectedTermLength}
                setSelectedTermLength={setSelectedTermLength}
                filterCounts={filterCounts}
                selectedProviderList={selectedProviderList}
                setSelectedProviderList={setSelectedProviderList}
                setAllOptionSelected={setAllOptionSelected}
                tooltips={tooltips}
                setFiltersClicked={setFiltersClicked}
                quickFilter={quickFilter}
                setQuickFilter={setQuickFilter}
                quickFilterList={quickFilterList}
                mobile
                offerList={offerList}
              />
              <div className="offer-grid-container-inner">
                {/* {!preFilter || preFilterRemoved ? (
                  <div className="landing-true-price-prompt hand">
                    <p>
                      Want to know a secret? ... The plans with the lowest
                      Advertised Price are not always the best value. Sort by
                      the True Price column below to learn why.
                    </p>
                    <div className="image-container">
                      <img
                        src={withPrefix('/images/arrows/arrow_22-short.svg')}
                        alt="Arrow pointing to True Price column"
                      />
                    </div>
                  </div>
                ) : null} */}

                {preFilter && !preFilterRemoved ? (
                  <div className="landing-true-price-prompt prefilter">
                    The {preFilterLabel} plans listed below are available in{' '}
                    {serviceAddress.Address.City} for the zip code{' '}
                    {serviceAddress.Address.Zip}.{' '}
                    <a
                      className="link"
                      onClick={() => setShowAddressSearch(true)}>
                      Change locations <ChevronRightIcon />
                    </a>
                  </div>
                ) : null}

                <div className="offer-row-header">
                  <div className={'offer-row header'}>
                    <div className="brand">
                      <div className="content">
                        Choose your priority <ArrowRightAltIcon />
                      </div>
                    </div>
                    <div className="primary-data">
                      {/* <div className="data-point details rate-type">
                      <Button onClick={() => sortOffers('RateType')}>
                        <SortIcon sortColumn={sortColumn[usageSetting]} column="RateType" />
                        Rate Type
                      </Button>
                    </div> */}
                      <div className="data-point term-length">
                        <Button
                          onClick={() => {
                            setFiltersClicked(true);
                            sortOffers('TermLength');
                            if (!showTermTypePopup) {
                              setShowTermTypePopup({ show: true });
                            }
                          }}
                          variant={
                            sortColumn &&
                            sortColumn[usageSetting] &&
                            sortColumn[usageSetting].indexOf('TermLength') !==
                              -1
                              ? 'contained'
                              : 'outlined'
                          }
                          color="primary">
                          <SortIcon
                            sortColumn={sortColumn[usageSetting]}
                            usageSetting={usageSetting}
                            column="TermLength"
                          />
                          Term Type
                          <Tooltip title={tooltips.TermType} color="primary">
                            <InfoIcon className="tooltip" />
                          </Tooltip>
                        </Button>
                      </div>

                      {usageSetting === 'lastBill' ? (
                        <>
                          <div className="data-point price">
                            <Button
                              onClick={() => {
                                setFiltersClicked(true);
                                sortOffers('SingleMonthPriceKWh');
                              }}
                              variant={
                                sortColumn &&
                                sortColumn[usageSetting] &&
                                sortColumn[usageSetting].indexOf(
                                  'SingleMonthPriceKWh'
                                ) !== -1
                                  ? 'contained'
                                  : 'outlined'
                              }
                              color="primary">
                              <SortIcon
                                sortColumn={sortColumn[usageSetting]}
                                column="SingleMonthPriceKWh"
                              />
                              Current Plan
                            </Button>
                          </div>
                          <div className="data-point price">
                            <Button
                              onClick={() => {
                                setFiltersClicked(true);
                                sortOffers('SingleMonthPriceDollars');
                              }}
                              variant={
                                sortColumn &&
                                sortColumn[usageSetting] &&
                                sortColumn[usageSetting].indexOf(
                                  'SingleMonthPriceDollars'
                                ) !== -1
                                  ? 'contained'
                                  : 'outlined'
                              }
                              color="primary">
                              <SortIcon
                                sortColumn={sortColumn[usageSetting]}
                                column="SingleMonthPriceDollars"
                              />
                              This Plan
                              <Tooltip
                                title={
                                  'The monthly amount this plan will cost based on your usage estimated at ' +
                                  (lastBillUsage
                                    ? lastBillUsage + ' kWh'
                                    : ' the usage from your last bill')
                                }
                                color="primary">
                                <InfoIcon className="tooltip" />
                              </Tooltip>
                            </Button>
                          </div>
                          <div className="data-point price">
                            <Button
                              onClick={() => {
                                setFiltersClicked(true);
                                sortOffers('AmountSaved');
                              }}
                              variant={
                                sortColumn &&
                                sortColumn[usageSetting] &&
                                sortColumn[usageSetting].indexOf(
                                  'AmountSaved'
                                ) !== -1
                                  ? 'contained'
                                  : 'outlined'
                              }
                              color="primary">
                              <SortIcon
                                sortColumn={sortColumn[usageSetting]}
                                column="AmountSaved"
                              />
                              Your Savings
                              <Tooltip
                                title={
                                  'The amount you will save with this plan compared to your last bill amount' +
                                  (lastBillAmount
                                    ? ' of $' + lastBillAmount.toFixed(2)
                                    : '')
                                }
                                color="primary">
                                <InfoIcon className="tooltip" />
                              </Tooltip>
                            </Button>
                          </div>
                          {/* <div className="data-point price">
                          <Button onClick={() => sortOffers('AmountSaved')}>
                            <SortIcon
                              sortColumn={sortColumn[usageSetting]}
                              column="AmountSaved"
                            />
                            Monthly Savings
                            <Tooltip
                              title={
                                'The amount you will save with this plan compared to your last bill amount of $' +
                                lastBillAmount.toFixed(2)
                              }
                              color="primary">
                              <InfoIcon className="tooltip" />
                            </Tooltip>
                          </Button>
                        </div>
                        <div className="data-point price">
                          <Button onClick={() => sortOffers('AmountSaved')}>
                            <SortIcon
                              sortColumn={sortColumn[usageSetting]}
                              column="AmountSaved"
                            />
                            Percent Savings
                            <Tooltip
                              title={
                                'The percentage you will save compared to your last bill amount of $' +
                                lastBillAmount.toFixed(2)
                              }
                              color="primary">
                              <InfoIcon className="tooltip" />
                            </Tooltip>
                          </Button>
                        </div> */}
                        </>
                      ) : (
                        <>
                          <div className="data-point term-length">
                            <Button
                              variant={
                                sortColumn &&
                                sortColumn[usageSetting] &&
                                sortColumn[usageSetting].indexOf(
                                  'RenewableContent'
                                ) !== -1
                                  ? 'contained'
                                  : 'outlined'
                              }
                              color="primary"
                              onClick={() => {
                                setFiltersClicked(true);
                                sortOffers('-RenewableContent');
                              }}>
                              <SortIcon
                                sortColumn={sortColumn[usageSetting]}
                                usageSetting={usageSetting}
                                column="RenewableContent"
                              />
                              % Renewable
                            </Button>
                          </div>

                          <div className="data-point price true-price">
                            <Button
                              variant={
                                sortColumn &&
                                sortColumn[usageSetting] &&
                                sortColumn[usageSetting].indexOf(
                                  'TrueCost_PerKWh'
                                ) !== -1
                                  ? 'contained'
                                  : 'outlined'
                              }
                              color="primary"
                              onClick={() => {
                                setFiltersClicked(true);
                                sortOffers('TrueCost_PerKWh');
                                setTruePriceClicked(true);
                              }}>
                              <SortIcon
                                sortColumn={sortColumn[usageSetting]}
                                column="TrueCost_PerKWh"
                              />
                              True Price
                              <Tooltip
                                title={tooltips.TruePrice}
                                color="primary">
                                <InfoIcon className="tooltip" />
                              </Tooltip>
                            </Button>
                          </div>

                          <div className="data-point price estimated-bill">
                            <Button
                              variant={
                                sortColumn &&
                                sortColumn[usageSetting] &&
                                sortColumn[usageSetting].indexOf(
                                  'SingleMonthPriceDollars'
                                ) !== -1
                                  ? 'contained'
                                  : 'outlined'
                              }
                              color="primary"
                              onClick={() => {
                                setFiltersClicked(true);
                                sortOffers('SingleMonthPriceDollars');
                              }}>
                              <SortIcon
                                sortColumn={sortColumn[usageSetting]}
                                column="SingleMonthPriceDollars"
                              />
                              Estimated Bill
                            </Button>
                          </div>

                          <div className="data-point price advertised">
                            <Button
                              variant={
                                sortColumn &&
                                sortColumn[usageSetting] &&
                                sortColumn[usageSetting].indexOf(
                                  'SingleMonthPriceKWh'
                                ) !== -1
                                  ? 'contained'
                                  : 'outlined'
                              }
                              color="primary"
                              onClick={() => {
                                setFiltersClicked(true);
                                sortOffers('SingleMonthPriceKWh');
                              }}>
                              <SortIcon
                                sortColumn={sortColumn[usageSetting]}
                                column="SingleMonthPriceKWh"
                              />
                              <span className="desktop">Advertised Price</span>
                              <span className="mobile">Price per kWh</span>
                              <Tooltip
                                title={tooltips.AdvertisedPrice}
                                color="primary">
                                <InfoIcon className="tooltip" />
                              </Tooltip>
                            </Button>
                          </div>
                          {/* <div className="data-point price">
                          <Button
                            onClick={() => sortOffers('TrueCost_Dollars')}>
                            <SortIcon
                              sortColumn={sortColumn[usageSetting]}
                              column="TrueCost_Dollars"
                            />
                            True Cost
                            <Tooltip title={tooltips.TrueCost} color="primary">
                              <InfoIcon className="tooltip" />
                            </Tooltip>
                          </Button>
                        </div> */}
                        </>
                      )}
                    </div>
                    <div className="action">
                      <div className="content">
                        <Button
                          variant={
                            sortColumn &&
                            sortColumn[usageSetting] &&
                            sortColumn[usageSetting].indexOf(
                              'SingleMonthPriceKWh'
                            ) !== -1
                              ? 'contained'
                              : 'outlined'
                          }
                          color="primary"
                          onClick={() => {
                            setFiltersClicked(true);
                            sortOffers('SingleMonthPriceKWh');
                          }}>
                          <SortIcon
                            sortColumn={sortColumn[usageSetting]}
                            column="SingleMonthPriceKWh"
                          />
                          <span className="desktop">Advertised Price</span>
                          <Tooltip
                            title={tooltips.AdvertisedPrice}
                            color="primary">
                            <InfoIcon className="tooltip" />
                          </Tooltip>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="offerRowStart"></div>

                {isLanding && sortColumn[usageSetting] === 'TrueCost_PerKWh' ? (
                  <SCLandingInline truePrice />
                ) : null}

                {showTrueCostExplanation && usageSetting === 'estimated' ? (
                  <div className="hand true-cost-explanation">
                    <div className="arrow">
                      {/* <div className="tip"></div> */}
                      <img
                        src={withPrefix('/images/arrows/arrow_04-long.svg')}
                        alt="Arrow pointing to True Cost explanation"
                      />
                    </div>
                    <Button
                      className="close"
                      onClick={() => {
                        setShowTrueCostExplanation(false);
                        dataApi.setCookie('mi-hide-true-cost-explanation', '1');
                      }}>
                      <CloseIcon />
                    </Button>
                    <p>
                      The advertised prices found on the Electricity Facts Label
                      (EFL) can sometimes be misleading because they only show
                      you what you’ll pay at exactly those usage points. Use
                      more or less than that exact usage and your price can go
                      up, sometimes significantly.
                    </p>
                    <p>
                      To help you better understand each plan's actual pricing
                      we’ve converted those advertised prices into what we call
                      a “True Price”.{' '}
                    </p>
                    <p>
                      The True Price column shows you what the average price
                      will be for that plan over a 12-month period after taking
                      seasonal usage fluctuations into consideration. This True
                      Price is a much better indicator of what you’ll actually
                      pay than the prices advertised by providers.
                    </p>
                  </div>
                ) : null}

                {usageSetting !== 'lastBill' ||
                (lastBillAmount && lastBillUsage) ? (
                  <>
                    {bestOffer ? (
                      <OfferRowElectric
                        key={'best-offer-' + bestOffer.OfferID}
                        offer={bestOffer}
                        offerDictionary={offerDictionary}
                        setOfferDictionary={setOfferDictionary}
                        selectedUsage={selectedUsage}
                        usageSetting={usageSetting}
                        estimatedUsage={estimatedUsage}
                        usageHistory={usageHistory}
                        setShowUsageHistoryPrompt={setShowUsageHistoryPrompt}
                        setShowUsageHistoryRequest={setShowUsageHistoryRequest}
                        lastBillAmount={lastBillAmount}
                        lastBillUsage={lastBillUsage}
                        tooltips={tooltips}
                        // bannerText={
                        //   <>
                        //     Best Deal
                        //     {sortColumn[usageSetting] !== 'TrueCost_Dollars' &&
                        //     sortColumn[usageSetting] !== 'TrueCost_PerKWh' &&
                        //     usageSetting !== 'lastBill' ? (
                        //       <div className="true-cost-suggestion">
                        //         Sort by True Price to see the best deals{' '}
                        //         <SubdirectoryArrowRightIcon />
                        //       </div>
                        //     ) : null}
                        //   </>
                        // }
                        isFeatured
                        highlightPriceColumn={highlightPriceColumn}
                        truePriceClicked={truePriceClicked}
                      />
                    ) : null}

                    {filteredOfferList.map((offer, offerIndex) =>
                      !bestOffer ||
                      bestOffer.OfferID !== offer.OfferID ||
                      offerIndex > 0 ? (
                        <>
                          <div className="hide-mobile">
                            <OfferRowElectric
                              key={offer.OfferID}
                              offer={offer}
                              offerDictionary={offerDictionary}
                              setOfferDictionary={setOfferDictionary}
                              selectedUsage={selectedUsage}
                              usageSetting={usageSetting}
                              estimatedUsage={estimatedUsage}
                              usageHistory={usageHistory}
                              setShowUsageHistoryPrompt={
                                setShowUsageHistoryPrompt
                              }
                              setShowUsageHistoryRequest={
                                setShowUsageHistoryRequest
                              }
                              lastBillAmount={lastBillAmount}
                              lastBillUsage={lastBillUsage}
                              highlightPriceColumn={highlightPriceColumn}
                              tooltips={tooltips}
                              truePriceClicked={truePriceClicked}
                            />
                            {isLanding &&
                            sortColumn[usageSetting] !== 'TrueCost_PerKWh' &&
                            offerIndex === 3 ? (
                              <SCLandingInline />
                            ) : null}
                          </div>

                          <div className="mobile-only">
                            <FeaturedOffer
                              offer={offer}
                              priceColumn={sortColumn[usageSetting]}
                              usage={selectedUsage}
                              usageSetting={usageSetting}
                            />
                          </div>
                        </>
                      ) : null
                    )}
                  </>
                ) : (
                  <div className="empty">
                    Enter your{' '}
                    <a
                      className="link"
                      onClick={() => {
                        setFiltersExpanded(true);
                        setTimeout(() => {
                          document.getElementById('lastBillAmount').focus();
                        }, 100);
                      }}>
                      last bill amount
                    </a>{' '}
                    and{' '}
                    <a
                      className="link"
                      onClick={() => {
                        setFiltersExpanded(true);
                        setTimeout(() => {
                          document.getElementById('lastBillUsage').focus();
                        }, 100);
                      }}>
                      last usage amount
                    </a>{' '}
                    in the form above to view plans.
                  </div>
                )}
              </div>
              <FiltersElectric
                filtersExpanded={filtersExpanded}
                usageSetting={usageSetting}
                setUsageSetting={setUsageSetting}
                estimatedUsage={estimatedUsage}
                setEstimatedUsage={setEstimatedUsage}
                selectedUsage={selectedUsage}
                setSelectedUsage={setSelectedUsage}
                serviceAddress={serviceAddress}
                usageHistory={usageHistory}
                setUsageHistory={setUsageHistory}
                setShowUsageHistoryRequest={setShowUsageHistoryRequest}
                lastBillUsage={lastBillUsage}
                setLastBillUsage={setLastBillUsage}
                lastBillAmount={lastBillAmount}
                setLastBillAmount={setLastBillAmount}
                usageHistoryMode={usageHistoryMode}
                setUsageHistoryMode={setUsageHistoryMode}
                setShowUsageHistoryPrompt={setShowUsageHistoryPrompt}
                setLastSelectedUsage={setLastSelectedUsage}
                setFiltersExpanded={setFiltersExpanded}
                allOptionSelected={allOptionSelected}
                selectedRateType={selectedRateType}
                setSelectedRateType={setSelectedRateType}
                selectedTermLength={selectedTermLength}
                setSelectedTermLength={setSelectedTermLength}
                filterCounts={filterCounts}
                selectedProviderList={selectedProviderList}
                setSelectedProviderList={setSelectedProviderList}
                setAllOptionSelected={setAllOptionSelected}
                tooltips={tooltips}
                setFiltersClicked={setFiltersClicked}
                quickFilter={quickFilter}
                setQuickFilter={setQuickFilter}
                quickFilterList={quickFilterList}
                offerList={offerList}
              />
            </div>
          ) : !webSocket || !webSocket.pageLoading ? (
            <div className="loading">
              <CircularProgress />
              <br />
              <br />
              Loading plans...
            </div>
          ) : null}
        </div>
      </div>

      {/* Commented out until we figure out how to present this or remove it for customers <UsageHistoryRequest
        usageHistory={usageHistory}
        setUsageHistory={setUsageHistory}
        setShowUsageHistoryPrompt={setShowUsageHistoryPrompt}
        showUsageHistoryRequest={showUsageHistoryRequest}
        setShowUsageHistoryRequest={setShowUsageHistoryRequest}
      /> */}
      <UsageHistoryEntry
        showUsageHistoryPrompt={showUsageHistoryPrompt}
        setShowUsageHistoryPrompt={setShowUsageHistoryPrompt}
        usageHistory={usageHistory}
        setUsageHistory={setUsageHistory}
      />

      {showTermTypePopup && showTermTypePopup.show ? (
        <TermTypePopup
          closeFunc={() => {
            setShowTermTypePopup({ show: false });
          }}
        />
      ) : null}

      {showResidentOnboarding ? (
        <ResidentOnboardingPopup
          providerServiceList={[showResidentOnboarding]}
          closeFunc={() => setShowResidentOnboarding(null)}
        />
      ) : null}

      {showAddressSearch ? (
        <AddressSearchPopup
          mode="zip"
          hideSearchBy={true}
          closeFunc={() => setShowAddressSearch(null)}
          title={
            preFilterLabel
              ? 'Compare ' + preFilterLabel + ' Plans'
              : 'Compare Rates'
          }
          afterAddressSelected={() => {
            //do nothing, stay here
            setShowAddressSearch(null);
          }}
        />
      ) : null}
    </>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferGridElectric);
