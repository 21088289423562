import React, { useState, useRef, useEffect } from 'react';

import { Button, AppBar, LinearProgress } from '@mui/material';
import { navigate } from 'gatsby-link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import * as dataApi from '../../api/dataApi';
import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as offerCalc from '../../api/offerCalc';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import './index.css';

import StepPlanBuilder from './StepPlanBuilder';
import IntroText from '../IntroText';
import DemoModePopup from '../Dashboard/DemoModePopup';
import OrderSummaryInternet from './OrderSummaryInternet';
import TaskMenu from '../TaskMenu';

import FeaturedOfferInternet from '../OfferGrid/FeaturedOfferInternet';
import ResidentInternetHeader from '../ResidentInternetHeader';

function getOfferOption(providerService, offerList) {
  let _offerList = [];

  //First include any offers that are standalone plans but are bundleable
  _offerList = _offerList.concat(
    offerList.filter(
      (offer) =>
        offer.OfferTypeID === 1 && offer.ProviderServiceList === providerService
      //&&
      // checkIfValidBundleOffer(providerService, offer)
    )
  );

  let _selectedOfferID = null;

  _offerList = _offerList.map((offer) => getOfferSettings(offer));

  let _shortDirections;
  let _longDirections;
  let _offsiteSignUpOfferList = [];
  let _satelliteOfferList = [];

  switch (providerService) {
    case 'Internet':
      _shortDirections = " - Choose the plan that's best for you";
      // _longDirections =
      //   'For the sake of transparency and fairness we are showing you the all-inclusive pricing for each plan below. If the provider offers better pricing as part of a contract, you\'ll see a "Contract Discount" option you may check.';
      _offsiteSignUpOfferList = offerList.filter(
        (offer) =>
          offer.OfferStatusID === 5 &&
          offer.OfferID < 0 &&
          offer.ProviderServiceList.indexOf('Internet') !== -1 &&
          offer.ProviderServiceList.indexOf('Satellite') === -1
      );

      _satelliteOfferList = offerList.filter(
        (offer) =>
          offer.OfferStatusID === 5 &&
          offer.OfferID < 0 &&
          offer.ProviderServiceList.indexOf('Satellite') !== -1
      );

      break;
    case 'TV':
      _shortDirections = ' - Add a TV/Entertainment Package';
      _longDirections =
        'The prices shown below include required surcharges such as ‘Broadcast TV Fees’ and ‘Regional Sports Fees’ that are often left out of the advertised prices on other sites.';
      break;
    case 'Phone':
      _shortDirections = ' - Choose your landline phone service';
      break;
    case 'Home Security':
      _shortDirections = ' - Choose your home security package';
      break;
    default:
      _shortDirections = '';
      break;
  }

  return {
    shortDirections: _shortDirections,
    longDirections: _longDirections,
    offsiteSignUpOfferList: _offsiteSignUpOfferList,
    satelliteOfferList: _satelliteOfferList,

    offerList: _offerList
  };
}

export function getOfferSettings(offer) {
  let chargeDictionary = offer ? { ...offer.chargeDictionary } : null;

  if (chargeDictionary) {
    Object.keys(chargeDictionary).map((chargeGroup) => {
      if (
        chargeDictionary[chargeGroup].chargeList &&
        chargeDictionary[chargeGroup].chargeList.length &&
        chargeGroup
      ) {
        if (
          chargeDictionary[chargeGroup].isRequired &&
          chargeDictionary[chargeGroup].chargeList.length === 1
        ) {
          if (chargeDictionary[chargeGroup].isRequired) {
            chargeDictionary[chargeGroup].selectedValue =
              chargeDictionary[chargeGroup].chargeList[0].name;
          } else {
            chargeDictionary[chargeGroup].selectedValue = 'No ' + chargeGroup;
          }
        }
        chargeDictionary[chargeGroup].selectedItemCount = 1;
      } else if (!chargeGroup) {
        chargeDictionary[chargeGroup].selectedValueList = {};
        chargeDictionary[chargeGroup].chargeList
          .filter(
            (addOn) =>
              (addOn.monthlyAmount === 0 && !addOn.standardPrice) ||
              addOn.isRequired ||
              addOn.includedInPrice
          )
          .map((addOn) => {
            chargeDictionary[chargeGroup].selectedValueList[addOn.name] = true;
          });
      }
    });
  }

  return {
    ...offer,
    chargeDictionary
  };
}

export function getOfferList(_offerList) {
  return _offerList
    ? _offerList.map((offer) => offerCalc.calcInternetOffer(_offerList, offer))
    : [];
}

export function getOfferOptionConfig(offerList) {
  console.log('getOfferOptionConfig', offerList);
  let _offerOptionDictionary = {
    Internet: getOfferOption('Internet', offerList)
    //TV: getOfferOption('TV', offerList)
    //Phone: getOfferOption('Phone'),
    //'Home Security': getOfferOption('Home Security')
  };

  let _offerOptionSettings = null;
  const cookieSettings = dataApi.getCookie('mi-internet-offer');
  if (cookieSettings) {
    try {
      _offerOptionSettings = JSON.parse(cookieSettings);
    } catch (e) {}
  }

  if (_offerOptionSettings) {
    //Validate the stored order pull from cookie to make sure any selected offers are still available
    Object.keys(_offerOptionDictionary).map((providerService) => {
      if (
        _offerOptionSettings[providerService] &&
        _offerOptionSettings[providerService].selectedOfferID !== null &&
        _offerOptionSettings[providerService].selectedOfferID !== undefined
      ) {
        const _selectedOffer = offerList.filter(
          (offer) =>
            offer.OfferID ===
            _offerOptionSettings[providerService].selectedOfferID
        )[0];

        if (_selectedOffer) {
          _offerOptionDictionary[providerService].selectedOfferID =
            _selectedOffer.OfferID;
          _offerOptionDictionary[providerService].selectedOffer = {
            ..._selectedOffer
          };
          _offerOptionDictionary[providerService].showOptions = true;

          if (_offerOptionSettings[providerService].chargeDictionary) {
            _offerOptionDictionary[
              providerService
            ].selectedOffer.chargeDictionary = {
              ..._offerOptionSettings[providerService].chargeDictionary
            };

            _offerOptionDictionary[providerService].offerList =
              _offerOptionDictionary[providerService].offerList.map((offer) =>
                offer.OfferID === _selectedOffer.OfferID
                  ? {
                      ...offer,
                      chargeDictionary:
                        _offerOptionSettings[providerService].chargeDictionary
                      //expanded: true
                    }
                  : { ...offer }
              );
          }
        } else if (
          _offerOptionSettings[providerService].selectedOfferID === 0
        ) {
          _offerOptionDictionary[providerService].selectedOfferID = 0;
        }
      }
    });
  }

  return {
    options: _offerOptionDictionary,
    settings: _offerOptionSettings
  };
}

export function updateOfferOptionSettings(
  offerOptionSettings,
  offerOptionDictionary
) {
  let _orderDetailToSave = {};
  Object.keys(offerOptionDictionary).map((providerService) => {
    if (
      offerOptionDictionary[providerService].selectedOfferID !== null &&
      offerOptionDictionary[providerService].selectedOfferID !== undefined
    ) {
      const selectedOffer = offerOptionDictionary[
        providerService
      ].offerList.filter(
        (offer) =>
          offer.OfferID ===
          offerOptionDictionary[providerService].selectedOfferID
      )[0];
      _orderDetailToSave[providerService] = {
        selectedOfferID: selectedOffer ? selectedOffer.OfferID : null,
        chargeDictionary: selectedOffer ? selectedOffer.chargeDictionary : null
      };
    }
  });

  return { ...offerOptionSettings, ..._orderDetailToSave };
}

function PlanBuilder({ serviceAddress, modal }) {
  const [offerList, setOfferList] = useState(null);
  const [offerOptionDictionary, setOfferOptionDictionary] = useState(null);
  const [offerOptionSettings, setOfferOptionSettings] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [orderDetailsExpanded, setOrderDetailsExpanded] = useState(null);
  const [activeCartItem, setActiveCartItem] = useState(null);
  const [grandTotal, setGrandTotal] = useState(null);
  const [showDemoModePopup, setShowDemoModePopup] = useState(null);
  const [checklistSection, setChecklistSection] = useState(null);
  const [featuredPlanList, setfeaturedPlanList] = useState(null);
  const [selectedFeaturedPlan, setSelectedFeaturedPlan] = useState(null);
  const [internetIncludedInRent, setInternetIncludedInRent] = useState(null);
  const [lastOfferExtraction, setLastOfferExtraction] = useState(null);
  const [preferredProviderDictionary, setPreferredProviderDictionary] =
    useState(null);

  useEffect(() => {
    if (serviceAddress && serviceAddress.Cart) {
      loadOfferList();

      if (!selectedSection && serviceAddress && serviceAddress.Checklist) {
        const serviceSectionID = 20;
        const _section = serviceAddress.Checklist.filter(
          (section) => section.ServiceSectionID === serviceSectionID
        )[0];
        const _checklistSection = serviceAddress.Checklist.filter(
          (section) => section.ServiceSectionID === 2
        )[0];

        setSelectedSection(_section);
        setChecklistSection(_checklistSection);
      }

      setActiveCartItem(
        serviceAddress.Cart.filter((cartItem) => cartItem.ProductTypeID === 1)
      );
    }

    if (
      serviceAddress &&
      serviceAddress.lastOfferExtraction &&
      serviceAddress.lastOfferExtraction !== lastOfferExtraction
    ) {
      setLastOfferExtraction(serviceAddress.lastOfferExtraction);

      //Decided not to do anything here to refresh offers, since the code above this section already reloads the offers when the service address updates
    }
  }, [serviceAddress]);

  useEffect(() => {
    if (offerList) {
      const offerOptions = getOfferOptionConfig(offerList);

      setOfferOptionSettings(offerOptions.settings);
      setOfferOptionDictionary(offerOptions.options);
    }
  }, [offerList]);

  useEffect(() => {
    if (offerOptionSettings) {
      dataApi.setCookie(
        'mi-internet-offer',
        JSON.stringify(offerOptionSettings),
        12
      );
    }
  }, [offerOptionSettings]);

  useEffect(() => {
    let _featuredPlanList = [];
    if (offerList) {
      const featuredOfferCategory = {
        'Most Popular Internet Plan': ['Internet 1000 (Fiber 1 GIG)'],
        'Fastest Internet Speed': ['Internet 5000 (Fiber 5 GIG)'],
        'Most Popular TV Plan': ['CHOICE'],
        'Popular Bundle': ['Internet 1000 (Fiber 1 GIG)', 'CHOICE']
      };

      const colorList = ['#ed7d31', '#0070c0', '#00b050', '#7030a0'];

      Object.keys(featuredOfferCategory).map((key) => {
        let _featuredPlan = {
          label: key,
          price: 0,
          color: colorList[_featuredPlanList.length],
          offerList: []
        };

        featuredOfferCategory[key].map((offerName) => {
          const offer = offerList.filter(
            (_offer) => _offer.OfferName === offerName
          )[0];
          if (offer) {
            _featuredPlan.offerList.push({ ...offer });
            _featuredPlan.price +=
              offerCalc.getInternetPrice(offer).monthlyAmount;
          }
        });

        if (
          _featuredPlan.offerList.length === featuredOfferCategory[key].length
        ) {
          _featuredPlanList.push(_featuredPlan);
        }
      });
    }

    setfeaturedPlanList(
      _featuredPlanList.length === 4 ? _featuredPlanList : []
    );
  }, [offerList]);

  function loadOfferList() {
    if (
      serviceAddress &&
      serviceAddress.Address &&
      serviceAddress.Address.ServiceAddressID
    ) {
      dataApi
        .listOffer(serviceAddress.Address.ServiceAddressID)
        .then((_offerList) => {
          const newOfferList = getOfferList(_offerList);
          if (offerCalc.checkOffersChanged(newOfferList, offerList)) {
            setOfferList(newOfferList);
          }
        });
    }
  }

  return (
    <>
      <div className="page-container checkout-container plan-builder-container undocked with-address internet product-type-1">
        {/* {selectedSection ? (
          <h1>
            {checklistSection ? (
              <TaskMenu section={checklistSection} page />
            ) : null}
            {selectedSection.Title}
          </h1>
        ) : null}

        {selectedSection && selectedSection.IntroText ? (
          <IntroText
            trackAs="internet-checkout"
            text={selectedSection.IntroText}
            mobileText={selectedSection.IntroTextMobile}
          />
        ) : null} */}

        {offerList ? (
          <>
            {serviceAddress &&
            serviceAddress.Address &&
            serviceAddress.Address.ResidentID ? (
              <ResidentInternetHeader
                setInternetIncludedInRent={setInternetIncludedInRent}
                setPreferredProviderDictionary={setPreferredProviderDictionary}
              />
            ) : featuredPlanList && featuredPlanList.length ? (
              <div className="featured-offer-list internet">
                {featuredPlanList.map((featuredPlan) => (
                  <FeaturedOfferInternet
                    featuredPlan={featuredPlan}
                    onOrderButtonClick={(event) => {
                      setSelectedFeaturedPlan(featuredPlan);
                    }}
                  />
                ))}
              </div>
            ) : null}

            {!internetIncludedInRent ? (
              <>
                {serviceAddress && activeCartItem && offerOptionDictionary ? (
                  // &&
                  // Object.keys(offerOptionDictionary).filter(
                  //   (providerService) =>
                  //     offerOptionDictionary[providerService].selectedOffer
                  // ).length > 0
                  <AppBar
                    color="default"
                    position="sticky"
                    className={
                      'order-summary' +
                      (orderDetailsExpanded ? ' expanded' : '')
                    }>
                    {activeCartItem ? (
                      <div>
                        {/* <h2 className="title">
                  <Button
                    onClick={() =>
                      setOrderDetailsExpanded(!orderDetailsExpanded)
                    }>
                    Order Summary
                    <KeyboardArrowDownIcon />
                  </Button>
                </h2> */}

                        <OrderSummaryInternet
                          offerOptionDictionary={offerOptionDictionary}
                          grandTotal={grandTotal}
                          setGrandTotal={setGrandTotal}
                          isProductPage
                        />
                      </div>
                    ) : null}
                  </AppBar>
                ) : null}

                <div className="checkout">
                  <StepPlanBuilder
                    offerOptionSettings={offerOptionSettings}
                    setOfferOptionSettings={setOfferOptionSettings}
                    offerOptionDictionary={offerOptionDictionary}
                    setOfferOptionDictionary={setOfferOptionDictionary}
                    offerList={offerList}
                    getOfferSettings={getOfferSettings}
                    selectedFeaturedPlan={selectedFeaturedPlan}
                    preferredProviderDictionary={preferredProviderDictionary}
                  />
                </div>

                {grandTotal ? (
                  <div className="grand-total-container">
                    <div className="grand-total">
                      <div>
                        <div className="total">
                          <div>
                            <em>Total Monthly Payment</em>$
                            {grandTotal.monthly.toFixed(2)}
                          </div>
                        </div>
                        <div className="total button">
                          <Button
                            variant="contained"
                            className="continue-button"
                            color="secondary"
                            disabled={!grandTotal.monthly}
                            onClick={() => {
                              if (modal) {
                                setShowDemoModePopup(true);
                              } else {
                                navigate('/internet-checkout');
                              }
                            }}>
                            <span className="hide-mobile"> Save and&nbsp;</span>
                            Continue
                            <ChevronRightIcon />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}
          </>
        ) : (
          <div style={{ minHeight: '60vh' }}>
            <LinearProgress />
          </div>
        )}
      </div>
      {showDemoModePopup ? (
        <DemoModePopup closeFunc={() => setShowDemoModePopup(null)} />
      ) : null}
    </>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    stateList: state.commonData.stateList,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      saveOrder: bindActionCreators(serviceAddressActions.saveOrder, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanBuilder);
