import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby';

import { IconButton, TextField, Tooltip, Alert } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import { getReportPath } from '../ResidentForm/ResidentFormReport';

import ResidentOnboardingPopup from '../ResidentOnboarding/ResidentOnboardingPopup';
import ResidentFormPopup from '../ResidentForm/ResidentFormPopup';
import GeneralPopup from '../GeneralPopup';

import './index.css';

function NoticeItemCard({
  totalItems,
  item,
  residentOnboarding,
  actions,
  webSocket
}) {
  const [steps, setSteps] = useState(null);
  const [showResidentOnboarding, setShowResidentOnboarding] = useState(null);
  const [showCalendar, setShowCalendar] = useState(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [showResidentForm, setShowResidentForm] = useState(null);
  const [showPrimaryResidentNotice, setShowPrimaryResidentNotice] =
    useState(null);

  useEffect(() => {
    switch (item.Grouping) {
      case 'reservation-notice':
        setSteps([
          {
            label:
              item.ResidentOnboardingStatusID !== 6
                ? 'Registration Submitted'
                : item.Description,
            action: () => {}
          },
          {
            label:
              item.ResidentOnboardingStatusID !== 6
                ? 'Date/Time Selected'
                : 'Select Date/Time',
            action: () => {}
          },
          {
            label:
              item.ResidentOnboardingStatusID !== 6
                ? 'Property Accepted'
                : 'Pending Property Review'
          },
          { label: 'Reservation Complete' }
        ]);
        break;
      case 'resident-form':
        setSteps([
          {
            label:
              item.ResidentOnboardingStatusNameOverride ||
              item.ResidentOnboardingStatusID !== 1
                ? 'Notice Submitted'
                : item.OnboardingColumn
                ? item.OnboardingColumn
                : item.Description,
            action: () => {}
          },
          item.ResidentFormID === 3
            ? {
                label:
                  item.ResidentOnboardingStatusID !== 1
                    ? item.ResidentOnboardingStatusNameOverride
                      ? item.ResidentOnboardingStatusNameOverride
                      : 'Data Captured Successfully'
                    : 'Capture Data'
              }
            : {
                label:
                  item.ResidentFormID === 7
                    ? item.ResidentOnboardingStatusID !== 1
                      ? 'Date/Time Selected'
                      : 'Select Date/Time'
                    : item.RequireAllOccupants
                    ? item.CountOccupantTotal
                      ? item.ResidentOnboardingStatusID !== 1
                        ? 'All Occupants Signed'
                        : item.ResidentOnboardingStatusNameOverride
                        ? item.ResidentOnboardingStatusNameOverride
                        : item.CountOccupantTotal + 1 + ' Signatures Required'
                      : item.ResidentOnboardingStatusID !== 1
                      ? 'Signed Electronically'
                      : 'Sign Electronically'
                    : (item.ResidentOnboardingStatusID !== 1
                        ? 'Completed by '
                        : 'Waiting on ') + item.OtherSigner
              },
          {
            label:
              item.ResidentOnboardingStatusID > 2
                ? 'Accepted by Property'
                : 'Pending Property Review'
          },
          {
            label:
              item.ResidentFormID === 7
                ? 'Inspection Scheduled'
                : item.Description.split('Written ').join('') + ' Accepted'
          }
        ]);
        break;
    }

    if (item.ResidentOnboardingStatusID === 3) {
      switch (item.Grouping) {
        default:
          setActiveStep(999);
          break;
      }
    } else if (item.ResidentOnboardingStatusID === 2) {
      switch (item.Grouping) {
        default:
          setActiveStep(2);
          break;
      }
    } else if (item.ResidentOnboardingStatusID === 6) {
      switch (item.Grouping) {
        default:
          setActiveStep(0);
          break;
      }
    } else if (item.ResidentOnboardingStatusID === 1) {
      switch (item.Grouping) {
        default:
          if (item.OtherSigner && item.ResidentOnboardingStatusNameOverride) {
            setActiveStep(1);
          } else {
            setActiveStep(0);
          }
          break;
      }
    }
  }, [item, residentOnboarding]);

  function getButtonLabel() {
    let description = item.OnboardingColumn
      ? item.OnboardingColumn
      : item.Description;

    if (
      item.Grouping === 'reservation-notice' &&
      item.ResidentOnboardingStatusID !== 6
    ) {
      return 'Edit Reservation';
    }

    if (item.ResidentOnboardingStatusID === 1 && item.SubmitButtonLabel) {
      return item.SubmitButtonLabel;
    } else if (item.ResidentOnboardingStatusID > 1 && item.EditButtonLabel) {
      return item.EditButtonLabel;
    }

    return (
      (item.ResidentOnboardingStatusID === 3
        ? 'View '
        : item.ResidentOnboardingStatusID === 1
        ? 'Start '
        : item.ResidentOnboardingStatusID === 6
        ? 'Reserve '
        : 'Edit ') + description
    );
  }

  return steps ? (
    <div
      className="notice-item-card-container"
      style={{ width: totalItems > 4 ? '25%' : 100 / totalItems + '%' }}>
      <div className={'notice-item-card'}>
        <div className="card-title">
          <h5>
            {item.OnboardingColumn ? item.OnboardingColumn : item.Description}
          </h5>
        </div>
        <div className="card-icon">
          {item.Grouping === 'resident-form' ? (
            item.ResidentFormID === 5 ? (
              <img src={withPrefix('/images/misc/contract.png')} />
            ) : item.ResidentFormID === 6 ? (
              <img src={withPrefix('/images/misc/forward-address.svg')} />
            ) : item.ResidentFormID === 7 ? (
              <img src={withPrefix('/images/misc/inspection.png')} />
            ) : (
              <img src={withPrefix('/images/misc/rent.png')} />
            )
          ) : item.Grouping === 'reservation-notice' ? (
            <img
              src={
                'https://mi-provider-logo.s3-us-west-1.amazonaws.com/85632_Email_Calen.png'
              }
            />
          ) : null}
        </div>
        <div className="card-description">
          <p>{item.RequiredItemText}</p>
        </div>
        <div className="card-buttons">
          <Box sx={{ maxWidth: 400 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel>{step.label}</StepLabel>
                  {/* {step.action ? (
                    <StepContent>
                      <Box sx={{ mb: 2 }}>
                        <div>
                          <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={step.action}
                            sx={{ mt: 1, mr: 1 }}>
                            Complete Form
                          </Button>
                        </div>
                      </Box>
                    </StepContent>
                  ) : null} */}
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>

        {item.ResidentFormID &&
        (item.ResidentOnboardingStatusNameOverride ||
          item.ResidentOnboardingStatusID > 1) &&
        (!webSocket.invitation ||
          !webSocket.invitation.Role ||
          webSocket.invitation.Role === 'Resident' ||
          item.ResidentOnboardingStatusID > 1) ? (
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            href={getReportPath(
              null,
              null,
              null,
              item.ResidentFormID,
              'Resident',
              true
            )}>
            View Authorization
          </Button>
        ) : (
          <Button
            variant={
              item.ResidentOnboardingStatusID === 1 ||
              item.ResidentOnboardingStatusID === 6
                ? 'contained'
                : 'outlined'
            }
            color={
              item.ResidentOnboardingStatusID === 1 ? 'secondary' : 'primary'
            }
            onClick={() => {
              if (
                item.ResidentOnboardingStatusID === 1 &&
                item.RequireAllOccupants &&
                webSocket.invitation &&
                webSocket.invitation.Role === 'Occupant' &&
                (!item.ResidentOnboardingStatusNameOverride ||
                  !item.MissingOccupantList ||
                  !item.MissingOccupantList.split(',').filter(
                    (_occupantID) =>
                      _occupantID == webSocket.invitation.OccupantID
                  ).length)
              ) {
                setShowPrimaryResidentNotice(true);
              } else if (
                item.ResidentOnboardingStatusID !== 1 &&
                item.Grouping !== 'reservation-notice'
              ) {
                //DO NOTHING - LOCKED
              } else {
                if (item.Grouping === 'resident-form') {
                  setShowResidentForm(true);
                } else {
                  setShowResidentOnboarding({ ...item });
                }
              }
            }}
            disabled={
              item.ResidentOnboardingStatusID !== 1 &&
              item.Grouping !== 'reservation-notice'
            }
            fullWidth>
            {getButtonLabel()}
          </Button>
        )}
      </div>
      {showResidentOnboarding ? (
        <ResidentOnboardingPopup
          otherTasks={showResidentOnboarding.Grouping}
          closeFunc={() => {
            setShowResidentOnboarding(false);
            actions.loadResidentOnboarding();
          }}
        />
      ) : null}
      {showResidentForm ? (
        <ResidentFormPopup
          closeFunc={() => setShowResidentForm(null)}
          item={item}
        />
      ) : null}
      {showPrimaryResidentNotice ? (
        <GeneralPopup
          title={'Action Not Available'}
          message={
            <>
              <p style={{ paddingTop: '20px' }}>
                This action must be initiated by the primary lease holder,{' '}
                {residentOnboarding.Header.FirstName}{' '}
                {residentOnboarding.Header.LastName}.
              </p>
            </>
          }
          closeFunc={() => setShowPrimaryResidentNotice(null)}
        />
      ) : null}
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      ),
      saveResidentOnboarding: bindActionCreators(
        residentOnboardingActions.saveResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NoticeItemCard);
