import React, { useState, useRef, useEffect, version } from 'react';
import {
  Button,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip
} from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby-link';
import { withPrefix } from 'gatsby-link';
import {
  Parallax,
  ParallaxBanner,
  ParallaxProvider
} from 'react-scroll-parallax';
import Alert from '@mui/material/Alert';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Xarrow, { useXarrow, Xwrapper } from 'react-xarrows';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import * as dataApi from '../../api/dataApi';
import * as offerCalc from '../../api/offerCalc';

import ToDoCheckbox from '../ToDoCheckbox';
import SchoolList from '../SchoolList';
import Confetti from '../Confetti';

import ServiceContainer from '../ServiceContainer';
import IntroText from '../IntroText';
import ServiceSectionSpa from './ServiceSectionSpa';
import TaskMenu, { getResidentOnboardingStatus } from '../TaskMenu';
import ApplicantTasks from '../ApplicantTasks';
import ApplicantOtherSigner from '../ApplicantOtherSigner';
import NoticeTasks from '../NoticeTasks';

import '../OfferGrid/index.css';
import './index.css';
import moment from 'moment';
import ResidentOnboardingStatusBadge from '../ResidentOnboarding/ResidentOnboardingStatusBadge';
import { UtilityButtonList } from '../ConnectUtilities/UtilityBox';
import ResidentOnboardingPopup from '../ResidentOnboarding/ResidentOnboardingPopup';
import GeneralPopup from '../GeneralPopup';
import CustomerEventCalendar from '../CustomerEventCalendar';

import { getCookie, getSessionStorage } from '../../api/dataApi';
import { getExportUrl } from '../OnboardingDashboard';
import { saveResidentOnboardingTask } from '../../api/residentOnboardingApi';

const bannerImage = '/images/misc/welcome-home.jpg';
const bannerImageMobile = '/images/misc/welcome-home-mobile.jpg';

const boxStyle = {
  border: 'grey solid 2px',
  borderRadius: '10px',
  padding: '5px'
};

const quickFilterList = [
  { label: 'Recommended Tasks', value: 'all' },
  { label: 'Required Tasks', value: 'required' }
];

function QuestionsSnippet({ serviceAddress, siteConfig, mobile }) {
  return null;
  // <div className={'contact' + (mobile ? ' mobile' : '')}>
  //   <strong>For moving related questions: {siteConfig.email}</strong>
  //   <br />
  //   {serviceAddress &&
  //   serviceAddress.Address &&
  //   serviceAddress.Address.PropertyPhone ? (
  //     <strong>Leasing Office: {serviceAddress.Address.PropertyPhone}</strong>
  //   ) : null}
  // </div>
}

function AlreadyCompletePrompt({
  showAlreadycompleted,
  setShowAlreadyCompleted,
  itemKey,
  item,
  section,
  message,
  onConfirm
}) {
  const [confirming, setConfirming] = useState(null);
  return (
    <div
      key={itemKey}
      className={
        'already-complete-prompt' +
        (showAlreadycompleted && showAlreadycompleted.key === itemKey
          ? ' open'
          : '')
      }>
      <div className="inner-content">
        <p>
          <WarningAmberIcon /> {message}
        </p>
        <Button
          variant="contained"
          disabled={confirming}
          color="secondary"
          onClick={() => {
            setConfirming(true);
            onConfirm().then(() => {
              setShowAlreadyCompleted(null);
              setConfirming(null);
            });
          }}>
          {confirming ? 'Confirming...' : 'Confirm'}
        </Button>
        <Button
          onClick={() => setShowAlreadyCompleted(null)}
          disabled={confirming}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

function Dashboard({
  actions,
  serviceAddress,
  mode,
  siteConfig,
  isConfirmationPage,
  webSocket,
  spaMode,
  continueFromServiceSection,
  continueTitle,
  spaOnSectionChange,
  spaLoadSection,
  residentOnboarding
}) {
  const route = dataApi.getCurrentRoute();
  const updateXarrow = useXarrow();

  const [hoverSection, setHoverSection] = useState(null);
  const overHoverTimeout = useRef();
  const outHoverTimeout = useRef();
  const [showChecklistPrompt, setShowChecklistPrompt] = useState(null);
  const [schoolDictionary, setSchoolDictionary] = useState(null);
  const [electricProvider, setElectricProvider] = useState(null);
  const [checklist, setChecklist] = useState(null);
  const [requiredItemChecklist, setRequiredItemChecklist] = useState(null);
  const [quickFilter, setQuickFilter] = useState('all');
  const [showQuickFiltersMenu, setShowQuickFiltersMenu] = useState(null);
  const [welcome, setWelcome] = useState(null);
  const [section, setSection] = useState(null);
  const [providerGrouping, setProviderGrouping] = useState(null);
  const [checklistProgress, setChecklistProgress] = useState(null);
  const [checklistProgressDelayed, setChecklistProgressDelayed] = useState({});
  const checklistProgressDelayedTimout = useRef();
  const [showTutorial, setShowTutorial] = useState(true);
  const loadingTimeout = useRef();
  const [activeSection, setActiveSection] = useState(null);
  const [showResidentOnboarding, setShowResidentOnboarding] = useState(null);
  const [showCalendar, setShowCalendar] = useState(null);
  const [showAlreadycompleted, setShowAlreadyCompleted] = useState(null);

  useEffect(() => {
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    console.log('requiredItemChecklist', requiredItemChecklist);
  }, [requiredItemChecklist]);

  useEffect(() => {
    let _schoolDictionary = {};
    if (serviceAddress && serviceAddress.SchoolDictionary) {
      for (const gradeLevel in serviceAddress.SchoolDictionary) {
        _schoolDictionary[gradeLevel] = {
          menuOpen: false,
          selectedIndex: 0
        };
      }
    }

    setSchoolDictionary(_schoolDictionary);

    let _electricProvider = null;
    let _requiredItemDictionary = {};

    if (
      serviceAddress &&
      serviceAddress.Checklist &&
      serviceAddress.Address &&
      (!serviceAddress.Address.ResidentID ||
        (residentOnboarding && residentOnboarding.Checklist))
    ) {
      let _checklist = serviceAddress.Checklist.filter(
        (section) =>
          section.ServiceList.filter(
            (service) =>
              !service.HideInDashboard ||
              (spaLoadSection && spaLoadSection === section.ServiceSectionID)
          ).length > 0
      )
        .filter(
          (section) =>
            !continueFromServiceSection ||
            section.ServiceSectionID !== continueFromServiceSection
        )
        .map((section) => {
          let _serviceList = section.ServiceList.filter(
            (service) =>
              !service.HideInDashboard ||
              (spaLoadSection && spaLoadSection === service.ServiceSectionID)
          );

          if (residentOnboarding && residentOnboarding.Checklist) {
            _serviceList = _serviceList
              .map((service) => {
                return {
                  ...service,
                  ProviderList: service.ProviderList.filter((provider) => {
                    let _itemList = residentOnboarding.Checklist.filter(
                      (item) => {
                        if (item.OnboardingSection === 'pending') {
                          if (
                            item.ProviderServiceID ===
                              provider.ProviderServiceID ||
                            (item.ProviderServiceID === 37 &&
                              provider.ProviderServiceID === 11)
                          ) {
                            return true;
                          } else {
                            switch (item.Grouping) {
                              case 'pet':
                                return provider.ProviderServiceID === 44;
                              case 'vehicle':
                                return provider.ProviderServiceID === 43;
                              case 'reservation':
                                return provider.ProviderServiceID === 45;
                            }
                          }
                        } else {
                          return false;
                        }
                      }
                    ).map((item) => {
                      return { ...item, provider };
                    });

                    if (_itemList.length) {
                      _itemList.map((item) => {
                        const itemKey = item.CalendarID
                          ? 'calendar-' + item.CalendarID
                          : item.OnboardingColumn;
                        if (!_requiredItemDictionary[itemKey]) {
                          _requiredItemDictionary[itemKey] = {
                            Title: item.OtherRequiredItemTitle
                              ? item.OtherRequiredItemTitle
                              : item.OnboardingColumn
                              ? item.OnboardingColumn
                              : item.Description,
                            ResidentOnboardingStatusID:
                              item.ResidentOnboardingStatusID === 6
                                ? 1
                                : item.ResidentOnboardingStatusID,
                            ResidentOnboardingStatusName:
                              item.ResidentOnboardingStatusName,
                            ResidentOnboardingStatusNameOverride:
                              item.ResidentOnboardingStatusNameOverride,
                            Route: provider.Route,
                            Description: item.OtherRequiredItemDescription
                              ? item.OtherRequiredItemDescription
                              : 'Your lease requires you to set up ' +
                                item.Description +
                                ' in your name prior to moving in.' +
                                (provider.Route === '/electric-rates'
                                  ? ' Signing up for your electric plan through here will automatically send proof of service documentation to the property.'
                                  : ''),
                            ItemList: [],
                            Grouping: item.Grouping,
                            Service: { ...service }
                          };
                        }

                        if (
                          item.ResidentOnboardingStatusID <
                          _requiredItemDictionary[itemKey]
                            .ResidentOnboardingStatusID
                        ) {
                          _requiredItemDictionary[
                            itemKey
                          ].ResidentOnboardingStatusID =
                            item.ResidentOnboardingStatusID;
                          _requiredItemDictionary[
                            itemKey
                          ].ResidentOnboardingStatusName =
                            item.ResidentOnboardingStatusName;
                          _requiredItemDictionary[
                            itemKey
                          ].ResidentOnboardingStatusNameOverride =
                            item.ResidentOnboardingStatusNameOverride;
                        }

                        _requiredItemDictionary[itemKey].ItemList.push({
                          ...provider,
                          ...item
                        });
                      });
                    }

                    return _itemList.length ? false : true;
                  })
                };
              })
              .filter((service) => service.ProviderList.length);
          }

          let _section = {
            ...section,
            ServiceList: _serviceList
          };

          if (
            _section.Route === '/connect-utilities' ||
            _section.Route === '/electric-rates'
          ) {
            _section.ServiceList = _section.ServiceList.map((service) => {
              let _service = { ...service };
              if (_service.Title === 'Electric') {
                if (isConfirmationPage) {
                  _service.ProviderList = _service.ProviderList.filter(
                    (_provider) =>
                      _provider.ProviderID === isConfirmationPage.ProviderID
                  );
                } else {
                  if (
                    _service.ProviderList &&
                    _service.ProviderList.length === 1
                  ) {
                    _electricProvider = _service.ProviderList[0];
                  }
                }
              }

              return _service;
            });
          }

          return _section;
        })
        .filter(
          (section) =>
            section.ServiceList.filter((service) => service.ProviderList.length)
              .length
        );

      setRequiredItemChecklist(
        Object.keys(_requiredItemDictionary).map((onboardingColumn) => {
          return { ..._requiredItemDictionary[onboardingColumn] };
        })
      );

      setChecklist(_checklist);
    } else {
      setChecklist(null);
    }

    if (_electricProvider) {
      setElectricProvider(_electricProvider);
    } else {
      setElectricProvider(null);
    }

    const time = new Date();
    const hourOfDay = time.getHours();
    let _welcome =
      hourOfDay > 16
        ? 'Good evening'
        : hourOfDay > 11
        ? 'Good afternoon'
        : 'Good morning';

    if (serviceAddress && serviceAddress.Address) {
      if (serviceAddress.Address.FirstName) {
        _welcome += ', ' + serviceAddress.Address.FirstName + '!';
      } else {
        _welcome += '!';
      }

      if (serviceAddress.Address.DefaultStartDate) {
        const defaultStartDate = new Date(
          serviceAddress.Address.DefaultStartDate.split('T')[0]
        );
        if (defaultStartDate && !isNaN(defaultStartDate)) {
          const differenceInDays = moment(defaultStartDate)
            .utc()
            .diff(moment(new Date()), 'days');
          if (differenceInDays > 0) {
            _welcome +=
              ' You have ' +
              differenceInDays.toFixed(0) +
              ' day' +
              (differenceInDays.toFixed(0) == '1' ? '' : 's') +
              ' left before your move.';
          }
        }
      }

      if (serviceAddress.Checklist) {
        const _section = serviceAddress.Checklist.filter(
          (section) => section.ServiceSectionID === 18
        )[0];

        setSection(_section);
      }
    } else {
      _welcome += '!';
    }

    setWelcome(_welcome);
  }, [serviceAddress, isConfirmationPage, residentOnboarding]);

  useEffect(() => {
    if (!spaLoadSection || spaLoadSection.ServiceSectionID !== 5) {
      trySpaLoadSection();
    }
  }, [checklist]);

  useEffect(() => {
    setTimeout(() => {
      updateXarrow();
    }, 300);
  }, [activeSection]);

  useEffect(() => {
    if (spaLoadSection === null) {
      setActiveSection(null);
    }
  }, [spaLoadSection]);

  useEffect(() => {
    if (
      serviceAddress &&
      serviceAddress.Address &&
      serviceAddress.Address.Address &&
      serviceAddress.Checklist
    ) {
      let _checklistProgress = {};

      serviceAddress.Checklist.map((section) => {
        _checklistProgress[section.ServiceSectionID] = section.IsCompleted;
      });

      setChecklistProgress(_checklistProgress);

      if (serviceAddress.Address.ResidentID) {
        actions.loadResidentOnboarding();
      }
    }
  }, [serviceAddress]);

  useEffect(() => {
    if (
      serviceAddress &&
      serviceAddress.Address &&
      serviceAddress.Address.Address &&
      checklistProgress
    ) {
      dataApi.setCookie(
        'mi-checklist-progress',
        JSON.stringify({
          ...checklistProgress,
          ServiceAddress: serviceAddress.Address.Address
        })
      );
    }

    if (checklistProgress) {
      if (checklistProgressDelayedTimout.current) {
        clearTimeout(checklistProgressDelayedTimout.current);
      }

      checklistProgressDelayedTimout.current = setTimeout(() => {
        let _checklistProgressDelayed = {};

        Object.keys(checklistProgress).map((key) => {
          _checklistProgressDelayed[key] = checklistProgress[key];
        });

        setChecklistProgressDelayed(_checklistProgressDelayed);
      }, 800);
    }
  }, [checklistProgress]);

  useEffect(() => {
    actions.mockRouteUpdated(activeSection ? activeSection.Route : null, () =>
      setActiveSection(null)
    );

    // if (activeSection) {
    //   dataApi.scrollToElementInPage(
    //     document.getElementsByClassName('header-container')[0],
    //     0,
    //     false
    //   );
    // }

    if (spaOnSectionChange) {
      if (activeSection && activeSection.Title) {
        spaOnSectionChange(activeSection);
      }
    }
  }, [activeSection]);

  function resizeListener() {
    setTimeout(() => {
      updateXarrow();
    }, 200);
  }

  function trySpaLoadSection() {
    if (checklist) {
      const section = checklist.filter(
        (service) => service.ServiceSectionID === spaLoadSection
      )[0];

      if (section) {
        setActiveSection(section);
      } else {
        setActiveSection(null);
      }
    }
  }

  function getUniqueProviderList(providerList, limit) {
    let dictProvider = {};
    let updatedProviderList = [];

    providerList.map((provider, providerIndex) => {
      if (!dictProvider[provider.DisplayName]) {
        if (providerIndex < limit) {
          updatedProviderList.push(provider);
        }

        dictProvider[provider.DisplayName] = true;
      }
    });

    return updatedProviderList;
  }

  function isRoutableLink(section, service, providerList) {
    return (
      service.OfferCount ||
      providerList.length > 1 ||
      section.Route === '/change-address'
    );
  }

  function trackOffsiteOrder(provider) {
    actions.saveOrder(
      serviceAddress.Address.Address,
      serviceAddress.Address.Secondary,
      serviceAddress.Address.City,
      serviceAddress.Address.State,
      serviceAddress.Address.Zip,
      provider.ProviderID,
      0,
      2,
      provider.ServiceStartUrl,
      null
    );
  }

  function navigateToSection(section, providerList) {
    if (spaMode) {
      setActiveSection({ ...section });
    } else if (section.ServiceSectionID === 2) {
      loadingTimeout.current = setTimeout(() => {
        actions.pageLoading(true, 'Loading...');
      }, 1000);

      actions
        .saveOrder(
          serviceAddress.Address.Address,
          serviceAddress.Address.Secondary,
          serviceAddress.Address.City,
          serviceAddress.Address.State,
          serviceAddress.Address.Zip,
          serviceAddress.DeregulatedElectric
            ? serviceAddress.DeregulatedElectric.DeregulatedAddressID
            : null,
          0,
          0,
          1,
          null,
          null,
          null,
          null,
          1
        )
        .then((result) => {
          if (loadingTimeout.current) {
            clearTimeout(loadingTimeout.current);
          }

          actions.pageLoading(false);

          navigate('/plan-builder/');
        });
    } else {
      navigate(
        section.Route +
          (providerList && providerList.length && providerList[0].RequestParams
            ? providerList[0].RequestParams
            : '')
      );
    }
  }

  function getSectionLogo(section) {
    switch (section.Route) {
      case '/connect-utilities':
        return 'stock-vector-utility-services-set-with-flat-icons-of-community-facilities-infrastructure-elements-human-character.png';
      case '/plan-builder':
        return 'internet_305333024.png';
      case '/streaming':
        return 'streaming_305333024.png';
      case '/moving-services':
        return 'moving_274240608.png';
      case '/change-address':
        return 'changeaddress_376486785.png';
      default:
        return 'utilities_422750846.png';
    }
  }

  function getOffsiteUrl(providerList) {
    const redirectUrl =
      providerList.length === 1 && providerList[0].ServiceStartUrl
        ? providerList[0].ServiceStartUrl
        : null;

    return redirectUrl ? redirectUrl : null;
  }

  function getSubmittalUrl(item) {
    let invitation = getCookie('mi-invitation');
    let profileKey = '';
    let residentID = null;
    if (!invitation) {
      profileKey = getCookie('mi-profile-key');
      if (profileKey) {
        profileKey = profileKey.split(':')[0];
      }
    }

    if (!residentID && !invitation) {
      try {
        const resident = JSON.parse(getSessionStorage('mi-selected-resident'));
        if (resident && resident.ResidentID) {
          residentID = resident.ResidentID;
        }
      } catch (e) {}
    }

    if (!residentID && residentOnboarding && residentOnboarding.Header) {
      residentID = residentOnboarding.Header.ResidentID;
    }

    return getExportUrl(profileKey, residentID, item, invitation, true);
  }

  return webSocket &&
    webSocket.invitation &&
    webSocket.invitation.Role !== 'Resident' ? (
    residentOnboarding &&
    residentOnboarding.Header &&
    residentOnboarding.Header.ResidentStatusID === 6 ? (
      <NoticeTasks />
    ) : residentOnboarding && residentOnboarding.Header ? (
      <ApplicantOtherSigner />
    ) : null
  ) : serviceAddress &&
    serviceAddress.Address &&
    checklist &&
    requiredItemChecklist ? (
    activeSection ? (
      <ServiceSectionSpa
        activeSection={activeSection}
        closeFunc={() => setActiveSection(null)}
      />
    ) : !serviceAddress.Address.ResidentID || residentOnboarding ? (
      residentOnboarding &&
      residentOnboarding.Header &&
      residentOnboarding.Header.ResidentStatusID === 4 ? (
        <ApplicantTasks />
      ) : residentOnboarding &&
        residentOnboarding.Header &&
        residentOnboarding.Header.ResidentStatusID === 6 ? (
        <NoticeTasks />
      ) : (
        <div
          className={
            'page-container dashboard-container' +
            (continueFromServiceSection ? ' embedded' : '')
          }>
          {!continueFromServiceSection ? (
            <div className="dashboard-header">
              <div className="welcome-banner">
                <ParallaxProvider>
                  <Parallax speed={-16}>
                    <img src={withPrefix(bannerImage)} />
                  </Parallax>
                </ParallaxProvider>

                <div className="content">
                  <h1
                    style={{
                      backgroundImage:
                        'url(' + withPrefix(bannerImageMobile) + ')'
                    }}>
                    <span>
                      Welcome home
                      {serviceAddress &&
                      serviceAddress.Address &&
                      serviceAddress.Address.FirstName
                        ? ', ' + serviceAddress.Address.FirstName + '!'
                        : '!'}
                    </span>
                  </h1>

                  {serviceAddress &&
                  serviceAddress.Address &&
                  serviceAddress.Address.Address ? (
                    <div className="address-info">
                      <div>
                        <div className="title">Your New Address:</div>
                        <div>
                          {serviceAddress.Address.Address}
                          {serviceAddress.Address.Secondary
                            ? ' ' + serviceAddress.Address.Secondary
                            : null}
                          ,{' '}
                          <span style={{ whiteSpace: 'nowrap' }}>
                            {' '}
                            {serviceAddress.Address.City +
                              ', ' +
                              serviceAddress.Address.State +
                              ' ' +
                              serviceAddress.Address.Zip}
                          </span>
                        </div>
                      </div>

                      <div>
                        <div className="title">
                          {serviceAddress.Address.DefaultStartDate
                            ? 'Your Lease Begins:'
                            : ''}
                        </div>
                        <div>
                          {serviceAddress.Address.DefaultStartDate
                            ? moment(
                                serviceAddress.Address.DefaultStartDate
                              ).format('MM/DD/YYYY')
                            : ''}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {section && section.Description ? (
                    <h3>{section.Description}</h3>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div className="inner">
                <QuestionsSnippet
                  serviceAddress={serviceAddress}
                  siteConfig={siteConfig}
                />

                <div className="intro-text-container">
                  {section && section.IntroText ? (
                    <IntroText
                      trackAs="dashboard"
                      text={section.IntroText}
                      mobileText={section.IntroTextMobile}
                    />
                  ) : null}
                </div>

                {checklistProgressDelayed &&
                Object.keys(checklistProgressDelayed).length > 0 &&
                Object.keys(checklistProgressDelayed).filter(
                  (serviceSectionID) =>
                    !checklistProgressDelayed[serviceSectionID]
                ).length === 0 ? (
                  <>
                    <h1>You're all set!</h1>
                    <Confetti />
                  </>
                ) : section ? (
                  <h3 className="get-started">{section.Description}</h3>
                ) : null}
              </div>
            </div>
          ) : continueTitle ? (
            <h2 className="when-you-return direction">{continueTitle}</h2>
          ) : null}
          <div
            className={
              'page dashboard' +
              (quickFilter === 'required' ? ' required-only' : '')
            }>
            {requiredItemChecklist && requiredItemChecklist.length ? (
              <>
                <h5 className="checklist-title required">Required Items</h5>
                <h6 className="checklist-subtitle">
                  Per your lease, the following items must be completed prior to
                  picking up your keys.
                </h6>
                <div
                  className={
                    'checklist required ' +
                    (requiredItemChecklist.length % 2 === 1 ? ' odd' : '')
                  }>
                  {requiredItemChecklist.map((itemGroup, itemGroupIndex) => (
                    <div
                      id={'required-item-' + itemGroupIndex}
                      className={
                        'checklist-item  ' +
                        (itemGroup.ResidentOnboardingStatusID === 1
                          ? ' required'
                          : '') +
                        (itemGroup.Route !== '/electric-rates'
                          ? ' with-bottom-buttons'
                          : ' with-electric') +
                        (' status-' + itemGroup.ResidentOnboardingStatusID) +
                        (' ' + itemGroup.Grouping) +
                        //(itemGroup.expanded ? ' expanded' : '')

                        ' expanded'
                      }>
                      <div className="connector"></div>
                      <div className="inner">
                        <Button
                          href={
                            itemGroup.Grouping === 'reservation'
                              ? ''
                              : itemGroup.ResidentOnboardingStatusID > 1
                              ? getSubmittalUrl(itemGroup.ItemList[0])
                              : itemGroup.Grouping === 'provider-service' &&
                                itemGroup.ItemList[0].ProviderServiceID !== 37
                              ? itemGroup.ItemList[0].ServiceStartUrl
                              : itemGroup.Route
                          }
                          target={
                            itemGroup.ResidentOnboardingStatusID > 1
                              ? '_blank'
                              : itemGroup.ItemList[0].Grouping ===
                                  'provider-service' &&
                                itemGroup.ItemList[0].ProviderServiceID !== 37
                              ? 'mi_offsite'
                              : ''
                          }
                          onClick={(event) => {
                            let preventDefault = true;

                            if (itemGroup.ItemList[0].CalendarID) {
                              setShowCalendar(
                                residentOnboarding.CalendarList.filter(
                                  (calendar) =>
                                    calendar.CalendarID ===
                                    itemGroup.ItemList[0].CalendarID
                                )[0]
                              );
                            } else if (
                              itemGroup.ResidentOnboardingStatusID <= 1
                            ) {
                              let doNav = true;

                              switch (itemGroup.Grouping) {
                                case 'pet':
                                case 'vehicle':
                                  doNav = false;
                                  setShowResidentOnboarding(
                                    itemGroup.ItemList[0]
                                  );
                                  break;
                                case 'provider-service':
                                  switch (
                                    itemGroup.ItemList[0].ProviderServiceID
                                  ) {
                                    case 37:
                                      break;
                                    default:
                                      if (
                                        itemGroup.Route !== '/electric-rates'
                                      ) {
                                        doNav = false;
                                        preventDefault = false;
                                      }
                                      break;
                                  }

                                  break;
                              }

                              if (doNav) {
                                navigateToSection(itemGroup);
                              }
                            } else if (itemGroup.Grouping !== 'reservation') {
                              preventDefault = false;
                            }

                            if (!itemGroup.expanded) {
                              setRequiredItemChecklist(
                                requiredItemChecklist.map(
                                  (_itemGroup, _itemGroupIndex) =>
                                    _itemGroupIndex === itemGroupIndex
                                      ? {
                                          ..._itemGroup,
                                          expanded: !_itemGroup.expanded
                                        }
                                      : { ..._itemGroup }
                                )
                              );
                            }

                            if (preventDefault) {
                              event.preventDefault();
                            }
                          }}></Button>
                        <h2>
                          {/* <Button
                            onClick={() =>
                              setRequiredItemChecklist(
                                requiredItemChecklist.map(
                                  (_itemGroup, _itemGroupIndex) =>
                                    _itemGroupIndex === itemGroupIndex
                                      ? {
                                          ..._itemGroup,
                                          expanded: !_itemGroup.expanded
                                        }
                                      : { ..._itemGroup }
                                )
                              )
                            }
                            className="expand-button">
                            {itemGroup.expanded ? 'Hide' : 'Tap for '} Details{' '}
                            <ExpandMoreIcon />
                          </Button> */}

                          <span className="dyn-desktop-only">
                            {itemGroup.Title}
                          </span>
                          <span className="dyn-mobile-only">
                            {itemGroup.Title}
                          </span>

                          {/* {itemGroup.ResidentOnboardingStatusID === 1 ? (
                            <ResidentOnboardingStatusBadge
                              status={itemGroup}
                              isProperty={false}
                            />
                          ) : null} */}
                        </h2>

                        {itemGroup.ResidentOnboardingStatusID === 1 ? (
                          <>
                            {itemGroup.Route !== '/electric-rates' ? (
                              <div className="required-message">
                                {itemGroup.ItemList[0].ProviderServiceID ==
                                37 ? (
                                  itemGroup.Service.ProviderList.filter(
                                    (provider) => provider.IsProviderPreferred
                                  )
                                    .concat({ ProviderID: 0 })
                                    .filter(
                                      (provider, providerIndex) =>
                                        providerIndex === 0
                                    )
                                    .map((provider) => (
                                      <img
                                        src={
                                          provider.ProviderID
                                            ? provider.LogoFile
                                            : withPrefix(
                                                '/images/misc/insurance.png'
                                              )
                                        }
                                        alt={itemGroup.Title}
                                      />
                                    ))
                                ) : (
                                  <img
                                    src={itemGroup.ItemList[0].LogoFile}
                                    alt={itemGroup.Title}
                                  />
                                )}
                                Action Required
                              </div>
                            ) : (
                              <ul>
                                <li
                                  style={{
                                    width: '100%'
                                  }}>
                                  {[0].map((test) => {
                                    const providerList = getUniqueProviderList(
                                      itemGroup.ItemList,
                                      4
                                    );
                                    return providerList.map(
                                      (item, itemIndex) => (
                                        <div
                                          className="provider-name"
                                          style={{
                                            width:
                                              100 / providerList.length + '%'
                                          }}>
                                          {item.LogoFile ? (
                                            <img
                                              src={item.LogoFile}
                                              className="provider-logo"
                                            />
                                          ) : (
                                            <span>{item.DisplayName}</span>
                                          )}
                                        </div>
                                      )
                                    );
                                  })}
                                </li>
                              </ul>
                            )}
                            <h3 className="dyn-desktop-only">
                              {itemGroup.Description}
                            </h3>
                            <h3 className="dyn-mobile-only">
                              {itemGroup.Description}
                            </h3>
                            {itemGroup.ItemList[0].ProviderServiceID &&
                            itemGroup.ItemList[0].ProviderServiceID != 37 &&
                            itemGroup.Route !== '/electric-rates' ? (
                              <div className="utility-button-list">
                                <UtilityButtonList
                                  provider={itemGroup.ItemList[0].provider}
                                  isTaskCompleted={
                                    itemGroup.ResidentOnboardingStatusID > 1
                                  }
                                  setShowResidentOnboarding={(show) => {
                                    if (show) {
                                      setShowResidentOnboarding(
                                        itemGroup.ItemList[0]
                                      );
                                    } else {
                                      setShowResidentOnboarding(null);
                                    }
                                  }}
                                />
                              </div>
                            ) : itemGroup.ItemList[0].ProviderServiceID ==
                              37 ? (
                              <>
                                <div className="utility-button-list">
                                  <Button
                                    variant="outlined"
                                    onClick={(event) => {
                                      event.preventDefault();
                                      navigateToSection(itemGroup);
                                    }}>
                                    Find a Plan <ChevronRightIcon />
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    onClick={() =>
                                      setShowAlreadyCompleted({
                                        key: 'insurance',
                                        item: itemGroup.ItemList[0]
                                      })
                                    }>
                                    I Already Did This
                                  </Button>
                                  <Button
                                    color="secondary"
                                    variant="outlined"
                                    onClick={() =>
                                      setShowResidentOnboarding(
                                        itemGroup.ItemList[0]
                                      )
                                    }>
                                    Upload Proof of Coverage{' '}
                                    <ChevronRightIcon />
                                  </Button>
                                </div>
                                <AlreadyCompletePrompt
                                  itemKey={'insurance'}
                                  showAlreadycompleted={showAlreadycompleted}
                                  setShowAlreadyCompleted={
                                    setShowAlreadyCompleted
                                  }
                                  message={
                                    'Click the button below to confirm that you already provided your Proof of Coverage information to the property.'
                                  }
                                  onConfirm={() => {
                                    return actions.saveProviderService({
                                      ...itemGroup.Service,
                                      IsAlreadyProvidedChecked: true
                                    });
                                  }}
                                />
                              </>
                            ) : itemGroup.Route === '/electric-rates' ? (
                              <div className="utility-button-list wide">
                                <Button
                                  variant="outlined"
                                  color={
                                    itemGroup.ResidentOnboardingStatusID === 1
                                      ? 'secondary'
                                      : 'primary'
                                  }
                                  href={itemGroup.Route}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    navigateToSection(itemGroup);
                                  }}>
                                  Find Electric Plans <ChevronRightIcon />
                                </Button>
                              </div>
                            ) : itemGroup.Grouping === 'pet' ? (
                              <>
                                <div
                                  className="utility-button-list two"
                                  onClick={(event) => {
                                    setShowResidentOnboarding(
                                      itemGroup.ItemList[0]
                                    );
                                  }}>
                                  <Button
                                    variant="outlined"
                                    color={
                                      itemGroup.ResidentOnboardingStatusID === 1
                                        ? 'secondary'
                                        : 'primary'
                                    }>
                                    {itemGroup.ResidentOnboardingStatusID === 1
                                      ? 'Start'
                                      : 'Edit'}{' '}
                                    Pet Registration <ChevronRightIcon />
                                  </Button>

                                  {!itemGroup.ItemList.filter(
                                    (item) => item.ResidentPetID
                                  ).length ? (
                                    <Button
                                      variant="outlined"
                                      onClick={(event) => {
                                        setShowAlreadyCompleted({
                                          key: 'pet',
                                          item: itemGroup.ItemList[0]
                                        });
                                        event.preventDefault();
                                        event.stopPropagation();
                                      }}>
                                      I Do Not Have a Pet
                                    </Button>
                                  ) : (
                                    <Button variant="outlined">
                                      Add Another Animal <ChevronRightIcon />
                                    </Button>
                                  )}
                                </div>{' '}
                                <AlreadyCompletePrompt
                                  itemKey={'pet'}
                                  showAlreadycompleted={showAlreadycompleted}
                                  setShowAlreadyCompleted={
                                    setShowAlreadyCompleted
                                  }
                                  message={
                                    'Click the button below to confirm that you do not have any pets or animals.'
                                  }
                                  onConfirm={() => {
                                    return actions
                                      .saveResidentOnboarding({
                                        ...residentOnboarding.Header,
                                        HasNoPet: true
                                      })
                                      .then((result) => {
                                        actions.loadResidentOnboarding();
                                        return result;
                                      });
                                  }}
                                />
                              </>
                            ) : itemGroup.Grouping === 'vehicle' ? (
                              <>
                                <div
                                  className="utility-button-list two"
                                  onClick={(event) => {
                                    setShowResidentOnboarding(
                                      itemGroup.ItemList[0]
                                    );
                                  }}>
                                  <Button
                                    variant="outlined"
                                    color={
                                      itemGroup.ResidentOnboardingStatusID === 1
                                        ? 'secondary'
                                        : 'primary'
                                    }>
                                    {itemGroup.ResidentOnboardingStatusID === 1
                                      ? 'Start'
                                      : 'Edit'}{' '}
                                    Vehicle Registration <ChevronRightIcon />
                                  </Button>

                                  {!itemGroup.ItemList.filter(
                                    (item) => item.ResidentPetID
                                  ).length ? (
                                    <Button
                                      variant="outlined"
                                      onClick={(event) => {
                                        setShowAlreadyCompleted({
                                          key: 'vehicle',
                                          item: itemGroup.ItemList[0]
                                        });
                                        event.preventDefault();
                                        event.stopPropagation();
                                      }}>
                                      I Do Not Have a Vehicle
                                    </Button>
                                  ) : (
                                    <Button variant="outlined">
                                      Add Another Vehicle <ChevronRightIcon />
                                    </Button>
                                  )}
                                </div>
                                <AlreadyCompletePrompt
                                  itemKey={'vehicle'}
                                  showAlreadycompleted={showAlreadycompleted}
                                  setShowAlreadyCompleted={
                                    setShowAlreadyCompleted
                                  }
                                  message={
                                    'Click the button below to confirm that you will not be using, parking or storing any vehicles on site.'
                                  }
                                  onConfirm={() => {
                                    return actions
                                      .saveResidentOnboarding({
                                        ...residentOnboarding.Header,
                                        HasNoVehicle: true
                                      })
                                      .then((result) => {
                                        actions.loadResidentOnboarding();
                                        return result;
                                      });
                                  }}
                                />
                              </>
                            ) : itemGroup.Grouping === 'reservation' ? (
                              <div
                                className="utility-button-list two"
                                onClick={(event) => {
                                  setShowCalendar(
                                    residentOnboarding.CalendarList.filter(
                                      (calendar) =>
                                        calendar.CalendarID ===
                                        itemGroup.ItemList[0].CalendarID
                                    )[0]
                                  );
                                }}>
                                <Button
                                  variant="outlined"
                                  color={
                                    itemGroup.ResidentOnboardingStatusID === 1
                                      ? 'secondary'
                                      : 'primary'
                                  }>
                                  {itemGroup.ResidentOnboardingStatusID === 1
                                    ? 'Schedule Appointment'
                                    : 'Edit Reservation'}

                                  <ChevronRightIcon />
                                </Button>

                                {!itemGroup.ItemList[0].RequiredItemText &&
                                itemGroup.ResidentOnboardingStatusID <= 1 ? (
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={(event) => {
                                      setShowAlreadyCompleted({
                                        key:
                                          'calendar-' +
                                          itemGroup.ItemList[0].CalendarID,
                                        item: itemGroup.ItemList[0]
                                      });
                                      event.preventDefault();
                                      event.stopPropagation();
                                    }}>
                                    This Is Not Needed
                                  </Button>
                                ) : null}
                              </div>
                            ) : null}

                            {residentOnboarding.CalendarList.filter(
                              (calendar) =>
                                calendar.CalendarID ===
                                itemGroup.ItemList[0].CalendarID
                            ).map((calendar) => (
                              <AlreadyCompletePrompt
                                itemKey={'calendar-' + calendar.CalendarID}
                                showAlreadycompleted={showAlreadycompleted}
                                setShowAlreadyCompleted={
                                  setShowAlreadyCompleted
                                }
                                message={
                                  'Click the button below to confirm that you will do not need a ' +
                                  calendar.CalendarName +
                                  ' ' +
                                  calendar.Term +
                                  '.'
                                }
                                onConfirm={() => {
                                  return saveResidentOnboardingTask(
                                    residentOnboarding.Header.ResidentID,
                                    'calendar-' + calendar.CalendarID,
                                    5
                                  ).then((result) => {
                                    actions.loadResidentOnboarding();
                                    return result;
                                  });
                                }}
                              />
                            ))}
                          </>
                        ) : (
                          <>
                            {itemGroup.ResidentOnboardingStatusID === 2 ? (
                              <>
                                <div className="required-message">
                                  <img
                                    src={withPrefix(
                                      '/images/misc/processing.png'
                                    )}
                                    alt={itemGroup.Title}
                                  />{' '}
                                  Waiting on Property
                                </div>

                                <h3 className="dyn-desktop-only">
                                  Your information has been sent to the property
                                  for review.
                                </h3>
                                <h3 className="dyn-mobile-only">
                                  Your information has been sent to the property
                                  for review.
                                </h3>
                              </>
                            ) : itemGroup.Grouping === 'reservation' ? (
                              <>
                                <div className="required-message">
                                  <img
                                    src={withPrefix(
                                      '/images/misc/success-check.png'
                                    )}
                                    alt={itemGroup.Title}
                                  />{' '}
                                  {itemGroup.ResidentOnboardingStatusID ===
                                  3 ? (
                                    itemGroup.ItemList[0].ReservationDate ? (
                                      moment(
                                        itemGroup.ItemList[0].ReservationDate
                                      ).format('MM/DD/YYYY') +
                                      (itemGroup.ItemList[0]
                                        .ReservationStartTime
                                        ? ' at ' +
                                          itemGroup.ItemList[0]
                                            .ReservationStartTime
                                        : '')
                                    ) : (
                                      'Reservation Confirmed'
                                    )
                                  ) : (
                                    <>Marked Not Needed</>
                                  )}
                                </div>

                                <h3 className="dyn-desktop-only">
                                  {itemGroup.ItemList[0].RequiredItemText
                                    ? itemGroup.ItemList[0].RequiredItemText
                                    : itemGroup.ResidentOnboardingStatusID === 3
                                    ? 'Your reservation is scheduled. If you need to make a change, use the link below.'
                                    : 'You marked this task as not needed' +
                                      residentOnboarding.TaskList.filter(
                                        (task) => {
                                          return (
                                            task.TaskKey ===
                                              'calendar-' +
                                                itemGroup.ItemList[0]
                                                  .CalendarID &&
                                            task.ResidentOnboardingStatusID ===
                                              5
                                          );
                                        }
                                      ).map(
                                        (task) =>
                                          ' on ' +
                                          moment
                                            .utc(
                                              task.Updated
                                                ? task.Updated
                                                : task.Created
                                            )
                                            .local()
                                            .format('MM/DD/YYYY')
                                      ) +
                                      '. If you need to reserve a time slot, use the link below.'}
                                </h3>
                                <h3 className="dyn-mobile-only">
                                  {itemGroup.ItemList[0].RequiredItemText
                                    ? itemGroup.ItemList[0].RequiredItemText
                                    : itemGroup.ResidentOnboardingStatusID === 3
                                    ? 'Your reservation is scheduled. If you need to make a change, use the link below.'
                                    : 'You marked this task as not needed' +
                                      residentOnboarding.TaskList.filter(
                                        (task) => {
                                          return (
                                            task.TaskKey ===
                                              'calendar-' +
                                                itemGroup.ItemList[0]
                                                  .CalendarID &&
                                            task.ResidentOnboardingStatusID ===
                                              5
                                          );
                                        }
                                      ).map(
                                        (task) =>
                                          ' on ' +
                                          moment
                                            .utc(
                                              task.Updated
                                                ? task.Updated
                                                : task.Created
                                            )
                                            .local()
                                            .format('MM/DD/YYYY')
                                      ) +
                                      '. If you need to reserve a time slot, use the link below.'}
                                </h3>
                              </>
                            ) : (
                              <>
                                <div className="required-message">
                                  <img
                                    src={withPrefix(
                                      '/images/misc/success-check.png'
                                    )}
                                    alt={itemGroup.Title}
                                  />{' '}
                                  All Set!
                                </div>

                                <h3 className="dyn-desktop-only">
                                  The property has accepted the information you
                                  submitted and marked this task as completed.
                                </h3>
                                <h3 className="dyn-mobile-only">
                                  The property has accepted the information you
                                  submitted and marked this task as completed.
                                </h3>
                              </>
                            )}

                            <div className="utility-button-list two submittal">
                              {itemGroup.Grouping === 'reservation' ? (
                                <Button
                                  onClick={(event) => {
                                    setShowCalendar(
                                      residentOnboarding.CalendarList.filter(
                                        (calendar) =>
                                          calendar.CalendarID ===
                                          itemGroup.ItemList[0].CalendarID
                                      )[0]
                                    );
                                  }}>
                                  {itemGroup.ResidentOnboardingStatusID === 3
                                    ? 'Change'
                                    : 'Add'}{' '}
                                  Reservation
                                </Button>
                              ) : (
                                <Button
                                  href={getSubmittalUrl(itemGroup.ItemList[0])}
                                  target="_blank">
                                  View Submittal
                                </Button>
                              )}

                              {itemGroup.Grouping === 'pet' &&
                              !residentOnboarding.Header.HasNoPet ? (
                                <Button
                                  onClick={(event) => {
                                    actions
                                      .addResidentPet()
                                      .then(
                                        setShowResidentOnboarding(
                                          itemGroup.ItemList[0]
                                        )
                                      );
                                  }}>
                                  Add Another Animal
                                </Button>
                              ) : itemGroup.Grouping === 'vehicle' &&
                                !residentOnboarding.Header.HasNoVehicle ? (
                                <Button
                                  onClick={(event) => {
                                    actions
                                      .addResidentVehicle()
                                      .then(
                                        setShowResidentOnboarding(
                                          itemGroup.ItemList[0]
                                        )
                                      );
                                  }}>
                                  Add Another Vehicle
                                </Button>
                              ) : null}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="main connector"></div>
                </div>
              </>
            ) : null}

            {requiredItemChecklist && requiredItemChecklist.length ? (
              <h5 className="checklist-title">Other Moving Related Items</h5>
            ) : null}
            <div
              className={
                'checklist' + (checklist.length % 2 === 1 ? ' odd' : '')
              }>
              {checklist.map((section, sectionIndex) => {
                let complete = section.IsCompleted;
                let completeDate = null;
                if (
                  residentOnboarding &&
                  residentOnboarding.Header &&
                  residentOnboarding.TaskList
                ) {
                  if (section.ServiceSectionID === 2) {
                    residentOnboarding.TaskList.map((task) => {
                      if (
                        task.TaskKey === 'internet' &&
                        task.ResidentOnboardingStatusID === 3
                      ) {
                        completeDate = task.Updated
                          ? task.Updated
                          : task.Created;
                        complete = true;
                      }
                    });
                  }
                }

                return (
                  <div
                    id={'item-' + sectionIndex}
                    className={
                      'checklist-item' +
                      (residentOnboarding &&
                      getResidentOnboardingStatus(residentOnboarding, section)
                        .ResidentOnboardingStatusID === 1
                        ? ' required'
                        : '') +
                      (activeSection &&
                      section.ServiceSectionID ===
                        activeSection.ServiceSectionID
                        ? ' active'
                        : '') +
                      (section.ServiceSectionID === 2
                        ? ' internet  with-bottom-buttons'
                        : '') +
                      (complete ? ' status-3' : '')
                    }>
                    <div className="connector"></div>
                    <div className="inner">
                      <Button
                        href={section.Route}
                        onClick={(event) => {
                          event.preventDefault();
                          navigateToSection(section);
                        }}></Button>
                      <h2>
                        <span className="dyn-desktop-only">
                          {section.Title}
                        </span>
                        <span className="dyn-mobile-only">
                          {section.ShortTitle}
                        </span>

                        {/* <TaskMenu
                        key={section.ServiceSectionID}
                        section={section}
                        checklistProgress={checklistProgress}
                        setChecklistProgress={setChecklistProgress}
                      /> */}
                        {!serviceAddress.Address.ResidentID &&
                        !residentOnboarding ? (
                          <div className="badge required">Required</div>
                        ) : null}
                      </h2>

                      {complete ? (
                        <div className={'required-message'}>
                          <img
                            src={withPrefix('/images/misc/success-check.png')}
                            alt={section.Title}
                          />{' '}
                          Completed{' '}
                          {completeDate
                            ? ' ' +
                              moment
                                .utc(completeDate)
                                .local()
                                .format('MM/DD/YYYY')
                            : ''}
                        </div>
                      ) : (
                        <ul
                          className={
                            section.ServiceList.length > 1 ? 'with-text' : ''
                          }>
                          {section.ServiceList.map((service, serviceIndex) => {
                            let limit =
                              section.ServiceList.length === 1
                                ? 4
                                : section.ServiceList.length === 2
                                ? 3
                                : 1;

                            let width = 100 / section.ServiceList.length;

                            //If this is the utilities section and there is more than one choice for electric,
                            //make the electric box span the whole area
                            let full = false;
                            if (section.Title === 'Connect Utilities') {
                              const electric = section.ServiceList.filter(
                                (_section) => _section.Headline === 'Electric'
                              )[0];
                              if (
                                electric != null &&
                                electric.ProviderList &&
                                electric.ProviderList.length > 1
                              ) {
                                if (service.Headline === 'Electric') {
                                  width = 100;
                                  limit = 5;
                                  full = true;
                                } else {
                                  width =
                                    100 / (section.ServiceList.length - 1);
                                }
                              }
                            }

                            const providerList = getUniqueProviderList(
                              service.ProviderList,
                              //limit
                              999 //Limited via CSS, scroll on mobile
                            ).filter(
                              (provider) =>
                                provider.ProviderServiceList.indexOf(
                                  'Satellite'
                                ) === -1
                            );

                            const providerCount = getUniqueProviderList(
                              service.ProviderList,
                              limit
                            ).filter(
                              (provider) =>
                                provider.ProviderServiceList.indexOf(
                                  'Satellite'
                                ) === -1
                            ).length;

                            const totalProviderCount = getUniqueProviderList(
                              service.ProviderList,
                              999
                            ).filter(
                              (provider) =>
                                provider.ProviderServiceList.indexOf(
                                  'Satellite'
                                ) === -1
                            ).length;

                            return (
                              <li
                                style={{
                                  width: width + '%'
                                }}>
                                {providerList.map((provider, providerIndex) => (
                                  <div
                                    className="provider-name"
                                    style={{
                                      width: 100 / providerCount + '%'
                                    }}>
                                    {provider.LogoFile ? (
                                      <img
                                        src={provider.LogoFile}
                                        className="provider-logo"
                                      />
                                    ) : (
                                      <span>{provider.DisplayName}</span>
                                    )}
                                  </div>
                                ))}
                                {section.ServiceList.length > 1 ? (
                                  <h4>
                                    {service.ProviderServiceCategoryOverrideTitle
                                      ? service.ProviderServiceCategoryName
                                      : service.Title}
                                  </h4>
                                ) : null}
                              </li>
                            );
                          })}
                        </ul>
                      )}

                      <h3 className="dyn-desktop-only">
                        {section.Description}
                      </h3>
                      <h3 className="dyn-mobile-only">
                        {section.DescriptionMobile}
                      </h3>

                      {section.ServiceSectionID === 2
                        ? [0].map((test) => {
                            return (
                              <>
                                <div className="utility-button-list two">
                                  <Button
                                    variant="outlined"
                                    color={complete ? 'primary' : 'secondary'}
                                    href={section.Route}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      navigateToSection(section);
                                    }}>
                                    View Available Plans <ChevronRightIcon />
                                  </Button>

                                  <Button
                                    variant="outlined"
                                    onClick={() => {
                                      if (
                                        residentOnboarding &&
                                        residentOnboarding.Header
                                      ) {
                                        if (complete) {
                                          saveResidentOnboardingTask(
                                            residentOnboarding.Header
                                              .ResidentID,
                                            'internet',
                                            1
                                          ).then((result) =>
                                            actions.loadResidentOnboarding()
                                          );
                                        } else {
                                          setShowAlreadyCompleted({
                                            key: 'internet',
                                            section: { ...section }
                                          });
                                        }
                                      } else {
                                        if (section.IsCompleted) {
                                          actions.toggleChecklistItemComplete(
                                            section,
                                            4
                                          );

                                          setChecklistProgress({
                                            ...checklistProgress,
                                            [section.ServiceSectionID]: 4
                                          });
                                        } else {
                                          setShowAlreadyCompleted({
                                            key: 'internet',
                                            section: { ...section }
                                          });
                                        }
                                      }
                                    }}>
                                    {complete
                                      ? 'Mark as Not Complete'
                                      : 'I Already Did This'}
                                  </Button>
                                </div>
                                <AlreadyCompletePrompt
                                  itemKey={'internet'}
                                  showAlreadycompleted={showAlreadycompleted}
                                  setShowAlreadyCompleted={
                                    setShowAlreadyCompleted
                                  }
                                  message={
                                    'Click the button below to confirm that you already set up Wifi Internet.'
                                  }
                                  onConfirm={() => {
                                    if (
                                      residentOnboarding &&
                                      residentOnboarding.Header
                                    ) {
                                      return saveResidentOnboardingTask(
                                        residentOnboarding.Header.ResidentID,
                                        'internet',
                                        3
                                      ).then((result) => {
                                        actions.loadResidentOnboarding();
                                        return result;
                                      });
                                    } else {
                                      setChecklistProgress({
                                        ...checklistProgress,
                                        [section.ServiceSectionID]: 1
                                      });
                                      return actions.toggleChecklistItemComplete(
                                        section,
                                        1
                                      );
                                    }
                                  }}
                                />
                              </>
                            );
                          })
                        : null}
                    </div>
                  </div>
                );
              })}

              <div className="main connector"></div>
            </div>

            {!continueFromServiceSection ? (
              <SchoolList
                serviceAddress={serviceAddress}
                siteConfig={siteConfig}
                checklistLength={checklist.length}
              />
            ) : null}

            {section && section.ServiceList ? (
              <div className="service-container">
                <ServiceContainer
                  innerOnly
                  dashboard
                  routeOverride={'/dashboard'}
                  providerGrouping={{
                    '': section.ServiceList.filter(
                      (_service) =>
                        _service.ProviderList &&
                        _service.ProviderList.filter(
                          (_provider) => _provider.ProviderID
                        ).length > 0
                    )
                  }}
                />
              </div>
            ) : null}

            <QuestionsSnippet
              serviceAddress={serviceAddress}
              siteConfig={siteConfig}
              mobile
            />
          </div>

          {showResidentOnboarding ? (
            showResidentOnboarding.ProviderServiceID ? (
              <ResidentOnboardingPopup
                providerServiceList={[showResidentOnboarding]}
                closeFunc={() => {
                  setShowResidentOnboarding(false);
                  actions.loadResidentOnboarding();
                }}
              />
            ) : (
              <ResidentOnboardingPopup
                otherTasks={showResidentOnboarding.Grouping}
                closeFunc={() => {
                  setShowResidentOnboarding(false);
                  actions.loadResidentOnboarding();
                }}
              />
            )
          ) : null}

          {showCalendar ? (
            <GeneralPopup
              title={showCalendar.CalendarName}
              message={
                <>
                  {showCalendar.Description ? (
                    <Alert
                      severity="info"
                      className="top-notice"
                      style={{ marginTop: '20px' }}>
                      {showCalendar.Description}
                    </Alert>
                  ) : null}

                  <CustomerEventCalendar
                    calendar={showCalendar}
                    minDate={residentOnboarding.Header.DateLeaseStart}
                    residentID={residentOnboarding.Header.ResidentID}
                  />
                </>
              }
              closeFunc={() => {
                setShowCalendar(null);
                actions.loadResidentOnboarding();
              }}
              closeLabel={'Close'}
              className={'calendar-timeslot-popup'}
            />
          ) : null}
        </div>
      )
    ) : (
      <>
        <LinearProgress />
      </>
    )
  ) : (
    <>
      <LinearProgress />
    </>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      saveOrder: bindActionCreators(serviceAddressActions.saveOrder, dispatch),
      mockRouteUpdated: bindActionCreators(
        webSocketActions.mockRouteUpdated,
        dispatch
      ),
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      ),
      toggleChecklistItemComplete: bindActionCreators(
        serviceAddressActions.toggleChecklistItemComplete,
        dispatch
      ),
      saveProviderService: bindActionCreators(
        residentOnboardingActions.saveProviderService,
        dispatch
      ),
      saveResidentOnboarding: bindActionCreators(
        residentOnboardingActions.saveResidentOnboarding,
        dispatch
      ),
      addResidentPet: bindActionCreators(
        residentOnboardingActions.addResidentPet,
        dispatch
      ),
      addResidentVehicle: bindActionCreators(
        residentOnboardingActions.addResidentVehicle,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
