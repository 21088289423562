import * as dataApi from './dataApi';

export function calcElectricOffer(offer, usage) {
  let result = { dollars: 0, perKWh: 0, detailList: [] };
  const originalUsage = usage;

  if (offer.UtilityBaseCharge) {
    const amount = parseFloat(offer.UtilityBaseCharge);

    if (amount > 0) {
      result.dollars += amount;
      result.detailList.push({
        subtotal: round(result.dollars, 2),
        amount: round(amount),
        category: 'TDU Charge',
        description: 'Base fee',
        rate: '$' + round(result.dollars, 2) + ' per month'
      });

      if (offer.BaseChargeAdjustment_All) {
        const adjustmentAmount = getAdjustment(
          offer,
          amount,
          'BaseChargeAdjustment_All'
        );
        if (adjustmentAmount.amount !== null) {
          result.dollars += adjustmentAmount.amount;
          result.detailList.push({
            ...adjustmentAmount,
            subtotal: round(result.dollars, 2),
            category: 'TDU Charge'
          });
        }
      }

      if (offer.BaseChargeAdjustment_Utility) {
        const adjustmentAmount = getAdjustment(
          offer,
          amount,
          'BaseChargeAdjustment_Utility'
        );
        if (adjustmentAmount.amount !== null) {
          result.dollars += adjustmentAmount.amount;
          result.detailList.push({
            ...adjustmentAmount,
            subtotal: round(result.dollars, 2),
            category: 'TDU Charge'
          });
        }
      }
    }
  }

  if (offer.UtilityUsageCharge) {
    const amount = round(parseFloat(offer.UtilityUsageCharge) * usage);
    if (amount > 0) {
      result.dollars += amount;
      result.detailList.push({
        subtotal: round(result.dollars, 2),
        amount: round(amount),
        category: 'TDU Charge',
        description: 'Delivery charge',
        rate:
          round(parseFloat(offer.UtilityUsageCharge) * 100) +
          '¢ x ' +
          usage +
          ' kWh'
      });

      if (offer.UsageChargeAdjustment_All) {
        const adjustmentAmount = getAdjustment(
          offer,
          amount,
          'UsageChargeAdjustment_All'
        );
        if (adjustmentAmount.amount !== null) {
          result.dollars += adjustmentAmount.amount;
          result.detailList.push({
            ...adjustmentAmount,
            subtotal: round(result.dollars, 2),
            category: 'TDU Charge'
          });
        }
      }

      if (offer.UsageChargeAdjustment_Utility) {
        const adjustmentAmount = getAdjustment(
          offer,
          amount,
          'UsageChargeAdjustment_Utility'
        );
        if (adjustmentAmount.amount !== null) {
          result.dollars += adjustmentAmount.amount;
          result.detailList.push({
            ...adjustmentAmount,
            subtotal: round(result.dollars, 2),
            category: 'TDU Charge'
          });
        }
      }
    }
  }

  let columnGroup = 'ProviderBaseCharge';
  let category = 'Provider Charge';
  let providerBaseChargeTotal = 0;
  for (let tierNumber = 1; tierNumber <= 5; tierNumber++) {
    let tierAmount = getFixedTier(offer, columnGroup, tierNumber, usage, true);
    if (tierAmount.amount !== null) {
      result.dollars += tierAmount.amount;
      providerBaseChargeTotal += tierAmount.amount;
      result.detailList.push({
        ...tierAmount,
        subtotal: round(result.dollars, 2),
        category: category
      });
    }
  }

  //Include the per day fee for some plans
  if (offer.ProviderBaseCharge_PerDay_Amount) {
    let amount = round(parseFloat(offer.ProviderBaseCharge_PerDay_Amount) * 30);
    if (amount > 0) {
      result.dollars += amount;
      providerBaseChargeTotal += amount;
      result.detailList.push({
        category: 'Provider Charge',
        description: 'Base charge',
        amount,
        rate:
          '$' + round(offer.ProviderBaseCharge_PerDay_Amount, 2) + ' x 30 days',
        subtotal: round(result.dollars, 2)
      });
    }
  }

  if (providerBaseChargeTotal && offer.BaseChargeAdjustment_All) {
    const adjustmentAmount = getAdjustment(
      offer,
      providerBaseChargeTotal,
      'BaseChargeAdjustment_All'
    );
    if (adjustmentAmount.amount !== null) {
      result.dollars += adjustmentAmount.amount;
      result.detailList.push({
        ...adjustmentAmount,
        subtotal: round(result.dollars, 2),
        category: 'Provider Charge'
      });
    }
  }

  if (providerBaseChargeTotal && offer.BaseChargeAdjustment_Provider) {
    const adjustmentAmount = getAdjustment(
      offer,
      providerBaseChargeTotal,
      'BaseChargeAdjustment_Provider'
    );
    if (adjustmentAmount.amount !== null) {
      result.dollars += adjustmentAmount.amount;
      result.detailList.push({
        ...adjustmentAmount,
        subtotal: round(result.dollars, 2),
        category: 'Provider Charge'
      });
    }
  }

  if (offer.ProviderBaseCharge_PerDay_UsageCredit) {
    let amount = round(
      parseFloat(offer.ProviderBaseCharge_PerDay_UsageCredit) * 30
    );
    if (amount > 0) {
      usage = usage - amount;
      if (usage > 0) {
        result.detailList.push({
          category: 'Provider Charge',
          description: 'Usage Discount',
          amount: 0,
          rate:
            'Subtract ' +
            round(offer.ProviderBaseCharge_PerDay_UsageCredit, 0) +
            ' kWh x 30 days (' +
            round(amount, 0) +
            ' kWh)',
          subtotal: round(result.dollars, 2)
        });
      }
    }
  }

  columnGroup = 'ProviderFlatCharge';
  category = 'Provider Charge';
  for (let tierNumber = 1; tierNumber <= 5; tierNumber++) {
    let tierAmount = getFixedTier(offer, columnGroup, tierNumber, usage, false);
    if (tierAmount.amount !== null) {
      result.dollars += tierAmount.amount;
      result.detailList.push({
        ...tierAmount,
        subtotal: round(result.dollars, 2),
        category: category
      });
    }
  }

  columnGroup = 'ProviderUsageCharge';
  category = 'Provider Charge';
  let providerUsageChargeTotal = 0;
  for (let tierNumber = 1; tierNumber <= 5; tierNumber++) {
    let tierAmount = getUsageTier(offer, columnGroup, tierNumber, usage, true);
    if (tierAmount.amount !== null) {
      result.dollars += tierAmount.amount;
      providerUsageChargeTotal += tierAmount.amount;
      result.detailList.push({
        ...tierAmount,
        subtotal: round(result.dollars, 2),
        category: category
      });
    }
  }

  if (providerUsageChargeTotal && offer.UsageChargeAdjustment_All) {
    const adjustmentAmount = getAdjustment(
      offer,
      providerUsageChargeTotal,
      'UsageChargeAdjustment_All'
    );
    if (adjustmentAmount.amount !== null) {
      result.dollars += adjustmentAmount.amount;
      result.detailList.push({
        ...adjustmentAmount,
        subtotal: round(result.dollars, 2),
        category: 'Provider Charge'
      });
    }
  }

  if (providerUsageChargeTotal && offer.UsageChargeAdjustment_Provider) {
    const adjustmentAmount = getAdjustment(
      offer,
      providerUsageChargeTotal,
      'UsageChargeAdjustment_Provider'
    );
    if (adjustmentAmount.amount !== null) {
      result.dollars += adjustmentAmount.amount;
      result.detailList.push({
        ...adjustmentAmount,
        subtotal: round(result.dollars, 2),
        category: 'Provider Charge'
      });
    }
  }

  columnGroup = 'ProviderCredit';
  category = 'Provider Charge';
  for (let tierNumber = 1; tierNumber <= 5; tierNumber++) {
    let tierAmount = getCreditTier(
      offer,
      columnGroup,
      tierNumber,
      usage,
      false,
      true
    );
    if (tierAmount.amount !== null) {
      result.dollars += tierAmount.amount;
      result.detailList.push({
        ...tierAmount,
        subtotal: round(result.dollars, 2),
        category: category
      });
    }
  }

  result.perKWh = round(result.dollars / originalUsage, 3);

  return result;
}

function getFixedTier(offer, columnGroup, tierNumber, usage, isBaseCharge) {
  let calcDetail = { amount: null, description: '' };
  if (offer && offer[columnGroup + '_Tier' + tierNumber + '_Amount']) {
    const tierAmount = parseFloat(
      offer[columnGroup + '_Tier' + tierNumber + '_Amount']
    );

    if (!isNaN(tierAmount)) {
      let tierStart = parseFloat(
        offer[columnGroup + '_Tier' + tierNumber + '_Start']
      );

      let noTierStart = false;
      if (!tierStart) {
        tierStart = 0;
        noTierStart = true;
      }

      if (tierStart < usage) {
        let tierEnd = parseFloat(
          offer[columnGroup + '_Tier' + tierNumber + '_End']
        );

        let noTierEnd = false;
        if (!tierEnd) {
          tierEnd = usage;
          noTierEnd = true;
        }

        if (usage < tierEnd) {
          tierEnd = usage;
        }

        const amount =
          usage >= tierStart
            ? usage <= tierEnd //Removed 04/22/24 - This was not working for 4change and no other offers have this field populated (see commented out text to the right) || !isBaseCharge //This is because the flat tiers are inclusive while the base tiers are not
              ? tierAmount
              : 0
            : 0;

        if (amount > 0) {
          calcDetail.amount = amount;

          calcDetail.description = isBaseCharge ? 'Base charge' : 'Flat charge';

          if (noTierStart && noTierEnd) {
            if (!isBaseCharge) {
              calcDetail.description += ' for any usage';
            }
          } else if (noTierStart) {
            calcDetail.description += ' for up to ' + tierEnd + ' kWh';
          } else if (noTierEnd) {
            calcDetail.description += ' above ' + tierStart + ' kWh';
          } else {
            calcDetail.description +=
              ' for ' + tierStart + ' to ' + tierEnd + ' kWh';
          }

          calcDetail.rate = '$' + round(tierAmount, 2) + ' per month';
          calcDetail.tierStart = tierStart;
          calcDetail.tierEnd = tierEnd;
        }
      }
    }
  }

  return calcDetail;
}

function getUsageTier(offer, columnGroup, tierNumber, usage) {
  let calcDetail = { amount: null, description: '' };
  if (offer && offer[columnGroup + '_Tier' + tierNumber + '_Amount'] !== null) {
    const tierAmount = parseFloat(
      offer[columnGroup + '_Tier' + tierNumber + '_Amount']
    );

    if (!isNaN(tierAmount)) {
      let tierStart = parseFloat(
        offer[columnGroup + '_Tier' + tierNumber + '_Start']
      );

      let noTierStart = false;
      if (!tierStart) {
        tierStart = 0;
        noTierStart = true;
      }

      if (tierStart < usage) {
        let tierEnd = parseFloat(
          offer[columnGroup + '_Tier' + tierNumber + '_End']
        );

        let noTierEnd = false;
        if (!tierEnd) {
          tierEnd = usage;
          noTierEnd = true;
        }

        if (usage < tierEnd) {
          tierEnd = usage;
        }

        const billableUsage = tierEnd - tierStart;
        calcDetail.amount =
          billableUsage > 0 ? round(tierAmount * billableUsage) : 0;

        calcDetail.description = 'Energy charge';

        if (noTierStart && noTierEnd) {
          calcDetail.description += ' for all usage';
        } else if (noTierStart) {
          calcDetail.description += ' for up to ' + tierEnd + ' kWh';
        } else if (noTierEnd) {
          calcDetail.description += ' above ' + tierStart + ' kWh';
        } else {
          calcDetail.description +=
            ' for ' + tierStart + ' to ' + tierEnd + ' kWh';
        }

        const splitTierAmount = String(tierAmount).split('.');
        const precision = splitTierAmount[1]
          ? splitTierAmount[1].length - 2
          : 0;

        calcDetail.rate =
          round(tierAmount * 100, precision > 1 ? precision : 1) +
          '¢ x ' +
          (billableUsage > 0 ? billableUsage : 0) +
          ' kWh';
        calcDetail.tierStart = tierStart;
        calcDetail.tierEnd = tierEnd;
      }
    }
  }

  return calcDetail;
}

function getCreditTier(offer, columnGroup, tierNumber, usage) {
  let calcDetail = { amount: null, description: '' };
  if (offer && offer[columnGroup + '_Tier' + tierNumber + '_Amount']) {
    const tierAmount = parseFloat(
      offer[columnGroup + '_Tier' + tierNumber + '_Amount']
    );

    if (!isNaN(tierAmount)) {
      let tierStart = parseFloat(
        offer[columnGroup + '_Tier' + tierNumber + '_Start']
      );

      let noTierStart = false;
      if (!tierStart) {
        tierStart = 0;
        noTierStart = true;
      }

      let tierEnd = parseFloat(
        offer[columnGroup + '_Tier' + tierNumber + '_End']
      );

      let noTierEnd = false;
      if (!tierEnd || tierEnd == '999999') {
        tierEnd = usage;
        noTierEnd = true;
      }

      calcDetail.amount =
        usage >= tierStart && usage <= tierEnd ? round(tierAmount * -1) : 0;

      calcDetail.description = 'Bill credit';

      calcDetail.rate = '$' + tierAmount.toFixed(2) + ' bill credit';

      if (noTierStart && noTierEnd) {
        calcDetail.rate += ' always applied';
      } else if (noTierStart) {
        calcDetail.rate += ' if usage ≤ ' + tierEnd + ' kWh';
      } else if (noTierEnd) {
        calcDetail.rate += ' if usage ≥ ' + tierStart + ' kWh';
      } else {
        calcDetail.rate +=
          ' if usage between ' + tierStart + ' and ' + tierEnd + ' kWh';
      }

      calcDetail.isBillCredit = true;
      calcDetail.isTiered = true;
      calcDetail.tierStart = tierStart;
      calcDetail.tierEnd = tierEnd;
      calcDetail.comparedUsage = usage;
    }
  }

  return calcDetail;
}

function getAdjustment(offer, chargeToAdjust, adjustmentField) {
  let calcDetail = { amount: null, description: '' };
  if (chargeToAdjust && offer[adjustmentField]) {
    const amount = round(
      parseFloat(offer[adjustmentField]) * parseFloat(chargeToAdjust) * -1
    );

    calcDetail.amount = round(amount);
    calcDetail.description = 'Time of use discount';
    calcDetail.rate =
      'Estimated ' + round(parseFloat(offer[adjustmentField]) * 100, 0) + '%';
    calcDetail.isBillCredit = true;

    calcDetail.rateLong =
      'All prices and rates shown for this plan assume that ' +
      round(parseFloat(offer[adjustmentField]) * 100, 0) +
      "% of your energy consumption occurs during the 'no-charge' period. <strong>Your actual consumption during the 'no-charge' period could be more or less depending on your actual usage.</strong>";
  }

  return calcDetail;
}

export function round(value, precision) {
  if (!precision && precision !== 0) {
    precision = 6;
  }

  return (
    Math.round(parseFloat(value) * Math.pow(10, precision)) /
    Math.pow(10, precision)
  );
}

export function getFullCostDetail(
  offer,
  selectedUsage,
  serviceAddress,
  usageHistory,
  usageSetting,
  lastBillAmount
) {
  if (!usageHistory) {
    usageHistory = getUsageHistory();
  }

  if (!usageSetting) {
    usageSetting = getUsageSetting();
  }

  let _offer = { ...offer, fullCostDetailApplied: selectedUsage };

  let advertisedUsage =
    selectedUsage === 500 || selectedUsage === 1000 || selectedUsage === 2000
      ? selectedUsage
      : 1000;

  const calculatedPrice = calcElectricOffer(offer, advertisedUsage);

  _offer.PriceUsage = advertisedUsage;
  _offer.PriceKWh = calculatedPrice.perKWh * 100;
  _offer.PriceDollars = calculatedPrice.dollars;

  const singleMonthPrice = calcElectricOffer(offer, selectedUsage);

  _offer.calcs = singleMonthPrice;

  _offer.SingleMonthPriceUsage = selectedUsage;
  _offer.SingleMonthPriceKWh = singleMonthPrice.perKWh * 100;
  _offer.SingleMonthPriceDollars = singleMonthPrice.dollars;

  if (lastBillAmount) {
    _offer.AmountSaved = lastBillAmount - _offer.SingleMonthPriceDollars;

    if (_offer.AmountSaved < 0) {
      _offer.AmountSaved = 0;
    }

    _offer.PercentSaved =
      _offer.SingleMonthPriceDollars > 0
        ? (_offer.AmountSaved / lastBillAmount) * 100
        : 0;
  } else {
    _offer.AmountSaved = null;
  }

  //Determine if there is a credit that applies to this
  let creditList = singleMonthPrice.detailList.filter(
    (detail) => detail.isBillCredit
  );

  //Remove any duplicate credits
  let usedCredit = {};
  let filteredCreditList = [];
  creditList.map((credit) => {
    if (!usedCredit[credit.rate]) {
      filteredCreditList.push(credit);
      usedCredit[credit.rate] = true;
    }
  });

  if (creditList.length) {
    const applicableCreditList = filteredCreditList.filter(
      (credit) => credit.amount !== 0
    );
    const nonApplicableCreditList = filteredCreditList.filter(
      (credit) => credit.amount === 0
    );

    _offer.calcs.creditList = applicableCreditList.length
      ? applicableCreditList
      : nonApplicableCreditList;
  }

  _offer = getTrueCostDetail(
    _offer,
    selectedUsage,
    serviceAddress,
    usageHistory,
    usageSetting
  );

  const trueCost =
    usageSetting === 'estimated' ||
    !_offer.trueCost ||
    !_offer.trueCost.actual ||
    !_offer.trueCost.actual.table ||
    _offer.trueCost.actual.table.noHistory
      ? _offer.trueCost.estimated
      : _offer.trueCost.actual;

  if (trueCost) {
    _offer.TrueCost_Dollars = trueCost.dollars;
    _offer.TrueCost_PerKWh = trueCost.perKWh;
    _offer.TrueCost_Usage = round(trueCost.usage, 0);

    if (
      serviceAddress &&
      serviceAddress.ElectricUsageHistory &&
      serviceAddress.ElectricUsageHistory.State &&
      serviceAddress.ElectricUsageHistory.State.January &&
      serviceAddress.ElectricUsageHistory.State.January
        .AveragePricePerCustomer_12Month &&
      _offer.TrueCost_PerKWh <
        serviceAddress.ElectricUsageHistory.State.January
          .AveragePricePerCustomer_12Month
    ) {
      _offer.BelowStateAverage = 'Yes it is!';
    }
  }

  return _offer;
}

export function getTrueCostDetail(
  offer,
  selectedUsage,
  serviceAddress,
  usageHistory
) {
  let _offer = { ...offer, fullCostDetailApplied: selectedUsage };

  //True cost calculations for advertised rates
  _offer.trueCost = {
    estimated: null,
    actual: null
  };

  if (
    serviceAddress &&
    serviceAddress.ElectricUsageHistory &&
    serviceAddress.ElectricUsageHistory.State
  ) {
    let totalForComparison_Usage = 0;
    let totalForComparison_Months = 0;

    let totalForYear_Usage = 0;
    let totalForYear_Dollars = 0;
    let totalMonths = 0;

    Object.keys(serviceAddress.ElectricUsageHistory.State).map((month) => {
      const usage = serviceAddress.ElectricUsageHistory.State[month];
      if (usage.Usage >= 0) {
        totalForComparison_Usage += usage.Usage;
        totalForComparison_Months++;
      }
    });

    if (totalForComparison_Months > 0 && totalForComparison_Usage > 0) {
      const averageToCompare =
        totalForComparison_Usage / totalForComparison_Months;

      _offer.trueCost.estimated = {
        table: {},
        source: 'EIA Seasonally Adjusted Usage Curve'
      };

      Object.keys(serviceAddress.ElectricUsageHistory.State).map((month) => {
        const usage = serviceAddress.ElectricUsageHistory.State[month];
        const usageVariable = usage.Usage / averageToCompare;

        const projectedUsage = round(selectedUsage * usageVariable, 0);

        totalForYear_Usage += projectedUsage;
        totalMonths++;

        const calcAmount = calcElectricOffer(offer, projectedUsage);

        totalForYear_Dollars += calcAmount.dollars;

        _offer.trueCost.estimated.table[month] = {
          usage: projectedUsage,
          dollars: calcAmount.dollars,
          perKWh: calcAmount.perKWh * 100,
          creditApplies: checkIfCreditApplies(calcAmount)
        };
      });

      if (totalMonths > 0) {
        _offer.trueCost.estimated.usage = totalForYear_Usage / totalMonths;
        _offer.trueCost.estimated.dollars = totalForYear_Dollars / totalMonths;
        _offer.trueCost.estimated.perKWh =
          (_offer.trueCost.estimated.dollars / selectedUsage) * 100;

        _offer.trueCost.estimated.table['Average'] = {
          usage: _offer.trueCost.estimated.usage,
          dollars: _offer.trueCost.estimated.dollars,
          perKWh: _offer.trueCost.estimated.perKWh
        };
      }
    }
  }

  //True cost calculations for usage history
  if (usageHistory) {
    let totalForYear_Usage = 0;
    let totalForYear_Dollars = 0;
    let totalMonths = 0;

    let _usageHistory = {};
    let usageHistoryExists = false;

    const monthList = dataApi.getMonthsOfYear();

    _offer.trueCost.actual = {
      table: { noHistory: true },
      source: usageHistory.SmartMeter
        ? 'data pulled from your smart meter'
        : 'usage history you entered'
    };

    //Determine which months of usage were entered
    const communityAverage = serviceAddress.ElectricUsageHistory
      ? serviceAddress.ElectricUsageHistory.State
      : null;
    let varianceFromCommunityAverage_Total = 0;
    let varianceFromCommunityAverage_MonthCount = 0;
    let projectionNeeded = false;

    monthList.map((month, monthIndex) => {
      const actualUsage = usageHistory[month];
      if (communityAverage && communityAverage[month].Usage) {
        if (actualUsage > 0 && communityAverage[month]) {
          usageHistoryExists = true;

          const varianceFromCommunityAverage =
            actualUsage / communityAverage[month].Usage;
          varianceFromCommunityAverage_Total += varianceFromCommunityAverage;
          varianceFromCommunityAverage_MonthCount++;

          _usageHistory[month] = {
            actual: actualUsage,
            varianceFromCommunityAverage
          };
        } else {
          _usageHistory[month] = {};
          projectionNeeded = true;
        }
      }
    });

    if (projectionNeeded && varianceFromCommunityAverage_MonthCount > 0) {
      const varianceFromCommunityAverage =
        varianceFromCommunityAverage_Total /
        varianceFromCommunityAverage_MonthCount;
      monthList.map((month, monthIndex) => {
        if (
          _usageHistory[month] &&
          !_usageHistory[month].actual &&
          communityAverage[month].Usage
        ) {
          _usageHistory[month].projected = round(
            communityAverage[month].Usage * varianceFromCommunityAverage,
            0
          );
          _usageHistory[month].projectedVariance = varianceFromCommunityAverage;
        }
      });
    }

    // if (serviceAddress.ElectricUsageHistory.State) {
    //   _usageHistory = projectActualUsage(
    //     _usageHistory,
    //     monthList,
    //     serviceAddress.ElectricUsageHistory.State
    //   );
    // }

    if (usageHistoryExists) {
      try {
        _offer.trueCost.actual.table.noHistory = false;

        monthList.map((month) => {
          let usage = _usageHistory[month].actual
            ? _usageHistory[month].actual
            : _usageHistory[month].projected;

          if (usage && usage >= 0) {
            totalForYear_Usage += parseFloat(usage);
            totalMonths += 1;

            const calcAmount = calcElectricOffer(offer, usage);

            totalForYear_Dollars += calcAmount.dollars;

            _offer.trueCost.actual.table[month] = {
              usage: usage,
              dollars: calcAmount.dollars,
              perKWh: calcAmount.perKWh * 100,
              isProjected: _usageHistory[month].projected,
              creditApplies: checkIfCreditApplies(calcAmount)
            };

            if (_usageHistory[month].projected) {
              _offer.trueCost.actual.table.someProjected = true;
            }
          }
        });
      } catch (e) {}
    }

    if (totalMonths > 0) {
      _offer.trueCost.actual.usage = totalForYear_Usage / totalMonths;
      _offer.trueCost.actual.dollars = totalForYear_Dollars / totalMonths;
      _offer.trueCost.actual.perKWh =
        (_offer.trueCost.actual.dollars / _offer.trueCost.actual.usage) * 100;

      _offer.trueCost.actual.table['Average'] = {
        usage: _offer.trueCost.actual.usage,
        dollars: _offer.trueCost.actual.dollars,
        perKWh: _offer.trueCost.actual.perKWh
      };
    }
  }

  return _offer;
}

function checkIfCreditApplies(calcPrice) {
  let creditList = calcPrice.detailList.filter(
    (detail) => detail.isBillCredit && detail.amount < 0
  );

  return creditList.length > 0;
}

function projectActualUsage(projectedUsage, monthList, communityAverage) {
  let _usageHistory = { ...projectedUsage, someProjected: false };

  monthList.map((month, monthIndex) => {
    const knownUsage = _usageHistory[month]
      ? _usageHistory[month].actual
        ? _usageHistory[month].actual
        : _usageHistory[month].projected
      : null;

    if (knownUsage) {
      const monthToProject = monthList[monthIndex > 0 ? monthIndex - 1 : 11];
      const prevMonthUsage = _usageHistory[monthToProject];

      if (!prevMonthUsage || !prevMonthUsage.projected) {
        if (!prevMonthUsage || !prevMonthUsage.actual) {
          if (
            communityAverage &&
            communityAverage[month] &&
            communityAverage[monthToProject]
          ) {
            const projectedVariance =
              communityAverage[monthToProject].Usage /
              communityAverage[month].Usage;
            const projectedUsage = round(knownUsage * projectedVariance, 0);

            if (projectedUsage) {
              _usageHistory[monthToProject] = {
                projected: projectedUsage,
                projectedSource: month,
                projectedSourceUsage: communityAverage[month].Usage,
                projectedVariance
              };

              _usageHistory.someProjected = true;
            }
          }
        }
      }
    }
  });

  if (_usageHistory.someProjected) {
    _usageHistory = projectActualUsage(
      _usageHistory,
      monthList,
      communityAverage
    );
  }

  return _usageHistory;
}

export function getSelectedUsage() {
  const selectedUsage = dataApi.getCookie('mi-selected-usage');

  if (selectedUsage && !isNaN(selectedUsage)) {
    return parseFloat(selectedUsage);
  }

  const lastUsage = getLastBillUsage();
  if (lastUsage && !isNaN(lastUsage)) {
    return parseFloat(lastUsage);
  }

  return 1000;
}

export function setSelectedUsage(usage) {
  dataApi.setCookie('mi-selected-usage', usage, 8760);
}

export function getEstimatedUsage() {
  const estimatedUsage = dataApi.getCookie('mi-estimated-usage');

  if (estimatedUsage && !isNaN(estimatedUsage)) {
    return parseFloat(estimatedUsage);
  }

  return null;
}

export function setLastBillUsage(usage) {
  dataApi.setCookie('mi-last-usage', usage, 8760);
}

export function setLastBillAmount(value) {
  dataApi.setCookie('mi-last-bill', value, 8760);
}

export function getLastBillUsage() {
  const lastUsage = dataApi.getCookie('mi-last-usage');
  if (lastUsage && !isNaN(lastUsage) && lastUsage > 0) {
    return parseFloat(lastUsage);
  }

  return null;
}

export function getLastBillAmount() {
  const lastAmount = dataApi.getCookie('mi-last-bill');
  if (lastAmount && !isNaN(lastAmount) && lastAmount > 0) {
    return parseFloat(lastAmount);
  }

  return null;
}

export function setEstimatedUsage(usage) {
  dataApi.setCookie('mi-estimated-usage', usage, 8760);
}

export function getUsageHistory(serviceAddress) {
  let _usageHistory = {};
  if (
    serviceAddress &&
    serviceAddress.ElectricUsageHistory &&
    serviceAddress.ElectricUsageHistory.Actual
  ) {
    _usageHistory = {
      SmartMeter: true
    };

    Object.keys(serviceAddress.ElectricUsageHistory.Actual).map((month) => {
      _usageHistory[month] =
        serviceAddress.ElectricUsageHistory.Actual[month].Usage;
    });
  } else {
    const usageHistoryCookie = dataApi.getCookie('mi-usage-history');
    if (usageHistoryCookie) {
      try {
        _usageHistory = JSON.parse(usageHistoryCookie);
        _usageHistory.CookieChecked = true;
        _usageHistory.SmartMeter = false;
      } catch (e) {}
    }
  }

  return _usageHistory;
}

export function setUsageHistory(usageHistory) {
  dataApi.setCookie(
    'mi-usage-history',
    JSON.stringify({ ...usageHistory }),
    8760
  );
}

export function getUsageSetting() {
  const usageSetting = dataApi.getCookie('mi-usage-setting');
  return usageSetting ? usageSetting : 'estimated';
}

export function setUsageSetting(usageSetting) {
  dataApi.setCookie('mi-usage-setting', usageSetting, 8760);
}

export function getRateType(offer) {
  let hasTiers = checkIfTiered(offer);
  let hasIndexedColumnValue =
    offer.BaseChargeAdjustment_All ||
    offer.BaseChargeAdjustment_Provider ||
    offer.BaseChargeAdjustment_Utility ||
    offer.UsageChargeAdjustment_All ||
    offer.UsageChargeAdjustment_Provider ||
    offer.UsageChargeAdjustment_Utility;

  return offer.IsPrepaid
    ? 'Prepaid'
    : !offer.TermLength || offer.TermLength === 1
    ? 'Variable'
    : hasTiers
    ? 'Fixed'
    : hasIndexedColumnValue
    ? 'Time of Use'
    : 'Fixed';
}

export function checkIfTiered(offer) {
  let hasTiers = 0;

  for (const field in offer) {
    if (field.indexOf('StateAverage') === -1) {
      if (field.indexOf('_Start') !== -1 || field.indexOf('_End') !== -1) {
        if (offer[field] > 0) {
          hasTiers = 1;
        }
      }
    }
  }

  return hasTiers;
}

export function formatDollars(amount) {
  let formattedAmount = String(round(Math.abs(amount), 2));

  const formattedAmountParts = formattedAmount.split('.');

  formattedAmount += !formattedAmountParts[1]
    ? '.00'
    : formattedAmountParts[1].length === 1
    ? '0'
    : '';

  return (amount < 0 ? '-' : '') + '$' + formattedAmount;
}

export function checkOffsiteLink(offer, field, part) {
  if (offer[field]) {
    const useIframe = String(offer[field]).indexOf('mi-offers') !== -1;
    if (!useIframe) {
      switch (part) {
        case 'href':
          return useIframe ? '' : offer[field];
        case 'target':
          return useIframe ? '' : 'mi_offsite';
        default:
          return useIframe;
      }
    }
  }

  return '';
}

export function calcInternetOffer(offerList, offer) {
  let _offer = { ...offer };

  _offer = getInternetPlanBreakdown(offerList, _offer);

  if (_offer.TermLength === 1) {
    _offer.termText =
      'This is a month-to-month plan, so the price may go up over time.';

    if (_offer.availableInBundleList && _offer.availableInBundleList.length) {
      const priceLockBundleList = _offer.availableInBundleList.filter(
        (bundleOffer) => bundleOffer.TermLength
      );
      if (priceLockBundleList.length) {
        let lowestPrice = null;
        let lowestPriceBundle = null;

        priceLockBundleList.map((priceLockBundle) => {
          if (
            lowestPrice === null ||
            offerCalc.getInternetPrice(priceLockBundle).monthlyAmount <
              lowestPrice
          ) {
            lowestPrice = priceLockBundle.PriceDollars;
            lowestPriceBundle = { ...priceLockBundle };
          }
        });

        if (lowestPriceBundle) {
          _offer.termText +=
            ' To lock in the price for ' +
            lowestPriceBundle.TermLength +
            ' months, you may choose to bundle this plan as part of the <strong><em>' +
            lowestPriceBundle.OfferName +
            '</em></strong> plan.';
        }
      }
    }
  } else if (_offer.TermLength === null) {
    _offer.termText =
      'Check with the provider for information on contract terms for this plan.';
  } else if (_offer.TermLength) {
    _offer.termText =
      'This no-contract plan has a price lock, so your price will not change for ' +
      _offer.TermLength +
      ' months.';
  } else {
    _offer.termText =
      'This plan has a ' + _offer.TermLength + '-month contract.';

    const termRates = getTermRates(_offer);
    if (!termRates) {
      _offer.termText +=
        ' The price remains the same for all ' + _offer.TermLength + ' months.';
    } else {
      _offer.termText += ' The price goes up to ' + termRates + '.';
      _offer.termRateDisclaimer = termRates;
    }
  }

  //Set the data for the monthly cost chart/table
  // if (_offer.PriceDollars) {
  _offer.priceTable = [];
  let monthsTotal = 24;
  Object.keys(_offer).map((field) => {
    if (
      field.indexOf('Term_') !== -1 &&
      (field.indexOf('_Start') !== -1 || field.indexOf('_End') !== -1)
    ) {
      const monthNumber = round(_offer[field], 0);
      if (monthNumber > monthsTotal) {
        monthsTotal = monthNumber;
      }
    }
  });

  for (let monthNumber = 1; monthNumber <= monthsTotal; monthNumber++) {
    let price = { monthNumber, amount: 0 };

    Object.keys(_offer).map((field) => {
      if (
        _offer[field] &&
        field.indexOf('Term_') == 0 &&
        field.indexOf('_Start') !== -1
      ) {
        const termStartMonth = field
          .split('Term_')
          .join('')
          .split('_Start')
          .join(''); //This will look something like 'Rate1'
        const termStart = _offer[field];
        const termEnd = _offer['Term_' + termStartMonth + '_End'];
        const termAmount = _offer['Term_' + termStartMonth + '_Amount'];

        if (termAmount) {
          if (monthNumber >= termStart) {
            if (!termEnd || termEnd >= monthNumber) {
              price.amount = round(termAmount, 2);
            }
          }
        }
      }
    });

    _offer.priceTable.push(price);
  }
  //}

  return _offer;
}

export function getInternetPrice(offer) {
  let _price = {
    monthlyAmount: 0,
    purchaseAmount: 0,
    isContract: offer.contractRequired
  };

  if (offer.chargeDictionary) {
    _price.monthlyAmount = 0;
    _price.purchaseAmount = 0;

    Object.keys(offer.chargeDictionary).map((chargeGroup) => {
      offer.chargeDictionary[chargeGroup].chargeList.map(
        (charge, chargeIndex) => {
          if (charge.monthlyAmount) {
            if (
              charge.name ===
                offer.chargeDictionary[chargeGroup].selectedValue &&
              chargeGroup
            ) {
              const itemCount =
                charge.maxCount > 1
                  ? offer.chargeDictionary[chargeGroup].selectedItemCount
                    ? offer.chargeDictionary[chargeGroup].selectedItemCount
                    : 1
                  : 1;

              offerOptionMonthlyTotal += itemCount * charge.monthlyAmount;
            } else if (
              offer.chargeDictionary[chargeGroup].selectedValueList &&
              offer.chargeDictionary[chargeGroup].selectedValueList[
                charge.name
              ] &&
              charge.monthlyAmount
            ) {
              _price.monthlyAmount += charge.monthlyAmount;

              if (charge.contractDiscount) {
                _price.isContract = true;
              }
            }
          }

          if (
            charge.purchaseAmount &&
            charge.name === offer.chargeDictionary[chargeGroup].selectedValue
          ) {
            const itemCount =
              charge.maxCount > 1
                ? offer.chargeDictionary[chargeGroup].selectedItemCount
                  ? offer.chargeDictionary[chargeGroup].selectedItemCount
                  : 1
                : 1;

            _price.purchaseAmount += charge.purchaseAmount * itemCount;
          } else if (
            offer.chargeDictionary[chargeGroup].selectedValueList &&
            offer.chargeDictionary[chargeGroup].selectedValueList[
              charge.name
            ] &&
            charge.purchaseAmount
          ) {
            _price.purchaseAmount += charge.purchaseAmount;
          }
        }
      );
    });
  }

  return _price;
}

function getInternetPlanBreakdown(offerList, offer, bundleOffer) {
  let _offerToModify = { ...offer };
  const _offerToDetail = bundleOffer ? { ...bundleOffer } : { ...offer };

  _offerToModify.detailList = _offerToModify.detailList
    ? _offerToModify.detailList.concat([])
    : [];

  const category = 'Provider Charges';

  //Add charge items
  let dictChargeGroup = {};

  Object.keys(_offerToDetail).map((field) => {
    if (field.indexOf('Charge') === 0 && field.indexOf('_Group') !== -1) {
      const fieldPrefix = field.split('_Group')[0] + '_';
      const chargeGroup = _offerToDetail[fieldPrefix + 'Group'];
      const chargeNumber = parseInt(
        field.split('Charge')[1].split('_Group')[0]
      );

      if (_offerToDetail[fieldPrefix + 'Name']) {
        if (!dictChargeGroup[chargeGroup]) {
          dictChargeGroup[chargeGroup] = {
            chargeList: []
          };
        }

        if (_offerToDetail[fieldPrefix + 'IsRequired']) {
          dictChargeGroup[chargeGroup].isRequired = true;
        }

        let charge = {
          group: chargeGroup,
          chargeNumber,
          name: _offerToDetail[fieldPrefix + 'Name'],
          monthlyAmount: _offerToDetail[fieldPrefix + 'MonthlyAmount'],
          purchaseAmount: _offerToDetail[fieldPrefix + 'PurchaseAmount'],
          term: _offerToDetail[fieldPrefix + 'Term'],
          termStart: _offerToDetail[fieldPrefix + 'Term_Start'],
          termEnd: _offerToDetail[fieldPrefix + 'Term_End'],
          isRequired: _offerToDetail[fieldPrefix + 'IsRequired'],
          maxCount: _offerToDetail[fieldPrefix + 'MaxCount'],
          includedInPrice: _offerToDetail[fieldPrefix + 'IncludedInPrice'],
          offerID: _offerToDetail.OfferID
        };

        if (charge.chargeNumber === 30 && charge.monthlyAmount) {
          _offerToModify.contractDiscount = charge.monthlyAmount;
          charge.contractDiscount = true;
        }

        if (charge.chargeNumber === 30 && charge.isRequired) {
          _offerToModify.contractRequired = true;
        }

        if (charge.chargeNumber === 30) {
          _offerToModify.contractTerm = charge.term;
        }

        if (charge.chargeNumber === 30 && charge.isRequired) {
          charge.contractRequired = true;
        }

        if (charge.termStart && charge.termStart > 1) {
          charge.standardPrice = charge.monthlyAmount;
          charge.standardPriceText =
            charge.termStart -
            1 +
            ' mo free - then $' +
            charge.standardPrice.toFixed(2) +
            ' per mo';
          charge.monthlyAmount = 0;
        }

        dictChargeGroup[chargeGroup].chargeList.push(charge);
      }
    }
  });

  if (dictChargeGroup) {
    //Add the details for the plan list page
    Object.keys(dictChargeGroup).map((chargeGroup) => {
      dictChargeGroup[chargeGroup].chargeList.map((charge, chargeIndex) => {
        if (!chargeGroup || chargeIndex === 0) {
          if (charge.isRequired || charge.monthlyAmount === 0) {
            if (!charge.standardPrice) {
              _offerToModify.detailList.push({
                amount: charge.monthlyAmount,
                category,
                description: charge.name,
                rate: ''
                //'$' + round(Math.abs(charge.monthlyAmount), 2) + ' per month'
              });
            }
          }
        }
      });
    });

    //Determine which image badges to show
    let badgeImageList = [];
    Object.keys(dictChargeGroup).map((chargeGroup) => {
      dictChargeGroup[chargeGroup].chargeList
        .filter(
          (charge) =>
            charge.isRequired || (!charge.monthlyAmount && !charge.term)
        )
        .map((charge) => {
          const chargeName = charge.name;

          if (!charge.standardPrice) {
            if (chargeName.indexOf('HBO Max') !== -1) {
              badgeImageList.push('HBO_Max_Logo.svg');
            } else if (chargeName.indexOf('HBO') !== -1) {
              badgeImageList.push('HBO_logo.svg');
            } else if (chargeName.indexOf('Showtime') !== -1) {
              badgeImageList.push('Showtime.svg');
            } else if (chargeName.indexOf('Epix') !== -1) {
              badgeImageList.push('Epix_Logo_2019.svg');
            } else if (chargeName.indexOf('Cinemax') !== -1) {
              badgeImageList.push('Cinemax.svg');
            } else if (chargeName.indexOf('AMC') !== -1) {
              badgeImageList.push('AMC_logo_2019.svg');
            } else if (chargeName.indexOf('NBA') !== -1) {
              badgeImageList.push('nba-league-pass.svg');
            } else if (chargeName.indexOf('NFL') !== -1) {
              badgeImageList.push('nfl.svg');
            } else if (
              chargeName.toUpperCase().indexOf('STARZ ENCORE') !== -1
            ) {
              badgeImageList.push('Starz_Encore.svg');
            } else if (chargeName.toUpperCase().indexOf('STARZ') !== -1) {
              badgeImageList.push('Starz_2016.svg');
            }
          }
        });
    });

    if (badgeImageList.length) {
      _offerToModify = { ..._offerToModify, badgeImageList, hasBadges: true };
    }
  }

  if (_offerToDetail.AutoPayDiscount) {
    const amount = round(_offerToDetail.AutoPayDiscount, 2);
    const discountCharge = {
      amount: amount,
      isBillCredit: true,
      category,
      description: 'AutoPay discount',
      rate: ''
      // '$' +
      // round(Math.abs(amount), 2) +
      // ' per month' +
      // (_offerToDetail.AutoPayDiscountTerm
      //   ? _offerToDetail.TermLength == 1 ||
      //     _offerToDetail.AutoPayDiscountTerm < _offerToDetail.TermLength
      //     ? ' first ' +
      //       (_offerToDetail.AutoPayDiscountTerm === 1
      //         ? 'month'
      //         : _offerToDetail.AutoPayDiscountTerm + ' months')
      //     : ''
      //   : '')
    };

    _offerToModify.detailList.push(discountCharge);

    dictChargeGroup['AutoPay Discount'] = {
      selectedValue: 'AutoPay Discount',
      chargeList: [
        {
          group: 'AutoPay Discount',
          name: 'AutoPay Discount',
          monthlyAmount: amount,
          purchaseAmount: null,
          isRequired: true,
          maxCount: 1,
          includedInPrice: true,
          offerID: _offerToDetail.OfferID
        }
      ]
    };
  }

  _offerToModify.chargeDictionary = dictChargeGroup;

  let subTotal = 0;
  for (let i = 0; i < _offerToModify.detailList.length; i++) {
    let detail = _offerToModify.detailList[i];
    subTotal += detail.amount;
    detail.subtotal = subTotal;
    _offerToModify.detailList[i] = detail;
  }

  return _offerToModify;
}

export function getProductTypeDescription(offer) {
  if (offer.ProductTypeID === 1) {
    let serviceList = [];
    if (offer.InternetRequired) {
      serviceList.push('Internet');
    }

    if (offer.TVRequired) {
      serviceList.push('TV');
    }

    if (offer.PhoneRequired) {
      serviceList.push('Phone');
    }

    if (offer.HomeSecurityRequired) {
      serviceList.push('Home Security');
    }

    let productTypeDescription = '';
    serviceList.map((service, serviceIndex) => {
      if (serviceIndex > 0 && serviceIndex === serviceList.length - 1) {
        productTypeDescription += ' & ';
      } else if (serviceIndex > 0) {
        productTypeDescription += ', ';
      }

      productTypeDescription += service;
    });

    return productTypeDescription;
  } else {
    return offer.ProductTypeName;
  }
}

function findAssociatedOffer(offerList, offer, fieldPrefix) {
  let _offer = { ...offer };

  if (_offer[fieldPrefix + '_OfferID']) {
    const _otherOffer = offerList.filter(
      (otherOffer) => otherOffer.OfferID === _offer[fieldPrefix + '_OfferID']
    )[0];
    if (_otherOffer) {
      _offer[fieldPrefix + '_ProviderName'] = _otherOffer.ProviderName;
      _offer[fieldPrefix + '_OfferName'] = _otherOffer.OfferName;
    }
  }

  return _offer;
}

function listBundleOptions(offer) {
  return Object.keys(offer).filter(
    (prop) =>
      prop.indexOf('Bundle') === 0 &&
      prop.indexOf('OfferID') !== -1 &&
      offer[prop]
  );
}

function getTermRates(offer) {
  let termRateText = '';
  Object.keys(offer).map((field) => {
    if (field.indexOf('Term_Rate') === 0 && field.indexOf('_Amount') !== -1) {
      //if (offer[field] > offer.PriceDollars) {
      const fieldPrefix = field.split('_Amount')[0];
      const termStart = offer[fieldPrefix + '_Start'] - 1;
      termRateText =
        '$' +
        round(offer[fieldPrefix + '_Amount'], 2).toFixed(2) +
        ' after ' +
        termStart +
        ' month' +
        (termStart === 1 ? '' : 's');
      return;
      //}
    }
  });

  return termRateText;
}

export function checkOffersChanged(newOfferList, oldOfferList) {
  let offersChanged = '';

  if (oldOfferList && oldOfferList.length) {
    if (newOfferList && newOfferList.length) {
      if (newOfferList.length === oldOfferList.length) {
        if (
          newOfferList.filter(
            (newOffer) =>
              oldOfferList.filter(
                (existingOffer) =>
                  existingOffer.ProviderName === newOffer.ProviderName &&
                  existingOffer.OfferName === newOffer.OfferName &&
                  existingOffer.ApiExtractionInProgress ===
                    newOffer.ApiExtractionInProgress
              ).length === 0
          ).length > 0
        ) {
          offersChanged = 'At least one offer changed';
        }
      } else {
        offersChanged = 'Number of offers changed';
      }
    } else {
      offersChanged = 'Offers were previously populated and now they are empty';
    }
  } else if (newOfferList && newOfferList.length) {
    offersChanged = 'Offers were previously empty and now they are populated';
  }

  return offersChanged;
}

export function getCheckoutRoute(offer) {
  if (offer) {
    switch (offer.ProductTypeID) {
      case 6:
        return '/electric-checkout/';
    }
  }

  return '/internet-checkout/';
}

export function getPlanSelectionRoute(offer, brandId) {
  switch (offer.ProductTypeID) {
    case 1:
      return (
        '/home-services?services=' +
        offer.ProviderServiceList.split(' ').join('')
      );
      break;
    case 6:
      return brandId == 'sc' ? '/' : '/electric-rates';
      break;
    default:
      break;
  }
}
