import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';

import ProviderOption from '../PropertyProfile/ProviderOption';

export default function ProviderMenu({
  providerService,
  setProviderServiceValue,
  setProviderValue,
  providerList,
  disabled,
  noRadio
}) {
  return (
    <FormControl fullWidth className={noRadio ? 'no-radio' : ''}>
      <RadioGroup
        value={providerService.preferredProviderID}
        onChange={(event) => {
          let newValue =
            event.target.value === '' ? null : Number(event.target.value);
          setProviderServiceValue(providerService, {
            preferredProviderID: newValue
          });
        }}>
        {providerList.map((provider) => (
          <ProviderOption
            providerService={providerService}
            setProviderServiceValue={setProviderServiceValue}
            setProviderValue={setProviderValue}
            provider={provider}
            disabled={disabled}
            noRadio={noRadio}
          />
        ))}

        <ProviderOption
          providerService={providerService}
          setProviderServiceValue={setProviderServiceValue}
          setProviderValue={setProviderValue}
          provider={{ ProviderID: 0, ProviderName: 'Other' }}
          disabled={disabled}
        />

        {/* <ProviderOption
            providerService={providerService}
            setProviderServiceValue={setProviderServiceValue}
            setProviderValue={setProviderValue}
            provider={{ ProviderID: null, ProviderName: '' }}
            disabled={disabled}
          /> */}
      </RadioGroup>
    </FormControl>
  );
}
