import React, { useRef } from 'react';

import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

import ResidentOnboardingStatusBadge from '../ResidentOnboardingStatusBadge';

function ResidentOnboardingChecklist({
  residentOnboarding,
  title,
  residentID,
  otherTasks,
  providerServiceList,
  parentRef
}) {
  function getItemList() {
    return residentOnboarding &&
      residentOnboarding.Checklist &&
      residentOnboarding.Header
      ? residentOnboarding.Checklist.filter((item) =>
          item.Grouping !== 'pet-interview' &&
          item.Grouping !== 'pet-esa' &&
          residentOnboarding.Header.ResidentStatusID === 4
            ? item.OnboardingSection === 'applicant'
            : residentOnboarding.Header.ResidentStatusID === 6
            ? item.OnboardingSection === 'notice'
            : item.OnboardingSection === 'pending'
        )
          .filter(
            (item) =>
              (!providerServiceList && !otherTasks) ||
              (providerServiceList &&
                providerServiceList.filter(
                  (providerService) =>
                    providerService.ProviderServiceID === item.ProviderServiceID
                ).length) ||
              (otherTasks &&
                !item.ProviderServiceID &&
                (otherTasks === true || otherTasks === item.Grouping))
          )
          .filter((item) => item.ResidentOnboardingStatusID != 5)
      : [];
  }
  return getItemList().length > 1 ? (
    <div className="checklist">
      {title ? (
        <h2>
          <PlaylistAddCheckIcon /> {title}
        </h2>
      ) : null}

      {!residentID && otherTasks ? (
        <p>
          <strong>
            The following information must be provided to, and accepted by, the
            property prior to your move-in date.
          </strong>
        </p>
      ) : null}

      <ul>
        {getItemList().map((item) => (
          <>
            {item.Grouping === 'walkthrough' ? (
              <p style={{ marginLeft: '-5px', marginTop: '20px' }}>
                <strong>Post Move-in Requirements:</strong>
              </p>
            ) : null}
            <li key={item.Description + '-' + item.DisplayOrder}>
              <Button
                onClick={() => {
                  const targetClass =
                    item.Grouping +
                    (item.ProviderServiceID
                      ? '-' + item.ProviderServiceID
                      : '') +
                    (item.ResidentVehicleID
                      ? '-' + item.ResidentVehicleID
                      : '') +
                    (item.ResidentPetID ? '-' + item.ResidentPetID : '') +
                    (item.CalendarID ? '-' + item.CalendarID : '');

                  const target =
                    parentRef.current.getElementsByClassName(targetClass)[0];

                  if (target) {
                    target.scrollIntoView({
                      block: 'start',
                      inline: 'nearest',
                      behavior: 'smooth'
                    });
                  }
                }}>
                <div
                  className={
                    'checklist-check' +
                    (item.ResidentOnboardingStatusID === 2 ? ' pending' : '') +
                    (item.ResidentOnboardingStatusID === 3
                      ? ' completed'
                      : item.ResidentOnboardingStatusID === 0
                      ? ' scheduled'
                      : item.ResidentOnboardingStatusID === 6
                      ? ' optional'
                      : '')
                  }>
                  <CheckIcon />
                </div>
                <ResidentOnboardingStatusBadge
                  status={item}
                  isProperty={residentID ? true : false}
                />
                <span className="link">
                  {item.ProviderServiceID ? 'Proof of ' : ''} {item.Description}
                </span>
              </Button>
            </li>
          </>
        ))}
      </ul>
    </div>
  ) : null;
}

export default ResidentOnboardingChecklist;
