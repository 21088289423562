import React, { useEffect, useRef, useState } from 'react';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export default function RadioOption({
  setProviderServiceValue,
  question,
  providerService,
  field,
  children,
  disabled,
  className,
  subOptionOnly,
  serviceProfile,
  hideRadioOptions
}) {
  return (
    <div
      className={
        'radio-option' +
        (disabled ? ' disabled' : '') +
        (className ? ' ' + className : '') +
        (subOptionOnly ? ' sub-option-only ' : '') +
        (hideRadioOptions ? ' hide-radio-options ' : '')
      }>
      <p>{question}</p>

      <FormControl className="radio-options">
        <RadioGroup
          row
          name="service-available"
          value={providerService[field]}
          onChange={(event) => {
            let options = {
              [field]: event.target.value == 'true'
            };

            switch (field) {
              case 'IsServiceRequired':
                if (options.IsServiceRequired) {
                  console.log(
                    'IsServiceRequired' + providerService.ProviderServiceName
                  );
                  options = {
                    ...options,
                    IsServiceAvailable: null,
                    IsServiceIncludedInRent: null,
                    IsServicePaidWithRent: null,
                    IsServicePaidToThirdParty: null,
                    IsServicePaidToOther: null,
                    CustomMessage: null
                  };
                } else {
                  options = {
                    ...options,
                    IsServiceProofDeliver: null,
                    IsServiceProofUpload: null,
                    IsServiceProofUseUrl: null,
                    IsServiceProofRequired: null,
                    ServiceProofUrl: null
                  };
                }
                break;
              case 'IsServiceProofRequired':
                if (!options.IsServiceProofRequired) {
                  options = {
                    ...options,
                    IsServiceProofDeliver: null,
                    IsServiceProofUpload: null,
                    IsServiceProofUseUrl: null,
                    ServiceProofUrl: null
                  };
                } else {
                  options = {
                    ...options,
                    IsServiceProofUpload:
                      serviceProfile &&
                      serviceProfile.Header &&
                      serviceProfile.Header.UseResidentDashboard
                        ? true
                        : null
                  };
                }
                break;
            }

            setProviderServiceValue(providerService, {
              ...options
            });
          }}>
          <FormControlLabel
            value={true}
            control={<Radio disabled={disabled} />}
            label={'Yes'}
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            disabled={disabled}
            label={'No'}
          />
        </RadioGroup>
      </FormControl>

      {children ? children : null}
    </div>
  );
}
