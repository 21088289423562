import React, { useState, useRef, useEffect, version } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import PetsIcon from '@mui/icons-material/Pets';

import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import Alert from '@mui/material/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import * as webSocketActions from '../../../redux/actions/webSocketActions';

import { saveResidentTimeslotApproval } from '../../../api/residentOnboardingApi';

import ResidentOnboardingFileUpload from '../ResidentOnboardingFileUpload';
import ResidentOnboardingStatusBadge from '../ResidentOnboardingStatusBadge';
import ResidentOnboardingPhotoUpload from '../ResidentOnboardingPhotoUpload';
import ResidentOnboardingStatusUpdate from '../ResidentOnboardingStatusUpdate';

import 'react-dropzone-uploader/dist/styles.css';
import '../index.css';

function ResidentOnboardingReservation({
  actions,
  serviceAddress,
  siteConfig,
  residentOnboarding,
  refreshResidentOnboarding,
  updateResidentOnboardingHeader,
  stateList,
  residentID,
  closeOnboardingRow,
  calendarType
}) {
  const [editMode, setEditMode] = useState(null);
  const [residentOnboardingStatus, setResidentOnboardingStatus] =
    useState(null);
  const [residentStatusUpdate, setResidentStatusUpdate] = useState(null);

  const [selectedTimeslot, setSelectedTimeslot] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [confirmTimeslot, setConfirmTimeslot] = useState(null);
  const maxTimeSlotCount = 1;

  useEffect(() => {
    if (residentOnboarding && !selectedTimeslot) {
      loadSelectedTimeslot();
    }
  }, [residentOnboarding]);

  useEffect(() => {
    if (residentOnboarding) {
      let _residentOnboardingStatus = null;
      if (residentOnboarding.Header.MoveTimeslotApproved) {
        _residentOnboardingStatus = {
          ResidentOnboardingStatusID: 3,
          ResidentOnboardingStatusName: 'Scheduled'
        };
      } else if (
        residentOnboarding.Header.MoveTimeslotRequested1 &&
        residentOnboarding.Header.MoveTimeslotRequested2 &&
        residentOnboarding.Header.MoveTimeslotRequested3 &&
        !residentOnboarding.Header.MoveTimeslotNote
      ) {
        _residentOnboardingStatus = {
          ResidentOnboardingStatusID: 2,
          ResidentOnboardingStatusName: 'Review Pending'
        };
      } else {
        _residentOnboardingStatus = {
          ResidentOnboardingStatusID: 1,
          ResidentOnboardingStatusName: 'Action Required'
        };
      }

      setResidentOnboardingStatus(_residentOnboardingStatus);

      if (editMode === null) {
        setEditMode(_residentOnboardingStatus.ResidentOnboardingStatusID === 1);
      }
    }
  }, [residentOnboarding]);

  function loadSelectedTimeslot() {
    setSelectedTimeslot(
      residentOnboarding.ReservationTimeSlotList[calendarType]
    );

    setSelectedDate(
      new Date(
        Object.keys(residentOnboarding.ReservationTimeSlotList[calendarType])[0]
      )
    );
  }

  return residentOnboardingStatus ? (
    <div className="resident-onboarding-item reservation">
      <div
        className={
          'form-header' +
          (residentOnboarding.Header.MoveTimeslotNote ? ' with-note' : '')
        }>
        {/* <ResidentOnboardingStatusBadge
          status={residentOnboardingStatus}
          isProperty={residentID ? true : false}
        /> */}

        <h3>
          {residentOnboarding.Header.ElevatorReservationInclude
            ? 'Elevator'
            : ''}{' '}
          {residentOnboarding.Header.LoadingDockRegistrationInclude
            ? (residentOnboarding.Header.ElevatorReservationInclude
                ? ' and '
                : '') + 'Loading Dock'
            : ''}{' '}
          Reservation
        </h3>

        <div className="save-button">
          {/* {residentID &&
          residentOnboardingStatus.ResidentOnboardingStatusID === 2 ? (
            <>
              <Button
                variant="contained"
                className="accept"
                disabled={!confirmTimeslot}
                color="primary"
                onClick={() => {
                  setResidentStatusUpdate({
                    dataTypeLabel: 'reservation',

                    residentOnboardingStatusID: 3
                  });
                }}>
                <DoneIcon /> Accept
              </Button>

              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setResidentStatusUpdate({
                    residentOnboardingStatusID: 1
                  });
                }}>
                <ClearIcon /> Reject
              </Button>
            </>
          ) : null} */}

          {editMode ? (
            <>
              <Button
                variant="contained"
                color="primary"
                //className={requiredTimeslotCount > 0 ? 'disabled' : ''}
                //disabled={requiredTimeslotCount > 0}
                onClick={() => {
                  setEditMode(false);
                }}>
                Save
              </Button>

              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  loadSelectedTimeslot();
                  setEditMode(false);
                }}>
                Cancel
              </Button>
            </>
          ) : null}

          {!editMode ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setEditMode(true)}>
              Edit
            </Button>
          ) : null}
        </div>

        {residentOnboarding.Header.MoveTimeslotNote ? (
          <Alert
            severity={
              residentOnboardingStatus.ResidentOnboardingStatusID === 3
                ? 'success'
                : 'warning'
            }>
            Note from Property: {residentOnboarding.Header.MoveTimeslotNote}
          </Alert>
        ) : null}
      </div>

      <div className="form-content">
        {residentOnboarding.Header.ElevatorRegistrationText ? (
          residentOnboardingStatus.ResidentOnboardingStatusID === 1 ||
          editMode ? (
            <div
              className="message"
              dangerouslySetInnerHTML={{
                __html:
                  residentOnboarding.Header.ElevatorRegistrationText.split(
                    '\n'
                  ).join('<br />')
              }}></div>
          ) : residentOnboardingStatus.ResidentOnboardingStatusID === 2 ? (
            residentID ? (
              <div className="message">
                The resident would like to reserve the{' '}
                {residentOnboarding.Header.ElevatorReservationInclude
                  ? 'Elevator'
                  : ''}{' '}
                {residentOnboarding.Header.LoadingDockRegistrationInclude
                  ? (residentOnboarding.Header.ElevatorReservationInclude
                      ? ' and '
                      : '') + 'Loading Dock'
                  : ''}{' '}
                during one of the following timeslots. Select a timeslot below
                to confirm the reservation.
              </div>
            ) : (
              <div className="message">
                Your reservation request has been submitted. Someone with the
                property will get back to you once your reservation is
                confirmed.
              </div>
            )
          ) : (
            <div className="message confirmed">
              Your reservation has been confirmed for:
              <br />
              <br />
              <strong>
                {serviceAddress.Address.DefaultStartDate
                  ? moment(serviceAddress.Address.DefaultStartDate).format(
                      'M/D/YYYY'
                    ) + ' from '
                  : ''}{' '}
                {residentOnboarding.Header.MoveTimeslotApproved}
              </strong>
            </div>
          )
        ) : null}

        <div className={'reservation-form ' + (!editMode ? 'disabled' : '')}>
          <div className="date">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDatePicker
                orientation="landscape"
                openTo="day"
                value={selectedDate}
                shouldDisableDate={(date) => {
                  return (
                    date < new Date(residentOnboarding.Header.DateLeaseStart)
                  );
                }}
                views={['day']}
                disableHighlightToday={true}
                disablePast
                onChange={(date) => {
                  setSelectedDate(date.toJSON());
                }}
              />
            </LocalizationProvider>
          </div>

          {selectedTimeslot ? (
            <div className="timeslot-list">
              <>
                <Table className="timeslot-table">
                  <TableHead>
                    <TableRow>
                      {Object.keys(selectedTimeslot).map((date) => (
                        <TableCell
                          style={{ fontWeight: 'bold', textAlign: 'center' }}>
                          {moment(date).format('ddd, MMM Do')}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedTimeslot[Object.keys(selectedTimeslot)[0]].map(
                      (slotPlaceholder, slotIndex) => (
                        <TableRow>
                          {Object.keys(selectedTimeslot).map(
                            (date, dateIndex) =>
                              [selectedTimeslot[date][slotIndex]].map(
                                (timeSlot) => (
                                  <TableCell>
                                    <Button
                                      disabled={!timeSlot.IsAvailable}
                                      onClick={() => {
                                        let _selectedTimeSlotCount =
                                          timeSlot.IsSelected ? 0 : 1;

                                        setSelectedTimeslot({
                                          ...selectedTimeslot,
                                          [date]: selectedTimeslot[date].map(
                                            (_timeSlot) => {
                                              if (
                                                _timeSlot.StartTime ===
                                                timeSlot.StartTime
                                              ) {
                                                return {
                                                  ..._timeSlot,
                                                  IsSelected:
                                                    !timeSlot.IsSelected
                                                };
                                              }

                                              let _isSelected =
                                                _timeSlot.IsSelected;

                                              if (_isSelected) {
                                                if (
                                                  _selectedTimeSlotCount >=
                                                  maxTimeSlotCount
                                                ) {
                                                  _isSelected = false;
                                                } else {
                                                  _selectedTimeSlotCount++;
                                                }
                                              }

                                              return {
                                                ..._timeSlot,
                                                IsSelected: _isSelected
                                              };
                                            }
                                          )
                                        });
                                      }}
                                      className="time-slot-button"
                                      variant={
                                        timeSlot.IsSelected
                                          ? 'contained'
                                          : 'outlined'
                                      }>
                                      <p className="scheduled-time">
                                        {timeSlot.StartTime} -{' '}
                                        {timeSlot.EndTime}
                                      </p>
                                      <p className="status-text">
                                        {timeSlot.Label}
                                      </p>
                                    </Button>
                                  </TableCell>
                                )
                              )
                          )}
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </>
            </div>
          ) : null}
        </div>

        {residentStatusUpdate ? (
          <ResidentOnboardingStatusUpdate
            type="reservation"
            dataTypeLabel={'reservation'}
            residentOnboardingStatusID={
              residentStatusUpdate.residentOnboardingStatusID
            }
            saveFunc={(note) => {
              saveResidentTimeslotApproval(
                residentID,
                residentStatusUpdate.residentOnboardingStatusID === 3
                  ? confirmTimeslot
                  : null,
                note
              ).then((result) => {
                setResidentStatusUpdate(null);
                refreshResidentOnboarding();
                closeOnboardingRow();
              });
            }}
            closeFunc={() => setResidentStatusUpdate(null)}
          />
        ) : null}
      </div>
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    stateList: state.commonData.stateList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResidentOnboardingReservation);
