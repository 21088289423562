import React, { useState, useRef, useEffect, version } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import Dropzone from 'react-dropzone-uploader';

import Button from '@mui/material/Button';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { getConfig } from '../../../api/residentOnboardingApi';
import { getCookie } from '../../../api/dataApi';

import * as webSocketActions from '../../../redux/actions/webSocketActions';
import * as residentOnboardingActions from '../../../redux/actions/residentOnboardingActions';

import 'react-dropzone-uploader/dist/styles.css';
import '../index.css';
import { IconButton, TextField } from '@mui/material';

function getResidentFileToken(residentID) {
  const headers = getConfig(residentID).headers;

  return btoa(
    headers.key +
      ':' +
      headers.visitorKey +
      ':' +
      headers.visitKey +
      ':' +
      getCookie('mi-invitation') +
      ':' +
      getCookie('mi-d') +
      (headers.property ? ':' + headers.property + ':' + residentID : '') +
      (headers.user ? ':' + headers.user : '')
  );
}

export function getFileDownloadUrl(file, isImage, residentID) {
  return (
    process.env.GATSBY_API_URL +
    'ResidentFile/DownloadFile?residentFileID=' +
    file.ResidentFileID +
    '&isImage=' +
    (isImage ? 'true' : 'false') +
    '&token=' +
    getResidentFileToken(residentID)
  );
}

function ResidentOnboardingFileUpload({
  actions,
  serviceAddress,
  siteConfig,
  residentOnboarding,
  refreshResidentOnboarding,
  service,
  disabled,
  disabledText,
  providerServiceID,
  residentVehicleID,
  residentPetID,
  residentEventID,
  residentFileTypeID,
  label,
  description,
  isImage,
  residentID,
  requireFileName,
  optional,
  uploadedFileList,
  setUploadedFileList,
  grouping
}) {
  const [fileList, setFileList] = useState([]);
  const [deleteFile, setDeleteFile] = useState(null);
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    setFileList(
      residentOnboarding.FileList.filter(
        (file) =>
          file.ResidentFileTypeID === residentFileTypeID &&
          ((providerServiceID &&
            file.ProviderServiceID === providerServiceID) ||
            (residentVehicleID &&
              file.ResidentVehicleID === residentVehicleID) ||
            (residentPetID && file.ResidentPetID === residentPetID) ||
            (residentEventID !== undefined &&
              file.ResidentEventID === residentEventID) ||
            (grouping && file.Grouping === grouping))
      )
    );
  }, [residentOnboarding, providerServiceID]);

  useEffect(() => {
    if (
      fileList &&
      setUploadedFileList &&
      (!uploadedFileList || uploadedFileList.length !== fileList)
    ) {
      setUploadedFileList(fileList.map((file) => file.ResidentFileID));
    }
  }, [fileList]);

  function getFileTypeName(file) {
    return requireFileName
      ? file.Description
      : providerServiceID === 37
      ? 'Proof of Coverage'
      : file.ResidentFileTypeName;
  }

  function getFileName(file) {
    if (file.FileName.indexOf('_') !== -1) {
      let _fileNameArray = file.FileName.split('_');
      _fileNameArray.splice(0, 1);
      return _fileNameArray.join('_').split('?')[0];
    }

    return file.FileName;
  }

  return (
    <div className={'document-upload' + (disabled ? ' disabled' : '')}>
      <label>
        {label}
        {optional ? '' : '*'}
      </label>
      {fileList.length ? (
        <div className="file-list">
          <TableContainer component={Paper}>
            <Table aria-label="simple table" stickyHeader={true}>
              <TableHead>
                <TableRow>
                  <TableCell>File</TableCell>
                  <TableCell style={{ textAlign: 'right' }}>Uploaded</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fileList.map((file) => (
                  <>
                    <TableRow>
                      <TableCell className="filename-container">
                        <Button
                          href={getFileDownloadUrl(file, isImage, residentID)}
                          target="_blank">
                          {file.FileName.indexOf('.pdf') !== -1 ? (
                            <PictureAsPdfIcon />
                          ) : (
                            <TextSnippetIcon />
                          )}
                          {getFileTypeName(file)} <em>{getFileName(file)}</em>
                        </Button>
                        <IconButton
                          onClick={() => setDeleteFile(file)}
                          className="delete">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell style={{ textAlign: 'right' }}>
                        {moment(file.Created).format('M/D/YYYY')}
                      </TableCell>
                    </TableRow>
                    {deleteFile &&
                    deleteFile.ResidentFileID === file.ResidentFileID ? (
                      <TableRow>
                        <TableCell colSpan={2} className="delete-row">
                          <p>
                            Are you sure you want to delete "
                            {getFileName(deleteFile)}"? The file will be
                            permanently removed.
                          </p>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              actions.deleteFile(
                                deleteFile.ResidentFileID,
                                residentID
                              );
                            }}>
                            Delete
                          </Button>
                          <Button onClick={() => setDeleteFile(null)}>
                            Cancel
                          </Button>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : null}

      {requireFileName ? (
        <TextField
          value={fileName}
          onChange={(event) => setFileName(event.target.value)}
          label={label + ' Name'}
          required={true}
          fullWidth
          variant="filled"
          style={{ marginTop: '10px', marginBottom: '0px' }}
        />
      ) : null}

      <Dropzone
        disabled={requireFileName && !fileName}
        getUploadParams={() => {
          let _headers = {
            'Cache-Control': ''
          };

          return {
            url:
              process.env.GATSBY_API_URL +
              'ResidentFile/UploadFile?residentFileTypeID=' +
              residentFileTypeID +
              (providerServiceID
                ? '&providerServiceID=' + providerServiceID
                : '') +
              (residentVehicleID
                ? '&residentVehicleID=' + residentVehicleID
                : '') +
              (residentPetID ? '&residentPetID=' + residentPetID : '') +
              (residentEventID !== undefined
                ? '&residentEventID=' + residentEventID
                : '') +
              (grouping ? '&grouping=' + encodeURIComponent(grouping) : '') +
              (requireFileName || description
                ? '&description=' +
                  encodeURIComponent(requireFileName ? fileName : description)
                : '') +
              (residentID ? '&residentID=' + residentID : ''),
            method: 'POST',
            withCredentials: false,
            headers: {
              ..._headers,
              ...getConfig(residentID).headers
            }
          };
        }}
        onChangeStatus={({ meta, remove, xhr }, status) => {
          if (status == 'done') {
            remove();
            try {
              const result = JSON.parse(xhr.response);
              if (result.UpdatedFileList) {
                actions.updateFileList(result.UpdatedFileList);
              }

              if (requireFileName) {
                setFileName('');
              }
            } catch (e) {}
          }
        }}
        maxFiles={1}
        multiple={false}
        canCancel={false}
        accept=".jpeg,.jpg,.png,.gif,.bmp,.pdf"
        inputContent={
          requireFileName && !fileName ? (
            <>Enter a file name to upload</>
          ) : (
            <>
              Click to upload{' '}
              {fileList.length ? 'another file' : label ? label : 'a file'}
              {disabled && disabledText ? (
                <div className="disabled-text">{disabledText}</div>
              ) : null}
            </>
          )
        }
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      updateFileList: bindActionCreators(
        residentOnboardingActions.updateFileList,
        dispatch
      ),
      deleteFile: bindActionCreators(
        residentOnboardingActions.deleteFile,
        dispatch
      )
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResidentOnboardingFileUpload);
