import React, { useState, useRef, useEffect } from 'react';
import {
  Map,
  InfoWindow,
  Marker,
  GoogleApiWrapper,
  Polygon
} from 'google-maps-react';
import { useHistory } from 'react-router-dom';
import { Button, TextField, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/material/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import './index.css';
import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import { ArrowUpward } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import PinDropIcon from '@mui/icons-material/PinDrop';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
function AreaMap({
  actions,
  serviceAddress,
  mode,
  google,
  backgroundMode,
  mapSummary
}) {
  const map = useRef();
  const infoWindow = useRef();
  const history = useHistory();
  const addressMarker = useRef();
  const schoolMarkerDictionary = useRef();

  useEffect(() => {
    if (serviceAddress) {
      document.title = getFullAddress();
    }
  }, [serviceAddress]);

  function getFullAddress() {
    return (
      serviceAddress.Address.Address +
      (serviceAddress.Address.Secondary
        ? ' ' + serviceAddress.Address.Secondary
        : '') +
      ', ' +
      serviceAddress.Address.City +
      ', ' +
      serviceAddress.Address.State +
      ' ' +
      serviceAddress.Address.Zip
    );
  }

  function addAddressMarker() {
    if (!addressMarker.current) {
      var position = {
        lat: serviceAddress.Address.Latitude,
        lng: serviceAddress.Address.Longitude
      };

      const mapMarker = new google.maps.Marker({
        position: position,
        // icon: {
        //   ...marker,
        //   fillColor: loadServiceAddressOption[key].ColorCode
        // },
        title: getFullAddress(),
        map: map.current
      });

      addressMarker.current = mapMarker;

      mapMarker.setMap(map.current);

      const offsetX = -0.33; // move center one quarter map width left
      const offsetY = -0.25; // move center one quarter map height down

      const span = map.current.getBounds().toSpan(); // a latLng - # of deg map spans

      var newCenter = {
        lat: position.lat + span.lat() * offsetY,
        lng: position.lng + span.lng() * offsetX
      };

      map.current.panTo(newCenter);
    }
  }

  function addSchoolMarkers() {
    for (const schoolType in serviceAddress.SchoolList) {
      if (!schoolMarkerDictionary.current[schoolType]) {
        const school = serviceAddress.SchoolList[schoolType];
        var position = {
          lat: school.Latitude,
          lng: school.Longitude
        };

        const mapMarker = new google.maps.Marker({
          position: position,
          // icon: {
          //   ...marker,
          //   fillColor: loadServiceAddressOption[key].ColorCode
          // },
          title: school.SchoolName,
          map: map.current
        });

        schoolMarkerDictionary.current[schoolType] = mapMarker;

        mapMarker.setMap(map.current);

        //   const offsetX = -0.33; // move center one quarter map width left
        //   const offsetY = 0.25; // move center one quarter map height down

        //   const span = map.current.getBounds().toSpan(); // a latLng - # of deg map spans

        //   var newCenter = {
        //     lat: position.lat + span.lat() * offsetY,
        //     lng: position.lng + span.lng() * offsetX
        //   };

        //map.current.panTo(mapMarker.position);
      }
    }
  }

  function zoomToSchool(schoolType) {
    if (schoolMarkerDictionary.current[schoolType]) {
      map.current.panTo(schoolMarkerDictionary.current[schoolType].position);
    }
  }

  function initMap(mapProps, _map) {
    map.current = _map;

    google.maps.event.addListener(_map, 'bounds_changed', function () {
      if (serviceAddress) {
        addAddressMarker();

        if (!schoolMarkerDictionary.current) {
          schoolMarkerDictionary.current = {};
        }
        addSchoolMarkers();
      }
    });

    // const drawingManager = new google.maps.drawing.DrawingManager({
    //   drawingMode: null,
    //   drawingControl: false, //flip true to allow adding new polygons
    //   drawingControlOptions: {
    //     position: google.maps.ControlPosition.TOP_LEFT,
    //     drawingModes: [
    //       //google.maps.drawing.OverlayType.MARKER,
    //       google.maps.drawing.OverlayType.POLYGON
    //     ]
    //   },
    //   polygonOptions: {
    //     fillColor: '#ff3366',
    //     fillOpacity: 0.35,
    //     strokeWeight: 2,
    //     strokeColor: '#ff3366',
    //     clickable: true,
    //     editable: true,
    //     draggable: true,
    //     zIndex: 1
    //   },
    //   map: map
    // });

    google.maps.Polygon.prototype.getBounds = function () {
      var bounds = new google.maps.LatLngBounds();
      var paths = this.getPaths();
      var path;
      for (var i = 0; i < paths.getLength(); i++) {
        path = paths.getAt(i);
        for (var ii = 0; ii < path.getLength(); ii++) {
          bounds.extend(path.getAt(ii));
        }
      }
      return bounds;
    };

    infoWindow.current = new google.maps.InfoWindow({
      content: '<div class="loading">Loading...</div>'
    });
  }

  return serviceAddress ? (
    <>
      <div className="map-container">
        <Map
          google={google}
          view
          mapType="satellite"
          className={'map'}
          zoom={20}
          onReady={initMap}
          panControl={backgroundMode ? true : null}
          disableDefaultUI={backgroundMode ? true : null}
          gestureHandling={backgroundMode ? 'none' : ''}
          yesIWantToUseGoogleMapApiInternals
          initialCenter={{
            lat: 39.8282, //USA center
            lng: -98.5795
          }}></Map>

        {serviceAddress.SchoolList &&
        Object.keys(serviceAddress.SchoolList).length ? (
          <div className="school-list-container">
            <ul className="school-list">
              {Object.keys(serviceAddress.SchoolList).map((schoolType) => (
                <li className="school">
                  <h4>
                    Grades {serviceAddress.SchoolList[schoolType].LowGrade} -{' '}
                    {serviceAddress.SchoolList[schoolType].HighGrade}
                  </h4>
                  <h3>{serviceAddress.SchoolList[schoolType].SchoolName}</h3>

                  <Button
                    className="data-point"
                    onClick={() => zoomToSchool(schoolType)}
                    startIcon={<PinDropIcon />}>
                    {serviceAddress.SchoolList[schoolType].Address}
                    <br />
                    {serviceAddress.SchoolList[schoolType].City},{' '}
                    {serviceAddress.SchoolList[schoolType].State}{' '}
                    {serviceAddress.SchoolList[schoolType].Zip}
                  </Button>
                  <Button
                    className="data-point"
                    href={'tel:' + serviceAddress.SchoolList[schoolType].Phone}
                    startIcon={<PhoneAndroidIcon />}>
                    {serviceAddress.SchoolList[schoolType].Phone}
                  </Button>

                  <div className="buttons">
                    <Button
                      variant="contained"
                      size="small"
                      href={serviceAddress.SchoolList[schoolType].WebsiteUrl}
                      target="_blank"
                      style={{ marginRight: '10px' }}>
                      School Website
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      href={
                        'https://www.google.com/maps/dir/?api=1&origin=' +
                        serviceAddress.Address.Address +
                        ', ' +
                        (serviceAddress.Address.Secondary
                          ? ' ' + serviceAddress.Address.Secondary
                          : '') +
                        ', ' +
                        serviceAddress.Address.City +
                        ', ' +
                        serviceAddress.Address.State +
                        ' ' +
                        serviceAddress.Address.Zip +
                        '&destination=' +
                        serviceAddress.SchoolList[schoolType].Address +
                        ', ' +
                        serviceAddress.SchoolList[schoolType].City +
                        ', ' +
                        serviceAddress.SchoolList[schoolType].State +
                        ' ' +
                        serviceAddress.SchoolList[schoolType].Zip
                      }
                      target="_blank">
                      Driving Directions
                    </Button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    </>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDvLqJv0ghq2bOYU8d9oK5DQUuV6vZ0pIM',
  libraries: ['drawing']
})(connect(mapStateToProps, mapDispatchToProps)(AreaMap));
