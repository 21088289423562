import React, { useState, useRef, useEffect, version } from 'react';
import { Button, IconButton, TextField, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import Grid from '@mui/material/Grid';
import PersonIcon from '@mui/icons-material/Person';
import DevicesIcon from '@mui/icons-material/Devices';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LaunchIcon from '@mui/icons-material/Launch';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailIcon from '@mui/icons-material/Mail';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import './index.css';
import * as webSocketActions from '../../redux/actions/webSocketActions';
import IntroText from '../IntroText';
import TaskMenu from '../TaskMenu';

const $ = typeof window !== 'undefined' ? window.$ : {};

import { useQueryParam } from 'gatsby-query-params';

function StateServiceList({ actions, serviceAddress, mode }) {
  let requestedType = useQueryParam('type', 'Current').toLowerCase();
  const [type, setType] = useState(null);

  const [showInstructionsPopup, setShowInstructionsPopup] = useState(null);

  const [titleContent, setTitleContent] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);

  useEffect(() => {
    setType(requestedType);
  }, [requestedType]);

  useEffect(() => {
    if (serviceAddress && serviceAddress.Checklist) {
      const _section = serviceAddress.Checklist.filter(
        (section) => section.ServiceSectionID === 6
      )[0];
      setSelectedSection(_section);
    }
  }, [serviceAddress]);

  function checkIncludeForType(stateService) {
    switch (stateService.StateServiceTypeID) {
      case 1:
      case 2:
      case 3:
        return type === 'new';
      case 4:
      case 5:
      case 6:
        return type === 'current';
      default:
        return true;
    }
  }

  function getDomain(url) {
    try {
      return url.split('/')[2].split('www.').join('');
    } catch (e) {}
    return 'Continue to Website';
  }

  function cleanHtml(html) {
    const div = $('<div>' + html + '</div>');
    div.appendTo('document,body');
    div.find('p').each(function () {
      if ($(this).text().trim() !== '' && $(this).text().trim() !== '&nbsp;') {
        $(this).replaceWith($(this).html() + '<br /><br />');
      } else {
        $(this).remove();
      }
    });
    html = div.html();
    console.log('html', html);
    div.remove();
    return html;
    // .split('</p>')
    // .join('<br /><br />')
    // .split('<p>')
    // .join('')
    // .split('</p>')
    // .join('');
  }

  useEffect(() => {
    if (serviceAddress && serviceAddress.Checklist) {
      const section = serviceAddress.Checklist.filter(
        (section) => section.Route === '/change-address'
      )[0];
      if (section) {
        setTitleContent(
          section.ServiceList.filter(
            (service) =>
              service.ProviderList.filter(
                (provider) => provider.ProviderID === 0
              ).length > 0
          )[0]
        );
      }
    }
  }, [serviceAddress, type]);

  function getPrimaryButton(stateService) {
    let button = { label: '', url: '' };
    if (stateService.SignUpUrl && stateService.OnlineAvailable) {
      button.label = stateService.SignUpButtonLabel;
      button.url = stateService.SignUpUrl;
    } else if (stateService.AppointmentUrl && !stateService.OnlineAvailable) {
      button.label = stateService.ScheduleAppointmentLabel;
      button.url = stateService.AppointmentUrl;
    } else if (
      stateService.OfficeLocationUrl &&
      !stateService.AppointmentUrl &&
      !stateService.OnlineAvailable
    ) {
      button.label = stateService.OfficeLocationsLabel;
      button.url = stateService.OfficeLocationUrl;
    } else if (
      stateService.FormUrl &&
      !stateService.OfficeLocationUrl &&
      !stateService.AppointmentUrl &&
      !stateService.OnlineAvailable
    ) {
      button.label = stateService.PrintApplicationLabel;
      button.url = stateService.FormUrl;
    }

    return button;
  }

  return titleContent && serviceAddress.StateServiceList ? (
    <>
      <div className={'page-container state-service-list-container'}>
        {selectedSection ? (
          <>
            <h1>
              <TaskMenu section={selectedSection} page />

              <span className="keep-together">{selectedSection.Title}</span>
            </h1>
            <Tabs
              value={type}
              onChange={(event, newValue) => setType(newValue)}
              indicatorColor="primary"
              textColor="primary"
              centered>
              <Tab
                value="current"
                label={'Moving Within ' + serviceAddress.Address.StateName}
              />
              <Tab value="new" label={'Moving from Out of State'} />
            </Tabs>
          </>
        ) : null}

        <IntroText
          trackAs="state-services"
          text={selectedSection.IntroText}
          mobileText={selectedSection.IntroTextMobile}
        />

        <div className="page state-service-list">
          <div className="inner">
            <Grid container spacing={4} justify="center">
              {/* <Grid item xs={12} sm={12} md={12}>
                <div className="card header">
                  {' '}
                  <h2>
                    {titleContent.ProviderList[0].LogoFile ? (
                      <img src={titleContent.ProviderList[0].LogoFile} />
                    ) : null}{' '}
                    Residents Moving {type === 'new' ? 'to' : 'Within'}{' '}
                    {serviceAddress.Address.StateName}
                  </h2>
                </div>
              </Grid> */}
              {serviceAddress.StateServiceList.map((stateService, index) =>
                checkIncludeForType(stateService) ? (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={
                      3
                      // index === serviceAddress.StateServiceList.length - 1
                      //   ? 12
                      //   : 4
                    }>
                    <div className="card">
                      <TaskMenu
                        section={selectedSection}
                        page
                        subSection={stateService.StateServiceTypeName}
                        subSectionCount={4}
                      />

                      {[getPrimaryButton(stateService)].map((button) =>
                        button && button.url ? (
                          <a href={button.url} target="mi_offsite">
                            {' '}
                            <h2>{stateService.StateServiceTypeName}</h2>
                          </a>
                        ) : (
                          <h2>{stateService.StateServiceTypeName}</h2>
                        )
                      )}

                      <div className="state-service-logo-container">
                        {stateService.LogoFile ? (
                          <img src={stateService.LogoFile} />
                        ) : null}

                        {stateService.StateServiceTypeName.indexOf(
                          'Vehicle'
                        ) !== -1 && (
                          <span className="state-name">
                            {serviceAddress.Address.StateName}
                          </span>
                        )}
                      </div>

                      <div className="icon-list">
                        {stateService.SignUpPeriod !== null ? (
                          <div className="option-group">
                            <h5>Complete by:</h5>
                            <div className="icon calendar">
                              <CalendarTodayIcon />
                              <div
                                className={
                                  'inner-label' +
                                  (stateService.SignUpPeriod === 0
                                    ? ' small'
                                    : '')
                                }>
                                {stateService.SignUpPeriod === 0
                                  ? 'ASAP'
                                  : stateService.SignUpPeriod + ' days'}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <OptionIcon stateService={stateService} />
                      </div>

                      {stateService.InstructionsText ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: cleanHtml(stateService.InstructionsText)
                          }}></p>
                      ) : null}

                      <div className="outlined-button">
                        {stateService.InstructionsUrl ||
                        stateService.InstructionsPopupText ? (
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{
                              width: '100%',
                              fontWeight: '700',
                              maxWidth: '300px'
                            }}
                            onClick={() => {
                              if (
                                !stateService.InstructionsUrl &&
                                stateService.InstructionsPopupText
                              ) {
                                setShowInstructionsPopup(stateService);
                              }
                            }}
                            href={stateService.InstructionsUrl}
                            target={
                              stateService.InstructionsUrl ? '_blank' : '_self'
                            }>
                            {stateService.InstructionsButtonLabel}
                          </Button>
                        ) : null}
                      </div>
                      <div className="solid-button">
                        {[getPrimaryButton(stateService)].map((button) =>
                          button ? (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                width: '100%',
                                fontWeight: '700',
                                maxWidth: '300px'
                              }}
                              href={button.url}
                              target="mi_offsite">
                              {button.label}
                              <ChevronRightIcon />
                            </Button>
                          ) : null
                        )}

                        {/* {stateService.SignUpUrl &&
                        stateService.OnlineAvailable ? (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              width: '100%',
                              fontWeight: '700',
                              maxWidth: '300px'
                            }}
                            href={stateService.SignUpUrl}
                            target="_blank">
                            {stateService.SignUpButtonLabel}
                            <ChevronRightIcon />
                          </Button>
                        ) : null}

                        {stateService.AppointmentUrl &&
                        !stateService.OnlineAvailable ? (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              width: '100%',
                              fontWeight: '700',
                              maxWidth: '300px'
                            }}
                            href={stateService.AppointmentUrl}
                            target="_blank">
                            {stateService.ScheduleAppointmentLabel}{' '}
                            <ChevronRightIcon />
                          </Button>
                        ) : null}

                        {stateService.OfficeLocationUrl &&
                        !stateService.AppointmentUrl &&
                        !stateService.OnlineAvailable ? (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              width: '100%',
                              fontWeight: '700',
                              maxWidth: '300px'
                            }}
                            href={stateService.OfficeLocationUrl}
                            target="_blank">
                            {stateService.OfficeLocationsLabel}{' '}
                            <ChevronRightIcon />
                          </Button>
                        ) : null}

                        {stateService.FormUrl &&
                        !stateService.OfficeLocationUrl &&
                        !stateService.AppointmentUrl &&
                        !stateService.OnlineAvailable ? (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              width: '100%',
                              fontWeight: '700',
                              maxWidth: '300px'
                            }}
                            href={stateService.FormUrl}
                            target="_blank">
                            {stateService.PrintApplicationLabel}{' '}
                            <ChevronRightIcon />
                          </Button>
                        ) : null} */}
                      </div>

                      <div className="secondary-buttons">
                        {stateService.InstructionsUrl ||
                        stateService.AppointmentUrl ||
                        stateService.FormUrl ||
                        stateService.OfficeLocationUrl ||
                        stateService.OnlineApplicationUrl ? (
                          <div className="smaller-buttons">
                            {stateService.FormUrl &&
                            (stateService.OfficeLocationUrl ||
                              stateService.OnlineAvailable ||
                              stateService.AppointmentUrl) ? (
                              <Button
                                href={stateService.FormUrl}
                                target="_blank">
                                {stateService.PrintApplicationLabel}
                              </Button>
                            ) : null}

                            {stateService.OnlineApplicationUrl ? (
                              <Button
                                href={stateService.OnlineApplicationUrl}
                                target="_blank">
                                {stateService.StartApplicationLabel}
                              </Button>
                            ) : null}

                            {stateService.OfficeLocationUrl &&
                            (stateService.OnlineAvailable ||
                              stateService.AppointmentUrl) ? (
                              <Button
                                href={stateService.OfficeLocationUrl}
                                target="_blank">
                                {stateService.OfficeLocationsLabel}
                              </Button>
                            ) : null}

                            {stateService.AppointmentUrl &&
                            stateService.OnlineAvailable ? (
                              <Button
                                href={stateService.AppointmentUrl}
                                target="_blank">
                                {stateService.ScheduleAppointmentLabel}
                              </Button>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Grid>
                ) : null
              )}

              {/* <Grid item xs={12} sm={12} md={8}>
                <div className="card friends-and-family">
                  <h2>Tell Friends and Family</h2>
                </div>
              </Grid> */}
            </Grid>
          </div>
        </div>
      </div>

      {showInstructionsPopup ? (
        <Dialog
          open={true}
          aria-labelledby="form-dialog-title"
          className="checkist-prompt-popup"
          fullWidth={true}>
          <DialogTitle id="form-dialog-title">
            <IconButton
              style={{ float: 'right' }}
              aria-label="close"
              onClick={() => {
                setShowInstructionsPopup(null);
              }}>
              <CloseIcon />
            </IconButton>
            <img
              src={withPrefix('/images/logo-icon.svg')}
              className="logo-icon"
            />
            {showInstructionsPopup.InstructionsButtonLabel}
          </DialogTitle>
          <DialogContent width="100%">
            <p
              dangerouslySetInnerHTML={{
                __html: cleanHtml(showInstructionsPopup.InstructionsPopupText)
              }}></p>
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  ) : null;
}

function OptionIcon({ stateService }) {
  return (
    <div className="option-group">
      <h5>Available options:</h5>
      {stateService.OnlineAvailable ? (
        <div className="icon">
          <DevicesIcon />
          <div className="label">Online</div>
        </div>
      ) : null}

      {stateService.InPersonAvailable ? (
        <div className="icon">
          <PersonIcon />
          <div className="label">In Person</div>
        </div>
      ) : null}

      {stateService.MailAvailable ? (
        <div className="icon">
          <MailIcon />
          <div className="label">Mail</div>
        </div>
      ) : null}

      {stateService.BasedOnCounty ? (
        <div className="icon based-on-county">
          <LocationOnIcon />
          <div className="label">Based on County</div>
        </div>
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StateServiceList);
