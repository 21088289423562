import React, { useState, useEffect } from 'react';
import { withPrefix } from 'gatsby-link';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function PropertyFilterCard({
  fileListString,
  title,
  unitNumber,
  firstName,
  lastName,
  profileLabel,
  profileClick,
  descriptionFilter
}) {
  const fileList = fileListString.split(',').map((descriptionFileName) => {
    const descriptionFileNameArray = descriptionFileName.split('=>');
    return {
      description: descriptionFileNameArray[0],
      url: descriptionFileNameArray[1]
    };
  });

  const [fileDictionary, setFileDictionary] = useState(null);
  const [selectedFile, setSelectedFile] = useState(0);
  const [showImagePopup, setShowImagePopup] = useState(null);

  useEffect(() => {
    let _fileDictionary = {};

    console.log('fileList', fileList);

    fileList.map((file, index) => (_fileDictionary[file.description] = index));

    setFileDictionary(_fileDictionary);
  }, []);

  useEffect(() => {
    if (fileDictionary && fileDictionary[descriptionFilter] !== undefined) {
      setSelectedFile(fileDictionary[descriptionFilter]);
    }
  }, [descriptionFilter]);

  return (
    <>
      <div className="property-filter-card">
        <div className="image">
          {fileList.length > 1 ? (
            <div className="image-nav">
              {fileList.length > 1 && selectedFile > 0 ? (
                <Button
                  className="left"
                  onClick={() => setSelectedFile(selectedFile - 1)}>
                  <ArrowBackIosIcon />
                </Button>
              ) : null}

              {profileLabel && profileClick ? (
                <Button
                  className="profile"
                  onClick={(event) => {
                    profileClick();
                    event.stopPropagation();
                  }}>
                  {profileLabel}
                </Button>
              ) : null}

              {fileList.length > 1 && selectedFile < fileList.length - 1 ? (
                <Button
                  className="right"
                  onClick={() => setSelectedFile(selectedFile + 1)}>
                  <ArrowForwardIosIcon />
                </Button>
              ) : null}
            </div>
          ) : null}

          <Button
            onClick={() => setShowImagePopup(true)}
            className="image-open-button">
            {fileList.length ? (
              <img src={fileList[selectedFile].url} alt={title} />
            ) : (
              <div className="no-image">No Image</div>
            )}
          </Button>
        </div>
        <div className="info">
          <div className="title">{title}</div>
          <div className="unit">
            Unit #{unitNumber} - {firstName} {lastName}
          </div>
        </div>
      </div>

      {showImagePopup ? (
        <Dialog
          open={true}
          aria-labelledby="form-dialog-title"
          className="property-filter-photo-popup">
          <DialogTitle id="form-dialog-title">
            <IconButton
              style={{ float: 'right' }}
              aria-label="close"
              onClick={() => {
                setShowImagePopup(null);
              }}>
              <CloseIcon />
            </IconButton>
            <img
              src={withPrefix('/images/logo-icon.svg')}
              className="logo-icon"
            />
            Photos of {title}
          </DialogTitle>
          <DialogContent width="100%">
            <div className="photo-list">
              {fileList.map((file) => (
                <img src={file.url} alt={file.Description} />
              ))}
            </div>
          </DialogContent>
          <DialogActions style={{ display: 'block', textAlign: 'right' }}>
            <Button
              onClick={() => {
                setShowImagePopup(null);
              }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
}

export default PropertyFilterCard;
