import * as api from './apiUtils';
import { getCookie } from './dataApi';

function getConfig() {
  return { headers: { user: getCookie('mi-user') } };
}

export function listUserCampaign() {
  return api
    .get('API/Partner/ListUserCampaign', getConfig())
    .then((response) => {
      return response.data;
    });
}

export function saveUserCampaign(campaign) {
  return api
    .post('API/Partner/SaveUserCampaign', campaign, getConfig())
    .then((response) => {
      return response.data;
    });
}

export function listOrder() {
  return api.get('API/Partner/ListOrder', getConfig()).then((response) => {
    return response.data;
  });
}

export function getServiceProfile(
  address,
  secondary,
  city,
  state,
  zip,
  serviceProfileID,
  propertyID
) {
  return api
    .get(
      'API/Partner/GetServiceProfile?address=' +
        encodeURIComponent(address) +
        '&secondary=' +
        (secondary ? encodeURIComponent(secondary) : '') +
        '&city=' +
        encodeURIComponent(city) +
        '&state=' +
        encodeURIComponent(state) +
        '&zip=' +
        encodeURIComponent(zip) +
        '&propertyID=' +
        (propertyID ? propertyID : '0') +
        '&serviceProfileID=' +
        (serviceProfileID ? serviceProfileID : '0'),
      getConfig()
    )
    .then((response) => {
      return response.data;
    });
}

export function saveServiceProfile(serviceProfile, isCompleted) {
  return api
    .post(
      'API/Partner/SaveServiceProfile?isCompleted=' +
        (isCompleted ? 'true' : 'false'),
      serviceProfile,
      getConfig()
    )
    .then((response) => {
      return response.data;
    });
}

export function listPartner() {
  return api.get('API/Partner/ListPartner', getConfig()).then((response) => {
    return response.data;
  });
}

export function listServiceAddressRequest(propertyID) {
  return api
    .get(
      'API/Partner/ListServiceAddressRequest?propertyID=' +
        (propertyID ? propertyID : '0'),
      getConfig()
    )
    .then((response) => {
      return response.data;
    });
}

export function createServiceAddressRequest(request) {
  return api
    .post('API/Partner/CreateServiceAddressRequest', request, getConfig())
    .then((response) => {
      return response.data;
    });
}

export function cancelServiceAddressRequest(serviceAddressRequestID) {
  return api
    .post(
      'API/Partner/CancelServiceAddressRequest?serviceAddressRequestID=' +
        serviceAddressRequestID,
      null,
      getConfig()
    )
    .then((response) => {
      return response.data;
    });
}
