import React, { useEffect, useRef, useState } from 'react';
import ConnectElectric from '../ConnectElectric';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby';
import { navigate } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';
import {
  Parallax,
  ParallaxBanner,
  ParallaxProvider
} from 'react-scroll-parallax';
import { ChevronRight } from '@mui/icons-material';

import {
  saveServiceAddress,
  getCurrentRoute,
  getCookie,
  scrollToElementInPage
} from '../../api/dataApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';

import { electricData } from '../../mock/electric';

import AddressSearch from '../AddressSearch';
import AddressSearchPopup from '../AddressSearch/AddressSearchPopup';
import Electric6MonthAvgCompare from '../Electric6MonthAvgCompare';
import TermTypePopup from '../ConnectElectric/TermTypePopup';

import './index.css';

function SCLandingWrapper({
  actions,
  city,
  webSocket,
  serviceAddress,
  siteConfig,
  noHeader,
  preFilter,
  preFilterLabel,
  headerIfName
}) {
  const checking = useRef();
  const initted = useRef();
  const [showAddressSearch, setShowAddressSearch] = useState(null);
  const [hasZipStored, setHasZipStored] = useState(getCookie('mi-zip-code'));
  const [hideHeader, setHideHeader] = useState(null);
  const uc = useQueryParam('uc');
  const [show6MonthAvg, setShow6MonthAvg] = useState(null);
  const [showSummer, setShowSummer] = useState(null);
  const [showTermTypePopup, setShowTermTypePopup] = useState(null);

  const simple = useQueryParam('simple') === '1';
  const zipEntered = useQueryParam('zipEntered') === '1';
  const scrollTimeout = useRef();

  useEffect(() => {
    actions.setLandingPage({ route: getCurrentRoute(), city });
    if (hasZipStored) {
      actions.pageLoading(true, 'Checking rates...');
    }
    //actions.serviceAddressUpdated({ ...electricData[city], mock: true });
  }, []);

  useEffect(() => {
    if (zipEntered) {
      setHideHeader(true);
    } else {
      setHideHeader(false);
    }
    console.log('zip entered', zipEntered);
  }, [zipEntered]);

  useEffect(() => {
    if (
      uc &&
      uc === 'IG-Aff' &&
      webSocket &&
      webSocket.initted &&
      webSocket.ajaxConnected &&
      !show6MonthAvg
    ) {
      setShow6MonthAvg(true);
    }
  }, [uc, webSocket]);

  useEffect(() => {
    if (
      uc &&
      uc === 'IG-Summer' &&
      webSocket &&
      webSocket.initted &&
      webSocket.ajaxConnected &&
      !showSummer
    ) {
      setShowSummer(true);
    }
  }, [uc, webSocket]);

  useEffect(() => {
    if (
      webSocket &&
      webSocket.initted &&
      webSocket.ajaxConnected &&
      !webSocket.hasServiceAddress &&
      !checking.current &&
      (!serviceAddress || serviceAddress.mock) &&
      city
    ) {
      checking.current = true;

      actions.pageLoading(true, 'Checking rates...');

      saveServiceAddress('', '', city, 'TX', '', 11).then((result) => {
        actions.serviceAddressUpdated({ ...result, force: true });
        actions.pageLoading(false);
      });
    }
  }, [webSocket]);

  useEffect(() => {
    if (
      serviceAddress &&
      serviceAddress.Address &&
      serviceAddress.Address.Zip
    ) {
      if (!hasZipStored && (getCookie('mi-zip-code') || city)) {
        scrollTimeout.current = setTimeout(() => {
          setHasZipStored(true);
          scrollToPlans(false);
        }, 500);
      }

      actions.pageLoading(false);
    }
  }, [serviceAddress]);

  function scrollToPlans(instant, offset) {
    const bestHeader = document.getElementsByClassName('best-header')[0];
    const offerRow = document.getElementsByClassName('offer-row')[0];
    if (bestHeader && offerRow) {
      scrollToElementInPage(bestHeader, offset ? offset : -280, instant);
    }
  }

  if (typeof window === 'undefined') {
    actions.serviceAddressUpdated({ ...electricData[city], mock: true });
  }

  return (
    <div
      className={
        'sc-landing-wrapper' +
        (hasZipStored &&
        (!serviceAddress ||
          !serviceAddress.Address ||
          !serviceAddress.Address.Zip)
          ? ' with-zip'
          : '') +
        (hideHeader ? ' hide-header' : '')
      }>
      {show6MonthAvg &&
      (!serviceAddress ||
        !serviceAddress.Address ||
        !serviceAddress.Address.Zip) ? (
        <Electric6MonthAvgCompare />
      ) : null}
      {(!noHeader && !hideHeader && !headerIfName) ||
      (headerIfName &&
        serviceAddress &&
        serviceAddress.Address &&
        serviceAddress.Address.FirstName) ? (
        <div className="hero">
          <div className="image">
            {!showSummer ? (
              <ParallaxProvider>
                <ParallaxBanner
                  layers={[
                    {
                      image: withPrefix(
                        '/images/misc/sc-landscape-layer6' +
                          (simple ? '_simple' : '') +
                          '.svg'
                      ),
                      speed: -5,
                      opacity: [1, 0],
                      shouldAlwaysCompleteAnimation: true
                    },
                    {
                      image: withPrefix(
                        '/images/misc/sc-landscape-layer5' +
                          (simple ? '_simple' : '') +
                          '.svg'
                      ),
                      speed: -4,
                      opacity: [1, 0],
                      shouldAlwaysCompleteAnimation: true
                    },
                    {
                      image: withPrefix(
                        '/images/misc/sc-landscape-layer4' +
                          (simple ? '_simple' : '') +
                          '.svg'
                      ),
                      speed: -3,
                      opacity: [1, 0],
                      shouldAlwaysCompleteAnimation: true
                    },
                    {
                      image: withPrefix(
                        '/images/misc/sc-landscape-layer3' +
                          (simple ? '_simple' : '') +
                          '.svg'
                      ),
                      speed: -4,
                      opacity: [1, 0],
                      shouldAlwaysCompleteAnimation: true
                    },
                    {
                      image: withPrefix(
                        '/images/misc/sc-landscape-layer2' +
                          (simple ? '_simple' : '') +
                          '.svg'
                      ),
                      speed: -3,
                      opacity: [1, 0],
                      shouldAlwaysCompleteAnimation: true
                    },
                    {
                      image: withPrefix(
                        '/images/misc/sc-landscape-layer1' +
                          (simple ? '_simple' : '') +
                          '.svg'
                      ),
                      speed: 0,
                      opacity: [1, 0.5],
                      shouldAlwaysCompleteAnimation: true
                    }
                  ]}
                  style={{
                    height: '350px'
                  }}></ParallaxBanner>
              </ParallaxProvider>
            ) : null}
          </div>

          {showSummer ? (
            <div className="summer-image">
              <img src={withPrefix('/images/misc/happy-sun.png')} />
            </div>
          ) : null}

          {headerIfName &&
          serviceAddress.Address &&
          serviceAddress.Address.FirstName &&
          serviceAddress.Address.Address ? (
            <div className="property-welcome">
              <h2>Hello, {serviceAddress.Address.FirstName}!</h2>
              {serviceAddress.Address.PropertyName ? (
                <>
                  <h3>Welcome to {serviceAddress.Address.PropertyName}</h3>
                  <p>
                    Your community has partnered with {siteConfig.brand} to help
                    you find a great electricity plan for your new home. Plus,
                    if you set up electric service through {siteConfig.brand},
                    we will automatically send the Proof of Service
                    documentation required by your lease to the property so you
                    don't have to.
                  </p>
                  <h4>Showing electric plans for:&nbsp;&nbsp;</h4>
                </>
              ) : null}

              <h5>
                {serviceAddress.Address.Address}{' '}
                {serviceAddress.Address.Secondary}
                <br />
                {serviceAddress.Address.City}, {serviceAddress.Address.State}{' '}
                {serviceAddress.Address.Zip}
              </h5>
            </div>
          ) : (
            <>
              <h2>
                <strong>The Safe, Simple, Hassle-free Way </strong>
                <br />
                to{' '}
                <strong>
                  save{' '}
                  <img
                    src={withPrefix('/images/arrows/arrow_22-short.svg')}
                    alt=""
                  />
                </strong>{' '}
                {showSummer
                  ? 'before it heats up!'
                  : 'on your next electric bill.'}
              </h2>

              <div className="zip-entry">
                <AddressSearch
                  // hideSearchBy
                  mode="address"
                  // afterAddressSelected={() => {
                  //   actions.pageLoading(true, 'Loading rates...');
                  //   navigate('/electric-rates');
                  // }}
                  hideAddressSearchButton
                  afterAddressSelected={() => {
                    scrollTimeout.current = setTimeout(() => {
                      scrollToPlans(false, -90);
                    }, 500);
                  }}
                />
              </div>
            </>
          )}
        </div>
      ) : null}

      {!preFilter ? (
        <>
          {serviceAddress &&
          serviceAddress.Address &&
          serviceAddress.Address.City ? (
            <div id="bestPlan" className="best-header">
              {serviceAddress &&
              serviceAddress.Address &&
              serviceAddress.Address.City &&
              city &&
              serviceAddress.Address.City.toLowerCase() ===
                city.toLowerCase() ? (
                <h2>
                  <span>
                    Best{' '}
                    <strong>
                      {serviceAddress &&
                      serviceAddress.Address &&
                      serviceAddress.Address.City
                        ? serviceAddress.Address.City
                        : electricData[city].Address.City}
                    </strong>{' '}
                    area electric plans
                  </span>
                </h2>
              ) : serviceAddress.Address.ResidentID ? null : (
                <h2>
                  {serviceAddress.OfferDictionary &&
                  serviceAddress.OfferDictionary.Electric ? (
                    <span>
                      Best electric plans for{' '}
                      <strong>
                        {serviceAddress.Address.Address ? (
                          <>
                            <br />
                            {serviceAddress.Address.Address}{' '}
                            {serviceAddress.Address.Secondary
                              ? serviceAddress.Address.Secondary
                              : ''}
                          </>
                        ) : (
                          serviceAddress.Address.Zip
                        )}
                      </strong>
                    </span>
                  ) : (
                    <span>No results found</span>
                  )}
                </h2>
              )}
              {/* {noHeader || hideHeader ? (
                <p>
                  <a
                    className="link"
                    onClick={() => setShowAddressSearch(true)}>
                    Click here
                  </a>{' '}
                  if you need to change locations.
                </p>
              ) : serviceAddress &&
                serviceAddress.Address &&
                serviceAddress.Address.PropertyName ? null : (
                // <p>
                //   <em>Did you know...</em> If you're moving to a new address,
                //   you can cancel your existing electric service contract without
                //   penalty or early termination fees?{' '}
                //   <a
                //     onClick={() => setShowTermTypePopup(true)}
                //     className="link">
                //     Click here
                //   </a>{' '}
                //   for more information.
                // </p>
                <p>
                  You can change locations by entering a new zip code above.
                </p>
              )} */}
            </div>
          ) : !hasZipStored ? (
            <>
              <div className="best-header finding">
                <h2>
                  <span>
                    Finding YOUR best deal on electricity has never been easier.
                  </span>
                </h2>

                {/* <h3 className="contract-text">
                <strong>In a contract? </strong>
                <a className="link" onClick={() => navigate('/switch')}>
                  Check if you can switch early <ChevronRight />
                </a>
              </h3> */}
              </div>

              <div className="sc-welcome-steps">
                <div className="sc-welcome-steps-inner">
                  <ul>
                    <li>
                      <em>
                        <span>Step</span>
                        <strong>1</strong>
                      </em>
                      <h3>Enter your zip code</h3>
                      <p>
                        Enter your zip code above to find energy providers and
                        plans in your area.
                      </p>
                    </li>
                    <li>
                      <em>
                        <span>Step</span>
                        <strong>2</strong>
                      </em>
                      <h3>Compare rates</h3>
                      <p>
                        Quickly compare rates to find the plan that is best for
                        you.
                      </p>
                    </li>
                    <li>
                      <em>
                        <span>Step</span>
                        <strong>3</strong>
                      </em>
                      <h3>Sign up and SAVE.</h3>
                      <p>Sign up online or over the phone and start saving.</p>
                    </li>
                  </ul>

                  <img className="logo" src={withPrefix(siteConfig.logo)} />

                  <h2>
                    The safe, simple, hassle-free way to SAVE on Electricity.
                  </h2>
                </div>

                <div className="provider-logo-list">
                  <img
                    className="provider-logo-list"
                    src={withPrefix('/images/misc/electric-provider-list.png')}
                  />
                </div>
              </div>
            </>
          ) : null}
        </>
      ) : null}

      {serviceAddress &&
      serviceAddress.Address &&
      serviceAddress.Address.Zip ? (
        <>
          <ConnectElectric
            isLanding
            preFilter={preFilter}
            preFilterLabel={preFilterLabel}
          />
        </>
      ) : null}

      {/* <div className="landing-info">
        <div className="coin1-container">
          <img src={withPrefix('/images/misc/coin1.svg')} />
        </div>
        <div className="coin2-container">
          <img src={withPrefix('/images/misc/coin2.svg')} />
        </div>
        <div className="coin3-container">
          <img src={withPrefix('/images/misc/coin3.svg')} />
        </div>
      </div> */}

      {showAddressSearch ? (
        <AddressSearchPopup
          mode="zip"
          closeFunc={() => setShowAddressSearch(null)}
          afterAddressSelected={() => {
            //do nothing, stay here
            setShowAddressSearch(null);
          }}
        />
      ) : null}

      {showTermTypePopup && showTermTypePopup.show ? (
        <TermTypePopup
          closeFunc={() => {
            setShowTermTypePopup({ show: false });
          }}
          title={'Contract Cancellation'}
        />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      setLandingPage: bindActionCreators(
        webSocketActions.setLandingPage,
        dispatch
      ),
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SCLandingWrapper);
