import React, { useState, useRef, useEffect, version } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import EditIcon from '@mui/icons-material/Edit';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import CloseIcon from '@mui/icons-material/Close';

import * as webSocketActions from '../../../redux/actions/webSocketActions';
import * as residentOnboardingActions from '../../../redux/actions/residentOnboardingActions';

import { saveResidentVehicleNotes } from '../../../api/propertyProfileApi';
import { scrollToElementInPage } from '../../../api/dataApi';

import ResidentOnboardingFileUpload from '../ResidentOnboardingFileUpload';
import ResidentOnboardingStatusBadge from '../ResidentOnboardingStatusBadge';
import ResidentOnboardingPhotoUpload from '../ResidentOnboardingPhotoUpload';
import ResidentOnboardingSaveWarning from '../ResidentOnboardingSaveWarning';
import ResidentOnboardingStatusUpdate from '../ResidentOnboardingStatusUpdate';
import GeneralPopup from '../../GeneralPopup';

import {
  vehicleTypeList,
  getMakeList,
  getModelList,
  colorList,
  getYearList
} from '../../PropertyProfile/vehicleData';

import 'react-dropzone-uploader/dist/styles.css';
import '../index.css';
import { Tooltip } from '@material-ui/core';

function ResidentOnboardingVehicle({
  actions,
  serviceAddress,
  siteConfig,
  residentOnboarding,
  refreshResidentOnboarding,
  updateResidentOnboardingHeader,
  stateList,
  residentID,
  closeOnboardingRow,
  residentVehicleID,
  profileKey
}) {
  const [residentOnboardingStatus, setResidentOnboardingStatus] =
    useState(null);
  const [editMode, setEditMode] = useState(null);
  const [residentOnboardingSetting, setResidentOnboardingSetting] =
    useState(null);

  const [residentVehicleList, setResidentVehicleList] = useState(null);
  const [deleteVehicle, setDeleteVehicle] = useState(null);
  const [isDisabled, setIsDisabled] = useState(null);
  const [saveWarning, setSaveWarning] = useState(null);
  const [residentStatusUpdate, setResidentStatusUpdate] = useState(null);
  const [editNote, setEditNote] = useState({ edit: false, note: null });
  const [validationErrorList, setValidationErrorList] = useState(null);

  useEffect(() => {
    if (residentOnboarding) {
      loadResidentVehicleList();
    }
  }, [residentOnboarding]);

  useEffect(() => {
    if (residentOnboarding) {
      let _residentOnboardingStatus = null;
      if (residentOnboarding.Header.HasNoVehicle) {
        _residentOnboardingStatus = {
          ResidentOnboardingStatusID: 3,
          ResidentOnboardingStatusName: 'No Action Needed'
        };
      } else if (residentVehicleList && residentVehicleList.length) {
        residentVehicleList.map((vehicle) => {
          if (
            !_residentOnboardingStatus ||
            vehicle.ResidentOnboardingStatusID <
              _residentOnboardingStatus.ResidentOnboardingStatusID
          ) {
            _residentOnboardingStatus = { ...vehicle };
          }
        });
      } else {
        _residentOnboardingStatus = {
          ResidentOnboardingStatusID: 1,
          ResidentOnboardingStatusName: 'Action Required'
        };
      }

      setResidentOnboardingStatus(_residentOnboardingStatus);

      if (!editMode && residentVehicleID && residentVehicleList) {
        console.log('here', residentVehicleID);
        setEditMode({
          ResidentVehicleID: residentVehicleID,
          loading: true,
          vehicleIndex: 0
        });
      }
    }
  }, [residentOnboarding, residentVehicleList]);

  useEffect(() => {
    checkIsDisabled();
  }, [residentOnboardingSetting]);

  useEffect(() => {
    if (editNote.edit) {
      if (document.getElementById('textboxVehicleNote')) {
        document.getElementById('textboxVehicleNote').focus();
      }
    }
  }, [editNote]);

  useEffect(() => {
    let _validationErrorList = [];
    let _allCheckboxesChecked = true;

    if (editMode && residentOnboarding) {
      residentVehicleList.map((vehicle, vehicleIndex) => {
        if (vehicle.ResidentVehicleID === editMode.ResidentVehicleID) {
          if (
            residentOnboarding.Header.VehicleRegistrationIncludeYear &&
            !vehicle.Year
          ) {
            _validationErrorList.push('Enter the Year of the vehicle');
          }

          if (
            residentOnboarding.Header.VehicleRegistrationIncludeMake &&
            !vehicle.Make
          ) {
            _validationErrorList.push('Enter the Make of the vehicle');
          }

          if (
            residentOnboarding.Header.VehicleRegistrationIncludeModel &&
            !vehicle.Model
          ) {
            _validationErrorList.push('Enter the Model of the vehicle');
          }

          if (
            (residentOnboarding.Header.VehicleRegistrationIncludeMake ||
              residentOnboarding.Header.VehicleRegistrationIncludeModel) &&
            (vehicle.Make === 'Other' || vehicle.Model === 'Other') &&
            !vehicle.VehicleType
          ) {
            _validationErrorList.push('Enter the Type of the vehicle');
          }

          if (
            residentOnboarding.Header.VehicleRegistrationIncludeColor &&
            !vehicle.Color
          ) {
            _validationErrorList.push('Enter the Color of the vehicle');
          }

          if (
            residentOnboarding.Header
              .VehicleRegistrationIncludeLicensePlateNumber &&
            !vehicle.LicensePlateNumber
          ) {
            _validationErrorList.push(
              'Enter the License Plate Number of the vehicle'
            );
          }

          if (
            residentOnboarding.Header
              .VehicleRegistrationIncludeLicensePlateState &&
            !vehicle.LicensePlateState
          ) {
            _validationErrorList.push(
              'Enter the License Plate State of the vehicle'
            );
          }

          if (
            residentOnboarding.Header.VehicleRegistrationIncludeVIN &&
            !vehicle.VIN
          ) {
            _validationErrorList.push('Enter the VIN of the vehicle');
          }

          let _allPhotosUploaded = true;

          getRequiredPhotoList().map((description) => {
            if (
              !residentOnboarding.FileList ||
              !residentOnboarding.FileList.filter(
                (file) =>
                  file.ResidentFileTypeID === 2 &&
                  file.ResidentVehicleID === vehicle.ResidentVehicleID &&
                  file.Description === description
              ).length
            ) {
              _allPhotosUploaded = false;
            }
          });

          if (!_allPhotosUploaded) {
            _validationErrorList.push('Upload all vehicle photos');
          }

          if (
            residentOnboarding.Header
              .VehicleRegistrationIncludeProofOfInsurance &&
            (!residentOnboarding.FileList ||
              !residentOnboarding.FileList.filter(
                (file) =>
                  file.ResidentFileTypeID === 3 &&
                  file.ResidentVehicleID === vehicle.ResidentVehicleID
              ).length)
          ) {
            _validationErrorList.push(
              'Upload your Proof of Vehicle Insurance document'
            );
          }

          if (
            vehicleIndex === 0 &&
            residentOnboarding.Header.VehicleRegistrationIncludeCheckbox &&
            !residentOnboardingSetting.IsVehicleCheckboxChecked
          ) {
            _allCheckboxesChecked = false;
          }
        }
      });

      if (!_allCheckboxesChecked) {
        _validationErrorList.push('Check all required checkboxes');
      }
    }

    setValidationErrorList(_validationErrorList);
  }, [
    editMode,
    residentOnboardingSetting,
    residentOnboarding,
    residentVehicleList
  ]);

  function loadResidentVehicleList(cancelEdit) {
    setResidentOnboardingSetting({ ...residentOnboarding.Header });

    let _residentVehicleList = [].concat(residentOnboarding.VehicleList);

    _residentVehicleList = _residentVehicleList.map((vehicle) => {
      let _vehicle = { ...vehicle };

      let _make = '';
      let _model = '';
      if (_vehicle.Make && _vehicle.Year) {
        _make = getMakeList(_vehicle.Year).filter(
          (make) =>
            make.toLowerCase().trim() === _vehicle.Make.toLowerCase().trim()
        )[0];
      }

      if (_vehicle.Year && _make && _vehicle.Model) {
        _model = getModelList(_vehicle.Year, _make).filter(
          (model) =>
            model.toLowerCase().trim() === _vehicle.Model.toLowerCase().trim()
        )[0];
      }

      return {
        ..._vehicle,
        Make: _make ? _make : '',
        Model: _model ? _model : ''
      };
    });

    if (!cancelEdit && residentVehicleList && editMode) {
      _residentVehicleList = _residentVehicleList.map((vehicle) => {
        const editedVehicle = residentVehicleList.filter(
          (_vehicle) => _vehicle.ResidentVehicleID === vehicle.ResidentVehicleID
        )[0];
        if (editedVehicle) {
          return {
            ...vehicle,
            ...editedVehicle,
            ResidentOnboardingStatusID: vehicle.ResidentOnboardingStatusID,
            ResidentOnboardingStatusName: vehicle.ResidentOnboardingStatusName,
            ResidentOnboardingStatusNote: vehicle.ResidentOnboardingStatusNote
          };
        } else {
          return { ...vehicle };
        }
      });
    }

    if (residentVehicleID) {
      const _vehicle = _residentVehicleList.filter(
        (vehicle) => vehicle.ResidentVehicleID === residentVehicleID
      )[0];
      if (_vehicle) {
        _residentVehicleList = [_vehicle];
        setEditNote({ edit: false, note: _vehicle.Notes });
      }
    }

    setResidentVehicleList(_residentVehicleList);
  }

  function getRequiredPhotoList() {
    let _descriptionList = [];

    if (residentOnboarding.Header.VehicleRegistrationPhotoIncludeFront) {
      _descriptionList.push('Front');
    }

    if (residentOnboarding.Header.VehicleRegistrationPhotoIncludeBack) {
      _descriptionList.push('Back');
    }

    if (residentOnboarding.Header.VehicleRegistrationPhotoIncludeSide) {
      _descriptionList.push('Side');
    }
    if (residentOnboarding.Header.VehicleRegistrationPhotoIncludeAny) {
      _descriptionList.push('Angled');
    }

    return _descriptionList;
  }

  function checkIsDisabled(residentVehicleID) {
    setIsDisabled(
      !residentOnboardingSetting || residentOnboardingSetting.HasNoVehicle
    );
  }

  return residentOnboardingStatus ? (
    <div className="resident-onboarding-item vehicle">
      <div className="form-header">
        {/* {!residentVehicleList.length ? (
          <ResidentOnboardingStatusBadge
            status={residentOnboardingStatus}
            isProperty={residentID ? true : false}
          />
        ) : null} */}

        <h3>
          {closeOnboardingRow ? (
            <IconButton
              className="close-button"
              onClick={() => closeOnboardingRow()}>
              <CloseIcon />
            </IconButton>
          ) : null}
          Vehicle{' '}
          {residentOnboarding.Header.ResidentStatusID === 4
            ? 'Application'
            : 'Registration'}
        </h3>
      </div>

      {!residentID ? (
        <Alert severity="info" className="top-notice">
          Per your lease, any vehicle used, parked or stored on site must be
          registered with the property.
        </Alert>
      ) : null}

      {!residentID &&
      residentOnboardingSetting.HasNoVehicle &&
      residentOnboardingSetting.HasNoVehicleConfirmed === false &&
      residentOnboardingSetting.HasNoVehicleReason ? (
        <Alert severity={'warning'}>
          Note from Property: {residentOnboardingSetting.HasNoVehicleReason}
        </Alert>
      ) : null}

      <div className="form-content">
        {residentID &&
        residentOnboardingSetting.HasNoVehicle &&
        residentOnboardingSetting.HasNoVehicleConfirmed === null ? (
          <div>
            <div className="multi-item does-not-have-property">
              <div className="save-button">
                <Button
                  variant="contained"
                  className="accept"
                  color="primary"
                  onClick={() => {
                    setResidentStatusUpdate({
                      dataTypeLabel: 'Resident does not have vehicles',
                      residentOnboardingStatusID: 3
                    });
                  }}>
                  <DoneIcon /> Accept
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setResidentStatusUpdate({
                      dataTypeLabel: 'Resident does not have vehicles',
                      residentOnboardingStatusID: 1
                    });
                  }}>
                  <ClearIcon /> Reject
                </Button>
              </div>

              <p>
                Resident has indicated that they will not be using, parking or
                storing any vehicles on site. If you agree with that statement,
                click the Accept button above. If you do not agree, click the
                Reject button.
              </p>
            </div>
          </div>
        ) : (
          <>
            {!residentVehicleList ||
            !residentVehicleList.length ||
            residentOnboardingSetting.HasNoVehicle ? (
              <div
                className={
                  'does-not-have-checkbox' +
                  (residentOnboardingSetting.HasNoVehicleConfirmed
                    ? ' disabled'
                    : '')
                }>
                <FormControlLabel
                  control={
                    <div>
                      <Checkbox
                        checked={residentOnboardingSetting.HasNoVehicle}
                        onChange={(event) => {
                          setResidentOnboardingSetting({
                            ...residentOnboardingSetting,
                            HasNoVehicle: event.target.checked
                          });
                          updateResidentOnboardingHeader({
                            ...residentOnboarding.Header,
                            HasNoVehicle: event.target.checked
                          });
                        }}
                        name="gilad"
                      />
                    </div>
                  }
                  label={
                    residentID ? (
                      <>
                        Resident will not be using, parking or storing any
                        vehicles on site.
                      </>
                    ) : (
                      <>
                        I will not be using, parking or storing any vehicles on
                        site.
                      </>
                    )
                  }
                />
              </div>
            ) : null}
            {!residentOnboardingSetting.HasNoVehicle && !residentVehicleID ? (
              <>
                {residentVehicleList.length ? (
                  <div className="multi-item-summary-container">
                    <ul className="multi-item-summary-list">
                      {residentVehicleList.map((vehicle, vehicleIndex) => (
                        <li className="multi-item-summary">
                          <div
                            className={
                              'multi-item-summary-card' +
                              (editMode &&
                              editMode.ResidentVehicleID ===
                                vehicle.ResidentVehicleID
                                ? ' selected'
                                : '') +
                              (vehicle.ResidentOnboardingStatusID >= 3
                                ? ' completed'
                                : '')
                            }>
                            <h5>
                              {vehicle.Make
                                ? vehicle.Make
                                : 'Vehicle ' + (vehicleIndex + 1)}
                            </h5>
                            <h6>
                              {vehicle.Model
                                ? vehicle.Model
                                : 'More Detail Needed'}
                            </h6>

                            <Stepper
                              activeStep={
                                !vehicle.ResidentOnboardingStatusID ||
                                vehicle.ResidentOnboardingStatusID === 1
                                  ? 0
                                  : vehicle.ResidentOnboardingStatusID === 2
                                  ? 2
                                  : 999
                              }
                              orientation="vertical">
                              {[
                                !vehicle.ResidentOnboardingStatusID ||
                                vehicle.ResidentOnboardingStatusID === 1
                                  ? 'Vehicle Registration'
                                  : 'Registration Submitted',
                                !vehicle.ResidentOnboardingStatusID ||
                                vehicle.ResidentOnboardingStatusID === 1
                                  ? 'Sign Vehicle Policy'
                                  : 'Vehicle Policy Signed',
                                vehicle.ResidentOnboardingStatusID <= 2
                                  ? 'Pending Property Review'
                                  : 'Accepted by Property',
                                'Registration Complete'
                              ].map((step, index) => (
                                <Step key={step}>
                                  <StepLabel>{step}</StepLabel>
                                </Step>
                              ))}
                            </Stepper>

                            <Button
                              variant={
                                !vehicle.ResidentOnboardingStatusID ||
                                vehicle.ResidentOnboardingStatusID <= 2
                                  ? 'contained'
                                  : 'outlined'
                              }
                              color={
                                !vehicle.ResidentOnboardingStatusID ||
                                vehicle.ResidentOnboardingStatusID === 1
                                  ? 'secondary'
                                  : 'primary'
                              }
                              onClick={() => {
                                setEditMode({ ...vehicle, vehicleIndex });
                                if (residentID) {
                                  setTimeout(
                                    () =>
                                      scrollToElementInPage(
                                        document.getElementById(
                                          'vehicleEditForm'
                                        ),
                                        -400
                                      ),
                                    100
                                  );
                                }
                              }}>
                              {!vehicle.ResidentOnboardingStatusID ||
                              vehicle.ResidentOnboardingStatusID === 1
                                ? 'Continue Processing'
                                : residentID
                                ? 'View Submittal'
                                : 'View Status'}
                            </Button>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <Button
                      className="add-button"
                      variant="outlined"
                      color="primary"
                      disabled={residentOnboardingSetting.HasNoVehicle}
                      onClick={() => {
                        actions.addResidentVehicle(residentID);
                      }}>
                      <DirectionsCarIcon /> Add{' '}
                      {residentVehicleList.length ? 'Another' : ''} Vehicle
                    </Button>
                  </div>
                ) : (
                  <>
                    <div className="vehicle-count-checkbox">
                      <FormControlLabel
                        control={
                          <div>
                            <Checkbox
                              onChange={(event) => {
                                actions.addResidentVehicle(residentID);
                              }}
                            />
                          </div>
                        }
                        label={
                          residentID ? (
                            <>
                              Resident has ONE vehicle <DirectionsCarIcon />
                            </>
                          ) : (
                            <>
                              I have ONE vehicle <DirectionsCarIcon />
                            </>
                          )
                        }
                      />
                    </div>

                    <div className="vehicle-count-checkbox">
                      <FormControlLabel
                        control={
                          <div>
                            <Checkbox
                              onChange={(event) => {
                                actions
                                  .addResidentVehicle(residentID)
                                  .then((result) => {
                                    actions.addResidentVehicle(residentID);
                                  });
                              }}
                            />
                          </div>
                        }
                        label={
                          residentID ? (
                            <>
                              Resident has TWO vehicles <DirectionsCarIcon />
                              <DirectionsCarIcon />
                            </>
                          ) : (
                            <>
                              I have TWO vehicles <DirectionsCarIcon />
                              <DirectionsCarIcon />
                            </>
                          )
                        }
                      />
                    </div>

                    <div className="vehicle-count-checkbox">
                      <FormControlLabel
                        control={
                          <div>
                            <Checkbox
                              onChange={(event) => {
                                actions
                                  .addResidentVehicle(residentID)
                                  .then((result) => {
                                    actions
                                      .addResidentVehicle(residentID)
                                      .then((result) => {
                                        actions.addResidentVehicle(residentID);
                                      });
                                  });
                              }}
                            />
                          </div>
                        }
                        label={
                          residentID ? (
                            <>
                              Resident has THREE vehicles <DirectionsCarIcon />
                              <DirectionsCarIcon />
                              <DirectionsCarIcon />
                            </>
                          ) : (
                            <>
                              I have THREE vehicles <DirectionsCarIcon />
                              <DirectionsCarIcon />
                              <DirectionsCarIcon />
                            </>
                          )
                        }
                      />
                    </div>
                  </>
                )}
              </>
            ) : null}
            <div>
              {residentVehicleList
                .filter(
                  (vehicle) =>
                    editMode &&
                    vehicle.ResidentVehicleID === editMode.ResidentVehicleID
                )
                .map((vehicle, vehicleIndex) => (
                  <div
                    id="vehicleEditForm"
                    className={
                      'multi-item' +
                      ' vehicle-' +
                      vehicle.ResidentVehicleID +
                      (!vehicle.ResidentOnboardingStatusID ||
                      vehicle.ResidentOnboardingStatusID === 1
                        ? ' edit-mode'
                        : '')
                    }>
                    <div
                      className={
                        'form-header' +
                        (vehicle.ResidentOnboardingStatusNote &&
                        vehicle.ResidentOnboardingStatusID !== 2
                          ? ' with-note'
                          : '')
                      }>
                      <h4>
                        <ResidentOnboardingStatusBadge
                          status={vehicle}
                          isProperty={residentID ? true : false}
                        />
                        <DirectionsCarIcon /> Vehicle{' '}
                        {editMode.vehicleIndex + 1}
                        {!vehicle.ResidentOnboardingStatusID ||
                        vehicle.ResidentOnboardingStatusID === 1 ? (
                          <div className="delete">
                            <IconButton
                              onClick={() => setDeleteVehicle(vehicle)}>
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        ) : null}
                      </h4>

                      <div className={'save-button'}>
                        {!vehicle.ResidentOnboardingStatusID ||
                        vehicle.ResidentOnboardingStatusID ===
                          1 ? null : residentID &&
                          vehicle.ResidentOnboardingStatusID === 2 ? (
                          <>
                            <Button
                              variant="contained"
                              className="accept"
                              color="primary"
                              onClick={() => {
                                setResidentStatusUpdate({
                                  dataTypeLabel:
                                    'Vehicle ' + (vehicleIndex + 1),
                                  vehicle,
                                  residentOnboardingStatusID: 3
                                });
                              }}>
                              <DoneIcon /> Accept
                            </Button>

                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                setResidentStatusUpdate({
                                  dataTypeLabel:
                                    'Vehicle ' + (vehicleIndex + 1),
                                  vehicle,
                                  residentOnboardingStatusID: 1
                                });
                              }}>
                              <ClearIcon /> Reject
                            </Button>
                          </>
                        ) : null}
                      </div>

                      {vehicle.ResidentOnboardingStatusNote &&
                      vehicle.ResidentOnboardingStatusID !== 2 ? (
                        <Alert
                          severity={
                            vehicle.ResidentOnboardingStatusID === 3
                              ? 'success'
                              : 'warning'
                          }>
                          Note from Property:{' '}
                          {vehicle.ResidentOnboardingStatusNote}
                        </Alert>
                      ) : null}
                    </div>

                    {deleteVehicle &&
                    deleteVehicle.ResidentVehicleID ===
                      vehicle.ResidentVehicleID ? (
                      <GeneralPopup
                        title={'Delete Vehicle'}
                        message={
                          <>
                            <p style={{ marginTop: '20px' }}>
                              Are you sure you want to delete Vehicle{' '}
                              {editMode.vehicleIndex + 1}? The vehicle will be
                              permanently removed.
                            </p>
                          </>
                        }
                        submitLabel={'Confirm'}
                        submitFunc={() =>
                          actions
                            .deleteResidentVehicle(deleteVehicle, residentID)
                            .then((result) => setDeleteVehicle(null))
                        }
                        closeFunc={() => setDeleteVehicle(null)}
                        closeLabel={'Cancel'}
                      />
                    ) : null}

                    {residentVehicleID ? (
                      <div className="property-notes">
                        <TextField
                          label="Property Notes"
                          value={editNote.note}
                          onChange={(event) =>
                            setEditNote({
                              ...editNote,
                              note: event.target.value
                            })
                          }
                          id="textboxVehicleNote"
                          disabled={!editNote.edit}
                          multiline
                          fullWidth
                          rows={5}
                          autoFocus={editNote.edit}
                          variant="filled"
                          onClick={() =>
                            setEditNote({ ...editNote, edit: true })
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {editNote.edit ? (
                                  <>
                                    <Button
                                      onClick={(event) => {
                                        saveResidentVehicleNotes(
                                          profileKey,
                                          residentID,
                                          residentVehicleID,
                                          editNote.note
                                        ).then(() => {
                                          setEditNote({
                                            ...editNote,
                                            edit: false
                                          });
                                        });

                                        event.stopPropagation();
                                      }}
                                      variant="contained"
                                      color="secondary">
                                      Save
                                    </Button>
                                    <Button
                                      onClick={(event) => {
                                        setEditNote({
                                          note: vehicle.Notes
                                            ? vehicle.Notes
                                            : '',
                                          edit: false
                                        });
                                        event.stopPropagation();
                                      }}>
                                      Cancel
                                    </Button>
                                  </>
                                ) : (
                                  <IconButton
                                    onClick={() =>
                                      setEditNote({
                                        note: vehicle.Notes
                                          ? vehicle.Notes
                                          : '',
                                        edit: true
                                      })
                                    }>
                                    {' '}
                                    <EditIcon />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            )
                          }}
                        />
                      </div>
                    ) : null}

                    <div
                      className={
                        'multi-item-content' +
                        (isDisabled || vehicle.ResidentOnboardingStatusID > 1
                          ? ' disabled'
                          : '')
                      }>
                      <div className="form-fields">
                        <div className="field-container">
                          <FormControl
                            variant="outlined"
                            fullWidth
                            required
                            disabled={
                              isDisabled ||
                              (editMode.PropertyVehicleId &&
                                editMode.OccupantID)
                            }>
                            <InputLabel
                              id="VehicleOwner-select-label"
                              fullWidth>
                              Owner (Responsible Party)
                            </InputLabel>
                            <Select
                              fullWidth
                              labelId="VehicleOwner-select-label"
                              id="VehicleOwner-select"
                              value={vehicle.OccupantID}
                              label="Owner (Responsible Party)"
                              onChange={(event) =>
                                setResidentVehicleList(
                                  residentVehicleList.map((_vehicle) =>
                                    _vehicle.ResidentVehicleID ===
                                    vehicle.ResidentVehicleID
                                      ? {
                                          ..._vehicle,
                                          dataUpdated: true,
                                          OccupantID: event.target.value
                                            ? event.target.value
                                            : -1
                                        }
                                      : { ..._vehicle }
                                  )
                                )
                              }>
                              <MenuItem value={-1}>
                                {residentOnboarding.Header.FirstName +
                                  ' ' +
                                  residentOnboarding.Header.LastName}
                              </MenuItem>
                              {residentOnboarding.OccupantList.map(
                                (occupant) => (
                                  <MenuItem value={occupant.OccupantID}>
                                    {occupant.FirstName +
                                      ' ' +
                                      occupant.LastName}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="field-container"></div>

                        {residentOnboarding.Header
                          .VehicleRegistrationIncludeYear ? (
                          <div className="field-container">
                            <FormControl
                              variant="outlined"
                              fullWidth
                              required
                              disabled={
                                isDisabled ||
                                (editMode.PropertyVehicleId && editMode.Year)
                              }>
                              <InputLabel
                                id={
                                  'textfieldLicensePlateState_' + vehicleIndex
                                }>
                                Year
                              </InputLabel>
                              <Select
                                labelId={'textfieldYear_' + vehicleIndex}
                                label="Year"
                                id={'textfieldYear_' + vehicleIndex + '_Menu'}
                                required
                                value={vehicle.Year}
                                onChange={(event) =>
                                  setResidentVehicleList(
                                    residentVehicleList.map((_vehicle) =>
                                      _vehicle.ResidentVehicleID ===
                                      vehicle.ResidentVehicleID
                                        ? {
                                            ..._vehicle,
                                            dataUpdated: true,
                                            Year: event.target.value,
                                            Make: '',
                                            Model: '',
                                            VehicleType: ''
                                          }
                                        : { ..._vehicle }
                                    )
                                  )
                                }>
                                {getYearList().map((year) => (
                                  <MenuItem value={year}>{year}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        ) : null}

                        {residentOnboarding.Header
                          .VehicleRegistrationIncludeMake ? (
                          <div className="field-container">
                            <FormControl
                              variant="outlined"
                              fullWidth
                              disabled={!vehicle.Year || isDisabled}
                              required>
                              <InputLabel
                                id={'dropdownMake_' + vehicleIndex + '_Label'}>
                                Make
                              </InputLabel>
                              <Select
                                labelId={
                                  'dropdownMake_' + vehicleIndex + '_Label'
                                }
                                label="Make"
                                id={'dropdownMake_' + vehicleIndex + '_Menu'}
                                required
                                value={vehicle.Make ? vehicle.Make : ''}
                                onChange={(event) =>
                                  setResidentVehicleList(
                                    residentVehicleList.map((_vehicle) =>
                                      _vehicle.ResidentVehicleID ===
                                      vehicle.ResidentVehicleID
                                        ? {
                                            ..._vehicle,
                                            dataUpdated: true,
                                            Make: event.target.value,
                                            Model: '',
                                            VehicleType: ''
                                          }
                                        : { ..._vehicle }
                                    )
                                  )
                                }>
                                {getMakeList(vehicle.Year).map((option) => (
                                  <MenuItem value={option}>{option}</MenuItem>
                                ))}
                                <MenuItem value={'Other'}>
                                  Other/Not Listed
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        ) : null}

                        {residentOnboarding.Header
                          .VehicleRegistrationIncludeModel &&
                        vehicle.Make !== 'Other' ? (
                          <div className="field-container">
                            <FormControl
                              variant="outlined"
                              fullWidth
                              required
                              disabled={isDisabled}>
                              <InputLabel
                                id={'dropdownModel_' + vehicleIndex + '_Label'}>
                                Model
                              </InputLabel>
                              <Select
                                labelId={
                                  'dropdownModel_' + vehicleIndex + '_Label'
                                }
                                label="Model"
                                id={'dropdownModel_' + vehicleIndex + '_Menu'}
                                required
                                value={vehicle.Model}
                                onChange={(event) =>
                                  setResidentVehicleList(
                                    residentVehicleList.map((_vehicle) =>
                                      _vehicle.ResidentVehicleID ===
                                      vehicle.ResidentVehicleID
                                        ? {
                                            ..._vehicle,
                                            dataUpdated: true,
                                            Model: event.target.value,
                                            VehicleType: ''
                                          }
                                        : { ..._vehicle }
                                    )
                                  )
                                }>
                                {getModelList(vehicle.Year, vehicle.Make).map(
                                  (option) => (
                                    <MenuItem value={option}>{option}</MenuItem>
                                  )
                                )}
                                <MenuItem value={'Other'}>
                                  Other/Not Listed
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        ) : null}

                        {(residentOnboarding.Header
                          .VehicleRegistrationIncludeMake ||
                          residentOnboarding.Header
                            .VehicleRegistrationIncludeModel) &&
                        (vehicle.Make === 'Other' ||
                          vehicle.Model === 'Other') ? (
                          <div className="field-container">
                            <FormControl variant="outlined" fullWidth required>
                              <InputLabel
                                id={
                                  'dropdownVehicleType_' +
                                  vehicleIndex +
                                  '_Label'
                                }>
                                Type
                              </InputLabel>
                              <Select
                                labelId={
                                  'dropdownVehicleType_' +
                                  vehicleIndex +
                                  '_Label'
                                }
                                label="Type"
                                id={
                                  'dropdownVehicleType_' +
                                  vehicleIndex +
                                  '_Menu'
                                }
                                required
                                value={
                                  vehicle.VehicleType ? vehicle.VehicleType : ''
                                }
                                onChange={(event) =>
                                  setResidentVehicleList(
                                    residentVehicleList.map((_vehicle) =>
                                      _vehicle.ResidentVehicleID ===
                                      vehicle.ResidentVehicleID
                                        ? {
                                            ..._vehicle,
                                            dataUpdated: true,
                                            VehicleType: event.target.value
                                          }
                                        : { ..._vehicle }
                                    )
                                  )
                                }>
                                {vehicleTypeList.map((option) => (
                                  <MenuItem value={option}>{option}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        ) : null}

                        {residentOnboarding.Header
                          .VehicleRegistrationIncludeColor ? (
                          <div className="field-container">
                            <Autocomplete
                              freeSolo
                              fullWidth
                              required
                              disabled={isDisabled}
                              value={vehicle.Color ? vehicle.Color : ''}
                              disableClearable
                              onChange={(event, value) => {
                                setResidentVehicleList(
                                  residentVehicleList.map((_vehicle) =>
                                    _vehicle.ResidentVehicleID ===
                                    vehicle.ResidentVehicleID
                                      ? {
                                          ..._vehicle,
                                          dataUpdated: true,
                                          Color: value
                                        }
                                      : { ..._vehicle }
                                  )
                                );
                              }}
                              options={colorList.map((option) => option)}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  value={vehicle.Color}
                                  label="Color"
                                  InputProps={{
                                    ...params.InputProps,
                                    type: 'search'
                                  }}
                                  onChange={(event) =>
                                    setResidentVehicleList(
                                      residentVehicleList.map((_vehicle) =>
                                        _vehicle.ResidentVehicleID ===
                                        vehicle.ResidentVehicleID
                                          ? {
                                              ..._vehicle,
                                              dataUpdated: true,
                                              Color: event.target.value
                                            }
                                          : { ..._vehicle }
                                      )
                                    )
                                  }
                                />
                              )}
                            />
                          </div>
                        ) : null}

                        {residentOnboarding.Header
                          .VehicleRegistrationIncludeLicensePlateNumber ? (
                          <div className="field-container">
                            <TextField
                              required
                              variant="outlined"
                              fullWidth
                              key={
                                'textfieldLicensePlateNumber_' + vehicleIndex
                              }
                              id={'textfieldLicensePlateNumber_' + vehicleIndex}
                              label={'License Plate Number'}
                              value={
                                vehicle.LicensePlateNumber
                                  ? vehicle.LicensePlateNumber
                                  : ''
                              }
                              disabled={
                                isDisabled ||
                                (editMode.PropertyVehicleId &&
                                  editMode.LicensePlateNumber)
                              }
                              onChange={(event) =>
                                setResidentVehicleList(
                                  residentVehicleList.map((_vehicle) =>
                                    _vehicle.ResidentVehicleID ===
                                    vehicle.ResidentVehicleID
                                      ? {
                                          ..._vehicle,
                                          dataUpdated: true,
                                          LicensePlateNumber: event.target.value
                                        }
                                      : { ..._vehicle }
                                  )
                                )
                              }
                            />
                          </div>
                        ) : null}

                        {residentOnboarding.Header
                          .VehicleRegistrationIncludeLicensePlateState ? (
                          <div className="field-container">
                            <FormControl
                              variant="outlined"
                              fullWidth
                              required
                              disabled={
                                isDisabled ||
                                (editMode.PropertyVehicleId &&
                                  editMode.LicensePlateState)
                              }>
                              <InputLabel
                                id={
                                  'textfieldLicensePlateState_' + vehicleIndex
                                }>
                                License Plate State
                              </InputLabel>
                              <Select
                                labelId={
                                  'textfieldLicensePlateState_' + vehicleIndex
                                }
                                label="License Plate State"
                                id={
                                  'textfieldLicensePlateState_' +
                                  vehicleIndex +
                                  '_Menu'
                                }
                                required
                                value={
                                  vehicle.LicensePlateState
                                    ? vehicle.LicensePlateState
                                    : ''
                                }
                                onChange={(event) =>
                                  setResidentVehicleList(
                                    residentVehicleList.map((_vehicle) =>
                                      _vehicle.ResidentVehicleID ===
                                      vehicle.ResidentVehicleID
                                        ? {
                                            ..._vehicle,
                                            dataUpdated: true,
                                            LicensePlateState:
                                              event.target.value
                                          }
                                        : { ..._vehicle }
                                    )
                                  )
                                }>
                                {stateList.map((state) => (
                                  <MenuItem value={state}>{state}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        ) : null}

                        {residentOnboarding.Header
                          .VehicleRegistrationIncludeVIN ? (
                          <div className="field-container">
                            <TextField
                              variant="outlined"
                              required
                              fullWidth
                              key={'textfieldVIN_' + vehicleIndex}
                              id={'textfieldVIN_' + vehicleIndex}
                              label={'Vehicle Identification Number (VIN)'}
                              value={vehicle.VIN ? vehicle.VIN : ''}
                              disabled={isDisabled}
                              onChange={(event) =>
                                setResidentVehicleList(
                                  residentVehicleList.map((_vehicle) =>
                                    _vehicle.ResidentVehicleID ===
                                    vehicle.ResidentVehicleID
                                      ? {
                                          ..._vehicle,
                                          dataUpdated: true,
                                          VIN: event.target.value
                                        }
                                      : { ..._vehicle }
                                  )
                                )
                              }
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className="photo-fields">
                        <ResidentOnboardingPhotoUpload
                          residentOnboarding={residentOnboarding}
                          refreshResidentOnboarding={refreshResidentOnboarding}
                          residentVehicleID={vehicle.ResidentVehicleID}
                          residentFileTypeID={2}
                          label={'Vehicle Photos'}
                          disabled={isDisabled}
                          requiredPhotoList={getRequiredPhotoList()}
                          residentID={residentID}
                        />
                      </div>

                      {residentOnboarding.Header
                        .VehicleRegistrationIncludeProofOfInsurance ? (
                        <ResidentOnboardingFileUpload
                          residentOnboarding={residentOnboarding}
                          refreshResidentOnboarding={refreshResidentOnboarding}
                          residentVehicleID={vehicle.ResidentVehicleID}
                          residentFileTypeID={3}
                          disabled={isDisabled}
                          residentID={residentID}
                          label={'Proof of Vehicle Insurance'}
                        />
                      ) : null}

                      {vehicleIndex === 0 &&
                      residentOnboarding.Header
                        .VehicleRegistrationIncludeCheckbox ? (
                        <div className={'acknowledgment'}>
                          <FormControlLabel
                            control={
                              <div>
                                <Checkbox
                                  checked={
                                    residentOnboardingSetting.IsVehicleCheckboxChecked
                                  }
                                  disabled={isDisabled}
                                  onChange={(event) => {
                                    setResidentOnboardingSetting({
                                      ...residentOnboardingSetting,
                                      IsVehicleCheckboxChecked:
                                        event.target.checked
                                    });
                                    updateResidentOnboardingHeader({
                                      ...residentOnboarding.Header,
                                      IsVehicleCheckboxChecked:
                                        event.target.checked
                                    });
                                  }}
                                  name="gilad"
                                />
                              </div>
                            }
                            label={
                              <>
                                Resident acknowledgment of insurance
                                requirements
                              </>
                            }
                          />
                          {residentOnboarding.Header
                            .VehicleRegistrationCheckboxText ? (
                            <div
                              className="message"
                              dangerouslySetInnerHTML={{
                                __html:
                                  residentOnboarding.Header.VehicleRegistrationCheckboxText.split(
                                    '\n'
                                  ).join('<br />')
                              }}></div>
                          ) : null}
                        </div>
                      ) : null}

                      {!vehicle.ResidentOnboardingStatusID ||
                      vehicle.ResidentOnboardingStatusID === 1 ? (
                        <div className="save-cancel">
                          {validationErrorList && validationErrorList.length ? (
                            <Tooltip
                              title={
                                <div className="resident-onboarding-submit-cover-tooltip">
                                  <p>
                                    Please check the form above and make sure
                                    you provided all fields:
                                  </p>
                                  <ul>
                                    {validationErrorList.map((error) => (
                                      <li>{error}</li>
                                    ))}
                                  </ul>
                                </div>
                              }
                              placement="top-start">
                              <div className="submit-cover"></div>
                            </Tooltip>
                          ) : null}

                          <Button
                            variant="contained"
                            color="primary"
                            className={
                              validationErrorList && validationErrorList.length
                                ? 'disabled'
                                : ''
                            }
                            disabled={
                              validationErrorList && validationErrorList.length
                            }
                            onClick={() => {
                              if (
                                !validationErrorList ||
                                !validationErrorList.length
                              ) {
                                actions.pageLoading(
                                  true,
                                  'Saving vehicle information...'
                                );
                                actions
                                  .saveResidentVehicle(vehicle, residentID)
                                  .then((result) => {
                                    actions.loadResidentOnboarding(residentID);

                                    actions.pageLoading(false);
                                    if (
                                      result.updateResult &&
                                      result.updateResult.Reason
                                    ) {
                                      setSaveWarning({
                                        residentVehicleID:
                                          vehicle.ResidentVehicleID,
                                        reason: result.updateResult.Reason
                                      });
                                    } else {
                                      setSaveWarning(null);
                                      setEditMode(null);
                                    }
                                  });
                              }
                            }}>
                            Save
                          </Button>

                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              loadResidentVehicleList(true);
                              setEditMode(null);
                            }}>
                            Cancel
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>

      {saveWarning ? (
        <ResidentOnboardingSaveWarning
          dataTypeLabel="vehicle"
          continueFunc={() => {
            setEditMode({
              ...residentVehicleList.filter(
                (_vehicle) =>
                  _vehicle.ResidentVehicleID === saveWarning.residentVehicleID
              )[0]
            });
            setSaveWarning(null);
          }}
          closeFunc={() => setSaveWarning(null)}
          remainingItems={saveWarning.reason}
        />
      ) : null}

      {residentStatusUpdate ? (
        <ResidentOnboardingStatusUpdate
          dataTypeLabel={'vehicle'}
          type="vehicle"
          residentOnboardingStatusID={
            residentStatusUpdate.residentOnboardingStatusID
          }
          profileKey={profileKey}
          resident={residentOnboarding.Header}
          exists={!residentOnboardingSetting.HasNoVehicle}
          saveFunc={(note) => {
            if (residentOnboardingSetting.HasNoVehicle) {
              return actions
                .saveResidentOnboarding(
                  {
                    ...residentOnboarding.Header,
                    HasNoVehicleConfirmed:
                      residentStatusUpdate.residentOnboardingStatusID === 3
                        ? true
                        : false,
                    HasNoVehicleReason: note
                  },
                  residentID
                )
                .then((result) => {
                  setResidentStatusUpdate(null);
                  closeOnboardingRow();
                });
            } else {
              actions
                .saveResidentVehicleStatus(
                  residentID,
                  { ...residentStatusUpdate.vehicle },
                  residentStatusUpdate.residentOnboardingStatusID,
                  note
                )
                .then((result) => {
                  setResidentStatusUpdate(null);
                  closeOnboardingRow();
                });
            }
          }}
          closeFunc={() => setResidentStatusUpdate(null)}
        />
      ) : null}
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    stateList: state.commonData.stateList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      saveResidentVehicle: bindActionCreators(
        residentOnboardingActions.saveResidentVehicle,
        dispatch
      ),
      addResidentVehicle: bindActionCreators(
        residentOnboardingActions.addResidentVehicle,
        dispatch
      ),
      deleteResidentVehicle: bindActionCreators(
        residentOnboardingActions.deleteResidentVehicle,
        dispatch
      ),
      saveResidentVehicleStatus: bindActionCreators(
        residentOnboardingActions.saveResidentVehicleStatus,
        dispatch
      ),
      saveResidentOnboarding: bindActionCreators(
        residentOnboardingActions.saveResidentOnboarding,
        dispatch
      ),
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResidentOnboardingVehicle);
