import React, { useState, useEffect } from 'react';
import { Button, IconButton, TextField, Tooltip } from '@mui/material';
import { withPrefix } from 'gatsby-link';
import PinDropIcon from '@mui/icons-material/PinDrop';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ServiceProfileNotice from '../ServiceProfileNotice';

import './index.css';

import SchoolPopup from '../SchoolPopup';

function SchoolList({ serviceAddress, siteConfig, checklistLength }) {
  const [schoolDictionary, setSchoolDictionary] = useState(null);
  const [showSchoolPopup, setShowSchoolPopup] = useState(false);
  const [schoolDistrictLabel, setSchoolDistrictLabel] = useState(null);

  useEffect(() => {
    let _schoolDictionary = {};
    if (serviceAddress && serviceAddress.SchoolDictionary) {
      for (const gradeLevel in serviceAddress.SchoolDictionary) {
        _schoolDictionary[gradeLevel] = {
          menuOpen: false,
          selectedIndex: 0
        };
      }
    }

    if (serviceAddress && serviceAddress.SchoolList) {
      let _schoolDistrictList = [];
      let _schoolDistrictDictionary = {};
      let _schoolDistrictLabel = '';

      serviceAddress.SchoolList.map((school) => {
        if (!_schoolDistrictDictionary[school.SchoolDistrictName]) {
          _schoolDistrictDictionary[school.SchoolDistrictName] = true;
          _schoolDistrictList.push(school.SchoolDistrictName);
        }
      });

      _schoolDistrictList.map((schoolDistrictName, schoolDistrictIndex) => {
        if (schoolDistrictIndex > 0) {
          _schoolDistrictLabel +=
            schoolDistrictIndex === _schoolDistrictList.length - 1
              ? ' and '
              : ', ';
        }
        _schoolDistrictLabel += schoolDistrictName;
      });

      setSchoolDistrictLabel(_schoolDistrictLabel);
    }

    setSchoolDictionary(_schoolDictionary);
  }, [serviceAddress]);

  function getDirectionsLink(gradeLevel) {
    return (
      'https://www.google.com/maps/dir/?api=1&origin=' +
      serviceAddress.Address.Address +
      ', ' +
      (serviceAddress.Address.Secondary
        ? ' ' + serviceAddress.Address.Secondary
        : '') +
      ', ' +
      serviceAddress.Address.City +
      ', ' +
      serviceAddress.Address.State +
      ' ' +
      serviceAddress.Address.Zip +
      '&destination=' +
      serviceAddress.SchoolDictionary[gradeLevel][
        schoolDictionary[gradeLevel].selectedIndex
      ].Address +
      ', ' +
      serviceAddress.SchoolDictionary[gradeLevel][
        schoolDictionary[gradeLevel].selectedIndex
      ].City +
      ', ' +
      serviceAddress.SchoolDictionary[gradeLevel][
        schoolDictionary[gradeLevel].selectedIndex
      ].State +
      ' ' +
      serviceAddress.SchoolDictionary[gradeLevel][
        schoolDictionary[gradeLevel].selectedIndex
      ].Zip
    );
  }

  return serviceAddress.SchoolDictionary &&
    serviceAddress.Checklist &&
    schoolDictionary &&
    siteConfig.id === 'mi' &&
    Object.keys(schoolDictionary).length ===
      Object.keys(serviceAddress.SchoolDictionary).length &&
    serviceAddress.SchoolList.length ? (
    <>
      <ServiceProfileNotice residentSection={'schools'} />
      <div
        className={
          'node schools' +
          (checklistLength && checklistLength % 2 === 1 ? ' odd' : '')
        }>
        <div className="title">
          <img src={withPrefix('/images/misc/school-bus.png')} />
          <h4>Your Local Schools</h4>
          <h5>{schoolDistrictLabel} </h5>
        </div>
        <ul
          className={
            'school-list' +
            (Object.keys(serviceAddress.SchoolDictionary).length > 4
              ? ' overflow'
              : '')
          }>
          {Object.keys(serviceAddress.SchoolDictionary).map((gradeLevel) =>
            schoolDictionary[gradeLevel] ? (
              <li
                className="school"
                style={{
                  width:
                    100 / Object.keys(serviceAddress.SchoolDictionary).length +
                    '%'
                }}>
                {serviceAddress.SchoolDictionary[gradeLevel].filter(
                  (school) => school.DistrictHasSchoolChoice
                ).length ? (
                  <div className="school-choice">
                    <h4>
                      {
                        serviceAddress.SchoolDictionary[gradeLevel][0]
                          .SchoolDistrictName
                      }{' '}
                      is a "School Choice" District
                    </h4>
                    <p>
                      Parents can select the campus they want their children to
                      attend and truly customize the student's educational
                      experience.{' '}
                      {serviceAddress.SchoolDictionary[gradeLevel][0]
                        .WebsiteUrl ? (
                        <>
                          <br />
                          <br /> Visit{' '}
                          <a
                            className="link"
                            href={
                              serviceAddress.SchoolDictionary[gradeLevel][0]
                                .WebsiteUrl
                            }>
                            {serviceAddress.SchoolDictionary[
                              gradeLevel
                            ][0].WebsiteUrl.split('https://')
                              .join('')
                              .split('http://')
                              .join('')}
                          </a>{' '}
                          to get started.
                        </>
                      ) : null}
                    </p>
                  </div>
                ) : (
                  <>
                    <h4>
                      {serviceAddress.SchoolDictionary[gradeLevel].length >
                      1 ? (
                        <>
                          <Button
                            onClick={(event) =>
                              setSchoolDictionary({
                                ...schoolDictionary,
                                [gradeLevel]: {
                                  ...schoolDictionary[gradeLevel],
                                  menuOpen: event.currentTarget
                                }
                              })
                            }>
                            {gradeLevel} <ExpandMoreIcon />
                          </Button>
                          <Menu
                            anchorEl={schoolDictionary[gradeLevel].menuOpen}
                            keepMounted
                            open={Boolean(
                              schoolDictionary[gradeLevel].menuOpen
                            )}
                            onClose={() =>
                              setSchoolDictionary({
                                ...schoolDictionary,
                                [gradeLevel]: {
                                  ...schoolDictionary[gradeLevel],
                                  menuOpen: null
                                }
                              })
                            }>
                            {serviceAddress.SchoolDictionary[gradeLevel].map(
                              (school, schoolIndex) => (
                                <MenuItem
                                  style={{
                                    width: '200px',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                  onClick={() =>
                                    setSchoolDictionary({
                                      ...schoolDictionary,
                                      [gradeLevel]: {
                                        ...schoolDictionary[gradeLevel],
                                        menuOpen: null,
                                        selectedIndex: schoolIndex
                                      }
                                    })
                                  }>
                                  {school.SchoolName}
                                </MenuItem>
                              )
                            )}
                          </Menu>
                        </>
                      ) : (
                        <span>{gradeLevel}</span>
                      )}
                    </h4>
                    <h3>
                      <a
                        href={
                          serviceAddress.SchoolDictionary[gradeLevel][
                            schoolDictionary[gradeLevel].selectedIndex
                          ].WebsiteUrl
                        }
                        target="mi_offsite"
                        className="link">
                        {
                          serviceAddress.SchoolDictionary[gradeLevel][
                            schoolDictionary[gradeLevel].selectedIndex
                          ].SchoolName
                        }
                      </a>
                    </h3>

                    {serviceAddress.SchoolDictionary[gradeLevel][
                      schoolDictionary[gradeLevel].selectedIndex
                    ].Address ? (
                      <Button
                        className="data-point"
                        //onClick={() => zoomToSchool(schoolType)}
                        startIcon={<PinDropIcon />}
                        href={getDirectionsLink(gradeLevel)}
                        target="mi_offsite">
                        {
                          serviceAddress.SchoolDictionary[gradeLevel][
                            schoolDictionary[gradeLevel].selectedIndex
                          ].Address
                        }
                        <br />
                        {
                          serviceAddress.SchoolDictionary[gradeLevel][
                            schoolDictionary[gradeLevel].selectedIndex
                          ].City
                        }
                        ,{' '}
                        {
                          serviceAddress.SchoolDictionary[gradeLevel][
                            schoolDictionary[gradeLevel].selectedIndex
                          ].State
                        }{' '}
                        {
                          serviceAddress.SchoolDictionary[gradeLevel][
                            schoolDictionary[gradeLevel].selectedIndex
                          ].Zip
                        }
                      </Button>
                    ) : null}

                    {serviceAddress.SchoolDictionary[gradeLevel][
                      schoolDictionary[gradeLevel].selectedIndex
                    ].Phone ? (
                      <Button
                        className="data-point phone"
                        href={
                          'tel:' +
                          serviceAddress.SchoolDictionary[gradeLevel][
                            schoolDictionary[gradeLevel].selectedIndex
                          ].Phone
                        }
                        startIcon={<PhoneAndroidIcon />}>
                        {
                          serviceAddress.SchoolDictionary[gradeLevel][
                            schoolDictionary[gradeLevel].selectedIndex
                          ].Phone
                        }
                      </Button>
                    ) : null}

                    {serviceAddress.SchoolDictionary[gradeLevel][
                      schoolDictionary[gradeLevel].selectedIndex
                    ].Website ||
                    serviceAddress.SchoolDictionary[gradeLevel][
                      schoolDictionary[gradeLevel].selectedIndex
                    ].Address ? (
                      <div className="buttons">
                        {serviceAddress.SchoolDictionary[gradeLevel][
                          schoolDictionary[gradeLevel].selectedIndex
                        ].WebsiteUrl ? (
                          <Button
                            variant="contained"
                            size="small"
                            href={
                              serviceAddress.SchoolDictionary[gradeLevel][
                                schoolDictionary[gradeLevel].selectedIndex
                              ].WebsiteUrl
                            }
                            target="mi_offsite"
                            style={{ marginRight: '10px' }}>
                            Enroll
                          </Button>
                        ) : null}
                        {serviceAddress.SchoolDictionary[gradeLevel][
                          schoolDictionary[gradeLevel].selectedIndex
                        ].Address ? (
                          <Button
                            variant="contained"
                            size="small"
                            href={getDirectionsLink(gradeLevel)}
                            target="mi_offsite">
                            Get Directions
                          </Button>
                        ) : null}
                      </div>
                    ) : null}
                  </>
                )}
              </li>
            ) : null
          )}
        </ul>
      </div>

      <div className="other-school-links">
        <Button onClick={() => setShowSchoolPopup(true)}>
          Show Non-Traditional/Specialized Public Schools
        </Button>
        <Button
          href={
            'https://www.greatschools.org/search/search.zipcode?sort=rating&st%5B%5D=private&st%5B%5D=public_charter&st%5B%5D=charter&zip=' +
            serviceAddress.Address.Zip
          }
          target="mi_offsite">
          Show Private/Charter Schools
        </Button>
      </div>

      {showSchoolPopup ? (
        <SchoolPopup closeFunc={() => setShowSchoolPopup(false)} />
      ) : null}
    </>
  ) : null;
}

export default SchoolList;
