import React, { useState, useRef, useEffect, version } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import Dropzone from 'react-dropzone-uploader';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import { getConfig } from '../../../api/residentOnboardingApi';
import { getCookie } from '../../../api/dataApi';

import * as webSocketActions from '../../../redux/actions/webSocketActions';
import * as residentOnboardingActions from '../../../redux/actions/residentOnboardingActions';

import ImageCrop from '../../Common/ImageCropNew';

import 'react-dropzone-uploader/dist/styles.css';
import '../index.css';

export function getPlaceholderImage(description) {
  let imageSettings = { url: '', size: 'cover' };
  switch (description) {
    case 'Front':
      imageSettings.url = withPrefix('/images/vehicle/vehicle-front.svg?v2');
      imageSettings.size = '50%';
      break;
    case 'Back':
      imageSettings.url = withPrefix('/images/vehicle/vehicle-back.svg?v2');
      imageSettings.size = '50%';
      break;
    case 'Vehicle Photo':
    case 'Side':
      imageSettings.url = withPrefix('/images/vehicle/vehicle-side.svg?v2');
      imageSettings.size = '75%';
      break;
    case 'Angled':
      imageSettings.url = withPrefix('/images/vehicle/vehicle-angled.svg');
      imageSettings.size = '75%';
      break;
    case 'Standing/Side':
      imageSettings.url = withPrefix('/images/pet/pet-standing.svg');
      imageSettings.size = '50%';
      break;
    case 'Favorite 1':
      imageSettings.url = withPrefix('/images/pet/pet-sitting.svg');
      imageSettings.size = '50%';
      break;
    case 'Favorite 2':
      imageSettings.url = withPrefix('/images/pet/pet-favorite.svg');
      imageSettings.size = '50%';
      break;
    case 'Pet Photo':
    case 'Face':
      imageSettings.url = withPrefix('/images/pet/pet-face.svg');
      imageSettings.size = '70%';
      break;
    default:
      imageSettings.url = withPrefix('/images/image-placeholder.svg');
      imageSettings.size = '50%';
      break;
  }

  return imageSettings;
}

export function getImageDownloadUrl(file) {
  return (
    process.env.GATSBY_API_URL +
    'ResidentFile/DownloadFile?residentFileID=' +
    file.ResidentFileID +
    '&isImage=true' +
    '&token=' +
    getResidentFileToken(file.ResidentID) +
    '&fileName=' +
    file.FileName
  );
}

function getResidentFileToken(residentID) {
  const headers = getConfig(residentID).headers;

  return btoa(
    headers.key +
      ':' +
      headers.visitorKey +
      ':' +
      headers.visitKey +
      ':' +
      getCookie('mi-invitation') +
      ':' +
      getCookie('mi-d') +
      ':' +
      headers.property +
      ':' +
      headers.residentID +
      (headers.user ? ':' + headers.user : '')
  );
}

function ResidentOnboardingPhotoUpload({
  actions,
  serviceAddress,
  siteConfig,
  residentOnboarding,
  refreshResidentOnboarding,
  disabled,
  label,
  residentVehicleID,
  residentPetID,
  residentWalkthroughItemID,
  residentFileTypeID,
  requiredPhotoList,
  residentID
}) {
  const [fileList, setFileList] = useState([]);
  const fileListRef = useRef();
  const [deleteFile, setDeleteFile] = useState(null);
  const [photoSlotList, setPhotoSlotList] = useState(
    requiredPhotoList ? requiredPhotoList : []
  );
  const [cropFile, setCropFile] = useState(null);
  const [photoSlotImage, setPhotoSlotImage] = useState(null);
  const componentKey =
    'photo-list' +
    (residentVehicleID
      ? '-vehicle-' + residentVehicleID
      : residentPetID
      ? '-pet-' + residentPetID
      : residentWalkthroughItemID
      ? '-walkthrough-' + residentWalkthroughItemID
      : '') +
    '-' +
    residentFileTypeID;

  fileListRef.current = fileList;

  useEffect(() => {
    const _fileList = residentOnboarding.FileList.filter(
      (file) =>
        file.ResidentFileTypeID === parseInt(residentFileTypeID) &&
        ((residentVehicleID &&
          file.ResidentVehicleID === parseInt(residentVehicleID)) ||
          (residentPetID && file.ResidentPetID === parseInt(residentPetID)) ||
          (residentWalkthroughItemID &&
            file.ResidentWalkthroughItemID ===
              parseInt(residentWalkthroughItemID)))
    );

    setFileList(_fileList);
  }, [residentOnboarding, residentVehicleID, residentPetID]);

  useEffect(() => {
    if (!requiredPhotoList) {
      const _photoSlotList = fileList
        .map((file) => getFileName(file))
        .concat('Add Photo');

      setPhotoSlotList(_photoSlotList);
    }
  }, [fileList]);

  useEffect(() => {
    let _photoSlotImage = {};

    photoSlotList.map((description) => {
      _photoSlotImage[description] = getBackgroundImage(description);
    });

    setPhotoSlotImage(_photoSlotImage);
  }, [photoSlotList, fileList]);

  function getFileTypeName(file) {
    return providerServiceID === 37
      ? 'Proof of Coverage'
      : file.ResidentFileTypeName;
  }

  function getFileName(file) {
    if (file) {
      if (file.FileName.indexOf('_') !== -1) {
        let _fileNameArray = file.FileName.split('cropped_')
          .join('')
          .split('_');
        _fileNameArray.splice(0, 1);
        return _fileNameArray.join('_').split('?')[0];
      }

      return file.FileName;
    }
    return 'Add Photo';
  }

  function getBackgroundImage(description) {
    let imageSettings = { url: '', source: 'icon', size: 'cover' };

    const photoList = fileList
      ? fileList.filter((file) =>
          requiredPhotoList
            ? file.Description === description
            : getFileName(file) === description
        )
      : [];

    if (photoList.length) {
      imageSettings.file = photoList[photoList.length - 1];
      imageSettings.url = getImageDownloadUrl(imageSettings.file);

      imageSettings.source = 'user';
    } else {
      imageSettings = { ...imageSettings, ...getPlaceholderImage(description) };
    }

    return [imageSettings];
  }

  return photoSlotImage ? (
    <div
      key={componentKey}
      className={'document-upload photo' + (disabled ? ' disabled' : '')}>
      <label>{label}</label>
      <div className="file-upload-list">
        {photoSlotList
          .filter(
            (description) =>
              photoSlotImage[description] && photoSlotImage[description].length
          )
          .map((description) => (
            <div
              className={
                'file' +
                (!residentID ||
                photoSlotImage[description].filter(
                  (image) => image.source === 'user'
                ).length
                  ? ''
                  : ' hide-disabled')
              }>
              {photoSlotImage[description].map((image) => (
                <div
                  className={'image ' + image.source}
                  onClick={(event) => {
                    const dropzoneContainer = document.getElementById(
                      'imageUpload_' + description
                    );
                    if (dropzoneContainer) {
                      const dropzoneElement =
                        dropzoneContainer.getElementsByClassName(
                          'dzu-inputLabel'
                        )[0];
                      if (dropzoneElement) {
                        dropzoneElement.click();
                      }
                    }
                  }}
                  key={componentKey + '-' + description}>
                  <>
                    <img
                      src={image.url}
                      style={{
                        width: image.size === 'cover' ? '100%' : image.size
                      }}
                    />

                    {image.source === 'user' ? (
                      <>
                        <Button
                          className="view"
                          // href={
                          //   process.env.GATSBY_API_URL +
                          //   'ResidentFile/DownloadFile?residentFileID=' +
                          //   image.file.ResidentFileID +
                          //   '&isImage=true' +
                          //   '&token=' +
                          //   getResidentFileToken()
                          // }
                          href={image.url}
                          target="_blank">
                          <IconButton
                            className="delete"
                            onClick={(event) => {
                              setDeleteFile(image.file);
                              event.stopPropagation();
                              event.preventDefault();
                            }}>
                            <DeleteIcon />
                          </IconButton>
                        </Button>
                      </>
                    ) : null}
                  </>
                </div>
              ))}

              <div
                className="dropzone-container"
                id={'imageUpload_' + description}>
                <Dropzone
                  id={'imageUpload_' + description}
                  getUploadParams={() => {
                    let _headers = {
                      'Cache-Control': ''
                    };

                    return {
                      url:
                        process.env.GATSBY_API_URL +
                        'ResidentFile/UploadFile?residentFileTypeID=' +
                        residentFileTypeID +
                        (residentVehicleID
                          ? '&residentVehicleID=' + residentVehicleID
                          : '') +
                        (residentPetID
                          ? '&residentPetID=' + residentPetID
                          : '') +
                        (residentWalkthroughItemID
                          ? '&residentWalkthroughItemID=' +
                            residentWalkthroughItemID
                          : '') +
                        (description
                          ? '&description=' + encodeURIComponent(description)
                          : ''),
                      method: 'POST',
                      withCredentials: false,
                      headers: {
                        ..._headers,
                        ...getConfig(residentID).headers
                      }
                    };
                  }}
                  onChangeStatus={({ meta, remove, xhr }, status) => {
                    if (status == 'done') {
                      remove();
                      try {
                        const result = JSON.parse(xhr.response);
                        console.log('file uploaded', result, meta);
                        if (result.UpdatedFileList) {
                          //Find the new file and prompt to crop it
                          const newFile = result.UpdatedFileList.filter(
                            (file) =>
                              file.ResidentFileID === result.ResidentFileID
                          )[0];

                          console.log('newFile', newFile);

                          if (newFile) {
                            setCropFile(newFile);
                          }

                          actions.updateFileList(result.UpdatedFileList);
                        }
                      } catch (e) {}
                    }
                  }}
                  maxFiles={1}
                  multiple={false}
                  canCancel={false}
                  accept=".jpeg,.jpg,.png,.gif,.bmp"
                  inputContent={
                    <>
                      <strong>
                        {description.indexOf('Favorite') !== -1
                          ? 'Optional '
                          : ''}
                        {description}{' '}
                      </strong>{' '}
                      Click to upload photo
                    </>
                  }
                />
              </div>
            </div>
          ))}
      </div>
      {deleteFile ? (
        <Dialog
          open={true}
          aria-labelledby="delete-file-dialog-label"
          className="delete-file-dialog">
          <DialogTitle id="delete-file-dialog-label">Delete Photo</DialogTitle>
          <DialogContent width="100%" style={{ paddingTop: '20px' }}>
            <p>
              Are you sure you want to delete this photo? The file will be
              permanently removed.
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                actions
                  .deleteFile(deleteFile.ResidentFileID, residentID)
                  .then((result) => setDeleteFile(null));
              }}>
              Delete
            </Button>
            <Button onClick={() => setDeleteFile(null)}>Cancel</Button>
          </DialogActions>
        </Dialog>
      ) : null}

      {cropFile ? (
        <ImageCrop
          title={label}
          url={
            process.env.GATSBY_API_URL +
            'ResidentFile/DownloadFilePreview?residentFileID=' +
            cropFile.ResidentFileID +
            '&isImage=true' +
            '&token=' +
            getResidentFileToken(residentID)
          }
          fileName={cropFile.FileName}
          closeFunc={() => {
            actions
              .deleteFile(cropFile.ResidentFileID, residentID)
              .then((result) => setCropFile(null));
          }}
          onCrop={(croppedImageData) => {
            console.log('onCrop', croppedImageData);

            actions.pageLoading(true, 'Cropping image...');
            actions
              .updateCroppedImage(
                cropFile.ResidentFileID,
                residentID,
                croppedImageData
              )
              .then((result) => {
                actions.pageLoading(false);
                setCropFile(null);
              });
          }}
        />
      ) : null}
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      updateFileList: bindActionCreators(
        residentOnboardingActions.updateFileList,
        dispatch
      ),
      deleteFile: bindActionCreators(
        residentOnboardingActions.deleteFile,
        dispatch
      ),
      cropImage: bindActionCreators(
        residentOnboardingActions.cropImage,
        dispatch
      ),
      updateCroppedImage: bindActionCreators(
        residentOnboardingActions.updateCroppedImage,
        dispatch
      )
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResidentOnboardingPhotoUpload);
