import React from 'react';

import ServiceContainer from '../ServiceContainer';
import ConnectUtilities from '../ConnectUtilities';
import ConnectElectric from '../ConnectElectric';
import StateServiceList from '../StateServiceList';
import SettleIn from '../SettleIn';
import PlanBuilder from '../Checkout/PlanBuilder';

import './spa-mode.css';

function ServiceSectionSpa({ activeSection, closeFunc }) {
  return activeSection ? (
    <div className="dashboard-spa">
      {activeSection.ServiceSectionID === 1 ? (
        <ConnectUtilities />
      ) : activeSection.ServiceSectionID === 19 ? (
        <ConnectElectric />
      ) : activeSection.ServiceSectionID === 2 ? (
        <PlanBuilder modal />
      ) : activeSection.ServiceSectionID === 6 ? (
        <StateServiceList />
      ) : activeSection.ServiceSectionID === 3 ? (
        <SettleIn routeOverride={activeSection.Route} />
      ) : (
        <ServiceContainer routeOverride={activeSection.Route} mobileCollapse />
      )}
    </div>
  ) : null;
}

export default ServiceSectionSpa;
